import {
	CaseConflict,
	ConfirmationConfigIndex,
	ConfirmationStatus
} from './ConfirmCaseContextType';
import { ActiveToastType, ToastType } from '../../../../interfaces/ToastStatus/ToastStatusState';
import { ToastConstants } from '../../../../utilities/toastConstants';
import { ConflictsDisplayWrapper } from '../../ConfirmationDrawer/ConflictsDisplayWrapper/ConflictsDisplayWrapper';
import React, { ReactNode } from 'react';
import { SavePendingLinkWrapper } from '../../ConfirmationDrawer/SavePendingLinkWrapper/SavePendingLinkWrapper';
import { IntegrationType } from '../../../../interfaces/IntegrationType';
import { AxiosResponse } from 'axios';
import { CONFLICTS_DISPLAY_WRAPPER, SAVE_PENDING_LINK_WRAPPER } from '@components/DrawerWrapper/drawerConstants';

interface DrawerConfiguration {
	name: string;
	component: React.FC;
	openOnApiResponse: boolean;
}

interface ToastConfiguration {
	type: ActiveToastType;
	message: string;
}

export interface ConfirmationStatusResponseConfiguration {
	apiResponseStatusCode: number;
	drawer?: DrawerConfiguration;
	toast?: ToastConfiguration;
	error?: ReactNode;
	modifiedResponse?: AxiosResponse;
	conflicts?: CaseConflict[];
}

export const DEFAULT_ERROR_CONFIGURATION: ConfirmationApiResponseConfiguration = {
	confirmationStatus: ConfirmationStatus.ERROR_OTHER,
	apiResponseStatusCode: 500,
	toast: {
		type: ToastType.ERROR,
		message: ToastConstants.SAVE_ERROR
	},
	error: 'There was an error confirming the case. Please try again.',
};

export const CONFIRMATION_STATUS_CONFIGURATION_BASE: ConfirmationConfigIndex = {
	[ConfirmationStatus.UNDEFINED]: {
		apiResponseStatusCode: 0,
	},
	[ConfirmationStatus.ERROR_CONFLICTS]: {
		...DEFAULT_ERROR_CONFIGURATION,
		apiResponseStatusCode: 409,
	},
	[ConfirmationStatus.ERROR_OTHER]: DEFAULT_ERROR_CONFIGURATION,
	// Default both confirmed to status that does not exist
	[ConfirmationStatus.CONFIRMED_EHR]: {
		apiResponseStatusCode: 299,
	},
	[ConfirmationStatus.CONFIRMED_MANUAL]: {
		apiResponseStatusCode: 299,
	},
	[ConfirmationStatus.ERROR_NOT_FOUND]: {
		apiResponseStatusCode: 499,
	},
};

const CONFIRMATION_STATUS_EHR_CONFIG: ConfirmationConfigIndex = {
	...CONFIRMATION_STATUS_CONFIGURATION_BASE,
	[ConfirmationStatus.ERROR_CONFLICTS]: {
		apiResponseStatusCode: 409,
		drawer: {
			name: CONFLICTS_DISPLAY_WRAPPER,
			component: ConflictsDisplayWrapper,
			openOnApiResponse: true,
		},
		error: 'Review case data conflict',
	},
	[ConfirmationStatus.ERROR_NOT_FOUND]: {
		apiResponseStatusCode: 404,
		drawer: {
			name: SAVE_PENDING_LINK_WRAPPER,
			component: SavePendingLinkWrapper,
			openOnApiResponse: true,
		},
	},
	[ConfirmationStatus.CONFIRMED_EHR]: {
		apiResponseStatusCode: 200,
		toast: {
			type: ToastType.SUCCESS,
			message: ToastConstants.FORCE_SAVE_SUCCESS,
		},
	},
};

const CONFIRMATION_STATUS_MANUAL_CONFIG: ConfirmationConfigIndex = {
	...CONFIRMATION_STATUS_CONFIGURATION_BASE,
	[ConfirmationStatus.CONFIRMED_MANUAL]: {
		apiResponseStatusCode: 200,
		toast: {
			type: ToastType.SUCCESS,
			message: ToastConstants.REQUEST_SUCCESS,
		},
	},
};


export const CONFIRMATION_STATUS_CONFIG: Record<IntegrationType, ConfirmationConfigIndex> = {
	[IntegrationType.EHR]: CONFIRMATION_STATUS_EHR_CONFIG,
	[IntegrationType.MANUAL]: CONFIRMATION_STATUS_MANUAL_CONFIG,
};

export interface ConfirmationApiResponseConfiguration extends ConfirmationStatusResponseConfiguration {
	confirmationStatus: ConfirmationStatus;
}

const statusToApiIndexed = (list: ConfirmationConfigIndex): Record<number, ConfirmationApiResponseConfiguration> =>
	(Object.keys(list) as ConfirmationStatus[])
		.reduce((indexedList: Record<number, ConfirmationApiResponseConfiguration>, status: ConfirmationStatus) => {
			const configuration = {
				...list[status],
				confirmationStatus: status,
			};
			indexedList[configuration.apiResponseStatusCode] = configuration;

			return indexedList;
		}, {});
export const CONFIRMATION_RESPONSE_TO_CONFIG = {
	[IntegrationType.EHR]: statusToApiIndexed(CONFIRMATION_STATUS_EHR_CONFIG),
	[IntegrationType.MANUAL]: statusToApiIndexed(CONFIRMATION_STATUS_MANUAL_CONFIG),
};
