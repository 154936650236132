import { DropdownOption } from '@definitions/DropdownOption';
import { removeMaskSpace } from '../formUtilities';

export const validators = {

	requiredValidator: (value: unknown) => {
		return (value === ''|| value === null || value === undefined) ? 'required' : null;
	},

	minLengthValidator: (value: string) => {
		return (value.length <= 2) ? 'minLength' : null;
	},

	minLengthValidatorForPhone: (value: string) => {
		return (value.length < 10) ? 'minLength' : null;
	},

	minLengthValidatorForPassword: (value: string) => {
		return (value.length < 8) ? 'minLength' : null;
	},

	nameValidator: (value: string) => {
		if (value == '') {
			return null;
		}
		return (!/(^[a-z])((?![ .,'-]$)[a-z .,'-]){0,45}$/i.test(value)) ? 'invalidName' : null;
	},

	passwordValidator: (value: string) => {
		// eslint-disable-next-line no-useless-escape
		const re = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})');
		return (!re.test(value)) ? 'inValidPassword' : null;
	},

	emailValidator: (value: string) => {
		if (!value || value == '') {
			return null;
		}
		// eslint-disable-next-line no-useless-escape
		return (!(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i).test(value)) ? 'inValidEmail' : null;
	},

	phoneValidator: (value: string) =>{
		if (value == '') {
			return null;
		}

		return (/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/).test(removeMaskSpace(value)) ? null : 'invalidPhone';
	},

	dobValidator: (date: string) => {
		return new Date(date).setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0) ? 'invalidDOB' : null;
	},

	dateValidator: (dateString?: string) =>{
		if (!dateString) { return 'invalidDate'; }
		const formatedDatewithoutSpaces = dateString.replace(/ /g, '');
		// First check for the pattern
		if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(formatedDatewithoutSpaces))
			return 'invalidDate';

		// Parse the date parts to integers
		const parts = dateString.split('/');
		const day = parseInt(parts[1], 10);
		const month = parseInt(parts[0], 10);
		const year = parseInt(parts[2], 10);

		// Check the ranges of month and year
		if (year < 1000 || year > 3000 || month == 0 || month > 12)
			return 'invalidDate';

		const monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

		// Adjust for leap years
		if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
			monthLength[1] = 29;

		// Check the range of the day
		return (day > 0 && day <= monthLength[month - 1]) ? null : 'invalidDate';
	},

	ssnValidator: (ssn: string) => {
		if (!ssn || ssn == '') {
			return null;
		} else if (ssn.length !== 4 && ssn.length !== 11) {
			return 'invalidSSNLength';
		} else if (ssn.length === 4) {
			return (/^(?!0000)[0-9]{4}$/.test(removeMaskSpace(ssn))) ? null : 'invalidSSN';
		} else {
			return (/^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/.test(removeMaskSpace(ssn))) ? null : 'invalidSSN';
		}
	},

	genderValidator: (input: DropdownOption) => {
		if (!input) return 'invalidGender';

		const { value, label } = input;

		if (!value.length || !label.length ) return 'invalidGender';

		return null;
	},

	languageValidator: (value: string) => {
		if (value == '') {
			return null;
		}

		return (!/(^[a-z])((?![ .,'-]$)[a-z .,'-]){0,45}$/i.test(value)) ? 'invalidLanguage' : null;
	},

	alphanumericValidator: (value: string) => {
		if (value == '') {
			return null;
		}
		return (/^[a-zA-Z0-9]*$/.test(value)) ? null : 'invalidAlphanumeric';
	},
	alphanumericValidatorWithSpaces: (value: string) => {
		if (value == '') {
			return null;
		}
		return (/^[a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$/.test(value)) ? null : 'invalidAlphanumericWithSpaces';
	},
	numericValidator: (value: string) => {
		if (!value) {
			return null;
		}
		return (/^[0-9]*$/.test(value)) ? null : 'invalidNumeric';
	},
	cptValidator: (value: string, listOfCptCodes: Array<string>) => {
		if (value == '' && listOfCptCodes.length < 1) {
			return false;
		} else if (value != '' && value.replace(/\s+/g, '').length !== 5) {
			return false;
		} else {
			return null;
		}
	},
	arrayHasValue: (value: Array<string>) => {
		if (value.length < 1) {
			return false;
		} else {
			return null;
		}
	},
	dropdownRequiredValidator: (dropdownOption: DropdownOption) =>
		(
			!dropdownOption ||
			dropdownOption.value === null ||
			dropdownOption.value === undefined ||
			dropdownOption.value === ''
		) ? 'invalidDropdown' : null,
	pagesIndexValidator: (value: string) => {
		const regex = new RegExp(/^[0-9,\s-]+$/);
		return regex.test(value) ? null : false;
	},
	zipCodeValidator: (value: string) => {
		if (!value) {
			return null;
		}
		return (/^\d{5}$/.test(value)) ? null : 'invalidZipCode';
	},
};
