import React from 'react';
import { useAsyncCollection } from '@utilities/hooks/useAsyncCollection/useAsyncCollection';
import { DescriptiveError } from '@utilities/DescriptiveError';

/**
 * useAsyncCollectionEntity hook
 * @description
 * This hook is used to grab a specific entity from the cache using a particular key similar to useCollectionEntity.
 *
 *
 */
export const useAsyncCollectionEntity = <EntityType extends Record<never, never>>(
	collectionEndpoint: string,
	key: string,
	keyProp: keyof EntityType = 'id' as keyof EntityType
) => {
	const collectionPromise = useAsyncCollection<EntityType>(collectionEndpoint);

	return React.useMemo(() => collectionPromise.then((collection) => {
		const entity = collection.find((entity) => entity[keyProp] === key);
		if (!entity) {
			throw new DescriptiveError(`Entity with {'${String(keyProp)}': '${key}', ...} not found in collection.`, 'ENTITY_NOT_FOUND_IN_COLLECTION');
		}
		return entity;
	}), [collectionPromise, key, keyProp]);
};
