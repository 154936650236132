const endpointTemplateToUrl = (templateString: string, parameters: Record<string, string> = {}, defaultValue?: string) => {
	const templateStringParams = templateString.split('{').map(l => l.split('}'));
	const [baseUrl] = templateStringParams[0];
	templateStringParams.shift();
	if (defaultValue === undefined) { // validate all parameters provided
		templateStringParams.forEach(([param]) => {
			if (!(param in parameters)) {
				throw new Error(`endpointTemplateToUrl(templateString, parameters, defaultValue) missing parameter: '${param}'. Include in parameters object or provide a default value`);
			}
		});
	}

	return baseUrl + templateStringParams.map(([param, urlPart]) => (param in parameters ? parameters[param] : defaultValue) + urlPart).join('');
};
export default endpointTemplateToUrl;
