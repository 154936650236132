import { StartEndEvent } from '@calendar/types';
import { combineConsecutiveEvents } from '@calendar';

/***
 * getInverseEvents to get the events between the provided list of events
 *
 * @param events {StartEndEvent[]} Unordered list of events.
 *
 * @returns {StartEndEvent<Date>} Ordered list of events that fill gaps between provided events. Returns empty array []
 *  if no gaps found.
 *
 * @example
 * getInverseEvents([
 *  {
 *      start: new Date('2021-05-04T07:00:00Z'),
 *      end: new Date('2021-05-04T08:00:00Z')
 *  },
 *  {
 *      start: new Date('2021-05-04T09:00:00Z'),
 *      end: new Date('2021-05-04T10:00:00Z')
 *  }
 * ])
 * // returns
 * [
 *  {
 *      start: new Date('2021-05-04T08:00:00Z'),
 *      end: new Date('2021-05-04T09:00:00Z')
 *  }
 * ]
 *
 * @example
 * getInverseEvents([
 *  {
 *      start: new Date('2021-05-04T07:00:00Z'),
 *      end: new Date('2021-05-04T08:00:00Z')
 *  }
 * ])
 * // returns
 * []
 *
 * @example
 * getInverseEvents([
 *  {
 *      start: new Date('2021-05-04T07:00:00Z'),
 *      end: new Date('2021-05-04T08:00:00Z')
 *  },
 *  {
 *      start: new Date('2021-05-04T08:00:00Z'),
 *      end: new Date('2021-05-04T10:00:00Z')
 *  },
 *  {
 *      start: new Date('2021-05-04T12:00:00Z'),
 *      end: new Date('2021-05-04T13:00:00Z')
 *  },
 * ])
 * // returns
 * [
 *  {
 *      start: new Date('2021-05-04T10:00:00Z'),
 *      end: new Date('2021-05-04T12:00:00Z')
 *  },
 * ]
 */
const getInverseEvents = (events: StartEndEvent[]): StartEndEvent<Date>[] => {
	events = combineConsecutiveEvents(events);
	const hasMultipleEvents = events.length > 1;
	if (!hasMultipleEvents) { return []; }

	const startEnd: Record<number, StartEndEvent> = {};

	events.forEach((e, i) => {
		const isFirst = i === 0;
		if (!isFirst) {
			startEnd[new Date(e.start).getTime()] = e;
		}

		const isLast = i === events.length - 1;
		if (!isLast) {
			startEnd[new Date(e.end).getTime()] = e;
		}
	});

	const inverseEvents: StartEndEvent<Date>[] = [];

	const eventStartEnds = Object.keys(startEnd);

	// Each loop iterates by 2
	for (let i = 0; i < eventStartEnds.length; i++) { // increase i by 1
		inverseEvents.push({
			start: new Date(parseInt(eventStartEnds[i])),
			end: new Date(parseInt(eventStartEnds[++i])), // increase i by 1
		});
	}

	return inverseEvents;
};

export default getInverseEvents;
