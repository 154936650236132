import { RegisterOptions } from 'react-hook-form';
import { MyFiltersState } from '@interfaces/MyFilters/MyFiltersState';

function errorWhenNull<T>(message: string): RegisterOptions {
	return {
		validate: {
			mustSelectOne: (val: T | null) => !!val || message,
		}
	};
}

const MyFiltersValidation: Record<keyof MyFiltersState, RegisterOptions> = {
	unit: errorWhenNull('You must select at least one unit.'),
	specialty: errorWhenNull('You must select at least one specialty.'),
	surgeon: errorWhenNull('You must select at least one surgeon.'),
	state: errorWhenNull('You must select at least one filter.'),
	status: errorWhenNull('You must select at least one filter.'),
	sortModel: {},
	procedureDate: {},
	from: {},
	to: {},
};

export default MyFiltersValidation;
