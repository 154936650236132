import React from 'react';

import { Redirect } from 'react-router-dom';
import useHasUnitWithOpenTime from '@components/SchedulePage/utilities/useHasUnitWithOpenTime/useHasUnitWithOpenTime';
import useHasPermission from '@utilities/hooks/useHasPermission/useHasPermission';
import { PermittedAction } from '@utilities/PermittedAction';
import { ROUTE_CONSTANTS } from '@utilities/RouteConstants';

export function HomePage() {
	const { isLoading, data: hasUnitWithOpenTime } = useHasUnitWithOpenTime();
	const hasPermissionRole = useHasPermission(
		PermittedAction.VIEW_NAVIGATION_OPTIONS,
	);

	if (isLoading) {
		return null;
	}

	const shouldSeeSchedule = hasUnitWithOpenTime && hasPermissionRole;
	if (shouldSeeSchedule) return <Redirect to={ROUTE_CONSTANTS.SCHEDULING} />;
	return <Redirect to={ROUTE_CONSTANTS.CASE_LIST} />;
}
