import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles, Grid, GridProps } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles(theme => createStyles({
	root: {
		color: theme.palette.common.white,
	},
}));

const CalendarErrorContainer: React.FC<GridProps> = ({ children, className, ...props }) => {
	const classes = useStyles();

	return (
		<Grid
			container
			alignItems="center"
			direction="column"
			justifyContent="space-evenly"
			className={classNames(classes.root, className)}
			{...props}
		>
			{children}
		</Grid>
	);
};

export default CalendarErrorContainer;
