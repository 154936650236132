import React, { useRef } from 'react';
import { cleanUpTimer } from '../../timerUtilities';

export const useDebounceCallBack = <A extends unknown[]>(
	callback: (...args: A) => void,
	wait: number
) => {
	const timeout = useRef<ReturnType<typeof setTimeout>>();

	React.useEffect(() => () => cleanUpTimer(timeout.current), []);

	return React.useCallback(( ...args: A ) => {
		cleanUpTimer(timeout.current);
		timeout.current = setTimeout(() => {
			callback(...args);
		}, wait);
	}, [timeout, callback, wait]);
};
