import React from 'react';
import classNames from 'classnames';
import { Box, BoxProps, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LastUpdateMins } from '@components/SchedulePage/LastUpdate/LastUpdateMins/LastUpdateMins';

const useStyles = makeStyles(theme => createStyles({
	lastUpdatedMins: {
		marginLeft: '0.25rem',
		marginRight: '0.25rem',
	},
	root: {
		alignItems: 'center',
		display: 'inline-flex',
		letterSpacing: '1.25px',
		lineHeight: 1.5,
	},
	textStyle: {
		color: theme.palette.grey[900],
		fontSize: '0.75rem',
		fontWeight: 600,
		textTransform: 'uppercase',
	}
}));

interface LastUpdateProps extends BoxProps {
	lastUpdate: string;
}

const LastUpdateMessage = ({ className, lastUpdate }: LastUpdateProps) => {
	const classes = useStyles();

	return (
		<Box className={classNames(classes.root, classes.textStyle, className)}>
			Last Updated
			<LastUpdateMins className={classNames(classes.lastUpdatedMins, classes.textStyle)} lastUpdateTime={lastUpdate} />
			minutes ago
		</Box>
	);
};

export default LastUpdateMessage;
