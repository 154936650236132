import useGetSlots from '@data/schedules/hooks/useGetSlots';
import useSlotRequestParams
	from '@components/RequestForm/utilities/hooks/useCheckBlockTimeOverlap/useSlotRequestParams/useSlotRequestParams';
import SlotResponse from '@data/schedules/types/SlotResponse';
import { AxiosResponse } from 'axios';
import { getDateFromApiDateTime } from '@calendar';
import React from 'react';
import { useRequestFormContext } from '@components/RequestForm/RequestForm';
import { useFormContext } from 'react-hook-form';
import { RequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import { useCheckBlockTimeOverlap } from '@components/RequestForm/utilities/hooks/useCheckBlockTimeOverlap/useCheckBlockTimeOverlap';
import { useAsyncFirstValidValue } from '@utilities/hooks/useAsyncFirstValidValue/useAsyncFirstValidValue';

export const useEditBlockOverlapValidation = () => {
	const { isEdit } = useRequestFormContext();
	const { watch } = useFormContext<RequestFormValidatedState>();

	// Initial params - since ref not updated then this will always be the initial values for the edited case
	const initialParamsRef = React.useRef(useSlotRequestParams(false));
	const getSlots = useGetSlots();

	const [ appointmentBlockTime ] = watch(['appointmentBlockTime']);
	const appointmentBlockTimeUntilValidPromise = useAsyncFirstValidValue(appointmentBlockTime, undefined);

	const [initialReleasedSlots, setInitialReleaseSlots] = React.useState<SlotResponse[]>();
	React.useEffect(() => {
		// Get released slots only when in edit mode
		if (!isEdit) { return; }

		void Promise.all([
			initialParamsRef.current,
			appointmentBlockTimeUntilValidPromise
		]).then(([
			initialParams,
			appointmentBlockTimeVal
		]) => {
			// Get released slots only when already scheduled in block time
			if (!appointmentBlockTimeVal) { return; }
			// Return if initialParamsRef is not set
			if (!initialParams) { return; }

			const onSuccess = (response: AxiosResponse<SlotResponse[]>) => {
				setInitialReleaseSlots(response.data
					// Filter to only slots that are released (other slots accounted for in the useRequestFormSlots hook)
					.filter(slot => !!slot.releaseDate && getDateFromApiDateTime(slot.releaseDate) < new Date())
				);
			};

			const onFailure = () => {
				// Failure - then just don't use the released slots, rest of algorithm will handle other scenarios
				setInitialReleaseSlots([]);
			};

			getSlots({
				data: initialParams,
				onSuccess,
				onFailure,
			});
		});
	}, [appointmentBlockTimeUntilValidPromise, getSlots, isEdit]);

	return useCheckBlockTimeOverlap(initialReleasedSlots);
};
