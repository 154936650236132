import { LinkMenuOption } from '@components/LinkMenu/LinkMenuOption/LinkMenuOption';
import { makeStyles, Menu } from '@material-ui/core';
import React from 'react';
import classNames from 'classnames';
import { LinkMenuProps } from '@components/LinkMenu/LinkMenuProps';
import { useAnchorContext } from '@components/AnchorProvider/AnchorProvider';

const useStyles = makeStyles((theme) => ({
	menu: {
		'& .MuiMenu-paper': {
			borderRadius: '8px',
			border: `1px solid ${theme.palette.grey[600]}`,
			width: '186px',
			overflow: 'visible',
		},
		'& .MuiMenu-paper > ul': {
			'padding': 0,
			'& .MuiSvgIcon-root': {
				height: '24px',
				marginRight: theme.spacing(1),
				color: theme.palette.grey[700]
			},
		},
		'& li': {
			'padding': theme.spacing(1.5, 2),
			'&:not(li:last-child)': {
				borderRadius: '6px 6px 0 0',
			},
		},
	},
}));

type MenuComponentProps = Pick<LinkMenuProps, 'options'> & { className?: string };

export const MenuComponent: React.FC<MenuComponentProps> = ({ options, className }) => {
	const classes = useStyles();
	const { handleClearAnchorEl, anchorEl } = useAnchorContext();

	const sortedOptions = React.useMemo(() => {
		return options.sort(({ order: orderA }, { order: orderB }) => {
			if (orderA > orderB) {
				return 1;
			} else if (orderA < orderB) {
				return -1;
			} else {
				return 0;
			}
		}).map(({ id, ...optionProps }, index) =>
			<LinkMenuOption
				key={id}
				id={id}
				autoFocus={index === 0}
				{...optionProps}
			/>
		);
	}, [options]);

	return (
		<Menu
			id="LinkMenu-menu"
			open={!!anchorEl}
			anchorEl={anchorEl}
			onClose={handleClearAnchorEl}
			className={classNames(classes.menu, className)}
			getContentAnchorEl={null}
			transformOrigin={{
				vertical: -8,
				horizontal: 'center',
			}}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
		>
			{sortedOptions}
		</Menu>
	);
};
