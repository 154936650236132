import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	title: {
		fontWeight: 600,
		whiteSpace: 'nowrap',
	},
	subTitle: {
		whiteSpace: 'nowrap',
	},
});

interface EventCardContentProps {
	title?: string;
	subTitle?: string;
	timeSpan?: string;
}

const EventCardContent: React.FC<EventCardContentProps> = ({ title, subTitle, timeSpan }) => {

	const classes = useStyles();

	return (
		<>
			<Typography variant="body2" component="h4" className={classes.title}>{title}</Typography>
			<Typography variant="caption" component="p" className={classes.subTitle}>{subTitle}</Typography>
			<Typography variant="caption" component="p" className={classes.subTitle}>{timeSpan}</Typography>
		</>
	);
};

export default EventCardContent;
