import React from 'react';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ColumnHeaderProps from '@components/SchedulePage/types/ColumnHeaderProps';
import BlockReleasedColumnHeader from '@components/SchedulePage/BlockReleasedColumnHeader/BlockReleasedColumnHeader';
import BlockUnreleasedColumnHeader
	from '@components/SchedulePage/BlockUnreleasedColumnHeader/BlockUnreleasedColumnHeader';

const useStyles = makeStyles( createStyles({
	blockText: {
		fontWeight: 600,
	},
}));

const BlockColumnHeader: React.FC<ColumnHeaderProps> = ({ blockInfo }) => {
	const classes = useStyles();

	//Can't simply test for truthy since 0 is valid
	const showUtilization = Number.isFinite(blockInfo.utilization)
		&& blockInfo.utilization >= 0
		&& blockInfo.utilization <= 100;

	const BlockColumnHeaderComponent = blockInfo.isReleased ? BlockReleasedColumnHeader : BlockUnreleasedColumnHeader;

	return (
		<BlockColumnHeaderComponent blockInfo={blockInfo}>
			{showUtilization &&
				<Typography variant="body2" component="h3" className={classes.blockText}>
					{blockInfo.utilization.toFixed() + '% utilization'}
				</Typography>
			}
		</BlockColumnHeaderComponent>
	);
};

export default BlockColumnHeader;
