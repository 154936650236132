import { Theme } from '@material-ui/core/styles/createTheme';

export enum AbbreviatedProcedureCardLayout {
	TEMPLATE,
	PROCEDURE
}

export enum AbbreviatedProcedureCardArea {
	TITLE = 'title',
	SURGEON = 'surgeon',
	TAGS = 'tags',
	MENU = 'menu',
	PROCEDURE = 'procedure',
	ANESTHESIA = 'anesthesia',
	COMMENTS = 'comments',
	LAST_USED = 'last_used',
	LOCKED_STATUS = 'locked_status',
}

interface LayoutSettings extends Partial<CSSStyleDeclaration> {
	gridTemplateAreas: string;
	gridTemplateColumns: string;
	gridTemplateRows: string;
	titleProperty: string;
}

type Layouts = {
	[layout in AbbreviatedProcedureCardLayout]: (theme: Theme) => LayoutSettings;
};

export const AbbreviateProcedureCardLayoutSettings: Layouts = {
	/******* TEMPLATE TILE
+----------------+-----------------+-------------------------------+---------------+
| Template Name  |                 |   Status Tags                 | Menu          |
+----------------+-----------------+-------------------------------+---------------+
| Surgeon Name   |                 |                               | Locked Status |
+----------------+-----------------+-------------------------------+---------------+
| Procedure Name |                 |                               |               |
+----------------+-----------------+-------------------------------+---------------+
| Anesthesia Type   | Additional Procedure Comments                  | Last Used   |
+----------------+-----------------+-------------------------------+---------------+
	 */
	[AbbreviatedProcedureCardLayout.TEMPLATE]: (theme) => ({
		titleProperty: 'name',
		gridTemplateAreas: `
			"${AbbreviatedProcedureCardArea.TITLE} ${AbbreviatedProcedureCardArea.TITLE} ${AbbreviatedProcedureCardArea.TITLE} ${AbbreviatedProcedureCardArea.TITLE} ${AbbreviatedProcedureCardArea.TAGS} ${AbbreviatedProcedureCardArea.MENU}"
			"${AbbreviatedProcedureCardArea.SURGEON} ${AbbreviatedProcedureCardArea.SURGEON} ${AbbreviatedProcedureCardArea.SURGEON} ${AbbreviatedProcedureCardArea.SURGEON} ${AbbreviatedProcedureCardArea.LOCKED_STATUS} ${AbbreviatedProcedureCardArea.LOCKED_STATUS}"
			"${AbbreviatedProcedureCardArea.PROCEDURE} ${AbbreviatedProcedureCardArea.PROCEDURE} ${AbbreviatedProcedureCardArea.PROCEDURE} ${AbbreviatedProcedureCardArea.PROCEDURE} ${AbbreviatedProcedureCardArea.LOCKED_STATUS} ${AbbreviatedProcedureCardArea.LOCKED_STATUS}"
			". . . . . ."
			"${AbbreviatedProcedureCardArea.ANESTHESIA} ${AbbreviatedProcedureCardArea.ANESTHESIA} ${AbbreviatedProcedureCardArea.COMMENTS} ${AbbreviatedProcedureCardArea.COMMENTS} ${AbbreviatedProcedureCardArea.COMMENTS} ${AbbreviatedProcedureCardArea.LAST_USED}"
		`,
		gridTemplateColumns: '3fr 1fr 1fr 3fr 3fr 1fr',
		gridTemplateRows: `repeat(5, minmax(${theme.spacing(3)}px, max-content))`,
		gridColumnGap: `${theme.spacing(5)}px`,
	}),
	/******* PROCEDURE TILE
+----------------+-----------------+-------------------------------+---------------+
| Procedure Name |                 |                               | Menu          |
+----------------+-----------------+-------------------------------+---------------+
| Surgeon Name   |                 |                               |               |
+----------------+-----------------+-------------------------------+---------------+
|                |                 |                               |               |
+----------------+-----------------+-------------------------------+---------------+
| Anesthesia Type   | Additional Procedure Comments                                |
+----------------+-----------------+-------------------------------+---------------+
	 */
	[AbbreviatedProcedureCardLayout.PROCEDURE]: (theme) => ({
		titleProperty: 'procedureName',
		gridTemplateAreas: `
			"${AbbreviatedProcedureCardArea.TITLE} ${AbbreviatedProcedureCardArea.TITLE} ${AbbreviatedProcedureCardArea.TITLE} ${AbbreviatedProcedureCardArea.TITLE} ${AbbreviatedProcedureCardArea.MENU}"
			"${AbbreviatedProcedureCardArea.SURGEON} ${AbbreviatedProcedureCardArea.SURGEON} ${AbbreviatedProcedureCardArea.SURGEON} ${AbbreviatedProcedureCardArea.SURGEON} ${AbbreviatedProcedureCardArea.SURGEON}"
			". . . . ."
			"${AbbreviatedProcedureCardArea.ANESTHESIA} ${AbbreviatedProcedureCardArea.ANESTHESIA} ${AbbreviatedProcedureCardArea.COMMENTS} ${AbbreviatedProcedureCardArea.COMMENTS} ${AbbreviatedProcedureCardArea.COMMENTS}"
		`,
		gridTemplateColumns: 'repeat(5, 1fr)',
		gridTemplateRows: `repeat(4, minmax(${theme.spacing(3)}px, max-content))`,
		gridColumnGap: `${theme.spacing(5)}px`,
	}),
};
