import { Reducer } from 'redux';
import { POPULATE_TEMPLATE_LIST, POPULATE_TEMPLATE_LIST_ERROR, REPLACE_TEMPLATE_IN_LIST } from '../actions/types';
import { TemplateListActions, TemplateListState } from '../../interfaces/TemplateListTypes';

const initialState = {
	templateList: [],
	totalCount:0,
	error: null
};

export const TemplateListReducer: Reducer<TemplateListState, TemplateListActions>
	= ( state = initialState, action: TemplateListActions) => {
		switch (action.type) {
		case POPULATE_TEMPLATE_LIST:
			return {
				...state,
				templateList: [...action.payload.data],
				totalCount: action.payload.totalCount,
				error: null
			};
		case POPULATE_TEMPLATE_LIST_ERROR:
			return { ...state, error: action.error };
		case REPLACE_TEMPLATE_IN_LIST: {
			const foundTemplateIndex = state.templateList.findIndex(t => t.id === action.payload.id);
			if (foundTemplateIndex === -1) { return state; }

			const updatedList = [ ...state.templateList ];
			updatedList.splice(foundTemplateIndex, 1, action.payload);

			return {
				...state,
				templateList: updatedList,
			};
		}
		default:
			return state;
		}
	};
