import React from 'react';
import { useSelector } from 'react-redux';
import { shouldShowGlobalLoaderSelector } from '@store/selectors/ApiStatusSelector';
import { BoxProps } from '@material-ui/core';
import Loader from '@components/Loaders/Loader/Loader';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles({
	loader: {
		position: 'fixed',
	}
});

const GlobalLoader: React.FC<BoxProps> = ({ className, ...props }) => {
	const classes = useStyles();

	const spinnerStart = useSelector(shouldShowGlobalLoaderSelector);

	if (!spinnerStart) { return null; }
	return <Loader className={classNames(classes.loader, className)} {...props} />;
};

export default GlobalLoader;
