import { AUTHENTICATION_ERROR, AUTHENTICATION_LOADING, LOGOUT, REQUIRE_AUTHENTICATION, SET_TOKEN } from './types';

export const requireAuthentication = () => {
	return {
		type: REQUIRE_AUTHENTICATION,
	};
};

export const setAuthenticationLoading = () => {
	return {
		type: AUTHENTICATION_LOADING
	};
};

export const logout = () => {
	return {
		type: LOGOUT,
	};
};

export const setToken = (token: string) => {
	return {
		type: SET_TOKEN,
		payload: token,
	};
};

export const setAuthError = (error: string, message: string) => {
	return {
		type: AUTHENTICATION_ERROR,
		payload: {
			error: error,
			message: message,
		},
	};
};

