import React from 'react';
import { UserAccessEnum } from '@interfaces/SurgeryRequest/UserAccessEnum';
import { SurgeryRequestAccessor } from '@interfaces/SurgeryRequest/SurgeryRequestAccessor';
import { useSelector } from 'react-redux';
import { RootState } from '@interfaces/rootState';
import { CaseDetailsState } from '@interfaces/CaseDetails/CaseDetailsState';
import { CaseDetailsSelector } from '@store/selectors/CaseDetailsSelector';
import { UserProfileDetailsSelector } from '@store/selectors/UserProfileSelector';
import { PermittedAction } from '@utilities/PermittedAction';
import useHasPermission from '@utilities/hooks/useHasPermission/useHasPermission';

const useGetAccessor = (accessTypes: UserAccessEnum[], checkForPermission: PermittedAction = PermittedAction.CONFIRM_CASE_SCHEDULE) => {
	const { details: { accessors } = {} } = useSelector<RootState, CaseDetailsState>(CaseDetailsSelector);
	const { userId: userAccessorId, firstName, lastName, access: currentAccessType } = React.useMemo((): Partial<SurgeryRequestAccessor> => {
		return accessors?.find((accessor) => accessTypes.includes(accessor.access)) ?? {};
	}, [accessors, accessTypes]);

	const { userId: userProfileId } = useSelector(UserProfileDetailsSelector);

	const isAccessorDifferentUser = !!userAccessorId && userAccessorId !== userProfileId;
	const name = firstName && lastName ? `${firstName} ${lastName}` : null;

	const hasPermission = useHasPermission(checkForPermission);

	return {
		hasPermission,
		currentAccessType,
		isAccessorDifferentUser,
		blockerName: hasPermission && isAccessorDifferentUser ? name : null,
	};
};

export default useGetAccessor;
