import {
	ADD_START_TIME,
	CLEAR_FORM_ERROR,
	REQUEST_FORM_POST,
	REQUEST_FORM_UPDATE,
	RESET_FORM,
	SAVE_FORM_ERROR,
	SAVE_FORM_SUCCESS,
} from '@store/actions/types';
import { ApiActionRequest, CaseTrackerThunkAction, CaseTrackerThunkDispatch } from '@interfaces/ApiAction/ApiAction';
import { apiAction } from '@store/middlewares/api/ApiActionCreators';
import { RequestFormAction } from '@interfaces/RequestForm/RequestFormAction';
import { RequestValidatedToApi } from '@utilities/typeConverters/RequestValidatedToApi';
import { RequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import { ApiActionPayload, ApiResponseType } from '@interfaces/ApiAction/ApiActionPayload';
import { SURGERY_API_ENDPOINT } from '@utilities/apiConstants';
import { AxiosError, AxiosResponse } from 'axios';
import errorToString from '@utilities/errorToString';
import { SurgeryRequest } from '@interfaces/SurgeryRequest/SurgeryRequest';
import { updateCaseDetails } from '@store/actions/CaseDetailsActionCreators';
import { SurgeryRequestGet } from '@interfaces/SurgeryRequest/SurgeryRequestGet';
import { Dispatch } from 'redux';
import SupplementalRequestInfo from '@data/request/SupplementalRequestInfo';
import convertPayloadToMultipart from '@utilities/typeConverters/convertPayloadToMultipart';
import DocumentRequestFormType from '@interfaces/RequestForm/forms/DocumentRequestFormType';
import { ErrorStatusState } from '@interfaces/ErrorStatus/ErrorStatusState';

const saveFormSuccess = ({ data }: ApiResponseType<SurgeryRequest>): RequestFormAction => {
	return {
		type: SAVE_FORM_SUCCESS,
		data: data,
	};
};

export const saveFormError = (error: AxiosError<unknown> | string): RequestFormAction => {
	let errorMessage: string | null;
	if (typeof error !== 'string' && error.response && (error.response as AxiosResponse<string>).data) {
		errorMessage = Array.isArray((error.response as AxiosResponse<string>).data) ? (error.response as AxiosResponse<string>).data[0] : (error.response as AxiosResponse<string>).data;
	} else {
		errorMessage = errorToString(error);
	}
	return {
		type: SAVE_FORM_ERROR,
		error: errorMessage,
	};
};

export const clearFormError = (): RequestFormAction => {
	return {
		type: CLEAR_FORM_ERROR
	};
};

export const saveForm = ({
	onSuccess: onSuccessCallback,
	onFailure: onFailureCallback,
	...extended
}: Partial<ApiActionPayload<FormData, ErrorStatusState, SurgeryRequestGet>> = {}) => {
	const onSuccess = (response: ApiResponseType<SurgeryRequestGet>): CaseTrackerThunkAction => {
		return (dispatch: CaseTrackerThunkDispatch): void => {
			dispatch(saveFormSuccess(response));
			if (onSuccessCallback) {
				onSuccessCallback(response);
			}
		};
	};

	const onFailure = (error: AxiosError<ErrorStatusState>): CaseTrackerThunkAction => {
		return (dispatch: CaseTrackerThunkDispatch): void => {
			dispatch(saveFormError(error));
			if (onFailureCallback) {
				onFailureCallback(error);
			}
		};
	};

	return (saveData: RequestFormValidatedState | DocumentRequestFormType, supplementalRequestInfo: SupplementalRequestInfo): ApiActionRequest<FormData, ErrorStatusState, SurgeryRequestGet> => {
		const payload = RequestValidatedToApi(saveData, supplementalRequestInfo);
		return apiAction<FormData, ErrorStatusState, SurgeryRequestGet>({
			url: SURGERY_API_ENDPOINT,
			method: 'POST',
			data: convertPayloadToMultipart(payload),
			label: REQUEST_FORM_POST,
			includeAccessToken: true,
			...extended,
			onSuccess: onSuccess,
			onFailure: onFailure,
		});
	};
};

export const updateForm = ({
	onSuccess: successCallback,
	onFailure: failureCallback,
	...extended
}: Partial<ApiActionPayload<FormData, ErrorStatusState, SurgeryRequestGet>> = {}) => {
	const onSuccess = (response: ApiResponseType<SurgeryRequestGet>) => {
		return (dispatch: Dispatch) =>{
			dispatch(updateCaseDetails(response));
			if (successCallback) {
				successCallback(response);
			}
		};
	};

	const onFailure = (error: AxiosError<ErrorStatusState>) => {
		return (dispatch: Dispatch) => {
			dispatch(saveFormError(error));
			if (failureCallback) {
				failureCallback(error);
			}
		};
	};

	return (saveData: RequestFormValidatedState | DocumentRequestFormType, supplementalRequestInfo: SupplementalRequestInfo, originalDetails: SurgeryRequestGet): ApiActionRequest<FormData, ErrorStatusState, SurgeryRequestGet> => {
		const { id: requestId } = originalDetails;
		const payload = RequestValidatedToApi(saveData, supplementalRequestInfo, originalDetails);
		return apiAction<FormData, ErrorStatusState, SurgeryRequestGet>({
			url: SURGERY_API_ENDPOINT + requestId,
			method: 'PUT',
			data: convertPayloadToMultipart(payload),
			label: REQUEST_FORM_UPDATE,
			includeAccessToken: true,
			...extended,
			onSuccess,
			onFailure,
		});
	};
};

export function resetForm (): RequestFormAction {
	return {
		type: RESET_FORM
	};
}

export function addStartTime (): RequestFormAction {
	return {
		type: ADD_START_TIME,
		payload: new Date()
	};
}
