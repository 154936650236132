import React from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Typography, Box, Button } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import RequestFormSelector from '@store/selectors/RequestFormSelector';
import { RequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import { RequestForm } from '@components/RequestForm/RequestForm';
import { CaseDetailsInfoSelector } from '@store/selectors/CaseDetailsSelector';
import { surgeryRequestApiToFormState } from '@utilities/typeConverters/surgeryRequestApiToFormState';
import usePageEffects from '@utilities/hooks/usePageEffects/usePageEffects';
import { ToastConstants } from '@utilities/toastConstants';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { LEGACY_MAX_WIDTH } from '@theme/themeConstants';
import useUnlockCaseOnUnmount from '@components/CaseDetails/useUnlockCaseOnUnmount/useUnlockCaseOnUnmount';
import { useCaseDetailsPageContext } from '@components/CaseDetails/CaseDetailsContext/CaseDetailsPageContext/CaseDetailsPageContext';
import GoldButton from '@components/GoldButton/GoldButton';
import TextLink from '@components/TextLink/TextLink';
import useSubmitRequest from '@data/request/useSubmitRequest';
import CardSectionTitle from '@components/CardSection/CardSectionTitle/CardSectionTitle';
import CardSection from '@components/CardSection/CardSection';
import { isWebRequest } from '@utilities/typeAssertionUtilities';
import { FormMetaProvider } from '@store/context/FormMetaContext';
import { DocumentSchema } from '@utilities/Validation/validationSchema';
import RequestRevision
	from '@components/RequestForm/RequestRevision/RequestRevision';
import RequestFormPageState from '@components/RequestForm/RequestFormPage.types';
import { NAVIGATION_WARN_MODAL } from '@components/Modal/modalConstants';
import { NavigationWarnModal } from '@components/RequestForm/NavigationWarnModal/NavigationWarnModal';
import { useIsDirtyForm } from '@components/RequestForm/utilities/hooks/useIsDirtyForm/useIsDirtyForm';
import {
	useEditFormNavigationHandlerFunctions
} from '@components/RequestForm/utilities/hooks/useNavigationHandlers/useEditFormNavigationHandlers/useEditFormNavigationHandlers';
import { ROUTE_CONSTANTS } from '@utilities/RouteConstants';
import { ProcedureNameProvider } from '@components/RequestForm/ProcedureName/ProcedureNameContext';
import { FindATimeProvider } from '@components/FindATime/FindATimeContext/FindATimeContextProvider';

const useStyles = makeStyles((theme) => createStyles({
	root: {
		'& .MuiButton-label': {
			fontSize: '.875rem',
		},
		'& .MuiTypography-h1': {
			lineHeight: 1.2,
			fontWeight: 500,
			marginBottom: theme.spacing(3),
		},
	},
	content: {
		width: LEGACY_MAX_WIDTH,
		maxWidth: '100%',
	},
	spaceRight: {
		marginRight: theme.spacing(2),
	},
	backLink: {
		margin: theme.spacing(3, 0),
	},
	buttonGroup: {
		paddingTop: theme.spacing(2),
	},
}));

const useCloseEdit = () => {
	const { showEdit } = useCaseDetailsPageContext();
	return React.useCallback(() => {
		showEdit(false);
	}, [showEdit]);
};

export const EditRequestForm: React.FC = () => {
	const classes = useStyles();
	const { formError } = useSelector(RequestFormSelector);

	const caseInfo = useSelector(CaseDetailsInfoSelector);
	const caseDetailsInfo = React.useMemo(() => surgeryRequestApiToFormState(caseInfo), [caseInfo]);

	let shouldDisplaySecondaryInsurance;
	if (isWebRequest(caseInfo)) {
		shouldDisplaySecondaryInsurance = caseInfo.insurances.length > 1;
	}

	const [showSecondaryInsurance, setShowSecondaryInsurance] = React.useState<boolean>(!!shouldDisplaySecondaryInsurance);

	useUnlockCaseOnUnmount();

	const closeForm = useCloseEdit();

	const history = useHistory<RequestFormPageState>();

	const isSchedulingEdit = history.location.state?.isSchedulingEdit;

	const pushToScheduling = React.useCallback(() => {
		history.push(ROUTE_CONSTANTS.SCHEDULING);
	}, [history]);

	const { save: saveAuthenticatedForm, formId } = useSubmitRequest({
		isNew: false,
		onSuccess: isSchedulingEdit ? pushToScheduling : closeForm,
		successToastMessage: ToastConstants.REQUEST_UPDATE_SUCCESS,
		errorToastMessage: ToastConstants.SAVE_ERROR
	});
	const formMethods = useForm<RequestFormValidatedState>({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		criteriaMode: 'all',
		defaultValues: { ...caseDetailsInfo },
	});

	const {
		formState: {
			errors,
			submitCount
		},
		getValues,
		watch,
	} = formMethods;

	// If this isn't set to denote the edit form modal id, the redirects will not work properly from the edit form.
	const editRequestFormModalId = `${NAVIGATION_WARN_MODAL}-edit`;
	const userError = Object.keys(errors).length > 0 ? 'Please check the page for errors.' : null;
	const serviceError = formError ? 'There was a problem submitting your request. Please try again later. ' + formError : null;
	const showError = submitCount > 0 && (userError || serviceError);
	usePageEffects('Edit Request');
	const [isLoaded, setIsLoaded] = React.useState(false);

	React.useLayoutEffect(() => {
		setIsLoaded(true);
	}, [caseDetailsInfo]);

	const [initialValues, setInitialValues] = React.useState<RequestFormValidatedState>();

	React.useEffect(() => {
		if (isLoaded) {
			setInitialValues(getValues());
		}
	}, [isLoaded, getValues]);

	const currentValues = watch();

	const isDirtyForm = useIsDirtyForm(currentValues, initialValues);

	const { handleConfirmNav, handleCancelNav, handleBackClick } = useEditFormNavigationHandlerFunctions({
		closeForm,
		isDirtyForm,
		editRequestFormModalId,
		pushToScheduling,
		isSchedulingEdit,
	});

	React.useLayoutEffect(() => {
		const scrollToTop = () => window.scrollTo(0, 0);
		scrollToTop();
		return scrollToTop;
	}, []);

	return (
		<FormMetaProvider schema={DocumentSchema} {...formMethods}>
			<ProcedureNameProvider unitId={currentValues.appointmentLocation} allowCustom={true}>
				<FindATimeProvider>
					<Box
						display="flex"
						alignItems="center"
						flexDirection="column"
						className={classes.root}
					>
						<Box className={classes.content}>
							<TextLink
								onClick={handleBackClick}
								prefixIcon={ChevronLeft}
								className={classes.backLink}
							>
								Back
							</TextLink>
							<Typography variant="h1">Edit surgery request</Typography>
							{isLoaded && <>
								<RequestForm
									formId={formId}
									showSecondaryInsurance={showSecondaryInsurance}
									setShowSecondaryInsurance={setShowSecondaryInsurance}
									isEdit={true}
								>
									<CardSection sectionTitle={<CardSectionTitle title="Request Revision" />}>
										<RequestRevision />
									</CardSection>
								</RequestForm>
							</>}
							<Box
								display="flex"
								data-field="submit-request"
								justifyContent="flex-end"
								className={classes.buttonGroup}
							>
								{showError && <ErrorMessage errorField={{ message: (userError || serviceError) as string }}/>}
								<Button
									variant="outlined"
									onClick={handleBackClick}
									color="primary"
									className={classes.spaceRight}
								>
									Cancel
								</Button>
								<GoldButton
									type="submit"
									id="request-form-save"
									variant="contained"
									onClick={formMethods.handleSubmit(saveAuthenticatedForm)}
								>
									Submit Revision
								</GoldButton>
							</Box>
						</Box>
					</Box>
					<NavigationWarnModal
						id={editRequestFormModalId}
						onCancel={handleCancelNav}
						onClose={handleCancelNav}
						onConfirm={handleConfirmNav}
					/>
				</FindATimeProvider>
			</ProcedureNameProvider>
		</FormMetaProvider>
	);
};
