import { AxiosError } from 'axios';
import { ErrorStatusState } from '@interfaces/ErrorStatus/ErrorStatusState';

/**
 * @deprecated use shouldDisableErrorHandler callback in the API payload to statically or dynamically disable default error modal.
 */
const errorMessageExceptions = [
	'Duplicate surgery request', // 500/422 Same date, time, surgeon
	'already exist in the Practice', // 422 Duplicate template name
	'No primary procedure found', // 500/422 No primary procedure selected
	'is locked and can\'t be deleted.', // 500 Delete locked template
	'Template doesn\'t exist' // 404 Delete already deleted template
];

/**
 * Determine if error should not be handled by universal modal
 * @param {string} message - message from JSON object returned by API. Defaults to empty message in case API fails to return JSON.
 * @returns {boolean} true when message includes text from exception list; otherwise false
 * @deprecated use shouldDisableErrorHandler callback in the API payload to statically or dynamically disable default error modal.
 */
export const isApiErrorException = (message = ''): boolean => {
	return errorMessageExceptions.some(exception => message && message.includes(exception));
};

/*
 * shouldDisableErrorHandler should be used going forward. This method is used to make the old logic work with the updated shouldDisableErrorHandler method.
 * @deprecated use shouldDisableErrorHandler callback in the API payload to statically or dynamically disable default error modal.
 */
export const defaultShouldDisableErrorHandler = ({ response }: AxiosError<ErrorStatusState>) => {
	return response && response.data && response.data.message && isApiErrorException(response.data.message);
};
