import React from 'react';
import { FormMetaProvider } from '../../../store/context/FormMetaContext';
import { SaveFiltersProvider } from './SaveFiltersContext/SaveFiltersContext';
import MyFiltersPage from './MyFiltersPage';
import { useForm } from 'react-hook-form';
import { MyFiltersState } from '../../../interfaces/MyFilters/MyFiltersState';
import { MyFiltersDefaultValues } from './MyFiltersDefaultValues';
import MyFiltersValidation from './validation/MyFiltersValidation';

interface MyFiltersFormProps {
	buttonsComponent: React.ReactNode;
}

export default function MyFiltersForm({ buttonsComponent }: MyFiltersFormProps) {
	const formMethods = useForm<MyFiltersState>({
		mode: 'onBlur',
		defaultValues: MyFiltersDefaultValues,
	});

	return (
		<FormMetaProvider schema={MyFiltersValidation} {...formMethods}>
			<SaveFiltersProvider>
				<MyFiltersPage />
				{buttonsComponent}
			</SaveFiltersProvider>
		</FormMetaProvider>
	);
}
