/***
 * roundEventTime - takes time and interval and returns rounded time by interval
 *
 * @example
 	roundedEventTime(new Date('2022-11-30 11:37Z'), 15) = new Date('2022-11-30 11:45Z')
 */

const roundEventTime = (time: Date, interval: number): Date => {
	const minutes = time.getMinutes();
	const roundedMinutes = interval - (minutes % interval || interval);

	return new Date(time.getTime() + roundedMinutes * 60000);
};

export default roundEventTime;
