import React from 'react';
import { useDispatch } from 'react-redux';
import { getFindATimeResponse } from '@data/findATime/actionCreators/getFindATimeResponse';
import { FindATimeRequest } from '@data/findATime/types/FindATimeRequest';

export const useGetFindATime = () => {
	const dispatch = useDispatch();

	return React.useCallback((requestOverrides?: FindATimeRequest) => {
		dispatch(getFindATimeResponse(requestOverrides));
	}, [dispatch]);
};
