import React from 'react';
import {
	Box,
	Dropdown,
	Flex,
	Label,
} from '@ascension/web';
import { useDropdownFormContext } from '@utilities/hooks/useDropdownFormContext/useDropdownFormContext';
import { useFieldFormContext } from '@utilities/hooks/useFieldFormContext/useFieldFormContext';
import { useTimeFormContext } from '@utilities/hooks/useTimeFormContext/useTimeFormContext';
import { PreAdmitTestSubformValidation } from './PreAdmitTestSubformValidation';
import PreAdmitTestSubformProps from './PreAdmitTestSubformProps';
import DateInputPicker from '@components/DateInputPicker/DateInputPicker';
import TimePicker from '@components/TimePicker/TimePicker';
import TextFieldInput from '@components/TextFieldInput/TextFieldInput';

const PreAdmitTestSubform = ({ isEdit }: PreAdmitTestSubformProps) => {

	const {
		currentPeriodValue,
		currentTimeValue,
		currentDateValue,
		setPeriodValue,
		setTimeValue,
		setDateValue,
		dateError,
		triggerDateValidation
	} = useTimeFormContext('pat', {
		datePickerValidation: isEdit ? PreAdmitTestSubformValidation.patDateEdit : PreAdmitTestSubformValidation.patDate,
	});

	const {
		selectedDropdownOption: selectedPatLocationOption,
		setSelectedValue: setPatLocationValue,
		dropdownOptions: patLocationOptions,
		error: patLocationError,
	} = useDropdownFormContext(
		'patLocation',
		[
			{
				value: 'PAT',
				label: 'PAT',
			},
			{
				value: 'POMC',
				label: 'POMC',
			},
			{
				value: 'Other',
				label: 'Other',
			},
		]
	);

	const {
		currentValue: patOtherLocation,
		setCurrentValue: setPatOtherLocation,
		error: patOtherLocationError,
	} = useFieldFormContext<string>('patOtherLocation');

	return React.useMemo(() => (<>
		<Box data-field="PAT_LocationLabel" mt={8}>
			<Label>Where is PAT taking place?</Label>
		</Box>
		<Flex>
			<Box data-field="PAT_Location">
				<Dropdown
					placeholder="Select"
					name="patLocation"
					errors={patLocationError}
					options={patLocationOptions}
					selected={selectedPatLocationOption}
					onSelect={setPatLocationValue}
				/>
			</Box>
			{selectedPatLocationOption && selectedPatLocationOption.value === 'Other' &&
				<Box data-field="PAT_OtherLocation" width={[1, .4]} mx={[0, 20]}>
					<TextFieldInput
						name="patOtherLocation"
						fieldName="patOtherLocation"
						placeholder="Enter Location"
						value={patOtherLocation}
						errorMessage={patOtherLocationError}
						onChange={setPatOtherLocation}
					/>
				</Box>
			}
		</Flex>
		<Box data-field="PAT_DateTimeLabel" mt={8}>
			<Label>
				Requested PAT appointment date and time (if known)
			</Label>
		</Box>
		<Flex flexDirection="row">
			<Box data-field="PAT_Date" className="margin-right-16">
				<DateInputPicker
					currentDate={currentDateValue}
					onValidDateChange={setDateValue}
					errorMessage={dateError}
					onBlur={triggerDateValidation}
				/>
			</Box>
			<Box data-field="PAT_Time">
				<TimePicker
					name="patTime"
					disabled={!currentDateValue}
					onTimeChange={setTimeValue}
					onPeriodChange={setPeriodValue}
					value={currentDateValue ? currentTimeValue : ''}
					periodValue={currentPeriodValue}
				/>
			</Box>
		</Flex>
	</>), [
		dateError,
		currentDateValue,
		triggerDateValidation,
		patLocationError,
		patLocationOptions,
		patOtherLocation,
		patOtherLocationError,
		selectedPatLocationOption,
		setDateValue,
		setPatLocationValue,
		setPatOtherLocation,
		currentTimeValue,
		currentPeriodValue,
		setPeriodValue,
		setTimeValue,
	]);
};

export default PreAdmitTestSubform;
