import ApiEndpointDescriptor from './ApiEndpointDescriptor';
import ApiEndpoint from './ApiEndpoint';
import ApiService from './ApiService';
import ApiRestMethod from './ApiRestMethod';

const apiEndpointConfig: Record<ApiEndpoint, ApiEndpointDescriptor> = {
	[ApiEndpoint.USER_REGISTRATION]: {
		service: ApiService.USER,
		path: '/users/{userId}/teams/{teamId}/members',
		methods: [
			ApiRestMethod.POST,
			ApiRestMethod.GET,
		],
	},
	[ApiEndpoint.DEACTIVATE_USER]: {
		service: ApiService.USER,
		path: '/users/{userId}',
		methods: [
			ApiRestMethod.PUT,
		],
	},
	[ApiEndpoint.EDIT_USER_REGISTRATION]: {
		service: ApiService.USER,
		path: '/users/{userId}',
		methods: [
			ApiRestMethod.PUT,
		],
	}
};

export default apiEndpointConfig;
