import React from 'react';
import { getRandomString } from '@utilities/commonFunction';

export type ProviderEventHandler<EventType> = (event?: EventType) => void;
type HandlerId = string;

/***
 * useProviderEventManager<EventType>
 *   Hook should be used by a provider to track a particular event and allow child components/hooks to register a callback
 *   to run when the event is triggered from the parent. Use multiple for separate events.
 *
 * @returns {
 *  registerEventHandler: (handler: ProviderEventHandler<Event>) => (() => void);
 *  runAllHandlers: (e: EventType) => void;
 *  clearAllHandlers: () => void;
 * }
 *   - registerEventHandler {(handler: ProviderEventHandler<Event>) => void} - Should be included as part of context values for children to register custom event handlers
 *      - Returns cleanup function that can be used when unmounting or wish for callback to stop being called.
 *   - runAllHandlers {(e: EventType) => void} - Should be kept to provider. Call this when event is triggered.
 *   - clearAllHandlers {() => void} - May be used if all handlers should be removed.
 *
 * @example
 *
 * const TaskContext = React.createContext<{tasks: Task[]; deleteTask: (id: string) => void; registerDeleteHandler: (handler: ProviderEventHandler<string>) => void}>(null);
 *
 * const TaskProvider = ({ children }) => {
 *     const [tasks, setTasks] = React.useState<Task[]>([]);
 *
 *     const {
 *          registerEventHandler: registerDeleteHandler,
 *          runAllHandlers: runAllDeleteHandlers,
 *          clearAllHandlers: clearAllDeleteHandlers,
 *      } = useProviderEventManager<string>();
 *
 *     const deleteTask = React.useCallback((deleteId: string) => {
 *          setTasks(currentTasks => currentTasks.filter(({ id }) => id !== deleteId);
 *          runAllDeleteHandlers(id);
 *     }, [runAllDelete]);
 *
 *     return <TaskContext.Provider values={tasks, registerDeleteHandler, deleteTask}>
 *          {children}
 *     </TaskContext.Provider>
 * }
 *
 * ---
 *
 * const DeleteNotification = () => {
 *     const { registerDeleteHandler } = useTaskContext();
 *     const [deleteId, setDeleteId] = React.useState()
 *
 *     const showDeleteMessage = React.useCallback((id: string) => {
 *          setDeleteId(id);
 *     }, [setDeleteId]);
 *
 *     React.useEffect(() => {
 *          return registerDeleteHandler(showDeleteMessage);
 *     }, [registerDeleteHandler, showDeleteMessage]);
 *
 *     if (!deleteId) { return null; }
 *
 *     return <Confirm onClick={() => setDeleteId(null)}>Are you sure?</Confirm>
 */
function useProviderEventManager<EventType = undefined>() {
	const [eventHandlers, setEventHandlers] = React.useState<Record<HandlerId, ProviderEventHandler<EventType>>>({});

	const removeEventHandler = React.useCallback((handlerId: string) => {
		setEventHandlers((currentEventHandlers) => {
			const updatedEventHandlers = { ...currentEventHandlers };
			delete updatedEventHandlers[handlerId];
			return updatedEventHandlers;
		});
	}, []);

	const registerEventHandler = React.useCallback((handler: ProviderEventHandler<EventType>) => {
		const handlerId = getRandomString(10);
		setEventHandlers((currentEventHandlers) => ({
			...currentEventHandlers,
			[handlerId]: handler,
		}));
		return () => removeEventHandler(handlerId);
	}, [removeEventHandler]);

	const runAllHandlers = React.useCallback((event: EventType) => {
		Object.values(eventHandlers).forEach(handler => handler(event));
	}, [eventHandlers]);

	const clearAllHandlers = React.useCallback(() => {
		setEventHandlers({});
	}, []);

	return {
		registerEventHandler,
		runAllHandlers,
		clearAllHandlers,
	};
}

export default useProviderEventManager;
