import React from 'react';
import ClickCoordinateBoxContextType from '@components/ClickCoordinateBox/ClickCoordinateBoxContextType';

const ClickCoordinateBoxContext = React.createContext<ClickCoordinateBoxContextType | null>(null);

export const useClickCoordinate = () => {
	const ctx = React.useContext(ClickCoordinateBoxContext);
	if (!ctx) {
		throw new Error('useClickCoordinate should be used within a subcomponent of ClickCoordinateBox provider');
	}
	return ctx;
};

export const ClickCoordinateBox: React.FC = ({ children }) => {
	const [coordinates, setCoordinates] = React.useState<[number, number]>([0, 0]);
	const [boxWidth, setBoxWidth] = React.useState(0);
	const clickBoxRef = React.useRef<HTMLDivElement>(null);

	const handleClick: React.MouseEventHandler = React.useCallback(({ pageX, pageY }) => {
		setBoxWidth(clickBoxRef.current?.offsetWidth ?? 0);
		setCoordinates([pageX, pageY]);
	}, []);

	return <ClickCoordinateBoxContext.Provider value={{
		coordinates,
		boxWidth,
	}}>
		<div
			ref={clickBoxRef}
			onClick={handleClick}
		>
			{children}
		</div>
	</ClickCoordinateBoxContext.Provider>;
};
