import React from 'react';
import { HoldTimeValidationSchema } from '@components/OfficeCalendarView/SelectOpenTimePopover/PreBookEventTimeSpanInput/HoldTimeValidationSchema';
import { useOfficeCalendarViewContext } from '@components/OfficeCalendarView/OfficeCalendarViewContext/OfficeCalendarViewContext';
import SelectOpenTimeFormValues from '@components/OfficeCalendarView/SelectOpenTimeFormValues';
import { useFormContext } from 'react-hook-form';
import { PreBookTimeSpanInputProps } from '@components/OfficeCalendarView/SelectOpenTimePopover/PreBookEventTimeSpanInput/PreBookEventTimeSpanInput';

const fieldName = 'timeSpan';

const useValidateHoldEvent = ({ hasAPIConflictError, setTimeSpanAPIError }: PreBookTimeSpanInputProps) => {
	const { events, selectedRoom, currentHoldEvent, unitConfig: { interval, hospitalTimeZone }, selectedDate } = useOfficeCalendarViewContext();
	const currentHoldEventId = currentHoldEvent?.id;

	const { watch, setError } = useFormContext<SelectOpenTimeFormValues>();
	const [ timeSpan = {} ] = watch([fieldName]);

	const eventsToValidate = React.useMemo(() => {
		return events.filter(e => e.column === selectedRoom && e.id !== currentHoldEventId);
	}, [currentHoldEventId, events, selectedRoom]);

	const completeTimeSpan = React.useMemo(() => {
		return {
			start: timeSpan.start,
			end: timeSpan.end,
			interval: interval,
			overlapEvents: eventsToValidate,
			date: selectedDate,
			timezone: hospitalTimeZone,
		};
	}, [eventsToValidate, hospitalTimeZone, interval, selectedDate, timeSpan.end, timeSpan.start]);

	const triggerTimeSpanValidation = React.useCallback(() => {
		const basicValidationErrors = HoldTimeValidationSchema.timeSpan.validate.checkAll(completeTimeSpan);

		setError(fieldName, { type: 'custom', message: basicValidationErrors === true ? '' : basicValidationErrors });
	}, [completeTimeSpan, setError]);

	React.useEffect(() => {
		// trigger validation for overlapping events when component is mounted
		triggerTimeSpanValidation();
	}, [triggerTimeSpanValidation]);

	React.useEffect(() => {
		// manually set timeSpan field error when we last received 409 conflict response status from API
		if (hasAPIConflictError.current) {
			setTimeSpanAPIError();
		}
	}, [hasAPIConflictError, setTimeSpanAPIError]);

	React.useEffect(() => {
		// manually clear API error anytime room, date, or start/end time change
		return () => {
			hasAPIConflictError.current = false;
		};
	}, [selectedRoom, timeSpan.start, timeSpan.end, hasAPIConflictError]);
};

export default useValidateHoldEvent;
