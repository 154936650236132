import React, { useCallback, useState } from 'react';
import { Flex, Text } from '@ascension/web';
import { CaseDetails } from '../CaseDetails/CaseDetails';
import { useDispatch, useSelector } from 'react-redux';
import { CaseQueueSelector } from '../../store/selectors/CaseQueueSelector';
import { QueueController } from './QueueController';
import { getQueueList } from '../../store/actions/CaseQueueActionCreators';
import { useParams } from 'react-router-dom';
import { DEFAULT_CASES_SORT } from '../../utilities/apiConstants';
import { FilterApiParams } from '../../interfaces/FilterParams';
import { filtersToApiParams } from '../../utilities/commonFunction';
import usePageEffects from '../../utilities/hooks/usePageEffects/usePageEffects';
import { StorageKey } from '../../utilities/authConstants';

export const QueuePage = () => {
	const { currentCaseId } = useSelector(CaseQueueSelector);
	const dispatch = useDispatch();
	const { indexPosition } = useParams<{ indexPosition: string }>();
	const indexPositionNum = Number(indexPosition);
	const queueQuery = sessionStorage.getItem(StorageKey.QUEUE_QUERY) || '';
	const urlParams = new URLSearchParams(queueQuery);
	const [sortModel] = useState(urlParams.get('sortModel') || DEFAULT_CASES_SORT);
	const page = 1;
	const size = 1000000;
	const surgeonParam = urlParams.getAll('surgeon');
	const [surgeonFilter] = useState(surgeonParam);
	const [procedureDateFilter] = useState(urlParams.get('procedureDate') || '');
	const statusParam = urlParams.getAll('status');
	const stateParam = urlParams.getAll('state');
	const [statusFilter] = useState(statusParam);
	const [stateFilter] = useState(stateParam);
	const [from] = useState(urlParams.get('from') || '');
	const [to] = useState(urlParams.get('to') || '');
	const [globalFilter] = useState(urlParams.get('globalFilter') || '');
	const [allTask] = useState(urlParams.get('task') || '');
	const [isLoaded, setIsLoaded] = React.useState<boolean>(false);
	const loadCaseList = useCallback((params?: FilterApiParams) => {
		dispatch(getQueueList(indexPositionNum, params));
	}, [dispatch, indexPositionNum]);

	const renderEmptyCurrentCaseDetail = () => {
		return (
			<>
				<Flex mt={11} justifyContent="center" alignItems="center">
					<Text fontSize={18} display="block" white-space="pre-line">
						Sorry, your selection does not result in any cases.
					</Text>
				</Flex>
				<Flex mt={5} justifyContent="center" alignItems="center" flexDirection="column" data-field="Reset_Filters">
					<Text fontSize={12} color="#999" lineHeight={1.25} display="block">
						Try editing your selection in account settings to view more cases.
					</Text>
				</Flex>
			</>
		);
	};

	usePageEffects('Queue Page');

	React.useEffect(
		() => {
			isLoaded || loadCaseList(filtersToApiParams({
				page,
				size,
				sortModel,
				procedureDateFilter,
				statusFilter,
				stateFilter,
				surgeonFilter,
				from,
				to,
				globalFilter,
				allTask
			}));
		},
		[isLoaded, loadCaseList, page, size, sortModel, procedureDateFilter, statusFilter, stateFilter, surgeonFilter, from, to, globalFilter, allTask ]);

	React.useEffect(() => {
		setIsLoaded(true);
		return () => setIsLoaded(false);
	}, []);

	return (
		<Flex
			flexDirection="column"
			mb="140px"
		>
			{ currentCaseId ? <CaseDetails caseId={currentCaseId}/> : renderEmptyCurrentCaseDetail() }
			<QueueController/>
		</Flex>
	);
};
