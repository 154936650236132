import React, { CSSProperties } from 'react';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { EventConfigDescriptor } from '@calendar/types/EventConfigDescriptor';

export interface EventLegendItemProps<EventEnum> {
	type: EventEnum;
	config: EventConfigDescriptor;
	styleConfig: CSSProperties;
}

const useStyles = makeStyles((theme) => createStyles({
	container: {
		display: 'flex',
		flexDirection: 'row',
	},
	legendIconBox: ({
		styleConfig,
	}: Pick<EventLegendItemProps<never>, 'styleConfig'>) => ({
		height: '24px',
		width: '24px',
		borderRadius: '4px',
		padding: theme.spacing(0.25),
		marginRight: theme.spacing(1),
		...styleConfig,
	}),
	legendLabel: ({
		styleConfig,
	}: Pick<EventLegendItemProps<never>, 'styleConfig'>) => ({
		fontWeight: 600,
		fontSize: '14px',
		alignSelf: 'center',
		color: styleConfig.color,
	}),
	icon: ({
		styleConfig,
	}: Pick<EventLegendItemProps<never>, 'styleConfig'>) => ({
		fill: styleConfig.fill,
	}),
}));

function EventLegendItem<EventEnum>({
	type,
	config: { legendLabel: label, icon: IconComponent },
	styleConfig,
}: EventLegendItemProps<EventEnum>) {
	const classes = useStyles({ styleConfig });

	return (
		<Box className={classes.container}>
			<Box
				className={classes.legendIconBox}
				data-field={`${type as string}-legend-icon`}
			>
				{IconComponent && (
					<IconComponent
						className={classes.icon}
						aria-describedby={`${type as string}-legend-item`}
					/>
				)}
			</Box>
			<Typography
				className={classes.legendLabel}
				id={`${type as string}-legend-item`}
			>
				{label}
			</Typography>
		</Box>
	);
}

export default EventLegendItem;
