import React from 'react';
import DisplayTimeFrameProps from '@components/DisplayTimeFrame/DisplayTimeFrameProps';
import { formatInTimeZone } from 'date-fns-tz';
import { getDateFromApiDateTime } from '@calendar';
import { Typography } from '@material-ui/core';

const formatTime = (timeZone: string, time: Date) => {
	return formatInTimeZone(time, timeZone, 'h:mm a');
};

const buildTimeStr = (timeZone: string, startDate: Date, endDate?: Date) => {
	const startStr = formatTime(timeZone, startDate);
	if (!endDate) { return startStr; }
	return `${startStr} - ${formatTime(timeZone, endDate)}`;
};

const DisplayTimeFrame: React.FC<DisplayTimeFrameProps> = ({ timeFrame: { start, end }, timeZone, ...typographyProps }) => {
	const displayTimeFrame = React.useMemo(() => buildTimeStr(timeZone, getDateFromApiDateTime(start), end ? getDateFromApiDateTime(end) : undefined), [end, start, timeZone]);

	return <Typography {...typographyProps}>{displayTimeFrame}</Typography>;
};

export default DisplayTimeFrame;
