import React from 'react';
import { Box, Theme, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SwitchToolTipContent from './SwitchToolTipContent';
import { ClickAwayListener } from '@material-ui/core';
import { SwitchToolTipProps } from './SwitchToolTipProps';

const useStyles = makeStyles((theme: Theme) => ({
	arrow: {
		color: theme.palette.common.black,
	},
	tooltip: {
		backgroundColor: theme.palette.common.black,
		maxWidth: '200px'
	}
}));

export default function SwitchToolTip({ children, title, showArrow, placement, disableSwitch }: SwitchToolTipProps) {
	const styles = useStyles();
	const [ toolTipOpen, setToolTipOpen ] = React.useState(false);

	const handleClick = () => disableSwitch && setToolTipOpen(prev => !prev);
	const handleClose = React.useCallback(() => setToolTipOpen(false), []);

	return (
		<ClickAwayListener onClickAway={handleClose}>
			<Tooltip
				title={<SwitchToolTipContent titleBody={title} handleClose={handleClose} />}
				open={toolTipOpen}
				interactive={true}
				arrow={showArrow}
				placement={placement}
				classes={styles}
				disableHoverListener={true}
			>
				<Box onClick={handleClick} data-field="switch-tooltip-inner-wrapper">{children}</Box>
			</Tooltip>
		</ClickAwayListener>
	);
}
