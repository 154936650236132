import { Box, Flex, Tag, Text } from '@ascension/web';
import React from 'react';
import { AlertProps } from './AlertProps';
import { AlertBody } from '../AlertBody/AlertBody';
import { utcToLocalTime } from '../../../utilities/dateUtilities';
import { useSelector } from 'react-redux';
import { UserProfileHasARoleSelector } from '../../../store/selectors/UserProfileSelector';

export const Alert = ({
	tagColor,
	headerColor,
	tagText,
	headerText,
	body,
	footer,
	alertUser,
	alertTime,
	showForRoles,
	...rest
}: AlertProps) => {

	const convertedDate = utcToLocalTime(alertTime);

	if (!useSelector(UserProfileHasARoleSelector(showForRoles)) && showForRoles) { return null; }

	return (
		<Flex flexDirection="column" bg="white" mb={10} borderRadius="4px" {...rest}>
			<Box
				bg={headerColor}
				p={1}
				borderTopLeftRadius={2}
				borderTopRightRadius={2}
				data-section="alert-header"
			>
				<Tag color={tagColor} m={2}>
					{tagText}
				</Tag>
				<Text fontSize={14} ml={4} color="#222222">
					{headerText}
				</Text>
			</Box>
			{(alertUser || alertTime) ?
				<AlertBody alertUser={alertUser} alertTime={convertedDate} body={body}/>
				: body
			}
			{footer &&
				<Box
					borderTop="1px solid #C9C9C9"
					px="24px"
					pt="12px"
					pb="3px"
					testId="alertFooter"
				>
					{footer}
				</Box>
			}
		</Flex>
	);
};
