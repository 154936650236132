import React from 'react';
import { Box } from '@material-ui/core';
import { useFieldFormContextWithPrevious } from '../../../../utilities/hooks/useFieldFormContextWithPrevious/useFieldFormContextWithPrevious';
import MyFiltersCheckbox from '../MyFiltersCheckbox';
import { useSaveFiltersContext } from '../SaveFiltersContext/SaveFiltersContext';
import { useFormContext } from 'react-hook-form';
import { Surgeon } from '../../../../data/surgeon/Surgeon';
import { getNewSelectList, SectionName, toggleSelectedSurgeonsBySpecialty } from '../MyFilterUtilities';
import { useIsAllSelected } from '../useIsAllSelected/useIsAllSelected';
import { useFormMetaSchema } from '../../../../store/context/FormMetaContext';
import { FormAssistiveText } from '../../../FormComponents/FormAssistiveText/FormAssistiveText';

export default function Specialties() {
	const validationRules = useFormMetaSchema('specialty');

	const {
		currentValue: myFilterSpecialtiesCurrentValue = [],
		setCurrentValue: myFilterSpecialtiesSetCurrentValue,
	} = useFieldFormContextWithPrevious<string[] | null>('specialty', {
		validationRules, triggerValidationOnChange: true
	});
	const { sortedSpecialtyIds, specialtyList } = useSaveFiltersContext();

	const allSelected = useIsAllSelected('specialty', sortedSpecialtyIds);

	const { setValue, getValues } = useFormContext<{surgeon: string[] | null}>();
	const toggleSurgeonsBySpecialty = React.useCallback((specId: string) => {
		if (specId === 'all' && !allSelected) {
			// Default - Selecting All Specialties selects All for all surgeon groups and sets state as []
			setValue('surgeon', []);
		} else if (specId === 'all' && allSelected) {
			// Deselecting All Specialties deselects All for all surgeon groups and sets state as null
			setValue('surgeon', null);
		} else {
			// Select/Deselect surgeons for any other specialty
			const { surgeon: currentSurgeons } = getValues();
			const { surgeons } = specialtyList[specId];
			const specialtySurgeons = surgeons.map((surgeon: Surgeon) => surgeon.id);
			const newSelectedSurgeons = toggleSelectedSurgeonsBySpecialty(specialtySurgeons, currentSurgeons);

			setValue('surgeon', newSelectedSurgeons);
		}
	}, [getValues, setValue, specialtyList, allSelected]);

	const getSelectedSpecialties = React.useMemo(() => getNewSelectList.bind(null, sortedSpecialtyIds, sortedSpecialtyIds), [sortedSpecialtyIds]);
	const toggleSpecialty = React.useCallback((specId: string) => {
		myFilterSpecialtiesSetCurrentValue((specialtiesCurrent) => getSelectedSpecialties(specialtiesCurrent, specId, SectionName.SPECIALTY));

		toggleSurgeonsBySpecialty(specId);
	}, [myFilterSpecialtiesSetCurrentValue, toggleSurgeonsBySpecialty, getSelectedSpecialties]);

	const formatSpecialtyNameAndCount = React.useCallback( (specialtyId: string) => {
		const specialtyDetail = specialtyList[specialtyId];
		const { specialtyName, specialtyCount } = specialtyDetail || {};
		const specialtyCountText = specialtyCount > 0 ? ` (${specialtyCount} Surgeons)` : '';
		return specialtyName && `${specialtyName}${specialtyCountText}` || '';
	}, [specialtyList]);

	return (
		<Box display="flex" flexDirection="column">
			<Box display="flex" flexDirection="column" justifyContent="space-between" width={1} alignItems="start" py={2} data-field="fullName">
				<Box fontSize={18} letterSpacing="1.4px" color="#222222" fontWeight="bold" pb={1}>
					Specialty
				</Box>
				<FormAssistiveText field="specialty">Select at least on specialty.</FormAssistiveText>
			</Box>
			<Box display="flex" flexDirection="row">
				<Box display="flex" flexDirection="row" flexWrap="wrap" width={1} pb={4}>
					<MyFiltersCheckbox
						id="all"
						name="All Specialties"
						onCheck={toggleSpecialty}
						width={.5}
						checked={allSelected}
					/>
					{sortedSpecialtyIds.map((specialtyId) =>
						<MyFiltersCheckbox
							id={specialtyId}
							name={formatSpecialtyNameAndCount(specialtyId)}
							onCheck={toggleSpecialty}
							key={specialtyId}
							width={.5}
							checked={!allSelected && !!myFilterSpecialtiesCurrentValue && myFilterSpecialtiesCurrentValue.includes(specialtyId)}
						/>
					)}
				</Box>
			</Box>
		</Box>
	);
}
