import React from 'react';
import { ManageCaseOption } from '@components/CaseDetails/ManageCaseDropDown/ManageCaseOption';
import { useSelector } from 'react-redux';
import { CaseDetailsAppointmentSelector } from '@store/selectors/CaseDetailsSelector';
import useHasPermission from '@utilities/hooks/useHasPermission/useHasPermission';
import { PermittedAction } from '@utilities/PermittedAction';
import { RenderAsProps } from '@components/CaseDetails/ManageCaseDropDown/DropDownOptions/DropDownOptionProps';
import { useModalContext } from '@components/Modal/ModalContextProvider';
import { UserAccessEnum } from '@interfaces/SurgeryRequest/UserAccessEnum';
import { REQUEST_REVISION_MODAL } from '@components/Modal/modalConstants';
import useGetAccessor from '@components/CaseDetails/CaseInUse/hooks/useGetAccessor/useGetAccessor';

export const RequestRevisionOption = ({ closeDropdown }: RenderAsProps) => {
	const { openModal } = useModalContext();
	const onRequestRevision = () => {
		openModal(REQUEST_REVISION_MODAL);
		closeDropdown();
	};
	const { isAccessorDifferentUser: disabled } = useGetAccessor([UserAccessEnum.CONFIRMER]);

	const { status } = useSelector(CaseDetailsAppointmentSelector);
	const isCancelledOrPendingCancelled =  status && ['CANCELLED', 'PENDING_CANCELLED'].includes(status);
	const displayRequestRevision = useHasPermission(PermittedAction.REQUEST_CASE_REVISION) && !isCancelledOrPendingCancelled;

	if (!displayRequestRevision) { return null; }

	return (
		<ManageCaseOption onClick={onRequestRevision} fieldName="requestRevision" disabled={disabled}>
                Request Revision
		</ManageCaseOption>
	);
};
