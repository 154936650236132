import React from 'react';
import CalendarUI from '@calendar/components/CalendarUI/CalendarUI';
import { CalendarProps } from '@calendar/types';
import CalendarWrapper from '@calendar/components/CalendarWrapper';

const Calendar: React.FC<CalendarProps> = (props) => {
	return <CalendarWrapper {...props}>
		<CalendarUI />
	</CalendarWrapper>;
};

export default Calendar;
