import React from 'react';
import { useFormContext } from 'react-hook-form';
import { deepObjectEquality } from '@utilities/deepObjectEquality';

/*** useShouldDisableSubmit - Contains editUnavailableEvent function to call API to edit an existing Unavailable Event
 *
 * @returns Boolean indicating if the submit button for a form will be clickable
 */
function useShouldDisableSubmit<T extends Record<keyof T, unknown>>(defaultFormValues: T) {
	const { getValues, watch } = useFormContext<T>();
	const currentValues = watch();
	const editFormValuesLoaded = React.useMemo(() => (
		!deepObjectEquality(currentValues, defaultFormValues)
	), [currentValues, defaultFormValues]);

	const initialEditFormState = React.useRef(defaultFormValues);
	const [shouldDisableSubmit, setShouldDisableSubmit] = React.useState(false);
	const [formStateInitiated, setFormStateInitiated] = React.useState(false);

	const resetInitialValues = React.useCallback(() => {
		initialEditFormState.current = { ...getValues() };
	}, [getValues]);

	React.useEffect(() => {
		// capture initial edit form state to determine when shouldDisableSubmit
		if (editFormValuesLoaded && !formStateInitiated) {
			resetInitialValues();
			setFormStateInitiated(true);
			setShouldDisableSubmit(true);
		} else {
			// re-check if shouldDisableSubmit every time currentValues changes
			setShouldDisableSubmit(deepObjectEquality(currentValues, initialEditFormState.current));
		}
	}, [currentValues, editFormValuesLoaded, formStateInitiated, resetInitialValues]);

	return { resetInitialValues, shouldDisableSubmit };
}

export default useShouldDisableSubmit;
