import { TemplateInfoApi } from '../../../interfaces/Procedure/TemplateInfoApi';

export enum TemplateAction {
	NONE,
	NEW,
	EDIT_PENDING,
	EDIT,
	DUPLICATE_PENDING,
	DUPLICATE,
	DELETE,
}
export interface SelectedAction {
	action: TemplateAction;
	templateId?: string;
	initialData?: Partial<TemplateInfoApi>;
}
