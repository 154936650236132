import React from 'react';
import { Icon, Box, Flex } from '@ascension/web';
import LockIcon from '@material-ui/icons/Lock';
import { TemplateTileMenuProps } from '@components/AccountSettings/MyTemplates/TemplateTile/TemplateTileMenuProps';
import { createStyles, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NoParamNoReturnCallback from '@interfaces/NoParamNoReturnCallback';

const useStyles = makeStyles((theme) => createStyles({
	menuStyle: {
		'& .MuiMenu-paper': {
			'maxWidth': '126px',
			'&.MuiPaper-elevation8': {
				boxShadow: '0 0 5px 0 #22222233',
			},
			'& .MuiList-padding': {
				paddingTop: 0,
				paddingBottom: 0,
			},
			'& .MuiListItem-root': {
				'cursor': 'pointer',
				'flexDirection': 'column',
				'outline': 'none',
				'color': '#222',
				'alignItems': 'flex-start',
				'textTransform': 'uppercase',
				'fontFamily': 'Lato',
				'lineHeight': 1.29,
				'fontSize': '12px',
				'fontWeight': 700,
				'&:not(:first-of-type)': {
					borderTop: '1px solid #C9C9C9',
				},
				'padding': theme.spacing(2, 3)
			},
		},
	},
	lockedMenuItem: {
		'&.MuiListItem-root': {
			'opacity': 1,
			'& > div': {
				gap: '8px',
			},
			'& > div > div': {
				fontFamily: 'Lato',
				fontSize: '10px',
				fontStyle: 'italic',
				whiteSpace: 'break-spaces',
				letterSpacing: 0,
				lineHeight: 1.2,
				textTransform: 'initial',
				color: '#222',
				fontWeight: 'normal',
			}
		},
	},
	smallIcon: {
		fontSize: '10px',
	}
}));

const TemplateTileMenu = ({
	lockedBy,
	onEditSelected,
	onDuplicateSelected,
	onDeleteSelected,
}: TemplateTileMenuProps) => {
	const { menuStyle, lockedMenuItem, smallIcon } = useStyles();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleClose = () => {
		setAnchorEl(null);
	};

	const openMenu = (ev: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(ev.currentTarget);
	};

	const handleItemClick = (cb: NoParamNoReturnCallback) => {
		return () => {
			handleClose();
			cb();
		};
	};

	return (
		<Box>
			<Box onClick={openMenu}>
				<Icon name="elipsis" size="18" color="#1e69d2"/>
			</Box>
			<Menu
				anchorEl={anchorEl}
				open={!!anchorEl}
				keepMounted
				onClose={handleClose}
				className={menuStyle}
				getContentAnchorEl={null}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				{lockedBy &&
					<MenuItem disabled={true} className={lockedMenuItem}>
						<Flex alignItems="center">
							<LockIcon fontSize="small" className={smallIcon}/>
							<Box>{`${lockedBy} is currently editing`}</Box>
						</Flex>
					</MenuItem>
				}
				{!lockedBy && onEditSelected &&
					<MenuItem onClick={handleItemClick(onEditSelected)}>
						Edit
					</MenuItem>
				}
				{onDuplicateSelected &&
					<MenuItem onClick={handleItemClick(onDuplicateSelected)}>
						Duplicate
					</MenuItem>
				}
				{!lockedBy && onDeleteSelected &&
					<MenuItem onClick={handleItemClick(onDeleteSelected)}>
						Delete
					</MenuItem>
				}
			</Menu>
		</Box>
	);
};

export default TemplateTileMenu;
