import React from 'react';
import { Box } from '@material-ui/core';
import { useFieldFormContext } from '@utilities/hooks/useFieldFormContext/useFieldFormContext';
import {
	InsuranceInformationValidationSchema
} from '@components/RequestForm/InsuranceInformation/InsuranceInformationValidationSchema';
import { makeStyles } from '@material-ui/core/styles';
import theme from '@theme/theme';
import TextFieldInput from '@components/TextFieldInput/TextFieldInput';
import getRequestFormLabelStyle from '@components/RequestForm/utilities/getRequestFormLabelStyle';
import { InsuranceInformationProps } from '@components/RequestForm/InsuranceInformation/InsuranceInformationInterface';

const useStyles = makeStyles({
	preCertContainer: {
		marginRight: theme.spacing(2),
	},
	preCertInput: {
		'paddingBottom': theme.spacing(3),
		'&>.MuiInputLabel-root': getRequestFormLabelStyle(theme),
	},
});

type PreCertNumberInputProps = Pick<InsuranceInformationProps, 'type'>;

const PreCertNumberInput = ({ type }: PreCertNumberInputProps) => {
	const boxWidth = [1, .32];
	const classes = useStyles();

	const {
		currentValue: insurancePreCertCurrentValue,
		setCurrentValue: insurancePreCertSetCurrentValue,
		error: insurancePreCertError,
		triggerSelfValidation: insurancePreCertTriggerSelfValidation,
	} = useFieldFormContext<string>(`preCertNumber${type}`, {
		validationRules: InsuranceInformationValidationSchema.preCertNumber,
	});

	return (
		<Box width={boxWidth} className={classes.preCertContainer} data-field="preCertNumber">
			<TextFieldInput
				fieldName="preCertNumber"
				label="Pre-Cert Number"
				value={insurancePreCertCurrentValue}
				onChange={insurancePreCertSetCurrentValue}
				onBlur={insurancePreCertTriggerSelfValidation}
				error={!!insurancePreCertError}
				errorMessage={insurancePreCertError}
				className={classes.preCertInput}
			/>
		</Box>
	);
};

export default PreCertNumberInput;
