import { useFormContext } from 'react-hook-form';
import { RequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import { AppointmentType } from '@data/request/AppointmentType';
import ComparisonOption from '@utilities/arrays/ComparisonOption';
import useDoesUnitSupportAppointmentTypes
	from '@utilities/hooks/useDoesUnitSupportAppointmentTypes/useDoesUnitSupportAppointmentTypes';

const useDoesSelectedUnitSupportAppointmentTypes = (appointmentTypes: AppointmentType[], comparisonOption: ComparisonOption = ComparisonOption.EXACT) => {
	const { watch } = useFormContext<RequestFormValidatedState>();
	const [ appointmentLocation = '' ] = watch(['appointmentLocation']);

	return useDoesUnitSupportAppointmentTypes(appointmentLocation, appointmentTypes, comparisonOption);
};

export default useDoesSelectedUnitSupportAppointmentTypes;
