import React from 'react';
import CardSection from '@components/CardSection/CardSection';
import CardSectionTitle from '@components/CardSection/CardSectionTitle/CardSectionTitle';
import DocumentRequest from '@components/RequestForm/DocumentRequest/DocumentRequest';
import ComposedCommonWebSections
	from '@components/RequestForm/composedSections/ComposedCommonWebSections/ComposedCommonWebSections';

const ComposedWebSections: React.FC = () => {
	return <>
		<ComposedCommonWebSections />
		<CardSection sectionTitle={<CardSectionTitle title="Documents" />}>
			<DocumentRequest />
		</CardSection>
	</>;
};

export default ComposedWebSections;
