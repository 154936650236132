import {
	Multiselect,
} from '@ascension/web';
import React from 'react';
import { SortingComponentProps } from './SortingComponentProps';
import { useFilterState } from '../Filters/FiltersContext/FiltersContext';
import { DropdownOption } from '@definitions/DropdownOption';
import { Box, alpha, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
	return {
		sortingDropdown: {
			'minWidth': 120,
			'border': '1px solid',
			'borderRadius': theme.spacing(.5),
			'borderColor': alpha(theme.palette.common.black, .6),
			'& > div > div > div:nth-of-type(2)': {
				marginTop: theme.spacing(.2),
			}
		},
	};
});

export default function SortingComponent ({ options, ...rest }: SortingComponentProps) {
	const classes = useStyles();
	const { sortModel, updateFilterPropsAndResetFilterToggle } = useFilterState();

	const option = options.filter( item => item.value == sortModel);

	const handleChange = (newValue: DropdownOption[]) => {
		updateFilterPropsAndResetFilterToggle({ sortModel: newValue[0].value });
	};

	return (
		<Box data-field="SORT_BY" className={classes.sortingDropdown}>
			<Multiselect single value={option} onChange={handleChange} label="Sort by" options={options} {...rest}/>
		</Box>
	);
}
