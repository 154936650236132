import React from 'react';
import {
	Box,
} from '@ascension/web';
import { makeStyles } from '@material-ui/core/styles';
import PagesIndexTextInput from '@components/PdfViewerPage/IndexingSection/EditIndexes/inputs/PagesIndexTextInput';
import DocumentTypeSelect from '@components/PdfViewerPage/IndexingSection/EditIndexes/inputs/DocumentTypeSelect';
import {
	useIndexingSectionContext
} from '@components/PdfViewerPage/IndexingSection/IndexingSectionContext/IndexingSectionContext';
import AddIndexButton from '@components/PdfViewerPage/IndexingSection/EditIndexes/AddIndexButton/AddIndexButton';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
	indexContainerOuter: {
		'flex': '1 0 1px',
		'marginTop': theme.spacing(3),
		'overflowY': 'auto',
		'overflowX': 'hidden',
		'&::-webkit-scrollbar': {
			width: '4px',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.palette.grey[400],
			borderRadius: '100px'
		},
	},
	indexContainerInner: {
		display: 'flex',
		flexDirection: 'row',
		gap: theme.spacing(1.5),
		marginBottom: theme.spacing(1.5),
	},
	deleteIcon: {
		fill: theme.palette.grey[700],
		height: '30px',
		width: '30px',
		cursor: 'pointer',
		marginTop: theme.spacing(5),
	},
}));

const EditIndexes: React.FC = () => {
	const classes = useStyles();
	const { allIdList, registerAddItemHandler, removeIndexRow } = useIndexingSectionContext();
	const addButtonContainerRef = React.useRef<HTMLDivElement | null>(null);

	const timerRef = React.useRef<ReturnType<typeof setTimeout>>();

	const scrollAddButtonToView = React.useCallback(() => {
		clearTimeout(timerRef.current);
		// Use timeout to trigger scroll at end of event loop. Scroll fails without this.
		timerRef.current = setTimeout(() => {
			if (!addButtonContainerRef.current) { return; }
			addButtonContainerRef.current.scrollIntoView();
		});
	}, []);

	React.useEffect(() => {
		return registerAddItemHandler(scrollAddButtonToView);
	}, [registerAddItemHandler, scrollAddButtonToView]);

	return (
		<Box className={classes.indexContainerOuter}>
			{allIdList.map((id: string, index) =>
				<Box key={id} className={classes.indexContainerInner} data-field="documentView-indexEdit-editFields">
					<PagesIndexTextInput shouldShowHelperText={index === 0} id={id}/>
					<DocumentTypeSelect id={id}/>
					<DeleteIcon
						className={classes.deleteIcon}
						onClick={() => removeIndexRow(id)}
						data-field="documentViewer-indexEdit-removeIndexRow"
					/>
				</Box>
			)}
			<div ref={addButtonContainerRef}>
				<AddIndexButton />
			</div>
		</Box>
	);
};

export default EditIndexes;
