import { DropdownOption } from '../../../../definitions/DropdownOption';
import { validators } from '../../../../utilities/Validation/validators';
import { dateParse } from '../../../../utilities/dateUtilities';
import { RegisterOptions } from 'react-hook-form';
import {
	INVALID_DATE, INVALID_FUTURE_DATE, PAT_LOCATION_MISSING,
	REQUIRED_DROPDOWN_NO_OPTION_SELECTED
} from '../../../../utilities/Validation/ValidationMessages';

const validDate = (testDate: Date) => {
	return (!testDate
		|| testDate && validators.dateValidator(dateParse(testDate)[0]) === null
		|| INVALID_DATE);
};

export const PreAdmitTestSubformValidation: Record<string, RegisterOptions> = {
	patLocation: {
		validate: (option: DropdownOption) => validators.dropdownRequiredValidator(option) === null || REQUIRED_DROPDOWN_NO_OPTION_SELECTED,
	},
	patOtherLocation: {
		required: PAT_LOCATION_MISSING,
	},
	patDate: {
		validate: {
			validDate,
			futureDate: (testDate: Date) => {
				return (!testDate
					|| testDate && new Date(testDate).getTime() > new Date().getTime()
					|| INVALID_FUTURE_DATE);
			},
		},
	},
	patDateEdit: {
		validate: {
			validDate,
		}
	}
};
