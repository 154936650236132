import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import { FindATimeForm } from '@components/FindATime/FindATimeForm';
import { deepObjectEquality } from '@utilities/deepObjectEquality';
import { useFindATimeContext } from '@components/FindATime/FindATimeContext/FindATimeContextProvider';
import RequestFormPageState from '@components/RequestForm/RequestFormPage.types';
import { FindATimeSearchDefaultValues } from '@components/FindATime/FindATimeSearchDefaultValues';
import { useGetSurgeonOptions } from '@utilities/hooks/useGetSurgeonOptions/useGetSurgeonOptions';
import { BaseButton } from '@components/BaseButton/BaseButton';

const useStyles = makeStyles((theme) => (createStyles({
	root: {
		display: 'flex',
		gap: theme.spacing(4),
	},
	searchButton: {
		'borderRadius': '100px',
		'height': '48px',
		'border': '2px solid',
		'&:hover': {
			border: '2px solid',
		},
	},
	resetButton: {
		height: '48px',
		border: 'none',
	},
})));

export const SearchActionButtons: React.FC = () => {
	const classes = useStyles();
	const { reset, getValues, formState, handleSubmit } = useFormContext<FindATimeForm>();
	const currentFormValues = getValues();
	const history = useHistory<RequestFormPageState>();
	const previousFindATimeValues = history.location.state?.previousFindATimeValues;
	const surgeonOptions = useGetSurgeonOptions();
	const firstSurgeonInList = surgeonOptions?.length > 0 ? surgeonOptions[0].value : '';

	const disableReset = React.useMemo(() => {
		if (formState.defaultValues && previousFindATimeValues && deepObjectEquality(formState.defaultValues, previousFindATimeValues)) {
			return false;
		}

		if (formState.defaultValues) {
			// For primarySurgeon, the default value is the first surgeon in list, but in FindATimeSearchDefault values it is ''
			// To accurately compare form state with default values, manually add this default value after list has been retrieved from API
			const fullDefaultValues = { ...formState.defaultValues, primarySurgeon: firstSurgeonInList };
			return deepObjectEquality(currentFormValues, fullDefaultValues);
		}

		return true;
	}, [currentFormValues, firstSurgeonInList, formState.defaultValues, previousFindATimeValues]);

	const { submitFindATimeSearch } = useFindATimeContext();

	const handleReset = () => {
		reset(FindATimeSearchDefaultValues);
	};

	const handleSearch = () => {
		submitFindATimeSearch();
	};

	return (
		<Box className={classes.root}>
			<BaseButton
				variant="outlined"
				color="primary"
				className={classes.searchButton}
				onClick={handleSubmit(handleSearch)}
				dataField="findATimePage-searchSection-searchButton"
			>
				Search
			</BaseButton>
			<BaseButton
				color="primary"
				className={classes.resetButton}
				disabled={disableReset}
				onClick={handleReset}
				dataField="findATimePage-searchSection-resetButton"
			>
				Reset
			</BaseButton>
		</Box>
	);
};
