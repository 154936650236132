import React  from 'react';
import useDeferredCollection from '@utilities/hooks/useDeferredCollection/useDeferredCollection';

type IndexableEntity<T> = { [K in keyof T]: string };

/**
 * useCollectionEntity
 *
 * @description
 * Use this hook to grab a specific entity from the cache using a particular key. This is a good utility to use in combination
 * with APIs that return only the surgeon ID or unit ID. You can use this to get the surgeon and unit info using that ID
 * either out of the cache or from the API if it hasn't already been requested.
 *
 * @param collectionEndpoint {string}
 * - ideally a non changing endpoint but will also work with endpoints that require an ID.
 *  - use the endpoint constant
 * @param key {string} - ID to lookup in list
 * @param keyProp {string: 'id'}- Name of the ID property to look for the key.
 *
 * @example
 * const { hospitalName, name: unitName } = useCollectionEntity<Unit>(ENDPOINT_USER_UNITS, unit, 'id');
 */
export const useCollectionEntity =
	<EntityType extends object = Record<string, unknown>>(collectionEndpoint: string, key: string, keyProp: keyof EntityType = 'id' as keyof EntityType): EntityType | Partial<Record<keyof EntityType, never>> => {
		const [collection, updateCollection] = useDeferredCollection<IndexableEntity<EntityType>>(collectionEndpoint);
		const [currentVal, setCurrentVal] = React.useState<IndexableEntity<EntityType> | Partial<Record<keyof EntityType, never>>>({});

		React.useEffect(() => {
			if (collection) {
				setCurrentVal(collection.find((entity) => (entity[keyProp] === key)) || {});
			}
		}, [collection, key, keyProp]);

		React.useEffect(() => {
			const abortController = updateCollection();
			return () => abortController.abort();
		}, [updateCollection]);

		return currentVal as EntityType;
	};
