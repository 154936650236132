import React, { ChangeEvent } from 'react';
import { Button, TextField } from '@ascension/ui-library';
import CloseIcon from '@material-ui/icons/Close';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useConfirmationCaseNumber } from '../../CaseDetailsContext/ConfirmCaseContext/ConfirmCaseUtilities';
import {
	CONFIRMATION_INPUT_CONFIG,
} from '../../../Alerts/UnscheduledAlert/ConfirmCaseInput/ConfirmCaseInputConfig';
import { ForceConfirmButton } from '../ForceConfirmButton/ForceConfirmButton';
import { IntegrationType } from '../../../../interfaces/IntegrationType';
import { useDrawerContext } from '@components/DrawerWrapper/DrawerContextProvider';
import { SAVE_PENDING_LINK_WRAPPER } from '@components/DrawerWrapper/drawerConstants';

const useStyles = makeStyles((theme) => ({
	innerDrawerContainer: {
		'display': 'flex',
		'flexDirection': 'column',
		'flexWrap': 'nowrap',
		'height': '100%',
		'padding': theme.spacing(2),
		'& strong': {
			fontWeight: 800
		}
	},
	iconContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		width: '100%',
	},
	closeIcon: {
		'fill': theme.palette.grey[600],
		'&:hover': {
			cursor: 'pointer',
		}
	},
	caseNumberStyle: {
		color: theme.palette.grey[900],
		fontSize: '1rem',
		lineHeight: '1.5rem',
		letterSpacing: '.03rem',
		margin: theme.spacing(0, 0, 1, 0),
	},
	helperText: {
		color: theme.palette.grey[600],
	},
	subheaderText: {
		fontSize: '1rem',
		lineHeight: '1.5rem',
		letterSpacing: '.03rem',
	},
	footer: {
		marginTop: 'auto',
		position: 'fixed',
		bottom: 0,
		backgroundColor: 'white',
		paddingBottom: theme.spacing(3),
	},
	button: {
		margin: theme.spacing(2, 2, 0, 0),
	},
	mainContainer: {
		overflow: 'auto',
		marginBottom: theme.spacing(24),
		[theme.breakpoints.up(theme.drawerPane.breakpoint)]: {
			marginBottom: theme.spacing(15),
		},
	},
	inputContainerStyle: {
		paddingBottom: theme.spacing(2),
		paddingTop: theme.spacing(1),
	},
	errorMessageStyle: {
		margin: 0,
	},
	inputLabelStyle: {
		top: 'unset',
	},
}));

export const SavePendingLinkWrapper = () => {
	const classes = useStyles();
	const caseNumber = useConfirmationCaseNumber();
	const { closeDrawer } = useDrawerContext();
	const [finValue, setFinValue] = React.useState('');
	const { validCharacterTest, invalidCharacterErrorMessage } = CONFIRMATION_INPUT_CONFIG[IntegrationType.MANUAL];
	const [showInvalidCharError, setShowInvalidCharError] = React.useState(false);

	const [validStringRegExp] = React.useState(new RegExp(`^[${validCharacterTest}]+$`));
	const [filterCharacterRegExp] = React.useState(new RegExp(`[^${validCharacterTest}]`, 'g'));
	const handlePaste = (event: React.ClipboardEvent) => {
		event.preventDefault();
		const pasteData = event.clipboardData ? event.clipboardData.getData('text') : '';
		if (!validStringRegExp.test(pasteData)) {
			setFinValue('');
			setShowInvalidCharError(true);
			return;
		}
		setFinValue(pasteData);
	};

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		setShowInvalidCharError(false);
		const value = event.target.value;
		const validated = value.replace(filterCharacterRegExp, '');
		setFinValue(validated);
	};

	const displayError = showInvalidCharError && invalidCharacterErrorMessage;

	return (
		<Grid container className={classes.innerDrawerContainer}>
			<Grid item className={classes.iconContainer}>
				<CloseIcon onClick={()=> closeDrawer(SAVE_PENDING_LINK_WRAPPER)} className={classes.closeIcon} data-field="closeIcon"/>
			</Grid>
			<Grid item id="mainContainer" className={classes.mainContainer}>
				<div id="conflictsDrawerHeader">
					<p className={classes.caseNumberStyle}>EHR Case #{caseNumber}</p>
					<Typography variant="h1">Request to link</Typography>
					<p className={`${classes.helperText} ${classes.subheaderText}`}>
						Please confirm that EHR Case Number <strong>{caseNumber} </strong>
						is correct. If the case number is correct, you may send a request to link this case.
						<br/>
						<br/>
						If there are no data conflicts, the case will be linked.
					</p>
				</div>
				<TextField
					type="text"
					variant="static"
					fullWidth
					translate="yes"
					name="EnterOptionalFIN"
					label="Add FIN (optional)"
					value={finValue}
					className={classes.inputContainerStyle}
					InputLabelProps={{ className: classes.inputLabelStyle }}
					error={!!displayError}
					helperText={displayError}
					FormHelperTextProps={{ className: classes.errorMessageStyle }}
					onChange={handleChange}
					onPaste={handlePaste}
				/>
			</Grid>
			<Grid item id="conflictsDrawerFooter" className={classes.footer}>
				<ForceConfirmButton optionalFin={finValue}>Save</ForceConfirmButton>
				<Button
					translate="yes"
					color="primary"
					variant="outlined"
					className={classes.button}
					onClick={()=> closeDrawer(SAVE_PENDING_LINK_WRAPPER)}
					data-field="cancelBtn"
				>
					Cancel
				</Button>
			</Grid>
		</Grid>
	);
};
