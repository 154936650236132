import { ManagedUser } from '../ManageUsersListContext/ManageUsersListContextType';
import { SortOrderType } from '../SortOrderType';

function descendingComparator(a: ManagedUser, b: ManagedUser, orderBy: keyof ManagedUser) {
	const first = a[orderBy];
	const second = b[orderBy];

	function compareHelper(first: ManagedUser[keyof ManagedUser] | string, second: ManagedUser[keyof ManagedUser] | string) {
		if (second && first) {
			if (second < first) {
				return -1;
			}
			if (second > first) {
				return 1;
			}
			return 0;
		} else {
			return 0;
		}
	}

	if (typeof (first) == 'string' || typeof (second) == 'string') {
		return compareHelper(String(first).toLowerCase(), String(second).toLowerCase());
	} else {
		return compareHelper(first, second);
	}
}

export function getComparator(
	order: SortOrderType,
	orderBy: keyof ManagedUser,
): (
    a: ManagedUser,
    b: ManagedUser,
) => number {
	return order === SortOrderType.DESC
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}
