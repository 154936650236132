import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { UserProfilePrimaryPracticeSelector } from '@store/selectors/UserProfileSelector';
import ModalViewer from '@components/Modal/ModalViewer';
import { useModalContext } from '@components/Modal/ModalContextProvider';
import useCreateUserRegistration from '@components/UserRegistrationPage/utilities/useCreateUserRegistration';
import GoldButton from '@components/GoldButton/GoldButton';
import { USER_LOCATION_CONFIRMATION_MODAL } from '@components/Modal/modalConstants';
import { makeStyles } from '@material-ui/core/styles';
import userRegistrationFormStateToUserRegistration
	from '@components/UserRegistrationPage/utilities/userRegistrationFormStateToUserRegistration';
import { useFormContext } from 'react-hook-form';
import { UserRegistrationFormState } from '@components/UserRegistrationPage/UserRegistration';

const useStyles = makeStyles((theme) => ({
	container: {
		width: '600px',
		height: '220px'
	},
	headerText: {
		fontWeight: 500,
		marginBottom: theme.spacing(4)
	},
	buttonContainer: {
		marginTop: theme.spacing(4),
		display: 'flex',
		flexDirection: 'row',
		gap: theme.spacing(1),
		justifyContent: 'flex-end',
	},
	locationText: {
		fontWeight: 600,
		paddingRight: theme.spacing(.5),
		paddingLeft: theme.spacing(.5),
	}
}));

const UserLocationConfirmationModal = () => {
	const { name: location } = useSelector(UserProfilePrimaryPracticeSelector);
	const { closeModal } = useModalContext();
	const { createUserRegistration } = useCreateUserRegistration();
	const { getValues } = useFormContext<UserRegistrationFormState>();
	const classes = useStyles();

	const handleSubmit = React.useCallback(() => {
		const formValues = userRegistrationFormStateToUserRegistration(getValues());
		createUserRegistration(formValues);
		closeModal(USER_LOCATION_CONFIRMATION_MODAL);
	}, [getValues, createUserRegistration, closeModal]);

	return (
		<ModalViewer
			id={USER_LOCATION_CONFIRMATION_MODAL}
			onClose={()=> closeModal(USER_LOCATION_CONFIRMATION_MODAL)}
			shouldCloseOnBackdropClick={true}
			shouldCloseOnEscapeKeyDown={true}
		>
			<Box className={classes.container}>
				<Typography variant="h2" className={classes.headerText}>
					Confirm New User Location
				</Typography>
				<Box>
					<Typography component="span">
						You are about to add a new user to the office practice location
					</Typography>
					<Typography component="strong" className={classes.locationText}>{location}.</Typography>
					<Typography component="span">
						Are you sure this location is correct?
					</Typography>
				</Box>
				<Box className={classes.buttonContainer}>
					<Button variant="outlined" onClick={()=> closeModal(USER_LOCATION_CONFIRMATION_MODAL)}>Cancel</Button>
					<GoldButton onClick={handleSubmit}>Yes, Submit User</GoldButton>
				</Box>
			</Box>
		</ModalViewer>
	);
};

export default UserLocationConfirmationModal;
