import React from 'react';
import { CalendarProps } from '@calendar';
import { ScrollWithinBox } from '@components/ScrollWithinBox';
import CalendarProvider from '@calendar/state/CalendarProvider/CalendarProvider';

const CalendarWrapper: React.FC<CalendarProps> = ({
	children,
	timezone,
	interval,
	columns,
	events,
	eventConfig,
	focusOn,
	tickStart,
	onCalendarClick,
	onHeaderHeightChange,
	...scrollWithinBoxProps
}) => {
	const calendarProviderProps = {
		timezone,
		interval,
		columns,
		events,
		eventConfig,
		focusOn,
		tickStart,
		onCalendarClick,
		onHeaderHeightChange,
	};

	return (<ScrollWithinBox {...scrollWithinBoxProps}>
		<CalendarProvider {...calendarProviderProps}>
			{children}
		</CalendarProvider>
	</ScrollWithinBox>);
};

export default CalendarWrapper;
