import React from 'react';
import { Box, makeStyles, createStyles, BoxProps } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => createStyles({
	root: {
		border: `1px solid ${theme.palette.grey[800]}`,
		borderRadius: '4px',
		height: '100%',
		display: 'grid',
		gridTemplateAreas: `
			"header"
			"body"
			"footer"
		`,
		gridTemplateRows: '36px 1fr 80px',
		overflow: 'hidden',
	},
	withoutFooter: {
		border: `1px solid ${theme.palette.grey[800]}`,
		borderRadius: '4px',
		height: '100%',
		display: 'grid',
		gridTemplateAreas: `
			"header"
			"body"
		`,
		gridTemplateRows: '36px 1fr',
		overflow: 'hidden',
	}
}));

interface WrapperProps extends BoxProps {
	hasFooter?: boolean;
}

export const SectionedBoxWrapper: React.FC<WrapperProps> = ({ children, hasFooter = true, ...boxProps  }) => {
	const classes = useStyles();
	return (
		<Box className={classNames({ [classes.root]: hasFooter, [classes.withoutFooter]: !hasFooter })} {...boxProps}>
			{children}
		</Box>
	);
};
