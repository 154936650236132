import { UserRegistrationRole } from '../../interfaces/UserProfile/UserRoleEnum';
import { UserDetailsBasic } from '../../interfaces/UserPrefInterface';

export interface UserRegistration extends Omit<UserDetailsBasic, 'adEmail'> {
	userRoles: UserRegistrationRole[] | null; // null if nothing selected yet
	ext?: string;
	phone: string;
	email: string;
	hasAscensionUsername: number | null; // 0 - NO, 1 - YES, 2 - UNSURE, null - nothing selected
	ascensionUsername: undefined | string; // required when hasAscensionUsername is 1
	dobMonth: undefined | number; // 1 - January, 11 - December, required when hasAscensionUsername is NOT 1
	dobDay: undefined | number; // 1 - 1st of month, required when hasAscensionUsername is NOT 1
	lastFourSsn: undefined | string;
}

export interface UserRegistrationFormState extends Omit<UserRegistration, 'userRoles'> {
	userRole: string | null;
}

export const UserRegistrationFormDefaultValues: UserRegistrationFormState = {
	userRole: null,
	firstName: '',
	lastName: '',
	email: '',
	phone: '',
	ext: undefined,
	hasAscensionUsername: null,
	ascensionUsername: undefined,
	dobMonth: undefined,
	dobDay: undefined,
	lastFourSsn: undefined,
};
