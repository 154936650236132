import React from 'react';
import { ConflictDisplayProps } from './ConflictDisplayProps';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	container: {
		'width': '100%',
		'padding': theme.spacing(1),
		'margin': theme.spacing(1, 0, 3, 0),
		'boxSizing': 'border-box',
		'borderCollapse': 'collapse',
		'& tr': {
			display: 'flex',
			flexDirection: 'column',
			[theme.breakpoints.up(theme.drawerPane.breakpoint)]: {
				display: 'revert',
				flexDirection: 'row',
			},
		},
		'& tr > td': {
			'border': `1px solid ${theme.palette.grey[300]}`,
			'width': '100%',
			'padding': theme.spacing(1, 2),
			'boxSizing': 'border-box',
			[theme.breakpoints.up(theme.drawerPane.breakpoint)]: {
				width: '50%',
				height: '1px',
			},
			'&>div': {
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
			},
		},
		'& tr > td:first-child': {
			background: theme.palette.grey[50],
		},
	},
	cellLabel: {
		fontSize: '.75rem',
		lineHeight: '1rem',
		color: theme.palette.grey[900],
	},
	currentText: {
		textDecoration: 'line-through',
		lineHeight: '1.5rem',
		letterSpacing: '.03rem',
		color: theme.palette.grey[600],
		wordBreak: 'break-word',
	},
	externalText: {
		lineHeight: '1.5rem',
		letterSpacing: '.03rem',
	},
	error: {
		fontSize: '.75rem',
		lineHeight: '1rem',
		color: '#E00019',
		margin: theme.spacing(-1, 0, 3, 0)
	},
}));

export const ConflictDisplay = ({ label, current, external, error }: ConflictDisplayProps) => {
	const classes = useStyles();

	return (
		<div>
			<Typography variant="h3">{label}</Typography>
			<table className={classes.container}>
				<tbody>
					<tr>
						<td>
							<div>
								<span className={classes.cellLabel}>Original Request</span>
								<span className={typeof current === 'string' ? classes.currentText : ''} data-field="currentValue">{current}</span>
							</div>
						</td>
						<td>
							<div>
								<span className={classes.cellLabel}>EHR</span>
								<span className={classes.externalText} data-field="externalValue">{external}</span>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			{ error &&
				<p className={classes.error} data-field="conflictError">{ error }</p>
			}
		</div>
	);
};
