import { RevisionAlertBodyProps } from './RevisionAlertBodyProps';
import React, { ReactNode, useCallback } from 'react';
import { RevisionStatus } from '@interfaces/SurgeryRequest/RevisionStatusEnum';
import { Box, Flex, TextLink } from '@ascension/web';
import { AlertDisplayField } from '@components/Alerts/AlertDisplayField/AlertDisplayField';
import { AlertBody } from '@components/Alerts/AlertBody/AlertBody';
import RequestRevisionModal from '@components/CaseDetails/RequestRevisionModal/RequestRevisionModal';
import { utcToLocalTime } from '@utilities/dateUtilities';
import useHasPermission from '@utilities/hooks/useHasPermission/useHasPermission';
import { PermittedAction } from '@utilities/PermittedAction';
import { useCommonStyles } from '@utilities/hooks/useCommonStyles/useCommonStyles';
import useGetAccessor from '@components/CaseDetails/CaseInUse/hooks/useGetAccessor/useGetAccessor';
import { UserAccessEnum } from '@interfaces/SurgeryRequest/UserAccessEnum';
import { useModalContext } from '@components/Modal/ModalContextProvider';
import ModalViewer from '@components/Modal/ModalViewer';
import { REQUEST_REVISION_MODAL } from '@components/Modal/modalConstants';
import { defaultCallback } from '@utilities/defaultCallback';

export const RevisionAlertBody = ({
	revision: {
		id: revisionId,
		reason,
		message,
		dateCreated,
		status,
		userCreated,
	},
	onMarkApproved,
	onMarkDeclined,
}: RevisionAlertBodyProps) => {
	const {
		firstName,
		lastName,
	} = userCreated;

	const convertedDate = utcToLocalTime(dateCreated);

	const hasInteractiveRevisionAlertPermission = useHasPermission(PermittedAction.ACKNOWLEDGE_REVISION_ALERT);
	const {
		isAccessorDifferentUser: caseInUse,
	} = useGetAccessor([UserAccessEnum.CONFIRMER]);

	const handleDeclined = useCallback(() => {
		onMarkDeclined(revisionId, false);
	}, [onMarkDeclined, revisionId]);

	const handleApproved = useCallback(() => {
		onMarkApproved(revisionId, true);
	}, [onMarkApproved, revisionId]);

	const { openModal, closeModal } = useModalContext();

	const requestRevisionModalId = REQUEST_REVISION_MODAL + revisionId;

	const requestRevisionModalWrapper = (
		<ModalViewer id={requestRevisionModalId}>
			<RequestRevisionModal close={()=> closeModal(requestRevisionModalId)} handleDeclined={ handleDeclined }/>
		</ModalViewer>
	);

	const { allowTextBreak } = useCommonStyles();

	const handleOpenRequestRevisionModal = useCallback(() => {
		openModal(requestRevisionModalId);
	}, [openModal, requestRevisionModalId]);

	const links: ReactNode | ReactNode[] =
		status === RevisionStatus.REVISED &&
		hasInteractiveRevisionAlertPermission &&
		(
			<Box>
				<TextLink inactive={caseInUse} onClick={caseInUse ? defaultCallback : handleOpenRequestRevisionModal} mr="10px">Decline</TextLink>
				<TextLink inactive={caseInUse} onClick={caseInUse ? defaultCallback : handleApproved}>Approve</TextLink>
			</Box>
		);

	return (
		<>
			<AlertBody
				alertUser={firstName + ' ' + lastName}
				alertTime={convertedDate}
				body={<Flex py={3} data-section="revisionInfo">
					<AlertDisplayField
						label="Revision Reason"
					>
						{reason}
					</AlertDisplayField>
					<AlertDisplayField
						label="Message"
						className={allowTextBreak}
					>
						{message}
					</AlertDisplayField>
					<Box ml="auto">
						{links}
					</Box>
				</Flex>}
			/>
			{requestRevisionModalWrapper}
		</>
	);
};
