import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { GapDisplayProps } from '@components/SchedulePage/BlockEvent/GapDisplay/GapDisplayProps';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => createStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		borderLeft: `4px solid ${theme.palette.grey[400]}`,
		margin: theme.spacing(0, 1, 2, 2),
		padding: theme.spacing(0.75, 0, 0.75, 1),
	},
}));
export const GapDisplay: React.FC<GapDisplayProps> = ({ gapDetails }) => {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<Typography variant="body2">{gapDetails.timeRange}</Typography>
			<Typography variant="body2">({gapDetails.durationHrsMins})</Typography>
		</Box>
	);
};
