import React from 'react';
import { useDispatch } from 'react-redux';
import getSlots from '@data/schedules/actionCreators/getSlots';
import SlotRequest from '@data/schedules/types/SlotRequest';

const useGetSlots = () => {
	const dispatch = useDispatch();

	return React.useCallback((requestOverrides?: SlotRequest) => {
		dispatch(getSlots(requestOverrides));
	}, [dispatch]);
};

export default useGetSlots;
