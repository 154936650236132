import React from 'react';
import { Box, Flex } from '@ascension/web';
import { Button as AscButton } from '@ascension/ui-library';
import { UserDetailsStatusApiInterface } from '../../../interfaces/UserPrefInterface';
import { updateUserProfileStatus } from '../../../store/actions/UserProfileActionCreators';
import { useDispatch } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/core';
import { ToastType } from '../../../interfaces/ToastStatus/ToastStatusState';
import { useToast } from '../../../utilities/hooks/useToast/useToast';
import { defaultCallback } from '../../../utilities/defaultCallback';
import { UserStatus } from '../../../interfaces/UserProfile/UserStatusEnum';
import { SetupConfirmListProps } from './SetupConfirmListProps';
import { YesNoAnswer } from '../../../interfaces/SurgeryRequest/YesNoAnswer';

const useStyles = makeStyles((theme) => createStyles({
	root: {
		paddingTop: theme.spacing(2),
	},
	btnContainer: {
		'display': 'flex',
		'alignItems': 'center',
		'paddingRight': theme.spacing(2),
		'height': theme.spacing(6),
		'& .MuiButton-outlinedSizeLarge': {
			padding: '8px 11px',
		}
	},
}));



export default function SetupConfirmList({ setInformationConfirmed, disabled }: SetupConfirmListProps) {
	const dispatch = useDispatch();
	const displayToast = useToast();
	const classes  = useStyles();
	const [selectedOption, setSelectedOption] = React.useState<YesNoAnswer | ''>('');

	const onSubmitFailure = () => {
		displayToast(ToastType.ERROR, 'Server error, please try again later');
	};

	const handleLock = () => {
		if (disabled) { return; }
		setSelectedOption(YesNoAnswer.NO);
		const payload: UserDetailsStatusApiInterface = {
			status: UserStatus.LOCK
		};
		dispatch(updateUserProfileStatus(payload, defaultCallback, onSubmitFailure));
	};

	const confirmInformation = () => {
		setSelectedOption(YesNoAnswer.YES);
		!disabled && setInformationConfirmed(true);
	};

	return (
		<Flex className={classes.root}>
			<Box className={classes.btnContainer}>
				<AscButton disabled={disabled} variant={selectedOption === YesNoAnswer.YES ? 'outlined' : 'text'} translate="yes" id="Yes" onClick={confirmInformation} color="primary">Yes</AscButton>
			</Box>
			<Box className={classes.btnContainer}>
				<AscButton disabled={disabled} variant={selectedOption === YesNoAnswer.NO ? 'outlined' : 'text'} translate="yes" id="No" onClick={handleLock} color="primary">No</AscButton>
			</Box>
		</Flex>
	);
}
