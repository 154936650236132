enum OREventType {
	AVAILABLE = 'AVAILABLE',
	UNAVAILABLE = 'UNAVAILABLE',
	BLOCKTIME = 'BLOCKTIME',
	SOFT_BLOCK = 'SOFT_BLOCK',
	SCHEDULED = 'SCHEDULED',
	UNSCHEDULED = 'UNSCHEDULED',
	WARNING = 'WARNING',
}

export default OREventType;
