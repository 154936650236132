import { HOLD_EVENT_OVERLAPPING_TIME } from '@utilities/Validation/ValidationMessages';
import { doEventsOverlap, StartEndEvent } from '@calendar';
import getDateFromApiDateTime from '@calendar/utilities/getDateFromApiDateTime/getDateFromApiDateTime';
import {
	CheckTimeRangeProps,
	decorateWithIndicator,
	getStartEndFromTimeSpan,
	handleCommonTimeSpanValidation,
	Indicator,
	PartialTimeSpan,
	validateStartEndTimeSpan,
} from '@utilities/Validation/TimeSpanValidationUtilities/TimeSpanValidationUtilities';

const doesStartOrEndTimeOverlap = (overlapEvent: StartEndEvent, holdEvent: StartEndEvent): string[] => {
	const overlapStart = getDateFromApiDateTime(overlapEvent.start).getTime();
	const overlapEnd = getDateFromApiDateTime(overlapEvent.end).getTime();
	const holdStart = getDateFromApiDateTime(holdEvent.start).getTime();
	const holdEnd = getDateFromApiDateTime(holdEvent.end).getTime();
	const overlaps = [];
	// if end of hold event is later than overlap start & before or at the same time as overlap end
	if (holdEnd > overlapStart && holdEnd <= overlapEnd) {
		// indicate end is overlapping
		overlaps.push(Indicator.END);
	}
	// if start of hold event is at the same time or after as overlap start & before overlap end
	if (holdStart >= overlapStart && holdStart < overlapEnd) {
		// indicate start is overlapping
		overlaps.push(Indicator.START);
	}
	// Consider the scenario where event fully overlaps
	// holdEvent {start: 8:00 AM, end: 12:00 PM}, overlapEvent: {9:00 AM, end: 11:00 AM}
	if (!overlaps.length && doEventsOverlap(overlapEvent, holdEvent)) {
		overlaps.push(Indicator.START);
		overlaps.push(Indicator.END);
	}
	return overlaps;
};

export const decorateErrMsgBasedOnOverlaps = ({ timeSpan, overlapEvents, overlapMsg }: CheckTimeRangeProps): string | undefined => {
	const { startTime, endTime } = getStartEndFromTimeSpan(timeSpan) || {};
	if (!startTime || !endTime) { return; }
	const overlaps = overlapEvents.flatMap(event => {
		return doesStartOrEndTimeOverlap(event, { start: startTime, end: endTime });
	});

	if (overlaps.includes(Indicator.START) && overlaps.includes(Indicator.END)) {
		return decorateWithIndicator(Indicator.END, decorateWithIndicator(Indicator.START, overlapMsg));
	}

	if (overlaps.includes(Indicator.START)) {
		return decorateWithIndicator(Indicator.START, overlapMsg);
	}

	if (overlaps.includes(Indicator.END)) {
		return decorateWithIndicator(Indicator.END, overlapMsg);
	}
};

export const checkHoldEventTimeRange = ({ timeSpan, overlapEvents, overlapMsg }: CheckTimeRangeProps): string | void => {
	const basicValidationResult = validateStartEndTimeSpan(timeSpan);
	if (basicValidationResult) {
		return basicValidationResult;
	}

	return decorateErrMsgBasedOnOverlaps({ timeSpan, overlapEvents, overlapMsg });
};

const TimeSpanValidationSchema = {
	validate: {
		checkAll: (currentValue: PartialTimeSpan = {}) => {
			const { start, end, overlapEvents = [] } = currentValue;
			const errors = handleCommonTimeSpanValidation(currentValue);

			if (start && end) {
				const decoratedTimeRangeMsg = checkHoldEventTimeRange({
					timeSpan: currentValue,
					overlapEvents,
					overlapMsg: HOLD_EVENT_OVERLAPPING_TIME,
				});

				if (decoratedTimeRangeMsg) {
					errors.push(decoratedTimeRangeMsg);
				}
			}

			return errors.join(';') || true;
		}
	}
};

export const HoldTimeValidationSchema = {
	timeSpan: TimeSpanValidationSchema,
};
