import React from 'react';
import { useSelector } from 'react-redux';
import useHasEhrIntegrationAndRole from '@utilities/hooks/useHasEhrIntegrationAndRole/useHasEhrIntegrationAndRole';
import { CaseDetailsInfoSelector } from '@store/selectors/CaseDetailsSelector';
import { Box, Flex } from '@ascension/web';
import { DisplayText } from '@components/DisplayText/DisplayText';
import EditIcon from '@material-ui/icons/Edit';
import { Button as AscButton } from '@ascension/ui-library';
import { Button, createStyles, makeStyles } from '@material-ui/core';
import { CONFIRMATION_INPUT_CONFIG } from '@components/Alerts/UnscheduledAlert/ConfirmCaseInput/ConfirmCaseInputConfig';
import { UserProfileHasARoleSelector } from '@store/selectors/UserProfileSelector';
import { HospitalInteractiveRoles } from '@interfaces/UserProfile/UserRoleEnum';
import ConfirmCaseInput from '../../Alerts/UnscheduledAlert/ConfirmCaseInput/ConfirmCaseInput';
import { IntegrationType } from '@interfaces/IntegrationType';
import { useCommonStyles } from '@utilities/hooks/useCommonStyles/useCommonStyles';
import { UserAccessEnum } from '@interfaces/SurgeryRequest/UserAccessEnum';
import useGetAccessor from '@components/CaseDetails/CaseInUse/hooks/useGetAccessor/useGetAccessor';
import { PermittedAction } from '@utilities/PermittedAction';

const useStyles = makeStyles((theme) => createStyles({
	editIcon: {
		width: '24px',
		height: '24px',
		color: theme.palette.primary.main,
		cursor: 'pointer'
	},
	cancelButton : {
		color: theme.palette.primary.main
	},
	caseNumberErrorStyle: {
		fontSize: '12px',
		margin: 0,
	},
}));

interface EditConfirmationNumberProps {
	type: IntegrationType;
	allowEdit?: boolean;
}

const EditConfirmationNumber: React.FC<EditConfirmationNumberProps> = ({ type, allowEdit }) => {
	const { editIcon, cancelButton } = useStyles();
	const { isAccessorDifferentUser: caseInUse } = useGetAccessor([UserAccessEnum.CONFIRMER], PermittedAction.CONFIRM_CASE_SCHEDULE);
	const { rowGap } = useCommonStyles();
	const {
		requestDetailsProperty,
		label,
	} = CONFIRMATION_INPUT_CONFIG[type];
	const caseDetails = useSelector(CaseDetailsInfoSelector);
	let existingConfirmationNumber = '';
	if (caseDetails[requestDetailsProperty] !== null) {
		existingConfirmationNumber = String(caseDetails[requestDetailsProperty]);
	}
	const [confirmationNumber, setConfirmationNumber] = React.useState<string>(existingConfirmationNumber);

	const [shouldShowInput, setShouldShowInput] = React.useState(false);
	const isHospitalInteractiveRole = useSelector(UserProfileHasARoleSelector(HospitalInteractiveRoles));

	const hasEhrIntegration = useHasEhrIntegrationAndRole(caseDetails);

	React.useEffect(() => {
		setConfirmationNumber(existingConfirmationNumber);
	}, [existingConfirmationNumber]);

	const setDefaultView = React.useCallback(() => {
		setConfirmationNumber(existingConfirmationNumber);
		setShouldShowInput(false);
	}, [existingConfirmationNumber]);

	const showInput = React.useCallback(() => {
		setShouldShowInput(true);
	}, []);

	const hideInput = React.useCallback(() => {
		setShouldShowInput(false);
	}, []);

	return (
		<>
			{!shouldShowInput || !allowEdit ? (
				<Flex width={label === 'FIN' ? 'fit-content' : '250px'} className={rowGap}>
					<DisplayText
						label={label}
						defaultValue="--"
						displayDefault={!confirmationNumber}
					>
						<Flex>
							<Box textAlign="center" mr={3} >
								{confirmationNumber}
							</Box>
							{isHospitalInteractiveRole && !(label === 'FIN' && hasEhrIntegration) &&
								<Button className={editIcon} onClick={showInput} id="editConfirmationNumber" disabled={caseInUse}>
									<EditIcon />
								</Button>
							}
						</Flex>
					</DisplayText>
				</Flex>
			) : (
				<Flex pb={2} flexGrow={1} flexShrink={1} maxWidth="598px" mr={30}>
					<ConfirmCaseInput initialConfirmationNumber={existingConfirmationNumber} onSuccessCallback={hideInput}>
						<Box mx={4} mb={5}>
							<AscButton translate="yes" onClick={setDefaultView} className={cancelButton} id="resetCancelButton">Cancel</AscButton>
						</Box>
					</ConfirmCaseInput>
				</Flex>
			)}
		</>
	);
};

export default EditConfirmationNumber;
