import OREventType from '@data/openCalendar/OREventType';
import EventStyleConfig from '@interfaces/OpenCalendar/EventStyleConfig';

export const HospitalEventStyleConfig: EventStyleConfig<OREventType> = {
	[OREventType.UNAVAILABLE]: {
		card: {
			background: 'repeating-linear-gradient(-45deg, #EEEEEE, #EEEEEE 5px, #E0E0E0 5px, #E0E0E0 10px)',
			border: '1px solid #424242',
			zIndex: 100,
		},
		legend: {
			background: 'repeating-linear-gradient(-45deg, #EEEEEE, #EEEEEE 5px, #E0E0E0 5px, #E0E0E0 10px)',
			border: '1px solid #9E9E9E',
			fill: '#1D1D1D'
		},
	},
	[OREventType.BLOCKTIME]: {
		card: {
			background: '#1E69D214',
			border: '1px solid #1551B4',
			zIndex: 200,
		},
		legend: {
			background: '#E4EDFA',
			border: '2px solid #1E69D2',
			fill: '#1551B4'
		},
	},
	[OREventType.SOFT_BLOCK]: {
		card: {
			background: '#EEEEEE',
			border: '1px dashed #424242',
			zIndex: 300,
		},
		legend: {
			background: '#EEEEEE',
			border: '2px dashed #D1D1D1',
		},
	},
	[OREventType.UNSCHEDULED]: {
		card: {
			background: '#FFF7E6',
			border: '1px dashed #FF7F00',
			zIndex: 400,
		},
		legend: {
			background: '#FFF7E6',
			border: '2px dashed #FF7F00',
		},
	},
	[OREventType.SCHEDULED]: {
		card: {
			background: '#E9FBE7',
			border: '1px solid #126E36',
			zIndex: 500,
		},
		legend: {
			background: '#E9FBE7',
			border: '2px solid #126E36',
		},
	},
	[OREventType.WARNING]: {
		card: {
			background: '#FEEBE5',
			border: '1px solid #E00019',
			zIndex: 400,
		},
		legend: {
			background: '#E00019',
			border: '2px solid #E00019',
			fill: '#FFF'
		},
	},
	[OREventType.AVAILABLE]: {
		legend: {
			background: '#FFF',
			border: '2px solid #E0E0E0'
		},
	}
};
