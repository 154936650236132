import React from 'react';
import * as amplitude from '@amplitude/analytics-browser';

const isElement = (target: EventTarget | null): target is Element => {
	return target instanceof Element;
};

const handleInteraction = (event: Event) => {
	let targetElement: EventTarget | null = event.target;

	// Navigate up the DOM tree until we find an element with a data-field attribute
	while (
		targetElement && !isElement(targetElement) ||
		(isElement(targetElement) && !targetElement.hasAttribute('data-field'))
	) {
		if (!isElement(targetElement)) {
			// Break if targetElement is not an Element
			break;
		}
		targetElement = targetElement.parentElement;
	}

	// If we found an element with a data-field attribute, log the event
	if (isElement(targetElement) && targetElement.hasAttribute('data-field')) {
		const fieldName = targetElement.getAttribute('data-field');
		amplitude.logEvent('data-field click', {
			fieldName,
			eventType: event.type,
		});
	}
};

export const useDataFieldInteractionTracking = (): void => {
	React.useEffect(() => {
		document.body.addEventListener('click', handleInteraction);
		return () => {
			document.body.removeEventListener('click', handleInteraction);
		};
	}, []);
};
