/***
 * These values are used to generate automatic configs so values must be maintained.
 * If an update is necessary, in addition to src refactors (should be automatic) be sure to update BASE_URL_{#} / BASE_URL_{#}_{ENV}:
 *  - manifest.yml - ensure correct endpoint assigned to corresponding value if necessary
 *  - config.js - ensure correct endpoint assigned to corresponding value
 *  - .profile - ensure max number is supported in script
 */
enum ApiService {
	SSM = 1,
	USER = 2,
	BTS = 3,
	DS = 4,  //Documents Service
}

export default ApiService;
