import React from 'react';
import { CaseActivityContextType } from '@components/CaseActivityDrawer/CaseActivityContext/CaseActivityContextType';
import CaseActivityRowType from '@components/CaseActivityDrawer/types/CaseActivityRowType';
import useGetCaseActivities from '@data/caseActivities/hooks/useGetCaseActivities';
import { AxiosResponse } from 'axios';
import { useCaseDetailsInfoContext } from '@components/CaseDetails/CaseDetailsContext/CaseDetailsInfoContext/CaseDetailsInfoContext';
import sortByMostRecentDate from '@components/CaseActivityDrawer/utilities/sortByMostRecentDate';
import { useDrawerContext } from '@components/DrawerWrapper/DrawerContextProvider';
import { CASE_ACTIVITY_DRAWER } from '@components/DrawerWrapper/drawerConstants';

const CaseActivityContext = React.createContext<CaseActivityContextType | null>(null);

/**
 * useCaseActivityContext - should be used to access/manage state related to the case activity UI
 *
 * @return CaseActivityContextType
 */
export const useCaseActivityContext = () => {
	const ctx = React.useContext(CaseActivityContext);

	if (!ctx) {
		throw new Error('useCaseActivityContext must be used in a subcomponent of CaseActivityContextProvider');
	}

	return ctx;
};

const CaseActivityContextProvider: React.FC = ({ children }) => {
	const [caseActivityEvents, setCaseActivityEvents] = React.useState<CaseActivityRowType[]>([]);
	const getCaseActivities = useGetCaseActivities();
	const { caseId: requestId } = useCaseDetailsInfoContext();
	const { activeDrawer } = useDrawerContext();

	const handleGetCaseActivities = React.useCallback(()=> {
		const onSuccess = (res: AxiosResponse<CaseActivityRowType[]>) => {
			setCaseActivityEvents(res.data
				.sort((a, b) => sortByMostRecentDate(a.createdTime, b.createdTime)));
		};
		getCaseActivities(requestId, { onSuccess });
	}, [requestId, getCaseActivities]);

	React.useEffect(()=>{
		if (activeDrawer === CASE_ACTIVITY_DRAWER) {
			handleGetCaseActivities();
		}
	}, [handleGetCaseActivities, activeDrawer]);

	return (
		<CaseActivityContext.Provider value={{
			caseActivityEvents,
			setCaseActivityEvents,
			handleGetCaseActivities
		}}>
			{children}
		</CaseActivityContext.Provider>
	);
};

export default CaseActivityContextProvider;
