import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { MenuItem, SvgIconTypeMap } from '@material-ui/core';
import { UserRole } from '@interfaces/UserProfile/UserRoleEnum';
import React from 'react';
import { useSelector } from 'react-redux';
import { UserProfileRoleSelector } from '@store/selectors/UserProfileSelector';
import { arraysOverlap } from '@utilities/commonFunction';
import { useAnchorContext } from '@components/AnchorProvider/AnchorProvider';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

export interface LinkMenuOptionProps {
	id: string;
	label: string;
	icon: OverridableComponent<SvgIconTypeMap>;
	showForRoles?: UserRole[];
	shouldHide?: boolean;
	onClick: () => void;
	order: number;
	autoFocus?: boolean;
	isActive?: boolean;
}

const useStyles = makeStyles((theme) => ({
	activeMenuOption: {
		background: theme.palette.primary[50],
		border: `2px solid ${theme.palette.primary.main}`,
		borderRadius: '0 0 8px 8px',
		boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
	},
}));

export const LinkMenuOption: React.FC<LinkMenuOptionProps> = ({ showForRoles, shouldHide, id, onClick, label, icon: Icon, autoFocus, isActive  }) => {
	const classes = useStyles();
	const { handleClearAnchorEl } = useAnchorContext();

	const handleClick = () => {
		onClick();
		handleClearAnchorEl();
	};

	const userRoles = useSelector(UserProfileRoleSelector);

	if (shouldHide) return null;
	if (showForRoles && !arraysOverlap(userRoles, showForRoles)) return null;

	return (
		<MenuItem
			onClick={handleClick}
			data-field={id}
			autoFocus={autoFocus}
			className={classNames({
				[classes.activeMenuOption]: isActive,
			})}
		>
			<Icon/>
			{label}
		</MenuItem>
	);
};
