import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => createStyles({
	root: {
		'position': 'relative',
		'&::-webkit-scrollbar': {
			width: theme.spacing(1),
			height: theme.spacing(1),
			color: theme.palette.primary[400],
		},
		'&::-webkit-scrollbar-track:horizontal': {
			backgroundRepeat: 'repeat-x',
		},
		'&::-webkit-scrollbar-track:vertical': {
			backgroundRepeat: 'repeat-y',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.palette.primary[400],
			borderRadius: theme.spacing(.5),
		},
		'&::-webkit-scrollbar-track': {
			backgroundImage: 'url("/assets/gray.png")',
			backgroundColor: 'transparent',
			backgroundPosition: 'center',
		},
	},
}));

const BoxFancyScroll: React.RefForwardingComponent<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>> = (({ className, children, ...props }, ref) => {
	const classes = useStyles();

	return <div ref={ref} className={classNames(classes.root, className)} {...props}>
		{children}
	</div>;
});

export default React.forwardRef(BoxFancyScroll);
