import React from 'react';
import { useDispatch } from 'react-redux';
import EventApiPayload from '@data/openCalendar/EventApiPayload';
import { createOREventsGetAction } from '@data/openCalendar/OREvents/OREventsActionCreators';

const useGetOREvents = () => {
	const dispatch = useDispatch();

	return React.useCallback((requestOverrides: EventApiPayload) => {
		dispatch(createOREventsGetAction(requestOverrides));
	}, [dispatch]);
};

export default useGetOREvents;
