import OREventType from '@data/openCalendar/OREventType';
import UnscheduledEventCard from '@components/UnitCalendarPage/EventCard/UnscheduledEventCard';
import UnavailableEventCard from '@components/UnitCalendarPage/EventCard/UnavailableEventCard';
import BlockTimeEventCard from '@components/UnitCalendarPage/EventCard/BlockTimeEventCard';
import PreBookedEventCard from '@components/UnitCalendarPage/EventCard/PreBookedEventCard';
import ScheduledEventCard from '@components/UnitCalendarPage/EventCard/ScheduledEventCard';
import WarningEventCard from '@components/UnitCalendarPage/EventCard/WarningEventCard';
import BlockIcon from '@material-ui/icons/Block';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import EventConfig from '@calendar/types/EventConfig';

export const HospitalCalendarEventConfig: EventConfig<OREventType> = {
	[OREventType.UNAVAILABLE]: {
		cardComponent: UnavailableEventCard,
		icon: BlockIcon,
		legendLabel: 'OR unavailable',
	},
	[OREventType.BLOCKTIME]: {
		cardComponent: BlockTimeEventCard,
		icon: PermContactCalendarIcon,
		legendLabel: 'Block time',
	},
	[OREventType.SOFT_BLOCK]: {
		cardComponent: PreBookedEventCard,
		legendLabel: 'Softblock',
	},
	[OREventType.UNSCHEDULED]: {
		cardComponent: UnscheduledEventCard,
		legendLabel: 'Unscheduled',
	},
	[OREventType.SCHEDULED]: {
		cardComponent: ScheduledEventCard,
		legendLabel: 'Scheduled',
	},
	[OREventType.WARNING]: {
		cardComponent: WarningEventCard,
		icon: ReportProblemOutlinedIcon,
		legendLabel: 'Warning',
	},
	[OREventType.AVAILABLE]: {
		legendLabel: 'OR available',
	},
};
