import React from 'react';
import Divider from '@material-ui/core/Divider';
import { SurgicalProcedureProps } from './SurgicalProcedureProps';
import { DisplayText } from '../../DisplayText/DisplayText';
import { ENDPOINT_SURGEONS } from '@utilities/apiConstants';
import { Surgeon } from '@data/surgeon/Surgeon';
import { getFormattedName } from '@utilities/commonFunction';
import { useCollectionEntity } from '@utilities/hooks/useCollectionEntity/useCollectionEntity';
import useDetailSectionGrid, {
	ProcedureGridFields,
} from '../useDetailSectionGrid/useDetailSectionGrid';
import DetailSection from '../DetailSection';
import { useCommonStyles } from '@utilities/hooks/useCommonStyles/useCommonStyles';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	divider: {
		backgroundColor: theme.palette.grey['300'],
		marginBottom: theme.spacing(3),
	},
}));

export const SurgicalProcedureDisplay = ({ procedure }: SurgicalProcedureProps) => {
	const {
		name,
		surgeonId = '',
		modifierSide,
		modifierApproach,
		anesthesiaType,
		comments,
		otherEquipments,
		vendor,
		vendorContacted,
		equipment,
	} = procedure || {};
	const surgeon = useCollectionEntity<Surgeon>(ENDPOINT_SURGEONS, surgeonId, 'id') || {};
	const implantsNeeded = procedure ? procedure.implantsNeeded : '';
	const robotic = procedure && procedure.robotic ? procedure.robotic : false;
	const primary = procedure && procedure.primary ? procedure.primary : false;
	const cptCode =
		procedure && procedure.cptCodes && procedure.cptCodes.length
			? procedure.cptCodes.join(', ')
			: '';
	const procedureHasEquipment = !!equipment && equipment?.length > 0;

	const { rowGap } = useCommonStyles();
	const { gridContainer } = useDetailSectionGrid(DetailSection.PROCEDURE);
	const classes = useStyles();

	const equipmentList = equipment?.map((equip) => equip.label).join(' | ');
	return (
		<>
			<Divider className={classes.divider} />
			<div className={`${gridContainer} ${rowGap}`}>
				<DisplayText
					label="Procedure Name"
					defaultValue="--"
					displayDefault={!name}
					data-field="Surgery_procedureName"
					style={{ gridArea: ProcedureGridFields.PROC_NAME }}
				>
					{name}
				</DisplayText>
				<DisplayText
					label="Surgeon"
					data-field="Surgery_surgeon"
					style={{ gridArea: ProcedureGridFields.SURGEON }}
				>
					{getFormattedName(surgeon, '--')}
				</DisplayText>
				<DisplayText
					label="CPT CODE(S)"
					defaultValue="--"
					displayDefault={!cptCode}
					data-field="Surgery_CPTCODE"
					style={{ gridArea: ProcedureGridFields.CPT_CODES }}
				>
					{cptCode}
				</DisplayText>
				<DisplayText
					label="PRIMARY PROCEDURE?"
					defaultValue="--"
					displayDefault={!procedure}
					data-field="Surgery_priProcedure"
					style={{ gridArea: ProcedureGridFields.PRIM_PROC }}
				>
					{primary ? 'YES' : 'NO'}
				</DisplayText>
				<DisplayText
					label="Modifier side"
					defaultValue="--"
					displayDefault={!modifierSide}
					data-field="Surgery_modifierSide"
					style={{ gridArea: ProcedureGridFields.MODIFIER }}
				>
					{modifierSide}
				</DisplayText>
				<DisplayText
					label="APPROACH"
					defaultValue="--"
					displayDefault={!modifierApproach}
					data-field="Surgery_approach"
					style={{ gridArea: ProcedureGridFields.APPROACH }}
				>
					{modifierApproach}
				</DisplayText>
				<DisplayText
					label="Anesthesia Type"
					defaultValue="--"
					displayDefault={!anesthesiaType}
					data-field="Surgery_anesthesia"
					style={{ gridArea: ProcedureGridFields.ANESTHESIA }}
				>
					{anesthesiaType?.join(' + ')}
				</DisplayText>
				<DisplayText
					label="IMPLANTS NEEDED?"
					defaultValue="--"
					displayDefault={!procedure}
					data-field="Surgery_impNeeded"
					style={{ gridArea: ProcedureGridFields.IMPLANTS_NEEDED }}
				>
					{implantsNeeded ? 'YES' : 'NO'}
				</DisplayText>
				{implantsNeeded && (
					<>
						<DisplayText
							label="VENDOR NAME"
							defaultValue="--"
							displayDefault={!vendor}
							data-field="VENDOR_NAME"
							style={{ gridArea: ProcedureGridFields.VENDOR }}
						>
							{vendor}
						</DisplayText>
						<DisplayText
							label="VENDOR CONTACTED"
							defaultValue="--"
							displayDefault={!procedure}
							data-field="VENDOR_CONTACTED"
							style={{ gridArea: ProcedureGridFields.VENDOR_CONTACT }}
						>
							{vendorContacted ? 'YES' : 'NO'}
						</DisplayText>
					</>
				)}
				<DisplayText
					label="Robot Procedure?"
					defaultValue="--"
					displayDefault={!procedure}
					data-field="Surgery_Robotic"
					style={{ gridArea: ProcedureGridFields.ROBOTIC }}
				>
					{robotic ? 'YES' : 'NO'}
				</DisplayText>
				<DisplayText
					label="Equipment"
					data-field="Equipment"
					defaultValue="--"
					displayDefault={!procedureHasEquipment}
					className={rowGap}
					style={{ gridArea: ProcedureGridFields.EQUIPMENT }}
				>
					{equipmentList}
				</DisplayText>
				{otherEquipments && (
					<DisplayText
						label="Other Equipment and supplies"
						data-field="Other_Equipment_and_supplies"
						className={rowGap}
						style={{ gridArea: ProcedureGridFields.OTHER_EQUIPMENT }}
					>
						{otherEquipments}
					</DisplayText>
				)}
			</div>

			{comments && (
				<DisplayText
					label="Additional Procedure comments"
					data-field="Additional_Procedure_Comments"
					className={rowGap}
				>
					{comments}
				</DisplayText>
			)}
		</>
	);
};
