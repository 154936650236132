import React from 'react';
import DOBMonthDayInput from '../../../DOBMonthDayInput/DOBMonthDayInput';
import { useFieldFormContext } from '../../../../utilities/hooks/useFieldFormContext/useFieldFormContext';
import { BasicUserInfoFormValidationSchema } from '../BasicUserInfoFormValidationSchema';

const DOBMonthDayInputWrapper = () => {
	const {
		currentValue: monthCurrentVal,
		setCurrentValue: setMonthCurrentVal,
		error: monthError,
	} = useFieldFormContext<number>('dobMonth', {
		validationRules: BasicUserInfoFormValidationSchema.dobMonth,
		triggerValidationOnChange: true,
	});
	const {
		currentValue: dayCurrentVal,
		setCurrentValue: setDayCurrentVal,
		error: dayError,
	} = useFieldFormContext<number>('dobDay', {
		validationRules: BasicUserInfoFormValidationSchema.dobDay,
		triggerValidationOnChange: true,
	});

	return <DOBMonthDayInput
		monthVal={monthCurrentVal}
		setMonthVal={setMonthCurrentVal}
		monthError={monthError}
		dayVal={dayCurrentVal}
		setDayVal={setDayCurrentVal}
		dayError={dayError}
		required
		label="User's date of birth"
	/>;
};

export default DOBMonthDayInputWrapper;
