import React from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import {
	UserPreferenceSelector,
	UserProfileHasARoleSelector,
	UserProfilePrimaryPracticeSelector
} from '@store/selectors/UserProfileSelector';
import { UserFormPractice } from '@interfaces/UserPrefInterface';
import { OfficeRoles } from '@interfaces/UserProfile/UserRoleEnum';
import ConfirmSelectionModal from '@components/MultiPracticeDropdown/ConfirmSelectionModal/ConfirmSelectionModal';
import { ModalContextProvider, useModalContext } from '@components/Modal/ModalContextProvider';
import { SELECT_PRACTICE_CONFIRM_MODAL } from '@components/Modal/modalConstants';

const useStyles = makeStyles(theme => {
	return {
		container: {
			flexDirection: 'row',
			alignItems: 'center',
			fontSize: '18px',
			color: theme.palette.common.white,
			padding: theme.spacing(1)
		},
		formControl: {
			'maxWidth': '305px',
			'& .MuiInputLabel-formControl': {
				color: 'white',
				transform: 'translate(14px, -3px) scale(0.74)',
			},
		},
		inputLabel: {
			display: 'flex',
			margin: 'auto',
			fontSize: '1rem',
			lineHeight: .5,
		},
		select: {
			'height': '45px',
			'color': theme.palette.common.white,
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: theme.palette.common.white,
			},
			'& .MuiSelect-iconOutlined': {
				color: theme.palette.common.white
			},
		},
	};
});

const PracticeMenuItem = ({ id, name }: UserFormPractice) => (
	<MenuItem key={id} value={id}>
		{name}
	</MenuItem>
);

const MultiPracticeDropdownInner: React.FC = () => {
	const classes = useStyles();
	const { practices } = useSelector(UserPreferenceSelector);
	const { id: selectedPractice } = useSelector(UserProfilePrimaryPracticeSelector);
	const hasOfficeRole = useSelector(UserProfileHasARoleSelector(OfficeRoles));
	const [newPracticeId, setNewPracticeId] = React.useState('');
	const { openModal } = useModalContext();

	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		if (typeof event.target.value === 'string') {
			const selectedPractice = event.target.value;
			if (selectedPractice) {
				setNewPracticeId(selectedPractice);
				openModal(SELECT_PRACTICE_CONFIRM_MODAL);
			}
		}
	};

	const alphabeticalPractices = React.useMemo(() => practices && practices.sort((a, b) => {
		if (!a.name || !b.name) { return 0; }
		if (a.name > b.name) { return 1; }
		if (a.name < b.name) { return -1; }
		return 0;
	}), [practices]);

	if (!hasOfficeRole || (practices && practices?.length <= 1)) return null;

	return (
		<Box className={classes.container}>
			<FormControl variant="outlined" className={classes.formControl}>
				<InputLabel id="select-location-label" className={classes.inputLabel}>Location</InputLabel>
				<Select
					id="select-location"
					labelId="select-location-label"
					value={selectedPractice}
					onChange={handleChange}
					label="Location"
					className={classes.select}
					MenuProps={{
						anchorOrigin: {
							vertical: 'bottom',
							horizontal: 'left',
						},
						transformOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},
						getContentAnchorEl: null,
					}}
				>
					{alphabeticalPractices?.map(PracticeMenuItem)}
				</Select>
			</FormControl>
			<ConfirmSelectionModal
				oldLocationId={selectedPractice}
				newLocationId={newPracticeId}
			/>
		</Box>
	);
};

const MultiPracticeDropdown = () => {
	return (
		<ModalContextProvider>
			<MultiPracticeDropdownInner />
		</ModalContextProvider>
	);
};

export default MultiPracticeDropdown;
