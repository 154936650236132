import { Reducer } from 'redux';
import { SharedFiltersState } from '@interfaces/SharedFilters/SharedFiltersState';
import { SharedFiltersActions } from '@interfaces/SharedFilters/SharedFiltersActions';
import { UPDATE_SELECTED_FACILITY } from '@store/actions/types';
import { UPDATE_SELECTED_SURGEONS } from '@store/actions/types';

const initialState = {
	selectedSurgeons: [],
	selectedFacilityId: '',
};

export const SharedFiltersReducer: Reducer<SharedFiltersState, SharedFiltersActions> = (state = initialState, action: SharedFiltersActions) => {
	switch (action.type) {
	case UPDATE_SELECTED_SURGEONS:
		return {
			...state,
			selectedSurgeons: action.payload,
		};
	case UPDATE_SELECTED_FACILITY:
		return {
			...state,
			selectedFacilityId: action.payload,
		};
	}
	return state;
};
