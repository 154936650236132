import React from 'react';
import { OfficeCalendarError } from '@components/OfficeCalendarView/OfficeCalendarError';
import OutOfRangeFutureCalendarError
	from '@components/OfficeCalendarView/errorOverlayContent/OutOfRangeFutureCalendarError';
import UnableToLoadOfficeCalendarError
	from '@components/OfficeCalendarView/errorOverlayContent/UnableToLoadOfficeCalendarError';
import NoAvailableTimesCalendarError
	from '@components/OfficeCalendarView/errorOverlayContent/NoAvailableTimesCalendarError';
import OutOfRangePastCalendarError
	from '@components/OfficeCalendarView/errorOverlayContent/OutOfRangePastCalendarError';

const officeErrorByType: Record<OfficeCalendarError, React.FC | undefined> = {
	[OfficeCalendarError.NONE]: undefined,
	[OfficeCalendarError.TIME_NO_LONGER_AVAIL]: undefined,
	[OfficeCalendarError.GENERAL_FAIL]: UnableToLoadOfficeCalendarError,
	[OfficeCalendarError.NO_OPEN_TIME]: NoAvailableTimesCalendarError,
	[OfficeCalendarError.OUT_OF_RANGE_PAST_DATE]: OutOfRangePastCalendarError,
	[OfficeCalendarError.OUT_OF_RANGE_FUTURE_DATE]: OutOfRangeFutureCalendarError,
};

export default officeErrorByType;
