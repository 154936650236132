import { PatientInformationApi } from '@interfaces/SurgeryRequest/PatientInformationApi';

export const buildPatientDisplayName = (
	{
		name: firstName = '',
		family: familyName = '',
		middleName = ''
	}: Pick<PatientInformationApi, 'name' | 'family' | 'middleName'>): string => {
	let formattedName = firstName;
	formattedName = `${formattedName}${formattedName && middleName ? ' ' : ''}${middleName || ''}`;
	return `${familyName}${familyName && formattedName ? ', ' : ''}${formattedName}`;
};
