import React from 'react';
import { Box, Button, Flex, Text } from '@ascension/web';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from '@material-ui/core';
import { TextArea } from '@ascension/web/components/base/TextArea';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import { CaseDetailsInfoSelector } from '@store/selectors/CaseDetailsSelector';
import { RequestRevisionSchema } from '@utilities/Validation/validationSchema';
import { setRevision } from '@store/actions/RevisionActionCreators';
import { useCommonStyles } from '@utilities/hooks/useCommonStyles/useCommonStyles';
import NoParamNoReturnCallback from '@interfaces/NoParamNoReturnCallback';
import { FieldError } from 'react-hook-form';

const revisionCheckBoxes = [
	{ value: 'Time not available', name: 'timeNotAvailable' },
	{ value: 'Missing information', name: 'missingInfo' },
	{ value: 'Other', name: 'other' }
];

export default function RequestRevisionModal ({ close, handleDeclined }: {close: NoParamNoReturnCallback; handleDeclined?: NoParamNoReturnCallback}) {
	const defaultUserValues: { listOfRevisions: string[]; message: string } = {
		listOfRevisions: [],
		message: ''
	};
	const { register, formState: { errors }, handleSubmit, setValue, getValues } = useForm<{ listOfRevisions: string[]; message: string }>({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		defaultValues: defaultUserValues
	});
	const { id: caseId } = useSelector(CaseDetailsInfoSelector);
	const dispatch = useDispatch();
	const onSubmit = () => {
		const onSuccess = () => close();
		const payload = {
			reason: getValues().listOfRevisions.join(','),
			message : getValues().message
		};
		dispatch(setRevision(caseId, payload, onSuccess));
		if (handleDeclined != undefined) {
			handleDeclined();
		}
	};

	const updateMessage = (value: string) => {
		setValue('message', value, { shouldValidate: true });
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let filtered = getValues().listOfRevisions;

		if (!event.target.checked) {
			filtered = getValues().listOfRevisions.filter((revision: string) => {
				return revision !== event.target.value;
			});
		} else {
			filtered.push(event.target.value);
		}
		setValue('listOfRevisions', filtered, { shouldValidate: true });
	};

	const { upperCase } = useCommonStyles();

	React.useLayoutEffect(() => {
		register('message', RequestRevisionSchema.message);

		register('listOfRevisions', RequestRevisionSchema.listOfRevisions);
	}, [register]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Flex flexDirection="row" justifyContent="center" alignItems="start" mx="40px" data-section="RequestRevision">
				<Flex flexDirection="column">
					<Flex justifyContent="space-between" letterSpacing={1} width={1} alignItems="start" pt="32px">
						<Box>
							<Text fontSize="26px" fontWeight="bold" color="black">
								Request a Revision
							</Text>
						</Box>
					</Flex>
					<Flex justifyContent="space-between" letterSpacing={1}  width={1} alignItems="start" py="24px">
						<Box>
							<Text fontSize={14}>
								Please provide the original scheduler with details of your revision request.
							</Text>
						</Box>
					</Flex>

					<Flex flexDirection="column" justifyContent="space-between" width={1} alignItems="start" data-field="fullName">
						<Text letterSpacing={1} className={upperCase} fontSize="12px" fontWeight="bold">
							Reason for Revision
						</Text>
					</Flex>
					<Flex flexDirection="row" flexWrap="wrap" width={1}>
						{revisionCheckBoxes.map((checkBox, index: number) => (
							<Box width={1} pb="5px" key={index}>
								<Checkbox
									id={'checkbox' + index}
									value={checkBox.value}
									name={checkBox.name}
									onChange={handleChange}
									color="primary"
								/>
								<Text fontSize="16px" letterSpacing={1} pt={1} data-field={'checkboxLabel' + index}>
									{checkBox.value}
								</Text>
								<ErrorMessage errorField={errors[checkBox.name as keyof typeof errors] as FieldError}/>
							</Box>
						))}
						<ErrorMessage errorField={errors.listOfRevisions as FieldError}/>
					</Flex>
					<Box data-field="message" width={500}>
						<TextArea
							label="MESSAGE"
							id="message"
							name="message"
							onChange={updateMessage}
						/>
						<ErrorMessage errorField={errors.message}/>
					</Box>
					<Box mt={8} textAlign="right">
						<Button type="button" className="margin-right-24" id="cancel" onClick={close} secondary>Cancel</Button>
						<Button id="saveInfo" type="submit">Request Revision</Button>
					</Box>
				</Flex>
			</Flex>
		</form>
	);
}
