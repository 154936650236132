import { useDispatch } from 'react-redux';
import { setAppAlert } from '@store/actions/AppAlertActionCreators';
import { useCallback } from 'react';
import { AlertMessage, AlertMessageDescriptor, AlertMessageToDescriptor } from '@utilities/alertMessages';
import NoParamNoReturnCallback from '@interfaces/NoParamNoReturnCallback';

const useAppAlert = () => {
	const dispatch = useDispatch();

	return useCallback((alertDescriptor: AlertMessageDescriptor | AlertMessage, onDismiss?: NoParamNoReturnCallback) => {
		if (typeof alertDescriptor === 'number') {
			alertDescriptor = AlertMessageToDescriptor[alertDescriptor];
		}
		const { title, message } = alertDescriptor;
		dispatch(setAppAlert(title, message, onDismiss));
	}, [dispatch]);
};

export default useAppAlert;
