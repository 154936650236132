import React, { useContext } from 'react';
import { HospitalWithUnits } from '../../../../../interfaces/Hospital';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../interfaces/rootState';
import { useFieldFormContext } from '../../../../../utilities/hooks/useFieldFormContext/useFieldFormContext';
import { UnitContextType } from './UnitContextType';
import { useFormMetaSchema } from '../../../../../store/context/FormMetaContext';
import { MyFiltersState } from '../../../../../interfaces/MyFilters/MyFiltersState';

const UnitContext = React.createContext<null | UnitContextType>(null);

export const useUnitContext = () => {
	const ctx = useContext(UnitContext);
	if (!ctx) {
		throw new Error('useUnitContext should be used within a subcomponent of UnitContextProvider');
	}

	return ctx;
};

export const UnitContextProvider: React.FC = ({ children }) => {
	const hospitalList: HospitalWithUnits[] = useSelector((state: RootState) => state.unitList.groupedUnitList);

	const validationRules = useFormMetaSchema<MyFiltersState>('unit');

	useFieldFormContext<string[]>('unit',
		{ validationRules });

	const [hospitalById, allUnitIds] = React.useMemo(() => {
		return hospitalList.reduce(([indexedList, allUnitIds]: [Record<string, HospitalWithUnits>, string[]], hospital) => {
			indexedList[hospital.id] = hospital;
			return [indexedList, allUnitIds.concat(hospital.units.map(u => u.id))];
		}, [{}, []]);
	}, [hospitalList]);

	return <UnitContext.Provider value={{
		allUnitIds,
		hospitalList,
		hospitalById,
	}}>{children}</UnitContext.Provider>;
};
