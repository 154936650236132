import React from 'react';
import { StatefulMultiSelect } from '@components/StatefulInputs/StatefulMultiSelect/StatefulMultiSelect';
import { useGetEquipmentList } from '@data/equipment/hooks/useGetEquipmentList';
import { AxiosResponse } from 'axios';
import { EquipmentListResponse } from '@data/equipment/types/EquipmentListResponse';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Theme } from '@material-ui/core';
import { SurgeryProcedureInterface } from '@components/RequestForm/SurgeryInformation/SurgeryInformationInterface';
import {
	transformEquipmentDataToDropdownOptions
} from '@components/ProcedureForm/inputs/EquipmentMultiSelect/EquipmentMultiSelectTypeConverters';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
	}
}));

export const EquipmentMultiSelect: React.FC = () => {
	const classes = useStyles();
	const getEquipmentListResponse = useGetEquipmentList();
	const [equipmentList, setEquipmentList] = React.useState<EquipmentListResponse>([]);

	React.useEffect(() => {
		getEquipmentListResponse({
			onSuccess: (response: AxiosResponse<EquipmentListResponse>) => {
				setEquipmentList(response.data);
			},
		});
	}, [getEquipmentListResponse]);

	return (
		<Box className={classes.root}>
			<StatefulMultiSelect<SurgeryProcedureInterface>
				options={transformEquipmentDataToDropdownOptions(equipmentList)}
				controllerOptions={{
					defaultValue: [],
				}}
				name="equipment"
				label="Equipment"
				columns={2}
				dataField="requestForm-procedureForm-equipmentMultiSelect"
				analyticsInputName="multiSelect"
			/>
		</Box>
	);
};
