import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@material-ui/core';
import { CaseDetailsAttachmentSelector } from '@store/selectors/CaseDetailsSelector';
import {
	useCaseDetailsPageContext
} from '@components/CaseDetails/CaseDetailsContext/CaseDetailsPageContext/CaseDetailsPageContext';
import DocumentInformationDisplayProps
	from '@components/CaseDetails/DocumentInformationDisplay/DocumentInformationDisplayProps';
import DocumentDetailsRow from '@components/CaseDetails/DocumentInformationDisplay/DocumentDetails/DocumentDetailsRow/DocumentDetailsRow';
import DocumentDetailsGrid from '@components/CaseDetails/DocumentInformationDisplay/DocumentDetails/DocumentDetailsGrid/DocumentDetailsGrid';
import DocumentDetails from '@components/CaseDetails/DocumentInformationDisplay/DocumentDetails/DocumentDetails';
import { SCROLLBAR_SIZE } from '@theme/themeConstants';

const useStyles = makeStyles((theme) => ({
	flexContainer: {
		'display': 'flex',
		'flexDirection': 'column',
		'& > .MuiBox-root:first-child': {
			marginTop: theme.spacing(-4)
		},
		'& > .MuiDivider-root:last-child': {
			display: 'none',
		},
	},
	headerCell: {
		whiteSpace: 'nowrap',
	},
	icon: {
		color: theme.palette.primary.main,
		fontSize: '40px',
	},
	noDocuments: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: theme.spacing(3.75)
	},
	tableContainer: {
		'padding': theme.spacing(0, 1),
		'width': 'unset',
		'& > table > tbody > tr:last-child > .MuiTableCell-root': {
			borderBottom: 'none',
		},
		'& > table > thead > tr > th, & > table > tbody > tr > td': {
			fontWeight: 450,
			letterSpacing: '0.25px',
			borderBottomColor: theme.palette.grey[200],
			paddingLeft: theme.spacing(2),
		},
		'& > table > tbody > tr > td:first-child, td:nth-child(4)': {
			whiteSpace: 'nowrap',
		},
		'&::-webkit-scrollbar': {
			height: SCROLLBAR_SIZE,
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.palette.grey[400],
			borderRadius: '100px',
		},
	},
	text: {
		fontSize: '16px',
		textAlign: 'center',
		fontWeight: 500
	},
}));

const COLUMNS = [
	'Date uploaded',
	'Uploaded by',
	'Document name',
	'Status',
	'Actions',
];

export const DocumentInformationDisplay: React.FC<DocumentInformationDisplayProps> = ({ expanded }) => {
	const classes = useStyles();
	const { showCondensedView } = useCaseDetailsPageContext();

	const attachments = useSelector(CaseDetailsAttachmentSelector);

	if (!attachments || !attachments.length) {
		return (
			<Box className={classes.noDocuments}>
				<InfoOutlinedIcon className={classes.icon}/>
				<Typography className={classes.text} variant="h1">No Documents were uploaded to this case</Typography>
			</Box>
		);
	}

	if (!showCondensedView || expanded) {
		return (
			<Box className={classes.flexContainer}>
				<TableContainer className={classes.tableContainer}>
					<Table>
						<TableHead>
							<TableRow>
								{COLUMNS.map(headCell => (
									<TableCell
										key={headCell}
										className={classes.headerCell}
									>
										{headCell}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{attachments.map(attachment =>
								<DocumentDetails key={attachment.id} {...attachment} renderAs={DocumentDetailsRow} />
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		);
	}

	return (
		<Box className={classes.flexContainer}>
			{attachments.map(attachment =>
				<DocumentDetails key={attachment.id} {...attachment} renderAs={DocumentDetailsGrid} />)
			}
		</Box>
	);
};
