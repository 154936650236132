import React from 'react';
import RightLeftDirectionEnum from '@interfaces/RightLeftDirectionEnum';

const useListenHorizontalArrowKey = (cb: (direction: RightLeftDirectionEnum, ev: KeyboardEvent) => void, elm: null | Pick<HTMLDivElement, 'addEventListener' | 'removeEventListener'> = document) => {
	React.useEffect(() => {
		if (!elm) { return; }
		const keydownHandler = (ev: KeyboardEvent) => {
			if (ev.key === 'ArrowRight') {
				cb(RightLeftDirectionEnum.RIGHT, ev);
			}
			if (ev.key === 'ArrowLeft') {
				cb(RightLeftDirectionEnum.LEFT, ev);
			}
		};

		void elm.addEventListener('keydown', keydownHandler);

		return () => {
			elm.removeEventListener('keydown', keydownHandler);
		};
	}, [cb, elm]);
};

export default useListenHorizontalArrowKey;
