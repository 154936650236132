import { useFormContext } from 'react-hook-form';
import { RequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import DocumentRequestFormType from '@interfaces/RequestForm/forms/DocumentRequestFormType';
import useGetPrimarySurgeonNpi
	from '@components/RequestForm/utilities/hooks/useCheckBlockTimeOverlap/useGetPrimarySurgeonNpi/useGetPrimarySurgeonNpi';
import useGetSelectedUnitInfo
	from '@components/RequestForm/utilities/hooks/useCheckBlockTimeOverlap/useGetSelectedUnitInfo/useGetSelectedUnitInfo';
import { dateToString } from '@utilities/dateUtilities';
import { TIME_12_REGEX_NO_MERIDIEM } from '@utilities/constants';
import React from 'react';
import SlotRequestParams, { SlotStatus } from '@data/schedules/types/SlotRequestParams';
import { doesUnitHaveOpenTime } from '@utilities/doesUnitHaveOpenTime';
import { Unit } from '@data/unit/Unit';
import { ENDPOINT_USER_UNITS } from '@utilities/apiConstants';
import { useAsyncCollectionEntity } from '@utilities/hooks/useAsyncCollectionEntity/useAsyncCollectionEntity';

const useSlotRequestParams = (activeOnly = false): Promise<SlotRequestParams | undefined> => {
	const { watch } = useFormContext<RequestFormValidatedState | DocumentRequestFormType>();
	const [
		appointmentLocation,
		procedureDate,
		procedureTime,
	] = watch([
		'appointmentLocation',
		'procedureDate',
		'procedureTime',
	]);

	const primarySurgeonNpi = useGetPrimarySurgeonNpi();
	const selectedUnitInfo = useGetSelectedUnitInfo();

	const selectedDateString = dateToString(procedureDate);
	const isTimeValid = procedureTime && TIME_12_REGEX_NO_MERIDIEM.test(procedureTime[1]);

	const unitPromise = useAsyncCollectionEntity<Unit>(ENDPOINT_USER_UNITS, appointmentLocation || '', 'id');

	return React.useMemo((): Promise<SlotRequestParams | undefined> => {
		return new Promise((resolve) => {
			unitPromise
				.then((unit) => {
					const hasOpenTime = !!unit?.id && doesUnitHaveOpenTime(unit);

					if (
						!selectedUnitInfo || // no unit selected
						!selectedDateString || // no date selected
						!isTimeValid || // time missing or invalid
						!primarySurgeonNpi || // no surgeon selected
						!hasOpenTime || // selected unit does not have open time enabled
						!selectedUnitInfo.hospitalCernerId // missing facility id
					) {
						resolve(undefined);
						return;
					}

					const params: SlotRequestParams = {
						npi: primarySurgeonNpi,
						unit: selectedUnitInfo.name,
						facility: selectedUnitInfo.hospitalCernerId,
						date: selectedDateString,
						hospitalTimeZone: selectedUnitInfo.hospitalTimeZone,
					};

					if (activeOnly) {
						params.status = SlotStatus.ACTIVE;
					}

					resolve(params);
				})
				.catch(() => undefined);
		});



	}, [activeOnly, unitPromise, isTimeValid, primarySurgeonNpi, selectedDateString, selectedUnitInfo]);
};

export default useSlotRequestParams;
