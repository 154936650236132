import { apiAction } from '@store/middlewares/api/ApiActionCreators';
import endpointTemplateToUrl from '@utilities/endpointTemplateToUrl';
import { ENDPOINT_CASE_ACTIVITY } from '@utilities/apiConstants';
import ApiService from '@utilities/apiEndpoints/ApiService';
import { GET_CASE_ACTIVITIES } from '@store/actions/types';
import { ApiActionPayload } from '@interfaces/ApiAction/ApiActionPayload';
import ApiRestMethod from '@utilities/apiEndpoints/ApiRestMethod';
import CaseActivityRowType from '@components/CaseActivityDrawer/types/CaseActivityRowType';

export const getCaseActivities = (requestId: string, apiActionRequest: Partial<ApiActionPayload<CaseActivityRowType[]>>) => {
	const url = endpointTemplateToUrl(ENDPOINT_CASE_ACTIVITY, { requestId });
	return apiAction({
		url,
		method: ApiRestMethod.GET,
		apiService: ApiService.SSM,
		includeAccessToken: true,
		label: GET_CASE_ACTIVITIES,
		shouldDisableLoadIndicator: true,
		...apiActionRequest,
	});
};
