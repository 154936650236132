import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFieldFormContext } from '@utilities/hooks/useFieldFormContext/useFieldFormContext';
import { Publish } from '@material-ui/icons';
import DocumentList from '@components/RequestForm/DocumentRequest/DocumentList/DocumentList';
import BlueOutlineButton from '@components/BlueOutlineButton/BlueOutlineButton';
import DocumentDeleteModal from './DocumentDeleteModal';
import {
	DocumentRequestContextProvider,
} from '@components/RequestForm/DocumentRequest/DocumentContext/DocumentRequestContext';
import { useMultiFormValueSetter } from '@components/RequestForm/utilities/MultiFormAdapter/MultiFormAdapter';
import useIsDocRequired from '@components/RequestForm/utilities/useIsDocRequired';
import ErrorMessageMui from '@components/ErrorMessageMui/ErrorMessageMui';
import { docRequiredValidation } from '@utilities/Validation/validationSchema';
import PreviewDocModal from '@components/RequestForm/DocumentRequest/PreviewDocModal/PreviewDocModal';

const useStyles = makeStyles(theme => ({
	hiddenInput: {
		width: '0.1px',
		height: '0.1px',
		opacity: '0',
		overflow: 'hidden',
		position: 'absolute',
		zIndex: - 1,
	},
	label: {
		'padding': theme.spacing(1, 2),
		'display': 'flex',
		'alignItems': 'center',
		'&:hover': {
			cursor: 'pointer',
		}
	},
	button: {
		margin: theme.spacing(2, 0),
		padding: theme.spacing(0)
	},
	uploadIcon: {
		marginRight: theme.spacing(1),
	},
}));

const DocumentRequest: React.FC = () => {
	const uploadBtnRef = React.useRef<HTMLInputElement | null>(null);

	const classes = useStyles();

	const isDocRequired = useIsDocRequired();

	const setDocuments = useMultiFormValueSetter('documents');
	const {
		currentValue: documents,
		error,
		triggerSelfValidation,
	} = useFieldFormContext<File[]>('documents', {
		validationRules: isDocRequired ? docRequiredValidation : undefined,
	});

	const handleChange = React.useCallback(() => {
		const fileBrowseBtn = uploadBtnRef.current;
		const files = fileBrowseBtn?.files || null;
		if (files) {
			const filesArr = Array.from(files);
			setDocuments((prev: File[] | undefined) => prev?.length ? [...prev, ...filesArr] : filesArr);
		}
		void triggerSelfValidation();
		if (fileBrowseBtn) { fileBrowseBtn.value = ''; }
	}, [setDocuments, triggerSelfValidation]);

	return (
		<DocumentRequestContextProvider>
			<PreviewDocModal />
			<DocumentDeleteModal />
			<Typography>Upload surgical PDF documents here</Typography>
			<Box width="100%">
				<BlueOutlineButton className={classes.button}>
					<label
						htmlFor="browseFiles"
						className={classes.label}
						data-field="requestForm-documents-uploadBtnLabel"
					>
						<Publish className={classes.uploadIcon}/>
						Select File
					</label>
					<input
						ref={uploadBtnRef}
						type="file"
						id="browseFiles"
						name="browseFiles"
						accept="application/pdf"
						onChange={handleChange}
						className={classes.hiddenInput}
						multiple
						data-field="requestForm-documents-uploadBtnInput"
					/>
				</BlueOutlineButton>
			</Box>
			{documents && documents.length > 0 && <DocumentList />}
			<ErrorMessageMui>{error}</ErrorMessageMui>
		</DocumentRequestContextProvider>
	);
};

export default DocumentRequest;
