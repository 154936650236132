import { ApiActionPayloadSuccessPartial, ApiActionRequest } from '@interfaces/ApiAction/ApiAction';
import { CacheType } from '@interfaces/CacheType';
import { API } from './types';
import { CancelPolicy } from '@interfaces/CancelPolicy';
import { defaultCallback } from '@utilities/defaultCallback';

export function getCollection<T>(
	endpoint: string,
	extended: ApiActionPayloadSuccessPartial<T>,
): ApiActionRequest<T> {
	return {
		type: API,
		payload: {
			url: endpoint,
			cancelPolicy: CancelPolicy.NO_DUPLICATE_PATH,
			cachePolicy: CacheType.RETRIEVE_OR_ADD_CACHE,
			label: 'useCollectionEntity_' + endpoint,
			includeAccessToken: true,
			onFailure: defaultCallback,
			...extended,
		}
	};
}
