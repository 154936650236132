import { FormControl, FormControlLabel, FormLabel, makeStyles, Radio, RadioGroup, RadioProps } from '@material-ui/core';
import { AppointmentType } from '@data/request/AppointmentType';
import React, { ChangeEvent } from 'react';
import OptionLabel from '@components/OptionLabel/OptionLabel';
import useFieldWithoutRegister from '@utilities/hooks/useFieldWithoutRegister/useFieldWithoutRegister';
import CommonRequestForm from '@interfaces/RequestForm/forms/CommonRequestForm';
import { useMultiFormValueSetter } from '@components/RequestForm/utilities/MultiFormAdapter/MultiFormAdapter';

const useStyles = makeStyles((theme) => ({
	label: {
		fontWeight: 600,
		color: 'rgba(0, 0, 0, 0.6)',
	},
	questionContainer: {
		'display': 'flex',
		'gap': theme.spacing(2),
		'& .MuiFormControlLabel-root': {
			'alignItems': 'flex-start',
			'& .MuiIconButton-root': {
				paddingTop: 0,
			},
		},
		'& .MuiFormGroup-root': {
			gap: theme.spacing(2),
		}
	}
}));

const PrimaryRadio: React.FC<RadioProps> = (props) => <Radio color="primary" {...props} />;

const FIELD_NAME: keyof CommonRequestForm = 'appointmentType';
const AppointmentTypeRadios = () => {
	const classes = useStyles();

	const {
		currentValue,
	} = useFieldWithoutRegister<CommonRequestForm>(FIELD_NAME);

	const updateValue = useMultiFormValueSetter(FIELD_NAME);

	const handleChange = React.useCallback((event: ChangeEvent<HTMLInputElement>) => {
		updateValue(event.target.value);
	}, [updateValue]);

	return <FormControl component="fieldset" className={classes.questionContainer}>
		<FormLabel
			required
			className={classes.label}
		>
			How would you like to submit this request?
		</FormLabel>
		<RadioGroup
			name="appointmentType"
			value={currentValue}
			onChange={handleChange}
			color="primary"
		>
			<FormControlLabel
				value={AppointmentType.WEB}
				label={
					<OptionLabel helperText="Submit the request details by completing the online request form">Web request</OptionLabel>
				}
				control={<PrimaryRadio/>}
				data-field="requestForm-appointmentType-webRadio"
			/>
			<FormControlLabel
				value={AppointmentType.DOCUMENT}
				label={
					<OptionLabel helperText="Submit the request details by uploading your booking sheet">Document request</OptionLabel>
				}
				control={<PrimaryRadio/>}
				data-field="requestForm-appointmentType-documentRadio"
			/>
		</RadioGroup>
	</FormControl>;
};

export default AppointmentTypeRadios;
