import { UPDATE_SELECTED_FACILITY, UPDATE_SELECTED_SURGEONS } from '@store/actions/types';
import { Surgeon } from '@data/surgeon/Surgeon';

export const updateSelectedSurgeons = (selectedSurgeons: Surgeon[]) => {
	return {
		type: UPDATE_SELECTED_SURGEONS,
		payload: selectedSurgeons,
	};
};

export const updateSelectedFacility = (facilityId: string) => {
	return {
		type: UPDATE_SELECTED_FACILITY,
		payload: facilityId,
	};
};
