import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, IconButton, Theme, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useCaseActivityContext } from '@components/CaseActivityDrawer/CaseActivityContext/CaseActivityContext';
import CaseActivityRow from '@components/CaseActivityDrawer/CaseActivityRow/CaseActivityRow';
import BoxWithLoader from '@components/Loaders/BoxWithLoader/BoxWithLoader';
import { GET_CASE_ACTIVITIES } from '@store/actions/types';
import { AppointmentType } from '@data/request/AppointmentType';
import DrawerWrapper from '@components/DrawerWrapper/DrawerWrapper';
import { CASE_ACTIVITY_DRAWER } from '@components/DrawerWrapper/drawerConstants';
import { useDrawerContext } from '@components/DrawerWrapper/DrawerContextProvider';

const useStyles = makeStyles((theme: Theme) => ({
	title: {
		fontWeight: 600,
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
	closeIcon: {
		height: '48px',
		width: '48px',
		top: '-12px',
		right: '-18px',
	},
}));

interface CaseActivityDrawerProps {
	appointmentType?: AppointmentType;
}

const CaseActivityDrawer: React.FC<CaseActivityDrawerProps> = ({ appointmentType }) => {
	const classes = useStyles();
	const { caseActivityEvents } = useCaseActivityContext();
	const { closeDrawer } = useDrawerContext();

	return (
		<DrawerWrapper drawerId={CASE_ACTIVITY_DRAWER}>
			<BoxWithLoader label={GET_CASE_ACTIVITIES} display="flex" flexDirection="column" justifyContent="start" height="100%">
				<Box display="flex" justifyContent="space-between">
					<Typography
						variant="h5"
						component="h1"
						className={classes.title}
					>
						Case Activity
					</Typography>
					<IconButton onClick={() => closeDrawer(CASE_ACTIVITY_DRAWER)} data-field="close-icon-btn" className={classes.closeIcon}>
						<CloseIcon />
					</IconButton>
				</Box>
				<Box display="flex" flexDirection="column">
					{caseActivityEvents && caseActivityEvents.length ? caseActivityEvents.map((activity) =>
						<CaseActivityRow key={activity.id} caseActivity={activity} appointmentType={appointmentType} />
					) : null}
				</Box>
			</BoxWithLoader>
		</DrawerWrapper>
	);
};

export default CaseActivityDrawer;
