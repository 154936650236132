import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FindATimeForm } from '@components/FindATime/FindATimeForm';
import { useProcedureNameContext } from '@components/RequestForm/ProcedureName/ProcedureNameContext';

export const useAutoClearProcedure = () => {
	const { clearErrors, setValue, watch }  = useFormContext<FindATimeForm>();

	const [unitId] = watch(['appointmentLocation']);
	const { setSelectedProcedureName } = useProcedureNameContext();
	React.useEffect(() => {
		setValue('procedureName', '');
		clearErrors('procedureName');
		setSelectedProcedureName('');
	}, [clearErrors, setSelectedProcedureName, setValue, unitId]);
};
