import React, { useCallback } from 'react';
import { Box, Checkbox, makeStyles } from '@material-ui/core';
import { useCommonStyles } from '../../../utilities/hooks/useCommonStyles/useCommonStyles';

interface MyFiltersCheckboxProps{
	id: string;
	name: string;
	onCheck: (id: string) => unknown;
	checked: boolean;
	capitalizeName?: boolean;
	width?: number | string;
}

const useStyles = makeStyles(() => ({
	checkBoxWrapperStyle: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		['-webkit-line-clamp']: 2,
		['-webkit-box-orient']: 'vertical',
		alignSelf: 'center'
	},
	checkBoxStyle: {
		padding: '0px',
		marginRight: '8px'
	}
}));

const MyFiltersCheckbox = ({ id, name, onCheck, capitalizeName, width = 0.5, checked }: MyFiltersCheckboxProps) => {
	const classes = useStyles();
	const { capitalize } = useCommonStyles();
	const nameWrapperClass = `${classes.checkBoxWrapperStyle} ${capitalizeName ? capitalize : ''}`;
	const handleChange = useCallback(() => onCheck(id), [onCheck, id]);

	return (
		<Box display="flex" width={width} pb="24px">
			<Checkbox
				id={`checkbox_${id}`}
				checked={checked}
				value={id}
				onChange={handleChange}
				color="primary"
				className={classes.checkBoxStyle}
			/>
			<Box pt={0.25} letterSpacing={1} data-field={`checkboxLabel${id}`} className={nameWrapperClass}>
				{name}
			</Box>
		</Box>
	);
};

export default MyFiltersCheckbox;
