import React from 'react';
import { StatefulDatePicker } from '@components/StatefulInputs/StatefulDatePicker/StatefulDatePicker';
import { useFormContext } from 'react-hook-form';
import { RequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import {
	NewRequestedDateValidation,
	UniversalRequestedDateValidation,
} from '@components/RequestForm/RequestFormValidation';
import { useRequestFormContext } from '@components/RequestForm/RequestForm';

export const RequestedDate = () => {
	const { watch, clearErrors, formState, setValue } = useFormContext<RequestFormValidatedState>();
	const [ roomId ] = watch(['roomId']);
	const { isEdit } = useRequestFormContext();

	//This is needed to clear the date value from the formState when clearing the selection from openAvailabilityCalendar
	React.useEffect(() => {
		if (roomId && formState.errors.procedureDate) {
			clearErrors('procedureDate');
		}
	}, [clearErrors, formState.errors.procedureDate, roomId]);

	const handleDateChange = () => {
		setValue('findATimeUsed', false);
	};

	const validationRules = isEdit ? UniversalRequestedDateValidation : NewRequestedDateValidation;
	return (
		<StatefulDatePicker
			name="procedureDate"
			label="Requested date"
			extraLabel="(MM/DD/YYYY)"
			placeholder=""
			styleAsRequired={true}
			controllerOptions={{
				rules: { ...validationRules, onChange: handleDateChange },
			}}
			dataField="requestForm-requestDetails-procedureDatePicker"
		/>
	);
};
