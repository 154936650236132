import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ColumnHeaderProps from '@components/SchedulePage/types/ColumnHeaderProps';
import ColumnHeaderCommon from '@components/SchedulePage/ColumnHeaderCommon/ColumnHeaderCommon';

const useStyles = makeStyles( theme => createStyles({
	root: {
		padding: theme.spacing(0.5, 1, 0.5, 1),
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.common.white,
		display: 'flex',
		flexDirection: 'column',
		borderRadius: '4px 4px 0 0',
	},
	availableTimeWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	availableTimeValue: {
		...theme.typography.subtitle1,
		fontWeight: 600,
	},
	blockText: {
		fontWeight: 600,
	},
}));

const BlockUnreleasedColumnHeader: React.FC<ColumnHeaderProps> = ({ blockInfo, children }) => {
	const classes = useStyles();

	return (
		<Box className={classes.root} data-field="block-unreleased-column-header" component="header">
			<Typography variant="body2" component="h3" className={classes.blockText}>Block Time</Typography>
			<ColumnHeaderCommon blockInfo={blockInfo} />
			<Typography variant="body2" component="span" className={classes.availableTimeWrapper}>
				<Typography className={classes.availableTimeValue}>{blockInfo.availableTimeDisplay}&nbsp;</Typography> approx. available
			</Typography>
			{ children }
		</Box>
	);
};

export default BlockUnreleasedColumnHeader;
