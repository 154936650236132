import React from 'react';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import CalendarDayNavigation from '@components/CalendarDayNavigation/CalendarDayNavigation';
import {
	twoBusinessDaysFromToday,
	useSchedulePageContext
} from '@components/SchedulePage/SchedulePageContext/SchedulePageContext';
import RefreshScheduleButton from '@components/SchedulePage/RefreshScheduleButton/RefreshScheduleButton';
import LastUpdateMessage from '@components/SchedulePage/LastUpdate/LastUpdateMessage/LastUpdateMessage';

const useStyles = makeStyles((theme) => createStyles({
	container: {
		display: 'flex',
		flexDirection: 'row',
		[theme.breakpoints.down('md')]: {
			flexWrap: 'wrap',
			justifyContent: 'end',
		},
	},
}));

const CalendarMainControls = () => {
	const classes = useStyles();
	const { selectedDate, setSelectedDate, lastUpdate } = useSchedulePageContext();
	const shouldDisableLeftDateNav = React.useMemo(() => selectedDate <= twoBusinessDaysFromToday, [selectedDate]);

	return (
		<Box className={classes.container}>
			<CalendarDayNavigation
				selectedDate={selectedDate}
				onSelectedDateChange={setSelectedDate}
				disablePastNavigation={shouldDisableLeftDateNav}
				shouldDisablePreviousDays={true}
				displayDateFormat="EEEE, MMM d"
			/>
			<Box>
				<RefreshScheduleButton />
				{lastUpdate && <LastUpdateMessage lastUpdate={lastUpdate} />}
			</Box>
		</Box>
	);
};

export default CalendarMainControls;
