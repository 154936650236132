import React from 'react';
import { Flex, Text } from '@ascension/web';
import UnitsListByHospital from './UnitsListByHospital';
import { UnitsHospitalGroupProps } from './UnitsHospitalGroupProps';
import { useFormContext } from 'react-hook-form';
import { getNewSelectList, SectionName } from '../../MyFilterUtilities';
import { useUnitContext } from '../UnitContext/UnitContext';

export default function UnitsHospitalGroup({ id }: UnitsHospitalGroupProps) {
	const { setValue, getValues } = useFormContext<{ unit: string[] | null }>();
	const { allUnitIds, hospitalById } = useUnitContext();

	const { name, units } = hospitalById[id];

	const unitIds = React.useMemo(() => units.map(u => u.id), [units]);

	const getSelectedUnits = React.useMemo(() => getNewSelectList.bind(null, allUnitIds, unitIds), [allUnitIds, unitIds]);

	const handleToggleUnit = React.useCallback( (unitId: string) => {
		const { unit: selectedUnits } = getValues();

		setValue('unit', getSelectedUnits(selectedUnits, unitId, SectionName.UNITS), { shouldValidate: true });
	}, [getSelectedUnits, getValues, setValue]);

	return (
		<Flex flexDirection="column">
			<Text color="#555555" fontSize={2} letterSpacing={1} fontWeight="bold" pb={8}>
				{name}
			</Text>
			<UnitsListByHospital units={units} onToggleUnit={handleToggleUnit}/>
		</Flex>
	);
}
