import React from 'react';
import { useSelector } from 'react-redux';
import { CaseConflict, ConfirmationStatus } from './ConfirmCaseContextType';
import { conflictAttrConfig } from '../CaseDetailsPageContext/ConflictAttrConfig';
import { CONFIRMATION_RESPONSE_TO_CONFIG, CONFIRMATION_STATUS_CONFIG } from './ConfirmCaseConfiguration';
import { useConfirmCaseContext } from './ConfirmCaseContext';
import useHasEhrIntegrationAndRole
	from '../../../../utilities/hooks/useHasEhrIntegrationAndRole/useHasEhrIntegrationAndRole';
import { CaseDetailsInfoSelector } from '@store/selectors/CaseDetailsSelector';
import { IntegrationType } from '@interfaces/IntegrationType';

const DEFAULT_CONFLICT_LIST: CaseConflict[] = [];
export const useConflictList = (): CaseConflict[] => {
	const { confirmationStatus, latestConfirmationResponse } = useConfirmCaseContext();

	return React.useMemo(() => {
		return confirmationStatus === ConfirmationStatus.ERROR_CONFLICTS && latestConfirmationResponse?.data?.conflicts || DEFAULT_CONFLICT_LIST;
	}, [confirmationStatus, latestConfirmationResponse]);
};

export const useConfirmationConfiguration = () => {
	const caseDetails = useSelector(CaseDetailsInfoSelector);
	const configKey = useHasEhrIntegrationAndRole(caseDetails) ? IntegrationType.EHR : IntegrationType.MANUAL;
	return {
		indexByApiStatus: CONFIRMATION_RESPONSE_TO_CONFIG[configKey],
		indexByConfirmationStatus: CONFIRMATION_STATUS_CONFIG[configKey]
	};
};

export const useConfirmationStatusConfiguration = () => {
	const { confirmationStatus } = useConfirmCaseContext();
	const { indexByConfirmationStatus: config } = useConfirmationConfiguration();
	return config[confirmationStatus];
};

export const useConfirmError = () => {
	const { error } = useConfirmationStatusConfiguration();
	return error;
};

export const useStandardConfirm = () => {
	const { saveConfirmId } = useConfirmCaseContext();

	return React.useCallback((confirmId: string) => {
		return saveConfirmId(confirmId, { force: false });
	}, [saveConfirmId]);
};

const conflictShouldDisableForce = (conflict: CaseConflict) => conflict.attribute in conflictAttrConfig && conflictAttrConfig[conflict.attribute].shouldDisableForceOnConflict;
export const useCanForceConfirm = () => {
	const { confirmationStatus } = useConfirmCaseContext();
	const conflictList = useConflictList();

	return React.useMemo(() => {
		switch (confirmationStatus) {
		case ConfirmationStatus.ERROR_CONFLICTS:
			return !conflictList.some(conflictShouldDisableForce);
		default:
			return true;
		}
	}, [confirmationStatus, conflictList]);
};

export const useForceConfirm = () => {
	const { saveConfirmId } = useConfirmCaseContext();
	const canForceConfirm = useCanForceConfirm();

	return React.useCallback((confirmId: string, optionalFin?: string) => {
		if (!canForceConfirm) { return; }
		return saveConfirmId(confirmId, { force: true, fin: optionalFin });
	}, [canForceConfirm, saveConfirmId]);
};

export const useConfirmationCaseNumber = () => {
	const { confirmationCaseNumber } = useConfirmCaseContext();
	return confirmationCaseNumber;
};

export const useConfirmationDrawerBodyConfiguration = () => {
	return useConfirmationStatusConfiguration().drawer;
};
