import React from 'react';
import { Box, Dialog, IconButton, makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { createStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { UserProfileHasARoleSelector } from '../../../store/selectors/UserProfileSelector';
import { useLocation } from 'react-router-dom';
import { Button } from '@ascension/ui-library';
import DynamicModalNavButton from './DynamicModalNavButton';
import { HospitalInteractiveRoles } from '../../../interfaces/UserProfile/UserRoleEnum';
import { UserAccessEnum } from '@interfaces/SurgeryRequest/UserAccessEnum';
import useGetAccessor from '@components/CaseDetails/CaseInUse/hooks/useGetAccessor/useGetAccessor';
import { PermittedAction } from '@utilities/PermittedAction';

const useStyles = makeStyles((theme) => createStyles({
	modal: {
		height: '272px',
		width: '600px',
		display: 'flex',
		flexDirection: 'column',
	},
	header: {
		display: 'flex',
		justifyContent: 'end',
	},
	title: {
		fontSize: '1.5rem',
		lineHeight: '2rem',
		fontWeight: 350,
		color: theme.palette.grey['900'],
	},
	content: {
		padding: theme.spacing(0, 5),
		fontSize: '1.125rem',
		lineHeight: '1.25rem',
		letterSpacing: '.01rem',
		color: theme.palette.grey['700'],
	},
	btnContainer: {
		display: 'flex',
		justifyContent: 'end',
		marginRight: theme.spacing(2),
	},
	primaryBtn: {
		'height': '48px',
		'boxShadow': 'none',
		'marginLeft': theme.spacing(2),
		'backgroundColor': '#FFD266',
		'&:hover': {
			backgroundColor: '#FFB400',
			boxShadow: 'none',
		},
	}
}));

const CaseDetailsAccessModal: React.FC = () => {
	const classes = useStyles();
	const { isAccessorDifferentUser: blocked, blockerName: fullAccessorName } = useGetAccessor([UserAccessEnum.CONFIRMER], PermittedAction.CONFIRM_CASE_SCHEDULE);
	const isHospitalInteractiveRole = useSelector(UserProfileHasARoleSelector(HospitalInteractiveRoles));
	const shouldShowOpen = blocked && !!isHospitalInteractiveRole;
	const [showOpen, setShowOpen] = React.useState(shouldShowOpen);

	const closeModal = () => {
		setShowOpen(false);
	};

	const location = useLocation();
	React.useEffect(() => {
		setShowOpen(shouldShowOpen);
	}, [shouldShowOpen, location.pathname]);

	return (
		<Dialog open={showOpen} onClick={closeModal}>
			<Box className={classes.modal}>
				<div className={classes.header}>
					<IconButton onClick={closeModal} data-field="close-access-modal">
						<CloseIcon/>
					</IconButton>
				</div>
				<div className={classes.content}>
					<Typography component="h1" className={classes.title}>
						Case is being managed by {fullAccessorName}
					</Typography>
					<p>
						You can still view the case normally, but you will not be able to make
						any changes to this case until {fullAccessorName} is done managing this case.
					</p>
				</div>
				<div className={classes.btnContainer}>
					<DynamicModalNavButton/>
					<Button
						data-field="view-case-details-btn"
						translate="yes"
						variant="contained"
						className={classes.primaryBtn}
						onClick={closeModal}
					>
						View Case Details
					</Button>
				</div>
			</Box>
		</Dialog>
	);
};

export default CaseDetailsAccessModal;
