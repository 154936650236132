import React from 'react';
import { useFormContext } from 'react-hook-form';
import { RequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import useDoesSelectedUnitSupportAppointmentTypes
	from '@components/RequestForm/utilities/useDoesSelectedUnitSupportAppointmentTypes';
import { AppointmentType } from '@data/request/AppointmentType';
import { ATTACHMENT_REQUEST_TYPES } from '@utilities/constants';
import ComparisonOption from '@utilities/arrays/ComparisonOption';

const FIELD_NAME: keyof RequestFormValidatedState = 'appointmentType';

const useSetInitialAppointmentType = (shouldAutoSet: boolean) => {
	const selectedUnitSupportsAttachments = useDoesSelectedUnitSupportAppointmentTypes(ATTACHMENT_REQUEST_TYPES, ComparisonOption.ANY);
	const { register, unregister, setValue } = useFormContext<RequestFormValidatedState>();

	React.useEffect(() => {
		register(FIELD_NAME);

		if (!shouldAutoSet) return;
		setValue(FIELD_NAME, selectedUnitSupportsAttachments ? AppointmentType.WEB : AppointmentType.LEGACY_WEB);

		return () => {
			unregister(FIELD_NAME);
		};
	}, [register, selectedUnitSupportsAttachments, setValue, shouldAutoSet, unregister]);
};

export default useSetInitialAppointmentType;
