import React from 'react';
import { useDispatch } from 'react-redux';
import { getSchedules } from '@data/schedules/actionCreators/getSchedules';
import { ScheduleRequest } from '@data/schedules/types/ScheduleRequest';

const useGetSchedules = () => {
	const dispatch = useDispatch();

	return React.useCallback((requestOverrides?: ScheduleRequest) => {
		dispatch(getSchedules(requestOverrides));
	}, [dispatch]);
};

export default useGetSchedules;
