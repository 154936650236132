import React from 'react';
import { DrawerPane } from '@ascension/ui-library';
import { makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import { UserStatusChip } from './UserStatusChip/UserStatusChip';
import { Flex } from '@ascension/web';
import CloseIcon from '@material-ui/icons/Close';
import { EditUserButton } from './EditUserButton/EditUserButton';
import { DeactivateUserButton } from './DeactivateUserButton/DeactivateUserButton';
import { CloseUserDetailsButton } from './CloseUserDetailsButton/CloseUserDetailsButton';
import { UserDetailsDrawerRow } from './UserDetailsDrawerRow/UserDetailsDrawerRow';
import { useManageUsersListContext } from '../ManageUsersListContext/ManageUsersListContext';
import { Backdrop } from '@material-ui/core';
import { UserStatus } from '../../../interfaces/UserProfile/UserStatusEnum';

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiAscensionDrawerPane-root > .MuiAscensionDrawerPane-drawerPaper': {
			boxSizing: 'border-box',
			padding: theme.spacing(6, 4, 4, 4),
		},
	},
	nameHeading: {
		'marginTop': theme.spacing(1),
		'marginBottom': theme.spacing(5),
		'&.MuiTypography-h4': {
			fontSize: '32px',
			lineHeight: '30px',
			letterSpacing: '.25px',
			fontWeight: 450
		},
	},
	backdrop: {
		zIndex: 110,
	},
	closeIcon: {
		'top' : '18px',
		'right' : '18px',
		'position' : 'absolute',
		'fill': theme.palette.grey[600],
		'&:hover': {
			cursor: 'pointer',
		}
	},
	viewUser: {
		paddingTop: theme.spacing(.5),
		fontWeight: 450
	},
	bottomBtnBar:{
		marginTop: 'auto',
	},
	bottomBtnBarDivider:{
		marginBottom: theme.spacing(4),
	}
}));


export const UserDetailsDrawer = () => {
	const classes = useStyles();

	const { isDrawerOpen, closeDrawer, managedUser } = useManageUsersListContext();

	return <div className={classes.root}>
		<Backdrop className={classes.backdrop} open={isDrawerOpen}/>
		<DrawerPane
			translate="yes"
			open={isDrawerOpen}
			variant="persistent"
			anchor="right"
		>
			<CloseIcon onClick={closeDrawer} className={classes.closeIcon} data-field="closeIcon"/>
			<Flex justifyContent="space-between" alignItems="center">
				<Typography variant="h3" className={classes.viewUser}>View User</Typography>
				<UserStatusChip status={managedUser?.statusStr}/>
			</Flex>
			<Typography variant="h4" className={classes.nameHeading}>{`${managedUser?.firstName} ${managedUser?.lastName}`}</Typography>
			<UserDetailsDrawerRow name="Role" value={managedUser?.userRolesStr} />
			<UserDetailsDrawerRow name="First Name" value={managedUser?.firstName} />
			<UserDetailsDrawerRow name="Last Name" value={managedUser?.lastName} />
			<UserDetailsDrawerRow name="Email Address" value={managedUser?.email} />
			<UserDetailsDrawerRow name="Phone Number" value={`${managedUser?.phone ? managedUser?.phone : ''}${managedUser?.ext ? ` ext. ${managedUser.ext}` : ''}`} />
			<UserDetailsDrawerRow name="Ascension Active Directory ID" value={managedUser?.ascensionUsername} disableDivider={true} />
			<div className={classes.bottomBtnBar}>
				<Divider className={classes.bottomBtnBarDivider} />
				<Flex>
					{managedUser?.status === UserStatus.PRE_REGISTERED ? <EditUserButton/> : <DeactivateUserButton />}
					<CloseUserDetailsButton />
				</Flex>
			</div>
		</DrawerPane>
	</div>;
};
