import React from 'react';
import { Box, Flex, Text } from '@ascension/web';
import { dateParse } from '../../../utilities/dateUtilities';
import { useSelector } from 'react-redux';
import {
	CaseDetailsAppointmentSelector,
	CaseDetailsInfoSelector,
	CaseDetailsPerformerSelector,
} from '../../../store/selectors/CaseDetailsSelector';
import { useCollectionEntity } from '../../../utilities/hooks/useCollectionEntity/useCollectionEntity';
import { Unit } from '../../../data/unit/Unit';
import { ENDPOINT_USER_UNITS } from '../../../utilities/apiConstants';
import { RootState } from '../../../interfaces/rootState';
import AppointmentDetailApi from '../../../interfaces/SurgeryRequest/AppointmentDetailApi';
import { PerformerApi } from '../../../interfaces/SurgeryRequest/PerformerApi';
import { SurgeryRequestGet } from '../../../interfaces/SurgeryRequest/SurgeryRequestGet';
import { Alert } from '../Alert/Alert';
import { AlertDisplayField } from '../AlertDisplayField/AlertDisplayField';
import useHasPermission from '../../../utilities/hooks/useHasPermission/useHasPermission';
import { PermittedAction } from '../../../utilities/PermittedAction';
import ConfirmCaseInput from './ConfirmCaseInput/ConfirmCaseInput';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	footerContainer: {
		justifyContent: 'flex-end',
		[theme.breakpoints.up(theme.drawerPane?.breakpoint)]: {
			flexDirection: 'row',
			justifyContent: 'flex-end',
			alignItems: 'flex-start',
		},
	},
	requestRevLink: {
		margin: theme.spacing(2, 2, 3, 0),
		display: 'flex',
		alignSelf: 'center',
	},
}));

export const UnscheduledAlert = () => {
	const {
		unit,
		dateCreated,
		room
	} = useSelector(CaseDetailsInfoSelector as (state: RootState) => SurgeryRequestGet);
	const {
		procedureDate,
	} = useSelector(CaseDetailsAppointmentSelector as (state: RootState) => AppointmentDetailApi);
	const {
		firstName,
		lastName,
	} = useSelector(CaseDetailsPerformerSelector as (state: RootState) => PerformerApi);

	const [requestedAppointmentDate, requestedAppointmentTime, requestedAppointmentMeridiem] = React.useMemo(
		() => procedureDate ? dateParse(procedureDate) : [],
		[procedureDate]);

	const { hospitalName, name: unitName } = useCollectionEntity<Unit>(ENDPOINT_USER_UNITS, unit, 'id');

	const hasEnterFinPermission = useHasPermission(PermittedAction.ENTER_FIN);

	const classes = useStyles();
	return (
		<>
			<Alert
				data-section="caseAlertBar"
				tagColor="#FEC78D"
				headerColor="#FAE6C8"
				tagText="UNSCHEDULED"
				headerText="This procedure has not been scheduled."
				alertUser={firstName + ' ' + lastName}
				alertTime={dateCreated}
				body={
					<Flex pt="6px" data-section="unscheduledInfo">
						<AlertDisplayField
							label="Requested Surgery Date"
						>
							{requestedAppointmentDate}
						</AlertDisplayField>
						<AlertDisplayField
							label="Requested Surgery Time"
						>
							{requestedAppointmentTime} <Text textTransform="uppercase">{requestedAppointmentMeridiem}</Text>
						</AlertDisplayField>
						<AlertDisplayField
							label="Appointment Location"
						>
							{hospitalName} {unitName}
						</AlertDisplayField>
						<AlertDisplayField
							label="OR Room"
						>
							{room ? room : '--'}
						</AlertDisplayField>
					</Flex>
				}
				footer={hasEnterFinPermission &&
				<Flex testId="footer" className={classes.footerContainer}>
					<Box width="500px"><ConfirmCaseInput/></Box>
				</Flex>
				}
			/>
		</>

	);
};
