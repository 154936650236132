import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useFieldFormContext } from '../../../../../utilities/hooks/useFieldFormContext/useFieldFormContext';
import { SurgeonContextType } from './SurgeonContextType';
import { FullSurgeonsSelector, PreferredSurgeonsSelector } from '../../../../../store/selectors/SurgeonListSelector';
import { UserProfileHasARoleSelector } from '../../../../../store/selectors/UserProfileSelector';
import { OfficeRoles } from '../../../../../interfaces/UserProfile/UserRoleEnum';
import { useFormMetaSchema } from '../../../../../store/context/FormMetaContext';
import { MyFiltersState } from '../../../../../interfaces/MyFilters/MyFiltersState';

const SurgeonContext = React.createContext<null | SurgeonContextType>(null);

export const useSurgeonContext = () => {
	const ctx = useContext(SurgeonContext);
	if (!ctx) {
		throw new Error('useSurgeonContext should be used within a subcomponent of SurgeonContextProvider');
	}

	return ctx;
};

export const SurgeonContextProvider: React.FC = ({ children }) => {
	const isOfficeRole = useSelector(UserProfileHasARoleSelector(OfficeRoles));
	const fullSurgeonSelector = isOfficeRole ?  FullSurgeonsSelector : PreferredSurgeonsSelector;
	const fullSurgeonList = useSelector(fullSurgeonSelector);

	const validationRules = useFormMetaSchema<MyFiltersState>('surgeon');

	useFieldFormContext<string[] | null>('surgeon',
		{ validationRules });

	const allSurgeonIds = React.useMemo(() => {
		return fullSurgeonList.reduce((allSurgIds: string[], currSurgeon) => {
			return allSurgIds.concat(currSurgeon.id);
		}, []);
	}, [fullSurgeonList]);

	return <SurgeonContext.Provider value={{
		allSurgeonIds,
	}}>{children}</SurgeonContext.Provider>;
};
