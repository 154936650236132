import React from 'react';
import { Box } from '@material-ui/core';
import { OfficeCalendarEventConfig } from '@components/OfficeCalendarView/OfficeCalendarEventConfig';
import {
	useOfficeCalendarViewContext,
} from '@components/OfficeCalendarView/OfficeCalendarViewContext/OfficeCalendarViewContext';
import { makeStyles } from '@material-ui/core/styles';
import CalendarLayout from '@components/UnitCalendarPage/CalendarLayout/CalendarLayout';
import OfficeEventStyleConfig from '@components/OfficeCalendarView/OfficeEventStyleConfig';
import SelectOpenTimePopover from '@components/OfficeCalendarView/SelectOpenTimePopover/SelectOpenTimePopover';
import MemoizedTimeTick from '@calendar/types/MemoizedTimeTick';
import { useSelector } from 'react-redux';
import { UserProfileHasARoleSelector } from '@store/selectors/UserProfileSelector';
import { HospitalInteractiveRoles } from '@interfaces/UserProfile/UserRoleEnum';
import HeaderAlert from '@components/OfficeCalendarView/HeaderAlert/HeaderAlert';
import useHasPermission from '@utilities/hooks/useHasPermission/useHasPermission';
import { PermittedAction } from '@utilities/PermittedAction';
import AvailableTimeErrorModal from '@components/OfficeCalendarView/AvailableTimeErrorModal/AvailableTimeErrorModal';
import officeErrorByType from '@components/OfficeCalendarView/errorOverlayContent/officeErrorByType';
import useCalendarEventsWithHoldEvent from '@components/OfficeCalendarView/utilities/useCalendarEventsWithHoldEvent';
import { FocusDescriptor, FocusType } from '@calendar';
import { FOCUS_TO_STUB_EVENT } from '@components/UnitCalendarPage/utilities/calendarConstants';
import { OfficeCalendarViewDrawerProps } from '@components/OfficeCalendarView/OfficeCalendarView';
import OREventType from '@data/openCalendar/OREventType';

const useStyles = makeStyles(theme => ({
	container: {
		'& > .MuiBox-root': {
			'height': `calc(100vh - ${theme.spacing(1)}px)`,
			'& hr:nth-child(2)': {
				display: 'none',
			},
			'& h1': {
				display: 'none',
			},
		},
	},
}));

const officeDisplayTxt = <>This calendar is for scheduling <strong>non-block</strong> procedures</>;
const orDisplayTxt = 'This is a preview of what the Office will see when they view the Open Time calendar';

const OfficeCalendarViewLayout: React.FC<OfficeCalendarViewDrawerProps> = ({ onSubmitHoldEventSuccess, isEdit }) => {
	const classes = useStyles();
	const hasHospitalInteractiveRole = useSelector(UserProfileHasARoleSelector(HospitalInteractiveRoles));
	const canCreateHoldEvent = useHasPermission(PermittedAction.CREATE_HOLD_EVENT);

	const {
		selectedDate,
		setSelectedDate,
		unitConfig,
		columns,
		calendarError,
		selectedRoom,
		setSelectedRoom,
		setStartTime,
		currentHoldEvent,
	} = useOfficeCalendarViewContext();

	const { start } = unitConfig;
	const currentHoldEventId = currentHoldEvent?.id;

	const handleCalendarClick = React.useCallback((column: string, tickStart?: MemoizedTimeTick) => {
		if (!canCreateHoldEvent) { return; }

		const clickedHeader = column && !tickStart;

		setSelectedRoom(prev =>
			// !prev - room not already selected && clickedColumnHeader
			!prev && clickedHeader
				// leave the prev undefined as is - should not update on column click if not already selected
				? prev
				// Change the column because it's selected already or clicked on cell with time
				: column
		);

		if (!tickStart) { return; }
		setStartTime(tickStart.formatted);
	}, [canCreateHoldEvent, setSelectedRoom, setStartTime]);

	const events = useCalendarEventsWithHoldEvent();

	const focusOn: FocusDescriptor = React.useMemo(() => {
		const defaultUnitStart = { type: FocusType.TIME, id: start };

		if (!selectedRoom) {
			return defaultUnitStart;
		}

		return  currentHoldEventId ? { ...FOCUS_TO_STUB_EVENT, id: currentHoldEventId } : FOCUS_TO_STUB_EVENT;
	}, [currentHoldEventId, selectedRoom, start]);

	const ErrorComponent = React.useMemo(() => {
		return officeErrorByType[calendarError];
	}, [calendarError]);

	return <Box className={classes.container}>
		<AvailableTimeErrorModal />
		<SelectOpenTimePopover onSubmitHoldEventSuccess={onSubmitHoldEventSuccess} isEdit={isEdit}/>
		<CalendarLayout<OREventType.UNAVAILABLE | OREventType.AVAILABLE>
			errorContent={ErrorComponent && <ErrorComponent />}
			eventConfig={OfficeCalendarEventConfig}
			eventStyleConfig={OfficeEventStyleConfig}
			events={events}
			columns={columns}
			unitConfig={unitConfig}
			selectedDate={selectedDate}
			onSelectedDateChange={setSelectedDate}
			onCalendarClick={handleCalendarClick}
			title=""
			rightLowerHeader={<HeaderAlert>{hasHospitalInteractiveRole ? orDisplayTxt : officeDisplayTxt}</HeaderAlert>}
			focusOn={focusOn}
		/>
	</Box>;
};

export default OfficeCalendarViewLayout;
