import {
	ENDPOINT_APPROVE_CANCELLATION,
	ENDPOINT_MARK_AS_SEEN_CANCELLED
} from '../../utilities/apiConstants';
import { apiAction } from '../middlewares/api/ApiActionCreators';
import { CacheType } from '../../interfaces/CacheType';
import { getCaseDetails } from './CaseDetailsActionCreators';
import endpointTemplateToUrl from '@utilities/endpointTemplateToUrl';

export const approvePendingCancellation = (requestId: string) => {
	const approveApiEndpoint = endpointTemplateToUrl(ENDPOINT_APPROVE_CANCELLATION, { requestId: requestId });
	return apiAction({
		url: approveApiEndpoint,
		method: 'PUT',
		label: 'APPROVE_PENDING_CANCELLATION',
		includeAccessToken: true,
		onSuccess: () => getCaseDetails(requestId),
		cachePolicy: CacheType.NO_CACHE,
	});
};

export const setCancellationToSeen = (requestId: string) => {
	const clearApiEndpoint = endpointTemplateToUrl(ENDPOINT_MARK_AS_SEEN_CANCELLED, { requestId: requestId });
	return apiAction({
		url: clearApiEndpoint,
		method: 'PUT',
		label: 'MARK_AS_SEEN',
		includeAccessToken: true,
		onSuccess: () => getCaseDetails(requestId),
		cachePolicy: CacheType.NO_CACHE,
	});
};
