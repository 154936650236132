import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles({
	guardImage: {
		height: 100,
		width: 'max-content',
		minWidth: 100,
		marginRight: 10,
		marginTop: 15,
		objectFit: 'contain',
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		backgroundImage: 'url("/assets/security-alert-badge.png")',
	},
});

interface SecurityAlertBadgeProps {
	className?: string;
}

export const SecurityAlertBadge = ({ className }: SecurityAlertBadgeProps) => {
	const classes = useStyles();
	return <Box className={classNames(classes.guardImage, className)} data-testid="security-alert-badge" />;
};
