import { ArrowDropDown } from '@material-ui/icons';
import { TextField } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useProcedureNameContext } from '@components/RequestForm/ProcedureName/ProcedureNameContext';

import { ProcedureFilterProps } from './ProcedureFilter.types';

const useStyles = makeStyles(() => ({
	filterInput: {
		width: '100%',
	},
	endAdornment: {
		cursor: 'pointer',
	},
}));

export const ProcedureFilter: React.FC<ProcedureFilterProps> = ({ onAdornmentClick }) => {
	const { setProcedureFilter } = useProcedureNameContext();

	const classes = useStyles();

	return (
		<TextField
			autoFocus
			className={classes.filterInput}
			variant="outlined"
			onChange={(e) => setProcedureFilter(e.target.value)}
			InputProps={{
				endAdornment: (
					<ArrowDropDown
						data-field="endAdornment"
						className={classes.endAdornment}
						fontSize="small"
						onClick={onAdornmentClick}
					/>
				),
			}}
		/>
	);
};
