import React from 'react';
import {
	useCaseDetailsInfoContext,
} from '@components/CaseDetails/CaseDetailsContext/CaseDetailsInfoContext/CaseDetailsInfoContext';
import useHasPermission from '@utilities/hooks/useHasPermission/useHasPermission';
import useSetAccessor from '@components/CaseDetails/CaseInUse/hooks/useGetCaseAsAccessor/useSetAccessor/useSetAccessor';
import { UserAccessEnum } from '@interfaces/SurgeryRequest/UserAccessEnum';
import { PermittedAction } from '@utilities/PermittedAction';
import { AxiosResponse } from 'axios';
import { SurgeryRequestGet } from '@interfaces/SurgeryRequest/SurgeryRequestGet';
import useGetCaseDetails from '@data/caseDetails/hooks/useGetCaseDetails';
import { ApiActionPayload } from '@interfaces/ApiAction/ApiActionPayload';

type ApiRequestOverrides = Partial<ApiActionPayload<SurgeryRequestGet>>;

/***
 * Should be used by CaseDetails and other components on the page that need to request updated case details. This sets the accessor as well on success.
 *
 * @param caseId {string} - Case Id to request
 * @param accessorAction {PermittedAction} - Only set accessor when this permission is set.
 * @param accessorTypes {[UserAccessEnum, ...UserAccessEnum[]]} - At least one value required. First value is the primary type that will be used by setter.
 * @param autoRun {boolean} - default true. Automatically run the getCaseAccessor
 * @param apiRequestOverrides {Partial<ApiActionPayload<SurgeryRequestGet>>} - Optional overrides for API request including an onSuccess handler
 *
 * @returns {(requestOverrides: Partial<ApiActionPayload<SurgeryRequestGet>>) => () => void} to run getCase on demand and receive the cleanup function. Can optionally pass new overrides.
 */
const useGetCasePostAccessor = (caseId: string, accessorAction: PermittedAction, accessorTypes: [UserAccessEnum, ...UserAccessEnum[]], autoRun: boolean, apiRequestOverrides?: ApiRequestOverrides) => {
	const getCaseDetails = useGetCaseDetails();

	const hasPermissionAsAccessor = useHasPermission(accessorAction);

	const { setter, cleanup } = useSetAccessor(caseId, accessorTypes[0]);

	const getCaseAsAccessor = React.useCallback((requestOverrides?: ApiRequestOverrides) => {
		const overrides = {
			...apiRequestOverrides,
			...requestOverrides,
		};

		const onSuccess = (response: AxiosResponse<SurgeryRequestGet>) => {
			void overrides?.onSuccess?.(response);

			if ( // Do not set accessor when either...
				// No permission as accessor
				!hasPermissionAsAccessor ||
				// One of the accessors exists in the case already
				accessorTypes.some(t => !!response.data?.accessors?.find(a => a.access === t))
			) { return; }

			setter();
		};

		getCaseDetails(caseId, {
			...overrides,
			onSuccess,
		});
		return cleanup;
	}, [apiRequestOverrides, getCaseDetails, caseId, cleanup, hasPermissionAsAccessor, accessorTypes, setter]);

	React.useEffect(() => {
		if (!autoRun) { return; }
		return getCaseAsAccessor();
	}, [autoRun, getCaseAsAccessor]);

	return { getCaseDetails: getCaseAsAccessor };
};

/***
 * useGetCasePostIndexEditorAccessor - specific implementation of useSetAccessor that first checks logic to set accessor then automatically
 * sets the in use status
 *
 * The EDIT_INDEX_NO_CONFIRM limits this to work for OR viewers only. EDIT_INDEX as the first parameter ensures that is
 * the primary access type used for setting. The CONFIRMER is simply checked for conflicts.
 *
 * @returns - void - all logic is handled automatically in useEffect on load/unload
 */
const postIndexAccessorTypes: [UserAccessEnum, ...UserAccessEnum[]] = [UserAccessEnum.EDIT_INDEX, UserAccessEnum.CONFIRMER];

export const useGetCasePostIndexEditorAccessor = (autoRun = true) => {
	const { caseId } = useCaseDetailsInfoContext();
	return useGetCasePostAccessor(caseId, PermittedAction.EDIT_INDEX_NO_CONFIRM, postIndexAccessorTypes, autoRun);
};

/***
 * useGetCasePostConfirmerAccessor - specific implementation of useSetAccessor that first checks logic to set accessor then automatically
 * sets the in use status
 *
 * The CONFIRM_CASE_SCHEDULE limits this to only OR schedulers. Since CONFIRMER is the only access type, that type is set
 * as long as there is not a conflicting CONFIRMER. Other access types are ignored.
 *
 * @returns - void - all logic is handled automatically in useEffect on load/unload
 */

const postConfirmerAccessorTypes: [UserAccessEnum, ...UserAccessEnum[]] = [UserAccessEnum.CONFIRMER];

export const useGetCasePostConfirmerAccessor = (caseId: string, apiRequestOverrides: ApiRequestOverrides, autoRun = true) => {
	return useGetCasePostAccessor(caseId, PermittedAction.CONFIRM_CASE_SCHEDULE, postConfirmerAccessorTypes, autoRun, apiRequestOverrides);
};
