import { Flex, Text } from '@ascension/web';
import React from 'react';
import { SetupCardSection } from '../SetupCardSection/SetupCardSection';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { UserProfileDetailsSelector } from '../../../store/selectors/UserProfileSelector';
import SetupConfirmList from '../SetupConfirmList/SetupConfirmList';

const useStyles = makeStyles({
	flexFieldContainer: {
		flexDirection: 'column',
		marginTop: '32px',
		color: 'rgba(0, 0, 0, 0.87)'
	},
	practiceNameText: {
		fontSize: '18px',
		letterSpacing: '0.15px',
		fontWeight: 700
	},
	practiceQuestionText: {
		fontSize: '16px',
		letterSpacing: '0.5px',
		fontWeight: 700
	},
	asterisk: {
		color: '#E00019',
	},
});

const DEFAULT_PRACTICE = { name: 'None' };

export default function SetupPracticeInformation({ setInformationConfirmed }: { setInformationConfirmed: (confirm: boolean) => unknown }) {
	const { flexFieldContainer, practiceNameText, practiceQuestionText, asterisk } = useStyles();
	const { primaryPractice: { name = DEFAULT_PRACTICE.name } = DEFAULT_PRACTICE } = useSelector(UserProfileDetailsSelector);

	return (
		<SetupCardSection sectionTitle="Practice Information">
			<Flex className={flexFieldContainer} data-field="PracticeName">
				<Text className={practiceNameText}>
					{name}
				</Text>
			</Flex>
			<Flex className={flexFieldContainer} >
				<Text className={practiceQuestionText} aria-required="true">
					Is this your practice? <span className={asterisk}>*</span>
				</Text>
			</Flex>
			<SetupConfirmList setInformationConfirmed={setInformationConfirmed} disabled={name === DEFAULT_PRACTICE.name}/>
		</SetupCardSection>
	);
}
