import React  from 'react';
import { useSelector } from 'react-redux';
import { Range } from 'react-date-range';
import { addDays, format } from 'date-fns';
import { Flex, Box, Multiselect } from '@ascension/web';
import { useCommonStyles } from '@utilities/hooks/useCommonStyles/useCommonStyles';
import { MyFiltersSelector } from '@store/selectors/MyFiltersSelector';
import { useFieldFormContextWithPrevious } from '@utilities/hooks/useFieldFormContextWithPrevious/useFieldFormContextWithPrevious';
import { DropdownOption } from '@definitions/DropdownOption';
import { PROCEDURE_DATE_OPTIONS } from '@utilities/constants';
import { getDropdownValue } from '@utilities/formUtilities';
import DatePeriodPicker from '@components/DatePeriodPicker/DatePeriodPicker';

const defaultCustomStartDate = new Date();
const defaultCustomEndDate = addDays(new Date(), 7);

export default function ProcedureDate() {
	const { inputBorderStyle } = useCommonStyles();
	const {
		procedureDate: lastSavedProcDateModel,
		from: lastSavedStartDate,
		to: lastSavedEndDate
	} = useSelector(MyFiltersSelector);
	const {
		currentValue: myFilterProcDateCurrentValue,
		setCurrentValue: myFilterProcDateSetCurrentValue,
	} = useFieldFormContextWithPrevious<string>('procedureDate');
	const {
		currentValue: myFilterStartDateCurrentValue,
		setCurrentValue: myFilterStartDateSetCurrentValue,
	} = useFieldFormContextWithPrevious<string | undefined>('from');
	const {
		currentValue: myFilterEndDateCurrentValue,
		setCurrentValue: myFilterEndDateSetCurrentValue,
	} = useFieldFormContextWithPrevious<string | undefined>('to');

	let defaultOption = { value: 'upcoming', label: 'UPCOMING' };
	const paramValue = getDropdownValue(myFilterProcDateCurrentValue as string);
	if (paramValue.length > 0)
		defaultOption = paramValue[0];
	const [value, setValue] = React.useState([defaultOption]);
	const [initialLoadComplete, setInitialLoadComplete] = React.useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const filterRef = React.useRef(null);

	const openPopover = () => {
		setAnchorEl(filterRef.current);
	};

	const closePopover = () => {
		setAnchorEl(null);
	};

	const setValueAndResetDatePicker = (newValue: DropdownOption[]) => {
		setValue(newValue);
		myFilterStartDateSetCurrentValue( '' );
		myFilterEndDateSetCurrentValue( '' );
	};

	const handleOptionChange = (newValue: DropdownOption[]) => {
		const newProcDateValue = newValue[0].value;
		if (newProcDateValue == 'custom') {
			myFilterProcDateSetCurrentValue(newProcDateValue);
			setValue(newValue);
			openPopover();
		}
		else {
			setValueAndResetDatePicker(newValue);

			myFilterProcDateSetCurrentValue(newProcDateValue);
		}
	};

	const handleApplyDateRange = React.useCallback( (selectionRange: Range) => {
		const startDate = selectionRange.startDate && format(selectionRange.startDate, 'MM/dd/yyyy');
		const endDate = selectionRange.endDate && format(selectionRange.endDate, 'MM/dd/yyyy');

		myFilterProcDateSetCurrentValue('custom');
		myFilterStartDateSetCurrentValue(startDate);
		myFilterEndDateSetCurrentValue(endDate);
		closePopover();
	}, [myFilterEndDateSetCurrentValue, myFilterProcDateSetCurrentValue, myFilterStartDateSetCurrentValue]);

	const currentValueNeedsInitialUpdate =
		!initialLoadComplete &&
		!!lastSavedProcDateModel &&
		!myFilterProcDateCurrentValue;

	React.useEffect(() => {
		if (currentValueNeedsInitialUpdate) {
			const newValue =  PROCEDURE_DATE_OPTIONS.filter(item => item.value == lastSavedProcDateModel);
			setValue(newValue);
			setInitialLoadComplete(true);

			if (lastSavedStartDate) {
				myFilterStartDateSetCurrentValue(lastSavedStartDate);
			}

			if (lastSavedEndDate) {
				myFilterEndDateSetCurrentValue(lastSavedEndDate);
			}
		}
	}, [currentValueNeedsInitialUpdate, lastSavedProcDateModel, myFilterProcDateSetCurrentValue, lastSavedEndDate, lastSavedStartDate, myFilterEndDateSetCurrentValue, myFilterStartDateSetCurrentValue]);

	React.useEffect(() => {
		// updates dropdown UI for all changes to context values, e.g. from reset and cancel
		let newDropdownOption;

		if (myFilterProcDateCurrentValue === '') {
			newDropdownOption = [{ value: 'upcoming', label: 'UPCOMING' }];
		} else {
			newDropdownOption = PROCEDURE_DATE_OPTIONS.filter( (item: DropdownOption) => item.value == myFilterProcDateCurrentValue );
		}

		setValue(newDropdownOption);
	}, [myFilterProcDateCurrentValue, setValue]);

	const [customStartDate, setCustomStartDate] = React.useState(myFilterStartDateCurrentValue);
	const [customEndDate, setCustomEndDate] = React.useState(myFilterEndDateCurrentValue);

	React.useEffect(() => {
		setCustomStartDate(myFilterStartDateCurrentValue);
		setCustomEndDate(myFilterEndDateCurrentValue);
	}, [myFilterEndDateCurrentValue, myFilterStartDateCurrentValue]);

	return (
		<Flex width={1} justifyContent="stretch" className="container standard-gap" ref={filterRef}>
			<Box flex="3 3 25%">
				<Box minWidth="150px" width={0.35} className={inputBorderStyle}>
					<Multiselect
						single value={value}
						onChange={handleOptionChange}
						label="Procedure Date"
						options={PROCEDURE_DATE_OPTIONS}
					/>
					<Box data-field="PERIOD_PICKER" width={1}>
						<DatePeriodPicker
							startDate={customStartDate || ''}
							endDate={customEndDate || ''}
							defaultStartDate={defaultCustomStartDate}
							defaultEndDate={defaultCustomEndDate}
							onStartChange={date => setCustomStartDate(date?.toString())}
							onEndChange={date => setCustomEndDate(date?.toString())}
							anchor={anchorEl}
							onClose={closePopover}
							onApplyChange={handleApplyDateRange}
						/>
					</Box>
				</Box>
			</Box>
		</Flex>
	);
}
