import RightLeftDirectionEnum from '@interfaces/RightLeftDirectionEnum';
import React from 'react';
import useListenHorizontalArrowKey from '@utilities/hooks/useListenHorizontalArrowKey/useListenHorizontalArrowKey';
import { defaultCallback } from '@utilities/defaultCallback';
import doHorizontalScroll from '@components/BoxScrollHorizontal/utilities/doHorizontalScroll/doHorizontalScroll';
import determineContentHiddenDirection
	from '@components/BoxScrollHorizontal/utilities/calculateIsSideHidden/determineContentHiddenDirection';

const useScrollContainerObserver = (columnScrollAmount: number) => {
	const scrollContainerRef = React.useRef<HTMLDivElement>();

	const [observer, setObserver] = React.useState<IntersectionObserver>();

	const doScroll = React.useCallback((direction: RightLeftDirectionEnum) =>
		doHorizontalScroll(scrollContainerRef.current, columnScrollAmount, direction),
	[columnScrollAmount]);

	useListenHorizontalArrowKey(doScroll);

	const [contentHiddenByDirection, setContentHiddenByDirection] = React.useState<Record<RightLeftDirectionEnum, boolean>>(determineContentHiddenDirection(scrollContainerRef.current));
	const determineContentHiddenInContainer = React.useCallback(() => setContentHiddenByDirection(determineContentHiddenDirection(scrollContainerRef.current)), []);

	const setupScrollContainerObs = React.useCallback((elm: HTMLDivElement) => {
		scrollContainerRef.current = elm;

		setObserver(new IntersectionObserver(determineContentHiddenInContainer, {
			root: elm,
			threshold: [0, 1]
		}));
	}, [determineContentHiddenInContainer]);

	const observeItem = React.useCallback((elm: HTMLDivElement | null) => {
		if (!observer || !elm) { return defaultCallback; }
		observer.observe(elm);
		return () => {
			observer.unobserve(elm);
			determineContentHiddenInContainer();
		};
	}, [determineContentHiddenInContainer, observer]);

	React.useEffect(() => {
		const scrollContainer = scrollContainerRef.current;
		const cleanup = () => {
			void scrollContainer?.removeEventListener('scrollend', determineContentHiddenInContainer);
			void observer?.disconnect();
		};

		void scrollContainer?.addEventListener('scrollend', determineContentHiddenInContainer);

		return cleanup;
	}, [determineContentHiddenInContainer, observer]);

	return {
		doScroll,
		setupScrollContainerObs,
		observeItem,
		contentHiddenByDirection,
	};
};

export default useScrollContainerObserver;
