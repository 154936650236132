import React from 'react';
import { ProcedureNameSelect } from '@components/RequestForm/inputs/ProcedureName/ProcedureNameSelect/ProcedureNameSelect';
import { useAutoClearProcedure } from '@components/FindATime/inputs/FindATimeProcedureName/useAutoClearProcedure/useAutoClearProcedure';
import { useProcedureOptionSelect } from '@components/FindATime/inputs/FindATimeProcedureName/useProcedureOptionSelect/useProcedureOptionSelect';

export const ProcedureNameSearchFilter = () => {
	useAutoClearProcedure();
	const handleOptionClick = useProcedureOptionSelect();

	return <ProcedureNameSelect onOptionClick={handleOptionClick} label="Procedure" dataField="findATimePage-searchSection-procedureNameSelect"/>;
};
