import React from 'react';
import { Box, IconButton, Popover, Typography } from '@material-ui/core';
import { Close, QueryBuilderRounded, RoomRounded, WarningRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import PreBookEventTimeSpanInput
	from '@components/OfficeCalendarView/SelectOpenTimePopover/PreBookEventTimeSpanInput/PreBookEventTimeSpanInput';
import { useOfficeCalendarViewContext } from '@components/OfficeCalendarView/OfficeCalendarViewContext/OfficeCalendarViewContext';
import BlueOutlineButton from '@components/BlueOutlineButton/BlueOutlineButton';
import GoldButton from '@components/GoldButton/GoldButton';
import { useFormContext } from 'react-hook-form';
import { HoldEventDetails } from '@data/holdEvent/types/HoldEventDetails';
import useSubmitHoldEvent from '@data/openCalendar/useSubmitHoldEvent';
import { useClickCoordinate } from '@components/ClickCoordinateBox/ClickCoordinateBox';
import CardWidthContextType from '@components/ClickCoordinateBox/ClickCoordinateBoxContextType';
import preventEventPropagation from '@utilities/preventEventPropagation';
import { OfficeCalendarViewDrawerProps } from '@components/OfficeCalendarView/OfficeCalendarView';
import { FieldErrors } from 'react-hook-form';

const useStyles = makeStyles(theme => ({
	popover: {
		'pointerEvents': 'none',
		'& .MuiPaper-root': {
			pointerEvents: 'all',
		},
	},
	fieldIcon: {
		color: theme.palette.action.disabled,
	},
	requestTimeBtn: {
		marginLeft: theme.spacing(2),
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(3),
		maxWidth: '384px',
	},
	closeBtn: {
		position: 'absolute',
		top: '12px',
		right: '12px',
	},
	dataRow: {
		display: 'flex',
		paddingTop: theme.spacing(3),
	},
	field: {
		paddingLeft: theme.spacing(1),
	},
	addTimeMsg: {
		'display': 'flex',
		'paddingLeft': theme.spacing(4),
		'paddingTop': theme.spacing(2),
		'& > .MuiSvgIcon-root': {
			marginRight: theme.spacing(1.5),
			color: '#FFA83F',
		},
	},
}));

const SelectOpenTimePopover: React.FC<OfficeCalendarViewDrawerProps> = ({ onSubmitHoldEventSuccess, isEdit }) => {
	const classes = useStyles();

	const {
		selectedDate,
		selectedRoom,
		unit: { hospitalName },
		holdEventCardElm,
		resetHoldEvent,
		currentHoldEvent,
		setSelectedRoom,
		setStartTime,
		setEndTime,
		isPopoverOpen,
		isLoading,
		setIsPopoverOpen,
	} = useOfficeCalendarViewContext();

	const coordinatesRef = React.useRef<Partial<CardWidthContextType>>({});
	coordinatesRef.current = useClickCoordinate();

	const calendarWidthExceeded = React.useMemo(() => {
		if (!selectedRoom) { return; }
		const { coordinates = [0, 0], boxWidth = 0 } = coordinatesRef.current;
		return boxWidth / 2 >= coordinates[0];
	}, [selectedRoom]);

	const transformPopoverDirection = calendarWidthExceeded ? 'left' : 'right';

	const locationDisplay = `${hospitalName}, ${selectedRoom}`;
	const formattedDate = format(selectedDate, 'EEEE, MMMM d');

	const handleClose = () => {
		if (currentHoldEvent) {
			resetHoldEvent();
			setIsPopoverOpen(false);
		} else {
			setSelectedRoom(undefined);
			setStartTime(undefined);
			setEndTime(undefined);
		}
	};

	const { handleSubmit, formState: { errors } } = useFormContext<HoldEventDetails>();
	const { submitHoldEvent, hasAPIConflictError, setTimeSpanAPIError } = useSubmitHoldEvent({ onSubmitHoldEventSuccess, isEdit });
	const hasError = Object.keys(errors)
		// Only count errors that have an object with a non-empty message
		.filter(k => errors[k as keyof FieldErrors<HoldEventDetails>]?.message).length;

	if (!holdEventCardElm || !selectedRoom || isLoading) { return null; }

	return (
		<Popover
			open={isPopoverOpen}
			anchorOrigin={{
				vertical: 'top',
				horizontal: calendarWidthExceeded ? 'right' : 'left',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: transformPopoverDirection,
			}}
			className={classes.popover}
			anchorReference="anchorEl"
			anchorEl={holdEventCardElm}
			// only way to override existing transform is with style prop
			style={{ transform: calendarWidthExceeded ? 'translateX(4px)' : 'translateX(-4px)' }}
		>
			<form onSubmit={preventEventPropagation(handleSubmit(submitHoldEvent))}>
				<Box className={classes.container}>
					<Box display="flex" justifyContent="space-between" alignItems="center">
						<Typography variant="h6" component="h3">New Procedure</Typography>
						<IconButton
							className={classes.closeBtn}
							onClick={handleClose}
							data-field="close-open-time-popover-icon"
						>
							<Close />
						</IconButton>
					</Box>
					<Box className={classes.dataRow}>
						<RoomRounded className={classes.fieldIcon} />
						<Typography variant="body1" className={classes.field}>{locationDisplay}</Typography>
					</Box>
					<Box className={classes.dataRow}>
						<QueryBuilderRounded className={classes.fieldIcon} />
						<Typography variant="body1" className={classes.field}>{formattedDate}</Typography>
					</Box>
					<PreBookEventTimeSpanInput
						hasAPIConflictError={hasAPIConflictError}
						setTimeSpanAPIError={setTimeSpanAPIError}
					/>
					<Box className={classes.addTimeMsg} data-field="openTimeCalendar-popover-addTimeMessage">
						<WarningRounded />
						<Typography variant="body1">
							Please add 30 minutes to your procedure duration for OR setup and cleanup time.
						</Typography>
					</Box>
					<Box className={classes.dataRow} justifyContent="flex-end">
						<BlueOutlineButton onClick={handleClose} data-field="close-open-time-popover-btn">
							CANCEL
						</BlueOutlineButton>
						<GoldButton
							className={classes.requestTimeBtn}
							data-field="submit-open-time-request-btn"
							type="submit"
							disabled={!!hasError}
						>
							REQUEST TIME
						</GoldButton>
					</Box>
				</Box>
			</form>
		</Popover>
	);
};

export default SelectOpenTimePopover;
