import React from 'react';
import { createStyles, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LastUpdateMinsProps } from './LastUpdateMinsProps';

const useStyles = makeStyles(() => createStyles({
	root: {
		fontSize: '14px',
	},
}));

export const LastUpdateMins = ({ lastUpdateTime, ...rest }: LastUpdateMinsProps) => {
	const classes = useStyles();
	const lastUpdateMs = new Date(lastUpdateTime).getTime();

	const [timeSince, setTimeSince] = React.useState(0);

	React.useEffect(() => {
		const updateTime = () => setTimeSince(Math.floor(((new Date() .getTime() - lastUpdateMs) / 1000 / 60) % 60));
		const timer = setInterval(updateTime, 5000);
		updateTime();
		return () => {
			clearInterval(timer);
		};
	}, [lastUpdateMs]);

	return (
		<Typography className={classes.root} component="span" {...rest}>
			{timeSince}
		</Typography>
	);
};
