import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import ViewStreamRoundedIcon from '@material-ui/icons/ViewStreamRounded';
import ViewWeekRoundedIcon from '@material-ui/icons/ViewWeekRounded';
import { useCaseDetailsPageContext } from '../CaseDetailsContext/CaseDetailsPageContext/CaseDetailsPageContext';

const useStyles = makeStyles((theme) => ({
	toggleViewContainer: {
		display: 'inline-flex',
		height: theme.spacing(4.5),
		alignItems: 'center',
	},
	toggleBtnGroup: {
		'height': 'inherit',
		'& > .MuiButtonBase-root.Mui-selected': {
			color: '#1E69D2',
			background: '#E4EAF2',
		},
	},
	toggleViewBtn: {
		color: '#1E69D2',
		background: '#FFF',
		border: '1px solid #C9CED5',
		borderColor: '#C9CED5',
	},
	label: {
		marginRight: '13px'
	},
	icon: {
		padding: theme.spacing(1),
	}
}));

export const CondensedViewToggle = () => {
	const classes = useStyles();
	const { showCondensedView, setShowCondensedView } = useCaseDetailsPageContext();

	const handleShowCondensed = () => {
		setShowCondensedView((prevState: boolean) => !prevState);
	};

	return (
		<div className={classes.toggleViewContainer}>
			<label htmlFor="showCondensedToggle" className={classes.label}>View:</label>
			<ToggleButtonGroup
				id="showCondensedToggle"
				value={showCondensedView}
				exclusive
				onChange={handleShowCondensed}
				data-field="show-condensed-toggle"
				aria-label="show-condensed-toggle"
				className={classes.toggleBtnGroup}
			>
				<ToggleButton
					value={false}
					data-field="expanded-view-btn"
					aria-label="expanded-view-btn"
					className={classes.toggleViewBtn}
				>
					<ViewStreamRoundedIcon className={classes.icon}/>
					Expanded
				</ToggleButton>
				<ToggleButton
					value={true}
					data-field="condensed-view-btn"
					aria-label="condensed-view-btn"
					className={classes.toggleViewBtn}
				>
					<ViewWeekRoundedIcon className={classes.icon}/>
					Condensed
				</ToggleButton>
			</ToggleButtonGroup>
		</div>
	);
};
