import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Flex, Label, Radio, Text } from '@ascension/web';
import { useRadioGroup } from '@ascension/web/utils/hooks/useRadioGroup';
import { InsuranceInformationProps } from './InsuranceInformationInterface';
import { useFieldFormContext } from '@utilities/hooks/useFieldFormContext/useFieldFormContext';
import { InsuranceInformationValidationSchema } from './InsuranceInformationValidationSchema';
import theme from '../../../theme/theme';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import { YesNoAnswer } from '@interfaces/SurgeryRequest/YesNoAnswer';
import { PreAuthOptionsEnum } from '@interfaces/SurgeryRequest/InsuranceApi';
import PreCertNumberInput from '@components/RequestForm/inputs/PreCertNumberInput';
import { RequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import TextFieldInput from '@components/TextFieldInput/TextFieldInput';

const useStyles = makeStyles({
	marginBottomMd: {
		marginBottom: theme.spacing(2)
	}
});

export default function InsuranceInformation ({ type, ...rest }: InsuranceInformationProps) {
	const radioGroup = useRadioGroup();
	const setAuthChecked = radioGroup[1];

	const [isToggleOn, setIsToggleOn ] = React.useState(false);
	const { watch, reset, getValues } = useFormContext<Partial<RequestFormValidatedState>>();
	const patientFirstName = watch('patientFirstName');
	const patientLastName = watch('patientLastName');

	const {
		currentValue: insuredLastNameCurrentValue,
		setCurrentValue: insuredLastNameSetCurrentValue,
		error: insuredLastNameError,
		triggerSelfValidation: insuredLastNameTriggerSelfValidation,
	} = useFieldFormContext('insuredLastName' + type, {
		validationRules: InsuranceInformationValidationSchema.nameValidation,
	});

	const {
		currentValue: insuredFirstNameCurrentValue,
		setCurrentValue: insuredFirstNameSetCurrentValue,
		error: insuredFirstNameError,
		triggerSelfValidation: insuredFirstNameTriggerSelfValidation,
	} = useFieldFormContext('insuredFirstName' + type, {
		validationRules: InsuranceInformationValidationSchema.nameValidation,
	});

	const {
		currentValue: insuranceHealthPlanNameCurrentValue,
		setCurrentValue: insuranceHealthPlanNameSetCurrentValue,
		error: insuranceHealthPlanNameError,
		triggerSelfValidation: insuranceHealthPlanNameTriggerSelfValidation,
	} = useFieldFormContext('healthPlanName' + type, {
		validationRules: InsuranceInformationValidationSchema.healthPlanName,
	});

	const {
		currentValue: insurancePolicyNumberCurrentValue,
		setCurrentValue: insurancePolicyNumberSetCurrentValue,
		error: insurancePolicyNumberError,
		triggerSelfValidation: insurancePolicyNumberTriggerSelfValidation,
	} = useFieldFormContext<string>('policyNumber' + type, {
		validationRules: InsuranceInformationValidationSchema.policyNumber,
	});

	const {
		currentValue: insuranceGroupNumberCurrentValue,
		setCurrentValue: insuranceGroupNumberSetCurrentValue,
		error: insuranceGroupNumberError,
		triggerSelfValidation: insuranceGroupNumberTriggerSelfValidation,
	} = useFieldFormContext('groupNumber' + type, {
		validationRules: InsuranceInformationValidationSchema.groupNumber,
	});

	const {
		currentValue: insurancePreAuthCurrentVal,
		setCurrentValue: insurancePreAuthSetCurrentValue,
		error: insurancePreAuthError,
	} = useFieldFormContext('preAuth' + type, {
		validationRules: InsuranceInformationValidationSchema.preAuth,
		triggerValidationOnChange: true,
	});

	const handleRadioYes =  (checked: boolean) => {
		setAuthChecked(1);
		if (checked) {
			insurancePreAuthSetCurrentValue(PreAuthOptionsEnum.YES);
		}
	};

	const handleRadioNo =  (checked: boolean) => {
		setAuthChecked(2);
		if (checked) {
			insurancePreAuthSetCurrentValue(PreAuthOptionsEnum.NO);
		}
	};

	const handleRadioNotApplicable =  (checked: boolean) => {
		setAuthChecked(3);
		if (checked) {
			insurancePreAuthSetCurrentValue(PreAuthOptionsEnum.NOT_APPLICABLE);
		}
	};
	const validateName = () => {
		void insuredLastNameTriggerSelfValidation();
		void insuredFirstNameTriggerSelfValidation();
	};

	const handlePrefillCheckboxChange = () => {
		//about to toggle ON, so toggle is still OFF
		if (!isToggleOn) {
			insuredFirstNameSetCurrentValue(patientFirstName);
			insuredLastNameSetCurrentValue(patientLastName);
			validateName();
		}
		//about to toggle OFF, so toggle is still ON
		else {
			insuredFirstNameSetCurrentValue('');
			insuredLastNameSetCurrentValue('');
		}
		setIsToggleOn(!isToggleOn);
	};

	React.useEffect(() => {
		const hasNoPatientName = !patientFirstName && !patientLastName;
		if (hasNoPatientName) {
			return;
		}

		const hasMatchingInsuredName = patientFirstName === insuredFirstNameCurrentValue
			&& patientLastName === insuredLastNameCurrentValue;

		setIsToggleOn(hasMatchingInsuredName);
	}, [isToggleOn, patientFirstName, patientLastName, insuredFirstNameCurrentValue, insuredLastNameCurrentValue]);


	React.useEffect(() => {
		if (!insurancePreAuthCurrentVal || (
			insurancePreAuthCurrentVal !== PreAuthOptionsEnum.YES &&
			insurancePreAuthCurrentVal !== PreAuthOptionsEnum.NO &&
			insurancePreAuthCurrentVal !== PreAuthOptionsEnum.NOT_APPLICABLE
		)) { return; }

		setAuthChecked(insurancePreAuthCurrentVal);
	}, [insurancePreAuthCurrentVal, setAuthChecked]);

	React.useEffect(() => {
		return () => {
			if (type === 'Secondary') {
				const formContextValues = getValues();
				reset({
					...formContextValues,
					insuredLastNameSecondary: undefined,
					insuredFirstNameSecondary: undefined,
					healthPlanNameSecondary: undefined,
					policyNumberSecondary: undefined,
					groupNumberSecondary: undefined,
					preAuthSecondary: undefined,
					preCertNumberSecondary: undefined
				});
			}
		};
	}, [type, reset, getValues]);

	const boxWidth = [1, .32];
	const { marginBottomMd } = useStyles();

	const handlePolicyPaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
		event.preventDefault();
		const pastedValue = event.clipboardData ? event.clipboardData.getData('Text') : '';
		insurancePolicyNumberSetCurrentValue(pastedValue.trim());
	};

	const handlePolicyBlur = () => {
		//remove leading and trailing spaces if present
		if (insurancePolicyNumberCurrentValue && insurancePolicyNumberCurrentValue.match(/(^\s) | (\s$)/ig) ) {
			insurancePolicyNumberSetCurrentValue(insurancePolicyNumberCurrentValue.trim());
		}
		void insurancePolicyNumberTriggerSelfValidation();
	};

	return (
		<Box {...rest}>
			<Text fontSize={14} mb={4} color="#828282">
				Enter insurance details as they appear on the card.
			</Text>
			<Box mt="16px">
				<Checkbox
					checked={isToggleOn}
					value="prefillCheckbox"
					onChange={handlePrefillCheckboxChange}
					color="primary"
					style={{ padding: '0px', marginRight: '8px' }}
					data-field="prefillCheckbox"
				/>
				<Text fontSize={16} color="#000000">
					Use patient name
				</Text>
			</Box>
			<Flex flexDirection="row" flexWrap="wrap" justifyContent="space-between">
				<Box width={[1, .49]} my="16px" data-field="insuredLastName">
					<TextFieldInput
						label="INSURED NAME"
						placeholder="Last"
						id="insuredLastName"
						name="insuredLastName"
						fieldName="insuredLastName"
						required
						value={insuredLastNameCurrentValue}
						errorMessage={insuredLastNameError}
						onBlur={insuredLastNameTriggerSelfValidation}
						onChange={insuredLastNameSetCurrentValue}
					/>
				</Box>
				<Box width={[1, .49]} my="16px" alignSelf="flex-end" data-field="insuredFirstName">
					<TextFieldInput
						placeholder="First"
						id="insuredFirstName"
						name="insuredFirstName"
						fieldName="insuredFirstName"
						required
						value={insuredFirstNameCurrentValue}
						errorMessage={insuredFirstNameError}
						onBlur={insuredFirstNameTriggerSelfValidation}
						onChange={insuredFirstNameSetCurrentValue}
					/>
				</Box>
			</Flex>
			<Flex flexDirection="row" flexWrap="wrap" justifyContent="space-between">
				<Box width={boxWidth} className={marginBottomMd} data-field="healthPlanName">
					<TextFieldInput
						label="HEALTH PLAN NAME"
						id="healthPlanName"
						name="healthPlanName"
						fieldName="healthPlanName"
						required
						value={insuranceHealthPlanNameCurrentValue}
						errorMessage={insuranceHealthPlanNameError}
						onBlur={insuranceHealthPlanNameTriggerSelfValidation}
						onChange={insuranceHealthPlanNameSetCurrentValue}
					/>
				</Box>
				<Box width={boxWidth} className={marginBottomMd} data-field="policyNumber">
					<TextFieldInput
						label="POLICY NUMBER"
						id="policyNumber"
						name="policyNumber"
						fieldName="policyNumber"
						onPaste={handlePolicyPaste}
						required
						value={insurancePolicyNumberCurrentValue}
						onBlur={handlePolicyBlur}
						onChange={insurancePolicyNumberSetCurrentValue}
						errorMessage={insurancePolicyNumberError}
					/>
				</Box>
				<Box width={boxWidth} className={marginBottomMd} data-field="groupNumber">
					<TextFieldInput
						label="GROUP NUMBER"
						id="groupNumber"
						name="groupNumber"
						fieldName="groupNumber"
						value={insuranceGroupNumberCurrentValue}
						errorMessage={insuranceGroupNumberError}
						onBlur={insuranceGroupNumberTriggerSelfValidation}
						onChange={insuranceGroupNumberSetCurrentValue}
					/>
				</Box>
			</Flex>
			<Flex flexDirection="row" flexWrap="wrap" data-field={'preAuth' + type}>
				<PreCertNumberInput type={type} />
				<Box width={boxWidth}>
					<Label id="preAuthLabel" required>
						PRE-AUTH IN PROGRESS
					</Label>
					<Flex flexDirection="row" mt="10px" style={{ gap: '36px' }}>
						<Radio
							key={1}
							name={'preAuth' + type}
							id="preAuthYes"
							checked={insurancePreAuthCurrentVal === PreAuthOptionsEnum.YES}
							label={YesNoAnswer.YES}
							value={PreAuthOptionsEnum.YES}
							onChange={handleRadioYes}
						/>
						<Radio
							key={2}
							name={'preAuth' + type}
							id="preAuthNo"
							checked={insurancePreAuthCurrentVal === PreAuthOptionsEnum.NO}
							label={YesNoAnswer.NO}
							value={PreAuthOptionsEnum.NO}
							onChange={handleRadioNo}
						/>
						<Radio
							key={3}
							name={'preAuth' + type}
							id="preAuthNA"
							checked={insurancePreAuthCurrentVal === PreAuthOptionsEnum.NOT_APPLICABLE}
							label="N/A"
							value={PreAuthOptionsEnum.NOT_APPLICABLE}
							onChange={handleRadioNotApplicable}
						/>
					</Flex>
					{
						insurancePreAuthError && <ErrorMessage errorField={{ message: insurancePreAuthError }} />
					}
				</Box>
			</Flex>
		</Box>
	);
}
