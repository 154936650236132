import { Dropdown } from '@ascension/web';
import React from 'react';
import { useDropdownFormContext } from '@utilities/hooks/useDropdownFormContext/useDropdownFormContext';
import {
	AppointmentDetailsValidationSchema
} from '@components/RequestForm/AppointmentDetails/AppointmentDetailsValidationSchema';

const RequestAuthStatusDropdown = () => {
	const {
		selectedDropdownOption: requestAuthStatus,
		setSelectedValue: setSelectedAuthStatusValue,
		dropdownOptions: requestAuthStatusOptions,
		error: requestAuthStatusError,
	} = useDropdownFormContext(
		'appointmentRequestAuthStatus',
		[
			{ label: 'Inpatient', value: 'Inpatient' },
			{ label: 'Outpatient', value: 'Outpatient' },
			{ label: 'Observation / 23 hr', value: 'Observation / 23 hr' },
		],
		{
			validationRules: AppointmentDetailsValidationSchema.requiredDropdown
		},
	);

	return (
		<Dropdown
			required
			label="Intended order status/auth status"
			options={requestAuthStatusOptions}
			placeholder="Select Status"
			selected={requestAuthStatus}
			onSelect={setSelectedAuthStatusValue}
			errorMessage={requestAuthStatusError}
			data-field="requestForm-requestAuthStatus-dropdown"
		/>
	);
};

export default RequestAuthStatusDropdown;
