import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FindATimeForm } from '@components/FindATime/FindATimeForm';
import { useAnchorContext } from '@components/AnchorProvider/AnchorProvider';
import { useProcedureNameContext } from '@components/RequestForm/ProcedureName/ProcedureNameContext';

export const useProcedureOptionSelect = () => {
	const { setValue } = useFormContext<FindATimeForm>();
	const { handleClearAnchorEl } = useAnchorContext();
	const { setSelectedProcedureName } = useProcedureNameContext();

	return React.useCallback(
		(value: string) => {
			handleClearAnchorEl();
			setSelectedProcedureName(value);
			setValue('procedureName', value, { shouldValidate: true });
		},
		[handleClearAnchorEl, setSelectedProcedureName, setValue],
	);
};
