import { Box } from '@ascension/web';
import React, { ReactNode } from 'react';
import { Typography } from '@material-ui/core';

interface CardSectionTitleProps {
	title: ReactNode;
}

const CardSectionTitle: React.FC<CardSectionTitleProps> = ({ title, children }) => {
	return (
		<Box
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			width={1}
		>
			{typeof title === 'string' ? <Typography className="section-title" variant="h5" component="h2">{title}</Typography> : title}
			{children}
		</Box>
	);
};

export default CardSectionTitle;
