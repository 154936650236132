import React from 'react';
import { Box, InputLabel, MenuProps } from '@material-ui/core';
import { Dropdown } from '@ascension/ui-library';
import { alpha, createStyles, makeStyles } from '@material-ui/core/styles';
import ErrorMessageMui from '@components/ErrorMessageMui/ErrorMessageMui';
import ErrorIcon from '@material-ui/icons/HighlightOff';
import DropdownInputProps from '@components/DropdownInput/DropdownInputProps';
import classNames from 'classnames';
import getRequestFormLabelStyle from '@components/RequestForm/utilities/getRequestFormLabelStyle';

const useStyles = makeStyles((theme) => createStyles({
	root: {
		'marginBottom': 'unset',
		'&>.MuiInputLabel-root': getRequestFormLabelStyle(theme),
		'& .MuiAscensionDropdown-formControlDefault': {
			'width': '100%',
			'margin': theme.spacing(2, 0, 0),
			'& label.MuiInputLabel-root': {
				'&.MuiFormLabel-filled': {
					display: 'none',
				},
				'&.MuiInputLabel-shrink': {
					transform: 'translate(14px, 20px)',
					color: alpha(theme.palette.common.black, .54),
				},
			},
			'&  .MuiOutlinedInput-notchedOutline>legend': {
				display: 'none',
			},
			'& .MuiFormHelperText-root.Mui-error': {
				display: 'none',
			}
		},
	},
	paper: {
		// The !important declaration is the only way to override inline styles from this class selector
		transition: 'none !important',
	},
	bodyOverflowHidden: {
		overflow: 'hidden',
	},
}));

const DropdownInput: React.FC<DropdownInputProps> = ({
	value,
	onChange,
	error,
	label,
	placeholder = '',
	required = false,
	fieldName,
	children,
	disabled,
	...boxProps
}) => {
	const classes = useStyles();

	const menuProps = {
		classes: {
			paper: classes.paper,
		},
		// cast to MenuProps to workaround bug in ui-library requiring open prop
	} as Partial<MenuProps> as MenuProps;

	const [menuOpen, setMenuOpen] = React.useState(false);

	const handleOpenDropdown = () => {
		setMenuOpen(true);
		// Add the class to hide the body's overflow
		document.body.classList.add(classes.bodyOverflowHidden);
	};

	const handleCloseDropdown = () => {
		setMenuOpen(false);
		// Remove the class to restore the body's overflow
		document.body.classList.remove(classes.bodyOverflowHidden);
	};

	return (
		<Box
			width={[1, 1/2]}
			mb={8}
			{...boxProps}
			data-field={`${fieldName}-box`}
			className={classNames(classes.root, boxProps.className)}
		>
			<InputLabel
				required={required}
				data-field={`${fieldName}-label`}
			>
				{label}
			</InputLabel>
			<Dropdown
				data-field={`${fieldName}-dropdown`}
				label={placeholder}
				aria-required={true}
				value={value}
				displayEmpty={true}
				select={onChange}
				translate="yes"
				error={!!error}
				MenuProps={menuProps}
				open={menuOpen}
				onClose={handleCloseDropdown}
				onOpen={handleOpenDropdown}
				disabled={disabled}
			>
				{children}
			</Dropdown>
			<ErrorMessageMui icon={ErrorIcon}>{error}</ErrorMessageMui>
		</Box>
	);
};

export default DropdownInput;
