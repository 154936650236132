import React, { useCallback } from 'react';
import { Box, Button, Flex, Text } from '@ascension/web';
import { useDispatch, useSelector } from 'react-redux';
import { TemplateListCountSelector, TemplateListSelector } from '../../../store/selectors/TemplateListSelector';
import TemplateTile from '../../AccountSettings/MyTemplates/TemplateTile/TemplateTile';
import { getTemplateList } from '../../../store/actions/TemplateListActionCreators';
import { FiltersProvider } from '../../Filters/FiltersContext/FiltersContext';
import { FilterApiParams } from '../../../interfaces/FilterParams';
import { DEFAULT_TEMPLATES_SORT } from '../../../utilities/apiConstants';
import TemplateApiInfoToSurgeryProcedureReq
	from '../../../utilities/typeConverters/TemplateApiInfoToSurgeryProcedureReq';
import { getAllSurgeonListData } from '../../../store/actions/SurgeonListActionCreators';
import { UserProfilePrimaryPracticeSelectorId } from '../../../store/selectors/UserProfileSelector';
import { MyTemplatesModalProps } from './MyTemplatesModalProps';
import { PreferredSurgeonFilter } from '../../Filters/SurgeonFilter/SurgeonFilter';
import TemplateFilters from '../../Filters/TemplateFilters/TemplateFilters';
import TemplateListSection from '../../Templates/TemplateListSection';
import NoTemplatesMessage from '../../Templates/NoTemplatesMessage';
import { PreferredSurgeonFilterProps } from '../../Filters/SurgeonFilter/SurgeonFilterProps';
import { TemplateTileProps } from '../../AccountSettings/MyTemplates/TemplateTile/TemplateTileProps';

const PreferredSurgeonFilterWithNoSurgeon = ({ ...rest }: PreferredSurgeonFilterProps) => {
	return <PreferredSurgeonFilter showNoSurgeon={true} {...rest} />;
};

function MyTemplatesModalInner ( { closeSelf, openSurgeryProcedureModal, setSurgeryProcedureFromTemplate }: MyTemplatesModalProps)  {
	const dispatch = useDispatch();

	const practiceId = useSelector(UserProfilePrimaryPracticeSelectorId);

	const templateList = useSelector(TemplateListSelector);

	// Retrieve all surgeons for practice
	React.useEffect(() => {
		if (practiceId !== '') {
			dispatch(getAllSurgeonListData(practiceId));
		}
	}, [dispatch, practiceId]);

	const selectedTemplateCallback = React.useCallback((selectedTemplateId: string) => {
		const templateInfo = templateList.find(currentTemplate => currentTemplate.id === selectedTemplateId);
		setSurgeryProcedureFromTemplate(TemplateApiInfoToSurgeryProcedureReq(templateInfo));
		closeSelf();
		openSurgeryProcedureModal();
	}, [closeSelf, openSurgeryProcedureModal, setSurgeryProcedureFromTemplate, templateList]);

	const TemplateDisplay = React.useMemo(() => {
		return function MyTemplateInfoDisplay({ info }: Pick<TemplateTileProps, 'info' >) {
			return <TemplateTile info={info} onClick={selectedTemplateCallback}/>;
		};
	}, [selectedTemplateCallback]);

	const noTemplates = (
		<NoTemplatesMessage>
			<Box py="4px">
				<Text fontSize={3}>
					Go to my templates to create a new template.
				</Text>
			</Box>
		</NoTemplatesMessage>);

	const withTemplates = (
		<TemplateListSection templateList={templateList} templateDisplay={TemplateDisplay} />
	);

	return (
		<Box px={40}>
			<Flex flexDirection="column" justifyContent="left">
				<Flex flexDirection="column" py={3}>
					<Flex justifyContent="space-between" width={1} alignItems="start" >
						<Box>
							<Text fontSize={7} letterSpacing={1} fontWeight="bold">
								Add a Procedure
							</Text>
						</Box>
					</Flex>
					<Flex justifyContent="space-between" letterSpacing={1} width={1} alignItems="start" pt={4} pb={4}>
						<Box>
							<Text fontSize={2}>
								Select a template
							</Text>
						</Box>
					</Flex>
				</Flex>
				<TemplateFilters surgeonFilter={PreferredSurgeonFilterWithNoSurgeon} />
				{templateList.length ? withTemplates : noTemplates}
			</Flex>
			<hr/>
			<Box mt={8} textAlign = "right">
				<Button secondary onClick={closeSelf} id="cancel">Cancel</Button>
			</Box>
		</Box>
	);
}

export default function MyTemplatesModal({ closeSelf, openSurgeryProcedureModal, setSurgeryProcedureFromTemplate }: MyTemplatesModalProps) {
	const dispatch = useDispatch();

	const loadListHandler = useCallback((apiFilters: FilterApiParams) => {
		dispatch(getTemplateList(apiFilters));
	}, [dispatch]);

	return (
		<FiltersProvider
			defaultSort={DEFAULT_TEMPLATES_SORT}
			disableRouteParams={true}
			disableDefaultFilters={true}
			totalCountSelector={TemplateListCountSelector}
			entityName="Templates"
			onListLoadRequest={loadListHandler}
		>
			<MyTemplatesModalInner closeSelf={closeSelf} openSurgeryProcedureModal={openSurgeryProcedureModal} setSurgeryProcedureFromTemplate={setSurgeryProcedureFromTemplate}/>
		</FiltersProvider>
	);
}
