import React from 'react';
import { AlertBox } from '@ascension/ui-library';
import { makeStyles } from '@material-ui/core/styles';
import { NotificationsNoneOutlined } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
	alertStyle: {
		marginBottom: theme.spacing(4),
		height: theme.spacing(5),
		display: 'flex',
		alignItems: 'center',
		borderColor: theme.palette.grey[700],
		boxShadow: theme.shadows[6],
	},
	iconStyle: {
		color: theme.palette.grey[700],
	},
}));

const PendingLinkAlert = () => {
	const { alertStyle, iconStyle } = useStyles();

	return (
		<AlertBox
			translate="yes"
			leftBorderAccent={true}
			className={alertStyle}
			icon={<NotificationsNoneOutlined className={iconStyle}/>}
		>
			<p>Case is pending to link to the EHR.</p>
		</AlertBox>
	);
};

export default PendingLinkAlert;
