import { UserRegistration } from '@components/UserRegistrationPage/UserRegistration';
import { UserStatus } from '@interfaces/UserProfile/UserStatusEnum';
import { UserRole } from '@interfaces/UserProfile/UserRoleEnum';
import NoParamNoReturnCallback from '@interfaces/NoParamNoReturnCallback';

export interface ManagedUser extends Omit<UserRegistration, 'userRoles'> {
	dateCreated: string | undefined;
	userRolesStr: string;
	statusStr: string;
	dateCreatedNum: Date | undefined;
	userId: string;
	status: UserStatus;
	userRoles: UserRole[] | null;
}

export enum InteractionState {
	NONE,
	VIEW,
	DEACTIVATE,
}
export interface InteractionDescriptor {
	user: string | undefined; // userId
	state: InteractionState;
}

export interface ManageUsersListContextType {
	// State info
	users: ManagedUser[] | undefined;
	managedUser: ManagedUser | undefined;
	interactionState: InteractionState;

	// Action handlers
	viewUser: (userInfo: ManagedUser) => void;
	deactivateUser: (userId: string) => void;
	deactivateUserApiCall: NoParamNoReturnCallback;
	resetInteractionState: NoParamNoReturnCallback;

	/***
	 * @deprecated - use `interactionState === InteractionState.VIEW` instead
	 */
	isDrawerOpen: boolean;
	/***
	 * @deprecated - use `resetInteractionState()` instead
	 */
	closeDrawer: NoParamNoReturnCallback;
}
