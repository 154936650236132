import React from 'react';
import { Flex, Text } from '@ascension/web';
import Divider from '@material-ui/core/Divider';
import { DisplayText } from '../../DisplayText/DisplayText';
import { useSelector } from 'react-redux';
import {
	CaseDetailsInfoSelector,
	CaseDetailsSurgeryInformationSelector,
} from '../../../store/selectors/CaseDetailsSelector';
import { SurgicalProcedureDisplay } from './SurgicalProcedureDisplay';
import { DiagnosisApi } from '../../../interfaces/SurgeryRequest/DiagnosesApi';
import { ProcedureInfoApi } from '../../../interfaces/Procedure/ProcedureInfoApi';
import { makeStyles } from '@material-ui/core/styles';
import { useCommonStyles } from '../../../utilities/hooks/useCommonStyles/useCommonStyles';
import { isWebRequest } from '@utilities/typeAssertionUtilities';

const useStyles = makeStyles((theme) => ({
	flexContainer: {
		'display': 'flex',
		'flexDirection': 'column',
		'alignItems': 'space-between',
		'& > .procedures hr:first-child': {
			display: 'none',
		},
	},
	divider: {
		backgroundColor: theme.palette.grey['300'],
		marginBottom: theme.spacing(3),
	},
}));

export const SurgeryInformationDisplay = () => {
	const { diagnosis, comments } = useSelector(CaseDetailsSurgeryInformationSelector);
	const caseDetails = useSelector(CaseDetailsInfoSelector);

	let procedures: ProcedureInfoApi[] = [];
	if (isWebRequest(caseDetails)) {
		procedures = caseDetails.procedures;
	}

	const { code: primaryCode } = (diagnosis && diagnosis.length ? diagnosis.find((diag: DiagnosisApi) => diag.primary) || diagnosis[0] : {}) as DiagnosisApi;
	const secondaryCodes = diagnosis && diagnosis.length ? diagnosis.filter((diag: DiagnosisApi) => diag.code !== primaryCode).map((diag: DiagnosisApi) => {
		return diag.code;
	}).join(', ') : '';

	const classes = useStyles();
	const { rowGap } = useCommonStyles();

	return (
		<div className={classes.flexContainer}>
			<Text
				fontWeight="bold"
				fontSize="16px"
				pt="10px"
				pb="15px"
			>
				ICD-10 Codes
			</Text>
			<div style={{ display: 'flex' }}>
				<DisplayText
					label="Primary Diagnoses"
					defaultValue="--"
					displayDefault={!primaryCode}
					data-field="Surgery_diagnosesPrimaryCode"
					mr="60px"
				>
					{primaryCode}
				</DisplayText>
				<DisplayText
					label="Secondary Diagnoses"
					defaultValue="--"
					displayDefault={!secondaryCodes}
					data-field="Surgery_diagnosesSecondaryCode"
				>
					{secondaryCodes}
				</DisplayText>
			</div>
			<Text
				fontWeight="bold"
				fontSize="16px"
				py="24px"
				data-field="procedure-title"
			>
				{procedures?.length === 1 ? 'Surgical Procedure' : `Surgical Procedures (${procedures?.length})`}
			</Text>
			<Flex
				data-section="ProceduresDisplay"
				flexDirection="column"
				className="procedures"
			>
				{procedures!==undefined && procedures.map((procedure: ProcedureInfoApi) => (
					<SurgicalProcedureDisplay procedure={procedure} key={procedure.id}/>
				))}
			</Flex>
			{comments &&
				<>
					<Divider className={classes.divider}/>
					<DisplayText
						label="Other Surgical Comments"
						data-field="Other_Surgical_Comments"
						className={rowGap}
					>
						{comments}
					</DisplayText>
				</>
			}
		</div>
	);
};
