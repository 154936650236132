import React from 'react';
import { Theme, Typography, TypographyProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
	reason: {
		display: 'inline',
		fontWeight: 600,
	},
	text: {
		display: 'inline',
		fontWeight: 325,
		marginLeft: theme.spacing(.25),
	},
	[theme.breakpoints.down('md')]: {
		reason: {
			fontSize: '10px',
		},
		text: {
			fontSize: '10px',
		},
	},
}));

const CaseActivityReason: React.FC<Partial<TypographyProps>> = ({ children, className, ...props }) => {
	const classes = useStyles();

	return (
		<Typography
			variant="caption"
			className={classNames(classes.reason, className)}
			{...props}
		>
			Reason:
			<Typography
				component="p"
				variant="caption"
				className={classes.text}
			>
				{children}
			</Typography>
		</Typography>
	);
};

export default CaseActivityReason;
