import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { apiAction } from '@store/middlewares/api/ApiActionCreators';
import { ENDPOINT_EDIT_UNAVAILABLE_EVENT, ENDPOINT_UNAVAILABLE_EVENT } from '@utilities/apiConstants';
import { CREATE_UNAVAILABLE_EVENT, EDIT_UNAVAILABLE_EVENT } from '@store/actions/types';
import shouldDisableErrorHandlerByStatusNums
	from '@utilities/shouldDisableErrorHandlerByStatusNums/shouldDisableErrorHandlerByStatusNums';
import ApiService from '@utilities/apiEndpoints/ApiService';
import {
	useUnitCalendarPageContext
} from '@components/UnitCalendarPage/UnitCalendarPageContext/UnitCalendarPageContext';
import SubmitUnavailableEventHookResult from '@data/openCalendar/SubmitUnavailableEventHookResult';
import { useToast } from '@utilities/hooks/useToast/useToast';
import { ToastType } from '@interfaces/ToastStatus/ToastStatusState';
import { ToastConstants } from '@utilities/toastConstants';
import { AxiosError } from 'axios';
import { OVERLAPPING_UNAVAILABLE_TIME } from '@utilities/Validation/ValidationMessages';
import unavailableEventFormToApiConverter from '@utilities/typeConverters/unavailableEventFormToApiConverter';
import UnavailableEventFormType from '@components/UnitCalendarPage/utilities/UnavailableEventFormType';
import endpointTemplateToUrl from '@utilities/endpointTemplateToUrl';
import { Indicator, decorateWithIndicator } from '@utilities/Validation/TimeSpanValidationUtilities/TimeSpanValidationUtilities';

/***
 * useSubmitUnavailableEvent - Contains submitUnavailableEvent function to call API to add or edit an Unavailable Event
 *
 * @returns {SubmitUnavailableEventHookResult} - Returns information necessary to add or edit an Unavailable Event and handle errors
 */
const useSubmitUnavailableEvent = (): SubmitUnavailableEventHookResult => {
	const dispatch = useDispatch();
	const { setError } = useFormContext();
	const {
		getUnitEventsFromApi,
		rooms,
		selectedRoom = '',
		selectedUnit: { hospital: hospitalId, id: unitId },
		setSelectedRoom,
	} = useUnitCalendarPageContext();
	const roomId = rooms[selectedRoom];
	const showToast = useToast();
	const hasAPIConflictError = React.useRef<boolean>(false);

	const setTimeSpanAPIError = React.useCallback(() => {
		const errorMsg = decorateWithIndicator(
			Indicator.START,
			decorateWithIndicator(Indicator.END, OVERLAPPING_UNAVAILABLE_TIME)
		);
		setError('timeSpan', { type: 'validate', message: errorMsg });
	}, [setError]);

	const submitUnavailableEvent = React.useCallback((unavailableEventFormValues: UnavailableEventFormType) => {
		if (!hospitalId || !roomId || !unitId) { return; }

		const unavailableEventId = unavailableEventFormValues?.id;
		const unavailableEventConvertedData = unavailableEventFormToApiConverter(unavailableEventFormValues, hospitalId, roomId, unitId);
		const editUnavailableEventUrl = unavailableEventId ? endpointTemplateToUrl(ENDPOINT_EDIT_UNAVAILABLE_EVENT, { unavailableEventId }) : '';

		const onSuccess = () => {
			showToast(ToastType.SUCCESS, unavailableEventId ? ToastConstants.EDIT_UNAVAILABLE_EVENT_SUCCESS : ToastConstants.CREATE_UNAVAILABLE_EVENT_SUCCESS);
			getUnitEventsFromApi();
			setSelectedRoom(undefined);
		};

		const onFailure = (error: AxiosError) => {
			getUnitEventsFromApi();
			const status = error.response?.status;
			if (status === 409) {
				setTimeSpanAPIError();
				hasAPIConflictError.current = true;
			}
		};

		dispatch(apiAction({
			url: unavailableEventId ? editUnavailableEventUrl : ENDPOINT_UNAVAILABLE_EVENT,
			method: unavailableEventId ? 'PUT' : 'POST',
			includeAccessToken: true,
			label: unavailableEventId ? EDIT_UNAVAILABLE_EVENT : CREATE_UNAVAILABLE_EVENT,
			data: unavailableEventConvertedData,
			onFailure,
			onSuccess,
			shouldDisableErrorHandler: shouldDisableErrorHandlerByStatusNums([409]),
			apiService: ApiService.BTS,
		}));
	}, [dispatch, getUnitEventsFromApi, hospitalId, roomId, setSelectedRoom, setTimeSpanAPIError, showToast, unitId]);

	return {
		submitUnavailableEvent,
		hasAPIConflictError,
		setTimeSpanAPIError
	};
};

export default useSubmitUnavailableEvent;

