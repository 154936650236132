import React, { useContext } from 'react';
import { DrawerContextProviderType } from '@components/DrawerWrapper/DrawerContextProviderType';

const DrawerContext = React.createContext<DrawerContextProviderType | null>(null);

export const useDrawerContext = () => {
	const ctx = useContext(DrawerContext);

	if (!ctx) {
		throw new Error('useDrawerContext must be used in subcomponent of DrawerContextProvider');
	}

	return ctx;
};

export const DrawerContextProvider: React.FC = ({ children }) => {
	const [activeDrawer, setActiveDrawer] = React.useState<string | null>(null);

	const openDrawer = React.useCallback((drawerId: string) => {
		setActiveDrawer(drawerId);
	}, [setActiveDrawer]);

	const closeDrawer = React.useCallback((drawerId: string) => {
		if (activeDrawer === drawerId) {
			setActiveDrawer(null);
		}
	}, [activeDrawer, setActiveDrawer]);

	return (
		<DrawerContext.Provider value={{ activeDrawer, openDrawer, closeDrawer }}>
			{children}
		</DrawerContext.Provider>
	);
};
