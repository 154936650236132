import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { UserProfileHasARoleSelector } from '../../../../store/selectors/UserProfileSelector';
import { OfficeInteractiveRoles } from '../../../../interfaces/UserProfile/UserRoleEnum';
import { CaseDetailsInfoSelector } from '../../../../store/selectors/CaseDetailsSelector';
import { setRevisionToCleared } from '../../../../store/actions/RevisionActionCreators';
import { UpdatedDateAlertBodyProps } from './UpdatedDateAlertBodyProps';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: theme.spacing(1, 2),
	},
	message: {
		fontSize: '0.875rem',
		fontFamily: 'Lato',
	},
	reviewBtn: {
		fontSize: '0.875rem',
		letterSpacing: '0.078rem',
		padding: 0,
		color: theme.palette.primary.main,
	},
}));

const UpdatedDateAlertBody = ({ revision }: UpdatedDateAlertBodyProps) => {
	const dispatch = useDispatch();
	const styles = useStyles();
	const hasOfficeInteractiveRole = useSelector(UserProfileHasARoleSelector(OfficeInteractiveRoles));
	const { id: caseId } = useSelector(CaseDetailsInfoSelector);

	const handleClearAlert = () => {
		dispatch(setRevisionToCleared(caseId, revision.id, true));
	};

	return (
		<Box className={styles.container}>
			<Typography className={styles.message}>
				Mark as seen to confirm update is accurate.
			</Typography>
			<Button
				className={styles.reviewBtn}
				data-field="markUpdatedDateAsSeen"
				disabled={!hasOfficeInteractiveRole}
				aria-disabled={!hasOfficeInteractiveRole}
				onClick={handleClearAlert}
			>
				Mark as Seen
			</Button>
		</Box>
	);
};

export default UpdatedDateAlertBody;
