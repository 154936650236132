import React from 'react';
import { CancelOutlined, FileCopyOutlined, VisibilityOutlined, EditOutlined } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { LinkMenu } from '@components/LinkMenu/LinkMenu';
import { LinkMenuOptionProps } from '@components/LinkMenu/LinkMenuOption/LinkMenuOption';
import {
	useCaseLockStatus
} from '@components/CaseDetails/ManageCaseDropDown/DropDownOptions/EditCase/CaseLockModal/useCaseLockStatus/useCaseLockStatus';
import { CASE_LOCK_MODAL, COPY_CASE_MODAL, SCHEDULING_CANCEL_MODAL } from '@components/Modal/modalConstants';
import { useModalContext } from '@components/Modal/ModalContextProvider';
import BlockEventProps from '@components/SchedulePage/BlockEvent/BlockEventProps';
import { OfficeRoles, OfficeInteractiveRoles } from '@interfaces/UserProfile/UserRoleEnum';
import useGetCaseDetails from '@data/caseDetails/hooks/useGetCaseDetails';
import { AppointmentType } from '@data/request/AppointmentType';
import RequestFormPageState from '@components/RequestForm/RequestFormPage.types';

export const ManageScheduleEventMenu: React.FC<BlockEventProps> = ({ eventInfo }) => {
	const history = useHistory<RequestFormPageState>();
	const checkCaseLockStatus = useCaseLockStatus();
	const { openModal } = useModalContext();
	const changeRoute = React.useCallback((path: string) => {
		history.push(path);
	}, [history]);

	const handleCancelCaseClick = React.useCallback(() => {
		checkCaseLockStatus(eventInfo.id, {
			onSuccess: () => openModal(SCHEDULING_CANCEL_MODAL),
			onFailure: () => openModal(CASE_LOCK_MODAL),
		});
	}, [checkCaseLockStatus, eventInfo.id, openModal]);

	const getCaseDetails = useGetCaseDetails();
	const handleCopyCaseClick = React.useCallback(() => {
		getCaseDetails(eventInfo.id, {
			onSuccess: () => {
				openModal(COPY_CASE_MODAL);
			},
		});
	}, [eventInfo.id, getCaseDetails, openModal]);

	const handleEditCaseClick = React.useCallback(() => {
		checkCaseLockStatus(eventInfo.id, {
			// if case is not locked, get case details in order to populate edit form
			onSuccess: () => getCaseDetails(eventInfo.id, {
				// after details are retrieved, redirect to edit form with isSchedulingEdit set to true
				onSuccess: () => {
					history.push(`/cases/${eventInfo.id}`, {
						isSchedulingEdit: true,
					});
				},
			}),
			// if case is locked, open case locked modal
			onFailure: () => openModal(CASE_LOCK_MODAL),
		});
	}, [checkCaseLockStatus, eventInfo.id, getCaseDetails, openModal, history]);

	const isDocumentRequest = eventInfo.flags.includes(AppointmentType.DOCUMENT);

	const options: LinkMenuOptionProps[] = React.useMemo(() => {
		return [
			{
				id: 'viewCaseDetails',
				label: 'View Detail',
				icon: VisibilityOutlined,
				onClick: () => changeRoute('/cases/' + eventInfo.id),
				order: 4,
				showForRoles: OfficeRoles,
			},
			{
				id: 'cancelCase',
				label: 'Cancel',
				icon: CancelOutlined,
				onClick: handleCancelCaseClick,
				showForRoles: OfficeInteractiveRoles,
				order: 1
			},
			{
				id: 'copyCase',
				label: 'Copy',
				icon: FileCopyOutlined,
				onClick: handleCopyCaseClick,
				showForRoles: OfficeInteractiveRoles,
				order: 2,
				shouldHide: isDocumentRequest,
			},
			{
				id: 'editCase',
				label: 'Edit',
				icon: EditOutlined,
				onClick: handleEditCaseClick,
				showForRoles: OfficeInteractiveRoles,
				order: 3,
			},
		];
	}, [changeRoute, eventInfo.id, handleCancelCaseClick, handleCopyCaseClick, handleEditCaseClick, isDocumentRequest]);

	if (!eventInfo.isPracticeEvent) {
		return null;
	}

	return (
		<LinkMenu
			label="Manage Case"
			options={options}
			data-field="scheduling-event-manageCaseMenu"
		/>
	);
};
