import {
	CREATE_NEW_TEMPLATE, DELETE_TEMPLATE,
	LOCK_TEMPLATE,
	UNLOCK_TEMPLATE,
	UPDATE_TEMPLATE,
	CREATE_DUPLICATE_TEMPLATE,
} from '@store/actions/types';
import { TemplateInfoApi } from '@interfaces/Procedure/TemplateInfoApi';
import { AxiosError, AxiosResponse } from 'axios';
import { updateTemplateStatus } from '@store/actions/TemplateStatusActionCreators';
import { TemplateTagType } from '@components/AccountSettings/MyTemplates/TemplateTile/TemplateTagType';
import { TemplateApi } from '@interfaces/Procedure/TemplateApi';
import { ApiActionRequest } from '@interfaces/ApiAction/ApiAction';
import { apiAction } from '@store/middlewares/api/ApiActionCreators';
import {
	ENDPOINT_ALL_TEMPLATE,
	ENDPOINT_DELETE_TEMPLATE,
	ENDPOINT_LOCK_TEMPLATE,
	ENDPOINT_UNLOCK_TEMPLATE
} from '@utilities/apiConstants';
import { Action } from 'redux';
import { defaultCallback } from '@utilities/defaultCallback';
import NoParamNoReturnCallback from '@interfaces/NoParamNoReturnCallback';
import { ErrorStatusState } from '@interfaces/ErrorStatus/ErrorStatusState';

export function formatApiTemplateFormError(err: AxiosError<ErrorStatusState> | undefined) {
	return err && err.response && err.response.data;
}

export function createNewTemplate(
	dataToSave: TemplateApi,
	onSuccessCb: (r: AxiosResponse<TemplateInfoApi>) => void,
	onFailureCb: (err: AxiosError<ErrorStatusState> | undefined) => void
): ApiActionRequest<TemplateApi, ErrorStatusState, TemplateInfoApi> {
	const onSuccess = (response: AxiosResponse<TemplateInfoApi>): Action | void => {
		onSuccessCb(response);
		// mark newly created template with tag NEW
		if (response && response.data && response.data.id) {
			return updateTemplateStatus(response.data.id, TemplateTagType.NEW);
		}
	};

	return apiAction<TemplateApi, ErrorStatusState, TemplateInfoApi>({
		url: ENDPOINT_ALL_TEMPLATE,
		method: 'POST',
		label: CREATE_NEW_TEMPLATE,
		data: dataToSave,
		includeAccessToken: true,
		onSuccess,
		onFailure: onFailureCb
	});
}

export function createDuplicateTemplate(
	dataToSave: TemplateApi,
	successCb: (r: AxiosResponse<TemplateInfoApi>) => void,
	onFailureCb: (err: AxiosError<ErrorStatusState> | undefined) => void
): ApiActionRequest<TemplateApi, ErrorStatusState, TemplateInfoApi> {
	const onSuccess = (response: AxiosResponse<TemplateInfoApi>): Action | void => {
		successCb(response);
		// mark newly created template with tag DUPLICATED
		if (response && response.data && response.data.id) {
			return updateTemplateStatus(response.data.id, TemplateTagType.DUPLICATE);
		}
	};
	return apiAction<TemplateApi, ErrorStatusState, TemplateInfoApi>({
		url: ENDPOINT_ALL_TEMPLATE,
		method: 'POST',
		label: CREATE_DUPLICATE_TEMPLATE,
		data: dataToSave,
		includeAccessToken: true,
		onSuccess,
		onFailure: onFailureCb
	});
}

export function updateTemplate(
	dataToSave: TemplateApi,
	onSuccessCb: (r: AxiosResponse<TemplateInfoApi>) => void,
	onFailureCb: (err: AxiosError<ErrorStatusState>) => void
): ApiActionRequest<TemplateApi, ErrorStatusState, TemplateInfoApi> {
	const onSuccess = (response: AxiosResponse<TemplateInfoApi>): Action | void => {
		onSuccessCb(response);
		// mark newly created template with tag EDITED
		if (response && response.data && response.data.id) {
			return updateTemplateStatus(response.data.id, TemplateTagType.EDITED);
		}
	};
	return apiAction<TemplateApi, ErrorStatusState, TemplateInfoApi>({
		url: ENDPOINT_ALL_TEMPLATE,
		method: 'PUT',
		label: UPDATE_TEMPLATE,
		data: dataToSave,
		includeAccessToken: true,
		onSuccess,
		onFailure: onFailureCb
	});
}

export function lockTemplate(id: string, onSuccess: NoParamNoReturnCallback, onFailure: (err: AxiosError<string>) => void) {
	const endpoint = ENDPOINT_LOCK_TEMPLATE
		.replace('{id}', id);

	return apiAction({
		url: endpoint,
		method: 'POST',
		label: LOCK_TEMPLATE,
		includeAccessToken: true,
		onSuccess,
		onFailure,
	});
}

export function unlockTemplate(id: string) {
	const endpoint = ENDPOINT_UNLOCK_TEMPLATE
		.replace('{id}', id);

	return apiAction({
		url: endpoint,
		method: 'POST',
		label: UNLOCK_TEMPLATE,
		includeAccessToken: true,
		onSuccess: defaultCallback,
	});
}
/***
* 	Action creator that returns API action that will attempt to delete the template specified in the parameter id.
 * 	@param {string} id - id of the template to delete
 * 	@param {Function} onSuccess - function that will be called after API returns response containing code indicating success
 * 	@param {(error: AxiosError) => void} onFailure - called if response from API call has error code
 * 	@returns {apiAction} API action that will attempt to delete template with id specified in the URL
 */
export function deleteTemplate(id: string, onSuccess: NoParamNoReturnCallback, onFailure?: (error: AxiosError<string>) => void) {
	const adjustedApiEndpoint = ENDPOINT_DELETE_TEMPLATE
		.replace('{id}', id);

	return apiAction({
		url: adjustedApiEndpoint,
		method: 'PUT',
		label: DELETE_TEMPLATE,
		includeAccessToken: true,
		onSuccess,
		onFailure,
	});
}
