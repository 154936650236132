import React from 'react';
import { Button as AscButton } from '@ascension/ui-library';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core';
import { useManageUsersListContext } from '../../ManageUsersListContext/ManageUsersListContext';

const useStyles = makeStyles((theme) => createStyles({
	btnStyle: {
		width: 'fit-content',
		height: '48px',
		fontSize: '14px',
		lineHeight: '16px',
		marginRight: theme.spacing(2),
	},
	icon: {
		height: '16px',
		width: '16px',
		paddingRight: theme.spacing(1.5),
		color: theme.palette.primary.main,
		cursor: 'pointer'
	},
}));

export const DeactivateUserButton = () => {
	const { btnStyle, icon } = useStyles();
	const { managedUser, deactivateUser } = useManageUsersListContext();

	return (
		<AscButton
			translate="yes"
			color="primary"
			variant="outlined"
			className={btnStyle}
			data-field="DeactivateUserButton"
			onClick={() => managedUser?.userId && deactivateUser(managedUser.userId)}
		>
			<DeleteIcon className={icon} />
			Deactivate
		</AscButton>
	);
};
