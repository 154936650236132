import React, { ReactElement } from 'react';
import InformationModalContextType from './InformationModalContextType';
import { Box, IconButton, Dialog, DialogProps, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DialogHeader from '../DialogHeader/DialogHeader';
import { createStyles } from '@material-ui/core/styles';

const InformationModalContext = React.createContext<InformationModalContextType | null>(null);

export const useInformationModal = (): InformationModalContextType => {
	const ctx = React.useContext(InformationModalContext);
	if (!ctx) {
		throw new Error('useInformationModal must be used in a child component of InformationModal');
	}
	return ctx;
};

const useStyles = makeStyles((theme) => createStyles({
	modal: {
		'& [class*="SsmDialogHeader-root"]': {
			padding: theme.spacing(.75, 1, .75, 3),
			boxSizing: 'border-box',
		},
		'height': '100%',
		'display': 'flex',
		'flexDirection': 'column',
		'overflow': 'hidden',
	},
	modalContent: {
		padding: theme.spacing(3),
		flex: 'auto 1 1',
		overflowY: 'auto',
	},
}));

export const InformationModal: React.FC<Omit<DialogProps, 'open' | 'onClose'>> = ({ children, ...rest }) => {
	const classes = useStyles();
	const [title, setTitle] = React.useState<ReactElement | null>(null);
	const [content, setContent] = React.useState<ReactElement | null>(null);

	const closeModal = React.useCallback(() => {
		setTitle(null);
		setContent(null);
	}, []);
	const openModal = React.useCallback((c: ReactElement, t?: ReactElement) => {
		setContent(c);
		setTitle(t || null);
	}, []);

	return <InformationModalContext.Provider value={{
		closeModal,
		openModal,
	}}>
		<Dialog open={!!content} onClose={closeModal} {...rest}>
			<Box className={classes.modal}>
				<DialogHeader title={title}><IconButton onClick={closeModal}><CloseIcon/></IconButton></DialogHeader>
				<Box className={classes.modalContent}>
					{content}
				</Box>
			</Box>
		</Dialog>
		{children}
	</InformationModalContext.Provider>;
};
