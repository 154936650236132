import { Box, IconButton, Typography } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { useFormContext } from 'react-hook-form';
import { RequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import { useDocumentRequestContext } from '@components/RequestForm/DocumentRequest/DocumentContext/DocumentRequestContext';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { SelectedDocumentAction } from '@components/RequestForm/DocumentRequest/DocumentContext/DocumentRequestContextType';
import NoParamNoReturnCallback from '@interfaces/NoParamNoReturnCallback';

const useDocRowStyles = makeStyles(theme => ({
	row: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		border: '0.5px solid rgba(0, 0, 0, 0.12)',
		borderRadius: '4px',
		padding: theme.spacing(1),
		marginTop: theme.spacing(1),
		gap: theme.spacing(1),
	},
	nameBox: {
		flex: 'auto 1 1',
		wordBreak: 'break-word',
	},
	buttonsBox: {
		'flex': 'auto 0 0',
		'gap': theme.spacing(1.5),
		'display': 'flex',
		'& .MuiButtonBase-root': {
			padding: 0,
		}
	},
	deleteIcon: {
		fill: theme.palette.error.light,
	},
	previewIcon: {
		fill: theme.palette.primary.main,
	},
}));

export interface DocumentRowProps {
	handleDeleteSelectedDoc: NoParamNoReturnCallback;
	name: string;
	handleOpenPreview: NoParamNoReturnCallback;
}

const DocumentRow: React.FC<DocumentRowProps> = ({ name, handleDeleteSelectedDoc, handleOpenPreview }) => {
	const classes = useDocRowStyles();

	return (
		<Box className={classes.row}>
			<Box className={classes.nameBox}>
				<Typography variant="body2">{name}</Typography>
			</Box>
			<Box className={classes.buttonsBox}>
				<IconButton
					data-field="preview-document-button"
					onClick={handleOpenPreview}
				>
					<VisibilityIcon
						className={classes.previewIcon}
						fontSize="medium"
					/>
				</IconButton>
				<IconButton
					data-field="delete-document-button"
					onClick={handleDeleteSelectedDoc}
				>
					<DeleteRoundedIcon
						className={classes.deleteIcon}
						fontSize="medium"
					/>
				</IconButton>
			</Box>
		</Box>
	);
};

const DocumentList: React.FC = () => {
	const { watch } = useFormContext<RequestFormValidatedState>();
	const [ documents = [] ] = watch(['documents']);
	const { setSelectedIndexAndAction } = useDocumentRequestContext();

	const setSelectedDocumentToDelete = (index: number) => {
		setSelectedIndexAndAction({
			index,
			action: SelectedDocumentAction.DELETE
		});
	};

	const setSelectedDocumentToPreview = (index: number) => {
		setSelectedIndexAndAction({
			index,
			action: SelectedDocumentAction.PREVIEW
		});
	};

	return (
		<>
			<Typography variant="body1" component="h3">Uploaded ({documents.length})</Typography>
			{
				documents.map((d, index) => (
					<DocumentRow
						key={`${d.name}-${index}`}
						name={d.name}
						handleDeleteSelectedDoc={() => setSelectedDocumentToDelete(index)}
						handleOpenPreview={() => setSelectedDocumentToPreview(index)}
					/>
				))
			}
		</>
	);
};

export default DocumentList;
