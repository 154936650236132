import { useSchedulePageContext } from '@components/SchedulePage/SchedulePageContext/SchedulePageContext';
import DisplayTimeFrame from '@components/DisplayTimeFrame/DisplayTimeFrame';
import React from 'react';
import DisplaySelectedUnitTimeFrameProps
	from '@components/SchedulePage/DisplaySelectedUnitTimeFrame/DisplaySelectedUnitTimeFrameProps';

const DisplaySelectedUnitTimeFrame: React.FC<DisplaySelectedUnitTimeFrameProps> = ({ timeFrame, ...typographyProps }) => {
	const { selectedFacility } = useSchedulePageContext();

	if (!selectedFacility) { return null; }

	return <DisplayTimeFrame timeFrame={timeFrame} timeZone={selectedFacility.hospitalTimeZone} {...typographyProps} />;
};

export default DisplaySelectedUnitTimeFrame;
