import { useFormMeta } from '@store/context/FormMetaContext';
import { FieldPath, useFormContext } from 'react-hook-form';
import { checkIsRequired } from '@utilities/formUtilities';
import React from 'react';
import { FieldValues } from 'react-hook-form';

function useFormFieldSchema<T extends FieldValues, Name extends(FieldPath<T>) = FieldPath<T>>(name: Name) {
	const { schema } = useFormMeta<T>();
	const { register, unregister, formState: { errors }, setValue, watch } = useFormContext<T>();
	const [internalValue = '', setInternalValue] = React.useState<T[Name]>();

	const inputSchema = schema[name];
	const errorDesc = errors[name];
	const error: string | undefined = errorDesc && 'message' in errorDesc && errorDesc.message && typeof errorDesc.message === 'string' ? errorDesc.message.toString() : undefined;
	const isRequired = checkIsRequired(schema[name]);

	React.useEffect(() => {
		register(name, inputSchema);
		return () => unregister(name);
	}, [name, unregister, register, inputSchema]);

	// Initialize value to watched val
	const val = watch(name) as T[Extract<keyof T, Name>];
	React.useEffect(() => {
		setInternalValue(val);
	}, [val, setInternalValue]);

	const updateValue = React.useCallback((inputVal: T[Name]) => {
		setInternalValue(inputVal);
		setValue(name, inputVal, { shouldValidate: true });
	}, [name, setValue]);

	return {
		updateValue,
		currentValue: internalValue,
		isRequired,
		error,
	};
}

export default useFormFieldSchema;
