import React from 'react';
import SurgeonMultiSelect from '@components/SurgeonMultiSelect/SurgeonMultiSelect';
import { useSchedulePageContext } from '@components/SchedulePage/SchedulePageContext/SchedulePageContext';

const ScheduleSurgeonMultiSelect = () => {
	const {
		prefSurgeons,
		selectedSurgeonsByNpis,
		setSelectedSurgeonsByNpis,
	} = useSchedulePageContext();

	return (
		<SurgeonMultiSelect
			selectedSurgeons={selectedSurgeonsByNpis}
			setSelectedSurgeons={setSelectedSurgeonsByNpis}
			surgeonList={prefSurgeons}
		/>
	);
};

export default ScheduleSurgeonMultiSelect;
