import React from 'react';
import { Avatar, AvatarProps, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		backgroundColor: theme.palette.grey[300],
		color: theme.palette.common.black,
		fontWeight: 500,
		marginRight: theme.spacing(1.75),
	},
}));

const CaseActivityAvatar: React.FC<Partial<AvatarProps>> = ({ children, className, ...props }) => {
	const classes = useStyles();

	return (
		<Avatar className={classNames(classes.root, className)} {...props}>
			{children}
		</Avatar>
	);
};

export default CaseActivityAvatar;
