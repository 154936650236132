import { apiAction } from '@store/middlewares/api/ApiActionCreators';
import { ENDPOINT_EQUIPMENT_LIST } from '@utilities/apiConstants';
import ApiService from '@utilities/apiEndpoints/ApiService';
import { GET_EQUIPMENT_LIST } from '@store/actions/types';
import { EquipmentListRequest } from '@data/equipment/types/EquipmentListRequest';
import { CacheType } from '@interfaces/CacheType';

/***
 * getEquipmentList - api action creator to get list of equipment
 *
 * @param apiActionRequest {Partial<EquipmentListRequest>} data with a FindATimeRequest
 *
 * @returns {ApiAction} api action to get list of equipment
 */
export const getEquipmentList = (apiActionRequest?: Partial<EquipmentListRequest>) => {
	return apiAction({
		url: ENDPOINT_EQUIPMENT_LIST,
		method: 'GET',
		apiService: ApiService.SSM,
		includeAccessToken: true,
		label: GET_EQUIPMENT_LIST,
		cachePolicy: CacheType.RETRIEVE_OR_ADD_CACHE,
		...apiActionRequest,
	});
};
