import React from 'react';
import { Button } from '@ascension/web';
import { SubmitButtonProps } from './SubmitButtonProps';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
	submitBtn: {
		width: '90px',
		alignSelf: 'flex-end',
		margin: '8px 0',
		padding: '16px'
	}
});

export const SubmitButton = ({ disabled }: SubmitButtonProps) => {
	const { submitBtn } = useStyles();

	return (
		<Button
			type="submit"
			disabled={disabled}
			className={submitBtn}
		>
			SUBMIT
		</Button>
	);
};
