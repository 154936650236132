import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	getUserProfileDetails,
	updateUserProfileTermsOfUseAcceptedApi,
} from '@store/actions/UserProfileActionCreators';
import { Box, Button, Flex, Text } from '@ascension/web';
import TermsOfUseContent from './TermsOfUseContent';
import { UserDetailsTermsOfUseAcceptedApiInterface } from '@interfaces/UserPrefInterface';
import { UserProfileDetailsSelector } from '@store/selectors/UserProfileSelector';
import { makeStyles } from '@material-ui/core/styles';
import { Portal } from '@material-ui/core';
import HistoryLocationState from '@interfaces/HistoryLocationState';
import { useAuthMethods } from '@ascension/ascensionid-sdk';
import { useAppPageBodyClassSetter } from '../AppPage/AppPageContext/AppPageContext';
import { UserStatus } from '@interfaces/UserProfile/UserStatusEnum';
import { ROUTE_CONSTANTS } from '@utilities/RouteConstants';

const useStyles = makeStyles({
	termsMain : {
		border: `1px solid transparent`,
		boxShadow: `0 0 8px 0 rgba(0,0,0,0.1)`,
		position: 'relative',
		padding: '40px 80px',
		backgroundColor: '#ffffff'
	},
	footer : {
		position: 'sticky',
		bottom: 0,
		background: 'linear-gradient(180deg, #FFFFFF 0%, #FEFEFE 65.1%, #F2F2F2 100%)',
		boxShadow: '0 2px 6px rgba(0,0,0,0.15)'
	},
	continueBtn: {
		'backgroundColor': '#1F69D2',
		'color': '#FFFFFF',
		'& span': {
			fontWeight: 400,
			fontSize: '16px',
		}
	},
	largeMaxWidth: {
		'& > div': {
			width: '90%',
			maxWidth: '90%',
		}
	}
});

export default function TermsOfUsePage() {
	const history = useHistory<HistoryLocationState>();
	const classes = useStyles();
	const { termsMain, footer, continueBtn } = classes;
	const dispatch = useDispatch();
	const { termsOfUseAccepted, userId, status } = useSelector(UserProfileDetailsSelector);
	const { logout } = useAuthMethods();
	const { setBodyClass } = useAppPageBodyClassSetter('TermsOfUsePage');

	React.useEffect(() => {
		setBodyClass(classes.largeMaxWidth);
	}, [classes, setBodyClass]);

	const handleAccept = () => {
		if (status === UserStatus.PENDING_ACTIVE) {
			history.replace(ROUTE_CONSTANTS.SETUP_PROFILE, { fromTermsOfUsePage: true });
			return;
		}
		const payload: UserDetailsTermsOfUseAcceptedApiInterface = {
			termsOfUseAccepted: true
		};

		const handleSuccess = () => {
			dispatch(getUserProfileDetails());
			history.replace(ROUTE_CONSTANTS.HOME);
		};

		const action = updateUserProfileTermsOfUseAcceptedApi(payload, handleSuccess);

		dispatch(action);
	};

	const handleDecline = () => {
		void logout();
		history.replace(ROUTE_CONSTANTS.LOGIN);
	};

	React.useEffect(() => {
		const { pathname = '', search = '' } = history.location.state && history.location.state.original || {};
		const redirectTo = ['/auth', '/login', ''].includes(pathname) ? ROUTE_CONSTANTS.HOME : pathname;

		if ( userId && termsOfUseAccepted ) {
			history.replace(`${redirectTo}${search}`);
		}
	}, [userId, termsOfUseAccepted, history]);

	return (
		<Flex flexDirection="column" margin="50px" className = {termsMain}>
			<Flex>
				<TermsOfUseContent/>
			</Flex>
			<Portal>
				<div className={footer}>
					<Box maxWidth = "90%" width = {1} mx = "auto">
						<Flex flex="1" alignItems = "center" justifyContent = "center">
							<Text style={{ fontWeight: 'bold' }}>
							By tapping “Continue”, you are agreeing to the terms of the End User Agreement and Privacy Policy
							</Text>
							<Box flex="1" textAlign="right">
								<Button onClick={handleDecline} data-field="decline-btn" secondary style = {{ margin : '15px', letterSpacing: '1.25px' }}>CANCEL</Button>
								<Button onClick={handleAccept} data-field="accept-btn" className={continueBtn} style = {{ margin : '15px' }}>Continue</Button>
							</Box>
						</Flex>
					</Box>
				</div>
			</Portal>
		</Flex>
	);
}



