import React from 'react';
import { AnchorProvider } from '@components/AnchorProvider/AnchorProvider';
import { LinkComponent } from '@components/LinkMenu/LinkComponent/LinkComponent';
import { MenuComponent } from '@components/LinkMenu/MenuComponent/MenuComponent';
import { LinkMenuProps } from '@components/LinkMenu/LinkMenuProps';

export const LinkMenu: React.FC<LinkMenuProps> = ({ label, options, linkClassName, menuClassName }) => {
	return (
		<AnchorProvider>
			<LinkComponent label={label} className={linkClassName} />
			<MenuComponent options={options} className={menuClassName} />
		</AnchorProvider>
	);
};
