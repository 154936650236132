import OfficeCalendarView from '@components/OfficeCalendarView/OfficeCalendarView';
import React from 'react';
import { useUnitCalendarPageContext } from '@components/UnitCalendarPage/UnitCalendarPageContext/UnitCalendarPageContext';

const PreviewOfficeCalendar = () => {
	const { selectedUnit, selectedDate } = useUnitCalendarPageContext();
	const { id } = selectedUnit;

	return (
		<OfficeCalendarView
			buttonText="Preview open time calendar"
			unitId={id}
			parentSelectedDate={selectedDate}
			style={{ height: '48px' }}
			showButton={true}
		/>
	);
};

export default PreviewOfficeCalendar;
