import React, { useContext } from 'react';
import { AnchorProviderContextType } from '@components/AnchorProvider/AnchorProviderContextType';

const AnchorElContext = React.createContext<AnchorProviderContextType | null>(null);

export const useAnchorContext = () => {
	const ctx = useContext(AnchorElContext);
	if (!ctx) {
		throw new Error('useAnchorContext must be used within a child component of AnchorProvider');
	}
	return ctx;
};

export const AnchorProvider: React.FC = ({ children }) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleSetAnchorEl = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	}, []);

	const handleClearAnchorEl = React.useCallback(() => {
		setAnchorEl(null);
	}, []);

	return (
		<AnchorElContext.Provider value={{
			anchorEl,
			handleSetAnchorEl,
			handleClearAnchorEl,
		}}>
			{children}
		</AnchorElContext.Provider>
	);
};
