import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import { Switch } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { MyFiltersSelector } from '../../store/selectors/MyFiltersSelector';
import { useFilterState, useResetAllFilters } from '../Filters/FiltersContext/FiltersContext';
import { useDebounceCallBack } from '../../utilities/hooks/useDebounce/useDebounceCallBack';
import { startSpinner } from '../../store/actions/UtilityActionCreators';
import { savedFiltersToApiParams } from '../../utilities/filtersUtilities';
import { UserProfileFilterUrlSelector, UserProfileHasARoleSelector } from '../../store/selectors/UserProfileSelector';
import { OfficeRoles } from '../../interfaces/UserProfile/UserRoleEnum';
import { MyFiltersState } from '../../interfaces/MyFilters/MyFiltersState';
import SwitchToolTip from './SwitchToolTip/SwitchToolTip';
import useSurgeonsFilterUpdate from '@utilities/hooks/useSurgeonsFilterUpdate/useSurgeonsFilterUpdate';

const GreyDefaultSwitch = withStyles({
	root: {
		cursor: 'pointer',
	},
	switchBase: {
		color: grey[900],
	},
})(Switch);

export default function SavedFilterSwitch() {
	const isOfficeRole = useSelector(UserProfileHasARoleSelector(OfficeRoles));
	const dispatch = useDispatch();
	const savedFilters = useSelector(MyFiltersSelector);
	const { filterApplied, toggleFilter } = useFilterState();
	const resetFilters = useResetAllFilters();
	const resetFiltersDebounced = useDebounceCallBack(resetFilters, 50);
	const savedFilterUrl = useSelector(UserProfileFilterUrlSelector);
	const { updateSurgeonsWithIds } = useSurgeonsFilterUpdate();

	const handleSwitchClick = useCallback(() => {
		dispatch(startSpinner());
		toggleFilter();
		if ( !filterApplied ) {
			const savedFiltersByRole: MyFiltersState = isOfficeRole ?
				{ ...savedFilters, surgeon: [] } :
				{ ...savedFilters, unit: [] };
			updateSurgeonsWithIds([]);
			resetFiltersDebounced(savedFiltersToApiParams(savedFiltersByRole));
		} else {
			resetFiltersDebounced(savedFiltersToApiParams({}));
		}
	}, [filterApplied, toggleFilter, resetFiltersDebounced, savedFilters, isOfficeRole, dispatch, updateSurgeonsWithIds]);

	return (
		<>
			<SwitchToolTip
				title="You haven’t saved your filter preferences yet."
				showArrow={true}
				placement="top"
				disableSwitch={!savedFilterUrl}
			>
				<GreyDefaultSwitch
					disabled={!savedFilterUrl}
					color="primary"
					checked={!!savedFilterUrl && !!filterApplied}
					onMouseDown={handleSwitchClick}
					name="myFilterToggle"
				/>
			</SwitchToolTip>
		</>
	);
}
