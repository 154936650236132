import { RegisterOptions } from 'react-hook-form';
import { ProcedureInfo } from './SurgeryInformationInterface';
import {
	INVALID_PRIMARY_DIAGNOSIS_CODE, PROCEDURE_MULTIPLE_PRIMARY_ERROR,
	PROCEDURE_NAME_MISSING, PROCEDURE_NO_PRIMARY_ERROR,
} from '../../../utilities/Validation/ValidationMessages';

const EXACTLY_ONE_PRIMARY_RESULT: Record<number | 'default', string | true> = {
	0: PROCEDURE_NO_PRIMARY_ERROR,
	1: true,
	default: PROCEDURE_MULTIPLE_PRIMARY_ERROR,
};

export const SurgeryInfoValidationSchema: {[propName: string]: RegisterOptions} = {
	primaryDiagnosis: {
		required: INVALID_PRIMARY_DIAGNOSIS_CODE,
	},
	surgicalProcedures: {
		validate: {
			requireOne: (procedures: ProcedureInfo[] | undefined) =>
				procedures && typeof procedures[0] === 'object' || PROCEDURE_NAME_MISSING,
			exactlyOnePrimary: (procedures: ProcedureInfo[] | undefined) => {
				const primaryProcedureCount: number = procedures ? procedures.filter((p) => p.isPrimaryProcedure === 1).length : 0;
				return EXACTLY_ONE_PRIMARY_RESULT[primaryProcedureCount] || EXACTLY_ONE_PRIMARY_RESULT.default;
			},
		},
	},
};
