import React, { FormEvent } from 'react';
import { Box, Checkbox, Grid, Modal, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from '@ascension/ui-library';
import { useCopyCaseState } from '../../../../CopyCaseContext/CopyCaseContext';
import { useHistory } from 'react-router-dom';
import { FormSection } from '../../../../../RequestForm/interface/FormSection';
import { ROUTE_CONSTANTS } from '@utilities/RouteConstants';

const useStyles = makeStyles((theme: Theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: theme.spacing(25)
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: 0,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(3),
		outline: 'none',
		maxWidth: '50vw',
		minHeight: '29vh',
		textAlign: 'center'
	},
	closeIcon: {
		cursor: 'pointer'
	},
	mainContainer: {
		padding: `0px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
		width: theme.spacing(50),
		textAlign: 'left'
	},
	topContentSection: {
		fontSize: 20,
		justifyContent: 'center',
	},
	disclaimerContainer: {
		fontSize: 13,
		marginTop: theme.spacing(1)
	},
	checkBoxStyle: {
		paddingLeft: 0
	},
	buttonsWrapperStyle: {
		display: 'flex',
		justifyContent: 'flex-end'
	},
	copyButtonStyle: {
		'backgroundColor': '#FFD266',
		'marginLeft': theme.spacing(2),
		'&:hover': {
			backgroundColor: '#FFB400'
		}
	},
	disabledCopyBtn: {
		marginLeft: theme.spacing(2),
		backgroundColor: '#666666',
	},
}));

export default function CopyCaseModal() {
	const history = useHistory();
	const { isOpen, closeModal, sectionOptions, toggleSection, isAllChecked, toggleAllOption } = useCopyCaseState();
	const closeIconClickHandler = () => {
		closeModal();
	};

	const copyContentSelections = React.useMemo(() => {
		const options = Object.entries(sectionOptions).map(([key, value]) => ({ label: key, selected: value }));
		const selections: string[] = options.filter(opt => opt.selected).map(sel => sel.label);
		if (selections.length === options.length) {
			selections.unshift('all');
		}

		return selections;
	}, [sectionOptions]);

	const copyClickHandler = (ev: FormEvent<HTMLFormElement>) => {
		ev.preventDefault();
		closeModal();
		history.push(ROUTE_CONSTANTS.REQUEST_FORM, {
			copyFrom: {
				sections: copyContentSelections,
			}
		});
	};
	const disableCopyBtn = React.useMemo(() =>
		// If any of the options are true, then do not disable. All can be implied false when any are true
		Object.values(sectionOptions)
			.every(option => !option), [sectionOptions]);

	const classes = useStyles();
	const copyBtnClass = disableCopyBtn ? classes.disabledCopyBtn : classes.copyButtonStyle;
	// color property needs to be implemented inline in order to override MUI disabled default
	const customDisabledStyle = disableCopyBtn ? { color: '#CCC' } : {};

	const getToggleHandler = React.useCallback((forSection: FormSection) => {
		return () => toggleSection(forSection);
	}, [toggleSection]);

	return (
		<Modal open={isOpen} className={classes.modal}>
			<Box className={classes.paper}>
				<Grid container direction="row" justify="flex-end">
					<Grid>
						<Grid data-field="button_copy_close">
							<CloseIcon
								className={classes.closeIcon}
								onClick={closeIconClickHandler}/>
						</Grid>
					</Grid>
				</Grid>
				<form onSubmit={copyClickHandler}>
					<Box className={classes.mainContainer}>
						<Box className={classes.topContentSection}>
							What information do you want to copy?
						</Box>
						<Box className={classes.disclaimerContainer}>
							<Checkbox
								id="checkbox_all"
								data-field="checkbox_all"
								checked={isAllChecked}
								onChange={toggleAllOption}
								color="primary"
								className={classes.checkBoxStyle}
							/>
							All
						</Box>
						<Box className={classes.disclaimerContainer}>
							<Checkbox
								id="checkbox_patient"
								data-field="checkbox_patient"
								checked={sectionOptions.patient}
								onChange={getToggleHandler(FormSection.patient)}
								color="primary"
								className={classes.checkBoxStyle}
							/>
							Patient Demographics
						</Box>
						<Box className={classes.disclaimerContainer}>
							<Checkbox
								id="checkbox_insurance"
								data-field="checkbox_insurance"
								checked={sectionOptions.insurance}
								onChange={getToggleHandler(FormSection.insurance)}
								color="primary"
								className={classes.checkBoxStyle}
							/>
							Insurance Information
						</Box>
					</Box>
					<Box className={classes.buttonsWrapperStyle}>
						<Button
							translate="yes"
							color="primary"
							variant="outlined"
							onClick={closeIconClickHandler}
							data-field="button_copy_cancel"
						>
							Cancel
						</Button>
						<Button
							className={copyBtnClass}
							translate="yes"
							disabled={disableCopyBtn}
							style={customDisabledStyle}
							data-field="button_copy_confirm"
							type="submit"
						>
							Copy
						</Button>
					</Box>
				</form>
			</Box>
		</Modal>
	);
}
