import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, BoxProps, createStyles } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles(() =>
	createStyles({
		header: {
			position: 'sticky',
			top: 0
		},
	}),
);

export const  CustomPopoverHeader: React.FC<BoxProps> = ({ children, className }) => {
	const classes = useStyles();

	return (
		<Box className={classNames(classes.header, className)}>
			{children}
		</Box>
	);
};
