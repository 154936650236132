import React from 'react';
import { PermittedAction } from '../../PermittedAction';
import { useSelector } from 'react-redux';
import { UserProfileRoleSelector } from '../../../store/selectors/UserProfileSelector';
import PermittedActionToUserRole from '../../PermittedActionToUserRole';
import { arraysOverlap } from '../../commonFunction';

/***
 * @example
 * const canEditFin = useHasPermission(PermittedAction.ENTER_FIN);
 *
 * {canEditFin && <Input label="FIN" />}
 *
 * @param {PermittedAction} action
 */
const useHasPermission = (action: PermittedAction) => {
	const userRoles = useSelector(UserProfileRoleSelector);

	const actionRoles = PermittedActionToUserRole[action];

	return React.useMemo(() => arraysOverlap(userRoles, actionRoles), [actionRoles, userRoles]);
};

export default useHasPermission;
