import React from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { UnitInfo } from '@data/openCalendar/UnitInfo';
import EventParser from '@utilities/hooks/useUnitEvents/EventParser';
import { format } from 'date-fns-tz';
import UnitMinimal from '@interfaces/Unit/UnitMinimal';
import { DATE_ONLY_FORMATTER } from '@utilities/constants';
import UnitEventHookResult from '@data/openCalendar/UnitEventHookResult';
import useGetEvents from '@data/openCalendar/useGetEvents';
import UseUnitEventsOptionalConfig from '@utilities/hooks/useUnitEvents/UseUnitEventsOptionalConfig';
import useHoldEventAdditionalParams from '@utilities/hooks/useHoldEventAdditionalParams/useHoldEventAdditionalParams';
import { ErrorStatusState } from '@interfaces/ErrorStatus/ErrorStatusState';

const DEFAULT_PARSED_VALS: Omit<UnitEventHookResult, 'getUnitEventsFromApi' | 'error'> & { error: undefined } = {
	error: undefined,
	events: [],
	columns: [],
	rooms: {},
	unitConfig: {
		ministry: '',
		hospital: '',
		unit: '',
		start: '07:00',
		end: '16:00',
		interval: 15,
		hospitalTimeZone: '',
	},
};

function isResponseError(response: unknown): response is AxiosError<ErrorStatusState> {
	return !!(response && typeof response === 'object' && 'isAxiosError' in response && (response as AxiosError).isAxiosError);
}

/***
 * useUnitEvents - retrieve unit events from opentime API
 * Calls API automatically on load, when provided values change, and when triggered manually via returned method
 *
 * @param selectedDate {Date} - set at hook level so that API can be called automatically each time value changes
 * @param selectedUnit {UnitMinimal} - set at hook level so that API can be called automatically each time value changes
 * @param eventParser {EventParser} - Utility to parse events from rooms to EventInfo for calendar
 * @param options {UseUnitEventsOptionalConfig<ErrorType>} - optional. Can pass the following config parameters:
 *  - @param overrideErrorMessage {ErrorType} - Error to return when API simply fails
 *  - @param shouldAutoLoad {boolean} - allow/prevent initial automatic loading of events for calendar
 *  @param isEdit {boolean} - true when editing an existing request, false when creating new request or editing hold event within new request
 *  @param onSuccessResponse {(info: UnitInfo) => void} optional - use to get results when API call ends
 *
 * @returns {UnitEventHookResult} - Returns information necessary to configure the calendar and a utility to trigger API call manually
 */
function useUnitEvents<ErrorType = string>(
	selectedDate: Date,
	selectedUnit: UnitMinimal,
	eventParser: EventParser<ErrorType>,
	options: UseUnitEventsOptionalConfig<ErrorType>,
	isEdit?: boolean,
	onSuccessResponse?: ((info: UnitInfo) => void)
): UnitEventHookResult<ErrorType> {
	const { shouldAutoLoad = true, errorParser } = options;
	const [dataResponse, setDataResponse] = React.useState<UnitInfo | AxiosError<ErrorStatusState> | null>(null);
	const getEvents = useGetEvents();
	const additionalParams = useHoldEventAdditionalParams({ isEdit });

	const getUnitEventsFromApi = React.useCallback(() => {
		const { id: unit, hospital } = selectedUnit;

		if (!unit || !hospital || !selectedDate) { return; }

		const startDate = format(selectedDate, DATE_ONLY_FORMATTER);

		const onSuccess = ({ data }: AxiosResponse<UnitInfo>) => {
			setDataResponse(data);
			if (onSuccessResponse) { onSuccessResponse(data); }
		};
		const onFailure = (err: AxiosError<ErrorStatusState>) => {
			setDataResponse(err);
		};

		getEvents({
			data: { hospital, unit, startDate },
			onFailure,
			onSuccess,
			additionalParams
		});
	}, [selectedUnit, selectedDate, getEvents, additionalParams, onSuccessResponse]);

	React.useEffect(() => {
		if (!shouldAutoLoad) { return; }
		getUnitEventsFromApi();
	}, [getUnitEventsFromApi, shouldAutoLoad]);

	const { unitConfig, columns, events, error, rooms } = React.useMemo(() => {
		// Likely no API call yet since no success or error response
		if (!dataResponse) {
			return DEFAULT_PARSED_VALS;
		}

		// Error response
		if (isResponseError(dataResponse)) {
			return {
				...DEFAULT_PARSED_VALS,
				error: errorParser(dataResponse),
			};
		}

		// Success
		const {
			rooms,
			...unitConfig
		} = dataResponse;

		const sortedRooms = rooms.sort((a, b) => (a.name < b.name) ? -1 : 1);

		const parseResults = eventParser(sortedRooms, unitConfig.hospitalTimeZone, unitConfig.interval || 15);

		return {
			unitConfig,
			columns: sortedRooms,
			events: Array.isArray(parseResults) ? parseResults : parseResults.events,
			error: 'error' in parseResults ? parseResults.error : undefined,
			rooms: sortedRooms.reduce((allRooms: Record<string, string>, room) => {
				allRooms[room.name] = room.id;
				return allRooms;
			}, {}),
		};
	}, [dataResponse, errorParser, eventParser]);

	return {
		error,
		events,
		columns,
		rooms,
		unitConfig,
		getUnitEventsFromApi,
	};
}

export default useUnitEvents;
