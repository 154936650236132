import { Box, Flex, Text } from '@ascension/web';
import React from 'react';
import { useSelector } from 'react-redux';
import { UserProfileHasARoleSelector } from '../../../store/selectors/UserProfileSelector';
import { HospitalRoles } from '../../../interfaces/UserProfile/UserRoleEnum';
import Units from './Units/Units';
import Specialties from './Specialties/Specialties';
import OtherFilters from './OtherFilters/OtherFilters';
import SortBy from './SortBy/SortBy';
import ProcedureDate from './ProcedureDate/ProcedureDate';
import Surgeons from './Surgeons/Surgeons';

export default function MyFiltersPage() {
	const isHospitalRoles = useSelector(UserProfileHasARoleSelector(HospitalRoles));

	return (
		<Flex flexDirection="column">
			<Flex
				justifyContent="space-between"
				letterSpacing={1}
				width={1}
				alignItems="start"
				flexDirection="column"
				borderBottom="1px solid rgba(0, 0, 0, 0.12)"
			>
				<Box my={20}>
					<Text fontSize={3} color="rgba(0, 0, 0, 0.6)">
						Select the filters you use the most, which will allow you to easily toggle them on and off from the home page.
					</Text>
				</Box>
			</Flex>
			<Flex justifyContent="flex-end" flexDirection="column" py={8}>
				<SortBy/>
				<ProcedureDate/>
				{ isHospitalRoles && <Units/> }
				{ isHospitalRoles && <Specialties/> }
				<Surgeons/>
				<OtherFilters/>
			</Flex>
		</Flex>
	);
}
