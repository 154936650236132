import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GoldButton from '@components/GoldButton/GoldButton';
import { Box } from '@material-ui/core';
import BlueOutlineButton from '@components/BlueOutlineButton/BlueOutlineButton';
import { useIndexingSectionContext } from '@components/PdfViewerPage/IndexingSection/IndexingSectionContext/IndexingSectionContext';

const useStyles = makeStyles(theme => ({
	flexContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		backgroundColor: theme.palette.common.white,
		padding: theme.spacing(2),
		boxSizing: 'border-box'
	},
	saveButton: {
		'&.MuiButton-contained': {
			flex: '1 1 auto',
			paddingTop: theme.spacing(1.75),
			paddingBottom: theme.spacing(1.75),
			marginRight: theme.spacing(2.75),
		},
	},
}));

export const ActionButtonsContainer = () => {
	const { flexContainer, saveButton } = useStyles();
	const { saveIndexes, shouldDisableSave, cancelEdit } = useIndexingSectionContext();

	return (
		<Box className={flexContainer}>
			<GoldButton
				data-field="pdfViewerPage-editIndex-saveButton"
				className={saveButton}
				onClick={saveIndexes}
				disabled={shouldDisableSave}
			>
				Save
			</GoldButton>
			<BlueOutlineButton
				data-field="pdfViewerPage-editIndex-cancelButton"
				onClick={cancelEdit}
			>
				Cancel
			</BlueOutlineButton>
		</Box>
	);
};
