import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PdfViewer from '@components/PdfViewer/PdfViewer';
import { useDocumentRequestContext } from '@components/RequestForm/DocumentRequest/DocumentContext/DocumentRequestContext';
import { useCommonStyles } from '@utilities/hooks/useCommonStyles/useCommonStyles';
import { SelectedDocumentAction } from '@components/RequestForm/DocumentRequest/DocumentContext/DocumentRequestContextType';

const useStyles = makeStyles(theme => createStyles({
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[900],
	},
	container: {
		'& .MuiDialog-container': {
			maxHeight: '90vh',
			marginTop: theme.spacing(5),
		},
		'& .MuiDialogTitle-root': {
			paddingLeft: 0,
		},
	},
	paper: {
		padding: theme.spacing(2),
		background: theme.palette.common.white,
	},
	content: {
		padding: 0,
	}
}));

const PreviewDocModal: React.FC = () => {
	const classes = useStyles();
	const { hideTextOverflow } = useCommonStyles();
	const { selectedDocument, handleCloseActionModal, selectedIndexAndAction } = useDocumentRequestContext();

	if (!selectedDocument || selectedIndexAndAction.action !== SelectedDocumentAction.PREVIEW) { return null; }

	return (
		<Dialog
			open={!!selectedDocument}
			onClose={handleCloseActionModal}
			PaperProps={{
				classes: {
					root: classes.paper
				},
			}}
			className={classes.container}
		>
			<DialogTitle disableTypography>
				<Typography component="h2" variant="h5" className={hideTextOverflow}>
					{selectedDocument.name}
				</Typography>
				<IconButton
					aria-label="close"
					className={classes.closeButton}
					onClick={handleCloseActionModal}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent className={classes.content}>
				<PdfViewer pdfDoc={selectedDocument} backgroundColor="#D9D9D9"/>
			</DialogContent>
		</Dialog>
	);
};

export default PreviewDocModal;
