import React from 'react';
import { makeStyles } from '@material-ui/core';
import GoldButtonProps from './GoldButtonProps';
import { Button } from '@ascension/ui-library';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
	button: {
		'backgroundColor': theme.palette.gold.light,
		'&:hover': {
			backgroundColor: theme.palette.gold.dark,
		},
		'&.MuiButton-contained': {
			boxShadow: 'unset',
			padding: theme.spacing(2),
			fontSize: '14px',
			lineHeight: 1,
			letterSpacing: '1px',
		},
	},
}));

const GoldButton: React.FC<GoldButtonProps> = ({ children, className, ...rest }) => {
	const classes = useStyles();

	return (
		<Button
			translate="yes"
			className={classNames(classes.button, className)}
			variant="contained"
			{...rest}
		>
			{children}
		</Button>
	);
};

export default GoldButton;
