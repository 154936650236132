import React from 'react';
import { Box, BoxProps, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReplacedNode } from '@components/CaseActivityDrawer/types/CaseActivityRowType';

interface CaseActivityChangesProps extends BoxProps {
	change: ReplacedNode;
}

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(0.5, 1),
		width: 'full',
		fontSize: 12,
		fontFamily: 'HCo Whitney SSm',
		backgroundColor: theme.palette.primary[50],
		borderRadius: 4,
	},
	horizontalRule: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	changesDiv: {
		display: 'flex',
		gap: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	changedItemsDiv: {
		display: 'flex',
		flexDirection: 'column',
		gap: 8,
		flex: 3
	},
	oldItemText: {
		textDecoration: 'line-through',
		textDecorationColor: theme.palette.grey[600],
		color: theme.palette.grey[600],
	},
	changeTitle: {
		flex: 1
	}
}));

export function CaseActivityChanges({ change, ...props }: CaseActivityChangesProps) {
	const classes = useStyles();
	const [changeTitle, procedureName] = change.fieldFriendlyName.split('::');

	return (
		<Box {...props} className={classes.root}>
			<Box>{procedureName ? `Procedure ${procedureName} was edited.` : 'This request was edited.'}</Box>
			<>
				<Divider className={classes.horizontalRule} />
				<Box>
					<Box className={classes.changesDiv}>
						<Box className={classes.changeTitle}>
							<strong>{changeTitle}:</strong>
						</Box>
						<Box className={classes.changedItemsDiv}>
							<Box>{change.currValue || 'No equipment'}</Box>
							<Box className={classes.oldItemText}>{change.prevValue || 'No equipment'}</Box>
						</Box>
					</Box>
				</Box>
			</>
		</Box>
	);
}
