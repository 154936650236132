import React from 'react';
import useDeleteHoldEvent from '@data/holdEvent/hooks/useDeleteHoldEvent/useDeleteHoldEvent';
import { useMultiFormValueSetter } from '@components/RequestForm/utilities/MultiFormAdapter/MultiFormAdapter';

const useClearHoldEventSelection = () => {
	const deleteHoldEvent = useDeleteHoldEvent();

	const setHoldEventRoomValue = useMultiFormValueSetter('room');
	const setHoldEventRoomIdValue = useMultiFormValueSetter('roomId');
	const setHoldEventIdValue = useMultiFormValueSetter('holdEventId');
	const setDurationCurrentValue = useMultiFormValueSetter('duration');
	const setProcedureDateValue = useMultiFormValueSetter('procedureDate');
	const setProcedureTimeValue = useMultiFormValueSetter('procedureTime');
	return React.useCallback((currentHoldEventId?: string) => {
		// hold event only exists when holdEventId is present (create surgery request workflow)
		// API call to delete hold event should be disabled when holdEventId is NOT present (edit surgery request workflow)
		currentHoldEventId && deleteHoldEvent(currentHoldEventId);
		setHoldEventRoomValue('');
		setHoldEventRoomIdValue('');
		setDurationCurrentValue('');
		setProcedureTimeValue(['', '']);
		setProcedureDateValue(undefined);
		setHoldEventIdValue('');
	}, [deleteHoldEvent, setDurationCurrentValue, setProcedureTimeValue, setProcedureDateValue, setHoldEventRoomValue, setHoldEventRoomIdValue, setHoldEventIdValue]);
};

export default useClearHoldEventSelection;
