import {
	useCaseDetailsInfoContext,
} from '../../../../CaseDetailsContext/CaseDetailsInfoContext/CaseDetailsInfoContext';
import { UserAccessEnum } from '@interfaces/SurgeryRequest/UserAccessEnum';
import React from 'react';
import { deleteCaseInUseStatus } from '@store/actions/CaseDetailsActionCreators';
import { useDispatch, useSelector } from 'react-redux';
import { UserProfileDetailsSelector } from '@store/selectors/UserProfileSelector';
import useGetAccessor from '@components/CaseDetails/CaseInUse/hooks/useGetAccessor/useGetAccessor';

/***
 * @internal
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * !!! This should be used internally only by useSetAccessor !!!
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 *
 * useRemoveCaseAccessorEffect - dispatches API action to delete current accessor if one currently exists with accessType: CONFIRMER or EDIT_INDEX
 * @returns {void}
 */
const useRemoveCaseAccessorEffect = (accessType: UserAccessEnum) => {
	const { caseId } = useCaseDetailsInfoContext();
	const { userId: userProfileId } = useSelector(UserProfileDetailsSelector);
	const dispatch = useDispatch();

	const {
		isAccessorDifferentUser,
		currentAccessType,
	} = useGetAccessor([accessType]);
	const matchesAccessType = currentAccessType === accessType;
	const accessorUserInfoRef = React.useRef({ userProfileId, isAccessorDifferentUser, matchesAccessType });
	accessorUserInfoRef.current = { userProfileId, isAccessorDifferentUser, matchesAccessType };

	const removeCurrentAccessor = React.useCallback(() => {
		const {
			userProfileId,
			isAccessorDifferentUser,
			matchesAccessType,
		} = accessorUserInfoRef.current;
		if (userProfileId && !isAccessorDifferentUser && matchesAccessType) {
			dispatch(deleteCaseInUseStatus(caseId, userProfileId));
		}
	}, [caseId, dispatch]);

	React.useEffect(() => {
		// handle delete accessor if browser/tab is closed, idle logout, or manual logout
		window.addEventListener('beforeunload', removeCurrentAccessor);
		return () => {
			window.removeEventListener('beforeunload', removeCurrentAccessor);
			// handle delete accessor if navigates to different page
			removeCurrentAccessor();
		};
	}, [removeCurrentAccessor]);

	return removeCurrentAccessor;
};

export default useRemoveCaseAccessorEffect;
