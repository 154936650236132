import React from 'react';
import { useSelector } from 'react-redux';
import { UserProfileDetailsSelector } from '../../store/selectors/UserProfileSelector';

function isPrimitive(v: unknown): v is string | boolean | number {
	return !!(v && ['string', 'boolean', 'number'].includes(typeof v));
}

const usePendoInitialization = () => {
	const userInfo = useSelector(UserProfileDetailsSelector);
	const userInfoRef = React.useRef(userInfo);

	React.useEffect(() => {
		userInfoRef.current = userInfo;
	}, [userInfo]);

	const [shouldInitialize, setShouldInitialize] = React.useState(false);

	React.useEffect(() => {
		if (!userInfoRef.current || !Object.keys(userInfoRef.current).length) { return; }
		setShouldInitialize(true);
	}, [userInfo]);

	React.useEffect(() => {
		if (!shouldInitialize) { return; }

		const {
			userId,
			email,
			firstName,
			lastName,
			userRoles = [],
			primaryHospital,
			primaryPractice,
		} = userInfoRef.current;

		const visitorDescriptor: pendo.IdentityMetadata = {
			id: userId,
			email: email,
			fullName: `${lastName}, ${firstName}`,
			role: userRoles.sort().join(','),
		};

		if (primaryPractice) {
			visitorDescriptor.practiceId = primaryPractice.id;
			visitorDescriptor.practiceName = primaryPractice.name;
		}

		if (primaryHospital) {
			visitorDescriptor.hospitalId = primaryHospital.id;
			visitorDescriptor.hospitalName = primaryHospital.name;
			if ('ministry' in primaryHospital && isPrimitive(primaryHospital['ministry'])) {
				visitorDescriptor.ministry = primaryHospital['ministry'];
			}
		}

		pendo.initialize({
			visitor: visitorDescriptor,
		});
	}, [shouldInitialize]);
};

export default usePendoInitialization;
