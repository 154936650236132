import React from 'react';
import UnableToLoadCalendarError from '@components/CalendarError/content/UnableToLoadCalendarError';
import {
	useOfficeCalendarViewContext
} from '@components/OfficeCalendarView/OfficeCalendarViewContext/OfficeCalendarViewContext';

const UnableToLoadOfficeCalendarError = () => {
	const {
		getUnitEventsFromApi,
	} = useOfficeCalendarViewContext();

	return <UnableToLoadCalendarError onReloadClick={getUnitEventsFromApi} />;
};

export default UnableToLoadOfficeCalendarError;
