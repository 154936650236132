import React from 'react';
import { format } from 'date-fns-tz';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AvailableDateOption } from '@data/findATime/types/AvailableDateOption';
import {
	ResultCard
} from '@components/RequestForm/FindATime/FindATimeAccordion/FindATimeResults/Results/ResultCard/ResultCard';
import { AvailableTimeOption } from '@data/findATime/types/AvailableTimeOption';

const useStyles = makeStyles((theme) => ({
	resultRow: {
		'display': 'flex',
		'flexDirection': 'column',
		'gap': theme.spacing(3),
		'&:not(:last-child)': {
			marginBottom: theme.spacing(3),
		},
	},
	rowTitle: {
		fontWeight: 600,
	},
	cardRowContainer: {
		containerType: 'inline-size',
	},
	cardRow: {
		// Maximum of four columns with a 24px gap.
		// Min width is a rough guess that seems to work well.
		'--grid-layout-gap': `${theme.spacing(3)}px`,
		'--grid-column-count': 4,
		'--grid-item--min-width': '155px',

		'--gap-count': 'calc(var(--grid-column-count) - 1)',
		'--total-gap-width': 'calc(var(--gap-count) * var(--grid-layout-gap))',
		'--grid-item--max-width':
			'calc((100% - var(--total-gap-width)) / var(--grid-column-count))',

		'display': 'grid',
		'gap': 'var(--grid-layout-gap)',

		'gridTemplateColumns':
			'repeat(auto-fill, minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr))',
	},
	resultCard: {
		'cursor': 'pointer',
		'border': `1px solid ${theme.palette.grey[600]}`,
		'borderRadius': theme.spacing(0.5),
		'padding': theme.spacing(1),
		'boxShadow': theme.shadows[6],
		'& p': {
			fontSize: '0.875rem',
		},
		'&[data-block="true"]:hover': {
			backgroundColor: theme.palette.primary[100],
		},
		'&[data-block="false"]:hover': {
			backgroundColor: theme.palette.secondary[100],
		},
	},
	blockTitle: {
		fontWeight: 600,
		color: theme.palette.primary.dark,
	},
	nonBlockTitle: {
		fontWeight: 600,
		color: theme.palette.secondary.dark,
	},
}));

interface ResultRowProps {
	option: AvailableDateOption;
	handleSelectTime: (time: AvailableTimeOption, timeSlotId: string, date: Date) => void;
	handleSetFindATimeUsed?: (newValue: boolean) => void;
}

export const ResultRow: React.FC<ResultRowProps> = ({ option, handleSelectTime, handleSetFindATimeUsed }) => {
	const classes = useStyles();
	const formattedDate = format(new Date(`${option.date}T00:00`), 'EEEE, MMMM dd, yyyy');

	return (
		<Box className={classes.resultRow} data-field="findATime-resultRow">
			<Typography className={classes.rowTitle}>{formattedDate}</Typography>
			<Box className={classes.cardRowContainer}>
				<Box className={classes.cardRow}>
					{option.times
						.map((time, index) =>
							<ResultCard
								key={`${time.start}-${time.end}-${index}`}
								date={new Date(`${option.date}T00:00`)}
								handleSelectTime={handleSelectTime}
								handleSetFindATimeUsed={handleSetFindATimeUsed}
								time={time} />
						)}
				</Box>
			</Box>
		</Box>
	);
};
