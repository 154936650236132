import React from 'react';
import { Button } from '@ascension/ui-library';
import CloseIcon from '@material-ui/icons/Close';
import { Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { ConflictList } from '../ConflictList/ConflictList';
import {
	useCanForceConfirm,
	useConfirmationCaseNumber,
	useForceConfirm,
} from '../../CaseDetailsContext/ConfirmCaseContext/ConfirmCaseUtilities';
import { useDrawerContext } from '@components/DrawerWrapper/DrawerContextProvider';
import { CONFLICTS_DISPLAY_WRAPPER } from '@components/DrawerWrapper/drawerConstants';

const useStyles = makeStyles((theme) => ({
	innerDrawerContainer: {
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'nowrap',
		height: '100%',
		padding: theme.spacing(2),
	},
	iconContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		width: '100%',
	},
	closeIcon: {
		'fill': theme.palette.grey[600],
		'&:hover': {
			cursor: 'pointer',
		}
	},
	caseNumberStyle: {
		color: theme.palette.grey[900],
		fontSize: '1rem',
		lineHeight: '1.5rem',
		letterSpacing: '.03rem',
		margin: theme.spacing(0, 0, 1, 0),
	},
	helperText: {
		color: theme.palette.grey[600],
	},
	subheaderText: {
		fontSize: '1rem',
		lineHeight: '1.5rem',
		letterSpacing: '.03rem',
	},
	footer: {
		marginTop: 'auto',
		position: 'fixed',
		bottom: 0,
		backgroundColor: 'white',
		paddingBottom: theme.spacing(3),
	},
	divider: {
		margin: theme.spacing(2, 0),
	},
	footerText: {
		fontSize: '.75rem',
		lineHeight: '1rem',
		letterSpacing: '.02rem',
		margin: theme.spacing(1, 0),
	},
	button: {
		margin: theme.spacing(2, 2, 0, 0),
	},
	primaryBtn: {
		'backgroundColor': theme.palette.gold.light,
		'&:hover': {
			backgroundColor: theme.palette.gold.dark,
		},
	},
	mainContainer: {
		overflow: 'auto',
		marginBottom: theme.spacing(24),
		[theme.breakpoints.up(theme.drawerPane.breakpoint)]: {
			marginBottom: theme.spacing(15),
		},
	},
}));

export const ForceConfirmWithConflictsButton = () => {
	const classes = useStyles();

	const caseNumber = useConfirmationCaseNumber() || '';
	const canForceConfirm = useCanForceConfirm();
	const forceConfirm = useForceConfirm();

	const handleLinkAndOverwrite = React.useCallback(() => {
		void forceConfirm(caseNumber);
	}, [forceConfirm, caseNumber]);

	return <Button
		translate="yes"
		className={`${classes.button} ${classes.primaryBtn}`}
		variant="contained"
		disabled={!canForceConfirm}
		onClick={handleLinkAndOverwrite}
		data-field="linkAndOverwrite"
	>
		Link and Overwrite
	</Button>;
};

export const ConflictsDisplayWrapper = () => {
	const classes = useStyles();
	const caseNumber = useConfirmationCaseNumber();
	const { closeDrawer } = useDrawerContext();

	return (
		<Grid container className={classes.innerDrawerContainer}>
			<Grid item className={classes.iconContainer}>
				<CloseIcon onClick={()=> closeDrawer(CONFLICTS_DISPLAY_WRAPPER)} className={classes.closeIcon} data-field="closeIcon"/>
			</Grid>
			<Grid item id="mainContainer" className={classes.mainContainer}>
				<div id="conflictsDrawerHeader">
					<p className={classes.caseNumberStyle}>EHR Case #{caseNumber}</p>
					<Typography variant="h1">Review Case Data Conflict</Typography>
					<p className={`${classes.helperText} ${classes.subheaderText}`}>
						Resolve conflict through case revisions, or overwrite the original request data with EHR data.
					</p>
				</div>
				<div id="conflictsContainer">
					<Typography variant="h2">Conflicts</Typography>
					<ConflictList/>
				</div>
			</Grid>
			<Grid item id="conflictsDrawerFooter" className={classes.footer}>
				<Divider className={classes.divider}/>
				<p className={`${classes.helperText} ${classes.footerText}`}>
					Surgery Scheduling Manager data will synchronize with the EHR. EHR data will replace all existing case details in Surgery Scheduling Manager.
				</p>
				<ForceConfirmWithConflictsButton />
				<Button
					translate="yes"
					color="primary"
					variant="outlined"
					className={classes.button}
					onClick={()=> closeDrawer(CONFLICTS_DISPLAY_WRAPPER)}
					data-field="cancelBtn"
				>
					Cancel
				</Button>
			</Grid>
		</Grid>
	);
};
