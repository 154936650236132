import React from 'react';
import { Box, Flex, Text } from '@ascension/web';
import { dateParse, getDayMonthDateFromString, utcToLocalTime } from '@utilities/dateUtilities';
import { useSelector } from 'react-redux';
import {
	CaseDetailsAppointmentSelector,
	CaseDetailsInfoSelector,
} from '@store/selectors/CaseDetailsSelector';
import { useCollectionEntity } from '@utilities/hooks/useCollectionEntity/useCollectionEntity';
import { Unit } from '@data/unit/Unit';
import { ENDPOINT_SURGEONS, ENDPOINT_USER_UNITS } from '@utilities/apiConstants';
import { Surgeon } from '@data/surgeon/Surgeon';
import { RibbonHollow } from '../../RibbonHollow/RibbonHollow';
import { getFormattedName } from '@utilities/commonFunction';
import { useCommonStyles } from '@utilities/hooks/useCommonStyles/useCommonStyles';
import CaseIdentifier from '../../CaseIdentifier/CaseIdentifier';
import { makeStyles } from '@material-ui/core';
import { buildPatientDisplayName } from '@utilities/buildPatientDisplayName';
import { DisplayText } from '@components/DisplayText/DisplayText';

const useStyles = makeStyles((theme) => ({
	separator: {
		margin: `0 ${theme.spacing(1)}px`
	},
	patientName: {
		'& .label-text': {
			display: 'none',
		},
		'& .default-value-text, .value-text': {
			fontWeight: 700,
			fontSize: '14px',
			color: theme.palette.common.black,
		},
	}
}));

export const AbbreviatedCaseCard = () => {
	const { procedureDate, duration } = useSelector(CaseDetailsAppointmentSelector);
	const caseDetailsInfo = useSelector(CaseDetailsInfoSelector);
	const { id, unit, primarySurgeon, bookmarked = false, dateCreated, performer, room } = caseDetailsInfo;
	const { patientInfo } = caseDetailsInfo || {};
	const { firstName, lastName } = performer && performer.firstName && performer.lastName ? performer : { firstName: null, lastName: null };


	const parsedProcDate = React.useMemo(
		() => procedureDate && dateParse(procedureDate),
		[procedureDate]);
	const [procDay, procMonth, procDate] = parsedProcDate ? getDayMonthDateFromString(parsedProcDate[0], true) : [];

	const convertedDateCreated = utcToLocalTime(dateCreated);
	const [, createdMonth, createdDate] = convertedDateCreated ? getDayMonthDateFromString(convertedDateCreated) : [];
	const [, createdTime, createdMeridiem] = convertedDateCreated ? dateParse(convertedDateCreated) : [];

	const { hospitalName, name: unitName } = useCollectionEntity<Unit>(ENDPOINT_USER_UNITS, unit, 'id') || {};

	const { upperCase, hideTextOverflow } = useCommonStyles();
	const classes = useStyles();

	let displaySurgeonId = '';
	let procedureName = '';
	let displayPatientName = '';

	const { procedures } = caseDetailsInfo;
	procedureName = procedures && procedures[0] && procedures[0].name ? procedures[0].name : '';

	displaySurgeonId = primarySurgeon || (!!procedureName && procedures[0].surgeonId) || '';

	displayPatientName = patientInfo ? buildPatientDisplayName(patientInfo) : '';

	const surgeon = useCollectionEntity<Surgeon>(ENDPOINT_SURGEONS, displaySurgeonId, 'id') || {};

	const displaySurgeonName = getFormattedName(surgeon, '');

	return (
		<Box
			flexDirection="column"
			data-section="abbreviatedCaseCard"
			backgroundColor="white"
			borderRadius="4px"
			p="16px"
			mb="16px"
		>
			<Flex justifyContent="space-between" width={1} alignItems="start">
				<Flex width="43px" justifyContent="center" mt="10px">
					{ id && <RibbonHollow requestId={id} bookmarked={bookmarked}/>}
				</Flex>
				<Flex flexDirection="column">
					<Box>
						<Text fontSize="20px" fontWeight={700} className="procedureTime">
							{procDay}, {procMonth} {procDate}
						</Text>
						<Text color="#c9c8c7" px="16px" fontSize="24px">|</Text>
						<Text fontSize="20px" fontWeight={700} className="time">
							{parsedProcDate && parsedProcDate[1]}{' '}
							<Text fontSize="10px" fontWeight={700} className={`${upperCase} meridiem`}>
								{parsedProcDate && parsedProcDate[2]}
							</Text>
						</Text>
					</Box>
					<CaseIdentifier caseDetails={caseDetailsInfo} />
				</Flex>
				<Text fontSize="20px" fontWeight={700} className="surgeonFirstAndLastName" ml="auto">
					{displaySurgeonName}
				</Text>
			</Flex>
			<hr className="case-card-divider"/>
			<Flex flexDirection="column" pl="43px">
				<Flex width={1} alignItems="start" mt="5px">
					<Flex flexDirection="column">
						<DisplayText
							label=""
							defaultValue="--"
							displayDefault={!displayPatientName.length}
							className={`${classes.patientName} patientName`}
						>
							{displayPatientName}
						</DisplayText>
					</Flex>
					<Text color="#c9c8c7" pl="6px" >
						{' | '}
					</Text>
					<Flex flexDirection="column" >
						<Text fontSize="14px" className="hospitalUnit" pl="6px">
							{hospitalName} {unitName}
						</Text>
					</Flex>
					{room &&
					<>
						<Text color="#c9c8c7" pl="6px" >
							{' | '}
						</Text>
						<Flex flexDirection="column" >
							<Text fontSize="14px" className="roomName" pl="6px" data-field="room">
								{room}
							</Text>
						</Flex>
					</>
					}

				</Flex>
				<Flex pt="4px" fontSize="14px">
					<Text className={`${hideTextOverflow} procedureName`}>
						{procedureName}
					</Text>
					{ duration &&
					<>
						<span className={classes.separator}>
							<Text>{'\u25aa'}</Text>
						</span>
						<Text minWidth="fit-content" data-field="duration">
							{`${duration} min`}
						</Text>
					</>
					}
				</Flex>
			</Flex>
			{dateCreated && performer &&
				<Flex width={1} justifyContent="start" pl="43px" mt="20px" alignItems="center" pb="4px">
					<Text fontSize="12px" color="#777777" pr="8px" fontWeight={600} verticalAlign="middle" height="12px" pb="4px">
						Created By:
					</Text>
					<Text fontSize="14px" fontWeight={700} data-field="performer">
						{firstName} {lastName}
					</Text>
					<Flex color="#777777" fontStyle="italic" fontSize="10px" alignItems="center" pl="4px">
						<Text data-field="createdTime">
							{createdTime + ' '}
						</Text>
						<Text className={upperCase} pl="2px" data-field="createdMeridiem">
							{createdMeridiem}
						</Text>
						<Text pl="2px" data-field="createdMonthDate">
							{createdMonth} {createdDate}
						</Text>
					</Flex>
				</Flex>
			}
		</Box>
	);
};
