import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';

const SURGEON_NOT_EXIST_MESSAGE = 'Surgeon does not exist';

const useStyles = makeStyles((theme) => createStyles({
	currentNotExists: {
		color: theme.palette.grey[600],
		textDecoration: 'line-through',
	},
	currentExists: {
		color: theme.palette.grey[600],
	}
}));
export interface CompareRequestSurgeonProps {
	compareSurgeons?: string[];
	uniqueOccurrenceCount?: number;
	crossAllAffected?: boolean;
	children: string;
}
const CompareRequestedSurgeon: React.FC<CompareRequestSurgeonProps> = ({
	children,
	compareSurgeons,
	crossAllAffected,
	uniqueOccurrenceCount= 0,
}) => {
	const classes = useStyles();

	// This will only happen for external (EHR) vals since SSM will always contain names for surgeons requested.
	if (children === SURGEON_NOT_EXIST_MESSAGE) {
		return <em>{children}</em>;
	}

	if (!compareSurgeons) {
		return <span>{children}</span>;
	}

	if (!crossAllAffected) {
		const totalOccurrenceCount = compareSurgeons.filter(s => s === children).length;
		if (compareSurgeons.includes(children) && uniqueOccurrenceCount <= totalOccurrenceCount) {
			return <span className={classes.currentExists}>{children}</span>;
		}
	}
	return <span className={classes.currentNotExists}>{children}</span>;
};

export default CompareRequestedSurgeon;
