import { START_SPINNER, END_SPINNER, GET_TERMS_CONDITION } from './types';
import { AxiosResponse } from 'axios';
import { UtilityActionTypes } from '@interfaces/utilityStoreInterface';
import { apiAction } from '../middlewares/api/ApiActionCreators';
import { ApiActionRequest } from '@interfaces/ApiAction/ApiAction';

export function startSpinner(): UtilityActionTypes {
	return {
		type: START_SPINNER
	};
}

export function endSpinner(): UtilityActionTypes {
	return {
		type: END_SPINNER
	};
}

export function fetchTermsOfUse(onSuccess: (response: AxiosResponse) => void): ApiActionRequest {
	return apiAction({
		baseURL: window.location.origin,
		url: '/terms.md',
		method: 'GET',
		responseType: 'blob',
		label: GET_TERMS_CONDITION,
		includeAccessToken: false,
		onSuccess: onSuccess
	});
}
