import React, { SetStateAction } from 'react';
import TimeSpanAutocompleteInput from '@components/TimeSpanAutocompleteInput/TimeSpanAutocompleteInput';
import getMidnightLocal from '../../../../calendar/utilities/getMidnightLocal/getMidnightLocal';
import { add } from 'date-fns';
import { useOfficeCalendarViewContext } from '@components/OfficeCalendarView/OfficeCalendarViewContext/OfficeCalendarViewContext';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
	END_INDICATOR_REGEXP,
	END_INDICATOR_STR,
	START_INDICATOR_REGEXP,
	START_INDICATOR_STR
} from '@utilities/constants';
import ErrorMessageMui from '@components/ErrorMessageMui/ErrorMessageMui';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SubmitUnavailableEventHookResult from '@data/openCalendar/SubmitUnavailableEventHookResult';
import useValidateHoldEvent
	from '@components/OfficeCalendarView/SelectOpenTimePopover/PreBookEventTimeSpanInput/useValidateHoldEvent';
import { useFormContext } from 'react-hook-form';
import SelectOpenTimeFormValues from '@components/OfficeCalendarView/SelectOpenTimeFormValues';
import getFormErrorForField from '@utilities/getFormErrorForField';

const useStyles = makeStyles(theme => ({
	container: {
		paddingTop: theme.spacing(3),
		paddingLeft: theme.spacing(4),
	},
	successMsgContainer: {
		'display': 'flex',
		'flexDirection': 'row',
		'marginTop': theme.spacing(3),
		'& > .MuiSvgIcon-root': {
			marginRight: theme.spacing(1.5),
			color: '#126E36',
		}
	}
}));

const fieldName = 'timeSpan';

export type PreBookTimeSpanInputProps = Pick<SubmitUnavailableEventHookResult, 'hasAPIConflictError' | 'setTimeSpanAPIError'>;

const getTimeSpanError = getFormErrorForField(fieldName);

const PreBookEventTimeSpanInput: React.FC<PreBookTimeSpanInputProps> = ({ hasAPIConflictError, setTimeSpanAPIError }) => {
	const classes = useStyles();
	const {
		unitConfig: { hospitalTimeZone, interval },
		setStartTime,
		setEndTime,
	} = useOfficeCalendarViewContext();

	const { watch, formState: { errors: formErrors } } = useFormContext<SelectOpenTimeFormValues>();
	const timeSpanError = getTimeSpanError(formErrors);

	const [ timeSpan ] = watch([fieldName]);
	const { start: currentStartInputValue = '', end: currentEndInputValue = '' } = timeSpan || {};

	const handleStartInputValueChange = React.useCallback((newVal: SetStateAction<string | undefined>) => setStartTime(newVal), [setStartTime]);

	const handleEndInputValueChange = React.useCallback((newVal: SetStateAction<string | undefined>) => setEndTime(newVal), [setEndTime]);

	const defaultStartTime = React.useMemo(() => getMidnightLocal(hospitalTimeZone), [hospitalTimeZone]);
	const defaultEndTime = React.useMemo(() => add(defaultStartTime, { days: 1 }), [defaultStartTime]);

	useValidateHoldEvent({ hasAPIConflictError, setTimeSpanAPIError });

	const hasStartErr = timeSpanError.includes(START_INDICATOR_STR);
	const hasEndErr = timeSpanError.includes(END_INDICATOR_STR);

	const errors = React.useMemo(() => timeSpanError
		.replace(START_INDICATOR_REGEXP, '')
		.replace(END_INDICATOR_REGEXP, '')
		.split(';')
		.filter(e => e), [timeSpanError]
	);

	return (
		<Box className={classes.container}>
			<TimeSpanAutocompleteInput
				defaultStartOption={defaultStartTime}
				defaultEndOption={defaultEndTime}
				currentStartInputValue={currentStartInputValue}
				currentEndInputValue={currentEndInputValue}
				onStartInputValueChange={handleStartInputValueChange}
				onEndInputValueChange={handleEndInputValueChange}
				hospitalTimeZone={hospitalTimeZone}
				interval={interval}
				hasStartErr={hasStartErr}
				hasEndErr={hasEndErr}
			/>
			{ errors.length ?
				errors.map((msg) =>
					<ErrorMessageMui key={msg} errorMessage={msg} />
				)
				:
				<Box className={classes.successMsgContainer}>
					<CheckCircleIcon/>
					<Typography variant="body1">
						Selected time is likely available (subject to OR review)
					</Typography>
				</Box>
			}
		</Box>
	);
};

export default PreBookEventTimeSpanInput;
