import React, { useContext } from 'react';
import {
	ManagedFieldType,
	ManageHoldEventContextType,
	WarningModalType
} from '@components/RequestForm/ManageHoldEventContext/ManageHoldEventContextType';
import { useFormContext } from 'react-hook-form';
import WarningModal from '@components/RequestForm/WarningModal/WarningModal';
import useClearHoldEventSelection
	from '@components/RequestForm/utilities/hooks/useClearHoldEventSelection/useClearHoldEventSelection';
import CommonRequestForm from '@interfaces/RequestForm/forms/CommonRequestForm';

const ManageHoldEventContext = React.createContext<ManageHoldEventContextType | null>(null);

export const useManageHoldEventContext = () => {
	const ctx = useContext(ManageHoldEventContext);

	if (!ctx) {
		throw new Error('useManageHoldEventContext must be used in a sub component of ManageHoldEventContextProvider');
	}

	return ctx;
};

export const ManageHoldEventContextProvider: React.FC = ({ children }) => {
	const { setValue, watch } = useFormContext<CommonRequestForm>();
	const [warningType, setWarningType] = React.useState<WarningModalType>(WarningModalType.NONE);
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [newFieldValue, setNewFieldValue] = React.useState<ManagedFieldType>();
	const [prevFieldValue, setPrevFieldValue] = React.useState<ManagedFieldType>();

	const handleUpdateFieldValue = React.useCallback((value: ManagedFieldType) => {
		if (warningType === WarningModalType.LOCATION && value && typeof value === 'string') {
			setValue('appointmentLocation', value);
		} else if (warningType === WarningModalType.BLOCK_TIME && value && typeof value === 'boolean') {
			setValue('appointmentBlockTime', value);
		}
	}, [setValue, warningType]);

	const handleOpenWarningModal = React.useCallback(() => {
		setIsModalOpen(true);
	}, []);

	const handleCloseWarningModal = React.useCallback(() => {
		setIsModalOpen(false);
		setWarningType(WarningModalType.NONE);
	}, []);

	const handleCancelValueChange = React.useCallback(() => {
		// set whichever field was changing back to previous value
		handleUpdateFieldValue(prevFieldValue);
		handleCloseWarningModal();
	}, [handleCloseWarningModal, handleUpdateFieldValue, prevFieldValue]);

	const clearHoldEvent = useClearHoldEventSelection();
	const [ holdEventId ] = watch(['holdEventId']);

	const handleClearDateTime = React.useCallback(() => {
		// set whichever field was changing to what user was trying to change it to
		handleUpdateFieldValue(newFieldValue);
		clearHoldEvent(holdEventId);
		handleCloseWarningModal();
	}, [clearHoldEvent, handleCloseWarningModal, handleUpdateFieldValue, holdEventId, newFieldValue]);

	return (
		<ManageHoldEventContext.Provider value={{
			handleOpenWarningModal,
			handleCancelValueChange,
			handleClearDateTime,
			setNewFieldValue,
			setPrevFieldValue,
			setWarningType,
			warningType,
			isModalOpen,
		}}>
			<WarningModal/>
			{children}
		</ManageHoldEventContext.Provider>
	);
};
