import React from 'react';
import {
	useDocumentDetailsContext
} from '@components/CaseDetails/DocumentInformationDisplay/DocumentDetails/DocumentDetails';
import DocumentField
	from '@components/CaseDetails/DocumentInformationDisplay/DocumentDetails/DocumentField/DocumentField';
import { DisplayTextProps } from '@components/DisplayText/DisplayTextProps';

const DocumentUploadedByField: React.FC<Partial<DisplayTextProps>> = (props) => {
	const { createdBy } = useDocumentDetailsContext();
	return <DocumentField fieldName="createdBy" {...props}>
		{createdBy}
	</DocumentField>;
};

export default DocumentUploadedByField;
