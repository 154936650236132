import React from 'react';
import OfficeSurgeons from './OfficeSurgeons/OfficeSurgeons';
import HospitalSurgeonsPage from './HospitalSurgeons/HospitalSurgeonsPage';
import { useSelector } from 'react-redux';
import { UserProfileHasARoleSelector } from '../../../../store/selectors/UserProfileSelector';
import { OfficeRoles } from '../../../../interfaces/UserProfile/UserRoleEnum';
import { SurgeonContextProvider } from './SurgeonContext/SurgeonContext';

export default function Surgeons() {
	const isOfficeRole = useSelector(UserProfileHasARoleSelector(OfficeRoles));
	const surgeonsForRole = isOfficeRole ? <OfficeSurgeons /> : <HospitalSurgeonsPage />;
	return (
		<SurgeonContextProvider>
			{ surgeonsForRole }
		</SurgeonContextProvider>
	);
}
