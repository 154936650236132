import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { UserProfileHasARoleSelector } from '../../../../store/selectors/UserProfileSelector';
import { HospitalInteractiveRoles } from '../../../../interfaces/UserProfile/UserRoleEnum';
import { CaseDetailsInfoSelector } from '../../../../store/selectors/CaseDetailsSelector';
import { useConfirmCaseContext } from '../../../CaseDetails/CaseDetailsContext/ConfirmCaseContext/ConfirmCaseContext';
import {
	CONFIRMATION_RESPONSE_TO_CONFIG,
	CONFIRMATION_STATUS_CONFIG,
	ConfirmationApiResponseConfiguration,
} from '../../../CaseDetails/CaseDetailsContext/ConfirmCaseContext/ConfirmCaseConfiguration';
import { AxiosResponse } from 'axios';
import { IntegrationType } from '../../../../interfaces/IntegrationType';
import {
	CaseConflict,
	ConfirmationStatus,
} from '../../../CaseDetails/CaseDetailsContext/ConfirmCaseContext/ConfirmCaseContextType';
import {
	ConflictAttributeEnum,
} from '../../../CaseDetails/CaseDetailsContext/CaseDetailsPageContext/ConflictAttrConfig';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: theme.spacing(1, 2),
	},
	message: {
		fontSize: '0.875rem',
		fontFamily: 'Lato',
	},
	reviewBtn: {
		fontSize: '0.875rem',
		letterSpacing: '0.078rem',
		padding: 0,
		color: theme.palette.primary.main,
	},
}));

const useReviewConfirm = (caseNumber = '') => {
	const { saveConfirmId } = useConfirmCaseContext();

	return React.useCallback(() => {
		const getResponseConfig = (response: AxiosResponse<ConfirmationApiResponseConfiguration>): ConfirmationApiResponseConfiguration => {
			// If the response status is ERROR_NOT_FOUND status
			return CONFIRMATION_RESPONSE_TO_CONFIG[IntegrationType.EHR][response.status].confirmationStatus === ConfirmationStatus.ERROR_NOT_FOUND ? {
				// Then actual return ERROR_CONFLICTS
				...CONFIRMATION_STATUS_CONFIG[IntegrationType.EHR][ConfirmationStatus.ERROR_CONFLICTS],
				// With the Case Number conflict
				modifiedResponse: {
					...response,
					data: {
						...response.data,
						conflicts: [
							{
								attribute: ConflictAttributeEnum.CASE_NUMBER,
								current: caseNumber,
								external: 'Case number does not exist',
							},
						] as CaseConflict[],
					},
				},
				confirmationStatus: ConfirmationStatus.ERROR_CONFLICTS,
			} : CONFIRMATION_RESPONSE_TO_CONFIG[IntegrationType.EHR][response.status];
		};
		void saveConfirmId(caseNumber, {
			force: false,
			getResponseConfig,
		});
	}, [caseNumber, saveConfirmId]);
};

const DataConflictAlertBody = () => {
	const { container, message, reviewBtn } = useStyles();
	const hasHospitalInteractiveRole = useSelector(UserProfileHasARoleSelector(HospitalInteractiveRoles));
	const { caseNumber } = useSelector(CaseDetailsInfoSelector);
	const handleReview = useReviewConfirm(caseNumber);

	return (
		<Box className={container}>
			<Typography className={message}>
				Review conflicts - Link and overwrite if case has been managed with the practice.
			</Typography>
			<Button
				className={reviewBtn}
				disabled={!hasHospitalInteractiveRole || !caseNumber}
				onClick={handleReview}
			>
				Review
			</Button>
		</Box>
	);
};

export default DataConflictAlertBody;
