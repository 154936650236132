import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosResponse } from 'axios';
import { ManagedUser, ManageUsersListContextType,
	InteractionDescriptor,
	InteractionState
} from './ManageUsersListContextType';
import endpointTemplateToUrl from '@utilities/endpointTemplateToUrl';
import apiEndpointConfig from '@utilities/apiEndpoints/apiEndpointConfig';
import ApiEndpoint from '@utilities/apiEndpoints/ApiEndpoint';
import { apiAction } from '@store/middlewares/api/ApiActionCreators';
import ApiRestMethod from '@utilities/apiEndpoints/ApiRestMethod';
import { CacheType } from '@interfaces/CacheType';
import ApiService from '@utilities/apiEndpoints/ApiService';
import { ApiActionPayload } from '@interfaces/ApiAction/ApiActionPayload';
import { UserProfileDetailsSelector } from '@store/selectors/UserProfileSelector';
import { UserStatus } from '@interfaces/UserProfile/UserStatusEnum';
import { useToast } from '@utilities/hooks/useToast/useToast';
import { ToastType } from '@interfaces/ToastStatus/ToastStatusState';
import { ToastConstants } from '@utilities/toastConstants';
import { getMonthNameDateFullYearFromString, utcToLocalTime } from '@utilities/dateUtilities';
import { mapStatusToString } from '../MapStatusToString';
import { UserRole } from '@interfaces/UserProfile/UserRoleEnum';

const ManageUsersListContext = React.createContext<ManageUsersListContextType | null>(null);

export const useManageUsersListContext = () => {
	const ctx = React.useContext(ManageUsersListContext);

	if (!ctx) {
		throw new Error('useManageUsersListContext must be used in a subcomponent of ManageUsersListContextProvider');
	}

	return ctx;
};

const getUsersListActionCreator = (userId: string, teamId: string, apiActionRequest: Partial<ApiActionPayload<ManagedUser[]>> & Pick<ApiActionPayload<ManagedUser[]>, 'onSuccess'>) => {
	const url = endpointTemplateToUrl(apiEndpointConfig[ApiEndpoint.USER_REGISTRATION].path, { userId, teamId });

	return apiAction({
		url,
		method: ApiRestMethod.GET,
		cachePolicy: CacheType.NO_CACHE,
		includeAccessToken: true,
		shouldDisableErrorHandler: false,
		label: ApiEndpoint.USER_REGISTRATION,
		apiService: ApiService.USER,
		...apiActionRequest,
	});
};

const deactivateUserActionCreator = (userDetails: ManagedUser, apiActionRequest: Partial<ApiActionPayload> & Pick<ApiActionPayload, 'onSuccess'>) => {
	const url = endpointTemplateToUrl(apiEndpointConfig[ApiEndpoint.DEACTIVATE_USER].path, { userId: userDetails.userId });
	return apiAction({
		url,
		data: userDetails,
		method: ApiRestMethod.PUT,
		cachePolicy: CacheType.NO_CACHE,
		includeAccessToken: true,
		shouldDisableErrorHandler: false,
		label: ApiEndpoint.USER_REGISTRATION,
		apiService: ApiService.USER,
		...apiActionRequest,
	});
};



const DEFAULT_INTERACTION_STATE: InteractionDescriptor = {
	user: undefined,
	state: InteractionState.NONE,
};

const mapRoleToString: Partial<Record<UserRole, string>> = {
	[UserRole.OFFICE_SCHEDULER]: 'Office Scheduler',
	[UserRole.KSWIC_OFFICE_SCHEDULER]: 'Office Scheduler',
	[UserRole.MIROC_OFFICE_SCHEDULER]: 'Office Scheduler',

	[UserRole.OFFICE_MANAGER]: 'Office Manager',
	[UserRole.KSWIC_OFFICE_MANAGER]: 'Office Manager',
	[UserRole.MIROC_OFFICE_MANAGER]: 'Office Manager',

	[UserRole.OFFICE_VIEWER]: 'Office Viewer',
	[UserRole.KSWIC_OFFICE_VIEWER]: 'Office Viewer',
	[UserRole.MIROC_OFFICE_VIEWER]: 'Office Viewer',

	[UserRole.SURGEON]: 'Surgeon',
	[UserRole.KSWIC_SURGEON]: 'Surgeon',
	[UserRole.MIROC_SURGEON]: 'Surgeon',
};

export const ManageUsersListContextProvider: React.FC = ({ children }) => {
	const [userList, setUserList] = React.useState<ManagedUser[]>([]);
	const [interaction, setInteraction] = React.useState<InteractionDescriptor>(DEFAULT_INTERACTION_STATE);

	const dispatch = useDispatch();
	const displayToast = useToast();

	const viewUser = React.useCallback(({ userId }: ManagedUser) => {
		setInteraction({
			user: userId,
			state: InteractionState.VIEW,
		});
	}, []);

	const deactivateUser = React.useCallback((userId: string) => {
		setInteraction({
			user: userId,
			state: InteractionState.DEACTIVATE,
		});
	}, []);

	const users: ManagedUser[] = React.useMemo(() => userList ? userList.map(user => {
		const convertedDateCreated = utcToLocalTime(user.dateCreated) || '';
		let dateCreatedNum;
		if ( user.dateCreated ) {
			dateCreatedNum = new Date(user.dateCreated);
		}
		const [monthString, dayNum, fullYear ] = convertedDateCreated ? getMonthNameDateFullYearFromString(convertedDateCreated) : [];
		const dateCreated = convertedDateCreated && `${monthString} ${dayNum}, ${fullYear}`;
		return {
			...user,
			dateCreatedNum,
			dateCreated,
			userRolesStr: user.userRoles ? user.userRoles.map(u => mapRoleToString[u]).join(', ') : '',
			statusStr: mapStatusToString[user.status],
		};
	}) : [], [userList]);

	const { userId: loggedInUserId, primaryPractice: loggedInUserPractice } = useSelector(UserProfileDetailsSelector);
	const { id: practiceId = '' } = loggedInUserPractice || {};

	const { user, state: interactionState } = interaction;

	const managedUser = React.useMemo(
		() => (user && users?.find(({ userId }) => userId === user)) || undefined,
		[user, users]
	);

	const resetInteractionState = React.useCallback(() => {
		setInteraction(DEFAULT_INTERACTION_STATE);
	}, []);

	const getUsersList = React.useCallback(() => {
		const onSuccess = (res: AxiosResponse<ManagedUser[]>) => {
			setUserList(res.data);
		};
		dispatch(getUsersListActionCreator(loggedInUserId, practiceId, { onSuccess }));
	}, [loggedInUserId, dispatch, practiceId]);

	const deactivateUserApiCall = React.useCallback(() => {
		if (!managedUser) { return; }
		const onSuccess = () => {
			resetInteractionState();
			displayToast(ToastType.SUCCESS, ToastConstants.USER_DEACTIVATED);
			getUsersList();
		};
		const selectedUserDetails = {
			...managedUser,
			status: UserStatus.DEACTIVATED,
			dateCreated: undefined,
		};
		dispatch(deactivateUserActionCreator(selectedUserDetails, { onSuccess }));
	}, [dispatch, displayToast, getUsersList, managedUser, resetInteractionState]);

	React.useEffect(() => {
		getUsersList();
	}, [getUsersList]);

	const manageUsersValues: ManageUsersListContextType = {
		// State info
		users,
		managedUser,
		interactionState,

		// Action handlers
		viewUser,
		deactivateUser,
		deactivateUserApiCall,
		resetInteractionState,

		// To be removed later
		isDrawerOpen: interactionState === InteractionState.VIEW,
		closeDrawer: resetInteractionState,
	};

	return (
		<ManageUsersListContext.Provider value={manageUsersValues}>
			{children}
		</ManageUsersListContext.Provider>
	);
};
