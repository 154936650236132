import DropDownOptionProps from '@components/CaseDetails/ManageCaseDropDown/DropDownOptions/DropDownOptionProps';
import {
	UserRole,
} from '@interfaces/UserProfile/UserRoleEnum';
import { sortDropDownOptions } from '@components/CaseDetails/ManageCaseDropDown/utilities/sortDropDownOptions';
import { dropDownOptionsConfig } from '@components/CaseDetails/ManageCaseDropDown/DropDownOptions/dropDownOptionsConfig';

const manageCaseOptionsByRole = (status: string|undefined) => {
	return Object.values(UserRole)
		.reduce<Partial<Record<UserRole, DropDownOptionProps[]>>>((indexedOptions, role) => {
			return {
				...indexedOptions,
				[role]: dropDownOptionsConfig.filter(o => o.roles.includes(role) && o.showWhenCaseStatus?.(status) !== false).sort(sortDropDownOptions),
			};
		}, {}) as Record<UserRole, DropDownOptionProps[]>;
};

export default manageCaseOptionsByRole;
