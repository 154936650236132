import React from 'react';
import { Box, Dialog, Typography } from '@material-ui/core';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from '@ascension/ui-library';
import { makeStyles } from '@material-ui/core/styles';
import GoldButton from '@components/GoldButton/GoldButton';
import { useDocumentRequestContext } from '@components/RequestForm/DocumentRequest/DocumentContext/DocumentRequestContext';
import { SelectedDocumentAction } from '@components/RequestForm/DocumentRequest/DocumentContext/DocumentRequestContextType';

const useStyles = makeStyles(theme => ({
	closeButton: {
		display: 'flex',
		justifyContent: 'flex-end',
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(1),
	},
	card: {
		boxSizing: 'border-box',
		marginRight: theme.spacing(4),
		marginLeft: theme.spacing(4),
		marginBottom: theme.spacing(4),
	},
	header: {
		marginBottom: theme.spacing(1),
	},
	headerText: {
		marginLeft: theme.spacing(2),
	},
	closeIcon: {
		'fill': theme.palette.grey[600],
		'&:hover': {
			cursor: 'pointer',
		},
	},
	question: {
		marginBottom: theme.spacing(3),
	},
	actionButtons: {
		marginTop: theme.spacing(4),
		display: 'flex',
		flexDirection: 'row',
	},
	warningIcon: {
		color: theme.palette.warning.main,
	}
}));

const DocumentDeleteModal: React.FC = () => {
	const classes = useStyles();
	const { selectedDocument, handleDeleteDocument, handleCloseActionModal, selectedIndexAndAction } = useDocumentRequestContext();

	if (!selectedDocument || selectedIndexAndAction.action !== SelectedDocumentAction.DELETE) { return null; }

	return (
		<Dialog open={!!selectedDocument} onClose={handleCloseActionModal} data-field="requestForm-documentsDelete-warningDialog">
			<Box className={classes.closeButton}>
				<Box>
					<CloseIcon onClick={handleCloseActionModal} className={classes.closeIcon} data-field="closeIcon"/>
				</Box>
			</Box>
			<Box className={classes.card}>
				<Box display="flex" className={classes.header} justifyContent="space-between" flexDirection="row">
					<Box style={{ flex: 'auto 1 1' }}>
						<Box display="flex" flexDirection="row">
							<ReportProblemOutlinedIcon className={classes.warningIcon} fontSize="large"/>
							<Typography variant="h5" className={classes.headerText}>Delete uploaded document</Typography>
						</Box>
					</Box>
				</Box>
				<Box>
					<Typography variant="body1" className={classes.question}>
						Are you sure you want to delete uploaded document <strong>{selectedDocument.name}</strong>?
					</Typography>
					<Typography variant="body1">
						If you delete your uploaded document, it will no longer be available. If you want to include it in this surgery request form you will have to re-upload it.
					</Typography>
				</Box>
				<Box display="flex" justifyContent="flex-end" className={classes.actionButtons}>
					<Button
						style={{ marginRight: '10px' }}
						data-field="requestForm-documentsDelete-cancelButton"
						translate="yes"
						variant="outlined"
						color="primary"
						onClick={handleCloseActionModal}
					>
						Cancel
					</Button>
					<GoldButton
						data-field="requestForm-documentsDelete-confirmButton"
						onClick={handleDeleteDocument}
					>
						YES, DELETE DOCUMENT.
					</GoldButton>
				</Box>
			</Box>
		</Dialog>
	);
};

export default DocumentDeleteModal;
