import { useDispatch } from 'react-redux';
import React from 'react';
import { saveDocumentIndexes } from '@data/docIndexes/actionCreators/DocIndexesActionCreators';
import { DocumentIndexPayload } from '@data/docIndexes/types/DocumentIndexPayload';

const useSaveDocIndexes = () => {
	const dispatch = useDispatch();

	return React.useCallback((documentId: string,  requestOverrides: DocumentIndexPayload) => {
		dispatch(saveDocumentIndexes(documentId, requestOverrides));
	}, [dispatch]);
};

export default useSaveDocIndexes;
