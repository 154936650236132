import React from 'react';
import {
	LDContext,
	useLDClient,
} from 'launchdarkly-react-client-sdk';
import { useSelector } from 'react-redux';
import { UserProfileDetailsSelector } from '@store/selectors/UserProfileSelector';

export const LaunchDarklyAppWrapper: React.FC = ({ children }) => {
	const user = useSelector(UserProfileDetailsSelector);
	const { userId, firstName, lastName, email: initialEmail } = user;
	const ldClient = useLDClient();

	React.useEffect(() => {
		const ldContext: LDContext = {
			kind: 'multi',
			user: {
				key: `ssmUi:${userId}`,
				name: `${firstName} ${lastName}`,
				email: initialEmail,
			},
		};

		if (ldClient && userId) {
			void ldClient.identify(ldContext)
		}
	}, [firstName, initialEmail, lastName, ldClient, userId]);

	return <div className="App">{children}</div>;
};
