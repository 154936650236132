import React, { ChangeEvent } from 'react';
import { Box, Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import { FieldValues, useController, useFormContext } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';

import { FindATimeForm } from '@components/FindATime/FindATimeForm';
import { StandardInputWrapper } from '@components/StatefulInputs/Base/StandardInputWrapper/StandardInputWrapper';
import { DAYS_OF_WEEK_NAMES, DAYS_OF_WEEK_NUMBERS } from '@utilities/constants';

const useStyles = makeStyles((theme) => ({
	iconButton: {
		'&.MuiIconButton-root': {
			color: theme.palette.primary.main,
		},
		'.Mui-error &.MuiIconButton-root': {
			color: theme.palette.error.main,
		},
		'&.Mui-disabled': {
			color: theme.palette.grey[500],
		},
	},
	formLabel: {
		'marginBottom': theme.spacing(1),
		'fontWeight': 600,
		'&.Mui-focused': {
			color: 'rgba(0, 0, 0, 0.54)',
		},
	},
	formControlLabelWrapper: {
		flex: 'auto',
	},
	formControlLabel: {
		marginRight: 'unset',
		marginLeft: 'unset',
	},
	checkbox: {
		'&.Mui-checked + .MuiFormControlLabel-label': {
			color: theme.palette.primary.main,
			fontWeight: 600,
		},
	},
}));

export function DayOfWeekSection() {
	const classes = useStyles();
	const { field } = useController<FindATimeForm>({
		name: 'dow',
	});

	// Needs watching as this needs to rerender when values are reset
	const { watch } = useFormContext<FindATimeForm>();
	const selectedDayNumbers = watch('dow');

	const handleCheckboxClick = React.useCallback((e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
		let selectedDayNumbersCopy = [...selectedDayNumbers];

		if (checked) {
			selectedDayNumbersCopy = [...selectedDayNumbersCopy, Number(e.target.value)].sort((a, b) => a - b);
		} else {
			selectedDayNumbersCopy = selectedDayNumbersCopy.filter(
				(dayNumber) => dayNumber !== Number(e.target.value),
			);
		}

		// send data to react hook form
		field.onChange(selectedDayNumbersCopy);
	}, [selectedDayNumbers, field]);

	return (
		<StandardInputWrapper<FieldValues>
			name="dow"
			label="Day of Week"
			// htmlFor doesn't really do anything here, but there's a TS error without it
			labelProps={{ className: classes.formLabel, htmlFor: 'dow' }}
			dataField="findATimePage-searchSection-dayOfWeekCheckboxes"
			analyticsInputName="checkbox"
		>
			<FormGroup row>
				{DAYS_OF_WEEK_NAMES.map((dayName, index) => (
					<Box key={dayName} className={classes.formControlLabelWrapper}>
						<FormControlLabel
							label={dayName}
							className={classes.formControlLabel}
							control={
								<Checkbox
									color="primary"
									value={DAYS_OF_WEEK_NUMBERS[index]}
									className={classes.checkbox}
									checked={selectedDayNumbers.includes(
										DAYS_OF_WEEK_NUMBERS[index],
									)}
									onChange={handleCheckboxClick}
								/>
							}
						/>
					</Box>
				))}
			</FormGroup>
		</StandardInputWrapper>
	);
}
