import { MyFiltersState } from '@interfaces/MyFilters/MyFiltersState';

export const MyFiltersDefaultValues: MyFiltersState = {
	sortModel: 'procedureDate:asc',
	procedureDate: '',
	from: '',
	to: '',
	status: [],
	state: [],
	surgeon: [],
	specialty: [],
	unit: [],
};
