import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import ModalViewer from '@components/Modal/ModalViewer';
import { CASE_LOCK_MODAL } from '@components/Modal/modalConstants';
import { useModalContext } from '@components/Modal/ModalContextProvider';
import { Button } from '@ascension/ui-library';

const useStyles = makeStyles((theme)=>{
	return {
		modal: {
			padding: theme.spacing(1.5)
		},
		container: {
			display: 'flex',
			flexDirection: 'column',
			width: '600px',
			padding: theme.spacing(0, 1.5, 1.5, 1.5)
		},
		content: {
			paddingTop: theme.spacing(.5),
			paddingBottom: theme.spacing(3)
		},
		closeButton: {
			height: '48px',
			width: '69px',
			marginLeft: 'auto',
		},
	};
});

export const CaseLockModal = () => {
	const classes = useStyles();
	const { closeModal } = useModalContext();

	const handleClose = () => {
		closeModal(CASE_LOCK_MODAL);
	};

	return (
		<ModalViewer id={CASE_LOCK_MODAL} className={classes.modal}>
			<Box className={classes.container}>
				<Typography component="p">{`This case is currently locked as it's being edited. Please refresh the page and wait until the case is available for editing.`}</Typography>
				<Button onClick={handleClose} className={classes.closeButton}>Close</Button>
			</Box>
		</ModalViewer>
	);
};
