import BlockEventCardData from '@data/schedules/types/BlockEventCardData';

type ComparisonObject = Pick<BlockEventCardData, 'startDate' | 'description'>;
const sortEvents = (a: ComparisonObject, b: ComparisonObject) => {
	// Sort by time
	if (a.startDate < b.startDate) { return -1; }
	if (a.startDate > b.startDate) { return 1; }
	// Then by patient name
	if (a.description < b.description) { return -1; }
	if (a.description > b.description) { return 1; }
	return 0;
};

export default sortEvents;
