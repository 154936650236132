import React from 'react';
import { useConfirmationDrawerBodyConfiguration } from '../CaseDetailsContext/ConfirmCaseContext/ConfirmCaseUtilities';
import DrawerWrapper from '@components/DrawerWrapper/DrawerWrapper';

const DefaultEmptyComponent: React.FC = () => null;

export const ConfirmationDrawer: React.FC = () => {
	const drawerBodyConfiguration = useConfirmationDrawerBodyConfiguration();
	const DrawerBodyComponent = drawerBodyConfiguration?.component || DefaultEmptyComponent;
	const drawerId = drawerBodyConfiguration?.name || '';

	return (
		<DrawerWrapper drawerId={drawerId}>
			<DrawerBodyComponent />
		</DrawerWrapper>
	);
};
