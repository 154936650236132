import React from 'react';
import { useParams } from 'react-router-dom';
import { CaseDetails } from './CaseDetails';


export const CaseDetailsPageWrapper = () => {
	const { caseId } = useParams<{ caseId: string }>();

	return (
		<CaseDetails caseId={caseId} />
	);
};
