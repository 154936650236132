import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Divider } from '@material-ui/core';
import { UserDetailsDrawerRowProps } from './UserDetailsDrawerRowProps';
import { Typography } from '@material-ui/core';
import { Box } from '@ascension/web';

const useStyles = makeStyles((theme) => ({
	cellLabel: {
		fontSize: '1rem',
		color: theme.palette.grey[700],
		flex: '33.33%',
		fontWeight: 500,
		lineHeight: '24px',
		alignItems: 'center',
		letterSpacing: '0.5px',
	},
	cellValue: {
		lineHeight: '24px',
		fontSize: '1rem',
		flex: '66.66%',
		fontWeight: 450
	},
	cell: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	divider: {
		width: '100%',
		color: '#999999',
		margin: theme.spacing(1, 0)
	},
}));

export const UserDetailsDrawerRow = ({ name, value, disableDivider }: UserDetailsDrawerRowProps) => {
	const classes = useStyles();

	let divider;
	if (!disableDivider) {
		divider = <Divider id="Divider" className={classes.divider} />;
	}

	return (
		<Box className={classes.cell}>
			<Typography variant="h6" component="span" className={classes.cellLabel}>{name}</Typography>
			<Typography variant="h6" component="span" className={classes.cellValue}>{value? value : '--'}</Typography>
			{divider}
		</Box>
	);
};
