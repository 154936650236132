import React from 'react';
import { isEqual } from 'date-fns';
import { useFormContext } from 'react-hook-form';
import { useFindATimeContext } from '@components/FindATime/FindATimeContext/FindATimeContextProvider';
import { FindATimeForm } from '@components/FindATime/FindATimeForm';

/**
 * A React hook that clears the selected time slot on the FindATime SelectATimeSection when the
 * duration, location, surgeon, start date, end date, days of the week, start time, end time, or procedure name changes.
 */
export const useClearFindATimeSelection = (): void => {
	const { watch } = useFormContext<FindATimeForm>();

	const [
		appointmentLocation,
		dow,
		duration,
		endDate,
		endTime,
		primarySurgeon,
		procedureName,
		startDate,
		startTime,
	] = watch([
		'appointmentLocation',
		'dow',
		'duration',
		'endDate',
		'endTime',
		'primarySurgeon',
		'procedureName',
		'startDate',
		'startTime',
	]);

	const { setFindATimeResponse } = useFindATimeContext();

	// Store the previous values of the watched fields
	const prevValues = React.useRef({
		appointmentLocation,
		dow,
		duration,
		endDate,
		endTime,
		primarySurgeon,
		procedureName,
		startDate,
		startTime,
	});

	React.useEffect(() => {
		// If any of the watched fields have changed...
		if (
			prevValues.current.appointmentLocation !== appointmentLocation ||
			prevValues.current.dow?.length !== dow?.length ||
			prevValues.current.duration !== duration ||
			prevValues.current.endDate && endDate && !isEqual(prevValues.current.endDate, endDate) ||
			prevValues.current.endTime !== endTime ||
			prevValues.current.primarySurgeon !== primarySurgeon ||
			prevValues.current.procedureName !== procedureName ||
			prevValues.current.startDate && startDate && !isEqual(prevValues.current.startDate, startDate) ||
			prevValues.current.startTime !== startTime
		) {
			setFindATimeResponse(undefined);
		}

		// Update the previous values to the current ones
		prevValues.current = {
			appointmentLocation,
			dow,
			duration,
			endDate,
			endTime,
			primarySurgeon,
			procedureName,
			startDate,
			startTime,
		};
	}, [
		appointmentLocation,
		dow,
		duration,
		endDate,
		endTime,
		primarySurgeon,
		procedureName,
		setFindATimeResponse,
		startDate,
		startTime,
	]);
};
