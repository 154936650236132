import React from 'react';
import { Box, BoxProps, createStyles, makeStyles } from '@material-ui/core';
import { TrackableEventType } from '@components/BaseEventTrackingWrapper/BaseTrackableEvents.types';

interface BaseEventTrackingWrapperProps extends BoxProps {
	children: React.ReactNode;
	eventsToTrack?: TrackableEventType[];
	dataField?: string;
	handleEvent: (event: React.SyntheticEvent<HTMLElement>) => void;
}

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			'& .MuiOutlinedInput-root': {
				width: '100%',
			},
		},
	}),
);

export const BaseEventTrackingWrapper: React.FC<BaseEventTrackingWrapperProps> = ({
	children,
	eventsToTrack = [TrackableEventType.OnFocus], // Default to common events
	dataField,
	handleEvent,
	...rest
}) => {

	const classes = useStyles();

	// Create props for the Box component to attach event handlers
	const eventProps = React.useMemo(() =>
		eventsToTrack.reduce<Partial<Record<TrackableEventType, React.EventHandler<React.SyntheticEvent<HTMLElement>>>>>((eventHandlers, eventName) => {
			eventHandlers[eventName] = handleEvent;
			return eventHandlers;
		}, {}), [eventsToTrack, handleEvent]);

	return (
		<Box className={classes.root} data-field={dataField} {...eventProps} {...rest}>
			{children}
		</Box>
	);
};
