import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import GoldButton from '@components/GoldButton/GoldButton';
import CloseIcon from '@material-ui/icons/Close';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
	useOfficeCalendarViewContext,
} from '@components/OfficeCalendarView/OfficeCalendarViewContext/OfficeCalendarViewContext';
import { OfficeCalendarError } from '@components/OfficeCalendarView/OfficeCalendarError';

const useStyles = makeStyles(theme => createStyles({
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	paper: {
		padding: theme.spacing(4),
	},
}));

const AvailableTimeErrorModal = () => {
	const classes = useStyles();

	const {
		calendarError,
		setCalendarError,
	} = useOfficeCalendarViewContext();

	const handleClose = React.useCallback(() => {
		setCalendarError(OfficeCalendarError.NONE);
	}, [setCalendarError]);

	const shouldOpen = calendarError === OfficeCalendarError.TIME_NO_LONGER_AVAIL;

	return (
		<Dialog
			open={shouldOpen}
			onClose={handleClose}
			PaperProps={{
				classes: {
					root: classes.paper
				},
			}}
			data-field="openTimeCalendar-availableTimeError-modal"
		>
			<DialogTitle disableTypography>
				<Typography component="h2" variant="h5">Time no longer available</Typography>
				<IconButton aria-label="close" className={classes.closeButton} onClick={handleClose} data-field="openTimeCalendar-availableTimeError-closeButton">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Typography>Unfortunately, this time has been claimed by another scheduler. The calendar will reload to show the current available times.</Typography>
			</DialogContent>
			<DialogActions>
				<GoldButton onClick={handleClose} data-field="openTimeCalendar-availableTimeError-okayButton">Okay</GoldButton>
			</DialogActions>
		</Dialog>
	);
};

export default AvailableTimeErrorModal;
