import { useDispatch } from 'react-redux';
import { useCaseDetailsInfoContext } from '@components/CaseDetails/CaseDetailsContext/CaseDetailsInfoContext/CaseDetailsInfoContext';
import React from 'react';
import { unlockCase } from '@store/actions/CaseDetailsActionCreators';

const useUnlockCaseOnUnmount = () => {
	const dispatch = useDispatch();

	const { caseId } = useCaseDetailsInfoContext();

	React.useEffect(() => {
		return () => {
			dispatch(unlockCase(caseId));
		};
	}, [caseId, dispatch]);
};

export default useUnlockCaseOnUnmount;
