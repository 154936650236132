import { RootState } from '../../interfaces/rootState';
import {
	PracticeInterface,
	UserDetailsApiInterface, UserDetailsPreferenceHospital, UserDetailsPreferenceOffice,
} from '../../interfaces/UserPrefInterface';
import { UserRole } from '../../interfaces/UserProfile/UserRoleEnum';
import { Unit } from '../../data/unit/Unit';
import { Surgeon } from '../../data/surgeon/Surgeon';

export const UserProfileSelector = ({ userProfile }: RootState) => userProfile;
export const UserProfileDetailsSelector = ({ userProfile }: RootState) => userProfile && userProfile.userProfileDetails;
export const UserProfilePrimaryPracticeSelector = ({ userProfile }: RootState): PracticeInterface =>  userProfile && userProfile.userProfileDetails ? userProfile.userProfileDetails.primaryPractice || {} as PracticeInterface : {} as PracticeInterface;
export const UserProfilePrimaryPracticeSelectorId = ({ userProfile }: RootState): string =>  userProfile && userProfile.userProfileDetails && userProfile.userProfileDetails.primaryPractice && userProfile.userProfileDetails.primaryPractice.id || '';
export const UserProfilePrimaryHospitalSelectorId = ({ userProfile }: RootState): string =>  userProfile && userProfile.userProfileDetails && userProfile.userProfileDetails.primaryHospital && userProfile.userProfileDetails.primaryHospital.id || '';
export const UserProfilePrimaryHospitalUnits = ({ userProfile }: RootState): Unit[] | [] =>  userProfile && userProfile.userProfileDetails && userProfile.userProfileDetails.primaryHospital && userProfile.userProfileDetails.primaryHospital.units || [];
export const UserProfilePrimaryRoleSelector = ({ userProfile }: RootState): UserRole | undefined => userProfile && userProfile.userProfileDetails && userProfile.userProfileDetails.userRoles && userProfile.userProfileDetails.userRoles.length ? userProfile.userProfileDetails.userRoles[0] : undefined;

export const UserProfileRoleSelector = ({ userProfile }: RootState): UserRole[] => userProfile && userProfile.userProfileDetails && userProfile.userProfileDetails.userRoles && userProfile.userProfileDetails.userRoles.length ? userProfile.userProfileDetails.userRoles : [];

export const UserProfileHasARoleSelector = (checkForRoles?: UserRole[]) => (state: RootState): boolean | undefined => {
	const roles = UserProfileRoleSelector(state);
	if (!roles.length || !checkForRoles) {
		return undefined;
	}
	return roles.some((role) => checkForRoles.includes(role));
};

export const UserPrefSurgeonListSelector = ({ surgeonList }: RootState): Array<Surgeon>| [] => surgeonList && surgeonList.surgeonList && surgeonList.surgeonList.length > 0 ? surgeonList.surgeonList : [];
export const UserPrefEmailNotificationSelector = ({ userProfile }: RootState) => {
	const userDetails: UserDetailsApiInterface = userProfile.userProfileDetails;
	return !!(userDetails.preference && userDetails.preference.emailNotification);
};

export const UserPreferenceSelector = ({ userProfile }: RootState): UserDetailsPreferenceHospital | UserDetailsPreferenceOffice => userProfile && userProfile.userProfileDetails && userProfile.userProfileDetails.preference || {};

export const UserProfileFilterUrlSelector = ({ userProfile }: RootState): string => userProfile && userProfile.userProfileDetails && userProfile.userProfileDetails.filterUrl;

export const UserProfileDocViewPermissionSelector = ({ userProfile }: RootState): boolean | undefined => userProfile && userProfile.userProfileDetails && !!userProfile.userProfileDetails.docViewPermission;
