import React from 'react';
import { LinkMenu } from '@components/LinkMenu/LinkMenu';
import { LinkMenuOptionProps } from '@components/LinkMenu/LinkMenuOption/LinkMenuOption';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import { OfficeInteractiveRoles, OfficeRoles } from '@interfaces/UserProfile/UserRoleEnum';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';
import classNames from 'classnames';
import { ROUTE_CONSTANTS } from '@utilities/RouteConstants';
import useHasUnitWithOpenTime from '@components/SchedulePage/utilities/useHasUnitWithOpenTime/useHasUnitWithOpenTime';
import useHasPermission from '@utilities/hooks/useHasPermission/useHasPermission';
import { PermittedAction } from '@utilities/PermittedAction';
import {
	useHomeNavigationContext
} from '@components/HeaderSection/NavigationOptions/HomeNavigationContext/HomeNavigationContext';

const useStyles = makeStyles((theme) => ({
	link: {
		textTransform: 'uppercase',
		color: theme.palette.primary[100],
		fontWeight: 700,
		fontSize: '1rem',
		marginTop: 0,
	},
	menu: {
		'& li': {
			fontSize: '1.125rem',
			fontWeight: 400,
		},
		'& .MuiSvgIcon-root': {
			fill: theme.palette.primary.main,
		},
	},
	activeNavLink: {
		color: theme.palette.common.white,
	},
	verticalDivider: {
		height: '2rem',
		background: theme.palette.common.white,
		display: 'block',
	},
}));

export const SchedulingMenu: React.FC = () => {
	const classes = useStyles();
	const history = useHistory();
	const { pathname } = useLocation();
	const { setHomeRoute } = useHomeNavigationContext();

	const handleCalendarClick = React.useCallback(() => {
		setHomeRoute(ROUTE_CONSTANTS.SCHEDULING);
		history.push(ROUTE_CONSTANTS.SCHEDULING);
	}, [setHomeRoute, history]);

	const isCalendarOptionActive = pathname === ROUTE_CONSTANTS.SCHEDULING;
	const isFindATimeOptionActive = pathname === ROUTE_CONSTANTS.FIND_A_TIME;

	const isLinkActive = isCalendarOptionActive || isFindATimeOptionActive;

	const options: LinkMenuOptionProps[] = React.useMemo(() => {
		return [
			{
				id: 'schedulingCalendar',
				label: 'Calendar',
				icon: CalendarTodayOutlinedIcon,
				onClick: handleCalendarClick,
				order: 1,
				showForRoles: OfficeRoles,
				isActive: isCalendarOptionActive,
			},
			{
				id: 'findATime',
				label: 'Find A Time',
				icon: ScheduleOutlinedIcon,
				onClick: () => history.push(ROUTE_CONSTANTS.FIND_A_TIME),
				order: 2,
				showForRoles: OfficeInteractiveRoles,
				isActive: isFindATimeOptionActive,
			},
		];
	}, [history, isFindATimeOptionActive, isCalendarOptionActive, handleCalendarClick]);

	const { data: hasUnitWithOpenTime } = useHasUnitWithOpenTime();
	const hasPermissionRole = useHasPermission(PermittedAction.VIEW_NAVIGATION_OPTIONS);

	if (!hasUnitWithOpenTime || !hasPermissionRole) {
		return null;
	}

	return (
		<>
			<LinkMenu
				label="Scheduling"
				options={options}
				linkClassName={classNames(classes.link, {
					[classes.activeNavLink]: isLinkActive,
				})}
				menuClassName={classes.menu}
			/>
			<Divider
				orientation="vertical"
				className={classes.verticalDivider}
			/>
		</>
	);
};
