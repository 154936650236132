import RightLeftDirectionEnum from '@interfaces/RightLeftDirectionEnum';

const CONTENT_HIDDEN_DIRECTIONS_DEFAULT = {
	[RightLeftDirectionEnum.LEFT]: false,
	[RightLeftDirectionEnum.RIGHT]: false,
};

const determineContentHiddenDirection = (scrollContainer: HTMLDivElement | undefined) => {
	if (!scrollContainer) { return CONTENT_HIDDEN_DIRECTIONS_DEFAULT; }

	const { scrollLeft, scrollWidth, clientWidth } = scrollContainer;
	const leftContentHidden = scrollLeft > 0;
	const rightContentHidden = scrollWidth - clientWidth - scrollLeft > 0;

	return {
		[RightLeftDirectionEnum.LEFT]: leftContentHidden,
		[RightLeftDirectionEnum.RIGHT]: rightContentHidden,
	};
};

export default determineContentHiddenDirection;


