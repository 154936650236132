import React from 'react';
import { Flex, Text } from '@ascension/web';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { UserProfileDetailsSelector, UserProfileHasARoleSelector } from '../../../store/selectors/UserProfileSelector';
import { SetupCardSection } from '../SetupCardSection/SetupCardSection';
import { TextField } from '@ascension/ui-library';
import { useFieldFormContext } from '../../../utilities/hooks/useFieldFormContext/useFieldFormContext';
import { SetupMyInformationValidationSchema } from './SetupMyInformationValidationSchema';
import LastFourSSNInput from '../../UserRegistrationPage/BasicUserInfoForm/LastFourSSNInput/LastFourSSNInput';
import DOBMonthDayInputWrapper
	from '../../UserRegistrationPage/BasicUserInfoForm/DOBMonthDayInputWrapper/DOBMonthDayInputWrapper';
import { OfficeRoles } from '../../../interfaces/UserProfile/UserRoleEnum';

const useStyles = makeStyles((theme) => ({
	parentContainer: {
		marginTop: theme.spacing(4),
	},
	flexGapContainer: {
		flexDirection: 'column',
		gap: theme.spacing(4),
	},
	label: {
		fontSize: '16px',
		fontWeight: 'bold',
		color: 'rgba(0, 0, 0, 0.54)',
		paddingBottom: theme.spacing(.5),
	},
	flexFieldContainer: {
		flexDirection: 'column',
		letterSpacing: '0.4px',
		marginRight: theme.spacing(2.5)
	},
	inputStyle: {
		'width': '352px',
		'& > .MuiFormHelperText-root.Mui-error': {
			position: 'absolute',
			marginTop: theme.spacing(10),
		},
	},
}));

export const SetupMyInformation = () => {
	const classes = useStyles();
	const { firstName = '', lastName = '', phone = '', ext = '' } = useSelector(UserProfileDetailsSelector);
	const isOfficeRole = useSelector(UserProfileHasARoleSelector(OfficeRoles));

	const {
		currentValue: preferredEmailCurrentValue,
		setCurrentValue: preferredEmailSetCurrentValue,
		error: preferredEmailError,
		triggerSelfValidation: preferredEmailTriggerSelfValidation,
	} = useFieldFormContext<string>('preferredEmail', {
		validationRules: SetupMyInformationValidationSchema.preferredEmail,
		triggerValidationOnChange: false
	});

	const handleEmailBlur = () => {
		void preferredEmailTriggerSelfValidation();
	};

	return (
		<SetupCardSection sectionTitle="My Information">
			<Flex className={`${classes.parentContainer} ${classes.flexGapContainer}`}>
				<Flex className={classes.flexFieldContainer} data-field="fullName">
					<Text className={classes.label}>
						Name
					</Text>
					<Text>
						{firstName} {lastName}
					</Text>
				</Flex>
				<Flex flexDirection="row" justifyContent="flex-start" alignItems="start">
					<Flex className={classes.flexFieldContainer} data-field="phoneNumber">
						<Text className={classes.label}>
							Office Phone Number
						</Text>
						<Text>
							{phone}
						</Text>
					</Flex>
					<Flex className={classes.flexFieldContainer} data-field="extension">
						<Text className={classes.label}>
							Extension
						</Text>
						<Text>
							{ext}
						</Text>
					</Flex>
				</Flex>
				<Flex className={classes.flexFieldContainer} data-field="email">
					<TextField
						translate="yes"
						variant="static"
						id="preferredEmail"
						name="preferredEmail"
						data-field="preferredEmail"
						label="Preferred Email Address"
						required
						value={preferredEmailCurrentValue}
						onChange={(e) => preferredEmailSetCurrentValue(e.target.value)}
						onBlur={handleEmailBlur}
						error={!!preferredEmailError}
						helperText={preferredEmailError}
						className={classes.inputStyle}
					/>
				</Flex>
				{ isOfficeRole &&
				<Flex className={classes.flexGapContainer}>
					<Flex data-field="dob-input">
						<DOBMonthDayInputWrapper/>
					</Flex>
					<Flex data-field="ssn-input">
						<LastFourSSNInput/>
					</Flex>
				</Flex>
				}
			</Flex>
		</SetupCardSection>
	);
};
