import { validators } from '../../../utilities/Validation/validators';
import { RegisterOptions } from 'react-hook-form';
import {
	INVALID_GROUP_NUMBER,
	INVALID_HEALTH_PLAN_NAME,
	INVALID_POLICY_NUMBER,
	INVALID_PRE_CERT_NUMBER,
	PRE_AUTH_OPTION_NOT_SELECTED
} from '../../../utilities/Validation/ValidationMessages';

const NAME_ERROR = 'Please enter a valid name.';

export const InsuranceInformationValidationSchema: {[propName: string]: Partial<RegisterOptions>} = {
	nameValidation: {
		required: NAME_ERROR,
		minLength: {
			value: 2,
			message: NAME_ERROR
		},
		validate: {
			invalidName: (name: string) => validators.nameValidator(name) === null || NAME_ERROR,
		},
	},
	healthPlanName: {
		required: INVALID_HEALTH_PLAN_NAME,
	},
	groupNumber: {
		validate: {
			alphanumeric: (num: string) => validators.alphanumericValidator(num) === null || INVALID_GROUP_NUMBER,
		},
	},
	preAuth: {
		required: PRE_AUTH_OPTION_NOT_SELECTED
	},
	preCertNumber: {
		validate: {
			alphanumeric: (num: string) => validators.alphanumericValidator(num) === null || INVALID_PRE_CERT_NUMBER,
		},
	},
	policyNumber: {
		required: INVALID_POLICY_NUMBER,
	},
};
