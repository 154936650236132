import React from 'react';
import { useFindATimeContext } from '@components/FindATime/FindATimeContext/FindATimeContextProvider';
import { useFindATimeRequest } from '@components/FindATime/utilities/useFindATimeRequest/useFindATimeRequest';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { QueryBuilderRounded } from '@material-ui/icons';
import {
	useDoesUnitSupportOpenTime
} from '@components/RequestForm/utilities/hooks/useDoesUnitSupportOpenTime/useDoesUnitSupportOpenTime';
import { useProcedureNameContext } from '@components/RequestForm/ProcedureName/ProcedureNameContext';
import classNames from 'classnames';
import { BaseButton } from '@components/BaseButton/BaseButton';

const useStyles = makeStyles(theme => ({
	buttonIcon: {
		paddingRight: theme.spacing(1),
	},
	fatButton: {
		borderColor: theme.palette.primary.main,
		color: theme.palette.primary.main,
		background: theme.palette.common.white,
		display: 'flex',
		width: 'fit-content',
		height: '36px',
		borderWidth: '2px',
		borderRadius: '100px',
	},
	fatButtonDisabled: {
		borderWidth: '2px',
		borderColor: alpha(theme.palette.common.black, 0.38),
		color: alpha(theme.palette.common.black, 0.38),
	}
}));

export const FindATimeButton: React.FC = () => {
	const classes = useStyles();

	const { submitFindATimeSearch, durationFallback } = useFindATimeContext();
	const findATimeRequest = useFindATimeRequest(durationFallback);
	const supportsOpenTime = useDoesUnitSupportOpenTime();
	const { isCustomProcedure, selectedProcedure } = useProcedureNameContext();

	const handleClick = () => {
		submitFindATimeSearch();
	};

	if (!supportsOpenTime) {
		return null;
	}
	const faTDurationInMinutes = findATimeRequest.data.duration ? findATimeRequest.data.duration : 0;
	const isDisabled = !findATimeRequest.isValid || isCustomProcedure || !selectedProcedure || faTDurationInMinutes < 30;

	return (
		<BaseButton
			dataField="requestForm-requestDetails-findATimeButton"
			onClick={handleClick}
			disabled={isDisabled}
			variant="outlined"
			className={classNames(classes.fatButton, isDisabled && classes.fatButtonDisabled)}
		>
			<QueryBuilderRounded className={classes.buttonIcon} />
			FIND A TIME
		</BaseButton>
	);
};
