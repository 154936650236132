import {
	ACCOUNT_INFORMATION_CHECKBOXES_NONE_SELECTED,
	INVALID_ASCENSION_USERNAME,
	INVALID_LAST_FOUR_SSN,
	INVALID_MONTH_OR_DAY_OF_BIRTH
} from '../../../utilities/Validation/ValidationMessages';

export const BasicUserInfoFormValidationSchema = {
	dobMonth: {
		required: INVALID_MONTH_OR_DAY_OF_BIRTH,
	},
	dobDay: {
		required: INVALID_MONTH_OR_DAY_OF_BIRTH,
	},
	lastFourSsn: {
		required: INVALID_LAST_FOUR_SSN,
		minLength: {
			value: 4,
			message: INVALID_LAST_FOUR_SSN,
		},
	},
	hasAscensionUsername: {
		required: ACCOUNT_INFORMATION_CHECKBOXES_NONE_SELECTED,
	},
	ascensionUsername: {
		required: INVALID_ASCENSION_USERNAME,
	},
};
