import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Button } from '@ascension/ui-library';
import BlueOutlineButton from '@components/BlueOutlineButton/BlueOutlineButton';
import ModalViewer from '@components/Modal/ModalViewer';

const useStyles = makeStyles(theme => createStyles({
	buttonContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		height: '48px',
	},
	message: {
		color: theme.palette.grey[900],
		fontWeight: 400,
		margin: theme.spacing(3, 0, .5, 0),
	},
	root: {
		width: '600px',
		padding: theme.spacing(3),
	},
	warningText: {
		color: theme.palette.error.main,
		fontWeight: 600,
		marginBottom: theme.spacing(3),
	},
	yesButton: {
		'backgroundColor': theme.palette.error.main,
		'color': theme.palette.common.white,
		'&:hover': {
			backgroundColor: theme.palette.error.dark,
		},
	},
}));

interface NavigationWarnModalProps {
	id: string;
	onCancel: () => void;
	onClose?: () => void;
	onConfirm: () => void;
}

export const NavigationWarnModal: React.FC<NavigationWarnModalProps> = ({
	id,
	onCancel,
	onClose,
	onConfirm
}) => {
	const classes = useStyles();

	return (
		<ModalViewer id={id} className={classes.root} onClose={onClose}>
			<Typography component="h2" variant="h6">
				Are you sure?
			</Typography>
			<Typography className={classes.message}>
				You are navigating away from the Request Form. You will lose all information entered in this
				form if you leave before submitting a request.
			</Typography>
			<Typography className={classes.warningText}>
				Are you sure you want to navigate away?
			</Typography>
			<Box className={classes.buttonContainer}>
				<BlueOutlineButton
					data-field="requestForm-navigationWarnModal-noStayOnScreenButton"
					onClick={onCancel}
				>
					No, Stay on this screen
				</BlueOutlineButton>
				<Button
					data-field="requestForm-navigationWarnModal-yesButton"
					className={classes.yesButton}
					translate="yes"
					onClick={onConfirm}
				>
					Yes
				</Button>
			</Box>
		</ModalViewer>
	);
};
