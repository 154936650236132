import { ActiveToastType, ToastType } from '../../../interfaces/ToastStatus/ToastStatusState';
import { useDispatch } from 'react-redux';
import { setToastErrorStatus, setToastSuccessStatus, setToastWarningStatus } from '../../../store/actions/ToastStatusActionCreators';
import { useCallback } from 'react';

export const useToast = () => {
	const dispatch = useDispatch();
	return useCallback((toastType: ActiveToastType, message: string, timeout?: number) => {
		switch (toastType) {
		case ToastType.SUCCESS:
			dispatch(setToastSuccessStatus(message, timeout));
			break;
		case ToastType.WARNING:
			dispatch(setToastWarningStatus(message, timeout));
			break;
		case ToastType.ERROR:
			dispatch(setToastErrorStatus(message, timeout ));
			break;
		}
	}, [dispatch]);
};
