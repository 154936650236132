import { RegisterOptions } from 'react-hook-form';
import { SpecialtiesIndex } from '../components/AccountSettings/MyFilters/SaveFiltersContext/SaveFiltersProps';
import { Surgeon } from '../data/surgeon/Surgeon';
import { PROCEDURE_DATE_OPTIONS } from './constants';

export const removeMaskSpace = (val: string) => {
	return val ? val.replace(/\s/g, '') : val;
};

export const checkIsRequired = (validationRules?: RegisterOptions): boolean => {
	return !!(validationRules && (validationRules.required || (validationRules.validate && (validationRules.validate as Record<string, RegisterOptions>).required)));
};

export const getSpecialtyNameAndCount = (specialtyId: string, specialtyName = '', surgeons: Surgeon[]) => {
	const nameCharLimit = 30;
	const specialtyCount = surgeons.length;
	const formattedSpecialtyName = specialtyName.length > nameCharLimit ?
		`${specialtyName.slice(0, nameCharLimit)}...` : specialtyName;
	return { specialtyName: formattedSpecialtyName, specialtyCount  };
};

export const formatSpecialtyWithSurgeons= (surgeonList: Surgeon[]) => surgeonList.reduce( (acc: SpecialtiesIndex, curr: Surgeon ) => {
	const { specialtyId, specialty } = curr;
	const surgeons = acc[specialtyId] ? [ ...acc[specialtyId].surgeons, curr ] : [ curr ];
	const { specialtyName, specialtyCount } = getSpecialtyNameAndCount(specialtyId, specialty, surgeons);

	return { ...acc, [specialtyId]: { specialtyName, surgeons, specialtyCount } };
}, {} );

export const getDropdownValue = (inputValue: string) => PROCEDURE_DATE_OPTIONS.filter(item => item.value == inputValue);
