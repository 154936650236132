import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
	parsePeriodAndUpdateTimeState,
	parseAndUpdateTimeState,
} from '@utilities/timeUtils/timeUtils';
import { useMultiFormValueSetter } from '@components/RequestForm/utilities/MultiFormAdapter/MultiFormAdapter';
import { UseProcedureTimeSetterType } from '@components/RequestForm/utilities/hooks/useProcedureTimeSetter/UseProcedureTimeSetterType';
import CommonRequestForm from '@interfaces/RequestForm/forms/CommonRequestForm';

export const useProcedureTimeSetter = (): UseProcedureTimeSetterType => {
	const timeSetter = useMultiFormValueSetter('procedureTime');

	const { getValues } = useFormContext<Pick<CommonRequestForm, 'procedureDate'>>();

	const setTimeValue = React.useCallback((timeUpdate?: string) =>
		parseAndUpdateTimeState(timeSetter, timeUpdate, getValues('procedureDate')),
	[getValues, timeSetter]);
	const setPeriodValue = React.useCallback((periodUpdate?: string) =>
		parsePeriodAndUpdateTimeState(timeSetter, periodUpdate, getValues('procedureDate')),
	[getValues, timeSetter]);

	return { setPeriodValue, setTimeValue };
};
