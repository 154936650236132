import { RowsColumnsInfo } from '@calendar/types/RowsColumnsInfo';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Box, Table, TableBody, TableRow } from '@material-ui/core';
import classNames from 'classnames';
import type { Theme as DefaultTheme } from '@material-ui/core/styles/createTheme';
import { useCalendarContext } from '@calendar';
import TimeAxisLabels from '@calendar/components/TimeAxisLabels/TimeAxisLabels';
import ColumnHeaders from '@calendar/components/ColumnHeaders/ColumnHeaders';
import GridLines from '@calendar/components/GridLines/GridLines';
import { ROW_HEIGHT_PX } from '@calendar/utilities/CalendarConstants';
import ClickDetectors from '@calendar/components/ClickDetectors/ClickDetectors';

const useCalendarClasses = makeStyles<DefaultTheme, Omit<RowsColumnsInfo, 'headerHeight'>>(theme => ({
	calendarWithVerticalAxis: {
		display: 'flex',
		flexDirection: 'row',
		position: 'relative',
	},
	calendarGrid: ({ columns, rows }) => ({
		width: '100%',
		display: 'grid',
		gridTemplateRows: `min-content 5px repeat(${rows - 1}, ${ROW_HEIGHT_PX}px)`,
		gridTemplateColumns: columns && `repeat(${columns}, minmax(125px, 320px))`,
	}),
	leftAxis: {
		backgroundColor: theme.palette.common.white,
		left: 0,
		position: 'sticky',
		zIndex: 1299,
	},
	table: {
		width: 'auto',
	},
	tableCell: {
		padding: theme.spacing(0),
	},
}));

const CalendarUI: React.FC = () => {
	const { memoizedTicks: { length: rows }, columns, events, eventConfig } = useCalendarContext();
	const classes = useCalendarClasses({ columns: columns.length, rows });

	return <Table className={classes.table}>
		<TableBody>
			<TableRow>
				<td className={classNames(classes.leftAxis, classes.tableCell)}>
					<TimeAxisLabels />
				</td>
				<td className={classes.tableCell}>
					<Box className={classes.calendarGrid}>
						<ColumnHeaders />
						<GridLines />
						<ClickDetectors />
						{events.map(e => {
							const Component = eventConfig[e.type]?.cardComponent;
							return (
								Component ? <Component {...e} key={e.id} /> : null
							);
						})}
					</Box>
				</td>
			</TableRow>
		</TableBody>
	</Table>;
};

export default CalendarUI;
