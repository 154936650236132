import { ConflictAttributeEnum } from '../CaseDetailsPageContext/ConflictAttrConfig';
import { AxiosResponse } from 'axios';
import {
	ConfirmationApiResponseConfiguration,
	ConfirmationStatusResponseConfiguration,
} from './ConfirmCaseConfiguration';

export interface CaseConflict {
	attribute: ConflictAttributeEnum;
	current: string;
	external: string;
}

export enum ConfirmationStatus {
	UNDEFINED = 'UNDEFINED',
	CONFIRMED_EHR = 'CONFIRMED_EHR',
	CONFIRMED_MANUAL = 'CONFIRMED_MANUAL',
	ERROR_CONFLICTS = 'ERROR_CONFLICTS',
	ERROR_NOT_FOUND = 'ERROR_NOT_FOUND',
	ERROR_OTHER = 'ERROR_OTHER',
}

export type ConfirmationConfigIndex = Record<ConfirmationStatus, ConfirmationStatusResponseConfiguration>;

export interface ConfirmationStatusInfo {
	status: ConfirmationStatus;
	latestResponse?: AxiosResponse<ConfirmationStatusResponseConfiguration>;
}

export interface SaveConfirmOptions {
	force?: boolean;
	fin?: string;
	getResponseConfig?: (response: AxiosResponse) => ConfirmationApiResponseConfiguration;
}

export interface ConfirmCaseContextType {
	saveConfirmId: (confirmId: string, confirmOptions?: SaveConfirmOptions) => Promise<void>;
	confirmationStatus: ConfirmationStatus;
	latestConfirmationResponse?: AxiosResponse<ConfirmationStatusResponseConfiguration>;
	confirmationCaseNumber?: string;
}
