import React from 'react';
import { Box, Divider, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { CaseActivityGridFields, CaseActivityContainerProps } from './CaseActivityContainer.types';

const useStyles = makeStyles<Theme, CaseActivityContainerProps>((theme: Theme) => ({
	root: {
		display: 'grid',
		flex: 'auto 1 1',
		columnGap: theme.spacing(0.25),
		rowGap: ({ hasChanges }) => (hasChanges ? theme.spacing(1) : theme.spacing(0.5)),
		gridTemplateAreas: `
			'${CaseActivityGridFields.AVATAR} ${CaseActivityGridFields.TITLE} ${CaseActivityGridFields.STATUS}'
			'. ${CaseActivityGridFields.CHANGES} ${CaseActivityGridFields.CHANGES}'
			'. ${CaseActivityGridFields.REASON} .'
			`,
		gridTemplateRows: '1fr',
		gridTemplateColumns: 'min-content 1fr min-content',
	},
	divider: {
		margin: theme.spacing(2, 0),
	},
}));

const CaseActivityContainer: React.FC<CaseActivityContainerProps> = ({ hasChanges, children }) => {
	const classes = useStyles({ hasChanges });

	return (
		<>
			<Box className={classes.root}>{children}</Box>
			<Divider variant="fullWidth" className={classes.divider} />
		</>
	);
};

export default CaseActivityContainer;
