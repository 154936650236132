import useCollection from '@utilities/hooks/useCollection/useCollection';
import { Unit } from '@data/unit/Unit';
import { ENDPOINT_USER_UNITS } from '@utilities/apiConstants';
import React from 'react';
import { useSelector } from 'react-redux';
import { UserProfilePrimaryPracticeSelector } from '@store/selectors/UserProfileSelector';

const useSupplementalInfoFromUnit = () => {
	const { id: practiceId, name: practiceName } = useSelector(UserProfilePrimaryPracticeSelector);

	const units = useCollection<Unit>(ENDPOINT_USER_UNITS);

	return React.useCallback((unitId: string) => {
		const { hospital: hospitalId = '', hospitalTimeZone = 'America/Chicago' } = units.find(u => u.id === unitId) || {};

		return ({
			practiceId,
			hospitalId,
			hospitalTimeZone,
			practiceName,
		});
	}, [practiceId, practiceName, units]);
};

export default useSupplementalInfoFromUnit;
