import React, { useContext, useState } from 'react';
import { ModalContextType } from '@components/Modal/ModalContextProviderType';

const ModalContext = React.createContext<ModalContextType | null>(null);

export const useModalContext = () => {
	const ctx = useContext(ModalContext);

	if (!ctx) {
		throw new Error('useModalContext must be used in subcomponent of ModalContextProvider');
	}

	return ctx;
};

export const ModalContextProvider: React.FC = ({ children }) => {
	const [modalOpen, setModalOpen] = useState<Record<string, boolean>>({});

	const isModalOpen = React.useCallback(
		(modalId: string) => modalOpen[modalId] ?? false,
		[modalOpen]
	);

	const openModal = React.useCallback((modalId: string) => {
		setModalOpen(prev => ({ ...prev, [modalId]: true }));
	}, [setModalOpen]);

	const closeModal = React.useCallback((modalId: string) => {
		setModalOpen(prev => ({ ...prev, [modalId]: false }));
	}, [setModalOpen]);

	return (
		<ModalContext.Provider value={{ isModalOpen, openModal, closeModal }}>
			{children}
		</ModalContext.Provider>
	);
};
