import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Duration } from '@components/RequestForm/inputs/Duration/Duration';
import { useFormContext } from 'react-hook-form';
import { FindATimeForm } from '@components/FindATime/FindATimeForm';
import { validators } from '@utilities/Validation/validators';

const useProcedureDurationStyles = makeStyles((theme) => ({
	instructionText: {
		padding: theme.spacing(1, 0, 0, 0),
		fontWeight: 500,
	}
}));

export const ProcedureDuration = () => {
	const classes = useProcedureDurationStyles();
	const { setValue } = useFormContext<FindATimeForm>();

	const handleChange =  (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		if (validators.numericValidator(e.target.value) === null) {
			setValue('duration', e.target.value);
		}
	};

	return (
		<>
			<Duration
				autoUpdate={true}
				label="Est. duration in minutes (setup, procedure & cleanup)"
				inputProps={{
					onChange: handleChange,
				}}
				dataField="findATimePage-searchSection-durationTextInput"
			/>
			<Typography variant="subtitle2" color="textSecondary" className={classes.instructionText}>
				Populated time is based on historical times of this surgeon and this procedure
			</Typography>
		</>
	);
};
