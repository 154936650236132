import React from 'react';
import { useDispatch } from 'react-redux';
import { getDocumentGetAction } from '@data/document/actionCreators/DocumentActionCreators';
import { AxiosResponse } from 'axios';

const useGetDocument = () => {
	const dispatch = useDispatch();

	return React.useCallback((id: string, onDocSuccess: (doc: Blob) => void) => {
		const onSuccess = ({ data }: AxiosResponse<Blob>) => onDocSuccess(data);
		dispatch(getDocumentGetAction({
			data: { id },
			onSuccess,
		}));
	}, [dispatch]);
};

export default useGetDocument;
