import React from 'react';
import { Flex, Box, Text } from '@ascension/web';
import usePageEffects from '@utilities/hooks/usePageEffects/usePageEffects';
import { makeStyles } from '@material-ui/core';
import { MY_FILTERS_SCROLLABLE_AREA } from '@utilities/constants';
import { useScrollHandler } from '@components/HeaderContext/HeaderContext';
import MyFiltersForm from '@components/AccountSettings/MyFilters/MyFiltersForm';
import MyFiltersButtons from '@components/AccountSettings/MyFilters/MyFiltersButtons';

const useStyles = makeStyles(() => ({
	resetButton: { cursor: 'pointer' },
	myFiltersCardBase: { marginBottom: '-40px', transition: 'all 0.5s ease', overflow: 'hidden', overflowY: 'scroll', height: '100%' }
}));

export default function MyFilters () {
	const { myFiltersCardBase } = useStyles();
	const handleScroll = useScrollHandler();

	React.useLayoutEffect(() => {
		return () => {
			handleScroll();
		};
	}, [handleScroll]);

	usePageEffects('My Filters');

	return (
		<Flex flexDirection="row" justifyContent="center" alignItems="start" height="100%" >
			<Box
				className={`${myFiltersCardBase} card`}
				width={1}
				ml={11}
				px={40}
				pt={40}
				borderRadius="4px"
				onScroll={handleScroll}
				data-field={MY_FILTERS_SCROLLABLE_AREA}
				flex="1 1 auto"
			>
				<Text fontSize={7} fontWeight="bold" color="#222222">
					My Filters
				</Text>
				<MyFiltersForm buttonsComponent={<MyFiltersButtons />}/>
			</Box>
		</Flex>
	);
}
