import { doEventsOverlap, EventInfo } from '@calendar';
import classNames from 'classnames';

const getUnavailableOverlapStyledEvents = (eventsList: EventInfo[], unavailableEvent: EventInfo, overlapClassName: string, unavailableOverlapClassName: string): EventInfo[] => {
	const styledEvents = eventsList.reduce<EventInfo[]>((events, event) => {
		if (doEventsOverlap(event, unavailableEvent)) {
			const existingAdditionalClassName = event.additionalClassName;
			return [
				...events,
				{
					...event,
					additionalClassName: existingAdditionalClassName?.length ? classNames(event.additionalClassName, overlapClassName) : overlapClassName,
				}
			];
		}
		return [...events, event];
	}, []);

	const styledUnavailableEvent = { ...unavailableEvent };
	if (eventsList.some(e => doEventsOverlap(e, unavailableEvent))) {
		styledUnavailableEvent.additionalClassName = unavailableEvent?.additionalClassName?.length ? classNames(unavailableEvent.additionalClassName, unavailableOverlapClassName) : unavailableOverlapClassName;
	}

	return [...styledEvents, styledUnavailableEvent];
};

export default getUnavailableOverlapStyledEvents;
