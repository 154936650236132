import React, { forwardRef } from 'react';
import { TemplateTileProps } from './TemplateTileProps';
import AbbreviatedProcedureCard from '../../../AbbreviatedProcedureCard/AbbreviatedProcedureCard';
import { AbbreviatedProcedureCardLayout } from '../../../AbbreviatedProcedureCard/AbbreviatedProcedureCardLayout';
import TemplateTileMenu from './TemplateTileMenu';
import { useSelector } from 'react-redux';
import { TemplateStatusSelectorCreator } from '../../../../store/selectors/TemplateStatusSelector';
import useHasPermission from '../../../../utilities/hooks/useHasPermission/useHasPermission';
import { PermittedAction } from '../../../../utilities/PermittedAction';
import { TemplateTagType } from './TemplateTagType';

/***
 * Component to display Template info. Intended for use in both My Templates (in settings with menu)
 * and the Add from Template (in Request Form without menu) components.
 * @param {TemplateInfoApi} info
 * @param {TemplateTileCallback} onClick - Makes entire tile hoverable/clickable. Menu will not display if set.
 * @param {TemplateTileCallback} onEditSelected - Edit in menu displays only if set.
 * @param {TemplateTileCallback} onDuplicateSelected - Duplicate in menu displays only if set.
 * @param {TemplateTileCallback} onDeleteSelected - Delete in menu displays only if set.
 * @param {React.Ref<HTMLDivElement>} ref - Reference to underlying div element
 * @constructor
 */
const TemplateTile = ({ info, onClick, onEditSelected, onDuplicateSelected, onDeleteSelected }: TemplateTileProps, ref: React.Ref<HTMLDivElement>) => {
	const isTemplateAdministration = !onClick;

	const tagType = useSelector(TemplateStatusSelectorCreator(info.id));
	const hasTemplateModifyPermission = useHasPermission(PermittedAction.MODIFY_TEMPLATE);

	const injectIdToCallback = React.useCallback((cb?: (id: string) => void) => {
		return cb && (() => cb(info.id));
	}, [info.id]);

	const lockedBy = isTemplateAdministration && info.lockStatus && info.lockStatus.locked ? `${info.lockStatus.firstName} ${info.lockStatus.lastName}` : undefined;

	const menu = isTemplateAdministration && hasTemplateModifyPermission &&
		(
			<TemplateTileMenu
				lockedBy={lockedBy}
				onEditSelected={injectIdToCallback(onEditSelected)}
				onDuplicateSelected={injectIdToCallback(onDuplicateSelected)}
				onDeleteSelected={injectIdToCallback(onDeleteSelected)}
			/>
		);

	const conditionalTagType = isTemplateAdministration ? tagType : TemplateTagType.NONE;

	return (
		<AbbreviatedProcedureCard
			info={info}
			tagType={conditionalTagType}
			layout={AbbreviatedProcedureCardLayout.TEMPLATE}
			menuComponent={menu}
			onClick={injectIdToCallback(onClick)}
			ref={ref}
		/>
	);
};

export default forwardRef(TemplateTile);
