import React from 'react';
import { Flex, Text } from '@ascension/web';
import { makeStyles } from '@material-ui/core';
import { SetupMyInformation } from './SetupMyInformation/SetupMyInformation';
import SetupPracticeInformation from './SetupPracticeInformation/SetupPracticeInformation';
import { useDispatch, useSelector } from 'react-redux';
import { UserProfileDetailsSelector, UserProfileHasARoleSelector } from '../../store/selectors/UserProfileSelector';
import { HospitalRoles } from '../../interfaces/UserProfile/UserRoleEnum';
import SetupMinistryInformation from './SetupMinistryInformation/SetupMinistryInformation';
import { SubmitButton } from './SubmitButton/SubmitButton';
import { useHistory, useLocation } from 'react-router-dom';
import { updateUserProfileStatus } from '../../store/actions/UserProfileActionCreators';
import { ToastType } from '../../interfaces/ToastStatus/ToastStatusState';
import { useToast } from '../../utilities/hooks/useToast/useToast';
import AccountLockedModal from './AccountLockModal/AccountLockedModal';
import { useInteractiveLogout } from '../../utilities/hooks/useInteractiveLogout/useInteractiveLogout';
import { UserDetailsSetupApiInterface } from '../../interfaces/UserPrefInterface';
import { SetupMyNotifications } from './SetupMyNotifications/SetupMyNotifications';
import { UserStatus } from '../../interfaces/UserProfile/UserStatusEnum';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { ModalContextProvider } from '@components/Modal/ModalContextProvider';
import ModalViewer from '@components/Modal/ModalViewer';
import { ACCOUNT_LOCK_MODAL } from '@components/Modal/modalConstants';
import { ROUTE_CONSTANTS } from '@utilities/RouteConstants';

const useStyles = makeStyles({
	container: {
		width: '640px',
		flexDirection: 'column',
		margin: 'auto',
	},
	titleStyle: {
		fontSize: '45px',
		marginTop: '32px',
		marginBottom: '12px'
	},
	errorTxt: {
		color: 'red',
		textAlign: 'right',
		fontSize: '12px',
		fontWeight: 'bold'
	}
});

const ProfileSetupPageInner = () => {
	const logout = useInteractiveLogout();
	const { container, errorTxt, titleStyle } = useStyles();
	const isHospitalRole = useSelector(UserProfileHasARoleSelector(HospitalRoles));
	const { status } = useSelector(UserProfileDetailsSelector);
	const { handleSubmit, getValues, formState: { errors } } = useFormContext();
	const [informationConfirmed, setInformationConfirmed] = React.useState(false);
	const [disableSubmit, setDisableSubmit] = React.useState(true);
	const [btnError, setBtnError] = React.useState(false);
	const submitErrorMsg = 'Server error, please try again later';
	const history = useHistory();
	const { state } = useLocation<Record<string, boolean>>();
	const { fromTermsOfUsePage } = state || {};
	const dispatch = useDispatch();
	const displayToast = useToast();
	const [isMyNotificationsChecked, setIsMyNotificationsChecked] = React.useState(false);

	const toggleNotificationCheckbox = () => {
		setIsMyNotificationsChecked((prevState) => !prevState);
	};

	const handleSubmitForm = React.useCallback(() => {
		const onSubmitSuccess = () => {
			setBtnError(false);
			history.push(ROUTE_CONSTANTS.SETUP_FILTERS, {
				disableClearToast: true
			});
			displayToast(ToastType.SUCCESS, 'Success, your account has been confirmed');
		};

		const onSubmitFailure = () => {
			setBtnError(true);
			displayToast(ToastType.ERROR, submitErrorMsg);
		};

		const {
			preferredEmail: preferredEmailCurrentValue,
			lastFourSsn: lastFourSsnCurrentValue,
			dobMonth: dobMonthCurrentValue,
			dobDay: dobDayCurrentValue
		} = getValues();
		let statusPayload: UserDetailsSetupApiInterface = {
			status: UserStatus.ACTIVE,
			preference: {
				emailNotification: isMyNotificationsChecked
			},
			email: preferredEmailCurrentValue as string,
			lastFourSsn: lastFourSsnCurrentValue as string,
			dobMonth: dobMonthCurrentValue as number,
			dobDay: dobDayCurrentValue as number,
		};
		if (fromTermsOfUsePage) {
			statusPayload = { ...statusPayload, termsOfUseAccepted: true };
		}

		dispatch(updateUserProfileStatus(statusPayload, onSubmitSuccess, onSubmitFailure));
	}, [dispatch, displayToast, getValues, isMyNotificationsChecked, history, fromTermsOfUsePage]);

	const preferredEmailError = React.useMemo(() => errors.preferredEmail?.message, [errors.preferredEmail]);
	React.useEffect(() => {
		if (!informationConfirmed || preferredEmailError) {
			setDisableSubmit(true);
		} else {
			setDisableSubmit(false);
		}
	}, [preferredEmailError, informationConfirmed]);

	return (
		<Flex className={container}>
			<Text className={titleStyle}>Review and Confirm</Text>
			<form onSubmit={handleSubmit(handleSubmitForm)}>
				<Flex flexDirection="column">
					<SetupMyInformation/>
					{ isHospitalRole ?
						<SetupMinistryInformation setInformationConfirmed={setInformationConfirmed}/> :
						<SetupPracticeInformation setInformationConfirmed={setInformationConfirmed}/>
					}
					<SetupMyNotifications data-field="setupMyNotifications" checked={isMyNotificationsChecked} onCheck={toggleNotificationCheckbox}/>
					<SubmitButton disabled={disableSubmit} data-field="submitProfile"/>
					{ btnError && <Text className={errorTxt} data-field="submitError">{submitErrorMsg}</Text> }
				</Flex>
			</form>
			{status === UserStatus.LOCK &&
			<ModalViewer id={ACCOUNT_LOCK_MODAL} onClose={logout}>
				<AccountLockedModal />
			</ModalViewer>
			}
		</Flex>
	);
};

interface ProfileSetupValidatedState {
	preferredEmail: string;
}

export const ProfileSetupPage = () => {
	const { email = '' } = useSelector(UserProfileDetailsSelector);
	const formMethods = useForm<ProfileSetupValidatedState>({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		criteriaMode: 'all',
		defaultValues: {
			preferredEmail: email
		}
	});

	return (
		<FormProvider {...formMethods}>
			<ModalContextProvider>
				<ProfileSetupPageInner data-field="profileSetupPageInner"/>
			</ModalContextProvider>
		</FormProvider>
	);
};
