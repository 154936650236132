import React  from 'react';
import useDeferredCollection from '@utilities/hooks/useDeferredCollection/useDeferredCollection';

/***
 * useCollection
 * @description
 * Retrieve full list of entities at collection endpoint. Will cancel update if updateCollection (or, because of
 * updateCollection dependencies, collectionEndpoint) is updated.
 *
 * @param collectionEndpoint {string}
 *  - ideally a non changing endpoint but will also work with endpoints that require an ID.
 *  - use the endpoint constant
 *
 * @example
 * 	const units = useCollection<Unit>(ENDPOINT_USER_UNITS);
 *
 * 			<Dropdown value={selectedUnitId} select={setSelectedUnitId}>
 * 				{units.map(({ id, hospitalName, name }) =>
 * 					<MenuItem key={id} value={id}>{hospitalName}, {name}</MenuItem>
 * 				)}
 * 			</Dropdown>
 */
const useCollection = <EntityType extends object>(collectionEndpoint: string): EntityType[] => {
	const [collection, updateCollection] = useDeferredCollection<EntityType>(collectionEndpoint);

	React.useEffect(() => {
		const abortController = updateCollection();
		return () => abortController.abort();
	}, [updateCollection]);

	return collection;
};

export default useCollection;
