import React from 'react';
import { Button } from '@ascension/ui-library';
import { makeStyles } from '@material-ui/core/styles';
import BlueOutlineButtonProps from './BlueOutlineButtonProps';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
	button: {
		borderColor: theme.palette.primary.main,
		color: theme.palette.primary.main,
		background: theme.palette.common.white,
	},
}));

const BlueOutlineButton: React.FC<BlueOutlineButtonProps> = ({ children, className, ...rest }) => {
	const classes = useStyles();
	return (
		<Button
			translate="yes"
			variant="outlined"
			className={classNames(classes.button, className)}
			{...rest}
		>
			{children}
		</Button>
	);
};

export default BlueOutlineButton;
