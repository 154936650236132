import React, { useCallback } from 'react';
import { FieldValues, useController } from 'react-hook-form';
import { IconButton, InputAdornment, OutlinedInput, OutlinedInputProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, DatePicker, DatePickerProps } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import { isValid, parse } from 'date-fns';

import { LocalizedUtils } from '@components/CalendarDayNavigation/CalendarDayUtils';
import { BaseStatefulInputProps } from '@components/StatefulInputs/Base/BaseStatefulInputProps';
import { StandardInputWrapper } from '@components/StatefulInputs/Base/StandardInputWrapper/StandardInputWrapper';
import { useMask } from '@utilities/hooks/useMask/useMask';
import { dateToString } from '@utilities/dateUtilities';

const useStyles = makeStyles((theme) => ({
	iconButton: {
		'&.MuiIconButton-root': {
			color: theme.palette.primary.main,
		},
		'.Mui-error &.MuiIconButton-root': {
			color: theme.palette.error.main,
		},
		'&.Mui-disabled': {
			color: theme.palette.grey[500],
		},
	},
}));

function isValidDateFormat(dateString: string) {
	const regex = /^([0-1]?[0-9])\s*\/\s*([0-3]?[0-9])\s*\/\s*([0-9]{4})$/;
	return regex.test(dateString);
}

interface StatefulDatePickerProps<FormType extends FieldValues>
	extends BaseStatefulInputProps<FormType> {
	datePickerProps?: DatePickerProps;
	textFieldProps?: OutlinedInputProps;
	disabled?: boolean;
	placeholder?: string;
}

export function StatefulDatePicker<FormType extends FieldValues>({
	controllerOptions,
	datePickerProps,
	textFieldProps,
	...statefulProviderProps
}: StatefulDatePickerProps<FormType>) {
	const classes = useStyles();
	const { field } = useController<FormType>({
		name: statefulProviderProps.name,
		...controllerOptions,
	});

	const {
		mask,
		value: maskedDate,
		updateValue: updateMaskedDate,
	} = useMask('date', dateToString(controllerOptions?.defaultValue));
	const dateFieldRef = React.useRef(null);
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
	const placeholderValue = 'placeholder' in statefulProviderProps ? statefulProviderProps.placeholder : mask.placeholder;
	const isDisabled = 'disabled' in statefulProviderProps ? statefulProviderProps.disabled : controllerOptions.disabled;

	// Without this useEffect, the initial appearance of the date is MM/DD/YYYY instead of MM / DD / YYYY
	React.useEffect(() => {
		const formattedDate = dateToString(field.value || controllerOptions?.defaultValue);
		updateMaskedDate(formattedDate || '');
	}, [updateMaskedDate, controllerOptions?.defaultValue, field.value]);

	const handleOpenDatePicker = () => {
		setAnchorEl(dateFieldRef.current);
	};

	const handleCloseDatePicker = () => setAnchorEl(null);

	const handleDateChange = (e: MaterialUiPickersDate) => {
		handleCloseDatePicker();
		field.onChange(e);
		field.onBlur();
		updateMaskedDate(dateToString(e as Date));
	};

	const handleTextFieldChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const { value } = e.target;
			updateMaskedDate(value);
			if (value && isValidDateFormat(value)) {
				const parsedDate = parse(value.replace(/\s+/g, ''), 'MM/dd/yyyy', new Date());
				if (isValid(parsedDate)) {
					field.onChange(parsedDate);
				}
			}
		},
		[updateMaskedDate, field]
	);

	return (
		<StandardInputWrapper<FormType>
			{...statefulProviderProps}
			labelProps={{
				id: `datePicker-label-${statefulProviderProps.name}`,
				shrink: true,
				htmlFor: `datePicker-field-${statefulProviderProps.name}`,
			}}
			analyticsInputName="datePicker"
		>
			<OutlinedInput
				{...field}
				{...textFieldProps}
				id={`datePicker-field-${statefulProviderProps.name}`}
				inputRef={dateFieldRef}
				value={maskedDate}
				onChange={handleTextFieldChange}
				placeholder={placeholderValue}
				endAdornment={
					<InputAdornment position="end">
						<IconButton
							onClick={handleOpenDatePicker}
							disabled={isDisabled}
							className={classes.iconButton}
						>
							<CalendarTodayOutlinedIcon />
						</IconButton>
					</InputAdornment>
				}
				disabled={isDisabled}
			/>

			<MuiPickersUtilsProvider utils={LocalizedUtils}>
				<DatePicker
					{...datePickerProps}
					value={field.value}
					onChange={handleDateChange}
					open={!!anchorEl}
					onClose={handleCloseDatePicker}
					name={statefulProviderProps.name}
					variant="inline"
					disableToolbar
					TextFieldComponent={() => null}
					PopoverProps={{
						anchorEl: anchorEl,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},
						transformOrigin: {
							vertical: 'bottom',
							horizontal: 'left',
						},
					}}
				/>
			</MuiPickersUtilsProvider>
		</StandardInputWrapper>
	);
}
