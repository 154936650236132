import React, { MutableRefObject, useContext } from 'react';
import {
	AidEnvironment,
	AidSignInPolicy,
	AuthCoreConfig,
	useAuthContext,
	AccessTokenValidationOptions,
	useIsLoggedIn,
	WithAuthentication,
} from '@ascension/ascensionid-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { StorageKey } from '@utilities/authConstants';
import { requireAuthentication, setAuthenticationLoading, setToken } from '@store/actions/AuthActionCreators';
import { useLocation } from 'react-router-dom';
import { AuthSelector } from '@store/selectors/AuthSelector';
import { AuthStatus } from '@interfaces/Auth/AuthStatus';
import { useInteractiveLogout } from '@utilities/hooks/useInteractiveLogout/useInteractiveLogout';
import usePendoInitialization from '@utilities/pendo/usePendoInitialization';
import { ROUTE_CONSTANTS } from '@utilities/RouteConstants';
import { useAmplitude } from '@utilities/analytics/useAmplitude/useAmplitude';

const ResetIdleEvents: Array<keyof DocumentEventMap> = [
	'mousedown',
	'click',
	'keypress',
	'scroll',
	'mousemove',
	'visibilitychange',
];

const ascensionIDConfig: AuthCoreConfig = {
	aidEnvironment: window.config.ASCENSION_ID_ENVIRONMENT as AidEnvironment,
	clientId: window.config.ASCENSION_ID_APPLICATION_ID,
	scopes: ['dssc-schedule-api/app.access'],
	redirectUri: `${window.location.origin}/`,
	postLogoutRedirectUri: `${window.location.origin}/login`,
	msalConfiguration: {
		cache: {
			cacheLocation: 'sessionStorage',
		},
	},
	signInPolicy: (window.config.SIGN_IN_POLICY || 'B2C_1A_SIGNIN_AAD') as AidSignInPolicy,
};

interface AuthBaseContextType {
	isAutoLogout: boolean;
	navToRef: MutableRefObject<Partial<Location>>;
}
const AuthBaseContext = React.createContext<AuthBaseContextType | null>(null);

const accessTokenValidationOptions: AccessTokenValidationOptions = {
	ignoreNotBefore: true,
};

export const useAuthBaseContext = (): AuthBaseContextType => {
	const context = useContext(AuthBaseContext);

	if (!context) {
		throw new Error('useAuthBaseContext must be used within AuthBase.');
	}

	return context;
};

const AuthBaseInner: React.FC = ({ children }) => {
	const dispatch = useDispatch();
	const isLoggedIn = useIsLoggedIn();
	const { authStatus } = useSelector(AuthSelector);
	const { authResponse, resetIdleTimer, addOnSecondsIdleListener } = useAuthContext();
	const { hash } = useLocation();
	const [isLoggingIn, setIsLoggingIn] = React.useState(true);
	const logout = useInteractiveLogout();
	const navToRef = React.useRef<Partial<Location>>({ pathname: ROUTE_CONSTANTS.HOME });
	const [isAutoLogout, setIsAutoLogout] = React.useState(false);

	React.useEffect(() => {
		const hashParams = new URLSearchParams((hash || '').replace(/^#/, '?'));
		setIsLoggingIn(hashParams.has('state'));
	}, [hash]);

	React.useEffect(() => {
		if (isLoggingIn) {
			dispatch(setAuthenticationLoading());
			return;
		}
		dispatch(authResponse && authResponse.accessToken ? setToken(authResponse.accessToken) : requireAuthentication());
		const newNavToRef = window.sessionStorage.getItem(StorageKey.NAV_TO) || (
			authResponse && authResponse.accessToken && authResponse.state
		);
		if (!newNavToRef) { return; }
		navToRef.current =  JSON.parse(atob(newNavToRef)) as Partial<Location>;
	}, [authResponse, dispatch, isLoggingIn]);

	React.useEffect(() => {
		if (isLoggedIn && authStatus === AuthStatus.AUTHENTICATION_REQUIRED) {
			logout();
		}
	}, [authStatus, logout, isLoggedIn]);

	const checkAutoLogout = React.useCallback(() => {
		setIsAutoLogout(true);
	}, [setIsAutoLogout]);

	React.useEffect(() => {
		const idleTimeInSeconds = 15 * 60;
		addOnSecondsIdleListener(idleTimeInSeconds, checkAutoLogout);
	}, [addOnSecondsIdleListener, checkAutoLogout]);

	React.useEffect(() => {
		ResetIdleEvents?.forEach((eventName) => {
			document.addEventListener(eventName, resetIdleTimer);
		});

		return () => {
			ResetIdleEvents?.forEach((eventName) => {
				document.removeEventListener(eventName, resetIdleTimer);
			});
		};
	}, [resetIdleTimer]);

	return <AuthBaseContext.Provider value={{
		isAutoLogout,
		navToRef,
	}}>{children}</AuthBaseContext.Provider>;
};

const AuthBase: React.FC = ({ children }) => {
	usePendoInitialization();
	useAmplitude();

	return <WithAuthentication
		config={ascensionIDConfig}
		attemptSilentSsoOnLoad={true}
		logoutAfterIdleMinutes={15}
		refreshTokenBufferSeconds={120}
		resetIdleEvents={ResetIdleEvents}
		accessTokenValidationOptions={accessTokenValidationOptions}
	>
		<AuthBaseInner>
			{children}
		</AuthBaseInner>
	</WithAuthentication>;
};

export default AuthBase;
