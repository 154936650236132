export enum PreAuthOptionsEnum {
	UNDEFINED = 0,
	YES = 1,
	NO = 2,
	NOT_APPLICABLE = 3,
}

export interface InsuranceApi {
	family: string;
	groupNumber: string;
	name: string;
	planName: string;
	policyNumber: string;
	preAuth: PreAuthOptionsEnum;
	preCert: string;
	primary: boolean;
}
