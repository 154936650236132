import React from 'react';
import { useFormContext } from 'react-hook-form';
import useRefreshHoldEvent from '@components/RequestForm/utilities/hooks/useRefereshHoldEvent/useRefreshHoldEvent';
import useDeleteHoldEvent from '@data/holdEvent/hooks/useDeleteHoldEvent/useDeleteHoldEvent';
import { AxiosError, AxiosResponse } from 'axios';
import HoldEventDetailsRequest from '@data/holdEvent/types/HoldEventDetailsRequest';
import { ENDPOINT_UPDATE_DELETE_HOLD_EVENT } from '@utilities/apiConstants';
import { RequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';

const REFRESH_INTERVAL_MINS = 4.5;
const FAILURE_MAX_ATTEMPTS = 4;
const FAILURE_MAX_INITIAL_RETRY_SECONDS = 5;

const REFRESH_INTERVAL_MS = REFRESH_INTERVAL_MINS * 60 * 1000;
const FAILURE_MAX_RETRY_MS = FAILURE_MAX_INITIAL_RETRY_SECONDS * 1000;

const URL_ID_REGEX = new RegExp(ENDPOINT_UPDATE_DELETE_HOLD_EVENT.replace(/\{[a-zA-Z]+}$/, '([0-9a-z]+)'));
const getIdFromUrl = (url?: string) => {
	if (!url) { return; }
	const match = URL_ID_REGEX.exec(url);
	if (!match) { return; }
	return match[1];
};

const useAutoMaintainHoldEvent = () => {
	const { watch } = useFormContext<RequestFormValidatedState>();
	const [ holdEventId ] = watch(['holdEventId']);
	const holdEventIdRef = React.useRef(holdEventId);

	const timeoutRef = React.useRef<ReturnType<typeof setTimeout> | undefined>();

	const update = useRefreshHoldEvent();

	const stopInterval = React.useCallback(() => {
		timeoutRef.current && clearTimeout(timeoutRef.current);
	}, []);

	const failureCountRef = React.useRef(0);
	// call update() to PUT the same event to restart API timer
	const autoUpdater = React.useCallback(() => {
		// Start timer for next call on success of this attempt
		const onSuccess = ({ data: { id } }: AxiosResponse<HoldEventDetailsRequest>) => {
			failureCountRef.current = 0;
			// Short circuit when API responds after new ID set or cleared
			if (id !== holdEventIdRef.current) { return; }
			timeoutRef.current = setTimeout(() => {
				// Short circuit when timeout occurs after new ID set or cleared. clearTimeout should also address this.
				if (id !== holdEventIdRef.current) { return; }
				autoUpdater();
			}, REFRESH_INTERVAL_MS);
		};
		// Start timer for next call on success of this attempt
		const onFailure = ({ config }: AxiosError) => {
			const { url } = config || {};
			const idFromUrl = getIdFromUrl(url);
			// Short circuit when API responds after new ID set or cleared
			if (idFromUrl !== holdEventIdRef.current) { return; }

			failureCountRef.current++;
			if (failureCountRef.current > FAILURE_MAX_ATTEMPTS) { return; }

			const retryInMs = FAILURE_MAX_RETRY_MS * failureCountRef.current; // backs off longer between each attempt

			timeoutRef.current = setTimeout(() => {
				// Short circuit when timeout occurs after new ID set or cleared. clearTimeout should also address this.
				if (idFromUrl !== holdEventIdRef.current) { return; }
				autoUpdater();
			}, retryInMs);
		};

		update({ onSuccess, onFailure, shouldDisableLoadIndicator: true });
	}, [update]);

	React.useEffect(() => {
		holdEventIdRef.current = holdEventId;
		failureCountRef.current = 0;

		if (!holdEventId) { return; }

		// POST completed already, wait for PUT refresh
		timeoutRef.current = setTimeout(() => autoUpdater(), REFRESH_INTERVAL_MS);

		// Clear previous timeout on unmount/remount
		return stopInterval;
	}, [stopInterval, holdEventId, autoUpdater]);

	const deleteHoldEvent = useDeleteHoldEvent();

	React.useEffect(() => {
		const cleanup = () => {
			if (!holdEventIdRef.current) {
				return;
			}
			deleteHoldEvent(holdEventIdRef.current);
		};

		window.addEventListener('beforeunload', cleanup);

		// Remove event on unload if holdEventId exists
		return () => {
			window.removeEventListener('beforeunload', cleanup);

			cleanup();
		};
	}, [deleteHoldEvent]);

	return { stopInterval };
};

export default useAutoMaintainHoldEvent;
