import React from 'react';
import { DisplayText } from '../../DisplayText/DisplayText';
import { InsuranceInformationProps } from './InsuranceInformationProps';
import { YesNoAnswer } from '../../../interfaces/SurgeryRequest/YesNoAnswer';
import useDetailSectionGrid, { InsuranceGridFields } from '../useDetailSectionGrid/useDetailSectionGrid';
import DetailSection from '../DetailSection';
import { PreAuthOptionsEnum } from '@interfaces/SurgeryRequest/InsuranceApi';

const convertPreAuthToDisplayValue = (value: PreAuthOptionsEnum | boolean): string | undefined => {
	switch (value) {
	case PreAuthOptionsEnum.YES:
	case true:
		return YesNoAnswer.YES;
	case PreAuthOptionsEnum.NO:
	case false:
		return YesNoAnswer.NO;
	case PreAuthOptionsEnum.NOT_APPLICABLE:
		return 'N/A';
	}
};

export const InsuranceInformationDisplay = ({ insurance }: InsuranceInformationProps) => {
	const { gridContainer } = useDetailSectionGrid(DetailSection.PRIMARY_INSURANCE);

	return insurance ? (
		<div className={gridContainer}>
			<DisplayText
				label="HEALTH PLAN NAME"
				defaultValue="--"
				displayDefault={!insurance.planName}
				data-field="Plan_Name"
				style={{ gridArea: InsuranceGridFields.PLAN_NAME }}
			>
				{insurance.planName}
			</DisplayText>
			<DisplayText
				label="INSURED"
				defaultValue="--"
				displayDefault={!insurance.name}
				data-field="Insured_Name"
				style={{ gridArea: InsuranceGridFields.INSURED_NAME }}
			>
				{insurance.name + ' ' + insurance.family}
			</DisplayText>
			<DisplayText
				label="POLICY"
				defaultValue="--"
				displayDefault={!insurance.policyNumber}
				data-field="Policy_Number"
				style={{ gridArea: InsuranceGridFields.POLICY_NUM }}
			>
				{insurance.policyNumber}
			</DisplayText>
			<DisplayText
				label="GROUP"
				defaultValue="--"
				displayDefault={!insurance.groupNumber}
				data-field="Group_Number"
				style={{ gridArea: InsuranceGridFields.GROUP_NUM }}
			>
				{insurance.groupNumber}
			</DisplayText>
			<DisplayText
				label="PRE-AUTH IN PROGRESS?"
				defaultValue="--"
				displayDefault={insurance.preAuth === undefined || insurance.preAuth === PreAuthOptionsEnum.UNDEFINED}
				data-field="Patient_HPN"
				style={{ gridArea: InsuranceGridFields.PRE_AUTH }}
			>
				{convertPreAuthToDisplayValue(insurance.preAuth)}
			</DisplayText>
			<DisplayText
				label="PRE-CERT NUMBER"
				defaultValue="--"
				displayDefault={!insurance.preCert}
				data-field="Precert_Number"
				style={{ gridArea: InsuranceGridFields.PRE_CERT }}
			>
				{insurance.preCert}
			</DisplayText>
		</div>
	) : (<></>);
};
