import React from 'react';
import { Popover } from '@material-ui/core';
import { Box, Flex, Text } from '@ascension/web';
import { DisplayText } from '@components/DisplayText/DisplayText';
import { makeStyles } from '@material-ui/core/styles';
import { EventInfo } from '@calendar';
import {
	useUnitCalendarPageContext
} from '@components/UnitCalendarPage/UnitCalendarPageContext/UnitCalendarPageContext';
import OREventType from '@data/openCalendar/OREventType';
import CloseIcon from '@material-ui/icons/Close';
import { format } from 'date-fns';
import { useClickCoordinate } from '@components/ClickCoordinateBox/ClickCoordinateBox';
import DEFAULT_SELECTED_EVENT from '@components/UnitCalendarPage/UnitCalendarPageContext/DefaultSelectedEvent';
import useClickAway from '@utilities/hooks/useClickAway/useClickAway';
import GoldButton from '@components/GoldButton/GoldButton';
import { useFormContext } from 'react-hook-form';
import ConfirmDialog
	from '@components/ConfirmDialog/ConfirmDialog';
import UnavailableEventFormType from '@components/UnitCalendarPage/utilities/UnavailableEventFormType';
import { formatInTimeZone } from 'date-fns-tz';
import { TIME_ONLY_FORMAT, DATE_ONLY_FORMATTER } from '@utilities/constants';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import useDeleteUnavailableEvent from '@data/openCalendar/useDeleteUnavailableEvent';

const useStyles = makeStyles(theme => ({
	popover: {
		'pointerEvents': 'none',
		'& .MuiPaper-root': {
			pointerEvents: 'all',
		},
	},
	card: {
		boxSizing: 'border-box',
		margin: theme.spacing(3),
	},
	closeIcon: {
		'fill': theme.palette.grey[600],
		'&:hover': {
			cursor: 'pointer',
		},
	},
	actionButtons: {
		display: 'flex',
		justifyContent:'space-between',
		alignItems: 'center',
		flexDirection: 'row',
	},
	infoSection: {
		display: 'flex',
		flexDirection: 'column',
		marginBottom: theme.spacing(3),
		marginTop: theme.spacing(3),
	},
	editButton: {
		width: '25%',
	},
	deleteIcon: {
		fill: theme.palette.primary.main,
		marginRight: theme.spacing(2),
		cursor: 'pointer',
	},
}));

const componentDefaultValues: EventInfo = {
	id: '',
	owner: '',
	title: '',
	subTitle: '',
	start: new Date(),
	end: new Date(),
	column: '',
	type: '',
	additionalClassName: undefined,
	timeSpanDisplay: '',
};

const useEditUnavailableFormReset = (handleClose: { (): void }) => {
	const {
		rooms,
		selectedEventDescription: {
			eventInfo = componentDefaultValues,
		},
		selectedUnit: {
			hospital: hospitalId,
			id: unitId,
		},
		setSelectedRoom,
		unitConfig: { hospitalTimeZone },
	} = useUnitCalendarPageContext();
	const { reset } = useFormContext();

	return React.useCallback(() => {
		setSelectedRoom(eventInfo?.column);
		const roomId = rooms[eventInfo?.column];
		const unavailableEventFormData: UnavailableEventFormType = {
			date: new Date(formatInTimeZone(eventInfo?.start, hospitalTimeZone, DATE_ONLY_FORMATTER)),
			description: eventInfo?.subTitle,
			timeSpan: {
				start: formatInTimeZone(eventInfo?.start, hospitalTimeZone, TIME_ONLY_FORMAT),
				end: formatInTimeZone(eventInfo?.end, hospitalTimeZone, TIME_ONLY_FORMAT),
			},
			roomId,
			unitId,
			hospitalId,
			id: eventInfo?.id
		};

		reset(unavailableEventFormData);

		handleClose();
	}, [eventInfo, handleClose, hospitalId, hospitalTimeZone, reset, rooms, setSelectedRoom, unitId]);
};

const UnavailableEventCardDetailsPopover: React.FC = () => {
	const classes = useStyles();
	const [deleteConfirmOpen, setDeleteConfirmOpen] = React.useState<boolean>(false);
	const { coordinates, boxWidth } = useClickCoordinate();
	const calendarWidthExceeded = boxWidth / 2 >= coordinates[0];
	const {
		selectedEventDescription: {
			eventInfo = componentDefaultValues,
			elmCard
		},
		selectedUnit: {
			hospitalName,
			name
		},
		selectedDate,
		setSelectedEventDescription,
	} = useUnitCalendarPageContext();
	const { deleteUnavailableEvent } = useDeleteUnavailableEvent();
	const unavailablePopoverOpen = eventInfo && 'type' in eventInfo && eventInfo.type === OREventType.UNAVAILABLE.toString() && Boolean(elmCard);
	const handleClose = React.useCallback(() => {
		if (!deleteConfirmOpen) {
			setSelectedEventDescription((current) => {
				return current.eventInfo ? DEFAULT_SELECTED_EVENT : current;
			});
		}
	}, [deleteConfirmOpen, setSelectedEventDescription]);
	const confirmDeleteContent = 'Are you sure you want to delete this event?';
	const confirmDeleteText = 'Delete Event';

	const handleEditClick = useEditUnavailableFormReset(handleClose);

	const { ref: clickAwayElmRef, mountEventListener, dismountEventListener } = useClickAway(handleClose, true);

	const handleOpenDeleteConfirm = React.useCallback(() => {
		setDeleteConfirmOpen(true);
	}, []);

	const handleCloseDeleteConfirm = React.useCallback(() => {
		setTimeout(() => setDeleteConfirmOpen(false), 0);
	}, []);

	const handleDeleteConfirm = React.useCallback(() => {
		deleteUnavailableEvent();
		handleCloseDeleteConfirm();
	}, [deleteUnavailableEvent, handleCloseDeleteConfirm]);

	React.useLayoutEffect(() => {
		eventInfo.type && mountEventListener();
		return dismountEventListener;
	}, [eventInfo, dismountEventListener, mountEventListener]);
	const formattedDate = eventInfo.dateCreated ? format(eventInfo.dateCreated, 'M/d/y h:mm aa') : '';
	const leftCoords = calendarWidthExceeded ? coordinates[0] + 30 : coordinates[0] - 30;
	const transformPopoverDirection = calendarWidthExceeded ? 'left' : 'right';
	return (
		<Popover
			open={unavailablePopoverOpen}
			onClose={handleClose}
			anchorOrigin={{
				vertical: 40,
				horizontal: 120,
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: transformPopoverDirection,
			}}
			anchorReference="anchorPosition"
			anchorPosition={{ top: coordinates[1], left: leftCoords }}
			anchorEl={elmCard}
			className={classes.popover}
		>
			<div className={classes.card} ref={clickAwayElmRef}>

				<Flex justifyContent="space-between" flexDirection="row">
					<Box>
						<Text fontSize={20} fontWeight="bold" color="black">OR Unavailable</Text>
					</Box>
					<Box className={classes.actionButtons}>
						<DeleteIcon
							className={classes.deleteIcon}
							data-field="unitCalendarPage-eventCard-unavailableEventCardDetailsPopover-deleteOpenBtn"
							onClick={handleOpenDeleteConfirm}
							fontSize="medium"
						/>
						<CloseIcon onClick={handleClose} className={classes.closeIcon} data-field="closeIcon"/>
					</Box>
				</Flex>

				<Flex flexDirection="column">
					<Box>
						<DisplayText
							className={classes.infoSection}
							label="Created By"
							defaultValue="--"
							displayDefault={!eventInfo.owner}
						>
							<Text>{`${eventInfo.owner || '--'}${formattedDate ? `, ${formattedDate}` : ''}`}</Text>
						</DisplayText>
						<DisplayText
							className={classes.infoSection}
							label="Location"
							defaultValue="--"
							displayDefault={!eventInfo.column}
						>
							<Text>{`${hospitalName} ${name}, ${eventInfo.column}`}</Text>
						</DisplayText>
						<DisplayText
							className={classes.infoSection}
							label="Date & Time"
							defaultValue="--"
							displayDefault={!eventInfo.timeSpanDisplay}
						>
							<Text>{`${format(selectedDate, 'EEEE, MMMM d,' )} ${eventInfo.timeSpanDisplay}`}</Text>
						</DisplayText>
						<DisplayText
							className={classes.infoSection}
							label="Description"
							defaultValue="--"
							displayDefault={!eventInfo.subTitle}
						>
							<Text>{eventInfo.subTitle}</Text>
						</DisplayText>
					</Box>
				</Flex>

				<Flex flexDirection="row"  justifyContent="flex-end">
					<GoldButton
						className={classes.editButton}
						onClick={handleEditClick}
						data-field="unitCalendarPage-eventCard-unavailableEventCardDetailsPopover-editBtn"
					>
						Edit
					</GoldButton>
				</Flex>

				<ConfirmDialog
					buttonText={confirmDeleteText}
					content={confirmDeleteContent}
					onClose={handleCloseDeleteConfirm}
					onConfirm={handleDeleteConfirm}
					open={deleteConfirmOpen}
					title={confirmDeleteText}
				/>
			</div>
		</Popover>
	);
};

export default UnavailableEventCardDetailsPopover;
