import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, ButtonProps } from '@ascension/ui-library';
import { createStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => createStyles({
	root: {
		marginTop: theme.spacing(2),
	},
}));

const CalendarErrorButton: React.FC<ButtonProps> = ({ children, ...buttonProps }) => {
	const classes = useStyles();

	return (
		<Button
			translate="yes"
			variant="outlined"
			size="large"
			color="white"
			className={classes.root}
			{...buttonProps}
		>
			{children}
		</Button>
	);
};

export default CalendarErrorButton;
