import { Box } from '@ascension/web';
import React from 'react';
import { FormComponentProps } from './FormComponentProps';
import useFormFieldSchema from './useFormFieldSchema';
import { FieldPath, FieldValues } from 'react-hook-form';
import TextFieldInput from '@components/TextFieldInput/TextFieldInput';

function FormTextField<T extends FieldValues, Name extends(FieldPath<T>) = FieldPath<T>>({
	name,
	label,
	subLabel,
	placeholder,
	disabled,
	...rest
}: FormComponentProps<T>) {
	const {
		currentValue,
		updateValue,
		error,
		isRequired,
	} = useFormFieldSchema<T>(name);

	const handleChange = (value: string) => {
		const castedValue = value as T[Extract<keyof T, Name>];
		updateValue(castedValue);
	};

	return (
		<Box mt={4} data-field={name} {...rest}>
			<TextFieldInput
				label={label}
				subLabel={subLabel}
				name={name}
				fieldName={name}
				required={isRequired}
				placeholder={placeholder}
				errorMessage={error}
				onChange={handleChange}
				value={currentValue}
				disabled={disabled}
			/>
		</Box>
	);
}

export default FormTextField;
