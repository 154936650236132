import React from 'react';
import { ManagedByMessage } from '@components/ManagedByMessage/ManagedByMessage';
import { PermittedAction } from '@utilities/PermittedAction';
import useGetAccessor from '@components/CaseDetails/CaseInUse/hooks/useGetAccessor/useGetAccessor';
import { UserAccessEnum } from '@interfaces/SurgeryRequest/UserAccessEnum';

export const IndexInUseMessage = () => {
	const { blockerName: currentlyInUseBy } = useGetAccessor([UserAccessEnum.EDIT_INDEX, UserAccessEnum.CONFIRMER], PermittedAction.EDIT_INDEX);

	return (
		<ManagedByMessage managedByName={currentlyInUseBy}/>
	);
};
