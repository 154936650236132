import React from 'react';
import { useDispatch } from 'react-redux';
import { UserRegistration } from '../../UserRegistrationPage/UserRegistration';
import endpointTemplateToUrl from '../../../utilities/endpointTemplateToUrl';
import { apiAction } from '../../../store/middlewares/api/ApiActionCreators';
import { CacheType } from '../../../interfaces/CacheType';
import { ApiActionPayload } from '../../../interfaces/ApiAction/ApiActionPayload';
import ApiEndpoint from '../../../utilities/apiEndpoints/ApiEndpoint';
import apiEndpointConfig from '../../../utilities/apiEndpoints/apiEndpointConfig';
import ApiRestMethod from '../../../utilities/apiEndpoints/ApiRestMethod';
import ApiService from '../../../utilities/apiEndpoints/ApiService';
import { useToast } from '../../../utilities/hooks/useToast/useToast';
import { ToastType } from '../../../interfaces/ToastStatus/ToastStatusState';
import { ToastConstants } from '../../../utilities/toastConstants';
import { useHistory } from 'react-router-dom';
import useGetEditUserIdFromPathname from './useGetEditUserIdFromPathname';
import { UserStatus } from '../../../interfaces/UserProfile/UserStatusEnum';
import { ROUTE_CONSTANTS } from '@utilities/RouteConstants';

const editUserActionCreator = (userId: string, userInfo: UserRegistration, apiActionRequest: Partial<ApiActionPayload> & Pick<ApiActionPayload, 'onSuccess'>) => {
	const url = endpointTemplateToUrl(apiEndpointConfig[ApiEndpoint.EDIT_USER_REGISTRATION].path, { userId });

	return apiAction({
		url,
		method: ApiRestMethod.PUT,
		data: { ...userInfo, status: UserStatus.PRE_REGISTERED },
		cachePolicy: CacheType.NO_CACHE,
		includeAccessToken: true,
		shouldDisableErrorHandler: false,
		label: ApiEndpoint.EDIT_USER_REGISTRATION,
		apiService: ApiService.USER,
		...apiActionRequest,
	});
};

const useEditUserRegistration = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const userId = useGetEditUserIdFromPathname() || '';
	const showToast = useToast();

	const editUserRegistration = React.useCallback((userInfo: UserRegistration) => {
		const onSuccess = () => {
			history.push(ROUTE_CONSTANTS.MANAGE_USERS);
			showToast(ToastType.SUCCESS, ToastConstants.EDIT_REGISTRATION_SUCCESS);
		};

		dispatch(editUserActionCreator(userId, userInfo, {
			onSuccess,
		}));
	}, [dispatch, showToast, userId, history]);

	return { editUserRegistration };
};

export default useEditUserRegistration;
