import { makeStyles } from '@material-ui/core/styles';

const useSelectedEventStyles = makeStyles(theme => ({
	selected: {
		'&&': {
			zIndex: 550,
			boxShadow: theme.shadows[24],
		},
	},
	unavailableOverlap: {
		'&&': {
			width: '75%',
			marginRight: 'auto',
		},
	},
	overlap: {
		'&&': {
			width: '45%',
			marginLeft: 'auto',
		},
	},
}));

export default useSelectedEventStyles;
