import { RoleOptionDescriptor } from './RoleOptionDescriptorInterface';
import MyInformation from '../MyInformation/MyInformation';
import { FunctionComponent } from 'react';
import MyFilters from '../MyFilters/MyFilters';
import MyNotifications from '../MyNotifications/MyNotifications';

export const HOSPITAL_OPTIONS: RoleOptionDescriptor[] = [
	{
		path: '/accountSettings/myInformation',
		linkText: 'My Information',
		card: MyInformation as FunctionComponent,
		id: 'myInformation',
	},
	{
		path: '/accountSettings/myNotifications',
		linkText: 'My Notifications',
		card: MyNotifications as FunctionComponent,
		id: 'myNotifications',
	},
	{
		path: '/accountSettings/myFilters',
		linkText: 'My Filters',
		card: MyFilters as FunctionComponent,
		id: 'myFilters'
	}
];
