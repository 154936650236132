import React, { ReactNode } from 'react';
import { Box, Flex, Text, TextLink } from '@ascension/web';
import { AlertDisplayField } from '@components/Alerts/AlertDisplayField/AlertDisplayField';
import { AlertBody } from '@components/Alerts/AlertBody/AlertBody';
import { useDispatch, useSelector } from 'react-redux';
import { CaseDetailsInfoSelector } from '@store/selectors/CaseDetailsSelector';
import { RootState } from '@interfaces/rootState';
import { SurgeryRequestGet } from '@interfaces/SurgeryRequest/SurgeryRequestGet';
import { approvePendingCancellation } from '@store/actions/CancellationAlertsActionCreators';
import { utcToLocalTime } from '@utilities/dateUtilities';
import useHasPermission from '@utilities/hooks/useHasPermission/useHasPermission';
import { PermittedAction } from '@utilities/PermittedAction';
import { UserAccessEnum } from '@interfaces/SurgeryRequest/UserAccessEnum';
import useGetAccessor from '@components/CaseDetails/CaseInUse/hooks/useGetAccessor/useGetAccessor';
import { defaultCallback } from '@utilities/defaultCallback';

export const PendingCancellationAlertBody = () => {
	const { isAccessorDifferentUser: caseInUse } = useGetAccessor([UserAccessEnum.CONFIRMER]);

	const {
		cancellation, id
	} = useSelector(CaseDetailsInfoSelector as (state: RootState) => SurgeryRequestGet);

	const convertedDate = React.useMemo(() => utcToLocalTime(cancellation.dateUpdated),
		[cancellation.dateUpdated]);

	const dispatch = useDispatch();

	const hasCancelCasePermission = useHasPermission(PermittedAction.CONFIRM_CANCEL_CASE);
	const PENDING_MASSAGE = 'Requested to cancel the procedure.';
	const handleResolved = React.useCallback(() => {
		dispatch(approvePendingCancellation(id));
	}, [dispatch, id]);

	const links: ReactNode | ReactNode[] = hasCancelCasePermission && (
		<TextLink inactive={caseInUse} onClick={caseInUse ? defaultCallback : handleResolved}>Confirm</TextLink>
	);

	return (
		<AlertBody
			alertUser={cancellation.userUpdated.firstName + ' ' + cancellation.userUpdated.lastName}
			alertTime={convertedDate} alertMessage={PENDING_MASSAGE}
			body={<Flex py={3} data-section="cancellationInfo">
				<AlertDisplayField
					label="Details"
				>
					<Text fontStyle="italic">
						{cancellation.message}
					</Text>

				</AlertDisplayField>
				<Box ml="auto" data-section="alert-link" >
					{links}
				</Box>
			</Flex>}
		/>
	);

};
