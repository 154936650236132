import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, BoxProps, createStyles } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles(() =>
	createStyles({
		body: {
			display: 'flex',
			overflow: 'auto',
		},
	}),
);

export const  CustomPopoverBody: React.FC<BoxProps> = ({ children, className }) => {
	const classes = useStyles();

	return (
		<Box className={classNames(classes.body, className)}>
			{children}
		</Box>
	);
};
