import { CustomInputWrapper } from '@components/CustomInputWrapper/CustomInputWrapper';
import { ProcedureNameInput } from '@components/RequestForm/inputs/ProcedureName/ProcedureNameInput/ProcedureNameInput';
import { CustomProcedureNameValidation } from '@components/RequestForm/RequestFormValidation';
import React from 'react';
import { useProcedureNameContext } from '@components/RequestForm/ProcedureName/ProcedureNameContext';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useFormContext } from 'react-hook-form';
import { RequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import {
	useChangeCustomProcedureName
} from '@components/RequestForm/utilities/hooks/useChangeCustomProcedureName/useChangeCustomProcedureName';

const useStyles = makeStyles((theme) =>
	createStyles({
		procedureInput: {
			background: theme.palette.common.white,
		},
	}),
);

export const CustomProcedureInput = () => {
	const classes = useStyles();

	const {
		isCustomProcedure,
		previousProcedureName,
		isProcedureOption,
		setCustomProcedure,
		procedures
	} = useProcedureNameContext();

	const onChangeCustomProcedure = useChangeCustomProcedureName();

	const { getValues } = useFormContext<RequestFormValidatedState>();

	React.useEffect(() => {
		const procedureName = getValues('procedureName');
		if (procedures.length && procedureName && !isProcedureOption(procedureName)) {
			setCustomProcedure(procedureName);
		}
	}, [getValues, isProcedureOption, procedures.length, setCustomProcedure]);

	const handleOnChangeCustomProcedure = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const procedureName = getValues('procedureName');

		if (!procedureName) {
			previousProcedureName.current = event.target.value;
		} else if (procedureName !== previousProcedureName.current) {
			previousProcedureName.current = procedureName;
		}

		onChangeCustomProcedure(event.target.value);
	}, [getValues, onChangeCustomProcedure, previousProcedureName]);

	return (
		<>
			{isCustomProcedure && (
				<CustomInputWrapper>
					<ProcedureNameInput
						label="Custom Procedure Name"
						name="procedureName"
						controllerOptions={{ rules: CustomProcedureNameValidation }}
						inputProps={{
							className: classes.procedureInput,
							onChange: handleOnChangeCustomProcedure,
						}}
						dataField="requestForm-requestDetails-customProcedureNameTextInput"
					/>
				</CustomInputWrapper>
			)}
		</>
	);
};
