import { BASE_API_URL } from '../apiConstants';

/**
 * Start with colon (:)
 * followed by 2-4 digit number
 * and optionally
 *   has a question mark (?) for query params
 *   or a slash (/)
 *   with 0 or more characters after (.*)
 */
const PORT_START_REGEX = /^:(\d{2,4})([/?].*)?/;

/***
 * optionally ends with colon (:) and numbers
 *
 * capture groups:
 *    1: part before colon
 *    2: colon with number
 */
const ORIGIN_PARTS = /^(.+)(:\d+)$/;

/***
 * Accepts a partial or full URL and returns a new URL filling in missing parts with the BASE_API_URL or base provided.
 * Uses URL constructor (https://developer.mozilla.org/en-US/docs/Web/API/URL/URL) and returns href portion.
 *
 * @example
 * buildUrlWithBase(':9090/user-service', 'https://localhost:8080') === 'https://localhost:9090/user-service'
 * buildUrlWithBase('/user-service', 'https://casetracker-api-dev') === 'https://casetracker-api-dev/user-service'
 * buildUrlWithBase('https://user-service.casetracker', 'https://casetracker-api-dev') === 'https://user-service.casetracker'
 *
 * @param url - {string}
 *  - May start with protocol (https) or double slash (//) when providing origin
 *  - start with colon (:) when providing port but use BASE_API_URL
 *  - Anything else will be assumed to go in path
 * @param base {string} - default to BASE_API_URL
 *
 * @returns {string} - Result from `href` of URL constructor
 */
const buildUrlWithBase = (url: string, base = BASE_API_URL) => {
	let parsedUrl = new URL(url, base);

	if (PORT_START_REGEX.test(url)) {
		const { origin } = new URL(base);
		const originParts = ORIGIN_PARTS.exec(origin);
		parsedUrl = new URL(`${originParts ? originParts[1] : origin}${url}`, base);
	}

	return parsedUrl.href;
};

export default buildUrlWithBase;
