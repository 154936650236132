import React, { ReactNode } from 'react';
import { dateParse, utcToTimezone } from '../../../../utilities/dateUtilities';
import { SurgeryRequestGet } from '../../../../interfaces/SurgeryRequest/SurgeryRequestGet';
import SurgeonList from '../../ConfirmationDrawer/CompareRequestedSurgeon/SurgeonList';

export enum ConflictAttributeEnum {
	PATIENT_NAME = 'patientName',
	DOB = 'dob',
	PROCEDURE_DATE = 'procedureDate',
	PRACTITIONER = 'practitioner',
	PRIMARY_PRACTITIONER = 'primaryPractitioner',
	MULTI_PRACTICES = 'multiPractices',
	HOSPITAL = 'hospital',
	UNIT = 'unit',
	CASE_NUMBER = 'caseNumber',
}

export interface ConflictAttributeDescriptor {
	displayName: string;
	order: number;
	shouldDisableForceOnConflict: false | string;
	formatter?: ((value: string) => ReactNode) |
		((value: string, caseDetails: SurgeryRequestGet, compareToValue?: string) => ReactNode);
}

const defaultErrorMessage = 'You will need to correct the case in the EHR.';
const primarySurgeonErrorMessage = 'You will need to correct the Primary surgeon in the EHR.';

export const conflictAttrConfig: Record<ConflictAttributeEnum, ConflictAttributeDescriptor> = {
	[ConflictAttributeEnum.PATIENT_NAME]: {
		displayName: 'Patient Name',
		order: 1,
		shouldDisableForceOnConflict: false,
	},
	[ConflictAttributeEnum.DOB]: {
		displayName: 'DOB',
		order: 2,
		shouldDisableForceOnConflict: false,
	},
	[ConflictAttributeEnum.HOSPITAL]: {
		displayName: 'Location',
		order: 3,
		shouldDisableForceOnConflict: defaultErrorMessage,
	},
	[ConflictAttributeEnum.UNIT]: {
		displayName: 'Location',
		order: 3,
		shouldDisableForceOnConflict: false,
	},
	[ConflictAttributeEnum.PRIMARY_PRACTITIONER]: {
		displayName: 'Surgeon Name',
		order: 4,
		shouldDisableForceOnConflict: primarySurgeonErrorMessage,
		formatter: (formatVal: string, caseDetails, compareVal) => {
			const surgeonList = <SurgeonList surgeons={formatVal} crossAllAffected={true}  ehrSurgeons={compareVal} conflictName={ConflictAttributeEnum.PRIMARY_PRACTITIONER} />;
			return surgeonList;
		},
	},
	[ConflictAttributeEnum.PRACTITIONER]: {
		displayName: 'Surgeon Name',
		order: 4,
		shouldDisableForceOnConflict: defaultErrorMessage,
		formatter: (formatVal: string, caseDetails, compareVal) => {
			const surgeonList = <SurgeonList surgeons={formatVal} ehrSurgeons={compareVal} />;
			return surgeonList;
		},
	},
	[ConflictAttributeEnum.MULTI_PRACTICES]: {
		displayName: 'Surgeon Name',
		order: 4,
		shouldDisableForceOnConflict: false,
		formatter: (formatVal: string, caseDetails, compareVal) => {
			const surgeonList = <SurgeonList surgeons={formatVal} ehrSurgeons={compareVal} />;
			return surgeonList;
		},
	},
	[ConflictAttributeEnum.PROCEDURE_DATE]: {
		displayName: 'Date and Time',
		order: 5,
		shouldDisableForceOnConflict: false,
		formatter: (d, { hospitalTimeZone }) => {
			const convertedProcedureDate = utcToTimezone(d, hospitalTimeZone);
			const dateTimeParts = convertedProcedureDate && dateParse(convertedProcedureDate);
			return `${dateTimeParts[0]} - ${dateTimeParts[1]}${dateTimeParts[2]}`;
		},
	},
	[ConflictAttributeEnum.CASE_NUMBER]: {
		displayName: 'Case Number',
		order: 1,
		shouldDisableForceOnConflict: 'You will need to correct the case number in Surgery Scheduling Manager.',
	},
};
