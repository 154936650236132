import React from 'react';
import { APP_NAME } from '../../constants';

const usePageEffects = (title: string) => {

	React.useEffect(() => {
		const originalTitle = document.title;
		document.title = title + ' - ' + APP_NAME;
		return () => {
			document.title = originalTitle;
		};
	}, [title]);
};

export default usePageEffects;
