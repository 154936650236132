/**
 * isAllGroupSelected to determine currentSelection should be considered all for provided group.
 *
 * Scenarios:
 *  - selectedEntityIds == null -> false; none selected at all
 *  - selectedEntityIds == [] -> true; initial or default state
 *  - selectedEntityIds contains all groupEntityIds -> true; all of this group selected
 *  - selectedEntityIds contains none of groupEntityIds -> false; none of this group selected
 *  - selectedEntityIds contains some of groupEntityIds -> false; only some of this group selected
 *
 * @param {string[] | null} selectedEntityIds - currently selected ID list. Assumes null when none selected. Empty array in default state
 * @param {string[]} groupEntityIds - this groups id list
 *
 * @returns Boolean indicating if all checkbox should be marked
 */
export const isAllGroupSelected = (selectedEntityIds: string[] | null, groupEntityIds: string[]) =>
	!!(selectedEntityIds && (!selectedEntityIds.length || !groupEntityIds.some(entity => !selectedEntityIds.includes(entity))));

/**
 * getListWithoutGroup to get distinct IDs from fullList based on groupEntityIds
 * @param {string[] | null} fullList - list of IDs to return as base
 * @param groupEntityIds - group to filter out
 *
 * @returns string[] with only IDs not in groupEntityIds OR [] if fullList is null
 */
export const getListWithoutGroup = (fullList: string[] | null, groupEntityIds: string[]) =>
	fullList ? fullList.filter(entity => !groupEntityIds.includes(entity)) : [];

/**
 * getEmptyAsNull - converts empty array to null to allow for unselecting all checkboxes
 *
 * @param {string[]} ids - list of IDs to select
 *
 * @returns {string[] | null} - null indicates none selected
 */
export const getEmptyAsNull = (ids: string[]) => ids.length ? ids : null;

/**
 * getAllIdsWithoutGroup - returns a bound function which returns all of the sections IDs without the provided groupIds
 * @param {string[]} allIds - all possible IDs for list
 * @param {string[]} groupIds - only this groups IDs
 *
 * @returns function with arguments bound to return all of the sections IDs without the provided groupIds
 */
const getAllIdsWithoutGroup = (allIds: string[], groupIds: string[]) => getListWithoutGroup.bind(null, allIds, groupIds);

/**
 * getSelectedWithoutGroup - returns a bound function which returns all selected IDs for the section without the provided groupIds
 * @param {string[] | null} allSelectedIds - currently selected IDs
 * @param {string[]} groupIds - only this groups IDs
 * *
 * @returns function with arguments bound to return all selected IDs for the section without the provided groupIds
 */
const getSelectedWithoutGroup = (allSelectedIds: string[] | null, groupIds: string[]) => getListWithoutGroup.bind(null, allSelectedIds, groupIds);

/**
 * handleCheckAllAsOnlyAllChecked - results in only All checkbox being checked if all is selected
 * @param {string[]} allIds - all possible IDs for list
 * @param {string[]} groupIds - only this groups IDs
 * @param {string[] | null} selectedIds - currently selected IDs
 * @param {string | 'all'} newId - ID to toggle or 'all'
 *
 * @returns string[] | null - null indicates none selected
 */
const handleCheckAllAsOnlyAllChecked = (allIds: string[], groupIds: string[], selectedIds: string[] | null, newId: string) => {
	const idsWithoutGroup = selectedIds?.length ?
		// Add to list of currently selected
		getSelectedWithoutGroup(selectedIds, groupIds)() :
		// None selected so add all the others to the list (default state)
		getAllIdsWithoutGroup(allIds, groupIds)();
	return idsWithoutGroup.concat(newId);
};

/**
 * handleCheckAllAsEverythingChecked - results in all checkboxes in section (including All) being checked if all is selected
 * @param {string[]} allIds - all possible IDs for list
 * @param {string[]} groupIds - only this groups IDs
 * @param {string[] | null} selectedIds - currently selected IDs
 * @param {string | 'all'} newId - ID to toggle or 'all'
 *
 * @returns string[] | null - null indicates none selected
 *
 * @description - Note for future implementations that use this, you will also need to update "checked" logic similar to how it is in SurgeonCheckbox
 */
const handleCheckAllAsEverythingChecked = (allIds: string[], groupIds: string[], selectedIds: string[] | null, newId: string) => {
	return getEmptyAsNull(getSelectedWithoutGroup(selectedIds, groupIds)()
		.concat(groupIds)
		.filter(id => id !== newId));
};

export enum SectionName {
	SURGEONS = 'surgeons',
	UNITS = 'units',
	SPECIALTY = 'specialty'
}

interface SectionDescriptor {
	onCheckHandler: (
		allIds: string[],
		groupIds: string[],
		allSelectedIds: string[] | null,
		newId: string
	) => (string[] | null);
}

const MyFiltersSectionConfig: Record<SectionName, SectionDescriptor> = {
	[SectionName.SURGEONS]: {
		onCheckHandler: handleCheckAllAsEverythingChecked
	},
	[SectionName.UNITS]: {
		onCheckHandler: handleCheckAllAsOnlyAllChecked
	},
	[SectionName.SPECIALTY]: {
		onCheckHandler: handleCheckAllAsOnlyAllChecked
	},
};

/**
 * getNewSelectList - provided the list and the ID, return the updated list
 *
 * @param {string[]} allIds - all possible IDs for list
 * @param {string[]} groupIds - only this groups IDs
 * @param {string[] | null} selectedIds - currently selected IDs
 * @param {string | 'all'} newId - ID to toggle or 'all'
 * @param {SectionName} sectionName - name of checkbox section within My Filters page
 *
 * @returns string[] | null - null indicates none selected
 */
export const getNewSelectList = (allIds: string[], groupIds: string[], selectedIds: string[] | null, newId: string, sectionName: SectionName): string[] | null => {
	const getUpdatedList = () => {
		const isAllSelected = isAllGroupSelected.bind(null, selectedIds, groupIds);

		if (newId === 'all') {
			// Initial default state
			if (selectedIds && !selectedIds.length) {
				return getEmptyAsNull(getAllIdsWithoutGroup(allIds, groupIds)());
			}

			// Remove all of this groups IDs
			if (isAllSelected()) {
				return getEmptyAsNull(getSelectedWithoutGroup(selectedIds, groupIds)());
			}

			// Add all of this groups IDs
			return getSelectedWithoutGroup(selectedIds, groupIds)().concat(groupIds);
		}

		// All is currently selected and newId is NOT all
		if (selectedIds && isAllSelected()) {
			return MyFiltersSectionConfig[sectionName].onCheckHandler(allIds, groupIds, selectedIds, newId);
		}

		const selected = selectedIds || [];
		return getEmptyAsNull(selected.includes(newId) ?
			selected.filter(i => i !== newId) :
			selected.concat(newId)
		);
	};

	const newIdList = getUpdatedList();

	return newIdList && newIdList.length === allIds.length ? [] : newIdList;
};

/**
 * toggleSelectedSurgeonsBySpecialty - gets new selected surgeon list based on toggled specialty group
 *
 * @param {string[]} specialtySurgeonIds - all surgeon IDs for specialty being toggled
 * @param {string[] | null} allSelectedSurgeonIds - all currently selected IDs
 *
 * @returns {string[] | null} all surgeon ids that should now be selected; null indicates nothing selected
 */
export const toggleSelectedSurgeonsBySpecialty = (specialtySurgeonIds: string[], allSelectedSurgeonIds: string[] | null): string[] | null => {
	let newIdList = allSelectedSurgeonIds ? [...allSelectedSurgeonIds] : [];

	specialtySurgeonIds.forEach((id) => {
		newIdList.includes(id) ?
			newIdList = newIdList.filter(i => i !== id) :
			newIdList = newIdList.concat(id);
	});

	return getEmptyAsNull(newIdList);
};
