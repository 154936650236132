import React from 'react';
import { Surgeon } from '../../../../../data/surgeon/Surgeon';
import { getFormattedName } from '../../../../../utilities/commonFunction';
import { useSelector } from 'react-redux';
import { FullSurgeonsSelector } from '../../../../../store/selectors/SurgeonListSelector';
import { AllSurgeonCheckbox } from '../AllSurgeonCheckbox/AllSurgeonCheckbox';
import { SurgeonCheckbox } from '../SurgeonCheckbox/SurgeonCheckbox';
import { useSurgeonContext } from '../SurgeonContext/SurgeonContext';
import { getNewSelectList, SectionName } from '../../MyFilterUtilities';
import { useIsAllSelected } from '../../useIsAllSelected/useIsAllSelected';
import { useFormContext } from 'react-hook-form';

const SurgeonsCheckBoxList = () => {
	const fullSurgeonList = useSelector(FullSurgeonsSelector);
	const { setValue, getValues } = useFormContext<{surgeon: string[] | null}>();

	const { allSurgeonIds } = useSurgeonContext();
	const allSelected = useIsAllSelected('surgeon', allSurgeonIds);
	const getSelectedSurgeons = React.useMemo(() => getNewSelectList.bind(null, allSurgeonIds, allSurgeonIds), [allSurgeonIds]);

	const handleToggleCheckbox = React.useCallback((surgeonId: string) => {
		const { surgeon: currentSurgeons } = getValues();

		setValue('surgeon', getSelectedSurgeons(currentSurgeons, surgeonId, SectionName.SURGEONS), { shouldValidate: true });
	}, [getSelectedSurgeons, getValues, setValue]);

	return (
		<>
			<AllSurgeonCheckbox onCheck={handleToggleCheckbox} allSelected={allSelected} />
			{fullSurgeonList.map((surgeon: Surgeon) => {
				return (
					<SurgeonCheckbox
						key={surgeon.id}
						id={surgeon.id}
						name={getFormattedName(surgeon)}
						onCheck={handleToggleCheckbox}
						allSelected={allSelected}
					/>
				);
			})}
		</>
	);
};

export default SurgeonsCheckBoxList;
