import { addBusinessDays } from 'date-fns';
import { FindATimeForm } from '@components/FindATime/FindATimeForm';
import { TODAY, TWO_BUSINESS_DAYS_FROM_NOW } from '@utilities/dateUtilities';

export const FindATimeSearchDefaultValues: FindATimeForm = {
	appointmentLocation: '',
	primarySurgeon: '',
	startDate: TWO_BUSINESS_DAYS_FROM_NOW,
	endDate: addBusinessDays(TODAY, 6),
	dow: [1, 2, 3, 4, 5],
	procedureName: '',
	duration: '',
	startTime: '',
	endTime: '',
	metaData: undefined,
};
