import { Dispatch } from 'react';
import NoParamNoReturnCallback from '@interfaces/NoParamNoReturnCallback';

export enum WarningModalType {
	NONE = 'NONE',
	LOCATION = 'LOCATION',
	BLOCK_TIME = 'BLOCK_TIME'
}

type LocationFieldType = string | undefined;
type BlockTimeFieldType = boolean;

export type ManagedFieldType = LocationFieldType | BlockTimeFieldType;

export interface ManageHoldEventContextType {
	handleOpenWarningModal: NoParamNoReturnCallback;
	handleCancelValueChange: NoParamNoReturnCallback;
	handleClearDateTime: NoParamNoReturnCallback;
	setNewFieldValue: Dispatch<ManagedFieldType>;
	setPrevFieldValue: Dispatch<ManagedFieldType>;
	isModalOpen: boolean;
	setWarningType: Dispatch<WarningModalType>;
	warningType: WarningModalType;
}
