import React from 'react';
import { Box } from '@ascension/web';
import { FieldError } from 'react-hook-form';

export default function ErrorMessage ({ errorField }: {errorField?: string | FieldError | {message: string}}) {
	const errorMessage = typeof errorField === 'object' && 'message' in errorField ? errorField.message : errorField;

	if (typeof errorMessage !== 'string' || !errorMessage) { return null; }

	return (
		<Box name = "errorField">
			<div className="error-wrap">
				<div className="error-icon">
					<svg width="100" height="100" viewBox="0 0 100 100">
						<path fill="#979797" d="M100 14.285L85.715 0 50.002 35.717 14.285 0 0 14.285l35.717 35.717L0 85.715 14.285 100l35.717-35.713L85.715 100 100 85.715 64.287 50.002z"></path>
					</svg>
				</div>
				<span>{errorMessage}</span>
			</div>
		</Box>
	);
}
