import React from 'react';
import { useGetProcedureDuration } from '@data/procedureDuration/hooks/useGetProcedureDuration';
import { useProcedureNameContext } from '@components/RequestForm/ProcedureName/ProcedureNameContext';
import { ProcedureDurationRequestParams } from '@data/procedureDuration/types/ProcedureDurationRequestParams';
import {
	useDoesUnitSupportOpenTime
} from '@components/RequestForm/utilities/hooks/useDoesUnitSupportOpenTime/useDoesUnitSupportOpenTime';

type UseGetRequestProcedureDurationParams = Partial<ProcedureDurationRequestParams>;

export const useGetRequestProcedureDuration = ({
	hospitalId,
	unitId,
	procedure,
	npi,
}: UseGetRequestProcedureDurationParams) => {
	const [durationApi, setDurationApi] = React.useState<number>();

	const getProcedureDuration = useGetProcedureDuration();
	const { isCustomProcedure } = useProcedureNameContext();
	const hasOpenTime = useDoesUnitSupportOpenTime();

	React.useEffect(() => {
		if (!hospitalId || !unitId || !npi || !procedure || isCustomProcedure || !hasOpenTime) {
			setDurationApi(undefined);
			return;
		}
		getProcedureDuration({
			data: {
				hospitalId,
				unitId,
				procedure,
				npi,
			},
			onSuccess: (response) => {
				setDurationApi(response.data.duration);
			},
		});
	}, [getProcedureDuration, hasOpenTime, hospitalId, isCustomProcedure, npi, procedure, unitId]);

	return durationApi;
};
