import React from 'react';
import { useSelector } from 'react-redux';
import { Box, alpha, makeStyles } from '@material-ui/core';
import { Multiselect } from '@ascension/web';
import { OtherFiltersOption } from '@definitions/OtherFiltersOption';
import { useFilterState } from '../FiltersContext/FiltersContext';
import { UserProfileRoleSelector } from '@store/selectors/UserProfileSelector';
import { HospitalRoles, OfficeRoles, UserRole } from '@interfaces/UserProfile/UserRoleEnum';
import { OtherFiltersObjectForCheckbox } from '@interfaces/OtherFilterTypes';
import { otherFiltersArrayOfObjectsForCheckboxes } from '@utilities/constants';

enum OtherFilterRoleBasedOptions {
	EHR_CONFLICT = 'EHR_CONFLICT',
	PENDING_LINK = 'PENDING_LINK',
	UPDATED_DATE = 'UPDATED_DATE',
	ROBOTIC = 'ROBOTIC',
}

const OTHER_FILTER_CONFIG: Record<string, { showForRoles: UserRole[]; label: string }> = {
	[OtherFilterRoleBasedOptions.EHR_CONFLICT]: { showForRoles: HospitalRoles, label: 'DATA CONFLICT' },
	[OtherFilterRoleBasedOptions.PENDING_LINK]: { showForRoles: HospitalRoles, label: 'PENDING LINK' },
	[OtherFilterRoleBasedOptions.UPDATED_DATE]: { showForRoles: OfficeRoles, label: 'UPDATED DATE' },
	[OtherFilterRoleBasedOptions.ROBOTIC]: { showForRoles: HospitalRoles, label: 'ROBOTIC' },
};

const useStyles = makeStyles((theme) => {
	return {
		otherFilterDropdown: {
			'minWidth': 120,
			'border': '1px solid',
			'borderRadius': theme.spacing(.5),
			'borderColor': alpha(theme.palette.common.black, .6),
			'& > div > div > div:nth-of-type(2)': {
				marginTop: theme.spacing(.2),
			}
		}
	};
});

export default function CaseListOtherFilters() {
	const classes = useStyles();
	const [value, setValue] = React.useState<OtherFiltersObjectForCheckbox[]>([]);
	const data: OtherFiltersObjectForCheckbox[] = otherFiltersArrayOfObjectsForCheckboxes;

	const {
		status,
		state,
		allOtherFilters,
		updateFilterPropsAndResetFilterToggle,
	} = useFilterState();

	const initialValues = React.useMemo(() => status.concat(state, allOtherFilters), [allOtherFilters, state, status]);

	const otherFilters = otherFiltersArrayOfObjectsForCheckboxes;
	React.useEffect(() => {
		const optionToValue = (option: OtherFiltersObjectForCheckbox) =>
			({ label: option.label, value: option.id, field: option.field, id: option.id });
		const defaultOption = otherFilters.filter(({ id }: OtherFiltersObjectForCheckbox) => initialValues.some((newValue: string) => newValue === id));
		const defaultValue = !defaultOption.length ?
			[optionToValue(otherFilters[0])] :
			defaultOption.map((option: OtherFiltersObjectForCheckbox) => {
				return optionToValue(option);
			});

		setValue(defaultValue);
	}, [initialValues, otherFilters]);

	const userRoles = useSelector(UserProfileRoleSelector);

	const filters = React.useMemo(() => {
		const isAValidRole = (showForRoles: UserRole[]) => userRoles.some((role) => showForRoles.includes(role));

		const filterOptionsByRole = (item: OtherFiltersObjectForCheckbox) => {
			const newItem = OTHER_FILTER_CONFIG[item.id] ? { ...item, ...OTHER_FILTER_CONFIG[item.id] } : { ...item };
			return !newItem.showForRoles || isAValidRole(newItem.showForRoles);
		};

		return data.filter(filterOptionsByRole).map(({ id, label, field }) => {
			label = OTHER_FILTER_CONFIG[id] ? OTHER_FILTER_CONFIG[id].label : label.replace('_', ' ');
			return {
				value: id,
				label: `${label?.toUpperCase()}`,
				field,
			};
		});
	}, [data, userRoles]);

	const placeholder = React.useCallback((items: string[]) => {
		if (!items) {
			return 'Select';
		}

		if (items.length > 1) {
			return `${items.length} Filters Selected`;
		}


		return OTHER_FILTER_CONFIG[items[0]]?.label || items[0];
	}, []);

	const handleChange = React.useCallback((selected: OtherFiltersOption[]) => {
		const statusParams: string[] = [];
		const stateParams: string[] = [];
		const allOtherFiltersParam: string[] = [];
		selected.forEach((val: OtherFiltersOption) => {
			if (val.field === 'status') {
				statusParams.push(val.value);
			}
			if (val.field === 'state') {
				stateParams.push(val.value);
			}
			if (val.field === 'all') {
				allOtherFiltersParam.push(val.value);
			}
		});
		updateFilterPropsAndResetFilterToggle({
			status: statusParams,
			state: stateParams,
			allOtherFilters: allOtherFiltersParam
		});
	}, [updateFilterPropsAndResetFilterToggle]);

	return (
		<Box data-field="Other_Filters" className={classes.otherFilterDropdown}>
			{data.length > 0 && <Multiselect value={value} onChange={handleChange} label="Other Filters" placeholder={placeholder} options={filters}/>}
		</Box>
	);
}
