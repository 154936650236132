import React from 'react';
import { Text, Flex } from '@ascension/web';
import { SetupCardSection } from '../SetupCardSection/SetupCardSection';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import SetupConfirmList from '../SetupConfirmList/SetupConfirmList';
import { getAllUnitListData } from '../../../store/actions/UnitListActionCreators';
import { RootState } from '../../../interfaces/rootState';

const useStyles = makeStyles({
	ministryTitle: {
		fontSize: '18px',
		marginTop: '32px',
		marginBottom: '24px',
		letterSpacing: '0.15px',
		fontWeight: 'bold'
	},
	hospitalSection: {
		flexDirection: 'row',
		flexWrap: 'wrap'
	},
	hospitalName: {
		lineHeight: '24px',
		letterSpacing: '0.5px',
		marginBottom: '8px',
		width: '50%'
	},
	hospitalQuestionText: {
		lineHeight: '24px',
		marginTop: '16px',
		letterSpacing: '0.5px',
		fontWeight: 'bold'
	},
	asterisk: {
		color: '#E00019',
	},
});

export default function SetupMinistryInformation({ setInformationConfirmed }: { setInformationConfirmed: (confirm: boolean) => unknown }) {
	const { ministryTitle, hospitalSection, hospitalName, hospitalQuestionText, asterisk } = useStyles();
	const dispatch = useDispatch();
	const { groupedUnitList = [] } = useSelector((state: RootState) => state.unitList);

	React.useEffect(() => {
		dispatch(getAllUnitListData());
	}, [dispatch]);

	//user can be part of only one ministry
	const ministryAcronym = groupedUnitList[0]?.units[0]?.ministry || '';
	const ministryLocation = groupedUnitList[0]?.units[0]?.ministryLocation || '';

	const ministryNameAndLocation = ministryAcronym || ministryLocation ? `${ministryLocation} (${ministryAcronym})` : '';

	return (
		<SetupCardSection sectionTitle="Ministry Information">
			<Text className={ministryTitle} data-field="ministryNameAndLocation">{ministryNameAndLocation}</Text>
			<Flex className={hospitalSection}>
				{groupedUnitList && groupedUnitList.map(hospital => <Text key={hospital.id} className={hospitalName}>{hospital.name}</Text>)}
			</Flex>
			<Text className={hospitalQuestionText} aria-required="true">Do you see your facility in this list? <span className={asterisk}>*</span></Text>
			<SetupConfirmList setInformationConfirmed={setInformationConfirmed}/>
		</SetupCardSection>
	);
}
