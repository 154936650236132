import React from 'react';
import classNames from 'classnames';
import { Box, BoxProps, makeStyles } from '@material-ui/core';
import { NoResultsMessage } from '@components/RequestForm/FindATime/FindATimeAccordion/FindATimeResults/NoResultsMessage/NoResultsMessage';
import { useFindATimeContext } from '@components/FindATime/FindATimeContext/FindATimeContextProvider';
import { Results } from '@components/RequestForm/FindATime/FindATimeAccordion/FindATimeResults/Results/Results';
import BoxFancyScroll from '@components/BoxFancyScroll/BoxFancyScroll';
import { AvailableTimeOption } from '@data/findATime/types/AvailableTimeOption';

const useStyles = makeStyles((theme)=> ({
	root: {
		width: '100%',
		overflowY: 'auto',
		padding: theme.spacing(3),
	},
	noResults: {
		padding: theme.spacing(3),
	}
}));

interface FindATimeResultsProps extends BoxProps {
	handleSelectTime: (time: AvailableTimeOption, timeSlotId: string, date: Date) => void;
	handleSetFindATimeUsed?: (newValue: boolean) => void;
}

export const FindATimeResults: React.FC<FindATimeResultsProps> = ({
	className,
	handleSelectTime,
	handleSetFindATimeUsed,
	...boxProps
}) => {
	const { findATimeResponse } = useFindATimeContext();
	const classes = useStyles();

	if (findATimeResponse && findATimeResponse.length > 0) {
		return (
			<BoxFancyScroll className={classNames(classes.root, className)}>
				<Results data={findATimeResponse} handleSelectTime={handleSelectTime} handleSetFindATimeUsed={handleSetFindATimeUsed} />
			</BoxFancyScroll>
		);
	}

	return (
		<Box
			className={classNames({
				className,
				[classes.noResults]: !className
			})}
			{...boxProps}
		>
			<NoResultsMessage />
		</Box>
	);
};
