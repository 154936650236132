import { Reducer } from 'redux';
import { CaseListState, CaseListTypes } from '../../interfaces/CaseListTypes';
import {
	SAVE_SCROLL_POS,
	UPDATE_CASE_LIST,
	UPDATE_CASE_LIST_ERROR,
	UPDATE_CASE_LIST_FILTER,
	UPDATE_TASK_COUNT
} from '../actions/types';
import { utcToTimezone } from '../../utilities/dateUtilities';

const initialState: CaseListState = {
	caseList: [],
	totalCount:0,
	taskCount: {
		warning: 0,
		unscheduled: 0,
		needRevision: 0,
		revised: 0,
		pendingLink: 0,
		updatedDate: 0,
	},
	filter: undefined,
	lastUpdate: undefined,
	error: null,
	scrollPos: ''
};
export const CaseListReducer: Reducer<CaseListState, CaseListTypes>
	= ( state = initialState, action: CaseListTypes) => {
		switch (action.type) {
		case SAVE_SCROLL_POS:
			return {
				...state,
				scrollPos: action.data
			};
		case UPDATE_CASE_LIST:
			return {
				...state,
				error: null,
				caseList: action.payload.data.map((caseInfo) => ({
					...caseInfo,
					procedureDate: utcToTimezone(caseInfo.procedureDate, caseInfo.hospitalTimeZone)
				})),
				totalCount: action.payload.totalCount,
			};
		case UPDATE_TASK_COUNT:
			return {
				...state,
				error: null,
				taskCount: {
					...initialState.taskCount,
					...action.data,
				},
				lastUpdate: new Date().toISOString(),
			};
		case UPDATE_CASE_LIST_ERROR:
			return { ...state, error: action.error };
		case UPDATE_CASE_LIST_FILTER:
			return { ...state, filter: action.payload };
		default:
			return state;
		}
	};
