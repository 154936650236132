import React from 'react';
import { Box, Flex } from '@ascension/web';
import SpecialtySurgeonSection from './SpecialtySurgeonSection';
import { useSaveFiltersContext } from '../../SaveFiltersContext/SaveFiltersContext';
import { FormAssistiveText } from '../../../../FormComponents/FormAssistiveText/FormAssistiveText';

export default function HospitalSurgeonsPage() {
	const { sortedSpecialtyIds = [] } = useSaveFiltersContext();

	return (
		<Flex
			flexDirection="column"
			justifyContent="space-between"
			alignItems="start"
			width={ 1 }
			mb={ 12 }
		>
			<Box fontSize={18} letterSpacing="1.4px" color="#222222" fontWeight="bold" pb={4}>
				Surgeons
			</Box>
			<FormAssistiveText field="surgeon">Select at least one surgeon.</FormAssistiveText>
			<Box width={1}>
				{sortedSpecialtyIds.map((specialtyId: string) =>
					<SpecialtySurgeonSection
						specialtyId={specialtyId}
						key={specialtyId}
					/>
				)}
			</Box>

		</Flex>
	);
}
