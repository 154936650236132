import { AxiosResponse } from 'axios';
import { EMPTY_ACTION } from '../../actions/types';
import { requireAuthentication } from '../../actions/AuthActionCreators';
import { setErrorStatus } from '../../actions/ErrorStatusActionCreators';
import { updateLocalUserProfileStatus } from '../../actions/UserProfileActionCreators';
import { UserStatus } from '@interfaces/UserProfile/UserStatusEnum';
import { Api401ErrorCode } from '@interfaces/ErrorStatus/APIErrorCodeEnum';
import { ErrorStatusState } from '@interfaces/ErrorStatus/ErrorStatusState';

export const getActionForErrorCode = (response: AxiosResponse<ErrorStatusState>) => {
	if (response.status === 401 && response.data && response.data.errorCode) {
		switch (response.data.errorCode) {
		case Api401ErrorCode.ULK: //display lock error modal
		case Api401ErrorCode.LNF: //display error for location ID
			return updateLocalUserProfileStatus(UserStatus.LOCK);
		case Api401ErrorCode.INR: //Log user out
		case Api401ErrorCode.INS: //Log user out
			return requireAuthentication();
		case Api401ErrorCode.UEX: //display standard HTTP error modal to prompt user to call
		case Api401ErrorCode.EXC: //display standard HTTP error modal to prompt user to call
			return setErrorStatus(response.data);
		}
	}
	//this should never reach that code if function is properly called when response status is 401
	return { type: EMPTY_ACTION };
};
