import {
	UPDATE_CASE_LIST,
	UPDATE_CASE_LIST_ERROR,
	UPDATE_CASE_LIST_FILTER,
	UPDATE_TASK_COUNT,
	SAVE_SCROLL_POS
} from '../store/actions/types';
import { CaseTrackerListInterface } from '../components/CaseTrackerList/CaseTrackerListInterface';
import { FilterApiParams } from './FilterParams';
import { UserRole } from './UserProfile/UserRoleEnum';

export enum TaskName {
	warning = 'warning',
	unscheduled = 'unscheduled',
	needRevision = 'needRevision',
	revised = 'revised',
	pendingLink = 'pendingLink',
	updatedDate = 'updatedDate',
}

export type TaskCountInterface = Record<TaskName, number>;

export interface TaskCardConfig {
	label: string;
	order: number;
	filterLink: string;
	showForRoles?: UserRole[];
}

export interface CaseListState {
	caseList: CaseTrackerListInterface[];
	totalCount: number;
	taskCount: TaskCountInterface;
	error: string | null;
	lastUpdate?: string;
	filter?: FilterApiParams;
	scrollPos: string;
}

interface UpdateCaseListAction {
	type: typeof UPDATE_CASE_LIST;
	payload: {
		data: CaseTrackerListInterface[];
		totalCount: number;
	};
}

interface UpdateCaseListFilterAction {
	type: typeof UPDATE_CASE_LIST_FILTER;
	payload: FilterApiParams;
}

interface UpdateCaseListError {
	type: typeof UPDATE_CASE_LIST_ERROR;
	error: string | null;
}

interface UpdateTaskCount {
	type: typeof UPDATE_TASK_COUNT;
	data: TaskCountInterface;
}

interface SaveScrollPos {
	type: typeof SAVE_SCROLL_POS;
	data: string;
}
export type CaseListTypes = UpdateCaseListAction | UpdateCaseListError | UpdateTaskCount | UpdateCaseListFilterAction | SaveScrollPos;
