import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PdfViewerProps } from '@interfaces/PdfViewer';
import { Theme as DefaultTheme } from '@material-ui/core/styles/createTheme';
import { Document, Page, pdfjs } from 'react-pdf';

// Necessary because pdfjs is not typed properly
type PdfJsType = {
	GlobalWorkerOptions: {
		workerSrc: string;
	};
};
( pdfjs as PdfJsType ).GlobalWorkerOptions.workerSrc = '/static/js/pdfWorker.js';

const useStyles = makeStyles<DefaultTheme, Record<'backgroundColor', string | undefined>>( theme => ({
	pageWrapper: {
		'padding': theme.spacing(3, 3, 0, 3),
		'maxWidth': '1072px',
		'margin': '0 auto',
		'&:last-of-type': {
			paddingBottom: theme.spacing(3),
		},
	},
	pdfContainer: ({ backgroundColor }) => ({
		backgroundColor: backgroundColor || theme.palette.grey[500],
		height: '100%',
		width: '100%',
	}),
	pdfPage: {
		'&& .react-pdf__Page__canvas': {
			margin: '0 auto',
			// This must be done, because generated inline styles override every other class.
			width: `100% !important`,
			height: '100% !important',
		},
		'&& .react-pdf__Page__annotations': {
			display: 'none',
		},
	},
}));

const PdfViewer: React.FC<PdfViewerProps> = ({ pdfDoc, backgroundColor, onLoadSuccess, onLoadError, onSourceError }) => {
	const classes = useStyles({ backgroundColor });
	const [numPages, setNumPages] = React.useState<number | null>(null);

	const handlePdfLoadSuccess = ({ numPages }: { numPages: number }) => {
		setNumPages(numPages);
		onLoadSuccess && onLoadSuccess();
	};

	return (
		<Document
			file={pdfDoc}
			onLoadSuccess={handlePdfLoadSuccess}
			onLoadError={onLoadError}
			onSourceError={onSourceError}
			className={classes.pdfContainer}
		>
			{Array.from(new Array(numPages), (el, index) => (
				<div id={`page_${index + 1}`} key={`page_${index + 1}`} className={classes.pageWrapper}>
					<Page
						pageNumber={index + 1}
						className={classes.pdfPage}
						scale={2}
						renderTextLayer={false}
					/>
				</div>
			))}
		</Document>
	);
};

export default PdfViewer;
