import React from 'react';
import { caseLockStatus } from '@store/actions/CaseDetailsActionCreators';
import { useDispatch, useSelector } from 'react-redux';
import { useCaseDetailsPageContext } from '@components/CaseDetails/CaseDetailsContext/CaseDetailsPageContext/CaseDetailsPageContext';
import { ManageCaseOption } from '@components/CaseDetails/ManageCaseDropDown/ManageCaseOption';
import {
	useCaseDetailsInfoContext
} from '@components/CaseDetails/CaseDetailsContext/CaseDetailsInfoContext/CaseDetailsInfoContext';
import { CaseDetailsAppointmentSelector, IsCaseDetailsLockedSelector } from '@store/selectors/CaseDetailsSelector';
import { RenderAsProps } from '@components/CaseDetails/ManageCaseDropDown/DropDownOptions/DropDownOptionProps';
import { useModalContext } from '@components/Modal/ModalContextProvider';
import { CASE_LOCK_MODAL } from '@components/Modal/modalConstants';

export const EditCaseOption = ({ closeDropdown }: RenderAsProps) => {
	const { caseId } = useCaseDetailsInfoContext();
	const { status } = useSelector(CaseDetailsAppointmentSelector);
	const lockStatus = useSelector(IsCaseDetailsLockedSelector);
	const isCancelledOrPending = status && ['CANCELLED', 'PENDING_CANCELLED'].includes(status);
	const disableOption = !!(lockStatus || isCancelledOrPending);

	const { showEdit } = useCaseDetailsPageContext();
	const dispatch = useDispatch();
	const { openModal } = useModalContext();

	const attemptOpenEdit = () => {
		dispatch(caseLockStatus(caseId, true, {
			onSuccess: () => {
				showEdit();
			},
			onFailure: () => openModal(CASE_LOCK_MODAL)
		}));
		closeDropdown();
	};

	return (
		<ManageCaseOption onClick={attemptOpenEdit} fieldName="editCase" disabled={disableOption}>
			Edit
		</ManageCaseOption>
	);
};
