import React from 'react';
import useSubmitDocPrint from '@components/UnitCalendarPage/utilities/useSubmitDocPrint';

const useOnAfterDocPrint = () => {
	const submitDocPrint = useSubmitDocPrint();

	React.useEffect(() => {
		window.addEventListener('afterprint', submitDocPrint);

		return () => window.removeEventListener('afterprint', submitDocPrint);
	}, [submitDocPrint]);
};

export default useOnAfterDocPrint;
