export enum NotificationTypeEnum {
	NEED_REVISION = 'NEED_REVISION',
	REVISED = 'REVISED',
	WARNING = 'WARNING',
	PENDING_CANCELLED = 'PENDING_CANCELLED',
	CANCELLED = 'CANCELLED',
	UNSCHEDULED = 'UNSCHEDULED',
	PENDING_LINK = 'PENDING_LINK',
	EHR_CONFLICT = 'EHR_CONFLICT',
	UPDATED_DATE = 'UPDATED_DATE',
}
