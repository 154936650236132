import React, { SetStateAction } from 'react';
import { useFormContext } from 'react-hook-form';
import {
	getStartEndFromTimeSpan,
	PartialTimeSpan,
} from '@utilities/Validation/TimeSpanValidationUtilities/TimeSpanValidationUtilities';
import { getMidnightLocal } from '@calendar';
import { MS_IN_DAY } from '@utilities/constants';
import { calculateDuration } from '@utilities/calculateDuration';
import { calculateTimeWithDuration } from '@utilities/calculateTimeWithDuration';

type TimeSpanSetter = (current: PartialTimeSpan) => PartialTimeSpan;

const defaultTimespan = {};

/***
 *
 * @param timeZone - timeZone to use for date calculations
 * @param date {Date} - current date to set on timespan
 * @param fieldName {string} - 'timeSpan' by default
 */
const useTimeSpanAutoCalculate = (timeZone: string, date: Date, fieldName = 'timeSpan') => {
	const durationMinutesRef = React.useRef(60);

	// Set date to ref so that it can be used in setEndTime and setStartTime without causing an additional dependency
	const dateRef = React.useRef(date);
	dateRef.current = date;

	const { setValue, getValues, watch } = useFormContext<Record<string, PartialTimeSpan>>();

	const timespan: PartialTimeSpan = watch(fieldName) || defaultTimespan;
	React.useEffect(() => { // Find duration of existing values
		const duration = calculateDuration(getStartEndFromTimeSpan(timespan));

		if (typeof duration !== 'number') { return; }

		durationMinutesRef.current = duration;
	}, [timespan]);

	const maxDate = React.useMemo(() =>
		// Define what value the end time will be (the clamped time), since this is what needs to be clamped TO
		// We are using midnight for now until we can do the end of calendar
		new Date(getMidnightLocal(timeZone).getTime() + MS_IN_DAY),
	[timeZone]);

	const setupNewTimeSpan = React.useCallback((timeSpanSetter: TimeSpanSetter) => {
		const values = getValues(); // retrieve previous timespan
		const prevTimeSpan: PartialTimeSpan = values[fieldName];

		return timeSpanSetter({ ...prevTimeSpan, date: dateRef.current }); // use a copy of the previous timespan
	}, [fieldName, getValues]);

	const setEndTime = React.useCallback((time: SetStateAction<string | undefined>) => {
		const newTimeSpan = setupNewTimeSpan((ts) => {
			ts.end = typeof time === 'function' ? time(ts?.end) : time;
			return ts;
		});

		// Does NOT clamp time when setting end time directly.
		// When time is clamped, the user's typed input would be changed.
		// Clamp should be used only for auto set end time when start is changed

		setValue(fieldName, newTimeSpan); // save updated timespan to the form context
	}, [setupNewTimeSpan, setValue, fieldName]);

	const setStartTime = React.useCallback((time: SetStateAction<string | undefined>) => {
		const newTimeSpan = setupNewTimeSpan((ts) => {
			ts.start = typeof time === 'function' ? time(ts?.start) : time;
			return ts;
		});

		// Determine end time from start
		newTimeSpan.end = calculateTimeWithDuration(timeZone, newTimeSpan.start, durationMinutesRef.current, maxDate)
			// leave as when unable to calculate
			|| newTimeSpan.end;

		setValue(fieldName, newTimeSpan); // save update timespan to form context
	}, [fieldName, maxDate, setValue, setupNewTimeSpan, timeZone]);

	return {
		setStartTime,
		setEndTime,
	};
};

export default useTimeSpanAutoCalculate;
