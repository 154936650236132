import { Link, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import React from 'react';
import { useAnchorContext } from '@components/AnchorProvider/AnchorProvider';
import { LinkMenuProps } from '@components/LinkMenu/LinkMenuProps';

const useStyles = makeStyles((theme) => ({
	link: {
		color: theme.palette.primary.main,
		marginTop: theme.spacing(1),
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer',
	},
	linkMenuOpen: {
		color: theme.palette.primary.dark,
	},
}));

type LinkComponentProps = Pick<LinkMenuProps, 'label'> & { className?: string };

export const LinkComponent: React.FC<LinkComponentProps> = ({ label, className }) => {
	const classes = useStyles();
	const { handleSetAnchorEl, anchorEl } = useAnchorContext();

	return (
		<Link
			className={classNames(classes.link, className, { [classes.linkMenuOpen]: anchorEl })}
			variant="body2"
			onClick={handleSetAnchorEl}
			component="button"
			aria-controls="LinkMenu-menu"
			aria-haspopup="true"
		>
			{label} {anchorEl ? <ArrowDropUp data-field="arrowUp"/> : <ArrowDropDown data-field="arrowDown"/>}
		</Link>
	);
};
