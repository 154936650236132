import React from 'react';
import { NAVIGATION_WARN_MODAL } from '@components/Modal/modalConstants';
import { useModalContext } from '@components/Modal/ModalContextProvider';

interface EditFormNavigationHandlerParams {
	closeForm: () => void;
	isDirtyForm: boolean;
	editRequestFormModalId: string;
	pushToScheduling: () => void;
	isSchedulingEdit?: boolean;
}

interface EditFormNavigationHandlersReturnVals {
	handleConfirmNav: () => void;
	handleCancelNav: () => void;
	handleBackClick: () => void;
}

/**
 * Custom hook that provides navigation handler functions for the edit form.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {Function} params.closeForm - A function that closes the form when called.
 * @param {boolean} params.isDirtyForm - A boolean value indicating whether the form has unsaved changes.
 * @param {string} params.editRequestFormModalId - A string representing the unique ID of the edit request form modal.
 * @param {Function} params.pushToScheduling - A function that navigates to the scheduling page when called.
 * @param {boolean} [params.isSchedulingEdit=false] - An optional boolean value indicating whether the form is in scheduling edit mode.
 *
 * @returns {Object} An object containing the following properties:
 * - `handleConfirmNav`: A function that confirms navigation when called.
 * - `handleCancelNav`: A function that cancels navigation when called.
 * - `handleBackClick`: A function that handles the back button click event.
 */
export const useEditFormNavigationHandlerFunctions = ({
	closeForm,
	isDirtyForm,
	editRequestFormModalId,
	pushToScheduling,
	isSchedulingEdit,
}: EditFormNavigationHandlerParams): EditFormNavigationHandlersReturnVals => {
	const { closeModal, isModalOpen, openModal } = useModalContext();

	const handleConfirmNav = React.useCallback(() => {
		closeModal(editRequestFormModalId);
		closeForm();
	}, [closeForm, closeModal, editRequestFormModalId]);

	const handleCancelNav = React.useCallback(() => {
		closeModal(editRequestFormModalId);
	}, [closeModal, editRequestFormModalId]);

	const handleBackClick = React.useCallback(() => {
		if (isDirtyForm && !isModalOpen(NAVIGATION_WARN_MODAL) && !isSchedulingEdit) {
			openModal(editRequestFormModalId);
		} else {
			isSchedulingEdit ? pushToScheduling() : closeForm();
		}
	}, [
		closeForm,
		editRequestFormModalId,
		isDirtyForm,
		isModalOpen,
		isSchedulingEdit,
		openModal,
		pushToScheduling,
	]);

	return { handleConfirmNav, handleCancelNav, handleBackClick };
};
