import React from 'react';
import { Box, alpha } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		'display': 'flex',
		'flexDirection': 'row',
		'& > div': {
			'borderRight': `1px solid ${alpha(theme.palette.common.black, .12)}`,
			'paddingRight': theme.spacing(2),
			'marginRight': theme.spacing(2),
			'&:last-child': {
				borderRight: '0px none',
				paddingRight: theme.spacing(0),
				marginRight: theme.spacing(0),
			},
		},
	}
}));

const OptionsWithDivider: React.FC = ({ children }) => {
	const classes = useStyles();

	return <Box className={classes.root}>
		{children}
	</Box>;
};

export default OptionsWithDivider;
