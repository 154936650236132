import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import BlueOutlineButton from '@components/BlueOutlineButton/BlueOutlineButton';
import { Button } from '@ascension/ui-library';
import ModalViewer from '@components/Modal/ModalViewer';
import { ModalValues } from '@interfaces/ModalValues';

const useStyles = makeStyles(theme => createStyles({
	message: {
		color: 'rgba(0, 0, 0, 0.6)',
		fontWeight: 600,
		margin: theme.spacing(3, 0),
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		height: '48px',
	},
	yesButton: {
		'backgroundColor': theme.palette.primary.main,
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
		},
	},
	root: {
		width: '600px',
		padding: theme.spacing(3),
	},
}));

interface CriticalCriteriaChangedModalProps {
	modalValues: ModalValues;
	onKeepPrevious: () => void;
	onAcceptChange: () => void;
	onClose?: () => void;
}

export const CriticalCriteriaChangedModal: React.FC<CriticalCriteriaChangedModalProps> = ({ modalValues, onKeepPrevious, onAcceptChange, onClose }) => {
	const classes = useStyles();

	return (
		<ModalViewer id={modalValues.id} className={classes.root} onClose={onClose}>
			<Typography component="h2" variant="h6">{modalValues.title}</Typography>
			<Typography className={classes.message}>{modalValues.message}</Typography>
			<Box className={classes.buttonContainer}>
				<BlueOutlineButton
					data-field="requestForm-primaryProcedureChangedModal-noKeepPreviousButton"
					onClick={onKeepPrevious}
				>
						No, Keep Previous
				</BlueOutlineButton>
				<Button
					data-field="requestForm-primaryProcedureChangedModal-yesButton"
					className={classes.yesButton}
					translate="yes"
					color="white"
					onClick={onAcceptChange}
				>
						Yes
				</Button>
			</Box>
		</ModalViewer>
	);
};
