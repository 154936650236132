import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles, Typography, TypographyProps } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles(() => createStyles({
	root: {
		fontWeight: 500,
		lineHeight: '120%',
	},
}));

const CalendarErrorTitle: React.FC<TypographyProps & { component?: string }> = ({ children, className, ...props }) => {
	const classes = useStyles();

	return (
		<Typography
			align="center"
			component="h3"
			variant="h1"
			className={classNames(classes.root, className)}
			{...props}
		>
			{children}
		</Typography>
	);
};

export default CalendarErrorTitle;
