import RoundingAlgorithm from '@calendar/types/RoundingAlgorithm';

const MS_IN_MINUTES = 1000 * 60;

function findClosestNumberIndex(
	numbersList: { number: number }[],
): (s: number | Date | string, roundingAlg?: RoundingAlgorithm) => number {
	return (searchTime, roundingAlg = RoundingAlgorithm.CEIL) => {
		const searchMinutes = Math.floor(new Date(searchTime).getTime() / MS_IN_MINUTES) * MS_IN_MINUTES;
		for (let i = 0; i < numbersList.length; i++) {
			const item = numbersList[i].number;
			switch (roundingAlg) {
			case RoundingAlgorithm.CEIL:
				if (searchMinutes <= item) {
					return i;
				}
				break;
			case RoundingAlgorithm.FLOOR:
				if (searchMinutes < item) {
					return i - 1;
				}
				if (searchMinutes === item) {
					return i;
				}
				break;
			}
		}
		return numbersList.length;
	};
}

export default findClosestNumberIndex;
