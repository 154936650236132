import { Index } from '@components/PdfViewerPage/IndexingSection/IndexingSectionContext/IndexingSectionContextType';

export const PAGES_PREFIX = 'pages::';
export const DOCUMENT_TYPE_PREFIX = 'documentType::';

const getFormStateFromIndexes = (indexes: Required<Index>[]): Record<string, string> => {
	return indexes.reduce<Record<string, string>>(( formState, index): Record<string, string> => {
		return {
			...formState,
			[`${PAGES_PREFIX}${index.id}`]: index.page,
			[`${DOCUMENT_TYPE_PREFIX}${index.id}`]: index.type,
		};
	}, {});
};

export default getFormStateFromIndexes;
