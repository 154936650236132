import React from 'react';
import classNames from 'classnames';
import { Box, Card, Tooltip, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import useSurgeonName from '@components/AbbreviatedProcedureCard/useSurgeonName/useSurgeonName';
import { ProcedureCardProps } from '@components/ProcedureCard/ProcedureCardProps';

const DEFAULT_TEXT = '---';

const useStyles = makeStyles((theme) => createStyles({
	arrow: {
		color: theme.palette.grey[800]
	},
	bodySection: {
		display: 'flex',
		flexDirection: 'column',
	},
	headerSection: {
		alignItems: 'center',
		display: 'flex',
		height: '2.25rem',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
		[theme.breakpoints.down('xs')]: {
			flexWrap: 'wrap',
		},
	},
	procedureCard: {
		alignItems: 'start',
		background: theme.palette.primary[50],
		borderBottom: 'none',
		boxShadow: 'none',
		display: 'flex',
		flexDirection: 'column',
		gap: `${theme.spacing(3)}px`,
		marginBottom: theme.spacing(2),
		padding: theme.spacing(3),
	},
	procedureName: {
		display: '-webkit-box',
		color: theme.palette.grey['900'],
		maxHeight: '3rem',
		maxWidth: '100%',
		overflow: 'hidden',
		textDecoration: 'underline',
		textDecorationStyle: 'dotted',
		textOverflow: 'ellipsis',
		textTransform: 'uppercase',
		textUnderlineOffset: `${theme.spacing(0.5)}px`,
		['-webkit-line-clamp']: 2,
		['-webkit-box-orient']: 'vertical',
	},
	sharedText: {
		letterSpacing: '0.15px',
		lineHeight: 1.5,
	},
	surgeonName: {
		marginTop: theme.spacing(0.5),
	},
	title: {
		color: theme.palette.primary[800],
	},
	tooltip: {
		backgroundColor: theme.palette.grey[800],
		fontSize: '1rem',
		lineHeight: 1.5,
		maxWidth: '228px',
		textTransform: 'uppercase',
	},
}));

export const ProcedureCard = ({ id, info, menuComponent }: ProcedureCardProps) => {
	const classes = useStyles();
	const surgeonObj = info.surgeon || {};
	const surgeonId = 'value' in surgeonObj ? surgeonObj.value : DEFAULT_TEXT;
	const surgeonName = useSurgeonName(surgeonId);

	return (
		<Card className={classes.procedureCard} data-field="procedureCard">
			<Box className={classes.headerSection}>
				<Typography
					className={classNames(classes.title, classes.sharedText)}
					component="h3"
					variant="h3"
				>
					Procedure {info.isPrimaryProcedure === 1 && <span>&#8212; Primary</span>}
				</Typography>
				{menuComponent}
			</Box>
			<Box className={classes.bodySection}>
				<Tooltip
					arrow
					classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
					placement="top-end"
					title={info.procedureName || DEFAULT_TEXT}
					data-field={`tooltip-${id}`}
				>
					<Typography
						className={classNames(classes.procedureName, classes.sharedText)}
						data-field={`procedureName-${id}`}
						component="h4"
						variant="h3"
					>
						{info.procedureName || DEFAULT_TEXT}
					</Typography>
				</Tooltip>
				<Typography data-field="surgeon" className={classes.surgeonName}>
					{surgeonName}
				</Typography>
			</Box>
		</Card>
	);
};
