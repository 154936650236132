import React, { FormEvent } from 'react';
import { DateRangePicker, DateRangePickerProps, Range, RangeKeyDict } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Box, createStyles, makeStyles, Popover, PopoverProps } from '@material-ui/core';
import BlueOutlineButton from '@components/BlueOutlineButton/BlueOutlineButton';
import GoldButton from '@components/GoldButton/GoldButton';
import { useTheme } from '@material-ui/core/styles';
import { DATE_RANGE_SELECTION_KEY } from '@utilities/constants';
import { addBusinessDays, differenceInBusinessDays } from 'date-fns';

const useStyles = makeStyles((theme) =>
	createStyles({
		datePickerWrapper: {
			'fontFamily': theme.typography.fontFamily,
			'& span': {
				fontWeight: theme.typography.fontWeightLight,
			},
			'& .rdrDateRangeWrapper': {
				width: '560px',
			},
			'& .rdrDateDisplayWrapper': {
				backgroundColor: 'unset',
				paddingTop: theme.spacing(1),
			},
			'& .rdrDefinedRangesWrapper': {
				display: 'none',
			},
			'& .rdrDayToday .rdrDayNumber span:after': {
				display: 'none',
			},
			'& .rdrEndEdge, .rdrStartEdge': {
				color: theme.palette.common.white,
			},
			'& .rdrDayToday:not(.rdrDayPassive) .rdrDayNumber': {
				borderRadius: theme.spacing(2),
			},
			'& button.rdrDay:not(.rdrDayPassive):has(.rdrStartEdge), button.rdrDay:not(.rdrDayPassive):has(.rdrEndEdge)':
				{
					'& .rdrDayNumber': {
						'backgroundColor': theme.palette.primary.main,
						'borderRadius': theme.spacing(2),
						'& span': {
							color: theme.palette.common.white,
						},
					},
				},
			'& .rdrDateDisplay span input': {
				fontWeight: theme.typography.fontWeightMedium,
				color: theme.palette.common.black,
				fontFamily: theme.typography.fontFamily,
			},
			'& .rdrMonthAndYearPickers span select': {
				fontFamily: theme.typography.fontFamily,
			},
			'& .rdrDayPassive .rdrDayNumber span': {
				display: 'none',
			},
			'& .rdrDateDisplayItem': {
				'borderWidth': theme.spacing(0.125),
				'borderColor': theme.palette.grey[500],
				'boxShadow': 'none',
				'&:hover': {
					borderColor: theme.palette.primary.main,
					borderWidth: theme.spacing(0.25),
				},
				'&:focus-within': {
					borderColor: theme.palette.primary.main,
					borderWidth: theme.spacing(0.25),
				},
			},
			'& .rdrMonthName': {
				...theme.typography.h3,
				display: 'flex',
				justifyContent: 'center',
				color: theme.palette.common.black,
			},
			'& .rdrMonthAndYearPickers': {
				display: 'none',
			},
			'& .rdrMonthAndYearWrapper': {
				height: 0,
			},
			'& .rdrNextPrevButton': {
				marginBottom: theme.spacing(-5.5),
			},
			'& .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span':
				{
					color: theme.palette.common.black,
				},
		},
		buttonsContainer: {
			'display': 'flex',
			'justifyContent': 'space-between',
			'backgroundColor': theme.palette.common.white,
			'padding': theme.spacing(0, 3, 3, 3),
			'height': '36px',
			'& button:last-child': {
				marginLeft: theme.spacing(2),
			},
		},
		buttonsContainerInner: {
			display: 'flex',
		},
	}),
);

export interface DatePeriodPickerProps {
	startDate: string;
	endDate: string;
	defaultStartDate?: Date;
	defaultEndDate?: Date;
	onStartChange: (date?: Date) => void;
	onEndChange: (date?: Date) => void;
	anchor: (EventTarget & HTMLElement) | null;
	onClose: () => void;
	onCancel?: () => void;
	onApplyChange?: (dateRange: Range) => void;
	dateRangePickerProps?: DateRangePickerProps;
	popoverProps?: Partial<PopoverProps>;
	showActionButtons?: boolean;
	previewRangeDays?: number;
	handleDisableDates?: (date: Date) => boolean;
}

const DatePeriodPicker = ({
	startDate,
	endDate,
	defaultStartDate,
	defaultEndDate,
	onStartChange,
	onEndChange,
	anchor,
	onClose,
	onCancel,
	onApplyChange,
	dateRangePickerProps,
	popoverProps,
	showActionButtons = true,
	previewRangeDays,
	handleDisableDates,
}: DatePeriodPickerProps) => {
	const classes = useStyles();
	const theme = useTheme();

	const handleChange = React.useCallback((date: RangeKeyDict) => {
		onStartChange(date[DATE_RANGE_SELECTION_KEY].startDate);
		onEndChange(date[DATE_RANGE_SELECTION_KEY].endDate);
	}, [onEndChange, onStartChange]);

	const resetDateRange = React.useCallback( () => {
		onStartChange(defaultStartDate);
		onEndChange(defaultEndDate);
	}, [defaultEndDate, defaultStartDate, onEndChange, onStartChange]);

	const rangeValue = React.useMemo(() => ({
		key: DATE_RANGE_SELECTION_KEY,
		startDate: startDate ? new Date(startDate) : defaultStartDate,
		endDate: endDate ? new Date(endDate) : defaultEndDate,
	}), [defaultEndDate, defaultStartDate, endDate, startDate]);

	const previewRange = React.useMemo(() => {
		// if no previewRangeDays specified or no startDate, do not show preview range
		if (!previewRangeDays || !rangeValue.startDate) {
			return undefined;
		}

		const previewStartDate = new Date(rangeValue.startDate);
		let previewEndDate = rangeValue.endDate ? new Date(rangeValue.endDate) : previewStartDate; // Use startDate as a fallback
		const startEndDifference = differenceInBusinessDays(previewEndDate, previewStartDate);

		// Extend endDate preview if the difference is less than previewRangeDays
		if (startEndDifference < previewRangeDays) {
			previewEndDate = addBusinessDays(previewStartDate, previewRangeDays);
		} else {
			// If the range meets/exceeds previewRangeDays, do not show preview range
			return undefined;
		}

		return {
			color: theme.palette.grey[200],
			startDate: previewStartDate,
			endDate: previewEndDate,
		};
	}, [rangeValue.startDate, rangeValue.endDate, theme.palette.grey, previewRangeDays]);

	const handleApplyDateChange = React.useCallback((e: FormEvent) =>  {
		e.preventDefault();
		onApplyChange?.(rangeValue);
	}, [onApplyChange, rangeValue]);

	const handleCancel = React.useCallback(() => {
		onClose();
		onCancel?.();
	}, [onCancel, onClose]);

	return (
		<>
			<Popover
				open={!!anchor}
				anchorEl={anchor}
				onClose={onClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				{...popoverProps}
			>
				<form onSubmit={handleApplyDateChange}>
					<DateRangePicker
						ranges={[rangeValue]}
						preview={previewRange}
						rangeColors={[theme.palette.grey[200]]}
						onChange={handleChange}
						months={2}
						direction="horizontal"
						className={classes.datePickerWrapper}
						weekdayDisplayFormat="EEEEEE"
						dateDisplayFormat="MM/dd/yyyy"
						monthDisplayFormat="MMMM yyyy"
						editableDateInputs={true}
						startDatePlaceholder="MM/DD/YYYY"
						endDatePlaceholder="MM/DD/YYYY"
						showMonthAndYearPickers={false}
						disabledDay={handleDisableDates}
						{...dateRangePickerProps}
					/>
					{showActionButtons && (
						<Box className={classes.buttonsContainer}>
							<BlueOutlineButton onClick={resetDateRange}>Reset</BlueOutlineButton>
							<Box className={classes.buttonsContainerInner}>
								<BlueOutlineButton onClick={handleCancel}>Cancel</BlueOutlineButton>
								<GoldButton type="submit">Apply</GoldButton>
							</Box>
						</Box>
					)}
				</form>
			</Popover>
		</>
	);
};

export default DatePeriodPicker;
