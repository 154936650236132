import { useCalendarContext } from '@calendar';
import React from 'react';
import useListElmRefs from '@calendar/utilities/useListElmRefs/useListElmRefs';
import { useDebounceCallBack } from '@utilities/hooks/useDebounce/useDebounceCallBack';

const useSetHeaderHeightWithRef = () => {
	const { setHeaderHeight, columns } = useCalendarContext();

	const {
		getElmAdderFor,
		elmList,
		clearList,
	} = useListElmRefs();

	const columnNames = React.useMemo(() => columns.sort().join(), [columns]);
	React.useLayoutEffect(() => clearList, [clearList, columnNames]);

	const getMaxHeight = React.useCallback(() => {
		const max = elmList.reduce((maxSoFar, elm) => {
			const { height = 0 } = elm?.getBoundingClientRect?.() || {};
			return Math.max(maxSoFar, height);
		}, 0);

		setHeaderHeight(max);
	}, [elmList, setHeaderHeight]);

	const resetHeaderHeightDebounced = useDebounceCallBack(getMaxHeight, 50);

	React.useLayoutEffect(() => {
		resetHeaderHeightDebounced();
		window.addEventListener('resize', resetHeaderHeightDebounced);

		return () => {
			window.removeEventListener('resize', resetHeaderHeightDebounced);
		};
	}, [resetHeaderHeightDebounced]);

	return {
		getElmAdderFor,
		clearList,
	};
};

export default useSetHeaderHeightWithRef;
