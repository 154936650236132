import { applyMiddleware, createStore, Store } from 'redux';
import { rootReducer } from './reducers/rootReducer';
import apiMiddleware from './middlewares/api/apiMiddleware';
import thunk from 'redux-thunk';
import { RootState } from '../interfaces/rootState';

export default function configureStore (): Store<RootState> {
	return createStore(
		rootReducer,
		applyMiddleware(
			thunk,
			apiMiddleware,
		),
	);
}
