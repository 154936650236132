import React from 'react';
import { Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
	wrapper: {
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(3),
		backgroundColor: '#FAFDFF',
		borderRadius: '4px',
		border: `1px solid ${theme.palette.primary.main}`,
	}
}));

export const CustomInputWrapper: React.FC = ({ children }) => {
	const classes = useStyles();
	return (
		<Box className={classes.wrapper}>
			{children}
		</Box>
	);
};
