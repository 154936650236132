import { Reducer } from 'redux';
import {
	API_START,
	REQUEST_FORM_POST,
	RESET_FORM,
	SAVE_FORM_ERROR,
	SAVE_FORM_SUCCESS,
	ADD_START_TIME,
	CLEAR_FORM_ERROR
} from '@store/actions/types';
import { RequestFormState } from '@interfaces/RequestForm/RequestFormState';
import { RequestFormAction } from '@interfaces/RequestForm/RequestFormAction';
import { RequestFormStatus } from '@interfaces/RequestForm/RequestFormStatus';
import { ApiStatusAction } from '@interfaces/ApiStatus/ApiStatusAction';

const initialState: RequestFormState = {
	formStatus: RequestFormStatus.PRISTINE,
	formError: null,
	startTime: null
};

export const RequestFormReducer: Reducer<RequestFormState, RequestFormAction | ApiStatusAction> =
	(currentState: RequestFormState = initialState, action: RequestFormAction | ApiStatusAction) => {
		let error: string | null = null;
		if (action.type === SAVE_FORM_ERROR && action.error) {
			if ((action.error as Error).message) {
				error = (action.error as Error).message;
			}
			else if (typeof action.error === 'string') {
				error = action.error;
			}
			else {
				error = 'There was an error';
			}
		}

		switch (action.type) {
		case API_START:
			if (action.payload.label !== REQUEST_FORM_POST) { return currentState; }
			return { ...currentState, formStatus: RequestFormStatus.LOADING };
		case SAVE_FORM_SUCCESS:
			return { ...currentState, formStatus: RequestFormStatus.SUBMITTED };
		case SAVE_FORM_ERROR:
			return { ...currentState, formStatus: RequestFormStatus.ERROR, formError: error };
		case CLEAR_FORM_ERROR:
			return { ...currentState, formStatus: RequestFormStatus.READY_TO_SUBMIT, formError: null };
		case RESET_FORM:
			return { ...initialState };
		case ADD_START_TIME:
			return { ...currentState, startTime: action.payload };
		}
		return currentState;
	};

