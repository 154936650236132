/***
 * padString - pad a string at the start or end to get to total length expected
 *
 * @param content {string} - Content of string that should be padded.
 * @param totalLength {number: 2} - If content.length is less than the totalLength, will be padded to get to
 *  totalLength. The content will not be truncated if more than totalLength.
 * @param padChar {string: '0'} - Character to use for padding. Must be single character.
 * @param isSuffix {boolean: false} - add characters to end when TRUE otherwise add characters to start
 *
 * @returns {string} with padding
 *
 * @example
 * padString('1') // returns '01'
 *
 * @example
 * padString('Hello', 10, '-', true) // returns 'Hello-----'
 *
 */
const padString = (content: string, totalLength = 2, padChar = '0', isSuffix = false) => {
	if (padChar.length !== 1) {
		throw new Error(`'${padChar}' is ${padChar.length} characters long but padChar must be 1 character long.`);
	}
	let charPadding = '';
	while (charPadding.length < totalLength - content.length) {
		charPadding += padChar;
	}

	return isSuffix ? `${content}${charPadding}` : `${charPadding}${content}`;
};

export default padString;
