import { utcToZonedTime } from 'date-fns-tz';

const TIMEZONE_END_PATTERN = /.+Z|([+-])(\d{2})(?::?\d{2})?$/i;

/**
 * @deprecated Do not provide a timezone string. It can cause problems and will be removed in a future update.
 */
function getDateFromApiDateTime(d: string | Date | number, timezone: string | undefined): Date;
function getDateFromApiDateTime(d: string | Date | number): Date;
/***
 * getDateFromApiDateTime(d: string | Date | number): Date
 * API returns dates as a UTC string in the format "yyyy-MM-dd hh:mm"
 * Because there is not a Z on the end, this is treated a local time using the browsers timezone.
 * To resolve this, this method appends Z to the end to indicate it is UTC if no timezone provided.
 * If a timezone is provided, the date is converted to that timezone.
 *
 * @param d {Date | string | number} - string or Date from API or other.
 * @param timezone {string | undefined} - IANA timezone string. - avoid this string. It will be removed in a future update.
 *
 * @returns {Date} representing intended time based on string date-time format from API
 */
function getDateFromApiDateTime(d: string | Date | number, /** @deprecated*/ timezone?: string): Date {
	const date = new Date(typeof d !== 'string' || TIMEZONE_END_PATTERN.test(d) ?
		d :
		`${d}Z`
	);

	return timezone ? utcToZonedTime(date, timezone) : date;
}

export default getDateFromApiDateTime;
