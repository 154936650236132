import React from 'react';
import {
	useDocumentDetailsContext
} from '@components/CaseDetails/DocumentInformationDisplay/DocumentDetails/DocumentDetails';
import DocumentField
	from '@components/CaseDetails/DocumentInformationDisplay/DocumentDetails/DocumentField/DocumentField';
import { format } from 'date-fns';
import getDateFromApiDateTime from '../../../../../calendar/utilities/getDateFromApiDateTime/getDateFromApiDateTime';
import { DisplayTextProps } from '@components/DisplayText/DisplayTextProps';

const DocumentDateUploadedField: React.FC<Partial<DisplayTextProps>> = (props) => {
	const { createdAt } = useDocumentDetailsContext();
	return <DocumentField fieldName="createdAt" displayDefault={!createdAt} {...props}>
		{format(getDateFromApiDateTime(createdAt), 'MMMM d, yyyy h:mm aa')}
	</DocumentField>;
};

export default DocumentDateUploadedField;
