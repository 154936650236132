import React from 'react';
import DialogHeaderProps from './DialogHeaderProps';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	root: {
		display: 'flex',
		width: '100%',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	controls: {
		display: 'flex',
		justifyContent: 'space-between',
		justifySelf: 'flex-end',
	},
}, { name: 'SsmDialogHeader' });

/***
 * DialogHeader to display title on left and controls (typically close button) on right.
 *
 * Uses 'SsmDialogHeader' for style name. Root can be selected using 'SsmDialogHeader-root'.
 *
 * @param title {ReactNode} - optional title rendered as H1 when a string, otherwise renders component as is
 * @param children - typically buttons with classname SsmDialogHeader-controls
 * @constructor
 */
const DialogHeader: React.FC<DialogHeaderProps> = ({ title, children }) => {
	const classes = useStyles();

	return <Box className={`${classes.root} SsmDialogHeader-root`}>
		{typeof title === 'string' ? <Typography variant="h1">{title}</Typography> : title}
		<Box className={`${classes.controls} SsmDialogHeader-controls`}>
			{children}
		</Box>
	</Box>;
};

export default DialogHeader;
