import React from 'react';
import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import { useFieldFormContext } from '@utilities/hooks/useFieldFormContext/useFieldFormContext';
import { SurgeryInfoValidationSchema } from '@components/RequestForm/SurgeryInformation/SurgeryInfoValidationSchema';
import TextFieldInput from '@components/TextFieldInput/TextFieldInput';
import TextLink from '@components/TextLink/TextLink';

const useStyles = makeStyles(theme => createStyles({
	diagnosisInput: {
		marginBottom: theme.spacing(4),
	},
	lookUpTextLink: {
		marginRight: theme.spacing(.5),
		marginBottom: theme.spacing(1),
		zIndex: 1,
	},
	sectionTitle: {
		fontWeight: 500,
	},
}));

interface DiagnosisInputProps {
	type: 'primary' | 'secondary';
}

const DiagnosisInput = ({ type }: DiagnosisInputProps) => {
	const classes = useStyles();

	const {
		currentValue,
		setCurrentValue,
		error,
		triggerSelfValidation,
	} = useFieldFormContext<string>(`${type}Diagnosis`, type === 'primary' ? {
		validationRules: SurgeryInfoValidationSchema.primaryDiagnosis,
	} : {});

	return (
		<Box>
			{type === 'primary' &&
				<Typography className={classes.sectionTitle} component="h3">ICD-10</Typography>
			}
			<Box flexDirection="row" flexWrap="wrap" mt={1}>
				<Box position="relative" width={1}>
					<Box position="absolute" right={0}>
						<TextLink className={classes.lookUpTextLink} onClick={()=> window.open('https://icd10cmtool.cdc.gov/', '_blank', 'noopener,noreferrer')} id="lookUpPrimary">Look up</TextLink>
					</Box>
					<Box data-field={`${type}Diagnosis`} className={classes.diagnosisInput}>
						<TextFieldInput
							placeholder="Type ICD-10 or diagnosis"
							id={`${type}Diagnosis`}
							label={`${type} Diagnosis`}
							fieldName={`${type}Diagnosis`}
							required={type === 'primary'}
							value={currentValue}
							error={!!error}
							helperText={type === 'secondary' && !error && 'List in order of priority'}
							errorMessage={error}
							onBlur={triggerSelfValidation}
							onChange={setCurrentValue}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default DiagnosisInput;
