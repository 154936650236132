import { SpecialtiesIndex } from '../components/AccountSettings/MyFilters/SaveFiltersContext/SaveFiltersProps';
import { MyFiltersState } from '../interfaces/MyFilters/MyFiltersState';
import { UserFormHospital, UserFormPractice, UserHospitalUpdate } from '../interfaces/UserPrefInterface';
import { Surgeon } from '../data/surgeon/Surgeon';
import { FilterApiParams, FilterStateProps } from '../interfaces/FilterParams';
import { DEFAULT_CASES_SORT } from './apiConstants';
import { MyFiltersDefaultValues } from '../components/AccountSettings/MyFilters/MyFiltersDefaultValues';

export const sortSpecialtyIdsByName = (specialtyList: SpecialtiesIndex) => Object.keys(specialtyList).map((key) => ({
	id: key,
	name: specialtyList[key].specialtyName
})).sort((i, j) => {
	if (i.name > j.name) { return 1; }
	if (i.name < j.name) { return -1; }
	return 0;
}).map(k => k.id);

export const parseFilterUrl = (filterUrl: string): FilterStateProps => {
	const stringItems = [ 'sortModel', 'procedureDate', 'from', 'to', 'page', 'size', 'globalFilter' ];
	const urlParams = new URLSearchParams(filterUrl);
	const arrayedParams = Array.from(urlParams.entries());
	const parsedObj = arrayedParams.reduce((acc: Record<string, string | string[]>, [key, value]) => {
		if ( stringItems.includes(key) ) {
			acc[key] = value;
		} else if ( acc[key] && acc[key].length ) {
			const foundKey = acc[key] as string[];
			foundKey.push(value);
		} else {
			acc[key] = [ value ];
		}
		return acc;
	}, {});

	return { ...MyFiltersDefaultValues, ...parsedObj };
};


export const filterStateEqualToDefault = (savedFilters: MyFiltersState): boolean => {
	// .some should return true and stop checking values as soon as a non default value is encountered.
	// If all values are default, then will return false (switched to true)
	return !Object.values(savedFilters).some((curr) =>
		// Null is not a default
		curr === null ||
		// String that is not empty or DEFAULT_CASES_SORT is not a default
		(typeof curr === 'string' && curr.length > 0 && curr !== DEFAULT_CASES_SORT) ||
		// Array with multiple values is not a default
		(Array.isArray(curr) && curr.length > 0)
	);
};

export const composeFilterUrl = (filterState: MyFiltersState) => {
	return filterStateEqualToDefault(filterState) ? '' : Object.entries(filterState).reduce((acc: string[], [key, value]) => {
		if (typeof value === 'string') {
			if (value === '') {
				return acc;
			}
			return [...acc, `${key}=${value}`];
		} else {
			return [...acc, (value as string[]).map(val => `${key}=${val}`).join('&')];
		}
	}, []).join('&');
};

export const practicesListFromSavedSurgeons = (selectedSurgeons: string[] | undefined, fullSurgeonList: Surgeon[], primaryPracticeId: string): UserFormPractice[] => {
	if (selectedSurgeons?.length === 0) {
		return [{
			id: primaryPracticeId,
			primary: true,
			surgeons: [...fullSurgeonList.map((surgeon: Surgeon) => surgeon.id)],
		}];
	} else {
		return [{
			id: primaryPracticeId,
			primary: true,
			surgeons: selectedSurgeons ? [...selectedSurgeons] : [],
		}];
	}
};

export const hospitalsListFromSavedUnits = (selectedUnits: string[] | undefined, allUnitsByHospital: UserFormHospital[], primaryHospitalId: string): UserHospitalUpdate[] => {
	if (selectedUnits?.length === 0) {
		return allUnitsByHospital.map((hospital: UserFormHospital) => {
			const isPrimary = hospital.id === primaryHospitalId;
			return {
				id: hospital.id,
				primary: isPrimary,
				units: hospital.units.map(unit => unit.id),
			};
		});
	} else {
		return allUnitsByHospital.map(({ id, units }) => {
			const isPrimary = id === primaryHospitalId;
			const unitIds = units.map(u => u.id);
			return {
				id: id,
				primary: isPrimary,
				units: selectedUnits ? selectedUnits.filter(selectedUnitId => unitIds.includes(selectedUnitId)) : [],
			};
		});
	}
};

export const savedFiltersToApiParams = (savedFilters: FilterStateProps ): FilterApiParams => {
	const savedFilterKeys = Object.keys(savedFilters) as Array<keyof FilterStateProps>;

	const formattedSaveFilters: FilterApiParams = savedFilterKeys.reduce( (acc, curr) => {
		const value = savedFilters[curr];
		const formattedValue =  Array.isArray(value) ? value.join(',') : value;
		return { ...acc, [curr]: formattedValue };
	}, {});

	return formattedSaveFilters;
};
