import React from 'react';
import { useCalendarContext } from '@calendar';
import { Box, BoxProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { COLUMN_OFFSET, TICK_OFFSET } from '@calendar/utilities/CalendarConstants';
import MemoizedTimeTick from '@calendar/types/MemoizedTimeTick';
import ColumnInfo from '@calendar/types/ColumnInfo';

const useStyles = makeStyles({
	invisibleBox: {
		zIndex: 1,
	},
});

interface ClickDetectorProps extends BoxProps {
	tick: MemoizedTimeTick;
	column: ColumnInfo;
}
const ClickDetector: React.FC<ClickDetectorProps> = ({ tick, column: { name }, ...boxProps }) => {
	const { onCalendarClick } = useCalendarContext();

	const handleCalendarClick = React.useCallback(() => {
		onCalendarClick(name, tick);
	}, [name, onCalendarClick, tick]);

	return React.useMemo(() => <Box
		onClick={handleCalendarClick}
		{...boxProps}
	/>, [boxProps, handleCalendarClick]);
};

const ClickDetectorColumn = ({ column, columnIndex }: { column: ColumnInfo; columnIndex: number }) => {
	const classes = useStyles();
	const { memoizedTicks: ticks } = useCalendarContext();

	return React.useMemo(() => (<>
		{ticks.map((tick, tickIndex) =>
			<ClickDetector
				key={`${column.name}_${tick.minutesSinceMidnight}`}
				column={column}
				tick={tick}
				className={classes.invisibleBox}
				data-field="clickDetector"
				style={{
					gridRow: tickIndex + TICK_OFFSET,
					gridColumn: columnIndex + COLUMN_OFFSET,
				}}
			/>
		)}
	</>), [ticks, column, classes.invisibleBox, columnIndex]);
};

const ClickDetectors = () => {
	const { columns } = useCalendarContext();

	return React.useMemo(() => (
		<>
			{columns.map((column, columnIndex) =>
				<ClickDetectorColumn key={column.name} column={column} columnIndex={columnIndex} />
			)}
		</>
	), [columns]);
};

export default ClickDetectors;
