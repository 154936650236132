import React from 'react';
import { Alert } from '../Alert/Alert';
import { NotificationTypeEnum } from '../../../interfaces/SurgeryRequest/NotificationTypeEnum';
import { NotificationAlertProps } from './NotificationAlertProps';
import { UnscheduledAlertBody } from './UnscheduledAlertBody/UnscheduledAlertBody';
import { PendingCancellationAlertBody } from './PendingCancellationAlertBody/PendingCancellationAlertBody';
import { CancelledAlertBody } from './CancelledAlertBody/CancelledAlertBody';
import DataConflictAlertBody from './DataConflictAlertBody/DataConflictAlertBody';
import UpdatedDateAlertBody from './UpdatedDateAlertBody/UpdatedDateAlertBody';
import { HospitalRoles, OfficeRoles } from '../../../interfaces/UserProfile/UserRoleEnum';

export const NotificationAlert = (notificationProps: NotificationAlertProps) => {
	switch (notificationProps.notification.type) {
	case NotificationTypeEnum.WARNING:
		return <Alert
			data-section="revisionAlert"
			tagColor="#BF0000"
			headerColor="#F8D7DA"
			tagText={NotificationTypeEnum.WARNING}
			headerText={notificationProps.notification.title}
			body={<UnscheduledAlertBody description={notificationProps.notification.description} />}
		/>;
	case NotificationTypeEnum.PENDING_CANCELLED:
		return <Alert
			data-section="revisionAlert"
			tagColor="#BF0000"
			headerColor="#F8D7DA"
			tagText={NotificationTypeEnum.WARNING}
			headerText={notificationProps.notification.title}
			body={<PendingCancellationAlertBody/>}
		/>;
	case NotificationTypeEnum.CANCELLED:
		return <Alert
			data-section="revisionAlert"
			tagColor="#BF0000"
			headerColor="#F8D7DA"
			tagText={NotificationTypeEnum.WARNING}
			headerText={notificationProps.notification.title}
			body={<CancelledAlertBody/>}
		/>;
	case NotificationTypeEnum.EHR_CONFLICT:
		return <Alert
			data-section="revisionAlert"
			tagColor="#BF0000"
			headerColor="#F8D7DA"
			tagText={NotificationTypeEnum.WARNING}
			headerText="Case has data conflicts with the EHR."
			body={<DataConflictAlertBody />}
			showForRoles={HospitalRoles}
		/>;
	case NotificationTypeEnum.UPDATED_DATE:
		return <Alert
			data-section="revisionAlert"
			tagColor="#BF0000"
			headerColor="#F8D7DA"
			tagText={NotificationTypeEnum.WARNING}
			headerText="Case date has been updated."
			body={<UpdatedDateAlertBody revision={notificationProps.notification}/>}
			showForRoles={OfficeRoles}
		/>;
	default:
		return null;
	}
};
