import DropDownOptionProps from '@components/CaseDetails/ManageCaseDropDown/DropDownOptions/DropDownOptionProps';
import { EditCaseOption } from '@components/CaseDetails/ManageCaseDropDown/DropDownOptions/EditCase/EditCaseOption';
import { HospitalInteractiveRoles, OfficeInteractiveRoles, UserRole } from '@interfaces/UserProfile/UserRoleEnum';
import { CopyCaseOption } from '@components/CaseDetails/ManageCaseDropDown/DropDownOptions/CopyCase/CopyCaseOption';
import CancelCaseOption from '@components/CaseDetails/ManageCaseDropDown/DropDownOptions/CancelCase/CancelCaseOption';
import {
	RequestRevisionOption
} from '@components/CaseDetails/ManageCaseDropDown/DropDownOptions/RequestRevision/RequestRevisionOption';
import { ViewActivity } from '@components/CaseDetails/ManageCaseDropDown/DropDownOptions/ViewActivity/ViewActivity';
import {
	RefreshActivity
} from '@components/CaseDetails/ManageCaseDropDown/DropDownOptions/RefreshActivity/RefreshActivity';

export const dropDownOptionsConfig: DropDownOptionProps[] = [
	{
		renderAs: EditCaseOption,
		roles: OfficeInteractiveRoles,
		orderBy: 'Edit',
		id: 'EDIT_DROPDOWN_OPTION',
	},
	{
		renderAs: CopyCaseOption,
		roles: OfficeInteractiveRoles,
		orderBy: 'Copy',
		id: 'COPY_DROPDOWN_OPTION',
	},
	{
		renderAs: CancelCaseOption,
		roles: OfficeInteractiveRoles,
		orderBy: 'Cancel',
		id: 'CANCEL_DROPDOWN_OPTION',
	},
	{
		renderAs: RequestRevisionOption,
		roles: HospitalInteractiveRoles,
		orderBy: 'Request Revision',
		id: 'REQUEST_REVISION_DROPDOWN_OPTION',
		showWhenCaseStatus: (status?: string) => !!status && !['CANCELLED', 'PENDING_CANCELLED'].includes(status),
	},
	{
		renderAs: ViewActivity,
		roles: Object.values(UserRole),
		id: 'VIEW_ACTIVITY_DROPDOWN_OPTION',
		orderBy: 'View Activity'
	},
	{
		renderAs: RefreshActivity,
		roles: Object.values(UserRole),
		id: 'REFRESH_ACTIVITY_DROPDOWN_OPTION',
		orderBy: 'View Activity'
	},
];
