import { useDispatch } from 'react-redux';
import React from 'react';
import {
	getHoldEventApiDeleteAction,
} from '@data/holdEvent/actionCreators/HoldEventActionCreators';
import { DeleteHoldEventApiPayload } from '@data/holdEvent/types/HoldEventApiPayload';

const useDeleteHoldEvent = () => {
	const dispatch = useDispatch();

	return React.useCallback((id: string, overrideRequest: DeleteHoldEventApiPayload = {}) => {
		dispatch(getHoldEventApiDeleteAction(id, overrideRequest));
	}, [dispatch]);
};

export default useDeleteHoldEvent;
