import React from 'react';
import { ScrollWithinBoxContext } from './ScrollWithinBoxContext';

export const useScrollWithinBox = () => {
	const ctx = React.useContext(ScrollWithinBoxContext);
	if (!ctx) {
		throw new Error('useScrollWithinBox must be used in a child component of ScrollWithinBox.');
	}

	return ctx;
};
