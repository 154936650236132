import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CaseDetailsInfoSelector } from '@store/selectors/CaseDetailsSelector';
import { RootState } from '@interfaces/rootState';
import { SurgeryRequestGet } from '@interfaces/SurgeryRequest/SurgeryRequestGet';
import { Alert } from '../Alert/Alert';
import { RevisionStatus } from '@interfaces/SurgeryRequest/RevisionStatusEnum';
import { RevisionAlertProps } from './RevisionAlertProps';
import { RevisionAlertBody } from './RevisionAlertBody/RevisionAlertBody';
import { setRevisionToCleared } from '@store/actions/RevisionActionCreators';

export const RevisionAlert = ({ revisionStatus }: RevisionAlertProps) => {
	const {
		id: requestId,
		revisions,
	} = useSelector(CaseDetailsInfoSelector as (state: RootState) => SurgeryRequestGet);

	const dispatch = useDispatch();

	const setRevisionCleared = useCallback((revId: string, accepted: boolean) => {
		dispatch(setRevisionToCleared(requestId, revId, accepted));
	}, [dispatch, requestId]);

	if (!revisions || revisions.length === 0) {
		return null;
	}

	const filteredRevisions = revisions.filter((rev) => rev.status === revisionStatus);

	if (filteredRevisions.length === 0) {
		return null;
	}

	let tagText = '';
	let headerText = '';
	switch (revisionStatus) {
	case RevisionStatus.NEED_REVISION:
		tagText = 'Needs Revision';
		headerText = 'Revision Request Pending';
		break;
	case RevisionStatus.REVISED:
		tagText = 'Revised';
		headerText = 'A revision request is pending approval.';
		break;
	}

	return (
		<Alert
			data-section="revisionAlert"
			tagColor="#994797"
			headerColor="#F7DFF7"
			tagText={tagText}
			headerText={headerText}
			body={filteredRevisions.map((revision) => (<RevisionAlertBody
				key={revision.id}
				revision={revision}
				onMarkApproved={setRevisionCleared}
				onMarkDeclined={setRevisionCleared}
			/>
			))}
		/>
	);
};
