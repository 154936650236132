import EventApiPayload from '@data/openCalendar/EventApiPayload';
import { apiAction } from '@store/middlewares/api/ApiActionCreators';
import { ENDPOINT_OR_OPENTIME } from '@utilities/apiConstants';
import { GET_ROOM_EVENTS } from '@store/actions/types';
import { CancelPolicy } from '@interfaces/CancelPolicy';
import ApiService from '@utilities/apiEndpoints/ApiService';
import { UnitInfo } from '@data/openCalendar/UnitInfo';
import { ErrorStatusState } from '@interfaces/ErrorStatus/ErrorStatusState';
import EventRequestData from '@data/openCalendar/EventRequestData';

export const createOREventsGetAction = (requestOverrides: EventApiPayload) => {
	return apiAction<EventRequestData, ErrorStatusState, UnitInfo>({
		url: ENDPOINT_OR_OPENTIME,
		includeAccessToken: true,
		label: GET_ROOM_EVENTS,
		cancelPolicy: CancelPolicy.NO_DUPLICATE_PATH,
		shouldDisableErrorHandler: true,
		apiService: ApiService.BTS,
		...requestOverrides,
	});
};
