import React from 'react';
import ReactDOM from 'react-dom';
import { asyncWithLDProvider, basicLogger } from 'launchdarkly-react-client-sdk';
import './index.css';
import App from './App';

const VALID_KEY_REG_EX = /^[0-9a-fA-F]{24}$/;

void (async () => {
	const ldClientId = window.config.LD_CLIENT_ID;

	if (!ldClientId || !VALID_KEY_REG_EX.test(ldClientId)) {
		ReactDOM.render(
			<App />,
			document.getElementById('root'),
		);

		return;
	}

	const LDProvider = await asyncWithLDProvider({
		clientSideID: ldClientId,
		context: {
			kind: 'user',
			anonymous: true,
		},
		options: {
			logger: basicLogger({ level: 'debug' }),
			application: {
				id: 'ssmUi',
			},
		},
	});

	ReactDOM.render(
		<React.StrictMode>
			<LDProvider>
				<App />
			</LDProvider>
		</React.StrictMode>,
		document.getElementById('root'),
	);
})();
