import React from 'react';
import { useDispatch } from 'react-redux';
import DeleteUnavailableEventHookResult from '@data/openCalendar/DeleteUnavailableEventHookResult';
import {
	useUnitCalendarPageContext
} from '@components/UnitCalendarPage/UnitCalendarPageContext/UnitCalendarPageContext';
import { useToast } from '@utilities/hooks/useToast/useToast';
import { apiAction } from '@store/middlewares/api/ApiActionCreators';
import { ENDPOINT_DELETE_UNAVAILABLE_EVENT } from '@utilities/apiConstants';
import { DELETE_UNAVAILABLE_EVENT } from '@store/actions/types';
import ApiService from '@utilities/apiEndpoints/ApiService';
import { ToastType } from '@interfaces/ToastStatus/ToastStatusState';
import { ToastConstants } from '@utilities/toastConstants';
import endpointTemplateToUrl from '@utilities/endpointTemplateToUrl';
import DEFAULT_SELECTED_EVENT from '@components/UnitCalendarPage/UnitCalendarPageContext/DefaultSelectedEvent';
import { useFormContext } from 'react-hook-form';
import UnavailableEventFormType from '@components/UnitCalendarPage/utilities/UnavailableEventFormType';

/*** useDeleteUnavailableEvent - Contains deleteUnavailableEvent function to call API to delete an existing Unavailable Event
 *
 * @returns {DeleteUnavailableEventHookResult} - Returns information necessary to delete an Unavailable Event and handle errors
 */
const useDeleteUnavailableEvent = (): DeleteUnavailableEventHookResult => {
	const dispatch = useDispatch();
	const {
		getUnitEventsFromApi,
		selectedEventDescription: {
			eventInfo
		},
		setSelectedEventDescription,
		setSelectedRoom,
	} = useUnitCalendarPageContext();
	const showToast = useToast();
	const handleClosePopover = React.useCallback(() => {
		setSelectedEventDescription(DEFAULT_SELECTED_EVENT);
	}, [setSelectedEventDescription]);

	const { getValues } = useFormContext<UnavailableEventFormType>();

	const deleteUnavailableEvent = React.useCallback(() => {
		if (!eventInfo) return;
		const unavailableEventId = eventInfo.id;
		const deleteUnavailableEventUrl = endpointTemplateToUrl(ENDPOINT_DELETE_UNAVAILABLE_EVENT, { unavailableEventId });
		const onSuccess = () => {
			// Set edit drawer to close (selectedRoom = undefined) when unavailableEventId === formEventId
			const { id: formEventId } = getValues();
			if (unavailableEventId === formEventId) {
				setSelectedRoom(undefined);
			}

			showToast(ToastType.SUCCESS, ToastConstants.DELETE_UNAVAILABLE_EVENT_SUCCESS);
			getUnitEventsFromApi();
			handleClosePopover();
		};

		dispatch(apiAction({
			url: deleteUnavailableEventUrl,
			method: 'DELETE',
			includeAccessToken: true,
			label: DELETE_UNAVAILABLE_EVENT,
			onSuccess,
			apiService: ApiService.BTS,
		}));
	}, [dispatch, eventInfo, getUnitEventsFromApi, getValues, handleClosePopover, setSelectedRoom, showToast]);

	return {
		deleteUnavailableEvent
	};
};

export default useDeleteUnavailableEvent;
