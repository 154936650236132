import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ConflictDisplay } from '../ConflictDisplay/ConflictDisplay';
import {
	conflictAttrConfig,
	ConflictAttributeDescriptor,
} from '../../CaseDetailsContext/CaseDetailsPageContext/ConflictAttrConfig';
import { useConflictList } from '../../CaseDetailsContext/ConfirmCaseContext/ConfirmCaseUtilities';
import { CaseConflict } from '../../CaseDetailsContext/ConfirmCaseContext/ConfirmCaseContextType';
import { useSelector } from 'react-redux';
import { CaseDetailsInfoSelector } from '../../../../store/selectors/CaseDetailsSelector';
import { SurgeryRequestGet } from '../../../../interfaces/SurgeryRequest/SurgeryRequestGet';

const useStyles = makeStyles((theme) => ({
	container: {
		marginTop: theme.spacing(3),
	},
}));

function identityFormatter<T = unknown>(value: T): T { return value; }
const mergeConfigWithConflict = (conflict: CaseConflict, caseDetails: SurgeryRequestGet) => {
	const conflictConfig = conflictAttrConfig[conflict.attribute] || {};
	const { formatter = identityFormatter } = conflictConfig;
	const { current, external } = conflict;
	return {
		...conflictConfig,
		...conflict,
		current: formatter(current, caseDetails, external),
		external: formatter(external, caseDetails),
	};
};

const sortBy = (a: ConflictAttributeDescriptor, b: ConflictAttributeDescriptor) => a.order - b.order;

export const ConflictList = () => {
	const classes = useStyles();

	const conflicts = useConflictList();
	const caseDetails = useSelector(CaseDetailsInfoSelector);

	const sortedConflicts = conflicts.map(conflict => mergeConfigWithConflict(conflict, caseDetails)).sort(sortBy);

	return (
		<div className={classes.container}>
			{sortedConflicts.map(
				({
					attribute,
					displayName,
					current,
					external,
					shouldDisableForceOnConflict
				}) =>
					<ConflictDisplay
						key={attribute}
						label={displayName}
						current={current}
						external={external}
						error={shouldDisableForceOnConflict ? shouldDisableForceOnConflict : undefined}
					/>
			)}
		</div>
	);
};
