import React from 'react';
import { AscensionLogoProps } from './AscensionLogoProps';

const ascensionFullIcon = (
	<svg
		id="ascensionFullIcon"
		x="0"
		y="0"
		fill="#FFFFFF"
		enableBackground="new 0 0 573.84 236.88"
		version="1.1"
		viewBox="0 0 573.84 236.88"
		xmlSpace="preserve"
	>

		<g>
			<g>
				<path className="st0" d="M108.08,120.03c-1.18,0.36-2.35,0.76-3.5,1.19c-0.5-3.02-0.78-6.11-0.78-9.27c0-17.4,8-33.18,20.43-43.51
			v6.22c-9.79,9.4-15.77,22.69-15.77,37.3c0,2.15,0.15,4.27,0.4,6.35L108.08,120.03z M160.29,163.78
			c-12.31,0-23.65-4.35-32.56-11.59c-1.21-0.98-2.37-2.02-3.49-3.1c-6.25-6.07-10.98-13.7-13.56-22.24l-2.77,6.13
			c2.94,7.26,7.34,13.8,12.85,19.21c1.12,1.1,2.28,2.16,3.49,3.17c9.8,8.16,22.38,13.08,36.05,13.08c6.91,0,13.6-1.24,19.74-3.57
			l-5.44-3.11C170,163.08,165.26,163.78,160.29,163.78"/>
				<path className="st0" d="M120.75,152.19c1.12,1.1,2.28,2.16,3.49,3.16c-9.8,8.16-22.38,13.08-36.05,13.08
			c-6.92,0-13.6-1.24-19.74-3.57l5.44-3.11c4.58,1.32,9.32,2.02,14.3,2.02C100.5,163.78,111.83,159.42,120.75,152.19 M144.67,111.95
			c0-17.4-8-33.18-20.44-43.51v6.22c9.79,9.4,15.77,22.69,15.77,37.3c0,1.9-0.11,3.77-0.31,5.62c-0.52,4.77-1.7,9.35-3.44,13.65
			c-2.74,6.77-6.86,12.85-12.02,17.87c1.12,1.09,2.28,2.12,3.49,3.11c4.52-4.44,8.29-9.62,11.13-15.35
			c2.39-4.84,4.11-10.06,5.03-15.55C144.39,118.25,144.67,115.13,144.67,111.95"/>
				<path className="st0" d="M180.03,164.87l-5.44-3.11c-4.04-16.16-15.7-29.37-30.85-35.51l7.54,16.24c3.42,7.3,5.44,9.01,8.94,9.56
			c0.39,0.08,0.39,2.18,0,2.25H139c-0.39-0.08-0.39-2.18,0-2.25c4.12-0.47,5.59-2.72,3.03-8.31l-3.18-6.89
			c2.39-4.84,4.11-10.06,5.03-15.55C162.55,128.22,176.63,144.78,180.03,164.87 M139.7,117.57c-0.52,4.77-1.71,9.35-3.44,13.65
			l-3.77-8.17c-2.69-0.44-5.43-0.69-8.25-0.69c-4.2,0-8.24,0.47-12.2,1.48l-1.36,3.01l-2.77,6.13l-3.64,8.03
			c-3.65,8.08-1.87,10.41,2.33,11.19c0.39,0.16,0.31,2.02-0.08,2.1H89.27c-0.39-0.08-0.47-1.94-0.08-2.1
			c3.03-1.09,5.36-2.8,9.64-11.97l6.53-14.3c-15.46,6.06-27.35,19.43-31.47,35.82l-5.44,3.11c3.37-20.13,17.49-36.66,36.13-43.64
			c1.15-0.43,2.31-0.83,3.5-1.19l0.79-1.73l14.36-31.36c0.39-0.23,1.86-0.23,2.33,0L139.7,117.57z M130.17,118.03l-7.82-16.94
			l-7.9,17.47c3.19-0.54,6.45-0.85,9.79-0.85C126.26,117.7,128.22,117.82,130.17,118.03"/>
			</g>
			<g>
				<path className="st0" d="M241.69,134.3l-14.06-38.68h-5.81l-13.27,34.26c-2.44,6.21-4.22,8.51-8.52,9.57v1.78h15.51v-1.78
			c-5.74-0.86-6.6-2.57-4.22-8.91l2.24-5.94h14.46l3.43,9.97c1.19,3.23,0.07,4.55-6.07,4.88v1.78h23.37v-1.78
			C243.94,138.65,242.94,137.66,241.69,134.3z M214.36,122.48l6.47-17.43h0.46l6.01,17.43H214.36z"/>
				<path className="st0" d="M265.78,121.62c-4.55-1.12-7.19-2.25-7.19-4.95s1.98-4.42,4.75-4.42c3.63,0,6.21,2.71,9.51,7.72h1.85v-9.77
			h-1.72l-1.65,2.05c-1.78-1.12-4.62-2.05-7.46-2.05c-7.26,0-12.15,4.03-12.15,10.17c0,4.82,2.77,7,9.7,8.98
			c6.34,1.85,8.38,3.17,8.38,6.07c0,2.44-1.72,4.22-5.08,4.22c-4.03,0-7.66-2.9-11.22-8.85h-1.85v10.96h1.78l1.98-2.24
			c1.78,1.12,6.27,2.24,8.71,2.24c7.46,0,12.34-4.55,12.34-10.5C276.47,125.85,272.78,123.41,265.78,121.62z"/>
				<path className="st0" d="M299.51,138.32c-6.54,0-9.44-5.68-9.44-12.81c0-8.65,2.84-13.47,7.19-13.47c2.25,0,3.83,1.06,4.49,2.18
			c-0.99,0.59-2.18,2.18-2.18,3.96c0,2.44,1.65,4.42,4.95,4.42c2.9,0,4.55-2.05,4.55-4.95c0-3.3-3.3-7.59-12.08-7.59
			c-9.31,0-16.24,6.27-16.24,16.37c0,9.44,5.87,15.38,15.51,15.38c6.4,0,10.43-2.9,13.4-7.46l-1.25-0.99
			C306.04,136.48,302.94,138.32,299.51,138.32z"/>
				<path className="st0" d="M328.74,110.07c-8.98,0-15.58,6.27-15.58,16.3c0,9.7,5.94,15.45,15.58,15.45c6.53,0,10.63-3.17,13.53-7.79
			l-1.32-0.99c-2.51,3.43-5.28,5.41-8.98,5.41c-6.34,0-9.57-4.75-9.57-12.28v-0.07v-0.73h19.87
			C342.74,116.74,338.05,110.07,328.74,110.07z M333.3,123.41l-10.83,0.07c0.33-7.33,2.24-11.35,5.81-11.35
			C332.37,112.12,334.02,116.8,333.3,123.41z"/>
				<path className="st0" d="M377.72,137.2v-17.23c0-6.21-2.57-9.57-8.05-9.57c-4.29,0-8.45,2.51-11.55,6.01v-5.81l-13.2,0.59v1.65
			c3.76,0.07,4.55,1.25,4.55,3.23v21.12c0,1.72-0.79,2.31-4.55,2.38v1.65h16.9v-1.65c-2.57-0.07-3.56-0.66-3.56-2.38v-18.95
			c1.78-1.85,3.7-3.1,6.07-3.1c3.3,0,4.62,1.98,4.62,6.01v16.04c0,1.72-0.99,2.31-3.63,2.38v1.65h16.96v-1.65
			C378.44,139.51,377.72,138.92,377.72,137.2z"/>
				<path className="st0" d="M400.36,121.62c-4.55-1.12-7.2-2.25-7.2-4.95s1.98-4.42,4.75-4.42c3.63,0,6.21,2.71,9.51,7.72h1.85v-9.77
			h-1.72l-1.65,2.05c-1.78-1.12-4.62-2.05-7.46-2.05c-7.26,0-12.15,4.03-12.15,10.17c0,4.82,2.77,7,9.7,8.98
			c6.34,1.85,8.38,3.17,8.38,6.07c0,2.44-1.72,4.22-5.08,4.22c-4.03,0-7.66-2.9-11.22-8.85h-1.85v10.96h1.78l1.98-2.24
			c1.78,1.12,6.27,2.24,8.71,2.24c7.46,0,12.34-4.55,12.34-10.5C411.05,125.85,407.35,123.41,400.36,121.62z"/>
				<path className="st0" d="M428.47,137.2v-26.6l-13.53,0.59v1.65c3.76,0.07,4.75,1.25,4.75,3.23v21.12c0,1.72-0.99,2.31-4.75,2.38v1.65
			h18.09v-1.65C429.2,139.51,428.47,138.92,428.47,137.2z"/>
				<path className="st0" d="M450.91,110.07c-9.64,0-15.97,6.27-15.97,15.97c0,9.77,5.94,15.78,15.97,15.78c9.51,0,15.91-6.27,15.91-16.04
			C466.82,116.08,460.75,110.07,450.91,110.07z M451.17,139.78c-4.75,0-6.86-4.55-6.86-13.86c0-9.31,1.58-13.8,6.27-13.8
			s6.8,4.49,6.8,13.8C457.38,135.22,455.73,139.78,451.17,139.78z"/>
				<path className="st0" d="M502.59,137.2v-17.23c0-6.21-2.57-9.57-8.05-9.57c-4.29,0-8.45,2.51-11.55,6.01v-5.81l-13.2,0.59v1.65
			c3.76,0.07,4.55,1.25,4.55,3.23v21.12c0,1.72-0.79,2.31-4.55,2.38v1.65h16.9v-1.65c-2.57-0.07-3.56-0.66-3.56-2.38v-18.95
			c1.78-1.85,3.7-3.1,6.07-3.1c3.3,0,4.62,1.98,4.62,6.01v16.04c0,1.72-0.99,2.31-3.63,2.38v1.65h16.96v-1.65
			C503.32,139.51,502.59,138.92,502.59,137.2z"/>
				<circle className="st0" cx="423.92" cy="100.14" r="5.25"/>
			</g>
		</g>
	</svg>

);

export const AscensionLogoWithName = ({ svgElmProps, iconProps }: AscensionLogoProps) => {
	return 	(
		<svg
			{...svgElmProps}
			xmlns="http://www.w3.org/2000/svg"
		>
			{ascensionFullIcon}
			<use xlinkHref="#ascensionFullIcon" {...iconProps}/>
		</svg>
	);
};
