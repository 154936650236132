import React from 'react';
import {
	SurgeryProcedureInterface, TemplateInfo,
} from '@components/RequestForm/SurgeryInformation/SurgeryInformationInterface';
import ProcedureForm from '@components/ProcedureForm/ProcedureForm';
import { useForm } from 'react-hook-form';
import { TemplateFormProps } from './TemplateFormProps';
import TemplateApiInfoToSurgeryProcedureReq from '@utilities/typeConverters/TemplateApiInfoToSurgeryProcedureReq';
import FormTextField from '@components/FormComponents/FormTextField';
import useProcedureFormOptions from '@components/ProcedureForm/useProcedureFormOptions/useProcedureFormOptions';
import { FullSurgeonsSelector } from '@store/selectors/SurgeonListSelector';
import { TemplateSchema } from '@utilities/Validation/validationSchema';
import { NO_SURGEON_DROPDOWN } from '@utilities/typeConverters/constants';

const templateFormDefaults: TemplateInfo = {
	templateName: '',
	surgeon: {
		label: '',
		value: '',
	},
	cptCodes: { currentEntry: '', entriesList: [] },
	procedureName: '',
	modifierSide: {
		label: '',
		value: '',
	},
	modifierApproach: {
		label: '',
		value: '',
	},
	isPrimaryProcedure: null,
	anesthesiaType: [],
	implantsNeeded: null,
	otherEquipment: '',
	additionalComments: '',
	vendorName: '',
	robotic: null,
};

const additionalOptions = [{ ...NO_SURGEON_DROPDOWN }];

const TemplateForm = ({ templateInitVals, templateFormError }: TemplateFormProps) => {
	const availableOptions = useProcedureFormOptions(FullSurgeonsSelector, additionalOptions);

	const templateAsSurgeryReq = React.useMemo(() =>
		TemplateApiInfoToSurgeryProcedureReq(templateInitVals),
	[templateInitVals]);

	const formMethods = useForm<SurgeryProcedureInterface>({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		defaultValues: templateAsSurgeryReq || templateFormDefaults,
	});

	const { setError } = formMethods;

	React.useEffect(() => {
		if (templateFormError && templateFormError.message && templateFormError.message.endsWith('already exist in the Practice')) {
			setError('templateName', { type: 'manual', message: 'This name is already in use, please choose a unique name.' });
		}
	}, [templateFormError, setError]);

	return (
		<ProcedureForm schema={TemplateSchema} options={availableOptions} {...formMethods}>
			<FormTextField
				label="Template Name"
				name="templateName"
				placeholder="Enter template name"
			/>
		</ProcedureForm>
	);
};

export default TemplateForm;
