import { TemplateStatusState } from '../../interfaces/TemplateStatus/TemplateStatusState';
import { TemplateStatusAction } from '../../interfaces/TemplateStatus/TemplateStatusAction';
import { UPDATE_TEMPLATE_STATUS } from '../actions/types';

const initialState: TemplateStatusState = {};

const TemplateStatusReducer =
	(state: TemplateStatusState = initialState, action: TemplateStatusAction): TemplateStatusState => {
		switch (action.type) {
		case UPDATE_TEMPLATE_STATUS:
			return {
				...state,
				[action.payload.templateId]: action.payload.status,
			};
		}
		return state;
	};

export default TemplateStatusReducer;
