import React from 'react';
import { useFormContext } from 'react-hook-form';
import { addDays } from 'date-fns';
import { EventInfo } from '@calendar';
import OREventType from '@data/openCalendar/OREventType';
import { useUnitCalendarPageContext } from '@components/UnitCalendarPage/UnitCalendarPageContext/UnitCalendarPageContext';
import { getNormalizedTime, getZonedTimeFromTimeAndDate } from '@utilities/dateUtilities';
import getUnavailableOverlapStyledEvents
	from '@components/UnitCalendarPage/utilities/getUnavailableOverlapStyledEvents';
import {
	EventTimeValidationSchema
} from '@components/UnitCalendarPage/EventTimeSpanInput/EventTimeValidationSchema';
import useSelectedEventStyles from '@components/UnitCalendarPage/utilities/useSelectedEventStyles';
import filterHandledEvents from '@components/UnitCalendarPage/utilities/filterHandledEvents';
import { TEMP_STUB_EVENT_ID } from '@components/UnitCalendarPage/utilities/calendarConstants';
import { PartialTimeSpan } from '@utilities/Validation/TimeSpanValidationUtilities/TimeSpanValidationUtilities';

const useCalendarEventsWithNewUnavailable = (): EventInfo[] => {
	const classes = useSelectedEventStyles();

	const {
		selectedDate: calendarNavigationDate,
		unitConfig: { hospitalTimeZone },
		selectedRoom,
		events,
	} = useUnitCalendarPageContext();

	const { watch } = useFormContext<{ timeSpan: PartialTimeSpan; date: Date; id: string }>();
	const [
		timeSpan,
		date,
		id,
	] = watch(['timeSpan', 'date', 'id']);
	const [stubEvent, setStubEvent] = React.useState<EventInfo | undefined>(undefined);
	// Set the stubEvent when valid
	React.useEffect(() => {
		// When timespan start/end invalid, don't update the stubEvent
		if (!(EventTimeValidationSchema.timeSpan.validate.checkAll(timeSpan) === true || undefined)) {
			return;
		}

		const { start, end } = timeSpan;

		const getConvertedTime = (t: string | undefined) => getZonedTimeFromTimeAndDate(t || '', hospitalTimeZone, date);

		const startTimeConverted = getConvertedTime(start);
		const endTimeConverted = getConvertedTime(end);

		// When converted times are falsy or undefined due to invalid start/end string, don't update stubEvent
		if (!startTimeConverted || !endTimeConverted) {
			return;
		}

		// Otherwise set the stub event with the new times
		setStubEvent({
			// Should use event ID when present (editing an event) otherwise generic ID const (new event)
			id: id || TEMP_STUB_EVENT_ID,
			type: OREventType.UNAVAILABLE,
			owner: '',
			title: 'Unavailable',
			subTitle: '',
			start: startTimeConverted,
			end: end === '12:00 AM' ? addDays(endTimeConverted, 1) : endTimeConverted,
			column: selectedRoom || '',
			timeSpanDisplay: `${start} - ${end}`,
			additionalClassName: classes.selected,
		});
	}, [classes.selected, hospitalTimeZone, id, selectedRoom, timeSpan, date]);

	// When stubEvent falsy, selectedRoom exists (drawer is open), OR form date does not match calendar date, return events
	if (!stubEvent ||
		!selectedRoom ||
		getNormalizedTime(calendarNavigationDate, hospitalTimeZone) !== getNormalizedTime(date, hospitalTimeZone)
	) {
		return events;
	}

	// Otherwise add the stub event and style with appropriate overlaps
	return [
		...events.filter(e => e.column != selectedRoom && e.id !== id),
		...getUnavailableOverlapStyledEvents(events.filter(e => e.column === selectedRoom && filterHandledEvents(e) && e.id !== id ),
			stubEvent,
			classes.overlap,
			classes.unavailableOverlap
		)
	];
};

export default useCalendarEventsWithNewUnavailable;
