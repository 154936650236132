import React from 'react';
import { DocPrintApiPayload } from '@data/docPrint/types/DocPrintRequest';
import { useParams } from 'react-router-dom';
import useGetDocPrint from '@data/docPrint/hooks/useGetDocPrint';

const useSubmitDocPrint = () => {
	const { attachmentId } = useParams<{ attachmentId: string }>();
	const getDocPrint = useGetDocPrint();

	return React.useCallback(() => {
		const updateDocPrintPayload: DocPrintApiPayload = {
			data: {
				id: attachmentId,
			},
		};

		getDocPrint(updateDocPrintPayload);
	}, [attachmentId, getDocPrint]);
};

export default useSubmitDocPrint;
