import Interval from '@interfaces/Interval';
import { areIntervalsOverlapping, isWithinInterval } from 'date-fns';

/***
 * getOverlapInterval - provide event with start/end properties and clamp with same to calculate the overlap union between the two.
 *
 * @example
 * // Intervals are each 2 hours with an hour overlapping
 * getOverlapInterval(
 * 	{ start: new Date('2020-10-05T06:00'), end: new Date('2020-10-05T09:00')},
 * 	{ start: new Date('2020-10-05T08:00'), end: new Date('2020-10-05T10:00')},
 * ) // returns { start: new Date('2020-10-05T08:00'), end:new Date('2020-10-05T09:00') }
 *
 * // See tests for more examples
 *
 * @returns {Interval | undefined} - undefined when no overlap. Otherwise, Interval calculated as the union between the
 * event and the clamp interval.
 *
 */
const getOverlapInterval = (event: Interval, clamp: Interval) => {
	if (!areIntervalsOverlapping(event, clamp)) { return; }

	const startWithin = isWithinInterval(event.start, clamp);
	// end not inclusive
	const endWithin = isWithinInterval(event.end, clamp);

	// Full event within block, count entire event
	if (startWithin && endWithin) {
		return { start: event.start, end: event.end };
	}

	// starts before, ends after - only count block time
	if (!startWithin && !endWithin) {
		return { start: clamp.start, end: clamp.end };
	}

	// Starts within, ends after
	if (startWithin) {
		return { start: event.start, end: clamp.end  };
	}

	// Starts before, ends within
	return { start: clamp.start, end: event.end  };
};

export default getOverlapInterval;
