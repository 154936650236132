import React from 'react';
import { Box, Divider } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../interfaces/rootState';
import {
	UserProfilePrimaryPracticeSelectorId,
} from '../../../../../store/selectors/UserProfileSelector';
import { getAllSurgeonListData, getSurgeonListData } from '../../../../../store/actions/SurgeonListActionCreators';
import SurgeonsCheckBoxList from './SurgeonsCheckBoxList';
import { FormAssistiveText } from '../../../../FormComponents/FormAssistiveText/FormAssistiveText';

export default function OfficeSurgeons() {
	const dispatch = useDispatch();
	const id = useSelector<RootState, string>(UserProfilePrimaryPracticeSelectorId);

	React.useEffect(() => {
		dispatch(getSurgeonListData());
	}, [dispatch]);

	React.useEffect(() => {
		if (!id) { return; }
		dispatch(getAllSurgeonListData(id));
	}, [dispatch, id]);

	return (
		<Box display="flex" flexDirection="column">
			<Box display="flex" flexDirection="column" justifyContent="space-between" width={1} alignItems="start" py={2} data-field="fullName">
				<Box fontSize={18} letterSpacing="1.4px" color="#222222" fontWeight="bold" pb={1}>
					Surgeons
				</Box>
				<FormAssistiveText field="surgeon">Select at least one surgeon.</FormAssistiveText>
			</Box>
			<Box display="flex" flexDirection="row" flexWrap="wrap" width={1} pb={4}>
				<SurgeonsCheckBoxList />
			</Box>
			<Divider style={{ marginBottom: '10px' }}/>
		</Box>
	);
}
