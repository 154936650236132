import React from 'react';
import { Box, makeStyles, createStyles, BoxProps } from '@material-ui/core';

const useStyles = makeStyles((theme) => createStyles({
	footer: {
		padding: theme.spacing(2, 3, 2),
		gridArea: 'footer',
	},
}));

export const SectionedBoxFooter: React.FC<BoxProps> = ({ children, ...boxProps }) => {
	const classes = useStyles();

	return (
		<Box className={classes.footer} {...boxProps}>
			{children}
		</Box>
	);
};
