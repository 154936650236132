import { useDispatch } from 'react-redux';
import React from 'react';
import { ApiActionPayload } from '@interfaces/ApiAction/ApiActionPayload';
import { getCaseActivities } from '@data/caseActivities/actionCreators/CaseActivitiesActionCreators';
import CaseActivityRowType from '@components/CaseActivityDrawer/types/CaseActivityRowType';

const useGetCaseActivities = () => {
	const dispatch = useDispatch();

	return React.useCallback((requestId: string, apiActionRequest: Partial<ApiActionPayload<CaseActivityRowType[]>>) => {
		dispatch(getCaseActivities(requestId, apiActionRequest));
	}, [dispatch]);
};

export default useGetCaseActivities;
