import React, { ChangeEvent } from 'react';
import { Box, FormHelperText, InputLabel } from '@material-ui/core';
import { TextField } from '@ascension/ui-library';
import { makeStyles } from '@material-ui/core/styles';
import { useFieldFormContext } from '../../../../../utilities/hooks/useFieldFormContext/useFieldFormContext';
import { BasicUserInfoFormValidationSchema } from '../../BasicUserInfoFormValidationSchema';
import ErrorMessageMui from '../../../../ErrorMessageMui/ErrorMessageMui';
import { UserRegistration } from '../../../UserRegistration';

const useStyles = makeStyles(theme => ({
	container: {
		'& .MuiInputLabel-formControl': {
			top: 'unset',
			left: 'unset',
			position: 'unset',
			transform: 'unset',
		},
		'& .MuiInputLabel-animated': {
			transition: 'unset',
		},
	},
	label: {
		marginBottom: theme.spacing(1),
	},
	input: {
		width: '240px',
	},
}));

type UsernameInputProps = Pick<UserRegistration, 'hasAscensionUsername'>;

const UsernameInput: React.FC<UsernameInputProps> = ({ hasAscensionUsername }) => {
	const classes = useStyles();
	const hasUsername = hasAscensionUsername === 1;
	const {
		currentValue,
		setCurrentValue,
		error,
		triggerSelfValidation,
	} = useFieldFormContext<string>('ascensionUsername', {
		validationRules: hasUsername ? BasicUserInfoFormValidationSchema.ascensionUsername : {},
	});

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setCurrentValue(e.target.value);
	};

	return (
		hasUsername ?
			<Box className={classes.container}>
				<InputLabel
					required
					className={classes.label}
					id="username-input-label"
					htmlFor="username"
					error={!!error}
				>
					User&apos;s Ascension username
				</InputLabel>
				<TextField
					translate="yes"
					variant="outlined"
					className={classes.input}
					value={currentValue}
					onChange={handleChange}
					onBlur={triggerSelfValidation}
					error={!!error}
					id="username"
					data-field="username-input"
					aria-describedby="username-input-error"
					aria-labelledby="username-input-label"
				/>
				<FormHelperText>Example: asmith95</FormHelperText>
				{ error && <span id="username-input-error"><ErrorMessageMui errorMessage={error} /></span>}
			</Box>
			:
			<></>
	);
};

export default UsernameInput;
