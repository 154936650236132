import React from 'react';
import { StorageKey } from '@utilities/authConstants';
import { useSelector } from 'react-redux';
import { UserProfileDetailsSelector } from '@store/selectors/UserProfileSelector';

const useSessionStorageCache = (key: StorageKey) => {
	const { userId } = useSelector(UserProfileDetailsSelector);

	const storedValue = React.useMemo(() => {
		const existingValue = sessionStorage.getItem(key);
		if (!existingValue || !existingValue.includes(userId)) {
			sessionStorage.removeItem(key);
			return;
		}
		return existingValue.split('::')[0];
	}, [key, userId]);

	const setStoredValue = React.useCallback((val: string) =>
		sessionStorage.setItem(key, val + `::${userId}`)
	, [key, userId]);

	return {
		storedValue,
		setStoredValue,
	};
};

export default useSessionStorageCache;
