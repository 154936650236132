import RightLeftDirectionEnum from '@interfaces/RightLeftDirectionEnum';
import determineContentHiddenDirection
	from '@components/BoxScrollHorizontal/utilities/calculateIsSideHidden/determineContentHiddenDirection';
import { GRADIENT_WIDTH } from '@theme/themeConstants';

const doHorizontalScroll = (scrollContainer: HTMLDivElement | undefined, columnScrollAmount: number, direction: RightLeftDirectionEnum) => {
	if (!scrollContainer) { return; }

	const { [RightLeftDirectionEnum.RIGHT]: rightHidden, [RightLeftDirectionEnum.LEFT]: leftHidden } = determineContentHiddenDirection(scrollContainer);

	const directionModifier = direction === RightLeftDirectionEnum.LEFT ? -1 : 1;

	let scrollAmount = scrollContainer.scrollLeft + columnScrollAmount * directionModifier;

	if (direction === RightLeftDirectionEnum.RIGHT && !leftHidden) {
		scrollAmount -= GRADIENT_WIDTH;
	}
	if (direction === RightLeftDirectionEnum.LEFT && !rightHidden) {
		scrollAmount += GRADIENT_WIDTH;
	}

	scrollContainer.scrollTo({
		top: 0,
		left: scrollAmount,
		behavior: 'smooth',
	});
};

export default doHorizontalScroll;
