import { RootState } from '@interfaces/rootState';
import { CancelerDescriptor } from '@interfaces/ApiStatus/ApiStatusState';

export const apiStatusCancelSelector = (url: string) =>
	({
		apiStatus: {
			cancelers: {
				[url]: canceler
			}
		}
	}: RootState): CancelerDescriptor | undefined => canceler;

export const apiCallsInFlightSelector = ({
	apiStatus: {
		callsInFlight,
	}
}: RootState) => callsInFlight;

export const apiGlobalLoadingCountSelector = ({
	apiStatus: {
		callsInFlight: {
			globalLoadingCount,
		}
	}
}: RootState) => globalLoadingCount;
export const shouldShowGlobalLoaderSelector = (state: RootState) => apiGlobalLoadingCountSelector(state) > 0;
