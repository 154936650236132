import React from 'react';
import { useDispatch } from 'react-redux';
import { getEquipmentList } from '@data/equipment/actionCreators/getEquipmentList';
import { EquipmentListRequest } from '@data/equipment/types/EquipmentListRequest';

export const useGetEquipmentList = () => {
	const dispatch = useDispatch();

	return React.useCallback((requestOverrides?: EquipmentListRequest) => {
		dispatch(getEquipmentList(requestOverrides));
	}, [dispatch]);
};
