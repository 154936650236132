import { Box } from '@ascension/web';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import { BoxProps, Typography } from '@material-ui/core';
import { Button } from '@ascension/ui-library';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
	frame: {
		'position': 'absolute',
		'bottom': '-4px',
		'left': '-4px',
		'right': 0,
		'height': 'calc(100% - 8px)',
		'width': 'calc(100% + 8px)',
		'borderRadius': '4px',
		'border': theme.spacing(0.5),
		'borderColor': theme.palette.grey[600],
		'borderStyle': 'solid',
		'&:hover': {
			'borderColor': 'transparent',
			'& > div:first-child': {
				borderRadius: '4px 4px 0 0',
			}
		},
	},
	frameHeader: {
		backgroundColor: theme.palette.grey[600],
		height: '36px',
		width: '100%',
		color: theme.palette.common.white,
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		paddingLeft: theme.spacing(2.5),
	},
	frameHeaderTxt: {
		paddingLeft: theme.spacing(1.25),
		fontSize: '14px',
	},
	overlay: {
		'position': 'absolute',
		'left': '-4px',
		'right': '-4px',
		'top': '-4px',
		'bottom': '-4px',
		'borderRadius': '4px',
		'backgroundColor': 'rgba(0,0,0,0.64)',
		'mixBlendMode': 'normal',
		'opacity': 0,
		'display': 'flex',
		'flexDirection': 'column',
		'justifyContent': 'center',
		'alignItems': 'center',
		'color': theme.palette.common.white,
		'&:hover': {
			opacity: 1,
		},
	},
	viewCaseBtn: {
		'color': theme.palette.common.white,
		'borderColor': theme.palette.common.white,
		'marginTop': theme.spacing(2),
		'&:hover': {
			color: 'rgba(0,0,0,0.87)',
			backgroundColor: theme.palette.common.white,
		},
	},
	overlayIcon: {
		paddingBottom: theme.spacing(2),
	},
}));

interface CaseInUseOverlayProps extends BoxProps {
	managingUser: string;
}

const CaseInUseOverlay: React.FC<CaseInUseOverlayProps> = ({ managingUser, className }) => {
	const classes = useStyles();

	return (
		<Box className={classNames(classes.frame, className)}>
			<Box className={classes.frameHeader}>
				<LockIcon fontSize="small"/>
				<Typography className={classes.frameHeaderTxt}>
					Currently being managed by {managingUser}
				</Typography>
			</Box>
			<Box className={classes.overlay}>
				<LockIcon fontSize="medium" className={classes.overlayIcon}/>
				<Typography variant="h6" component="span">
					Currently being managed by {managingUser}
				</Typography>
				<Button
					translate="yes"
					variant="outlined"
					className={classes.viewCaseBtn}
					data-field="view-case-btn"
				>
					VIEW CASE
				</Button>
			</Box>
		</Box>
	);
};

export default CaseInUseOverlay;
