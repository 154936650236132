import { Flex } from '@ascension/web';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import FormYesNoRadio from '../../FormComponents/FormYesNoRadio';
import FormTextField from '../../FormComponents/FormTextField';
import { useFormMeta } from '../../../store/context/FormMetaContext';
import { SurgeryProcedureInterface } from '../../RequestForm/SurgeryInformation/SurgeryInformationInterface';

const ImplantsField = () => {
	const {
		getValues
	} = useFormContext();

	const { schema } = useFormMeta<SurgeryProcedureInterface>();

	const implantsNeeded = !!getValues()['implantsNeeded'];

	return (
		<>
			<FormYesNoRadio name="implantsNeeded" label="Implants Needed" />

			{ implantsNeeded &&
			<Flex
				flexDirection="row"
				mt={0}
				mb={8}
				flexWrap="wrap"
				style={{ gap: '16px' }}
			>
				<FormTextField
					name="vendorName"
					label="Vendor"
					placeholder="Enter a vendor name"
					mt={0}
					flex="1 1 auto"
				/>
				{ 'vendorContacted' in schema &&
					<FormYesNoRadio
						name="vendorContacted"
						label="Vendor Contacted?"
						flex="1 1 auto"
						mt={0}
					/>
				}
			</Flex>}
		</>
	);
};

export default ImplantsField;
