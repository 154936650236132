import { DropdownOption } from '@definitions/DropdownOption';
import {
	INVALID_TIME,
	REQUIRED_DROPDOWN_NO_OPTION_SELECTED
} from '@utilities/Validation/ValidationMessages';

export const AppointmentDetailsValidationSchema = {
	requiredDropdown: {
		validate: {
			requiredDropdownSelected: (optionSelected: DropdownOption) => {
				return optionSelected && optionSelected.value
					? true
					: REQUIRED_DROPDOWN_NO_OPTION_SELECTED;
			},
		},
	},
	appointmentLocation: {
		required: REQUIRED_DROPDOWN_NO_OPTION_SELECTED,
	},
	appointmentTime: {
		pattern: {
			value: /\d{2}:\d{2}/,
			message: INVALID_TIME,
		},
		required: INVALID_TIME,
		validate: {
			validateTime: (testTime: [string, string]) => {
				const test24HrTime = testTime && testTime[0];
				const testEntryTime = testTime && testTime[1] && testTime[1].replace(/\D/, '');
				const timePieces = test24HrTime.split('');
				return (
					(timePieces.length === 5 &&
						/[012]/.test(timePieces[0]) &&
						/\d/.test(timePieces[1]) &&
						/[0-5]/.test(timePieces[3]) &&
						/\d/.test(timePieces[4]) &&
						testEntryTime.length === 4) ||
					INVALID_TIME
				);
			},
		},
	},
};
