import React from 'react';
import { Theme, Typography, TypographyProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { PictureAsPdfRounded } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		'display': 'flex',
		'alignItems': 'center',
		'marginTop': theme.spacing(.25),
		'maxWidth': '250px',
		'width': '100%',
		[theme.breakpoints.down('md')]: {
			fontSize: '10px',
			maxWidth: '140px',
		},
		'& .MuiSvgIcon-root': {
			fontSize: 'inherit',
			marginLeft: theme.spacing(0.5),
			color: theme.palette.grey[600],
		},
	},
	nonAttachment: {
		borderRadius: '4px',
		backgroundColor: theme.palette.grey[200],
		padding: theme.spacing(.5, 1),
		wordBreak: 'break-word',
	},
}));

interface CaseActivityMessageProps extends Partial<TypographyProps> {
	isAttachmentActivity: boolean;
}

const CaseActivityMessage: React.FC<CaseActivityMessageProps> = ({ isAttachmentActivity, children, className, ...props }) => {
	const classes = useStyles();

	return (
		<Typography
			variant="caption"
			className={classNames(classes.root, className, { [classes.nonAttachment]: !isAttachmentActivity })}
			{...props}
		>
			{isAttachmentActivity ? children : `"${children && typeof children === 'string' && children.toString()}"`}
			{isAttachmentActivity && <PictureAsPdfRounded />}
		</Typography>
	);
};

export default CaseActivityMessage;
