import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AlertBox } from '@ascension/ui-library';
import { Text } from '@ascension/web';
import { ToastType } from '../../interfaces/ToastStatus/ToastStatusState';
import { clearToastStatus } from '../../store/actions/ToastStatusActionCreators';
import { ToastStatusSelector } from '../../store/selectors/ToastStatusSelector';
import { useLocation } from 'react-router-dom';

type ToastState = { disableClearToast: boolean };

export default function Toast () {
	const dispatch = useDispatch();
	const { toastType, timeout, message, timestamp } = useSelector(ToastStatusSelector);
	const { pathname, state } = useLocation<ToastState>();
	const stateRef = React.useRef<ToastState>(state);

	React.useEffect(() => {
		//Show toast only if its status is either Success or Error
		if ([ToastType.SUCCESS, ToastType.ERROR].includes(toastType)) {
			const timeoutEventId = setTimeout(() => {
				dispatch(clearToastStatus());
			}, timeout);
			return () => clearTimeout(timeoutEventId);
		}
		/* Timestamp is required as dependency so in situation when two identical toasts are dispatched in sequence
		the timeout is respected for the second toast dispatched
		(e.g. user Edits Template and before first toast goes away user edits another template) */
	}, [dispatch, toastType, timeout, timestamp]);

	React.useEffect(() => {
		// Needed to save a ref of current location state
		stateRef.current = state;
	}, [state]);

	React.useEffect(() => {
		// Do not clear toast if { disableClearToast: true } was included in state of last location change
		if (stateRef.current && stateRef.current.disableClearToast) { return; }

		dispatch(clearToastStatus());
	}, [dispatch, pathname]);

	return (
		<>
			{toastType === ToastType.SUCCESS ?
				<AlertBox
					severity="success"
					id="success"
					translate="yes"
					leftBorderAccent={true}
				>
					<Text color="#222222" letterSpacing={1} fontWeight="bold" mx ="10px">
						{message}
					</Text>
				</AlertBox>
				: null}
			{toastType === ToastType.ERROR ?
				<AlertBox
					severity="error"
					id="error"
					translate="yes"
					leftBorderAccent={true}
				>
					<Text color="#222222" letterSpacing={1} fontWeight="bold" mx ="10px">
						{message}
					</Text>
				</AlertBox>
				: null}
		</>
	);
}
