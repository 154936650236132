import { GET_UNIT_LIST, UPDATE_ALL_UNIT, UPDATE_UNIT_LIST_ERROR } from './types';
import { ApiActionRequest } from '../../interfaces/ApiAction/ApiAction';
import { apiAction } from '../middlewares/api/ApiActionCreators';
import { ENDPOINT_USER_UNITS } from '../../utilities/apiConstants';
import { AxiosResponse } from 'axios';
import errorToString from '../../utilities/errorToString';
import { CacheType } from '../../interfaces/CacheType';
import { Dispatch } from 'redux';
import { RootState } from '../../interfaces/rootState';
import { Unit } from '../../data/unit/Unit';
import { UnitListTypes } from '../../interfaces/Unit/UnitAction';
import { Hospital } from '../../interfaces/Hospital';
import { CancelPolicy } from '@interfaces/CancelPolicy';
import { ApiActionPayload } from '@interfaces/ApiAction/ApiActionPayload';

export function updateFullUnitList({ data }: { data: Unit[] },  units: Unit[]): UnitListTypes {
	return {
		type: UPDATE_ALL_UNIT,
		payload: { data, units }
	};
}

export function updateUnitListError(error: unknown): UnitListTypes {
	return {
		type: UPDATE_UNIT_LIST_ERROR,
		error: errorToString(error)
	};
}


export function updateAllUnit(response: AxiosResponse<Unit[]>) {
	return (dispatch: Dispatch, getState: () => RootState) => {
		const units: Unit[] = (getState().userProfile.userProfileDetails.preference as {hospitals: Hospital[]}).hospitals.reduce((allPrefUnits: Unit[], { units }) => {
			return allPrefUnits.concat(units);
		}, []);
		dispatch(updateFullUnitList({ data : response.data }, units));
	};
}

export function getUnitsForORUser(apiOverride: Partial<ApiActionPayload<Unit[]>> & Pick<ApiActionPayload<Unit[]>, 'onSuccess'>): ApiActionRequest<Unit[]> {
	return apiAction<Unit[]>({
		url: ENDPOINT_USER_UNITS,
		method: 'GET',
		label: GET_UNIT_LIST,
		includeAccessToken: true,
		cancelPolicy: CancelPolicy.NO_DUPLICATE_PATH,
		cachePolicy: CacheType.RETRIEVE_OR_ADD_CACHE,
		...apiOverride,
	});
}

export function getAllUnitListData (apiOverride?: Partial<ApiActionPayload<Unit[]>>): ApiActionRequest<Unit[]> {
	return getUnitsForORUser({
		onSuccess: updateAllUnit,
		onFailure: updateUnitListError,
		...apiOverride,
	});
}


