/**
 * CacheType enum
 *
 * Used by apiMiddleware to determine what APIs should use cache data.
 *
 * To disable cache, simply use:
 * 	* NO_CACHE - Does not check cache and does not save to cache
 *
 * Two options for collections:
 * 	* RETRIEVE_OR_ADD_CACHE - Check cache for request and return data if present.
 * 		If not then retrieve from API and add to cache.
 * 	* UPDATE_CACHE - Call API regardless of status in cache. Add or replace response to cache.
 */

export enum CacheType {
	NO_CACHE,
	RETRIEVE_OR_ADD_CACHE,
	UPDATE_CACHE,
}
