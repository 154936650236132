import { useFormContext } from 'react-hook-form';
import { useRequestFormContext } from '@components/RequestForm/RequestForm';
import useGetSelectedUnitInfo from '@components/RequestForm/utilities/hooks/useCheckBlockTimeOverlap/useGetSelectedUnitInfo/useGetSelectedUnitInfo';
import { RequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';

export const useCheckAddressValidationRequired = () => {
	const { isEdit } = useRequestFormContext();
	const { watch } = useFormContext<RequestFormValidatedState>();
	const selectedUnitInfo = useGetSelectedUnitInfo();
	const addressRequired = !!selectedUnitInfo?.addressRequired;
	const [
		patientAddressLine1,
		patientCity,
		patientState,
		patientZipCode,
	] = watch(['patientAddressLine1', 'patientCity', 'patientState', 'patientZipCode']);
	const isAddressInfoPresent = patientAddressLine1 || patientCity || patientState || patientZipCode;
	const shouldFieldsBeRequired = !!(addressRequired && (!isEdit || isAddressInfoPresent));

	return { shouldFieldsBeRequired };
};
