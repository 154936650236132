import { Dispatch, SetStateAction } from 'react';
import NoParamNoReturnCallback from '@interfaces/NoParamNoReturnCallback';

export enum SelectedDocumentAction {
	NONE = 'NONE',
	DELETE = 'DELETE',
	PREVIEW = 'PREVIEW'
}

export interface SelectedDocIndexAction {
	index: number | null;
	action: SelectedDocumentAction;
}

export interface DocumentRequestContextType {
	selectedDocument: File | undefined;
	selectedIndexAndAction: SelectedDocIndexAction;
	setSelectedIndexAndAction: Dispatch<SetStateAction<SelectedDocIndexAction>>;
	handleDeleteDocument: NoParamNoReturnCallback;
	handleCloseActionModal: NoParamNoReturnCallback;
}
