import React, { ChangeEvent, KeyboardEventHandler } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormLabel, makeStyles, Typography } from '@material-ui/core';
import { TextField } from '@ascension/ui-library';
import { useFieldFormContextWithPrevious } from '@utilities/hooks/useFieldFormContextWithPrevious/useFieldFormContextWithPrevious';
import { useFormMeta } from '@store/context/FormMetaContext';
import { checkIsRequired } from '@utilities/formUtilities';
import Chip from '@components/Chip/Chip';
import {
	ChipValues,
	SurgeryProcedureInterface
} from '../../RequestForm/SurgeryInformation/SurgeryInformationInterface';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import getRequestFormLabelStyle from '@components/RequestForm/utilities/getRequestFormLabelStyle';

const initialValue: ChipValues = { currentEntry: '', entriesList: [] };

const delimiterRegExp = /[\s,]/;

const entryShouldBeAdded = (entry: string) => {
	return entry && (delimiterRegExp.test(entry) || entry.length >= 5);
};

const addToListFromString = (list: string[], entry: string) => list.concat(entry.split(delimiterRegExp).filter((testForLength: string) => testForLength.length));

const useCodeChipFormContext = () => {
	const { schema } = useFormMeta<SurgeryProcedureInterface>();
	const validationRules = schema['cptCodes'];
	const { getValues } = useFormContext<{ cptCodes: { currentEntry: string; entriesList: string[]; } }>();
	const initialCptCodes = React.useRef<ChipValues | null>(null);

	const isRequired = React.useMemo(() => checkIsRequired(validationRules), [validationRules]);

	const {
		currentValue = initialValue,
		setCurrentValue,
		error,
		triggerSelfValidation,
	} = useFieldFormContextWithPrevious<ChipValues>('cptCodes', { validationRules, triggerValidationOnChange: false });

	const { currentEntry, entriesList } = currentValue;

	const checkValidationRef = React.useRef(false);
	React.useEffect(
		() => {
			if (!checkValidationRef.current) {
				return;
			}
			// Reset validation back to false so check only happens once
			checkValidationRef.current = false;
			void triggerSelfValidation();
		},
		// Run validation when currentValue changes
		[currentValue, triggerSelfValidation]);

	const valUpdater = React.useCallback((listUpdater: (list: string[], entry?: string) => ([string[], string] | [string[]])) => {
		setCurrentValue(({ entriesList: newEntriesList = [], currentEntry: newCurrentEntry }: ChipValues = initialValue): ChipValues => {
			const [list, entry = newCurrentEntry] = listUpdater(newEntriesList, newCurrentEntry);
			return { currentEntry: entry, entriesList: list };
		});
	}, [setCurrentValue]);

	// Update initial values on load
	React.useEffect(() => {
		// Values already set so no need for the rest of this
		if (initialCptCodes.current) { return; }
		// Set to current value of cptCodes or with empty values
		const { currentEntry: currentEntryValue = '', entriesList: entriesListValue = [] } = getValues()['cptCodes'] || {};
		initialCptCodes.current = { currentEntry: currentEntryValue, entriesList: entriesListValue };
		valUpdater(() => [entriesListValue, currentEntryValue]);
	}, [getValues, valUpdater]);

	const setCptEntry = React.useCallback((entry: string = '') => {
		if (entryShouldBeAdded(entry)) {
			valUpdater((list: string[] = []) => {
				// clear error on update
				checkValidationRef.current = true;
				return [addToListFromString(list, entry), ''];
			});
		} else {
			valUpdater((l) => [l, entry]);
		}
	}, [valUpdater]);

	const addCptCodeAndValidate = React.useCallback(() => {
		valUpdater((list: string[] = [], entry = '') => {
			// Runs on blur, should check
			checkValidationRef.current = true;
			if (!entryShouldBeAdded(entry)) { return [list]; }
			return [addToListFromString(list, entry), ''];
		});
	}, [valUpdater]);

	const removeCptCode = React.useCallback((code) => {
		valUpdater(l => [l.filter((c) => c !== code)]);
	}, [valUpdater]);

	return {
		addCptCodeAndValidate,
		removeCptCode,
		setCptEntry,
		cptEntry: currentEntry,
		cptList: entriesList,
		isRequired,
		error,
		triggerSelfValidation,
	};
};

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		paddingBottom: theme.spacing(3),
		marginBottom: theme.spacing(1),
	},
	innerContainer: {
		'display': 'flex',
		'flexWrap': 'wrap',
		'borderRadius': '4px',
		'padding': theme.spacing(1, 2),
		'cursor': 'text',
		'border': '1px solid #999',
		'&:hover': {
			border: '2px solid #1E69D2',
		},
	},
	formLabel: {
		'&.MuiFormLabel-root': getRequestFormLabelStyle(theme),
		'marginBottom': theme.spacing(1),
	},
	chipContainer: {
		display: 'inline',
		padding: theme.spacing(.5),
	},
	errorStyle: {
		margin: theme.spacing(.5, 0, 0, .5),
	},
	inputStyle: {
		width: '100px',
		margin: theme.spacing(.5, 0, 0, .5),
	},
	helperText: {
		margin: theme.spacing(.5, 0, 0, .5),
	},
}));

const CodeChipInput = () => {
	const {
		addCptCodeAndValidate,
		removeCptCode,
		setCptEntry,
		cptEntry = '',
		cptList = [],
		error,
		isRequired,
	} = useCodeChipFormContext();
	const [isActive, setIsActive] = React.useState(false);

	const { container, innerContainer, chipContainer, errorStyle, inputStyle, helperText, formLabel } = useStyles();
	const getBorderStyle = (): string => {
		if (error) {
			return '2px solid #BF0000';
		}
		if (isActive) {
			return '2px solid #1E69D2';
		}
		return '';
	};
	const borderStyle = {
		'border': getBorderStyle(),
		'&:hover': {
			border: '2px solid #1E69D2',
		}
	};
	const getCustomHeight = (): string => {
		if (!isActive && cptList.length && !cptEntry.length) {
			return '0';
		} else {
			return '';
		}
	};

	const addEntryOnEnter: KeyboardEventHandler<HTMLDivElement> = (keyBoardEvent) => {
		if (keyBoardEvent.key === 'Enter') {
			const inputTypedEvent = keyBoardEvent as unknown as ChangeEvent<HTMLInputElement>;
			setCptEntry(inputTypedEvent.target.value + '\n');
		}
	};

	const handleInputFocus = () => {
		void document.getElementById('cptCodes')?.focus();
		setIsActive(true);
	};

	const handleBlur = () => {
		addCptCodeAndValidate();
		setIsActive(false);
	};

	return (
		<div className={container}>
			<FormLabel required={isRequired} htmlFor="cptCodes" className={formLabel}>
				CPT CODE(S)
			</FormLabel>
			<div>
				<div className={innerContainer} style={borderStyle} onClick={handleInputFocus} data-field="cptCodesContainer">
					{cptList.map((item: string, index) => (
						<div key={index} data-field={'chip' + index} className={chipContainer}>
							<Chip onDelete={() => removeCptCode(item)} data-field={'chipValue' + index}>{item}</Chip>
						</div>
					))}
					<TextField
						translate="yes"
						id="cptCodes"
						name="cptCodesInner"
						data-field="cptCodes"
						required={isRequired}
						value={cptEntry}
						onChange={(e) => setCptEntry(e.target.value)}
						onBlur={handleBlur}
						onKeyUp={(e) => addEntryOnEnter(e)}
						InputProps={{ disableUnderline: true }}
						className={inputStyle}
						style={{ height: getCustomHeight() }}
						aria-describedby="cptCodeError"
					/>
				</div>
				{error ? (
					<div className={errorStyle} id="cptCodeError">
						<ErrorMessage errorField={{ message: error }} />
					</div>
				) : (
					<Typography className={helperText} color="textSecondary" component="p" variant="caption">
						Please list CPT codes by highest priority
					</Typography>
				)}
			</div>
		</div>
	);
};

export default CodeChipInput;
