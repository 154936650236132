import React from 'react';
import { formatInTimeZone } from 'date-fns-tz';
import { Box, Divider, Theme, Typography } from '@material-ui/core';
import { alpha, makeStyles } from '@material-ui/core/styles';
import CalendarLayoutProps from '@components/UnitCalendarPage/CalendarLayout/CalendarLayoutProps';
import { Calendar, FocusDescriptor, FocusType, getMidnightLocal } from '@calendar';
import CalendarDayNavigation from '@components/CalendarDayNavigation/CalendarDayNavigation';
import EventErrorDialog from '@components/CalendarError/EventErrorDialog';
import EventLegend from '@components/UnitCalendarPage/EventLegend/EventLegend';
import { convert24TimeToDateObj } from '@utilities/dateUtilities';

const useStyles = makeStyles((theme: Theme) => ({
	calendarContainer: {
		'&::-webkit-scrollbar': {
			width: 5,
			height: 5,
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.palette.grey[600],
			borderRadius: '100px',
		},
		'overflow': 'auto',
		'background': 'white',
		'gridArea': 'calendar',
	},
	errorOverlay: {
		gridArea: 'calendar',
	},
	legend: {
		gridArea: 'legend',
		zIndex: 1301,
		backgroundColor: theme.palette.common.white,
	},
	body: {
		position: 'relative',
		overflow: 'hidden',
		display: 'grid',
		gridTemplateRows: '1fr min-content',
		gridTemplateAreas: `
			"calendar"
			"legend"
		`,
	},
	title: {
		marginTop: theme.spacing(4),
	},
	main: {
		display: 'flex',
		flexDirection: 'column',
		height: 'calc(100vh - 72px)',
		margin: theme.spacing(0, 5),
	},
	header: {
		'display': 'flex',
		'flexDirection': 'column',
		'& .MuiDivider-root': {
			margin: theme.spacing(2, 0),
		},
	},
	fullWidthDivider: {
		'&.MuiDivider-root': {
			margin: theme.spacing(2, -5, 1),
		},
	},
	timeString: {
		color: alpha(theme.palette.common.black, .87),
		backgroundColor: theme.palette.primary[50],
		marginTop: theme.spacing(2),
		padding: theme.spacing(1, 2),
		alignSelf: 'start',
	},
	halfXl: {
		flex: '0 1 auto',
		[theme.breakpoints.up('xl')]: {
			flexBasis: '50%',
		}
	},
	standardFlexGap: {
		gap: theme.spacing(2, 0),
	},
}));

function CalendarLayout<EventEnum>({
	eventConfig,
	eventStyleConfig,
	errorContent,
	events,
	columns,
	unitConfig: { start, end, interval, hospitalTimeZone },
	onCalendarClick,
	selectedDate,
	onSelectedDateChange,
	autoScrollWhenUpdate,
	title,
	rightUpperHeader,
	rightLowerHeader,
	focusOn: providedFocusOn,
}: CalendarLayoutProps<EventEnum>) {
	const classes = useStyles();
	const focusOn: FocusDescriptor = React.useMemo(() => providedFocusOn || ({ type: FocusType.TIME, id: start }), [start, providedFocusOn]);

	const scrollDependencies = React.useMemo(() => [selectedDate, autoScrollWhenUpdate], [selectedDate, autoScrollWhenUpdate]);

	const tickStart = React.useMemo(() => getMidnightLocal(hospitalTimeZone, selectedDate), [hospitalTimeZone, selectedDate]);

	const formatTime = React.useCallback((timeOnly24Hr: string) => {
		const dateObj = convert24TimeToDateObj(timeOnly24Hr, hospitalTimeZone, selectedDate);
		if (!dateObj) { return; }

		return formatInTimeZone(dateObj, hospitalTimeZone, 'h:mm a');
	}, [hospitalTimeZone, selectedDate]);

	const startStr = React.useMemo(() => formatTime(start), [formatTime, start]);
	const endStr = React.useMemo(() => formatTime(end), [formatTime, end]);

	const [headerHeight, setHeaderHeight] = React.useState<number | undefined>();

	return (
		<Box className={classes.main}>
			<Box className={classes.header}>
				<Box display="flex" justifyContent="space-between" alignItems="flex-end">
					<Typography variant="h4" component="h1" className={classes.title}>{title}</Typography>
					{rightUpperHeader}
				</Box>
				<Divider />
				<Box display="flex" flexDirection="row" flexWrap="wrap" alignItems="flex-end" className={classes.standardFlexGap}  justifyContent="space-between">
					<Box className={classes.halfXl}>
						<CalendarDayNavigation
							selectedDate={selectedDate}
							onSelectedDateChange={onSelectedDateChange}
						/>
					</Box>
					<Box className={classes.halfXl}>
						{rightLowerHeader}
					</Box>
				</Box>
				{startStr && endStr && <Box className={classes.timeString}>
					<Typography variant="subtitle2" component="p">This unit is available for scheduling between {startStr}–{endStr}</Typography>
				</Box>}
				<Divider variant="fullWidth" className={classes.fullWidthDivider}/>
			</Box>
			<Box className={classes.body}>
				<Calendar
					tickStart={tickStart}
					className={classes.calendarContainer}
					columns={columns}
					eventConfig={eventConfig}
					events={events}
					focusOn={focusOn}
					interval={interval}
					timezone={hospitalTimeZone}
					scrollWhenPropUpdates={scrollDependencies}
					onCalendarClick={onCalendarClick}
					onHeaderHeightChange={setHeaderHeight}
				/>
				{ errorContent && <EventErrorDialog className={classes.errorOverlay} headerHeight={headerHeight}>{errorContent}</EventErrorDialog> }
				<EventLegend<EventEnum> className={classes.legend} config={eventConfig} styleConfig={eventStyleConfig}/>
			</Box>
		</Box>
	);
}

export default CalendarLayout;
