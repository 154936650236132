import React from 'react';
import { AppPageContextType, AppPageControlledProperties } from './AppPageContextType';
import { maxWidthContainerStyle } from '@theme/useMaxWidthContainerStyle';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const AppPageContext = React.createContext<AppPageContextType | null>(null);

const useAppPageContextWithError = (methodName: string) => {
	const ctx = React.useContext(AppPageContext);

	if (!ctx) {
		throw new Error(`${methodName} must be used in a subcomponent of AppPageContextProvider`);
	}

	return ctx;
};

export const useAppPageControlledProperties = (): AppPageControlledProperties => {
	const {
		bodyClass,
		shouldApplyToHeader,
	} = useAppPageContextWithError('useAppPageControlledProperties');

	return {
		bodyClass,
		shouldApplyToHeader,
	};
};

export const useAppPageBodyClassSetter = (controllerId: string) => {
	const { setBodyClasses, setShouldApplyToHeader } = useAppPageContextWithError('useAppPageBodyClassSetter');

	React.useEffect(() =>
		// Remove class for associated controllerId from list on dismount
		() => {
			setBodyClasses((classes) => {
				const updatedClasses = { ...classes };
				delete updatedClasses[controllerId];
				setShouldApplyToHeader(false);
				return updatedClasses;
			});
		}
	, [controllerId, setBodyClasses, setShouldApplyToHeader]);

	const setBodyClass = React.useCallback((className: string) => {
		// Update || add class to indexed list
		setBodyClasses((classes ) => {
			const updatedClasses: Record<string, string> = { ...classes };
			updatedClasses[controllerId] = className;
			return updatedClasses;
		});
	}, [controllerId, setBodyClasses]);

	return {
		setBodyClass,
		setShouldApplyToHeader,
	};
};

const useStyles = makeStyles(() => createStyles({
	appPageMaxWidth: {
		'& > div': maxWidthContainerStyle(),
	}
}));

export const AppPageContextProvider: React.FC = ({
	children
}) => {
	const classes = useStyles();
	const [bodyClasses, setBodyClasses] = React.useState<Record<string, string>>({ main: classes.appPageMaxWidth });
	const [shouldApplyToHeader, setShouldApplyToHeader] = React.useState<boolean>(false);

	// Convert indexed classes to list
	const bodyClass = Object.values(bodyClasses).join(' ');

	return (
		<AppPageContext.Provider value={{
			setBodyClasses,
			bodyClass,
			shouldApplyToHeader,
			setShouldApplyToHeader,
		}}>
			{children}
		</AppPageContext.Provider>
	);
};
