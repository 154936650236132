import { SurgeryRequestGet } from '../../../interfaces/SurgeryRequest/SurgeryRequestGet';
import { UserRole } from '../../../interfaces/UserProfile/UserRoleEnum';
import { UserProfileHasARoleSelector } from '../../../store/selectors/UserProfileSelector';
import { useSelector } from 'react-redux';
import { CaseTrackerListInterface } from '../../../components/CaseTrackerList/CaseTrackerListInterface';

const useHasEhrIntegrationAndRole = ({ hospitalCernerId }: Partial<SurgeryRequestGet> | CaseTrackerListInterface = {}, roles?: UserRole[]): boolean => {
	const userHasCorrectRole = useSelector(UserProfileHasARoleSelector(roles)) || !roles;

	return !!hospitalCernerId && userHasCorrectRole;
};

export default useHasEhrIntegrationAndRole;
