import { Theme as DefaultTheme } from '@material-ui/core/styles/createTheme';

const getRequestFormLabelStyle = (theme: DefaultTheme) => ({
	color: theme.palette.common.black,
	fontFamily: 'Lato',
	textTransform: 'uppercase',
	letterSpacing: '1px',
	fontSize: '12px',
	lineHeight: '1.25',
	fontWeight: 700
});

export default getRequestFormLabelStyle;
