import React from 'react';
import { Location } from 'history';
import { Prompt } from 'react-router-dom';
import { NavigationWarnModal } from '@components/RequestForm/NavigationWarnModal/NavigationWarnModal';
import { NAVIGATION_WARN_MODAL } from '@components/Modal/modalConstants';
import {
	useRequestFormNavigationHandlers
} from '@components/RequestForm/utilities/hooks/useNavigationHandlers/useRequestFormNavigationHandlers/useRequestFormNavigationHandlers';

interface RouteNavigationGuardProps {
	when?: boolean;
	shouldBlockNavigation: (location: Location) => boolean;
	onConfirm?: () => void;
}

export const RouteNavigationGuard = ({
	when,
	shouldBlockNavigation,
	onConfirm,
}: RouteNavigationGuardProps) => {
	const { handleStayOnPage, handleBlockedNavigation, handleConfirmNavigationClick } =
		useRequestFormNavigationHandlers({ shouldBlockNavigation });

	const handleConfirm = React.useCallback(() => {
		handleConfirmNavigationClick();
		onConfirm && onConfirm();
	}, [handleConfirmNavigationClick, onConfirm]);

	return (
		<>
			<Prompt when={when} message={handleBlockedNavigation} />
			<NavigationWarnModal
				id={NAVIGATION_WARN_MODAL}
				onCancel={handleStayOnPage}
				onClose={handleStayOnPage}
				onConfirm={handleConfirm}
			/>
		</>
	);
};
