import React from 'react';
import { Box, Card, Flex, Text } from '@ascension/web';
import { dateParse, getDayMonthDateFromString, utcToLocalTime } from '@utilities/dateUtilities';
import { CaseCardProps } from '../CaseTrackerListInterface';
import { SurgeryRequestStatusEnum } from '@interfaces/SurgeryRequest/SurgeryRequestStatusEnum';
import { NotificationChip } from './NotificationChip';
import { NotificationTypeEnum } from '@interfaces/SurgeryRequest/NotificationTypeEnum';
import { RibbonHollow } from '../../RibbonHollow/RibbonHollow';
import { getFormattedName } from '@utilities/commonFunction';
import { useCommonStyles } from '@utilities/hooks/useCommonStyles/useCommonStyles';
import CaseIdentifier from '../../CaseIdentifier/CaseIdentifier';
import { alpha, createStyles, makeStyles } from '@material-ui/core/styles';
import {
	HospitalInteractiveRoles,
	HospitalRoles,
	OfficeRoles,
	UserRole
} from '@interfaces/UserProfile/UserRoleEnum';
import { useSelector } from 'react-redux';
import {
	UserProfileDetailsSelector,
	UserProfileHasARoleSelector,
	UserProfileRoleSelector
} from '@store/selectors/UserProfileSelector';
import useHasEhrIntegrationAndRole
	from '../../../utilities/hooks/useHasEhrIntegrationAndRole/useHasEhrIntegrationAndRole';
import CaseInUseOverlay from './CaseInUseOverlay/CaseInUseOverlay';
import classNames from 'classnames';
import { UserAccessEnum } from '@interfaces/SurgeryRequest/UserAccessEnum';
import { DisplayText } from '@components/DisplayText/DisplayText';
import { buildPatientDisplayName } from '@utilities/buildPatientDisplayName';
import { AttachFileRounded, LaptopChromebookRounded } from '@material-ui/icons';
import { AppointmentType } from '@data/request/AppointmentType';
import { Typography } from '@material-ui/core';
import Chip from '@components/Chip/Chip';
import { MUI_STYLE_VARIANTS } from '@theme/themeConstants';

const useStyles = makeStyles((theme) => createStyles({
	root: {
		'position': 'relative',
		'& .case-identifier-group > span': {
			fontSize: '.75rem',
			lineHeight: '1rem',
		},
		'& .left-indicator': {
			'border': 'unset',
			'borderLeft': 'solid 6px',
			'&.red': {
				borderLeftColor: theme.palette.error.main,
			},
			'&.grey': {
				borderLeftColor: theme.palette.grey['700'],
			}
		},
	},
	flexWithGap: {
		gap: '10px',
	},
	chipContainer: {
		gap: theme.spacing(.5),
		marginRight: theme.spacing(.25),
		marginLeft: theme.spacing(.25),
	},
	caseInUseOverlay: {
		'paddingTop': theme.spacing(4.5),
		'& > div': {
			borderRadius: '0px',
		},
		'& $caseInUserOverlayDefault': {
			display: 'block',
		},
	},
	caseInUserOverlayDefault: {
		display: 'none',
	},
	patientNameDisplay: {
		'& .label-text': {
			display: 'none',
		},
		'& .default-value-text, .value-text': {
			fontWeight: 700,
			fontSize: '14px',
			color: theme.palette.common.black,
			paddingBottom: theme.spacing(.5),
		},
	},
	authVendorDisplay: {
		'& .label-text': {
			fontSize: '10px',
			color: theme.palette.grey[700],
			fontWeight: 500,
			letterSpacing: 0,
		},
		'& .default-value-text, .value-text': {
			fontSize: '14px',
		},
	},
	requestType: {
		'& .MuiTypography-caption': {
			'display': 'flex',
			'alignItems': 'center',
			'color': alpha(theme.palette.common.black, 0.6),
			'marginTop': theme.spacing(2.5),
			'& .MuiSvgIcon-root': {
				marginRight: theme.spacing(0.5),
			},
		},
	},
}));

interface ChipOptions {
	color?: MUI_STYLE_VARIANTS;
	chipText: string;
}

const CHIP_OPTIONS: Record<SurgeryRequestStatusEnum, ChipOptions> = {
	[SurgeryRequestStatusEnum.UNSCHEDULED]: {
		color: 'warning',
		chipText: 'Unscheduled',
	},
	[SurgeryRequestStatusEnum.SCHEDULED]: {
		color: 'success',
		chipText: 'Scheduled',
	},
	[SurgeryRequestStatusEnum.PENDING_CANCELLED]: {
		color: 'default',
		chipText: 'Pending Cancellation',
	},
	[SurgeryRequestStatusEnum.CANCELLED]: {
		color: 'default',
		chipText: 'Cancelled',
	},
};

interface LeftIndicatorOptions {
	color: string;
	priority: number;
}

interface NotificationOptions extends ChipOptions {
	displayAs?: NotificationTypeEnum;
	leftIndicator?: LeftIndicatorOptions;
	showForRoles?: UserRole[];
	priority: number;
}

const redHighPriorityLeftIndicator: LeftIndicatorOptions = {
	color: 'red',
	priority: 100,
};

const greyLeftIndicator: LeftIndicatorOptions = {
	color: 'grey',
	priority: 50,
};

const NOTIFICATION_OPTIONS: Record<NotificationTypeEnum, NotificationOptions> = {
	[NotificationTypeEnum.CANCELLED]: {
		color: 'error',
		chipText: NotificationTypeEnum.WARNING.toLowerCase(),
		displayAs: NotificationTypeEnum.WARNING,
		leftIndicator: redHighPriorityLeftIndicator,
		priority: 100,
	},
	[NotificationTypeEnum.PENDING_CANCELLED]: {
		color: 'error',
		chipText: NotificationTypeEnum.WARNING.toLowerCase(),
		displayAs: NotificationTypeEnum.WARNING,
		leftIndicator: redHighPriorityLeftIndicator,
		priority: 100,
	},
	[NotificationTypeEnum.WARNING]: {
		color: 'error',
		chipText: NotificationTypeEnum.WARNING.toLowerCase(),
		leftIndicator: redHighPriorityLeftIndicator,
		priority: 100,
	},
	[NotificationTypeEnum.UNSCHEDULED]: {
		color: 'error',
		chipText: NotificationTypeEnum.WARNING.toLowerCase(),
		priority: 0,
	},
	[NotificationTypeEnum.REVISED]: {
		color: 'secondary',
		chipText: NotificationTypeEnum.REVISED.toLowerCase(),
		priority: 0,
	},
	[NotificationTypeEnum.NEED_REVISION]: {
		color: 'secondary',
		chipText: 'Needs Revision',
		priority: 0,
	},
	[NotificationTypeEnum.PENDING_LINK]: {
		color: 'default',
		chipText: 'Pending Link',
		showForRoles: HospitalRoles,
		leftIndicator: greyLeftIndicator,
		priority: 50,
	},
	[NotificationTypeEnum.EHR_CONFLICT]: {
		color: 'error',
		chipText: 'Data Conflict',
		showForRoles: HospitalRoles,
		leftIndicator: redHighPriorityLeftIndicator,
		priority: 50,
	},
	[NotificationTypeEnum.UPDATED_DATE]: {
		color: 'error',
		chipText: 'Updated Date',
		showForRoles: OfficeRoles,
		leftIndicator: redHighPriorityLeftIndicator,
		priority: 50,
	},
};

const requestTypeContent: Record<AppointmentType, JSX.Element> = {
	[AppointmentType.WEB]: <Typography variant="caption"><LaptopChromebookRounded/>Web Request</Typography>,
	[AppointmentType.LEGACY_WEB]: <Typography variant="caption"><LaptopChromebookRounded/>Web Request</Typography>,
	[AppointmentType.DOCUMENT]: <Typography variant="caption"><AttachFileRounded/>Document Request</Typography>,
};

export default function CaseCard({ caseDetails, ...rest }: CaseCardProps) {
	const classes = useStyles();
	const userRoles = useSelector(UserProfileRoleSelector);
	const { userId } = useSelector(UserProfileDetailsSelector);
	const hasHospitalInteractiveRole = useSelector(UserProfileHasARoleSelector(HospitalInteractiveRoles));
	const parsedProcDate = React.useMemo(
		() => caseDetails.procedureDate && dateParse(caseDetails.procedureDate),
		[caseDetails.procedureDate]);
	const [procDay, procMonth, procDate] = getDayMonthDateFromString(caseDetails.procedureDate);

	const convertedDateCreated = utcToLocalTime(caseDetails.dateCreated);
	const [, createdTime, createdMeridiem] = convertedDateCreated ? dateParse(convertedDateCreated) : [];
	const [, createdMonth, createdDate] = convertedDateCreated ? getDayMonthDateFromString(convertedDateCreated) : [];
	const { performer } = caseDetails && caseDetails.performer ? caseDetails : { performer: null };
	const { firstName, lastName } = performer ? performer : { firstName: null, lastName: null };

	const showBlk = caseDetails.blockTime && caseDetails.status !== SurgeryRequestStatusEnum.PENDING_CANCELLED.toString()
		&& caseDetails.status !== SurgeryRequestStatusEnum.CANCELLED.toString();
	const { color: chipColor = 'warning', chipText: chipStatus = '' } = CHIP_OPTIONS[caseDetails.status as SurgeryRequestStatusEnum];
	const [notificationDescriptors, notificationCardClass] = React.useMemo(() => {
		const notiIndexed: Record<NotificationTypeEnum, true> = caseDetails.notifications.reduce((indexedNotifications: Record<string, true>, { type: currentNotificationType }) => {
			const notificationConfig = NOTIFICATION_OPTIONS[currentNotificationType];
			if (!notificationConfig) { return indexedNotifications; }
			if (notificationConfig.showForRoles && !userRoles.some((role) => notificationConfig.showForRoles?.includes(role))) {
				return indexedNotifications;
			}
			const notificationMappedType = notificationConfig.displayAs || currentNotificationType;
			indexedNotifications[notificationMappedType] = true;
			return indexedNotifications;
		}, {});
		const cardNotificationTypes = Object.keys(notiIndexed) as NotificationTypeEnum[];
		const notiDescriptors = cardNotificationTypes.map((notificationType: NotificationTypeEnum) => {
			const { color, chipText: text, showForRoles, priority } = NOTIFICATION_OPTIONS[notificationType];
			return {
				value: notificationType,
				color,
				text,
				showForRoles,
				priority
			};
		});
		const leftIndicator = cardNotificationTypes.reduce((highestPriorityIndicator: LeftIndicatorOptions | undefined, notificationType) => {
			const { leftIndicator } = NOTIFICATION_OPTIONS[notificationType];
			if (!leftIndicator) {
				return highestPriorityIndicator;
			}
			return highestPriorityIndicator && highestPriorityIndicator.priority > leftIndicator.priority ? highestPriorityIndicator : leftIndicator;
		}, undefined);
		const notificationCardClass = leftIndicator ? `left-indicator ${leftIndicator.color}` : '';
		const orderedNotiDescriptors = notiDescriptors.sort((a, b) => b.priority - a.priority);
		return [orderedNotiDescriptors, notificationCardClass];
	}, [caseDetails.notifications, userRoles]);

	const surgeonName = React.useMemo(() => getFormattedName(caseDetails.surgeon, ''), [caseDetails.surgeon]);

	const hasUpdatedDateNotification = useHasEhrIntegrationAndRole(caseDetails, OfficeRoles) && caseDetails.notifications.some(n => n.type === NotificationTypeEnum.UPDATED_DATE);

	const { upperCase, hideTextOverflow } = useCommonStyles();

	const existingConfirmerAccess = caseDetails.accessors?.find(acc => acc.access === UserAccessEnum.CONFIRMER);
	const showCaseInUse = hasHospitalInteractiveRole && existingConfirmerAccess && existingConfirmerAccess.userId !== userId;
	const managingUser = showCaseInUse ? `${existingConfirmerAccess?.firstName} ${existingConfirmerAccess?.lastName}` : '';
	const displayPatientName = buildPatientDisplayName({
		name: caseDetails.patientName,
		family: caseDetails.patientFamilyName,
		middleName: caseDetails.patientMiddleName
	});

	return (
		<Flex
			justifyContent="center"
			{...rest}
			className={classNames(classes.root, { [classes.caseInUseOverlay]: showCaseInUse })}
		>
			<Card
				mt={8}
				flexDirection="column"
				className={notificationCardClass}
				elevated
				width={1}
			>
				<CaseInUseOverlay managingUser={managingUser} className={classes.caseInUserOverlayDefault}/>
				<Flex flexDirection="row" width={1} className={classes.flexWithGap}>
					<Box pt={3}>
						<RibbonHollow requestId={caseDetails.id} bookmarked={caseDetails.bookmarked}/>
					</Box>
					<Flex flexDirection="column" width={1}>
						<Flex justifyContent="space-between" width={1} alignItems="start">
							<Flex flexDirection="row">
								<Box>
									<Text fontSize={20} fontWeight={700} mr={5} color={hasUpdatedDateNotification ? '#E00019' : ''} data-field={'procDate' + caseDetails.id}>
										{procDay}, {procMonth}. {procDate}
									</Text>
								</Box>
								<Text
									fontSize={20}
									color="#C9C9C9"
									pl="8px"
									pr="8px"
								>
									|
								</Text>
								<Text fontSize={20} fontWeight={700} mx={5} data-field={'appointmentTime' + caseDetails.id}>
									{parsedProcDate && parsedProcDate[1]}{' '}
									<Text fontSize="10px" fontWeight={700} className={upperCase}>
										{parsedProcDate && parsedProcDate[2]}
									</Text>
								</Text>
								<Box display="flex" alignItems="start" className={classes.chipContainer}>
									<Chip data-field={`casecard-${caseDetails.status}-tag`} color={chipColor}>
										{chipStatus}
									</Chip>
									{showBlk && <Chip data-field="casecard-blk-tag" color={chipColor}>BLK</Chip>}
									{notificationDescriptors.map(({ value, color, text, showForRoles }) =>
										<NotificationChip
											key={`${caseDetails.id}${value}`}
											type={value}
											chipColor={color}
											chipText={text}
											showForRoles={showForRoles}
										/>
									)}
								</Box>
							</Flex>
							<Flex>
								<Text fontSize={20} fontWeight={700} data-field={'surgeonName' + caseDetails.id}>
									{surgeonName}
								</Text>
							</Flex>
						</Flex>
						<CaseIdentifier caseDetails={caseDetails} />
					</Flex>
				</Flex>
				<hr className="case-card-divider"/>
				<Flex width={1} alignItems="start" mt="8px">
					<Flex flexDirection="column" width={.5} pl="26px">
						<DisplayText
							label=""
							defaultValue="--"
							displayDefault={!displayPatientName.length}
							className={classes.patientNameDisplay}
							data-field={'patientName' + caseDetails.id}
						>
							{displayPatientName}
						</DisplayText>
						<Text fontSize="14px" data-field={'procedureName' + caseDetails.id} pb="4px" className={hideTextOverflow}>
							{caseDetails.procedureName}
						</Text>
						<Text fontSize="14px" data-field={'location' + caseDetails.id}>
							{caseDetails.hospital} {caseDetails.unit}
						</Text>
					</Flex>
					<Flex width={.2}>
						<DisplayText
							label="Type"
							defaultValue="--"
							displayDefault={!caseDetails.authStatus}
							className={classes.authVendorDisplay}
							data-field={'type' + caseDetails.id}
						>
							{caseDetails.authStatus}
						</DisplayText>
					</Flex>
					<Flex width={.2}>
						<DisplayText
							label="Vendor"
							defaultValue="--"
							displayDefault={!caseDetails.vendor}
							className={classes.authVendorDisplay}
							data-field={'vendor' + caseDetails.id}
						>
							{caseDetails.vendor}
						</DisplayText>
					</Flex>
				</Flex>
				<Flex width={1} justifyContent="space-between">
					{caseDetails.dateCreated && performer &&
						<Flex justifyContent="start" pl="26px" mt="20px" alignItems="center" data-field="createdDetails">
							<Text fontSize="12px" color="#777777" pr="8px" fontWeight={600} verticalAlign="middle" height="12px" pb="4px">
								Created By:
							</Text>
							<Text fontSize="14px" fontWeight={700} data-field={'createdPerformer' + caseDetails.id}>
								{firstName} {lastName}
							</Text>
							<Flex color="#777777" fontStyle="italic" fontSize="10px" alignItems="center" pl="4px">
								<Text data-field={'createdTime' + caseDetails.id}>
									{createdTime + ' '}
								</Text>
								<Text className={upperCase} pl="2px" data-field={'createdMeridiem' + caseDetails.id}>
									{createdMeridiem}
								</Text>
								<Text pl="2px" data-field={'createdMonthDate' + caseDetails.id}>
									{createdMonth} {createdDate}
								</Text>
							</Flex>
						</Flex>
					}
					{caseDetails.appointmentType && requestTypeContent[caseDetails.appointmentType] &&
						<Box className={classes.requestType} data-field={`caseDetails-caseCard-requestType-${caseDetails.id}`}>
							{requestTypeContent[caseDetails.appointmentType]}
						</Box>
					}
				</Flex>
			</Card>
		</Flex>
	);
}
