import { PermittedAction } from './PermittedAction';
import {
	HospitalInteractiveRoles,
	HospitalRoles,
	OfficeInteractiveRoles, OfficeRoles,
	UserRole
} from '@interfaces/UserProfile/UserRoleEnum';


const PermittedActionToUserRole: Record<PermittedAction, UserRole[]> = {
	[PermittedAction.ENTER_FIN]: HospitalInteractiveRoles,
	[PermittedAction.REQUEST_CASE_REVISION]: HospitalInteractiveRoles,
	[PermittedAction.CONFIRM_CANCEL_CASE]: HospitalInteractiveRoles,
	[PermittedAction.CONFIRM_CASE_SCHEDULE]: HospitalInteractiveRoles,
	[PermittedAction.ACKNOWLEDGE_REVISION_ALERT]: HospitalInteractiveRoles,
	[PermittedAction.ACKNOWLEDGE_CASE_ALERT]: OfficeInteractiveRoles,
	[PermittedAction.CREATE_CASE]: OfficeInteractiveRoles,
	[PermittedAction.MANAGE_CASE]: OfficeInteractiveRoles,
	[PermittedAction.MODIFY_TEMPLATE]: OfficeInteractiveRoles,
	[PermittedAction.VIEW_CONDENSED]: HospitalRoles,
	[PermittedAction.CREATE_HOLD_EVENT]: OfficeInteractiveRoles,
	[PermittedAction.EDIT_INDEX]: HospitalRoles,
	[PermittedAction.EDIT_INDEX_NO_CONFIRM]: [UserRole.OR_VIEWER],
	[PermittedAction.VIEW_NAVIGATION_OPTIONS]: OfficeRoles,
};

export default PermittedActionToUserRole;
