interface DocumentTypeOptions {
	key: string;
	value: string;
}
export const documentTypeOptions: DocumentTypeOptions[] = [
	{ key: 'Authorizations', value: 'Authorizations' },
	{ key: 'Booking Sheet', value: 'Booking Sheet' },
	{ key: 'Consents', value: 'Consents' },
	{ key: 'Driver\'s License', value: 'Driver\'s License' },
	{ key: 'History and Physicals', value: 'History and Physicals' },
	{ key: 'Insurance', value: 'Insurance' },
	{ key: 'Legal Documents', value: 'Legal Documents' },
	{ key: 'Orders', value: 'Orders' },
	{ key: 'Other', value: 'Other' },
	{ key: 'Patient Demographics', value: 'Patient Demographics' },
	{ key: 'Surgical Clearance', value: 'Surgical Clearance' },
];
