import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { AvailableTimeOption } from '@data/findATime/types/AvailableTimeOption';
import { useFindATimeContext } from '@components/FindATime/FindATimeContext/FindATimeContextProvider';
import { useTimeAndDurationWatcher } from '@components/RequestForm/utilities/hooks/useTimeAndDurationWatcher/useTimeAndDurationWatcher';
import { timeStringAs12Hr } from '@utilities/dateUtilities';
import { useFormContext } from 'react-hook-form';
import CommonRequestForm from '@interfaces/RequestForm/forms/CommonRequestForm';

const useStyles = makeStyles((theme) => ({
	blockTitle: {
		fontWeight: 600,
		color: theme.palette.primary.dark,
	},
	nonBlockTitle: {
		fontWeight: 600,
		color: theme.palette.secondary.dark,
	},
	resultCard: {
		'color': theme.palette.grey[900],
		'cursor': 'pointer',
		'border': `1px solid ${theme.palette.grey[600]}`,
		'borderRadius': theme.spacing(0.5),
		'padding': theme.spacing(1),
		'boxShadow': theme.shadows[6],
		'& p': {
			fontSize: '0.875rem',
		},
		'&[data-block="true"]:hover': {
			backgroundColor: theme.palette.primary[100],
		},
		'&[data-block="false"]:hover': {
			backgroundColor: theme.palette.secondary[100],
		},
	},
	selected: {
		'color': theme.palette.common.white,
		'&[data-block="true"]': {
			'backgroundColor': theme.palette.primary.dark,
			'& $blockTitle': {
				color: theme.palette.common.white,
			},
			'&:hover': {
				'backgroundColor': theme.palette.primary.dark,
				'color': theme.palette.common.white,
				'& $blockTitle': {
					color: theme.palette.common.white,
				},
			}
		},
		'&[data-block="false"]': {
			'backgroundColor': theme.palette.secondary.dark,
			'& $nonBlockTitle': {
				color: theme.palette.common.white,
			},
			'&:hover': {
				'backgroundColor': theme.palette.secondary.dark,
				'color': theme.palette.common.white,
				'& $nonBlockTitle': {
					color: theme.palette.common.white,
				},
			}
		},
	},
}));

interface ResultCardProps {
	date: Date;
	handleSelectTime: (time: AvailableTimeOption, timeSlotId: string, date: Date) => void;
	time: AvailableTimeOption;
	handleSetFindATimeUsed?: (newValue: boolean) => void;
}

export const ResultCard: React.FC<ResultCardProps> = ({ handleSelectTime, time, date, handleSetFindATimeUsed }) => {
	// Must not be randomized, so this can be passed from FindATime to RequestForm with set unique values
	const timeSlotId = React.useMemo(() => `${format(date, 'MM-dd-yyyy')}-${time.start}-${time.end}-${time.block ? 'B' : 'NB'}`, [date, time]);
	const classes = useStyles();
	const { selectedTimeSlot } = useFindATimeContext();
	const { watch } = useFormContext<CommonRequestForm>();
	useTimeAndDurationWatcher(time, timeSlotId);
	const findATimeUsed = watch('findATimeUsed');

	const displayFormattedStartTime = timeStringAs12Hr(time.start);
	const displayFormattedEndTime = timeStringAs12Hr(time.end);

	const selected = React.useMemo(() => {
		return selectedTimeSlot === timeSlotId;
	}, [selectedTimeSlot, timeSlotId]);

	React.useEffect(() => {
		if (handleSetFindATimeUsed && selectedTimeSlot && (selectedTimeSlot === timeSlotId) && !findATimeUsed) {
			handleSetFindATimeUsed(true);
		} else if (handleSetFindATimeUsed && !selectedTimeSlot && findATimeUsed) {
			handleSetFindATimeUsed(false);
		}
	}, [selectedTimeSlot, timeSlotId, selected, findATimeUsed, handleSetFindATimeUsed]);

	return (
		<Box
			className={classNames(classes.resultCard, {
				[classes.selected]: selected,
			})}
			data-block={time.block}
			onClick={() => handleSelectTime(time, timeSlotId, date)}
			data-field={`findATime-resultCard-${timeSlotId}`}
		>
			<Typography
				className={classNames({
					[classes.blockTitle]: time.block,
					[classes.nonBlockTitle]: !time.block,
				})}
			>
				{time.block ? 'Block' : 'Non-Block'}
			</Typography>
			<Typography>
				{displayFormattedStartTime} - {displayFormattedEndTime}
			</Typography>
		</Box>
	);
};
