import { RequestFormPage } from '@components/RequestForm/RequestFormPage';
import CaseTrackerList from '@components/CaseTrackerList/CaseTrackerList';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import React from 'react';
import { AppPage } from '@components/AppPage/AppPage';
import { Login } from '@components/Login/Login';
import AccountSettings from '@components/AccountSettings/AccountSettings';
import { CaseDetailsPageWrapper } from '@components/CaseDetails/CaseDetailsPageWrapper';
import { QueuePage } from '@components/QueuePage/QueuePage';
import {
	HospitalInteractiveRoles,
	OfficeInteractiveRoles,
	OfficeManagerRoles,
	OfficeRoles,
} from '@interfaces/UserProfile/UserRoleEnum';
import GlobalLoader from '@components/Loaders/GlobalLoader/GlobalLoader';
import TermsOfUsePage from '@components/TermsOfUse/TermsOfUsePage';
import AuthBase from '@components/AuthBase/AuthBase';
import { ProfileSetupPage } from '@components/ProfileSetup/ProfileSetupPage';
import { FiltersSetupPage } from '@components/ProfileSetup/FiltersSetupPage/FiltersSetupPage';
import { AppPageContextProvider } from '@components/AppPage/AppPageContext/AppPageContext';
import ManageUsersListPage from '@components/ManageUsersList/ManageUsersListPage';
import UserRegistrationPage from '@components/UserRegistrationPage/UserRegistrationPage';
import UnitCalendarPage from '@components/UnitCalendarPage/UnitCalendarPage';
import EditUserRegistrationPage from '@components/ManageUsersList/EditUserRegistrationPage/EditUserRegistrationPage';
import SchedulePage from '@components/SchedulePage/SchedulePage';
import { HomePage } from '@components/HomePage/HomePage';
import { FindATimePage } from '@components/FindATime/FindATimePage/FindATimePage';
import { ROUTE_CONSTANTS } from '@utilities/RouteConstants';

export const Routes = () => (
	<BrowserRouter>
		<AuthBase>
			<GlobalLoader />
			<AppPageContextProvider>
				<Switch>
					<Route path={ROUTE_CONSTANTS.LOGIN} component={Login} />
					<AppPage
						path={ROUTE_CONSTANTS.TERMS_OF_USE}
						exact={true}
						hideDropDown={true}
						hideRequestButton={true}
						component={TermsOfUsePage}
						showHomeLink={false}
						hasAlert={false}
					/>
					<AppPage
						path={ROUTE_CONSTANTS.SETUP_PROFILE}
						exact={true}
						component={ProfileSetupPage}
						hideDropDown={true}
						hideRequestButton={true}
						hasAlert={true}
					/>
					<AppPage
						path={ROUTE_CONSTANTS.SETUP_FILTERS}
						exact={true}
						component={FiltersSetupPage}
						hideDropDown={true}
						hideRequestButton={true}
						hasAlert={true}
					/>
					<AppPage
						path={ROUTE_CONSTANTS.ACCOUNT_SETTINGS}
						exact={true}
						component={AccountSettings}
						showHomeLink={true}
						hasAlert={true}
					/>
					<AppPage
						path={ROUTE_CONSTANTS.REQUEST_FORM}
						component={RequestFormPage}
						hideRequestButton={true}
						onlyAllowRoles={OfficeInteractiveRoles}
						forbiddenRedirectTo="/"
						hasAlert={true}
					/>
					<AppPage
						path={ROUTE_CONSTANTS.CASE_DETAILS}
						component={CaseDetailsPageWrapper}
						hasAlert={true}
					/>
					<AppPage
						path={ROUTE_CONSTANTS.QUEUE}
						component={QueuePage}
						hasAlert={true}
					/>
					<AppPage
						path={ROUTE_CONSTANTS.EDIT_USER_REGISTRATION}
						component={EditUserRegistrationPage}
						hideRequestButton={false}
						onlyAllowRoles={OfficeManagerRoles}
						hasAlert={true}
					/>
					<AppPage
						path={ROUTE_CONSTANTS.USER_REGISTRATION}
						component={UserRegistrationPage}
						hideRequestButton={true}
						onlyAllowRoles={OfficeManagerRoles}
						hasAlert={true}
					/>
					<AppPage
						path={ROUTE_CONSTANTS.MANAGE_USERS}
						component={ManageUsersListPage}
						hideRequestButton={false}
						onlyAllowRoles={OfficeManagerRoles}
						hasAlert={true}
					/>
					<AppPage
						path={ROUTE_CONSTANTS.UNIT_CALENDAR}
						component={UnitCalendarPage}
						onlyAllowRoles={HospitalInteractiveRoles}
						hasAlert={true}
					/>
					<AppPage
						path={ROUTE_CONSTANTS.FIND_A_TIME}
						component={FindATimePage}
						onlyAllowRoles={OfficeInteractiveRoles}
						hasAlert={true}
						hasBanner={false}
					/>
					<AppPage
						path={ROUTE_CONSTANTS.SCHEDULING}
						component={SchedulePage}
						onlyAllowRoles={OfficeRoles}
						hasAlert={true}
						hideRequestButton={true}
						hasBanner={false}
					/>
					<AppPage
						path={ROUTE_CONSTANTS.CASE_LIST}
						component={CaseTrackerList}
						hasAlert={true}
						subHeaderTitle="Case List"
					/>
					<AppPage path={ROUTE_CONSTANTS.HOME} component={HomePage} />
				</Switch>
			</AppPageContextProvider>
		</AuthBase>
	</BrowserRouter>
);
