import { FilterApiParams } from '@interfaces/FilterParams';
import { filtersToApiParams } from '@utilities/commonFunction';
import { DEFAULT_FILTERS } from '@components/Filters/FiltersContext/FiltersContext';

const getInitialFilters = (currentFilters: FilterApiParams | undefined, selectedIdList: string[] | undefined): FilterApiParams | undefined => {
	if (!selectedIdList) {
		return undefined; // Allow FiltersContext to use defaults instead which uses SessionStorage for initial page load
	}
	return {
		...(currentFilters || filtersToApiParams(DEFAULT_FILTERS)),
		procedureDate: currentFilters?.procedureDate || DEFAULT_FILTERS.procedureDate,
		filterApplied: false,
		surgeon: selectedIdList.join(',')
	};
};

export default getInitialFilters;
