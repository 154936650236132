import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import FormProviderProps from '@components/FormContextProvider/FormProviderProps';
import { FieldValues } from 'react-hook-form';

function FormContextProvider<T extends FieldValues = FieldValues>({ children, ...formOptions }: FormProviderProps<T>) {
	const formMethods = useForm(formOptions);

	return <FormProvider<T> {...formMethods}>
		{children}
	</FormProvider>;
}

export default FormContextProvider;
