import React from 'react';
import {
	Box,
	Flex,
	Text,
	TextLink,
} from '@ascension/web';
import { useDispatch, useSelector } from 'react-redux';
import { CaseQueueSelector } from '../../store/selectors/CaseQueueSelector';
import { QueueNavigate } from './QueueNavigate';
import { useHistory, useLocation } from 'react-router-dom';
import { navigateCaseQueue } from '../../store/actions/CaseQueueActionCreators';
import { makeStyles } from '@material-ui/core/styles';
import { DEFAULT_MAX_WIDTH, LARGE_MAX_WIDTH, X_LARGE_MAX_WIDTH } from '../../theme/themeConstants';

const useStyles = makeStyles(theme => ({
	navContainer: {
		'cursor': 'pointer',
		'& *::selection': {
			backgroundColor: 'unset',
		},
		'&:hover': {
			borderColor: '#1E69D2',
		},

	},
	navContainerNext: {
		'marginLeft': 'auto',
		'& .nav-descriptor': {
			textAlign: 'left',
		},
		'& .previous': {
			display: 'none',
		},
		'& .nav-icon': {
			marginRight: '-12px',
			marginLeft: '-5px',
		},
	},
	navContainerPrevious: {
		'& .nav-descriptor': {
			textAlign: 'right',
		},
		'& .next': {
			display: 'none',
		},
		'& .nav-icon': {
			marginRight: '-5px',
			marginLeft: '-12px',
		},
	},
	fullWidth: {
		width: '100%',
		[theme.breakpoints.up('lg')]: {
			maxWidth: LARGE_MAX_WIDTH,
		},
		[theme.breakpoints.up('xl')]: {
			maxWidth: X_LARGE_MAX_WIDTH,
		},
		maxWidth: DEFAULT_MAX_WIDTH,
	},
}));

export const QueueController = () => {
	const { navContainer, navContainerNext, navContainerPrevious, fullWidth } = useStyles();
	const history = useHistory();
	const { search } = useLocation();
	const {
		currentReviewAtCount,
		totalCount,
		previousCaseDetails,
		previousCaseIndex,
		nextCaseDetails,
		nextCaseIndex,
		lastCaseIndex,
	} = useSelector(CaseQueueSelector);
	const dispatch = useDispatch();

	const navigateToCase = (navigateToIndex?: number) => {
		dispatch(navigateCaseQueue(navigateToIndex as number));
		history.push({
			pathname: '/queue/' + navigateToIndex,
			search,
		});
	};

	return (
		<Flex
			bottom={0}
			left={0}
			position="fixed"
			backgroundColor="white"
			width={1}
			pl="40.5px"
			pr="40.5px"
			pt="16.5px"
			pb="17px"
			style={{ boxShadow: '0 2px 4px 0 #00000080' }}
			zIndex={110}
			justifyContent="center"
		>
			<Box
				className={fullWidth}
			>
				<Flex
					justifyContent="space-between"
				>
					<Text fontSize={14}>
						Reviewing <Text fontWeight="bold">{currentReviewAtCount} of {totalCount} Filtered Cases</Text>
					</Text>
					<TextLink onClick={() => navigateToCase(lastCaseIndex)}>End Queue</TextLink>
				</Flex>
				<Flex
					justifyContent="space-between"
				>
					{previousCaseDetails &&
					<QueueNavigate
						caseInfo={previousCaseDetails}
						className={[navContainer, navContainerPrevious].join(' ')}
						onClick={() => navigateToCase(previousCaseIndex)}
					/>}
					{nextCaseDetails &&
					<QueueNavigate
						caseInfo={nextCaseDetails}
						className={[navContainer, navContainerNext].join(' ')}
						onClick={() => navigateToCase(nextCaseIndex)}
					/>}
				</Flex>
			</Box>
		</Flex>
	);
};
