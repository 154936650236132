import React, { Ref } from 'react';
import {
	Box,
} from '@ascension/web';
import { CardSectionProps } from './CardSectionProps';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
	defaultStyles: {
		'& .sectionTitle': {
			padding: theme.spacing(4, 4, 3, 4),
		},
		'& .sectionBody': {
			paddingLeft: theme.spacing(4),
			paddingRight: theme.spacing(4),
		},
	},
}));

const CardSection = ({ id, sectionTitle, children, className, ...rest }: CardSectionProps, ref: Ref<HTMLDivElement>) => {
	const { defaultStyles } = useStyles();
	return (
		<Box id={id} mb={[20, 20]} display="flex" flexDirection="column" className={classNames({ card: true, [className || '']: !!className, [defaultStyles]: !className })} ref={ref} {...rest}>
			{sectionTitle &&
			<Box mx={0} mt={0} className="sectionTitle">
				{sectionTitle}
			</Box>
			}
			<Box pb={35} className="sectionBody">
				{children}
			</Box>
		</Box>
	);
};

export default React.forwardRef(CardSection);
