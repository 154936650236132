import { apiAction } from '@store/middlewares/api/ApiActionCreators';
import { ENDPOINT_PROCEDURES } from '@utilities/apiConstants';
import { GET_PROCEDURES } from '@store/actions/types';
import { ProcedureRequest } from '@data/procedures/types/ProcedureRequest';
import ApiService from '@utilities/apiEndpoints/ApiService';

export const getProcedures = (apiActionRequest?: Partial<ProcedureRequest>) => {
	return apiAction({
		url: ENDPOINT_PROCEDURES,
		apiService: ApiService.SSM,
		includeAccessToken: true,
		label: GET_PROCEDURES,
		...apiActionRequest,
	});
};
