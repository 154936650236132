import React, { PropsWithChildren } from 'react';
import { StandardInputWrapperProps } from '@components/StatefulInputs/Base/StandardInputWrapper/StandardInputWrapper.types';
import { useFormContext } from 'react-hook-form';
import { createStyles, FormControl, makeStyles } from '@material-ui/core';
import { StatefulInputLabel } from '@components/StatefulInputs/Base/StatefulInputLabel/StatefulInputLabel';
import { StatefulError } from '@components/StatefulInputs/Base/StatefulError/StatefulError';
import classNames from 'classnames';
import {
	BaseEventTrackingWrapper
} from '@components/BaseEventTrackingWrapper/BaseEventTrackingWrapper';
import { track } from '@amplitude/analytics-browser';

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			'& > .MuiInputLabel-outlined': {
				pointerEvents: 'unset',
			},
			'& > .MuiInputLabel-outlined, & > .MuiInputLabel-outlined.MuiInputLabel-shrink':
				{
					transform: 'unset',
					position: 'relative',
					marginBottom: theme.spacing(1),
				},
			'& .MuiOutlinedInput-root': {
				'& fieldset': {
					borderColor: theme.palette.grey[500],
				},
				'&:hover fieldset': {
					borderColor: theme.palette.primary.main,
					borderWidth: theme.spacing(0.25),
				},
				'&.Mui-error:hover fieldset': {
					border: `2px solid ${theme.palette.error.main}`,
				},
				'&.Mui-disabled fieldset': {
					border: `1px solid ${theme.palette.grey[500]}`,
				},
			},
			'&  .MuiOutlinedInput-notchedOutline': {
				top: 0,
			},
			'&  .MuiOutlinedInput-notchedOutline>legend': {
				display: 'none',
			},
		},
	}),
);

export function StandardInputWrapper<FormType>({
	name,
	label,
	styleAsRequired,
	extraLabel,
	labelProps,
	children,
	shouldShowError,
	wrapperClassName,
	dataField,
	eventsToTrack,
	analyticsInputName,
}: PropsWithChildren<
	StandardInputWrapperProps<FormType>
>): React.ReactElement {
	const classes = useStyles();

	const {
		formState: { errors },
	} = useFormContext();

	const displayError = !!errors[name] && (shouldShowError !== false);

	const handleTrackingEvent = React.useCallback((event: React.SyntheticEvent<HTMLElement>) => {
		const eventType = event.type;
		const eventName = `${analyticsInputName}-${eventType}`;

		track(eventName, { fieldName: dataField, eventType: eventType });
	}, [analyticsInputName, dataField]);

	return (
		<FormControl
			data-field={`input-root-${name}`}
			required={styleAsRequired}
			error={displayError}
			variant="outlined"
			className={classNames(classes.root, wrapperClassName)}
		>
			<StatefulInputLabel
				{...labelProps}
				label={label}
				extraLabel={extraLabel}
			/>
			<BaseEventTrackingWrapper
				handleEvent={handleTrackingEvent}
				dataField={dataField}
				eventsToTrack={eventsToTrack}
			>
				{children}
			</BaseEventTrackingWrapper>
			{displayError && <StatefulError name={name} />}
		</FormControl>
	);
}
