import React from 'react';
import { MenuItem } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useProcedureNameContext } from '@components/RequestForm/ProcedureName/ProcedureNameContext';
import BoxFancyScroll from '@components/BoxFancyScroll/BoxFancyScroll';

const useStyles = makeStyles((theme) => createStyles({
	options: {
		flexDirection: 'column',
		flex: '1 1 auto',
		overflowY: 'auto',
		gap: theme.spacing(1),
	},
	optionItem: {
		lineHeight: '24px',
		fontSize: '18px',
		fontWeight: 400,
		padding: theme.spacing(1, 0, 1, 2),
	}
}));

interface ProcedureNameOptionsProps {
	onClick: (value: string) => void;
	selectedIndex: number;
}

export const ProcedureNameOptions: React.FC<ProcedureNameOptionsProps> = ({ onClick, selectedIndex }) => {
	const classes = useStyles();
	const { procedures } = useProcedureNameContext();
	const selectedRef = React.useRef<HTMLLIElement>(null);

	React.useEffect(() => {
		selectedRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
	}, [selectedIndex]);

	return (
		<BoxFancyScroll className={classes.options}>
			{procedures.map(({ procedureName }, index) => (
				<MenuItem
					className={classes.optionItem}
					key={procedureName}
					onClick={() => onClick(procedureName)}
					selected={index === selectedIndex}
					ref={index === selectedIndex ? selectedRef : undefined}
				>
					{procedureName}
				</MenuItem>
			))}
		</BoxFancyScroll>
	);
};
