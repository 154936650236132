import IconButtonStyled from '@components/IconButtonStyled/IconButtonStyled';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Box } from '@material-ui/core';
import React from 'react';
import BoxScrollHorizontalButtonProps
	from '@components/BoxScrollHorizontal/BoxScrollHorizontalButton/BoxScrollHorizontalButtonProps';
import RightLeftDirectionEnum from '@interfaces/RightLeftDirectionEnum';
import {
	useBoxScrollHorizontalContextDirection,
} from '@components/BoxScrollHorizontal/BoxScrollHorizontal';

const BoxScrollHorizontalButton: React.FC<BoxScrollHorizontalButtonProps> = ({ direction }) => {
	const { hasHiddenContent, doScrollDirection } = useBoxScrollHorizontalContextDirection(direction);

	return (
		<Box>
			<IconButtonStyled
				disabled={!hasHiddenContent}
				onClick={doScrollDirection}
				onKeyPress={doScrollDirection}
				data-field={`boxScrollHorizontal-iconButton-${direction}`}
			>
				{direction === RightLeftDirectionEnum.RIGHT ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
			</IconButtonStyled>
		</Box>
	);
};

export default BoxScrollHorizontalButton;
