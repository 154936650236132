import { eachMinuteOfInterval, add } from 'date-fns';
import getMidnightLocal from '@calendar/utilities/getMidnightLocal/getMidnightLocal';

/***
 * getTimeScale - generate array of time using dateFns.eachMinuteOfInterval
 * @param timeZone {string} - timezone to render ticks in
 * @param interval {number: 15} -  number of minutes between ticks
 * @param dateStart {Date: new Date()} - start of ticks - default to midnight START of today
 * @param dateEnd {Date: new Date()} - end of ticks - defaults to midnight END of today
 */
const getTimeScale = (
	timeZone: string,
	interval = 15,
	dateStart?: Date,
	dateEnd?: Date,
) => {
	const timeStart = dateStart || getMidnightLocal(timeZone);
	const timeEnd = dateEnd || add(getMidnightLocal(timeZone, timeStart), { days: 1 });

	return eachMinuteOfInterval({
		start: timeStart,
		end: timeEnd,
	}, { step: interval });
};

export default getTimeScale;
