import { Action, Middleware, Dispatch } from 'redux';
import { API } from '@store/actions/types';
import { ApiAction, CaseTrackerThunkDispatch } from '@interfaces/ApiAction/ApiAction';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { accessDenied, apiEnd, apiStart } from '@store/middlewares/api/ApiActionCreators';
import { CacheType } from '@interfaces/CacheType';
import { ApiResponseType } from '@interfaces/ApiAction/ApiActionPayload';
import { RootState } from '@interfaces/rootState';
import { saveDataToCache } from '@store/actions/ApiCacheActionCreators';
import { setErrorStatus } from '@store/actions/ErrorStatusActionCreators';
import { CancelPolicy } from '@interfaces/CancelPolicy';
import { addApiCanceler } from '@store/actions/ApiStatusActionCreators';
import { apiStatusCancelSelector } from '@store/selectors/ApiStatusSelector';
import { getActionForErrorCode } from '@store/middlewares/api/apiMiddlewareUtilities';
import { ErrorStatusState } from '@interfaces/ErrorStatus/ErrorStatusState';
import { defaultShouldDisableErrorHandler } from '@utilities/apiErrorExceptions';
import getEndpointConfigByService from '@utilities/apiEndpoints/getEndpointConfigByService';
import ApiService from '@utilities/apiEndpoints/ApiService';
import { getRandomString } from '@utilities/commonFunction';

/* eslint-disable @typescript-eslint/unbound-method */
const apiMiddleware: Middleware<Dispatch, RootState, CaseTrackerThunkDispatch> = ({ dispatch, getState }) => (next: Dispatch) => (action: ApiAction) => {
	if (action.type !== API) {
		next(action);
		return;
	}
	const {
		baseURL,
		url,
		method,
		data: requestData,
		includeAccessToken,
		label,
		headers: requestHeaders = {},
		onSuccess,
		onFailure,
		cachePolicy,
		responseType,
		asDataOrParams,
		cancelPolicy,
		apiService,
		shouldDisableErrorHandler = defaultShouldDisableErrorHandler,
		shouldDisableLoadIndicator,
		additionalParams,
	} = action.payload;

	const pendingActions: Promise<unknown>[] = [];

	const dataOrParams = asDataOrParams || (['GET', 'DELETE', 'get', 'delete'].includes(method as string) ? 'params' : 'data');

	const { url: serviceUrl, key: apiKey } = getEndpointConfigByService(apiService || ApiService.SSM);

	const isFormData = requestData instanceof FormData;

	const envConfig: AxiosRequestConfig<unknown> & Required<Pick<AxiosRequestConfig, 'url' | 'headers'>> = {
		url,
		baseURL: baseURL || serviceUrl,
		method,
		responseType,
		[dataOrParams]: requestData,
		headers: {
			'apikey': apiKey,
			...requestHeaders,
			'Content-Type': isFormData ? 'multipart/form-data' : 'application/json',
		}
	};

	if (additionalParams) {
		envConfig.params = {
			...envConfig.params,
			...additionalParams
		} as Record<string, string>;
	}

	if (cancelPolicy === CancelPolicy.NO_DUPLICATE_PATH) {
		const prevCancel = apiStatusCancelSelector(url)(getState());

		if (prevCancel) {
			const { canceler: prevCanceler, cancelPromise: prevCancelPromise } = prevCancel;
			prevCanceler('Cancelled duplicate request');
			pendingActions.push(prevCancelPromise);
		}

		const cancelToken = axios.CancelToken;
		const source = cancelToken.source();
		const canceler = source.cancel;
		const cancelPromise = source.token.promise;
		dispatch(addApiCanceler(url, canceler, cancelPromise));
		envConfig.cancelToken = source.token;
	}

	const handleError = (err: AxiosError) => {
		if (!onFailure) { return; }
		const failureAction = onFailure(err) as Action;
		if (failureAction) {
			//dispatch ThunkAction or regular redux Action
			dispatch(failureAction);
		}
	};

	if (includeAccessToken) {
		const { auth: { accessToken = null } } = getState();

		if (!accessToken && !window.config.DISABLE_AUTH) {
			handleError({
				message: 'User not logged in. Could not make request access token.',
				config: envConfig,
				isAxiosError: false,
				name: label,
			} as AxiosError);
			return;
		}

		envConfig.headers = {
			...envConfig.headers,
			Authorization: `Bearer ${accessToken}`,
		};
	}

	const fromAxios: () => Promise<ApiResponseType> = () => {
		if (isFormData) {
			const { url, data, ...restConfig } = envConfig;
			switch (envConfig.method) {
			case 'POST':
				return axios.postForm(url, data, restConfig);
			case 'PUT':
				return axios.putForm(url, data, restConfig);
			}
		}
		return axios.request(envConfig);
	};

	let dataRequest: () => Promise<ApiResponseType> = fromAxios;
	let shouldUpdateCache = false;
	switch (cachePolicy) {
	case CacheType.RETRIEVE_OR_ADD_CACHE: {
		const { apiCache: { cache } } = getState();
		const cachedData = cache[url];

		shouldUpdateCache = true;

		if (!cachedData) {
			dispatch(saveDataToCache(url, []));

			break;
		}

		if (cachedData.length) {
			shouldUpdateCache = false;
			dataRequest = () => Promise.resolve({
				data: cachedData,
			} as ApiResponseType);
		}
		break;
	}
	case CacheType.UPDATE_CACHE: {
		shouldUpdateCache = true;
		dataRequest = fromAxios;
		break;
	} }

	const id = getRandomString(10);

	dispatch(apiStart(id, label, url, shouldDisableLoadIndicator));

	Promise.all(pendingActions)
		.then(dataRequest)
		.then((response: ApiResponseType) => {
			if (shouldUpdateCache) {
				dispatch(saveDataToCache(url, response.data as []));
			}
			if (!onSuccess) { return; }
			const successAction: Action = onSuccess(response) as Action; // Could also be ThunkAction but throws unnecessary error
			if (!successAction) { return; }
			dispatch(successAction);
		})
		.catch((error: AxiosError<ErrorStatusState>) => {
			handleError(error);

			if (shouldDisableErrorHandler === true || shouldDisableErrorHandler && shouldDisableErrorHandler(error)) {
				return;
			}

			if (error.response) {
				switch (error.response.status) {
				case 401:
					dispatch(accessDenied(window.location.pathname));
					dispatch(getActionForErrorCode(error.response));
					break;
				default:
					dispatch(setErrorStatus(error.response.data));
				}
			}
			// display error modal for errors that do not have status code
			if (error.message === 'Network Error') {
				const customError: ErrorStatusState = {
					status: 504,
					errorCode: '504NET',
					message: error.message,
					timestamp: new Date().getTime()
				};
				dispatch(setErrorStatus(customError));
			}
		})
		.finally(() => {
			dispatch(apiEnd(id));
		});
};

export default apiMiddleware;
