import React from 'react';
import {
	useDocumentDetailsContext,
} from '@components/CaseDetails/DocumentInformationDisplay/DocumentDetails/DocumentDetails';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DocumentActionButton
	from '@components/CaseDetails/DocumentInformationDisplay/DocumentDetails/DocumentActions/DocumentActionButton';
import {
	useCaseDetailsPageContext,
} from '@components/CaseDetails/CaseDetailsContext/CaseDetailsPageContext/CaseDetailsPageContext';
import { useInformationModal } from '@components/InformationModal/InformationModal';

const ViewDocument: React.FC = () => {
	const { setSelectedAttachment } = useCaseDetailsPageContext();
	const { closeModal } = useInformationModal();
	const attachment = useDocumentDetailsContext();
	const handleClick = React.useCallback(() => {
		setSelectedAttachment(attachment);
		closeModal();
	}, [attachment, closeModal, setSelectedAttachment]);

	return <DocumentActionButton fieldName="docList-view" onClick={handleClick}><VisibilityIcon />View</DocumentActionButton>;
};

export default ViewDocument;
