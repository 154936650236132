import React from 'react';
import { Box, Flex } from '@ascension/web';
import { HospitalWithUnits } from '../../../../interfaces/Hospital';
import UnitsHospitalGroup from './UnitCheckboxes/UnitsHospitalGroup';
import { UnitContextProvider, useUnitContext } from './UnitContext/UnitContext';
import { FormAssistiveText } from '../../../FormComponents/FormAssistiveText/FormAssistiveText';

const UnitList = () => {
	const { hospitalList } = useUnitContext();

	return <>
		{hospitalList.map(({ id }: HospitalWithUnits) => (
			<UnitsHospitalGroup key={id} id={id} />
		))}
	</>;
};

export default function Units ()  {
	return (
		<Box display="flex" flexDirection="column"  py={8}>
			<Box display="flex" flexDirection="column" justifyContent="space-between" width={1} alignItems="start" py={2} data-field="fullName" mb={4}>
				<Box fontSize={18} letterSpacing="1.4px" color="#222222" fontWeight="bold" pb={1}>
					Units
				</Box>
				<Box height={24}>
					<FormAssistiveText field="unit">Select at least one unit.</FormAssistiveText>
				</Box>
			</Box>
			<Flex flexDirection="column" width={1} pb={4}>
				<UnitContextProvider>
					<UnitList/>
				</UnitContextProvider>
			</Flex>
		</Box>
	);
}
