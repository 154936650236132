import React from 'react';
import { useDispatch } from 'react-redux';
import { ApiActionPayload } from '@interfaces/ApiAction/ApiActionPayload';
import { SurgeryRequestGet } from '@interfaces/SurgeryRequest/SurgeryRequestGet';
import { getCaseDetails } from '@store/actions/CaseDetailsActionCreators';

const useGetCaseDetails = () => {
	const dispatch = useDispatch();

	return React.useCallback((id: string, apiRequestOverrides?: Partial<ApiActionPayload<SurgeryRequestGet>>) => {
		dispatch(getCaseDetails(id, apiRequestOverrides));
	}, [dispatch]);
};

export default useGetCaseDetails;
