import { makeStyles } from '@material-ui/core/styles';
import { COLUMN_OFFSET, TICK_OFFSET } from '@calendar/utilities/CalendarConstants';
import React from 'react';
import { useCalendarContext } from '@calendar/state';
import { Box } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles({
	tick: {
		gridColumn: `${COLUMN_OFFSET} / -1`,
		gridRowEnd: 'span 1',
		borderTop: '1px solid #BDBDBD',
	},
	tickThirty: {
		borderTopWidth: '1px',
	},
	tickHour: {
		borderTopWidth: '2px',
	},
	tickFifteen: {
		borderTopWidth: '1px',
		borderTopColor: '#BDBDBD6F',
	},
	column: {
		gridColumnEnd: `span 1`,
		gridRow: `1 / -1`,
		borderLeft: '2px solid #BDBDBD',
		zIndex: 1050,
		pointerEvents: 'none',
	},
});

const GridLines: React.FC = () => {
	const { memoizedTicks: ticks, columns } = useCalendarContext();
	const classes = useStyles();

	return <>
		{ticks.map((t, index) => (
			<Box
				id="horizontalLine"
				key={t.minutesSinceMidnight}
				className={classNames(
					classes.tick,
					{
						[classes.tickHour]: t.minute === 0,
						[classes.tickThirty]: t.minute === 30,
						[classes.tickFifteen]: t.minute === 15 || t.minute === 45,
					}
				)}
				style={{
					gridRowStart: index + TICK_OFFSET,
				}}
			/>
		))}
		{columns.map((c, index) => (
			<Box
				id="verticalLine"
				key={c.id}
				className={classes.column}
				style={{
					gridColumnStart: index + COLUMN_OFFSET,
				}}
			/>
		))}
		<Box
			className={classes.column}
			style={{
				gridColumnStart: columns.length + COLUMN_OFFSET,
			}}
		/>
	</>;

};

export default GridLines;
