import React from 'react';
import { CustomPopoverBody } from '@components/CustomPopover/CustomPopoverBody/CustomPopoverBody';
import { ProcedureNameOptions } from '@components/RequestForm/inputs/ProcedureName/ProcedureNameOptions/ProcedureNameOptions';
import { CustomPopoverFooter } from '@components/CustomPopover/CustomPopoverFooter/CustomPopoverFooter';
import { CustomProcedureOption } from '@components/RequestForm/inputs/ProcedureName/CustomProcedureOption/CustomProcedureOption';
import { CustomPopover } from '@components/CustomPopover/CustomPopover';
import { ProcedureNamePopoverProps } from '@components/RequestForm/inputs/ProcedureName/ProcedureNamePopover/ProcedureNamePopoverProps';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CustomPopoverHeader } from '@components/CustomPopover/CustomPopoverHeader/CustomPopoverHeader';
import { ProcedureFilter } from '@components/RequestForm/inputs/ProcedureName/ProcedureFilter/ProcedureFilter';
import { useAnchorContext } from '@components/AnchorProvider/AnchorProvider';
import {
	CUSTOM_PROCEDURE_OPTION,
	useProcedureNameContext
} from '@components/RequestForm/ProcedureName/ProcedureNameContext';
import { useHandleKeyDown } from '@utilities/hooks/useHandleKeyDown/useHandleKeyDown';

const useStyles = makeStyles((theme) =>
	createStyles({
		paper: {
			'display': 'flex',
			'flexDirection': 'column',
			'position': 'relative',
			'maxHeight': '400px',
			'& .MuiMenuItem-root': {
				textWrap: 'wrap',
			},
		},
		procedureOptions: {
			margin: theme.spacing(2, 2, 1, 0),
		},
	}),
);

export const ProcedureNamePopover: React.FC<ProcedureNamePopoverProps> = ({ onOptionClick }) => {
	const { handleClearAnchorEl, anchorEl } = useAnchorContext();
	const { setProcedureFilter, triggerProcedureNameValidation, procedures, selectedProcedure, allowCustom } = useProcedureNameContext();

	const defaultSelectedIndex = selectedProcedure ? procedures.findIndex(procedure => procedure.procedureName === selectedProcedure.procedureName) : 0;
	const [ selectedIndex, setSelectedIndex ] = React.useState(defaultSelectedIndex);

	const classes = useStyles();

	const handlePopoverClose = React.useCallback(() => {
		handleClearAnchorEl();
		triggerProcedureNameValidation();
		setProcedureFilter('');
	}, [handleClearAnchorEl, setProcedureFilter, triggerProcedureNameValidation]);

	useHandleKeyDown({
		ArrowUp: () => setSelectedIndex(prev => prev > 0 ? prev - 1 : 0),
		ArrowDown: () => setSelectedIndex(prev => prev < procedures.length ? prev + 1 : procedures.length),
		Enter: (e) => {
			e.preventDefault();
			onOptionClick(selectedIndex < procedures.length ? procedures[selectedIndex].procedureName : CUSTOM_PROCEDURE_OPTION);
			selectedIndex < procedures.length && triggerProcedureNameValidation();
			handleClearAnchorEl();
		},
		Tab: handlePopoverClose,
	}, !anchorEl);

	return (
		<CustomPopover
			paperProps={{
				className: classes.paper,
			}}
			onClose={handlePopoverClose}
		>
			<CustomPopoverHeader>
				<ProcedureFilter onAdornmentClick={handlePopoverClose} />
			</CustomPopoverHeader>
			<CustomPopoverBody className={classes.procedureOptions}>
				<ProcedureNameOptions onClick={onOptionClick} selectedIndex={selectedIndex} />
			</CustomPopoverBody>
			{allowCustom &&
				<CustomPopoverFooter>
					<CustomProcedureOption onClick={onOptionClick} selected={selectedIndex === procedures.length} />
				</CustomPopoverFooter>
			}
		</CustomPopover>
	);
};
