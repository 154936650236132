import React from 'react';
import { Path, PathValue, useFormContext } from 'react-hook-form';
import { FieldValues } from 'react-hook-form';
import getFormErrorForField from '@utilities/getFormErrorForField';

function useFieldWithoutRegister<FormType extends FieldValues>(fieldName: Path<FormType>) {
	const { watch, setValue, trigger, formState: { errors }, setError } = useFormContext<FormType>();

	const { [fieldName]: currentValue } = watch();

	const updateValue = React.useCallback((value: PathValue<FormType, Path<FormType>>) => {
		setValue(fieldName, value);
	}, [fieldName, setValue]);

	const triggerSelfValidation = React.useCallback(() => {
		void trigger(fieldName);
	}, [fieldName, trigger]);

	const setFieldError = React.useCallback((errorType: string, errorMsg: string) => {
		setError(fieldName, { type: errorType, message: errorMsg });
	}, [fieldName, setError]);

	const error = getFormErrorForField(fieldName)(errors);

	return {
		currentValue,
		updateValue,
		triggerSelfValidation,
		error,
		setFieldError,
	};
}

export default useFieldWithoutRegister;
