import React from 'react';
import { makeStyles, MenuItem } from '@material-ui/core';
import DropdownInput from '@components/DropdownInput/DropdownInput';
import { LocationSelectionProps } from '@components/LocationSelection/LocationSelectionProps';

const useStyles = makeStyles(theme => ({
	root: {
		'width': '295px',
		[theme.breakpoints.down('md')]: {
			flex: 'auto',
		},
		'& .MuiInputLabel-root': {
			textTransform: 'unset',
			fontSize: '1rem',
			color: theme.palette.grey[700]
		},
		'& .MuiOutlinedInput-root': {
			height: '48px',
		},
		'& .MuiAscensionDropdown-formControlDefault': {
			marginTop: theme.spacing(.5),
		},
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.palette.grey[700]
		},
		'&& .MuiOutlinedInput-notchedOutline > legend': {
			width: '0px',
			display: 'block',
		}
	},
}));
const LocationSelection: React.FC<LocationSelectionProps> = ({ selectedFacility, setSelectedFacility, facilityList }) => {
	const classes = useStyles();

	return (
		<DropdownInput
			value={selectedFacility}
			onChange={setSelectedFacility}
			fieldName="facility"
			label="Facility"
			className={classes.root}
		>
			{facilityList.map(option =>
				<MenuItem key={option.id} value={option.id}>
					{option.name}
				</MenuItem>
			)}
		</DropdownInput>
	);
};

export default LocationSelection;
