import { Box, Text } from '@ascension/web';
import ListHeaderComponent from '../ListHeader/ListHeaderComponent';
import PaginationComponent from '../Pagination/PaginationComponent';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TemplateListSectionProps } from './TemplateListSectionProps';
import { TemplateInfoApi } from '../../interfaces/Procedure/TemplateInfoApi';
import { getFormattedName } from '../../utilities/commonFunction';
import { useFilterState } from '../Filters/FiltersContext/FiltersContext';
import { NO_SURGEON_DROPDOWN } from '../../utilities/typeConverters/constants';

const useStyles = makeStyles({
	paginationHeader: {
		fontSize: '12px',
		paddingBottom: '8px',
	},
	listHeader: {
		'display': 'inline-block',
		'color': '#222222',
		'fontSize': '12px',
		'fontWeight': 'bold',
		'letterSpacing': '1.6px',
		'lineHeight': '15px',
		'textTransform': 'uppercase',
		'marginBottom': '8px',
		'maxWidth': '100%',
		'whiteSpace': 'nowrap',
		'overflow': 'hidden',
		'textOverflow': 'ellipsis',
		'& ~ *': {
			marginLeft: '31px',
		},
	},
});

interface TemplateGroup {
	name: string;
	templates: TemplateInfoApi[];
}
interface TemplatesIndexed {
	[name: string]: TemplateInfoApi[];
}

const useGroupedList = (templateList: TemplateInfoApi[]) => {
	const { sortModel } = useFilterState();

	return React.useMemo(() => {
		const indexed = templateList.reduce((groups: TemplatesIndexed, item: TemplateInfoApi) => {
			let key = '';

			switch (sortModel) {
			case 'lastName:asc,firstName:asc,name:asc':
			case 'lastName:desc,firstName:asc,name:asc':
				key = getFormattedName(item.surgeon, NO_SURGEON_DROPDOWN.label);
				break;
			case 'procedureName:asc,name:asc':
			case 'procedureName:desc,name:asc':
				key = item.procedureName;
				break;
			case 'dateCreated:asc,name:asc':
			case 'dateCreated:desc,name:asc':
				// Date created logic (!not implemented yet on BE!)
				key = item.dateCreated || '';
				break;
			}

			// Sort without grouping will just be empty key
			if (!groups[key]) {
				groups[key] = [];
			}
			groups[key].push(item);
			return groups;
		}, {});
		return Object.entries(indexed).map(([name, templates]) => ({
			name,
			templates,
		}));
	}, [templateList, sortModel]);
};

const TemplateListSection = ({ templateDisplay: TemplateDisplay, templateList, refList }: TemplateListSectionProps) => {
	const { paginationHeader, listHeader } = useStyles();

	const groupedList: TemplateGroup[] = useGroupedList(templateList);

	const updateRefList = React.useCallback((id: string) => {
		return (domNode: HTMLDivElement) => {
			if (!refList || !refList.current) { return; }
			if (!domNode) {
				delete refList.current[id];
				return;
			}
			refList.current[id] = domNode;
		};
	}, [refList]);

	return (
		<>
			<Box data-field="TemplateListHeader" pt={6}>
				<ListHeaderComponent className={paginationHeader} />
			</Box>
			{groupedList.map(({ name, templates }) => (
				<Box key={name} data-field="TemplateGroup">
					{name && <Text className={listHeader}> {name} </Text>}
					{templates.map((item) => (
						<TemplateDisplay
							info={item}
							ref={updateRefList(item.id)}
							key={'Template' + item.id}
						/>
					))}
				</Box>
			))}
			<Box data-field="TemplatePagination">
				<PaginationComponent displayPerPage={false} fontSize="12px" />
			</Box>
		</>
	);
};

export default TemplateListSection;
