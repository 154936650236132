import React from 'react';
import { alpha, createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, InputLabel, TextField } from '@material-ui/core';
import { PAGES_PREFIX } from '@components/PdfViewerPage/IndexingSection/utilities/getFormStateFromIndexes';
import ErrorMessageMui from '@components/ErrorMessageMui/ErrorMessageMui';
import { useFieldFormContext } from '@utilities/hooks/useFieldFormContext/useFieldFormContext';
import { pagesIndexValidation } from '@utilities/Validation/validationSchema';

const useStyles = makeStyles(theme => createStyles({
	textField: {
		'margin': theme.spacing(1, 0, 0),
		'& .MuiOutlinedInput-root': {
			height: '48px',
			width: '63px'
		},
		'& .MuiFormHelperText-root': {
			fontSize: '.65rem',
			letterSpacing: '0.4px',
			fontWeight: theme.typography.fontWeightMedium,
			whiteSpace: 'nowrap',
			color: alpha(theme.palette.common.black, .6),
		},
		'& .MuiFormHelperText-contained': {
			margin: theme.spacing(.35, 0)
		},
		'& .MuiInputBase-root': {
			background: theme.palette.common.white
		}
	},
	labelText: {
		color: alpha(theme.palette.common.black, .6),
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: '1rem',
		lineHeight: 1.5,
		display: 'flex',
		alignItems: 'end'
	},
}));

export interface PagesTextProp {
	shouldShowHelperText: boolean;
	id: string;
}

const PagesIndexTextInput: React.FC<PagesTextProp> = ({ shouldShowHelperText, id }) => {
	const pagesInputRef = React.useRef<HTMLInputElement>(null);
	const { currentValue = '', setCurrentValue, error, triggerSelfValidation, setErrorMessage } =
		useFieldFormContext(`${PAGES_PREFIX}${id}`, {
			validationRules: pagesIndexValidation,
		});

	const classes = useStyles();
	const handleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>)=> {
		const newVal = event.target.value.replace(/[^0-9,\s-]/, '');
		setCurrentValue(newVal);
		void triggerSelfValidation();
	}, [setCurrentValue, triggerSelfValidation]);

	const handlePaste = React.useCallback((event: React.ClipboardEvent<HTMLDivElement>) => {
		event.preventDefault();
		setErrorMessage('paste', 'Invalid');
	}, [setErrorMessage]);

	return (
		<Box>
			<InputLabel
				required={true}
				className={classes.labelText}
				htmlFor={`pagesIndexInput-${id}`}
			>
				Pages
			</InputLabel>
			<TextField
				id={`pagesIndexInput-${id}`}
				ref={pagesInputRef}
				helperText={shouldShowHelperText && !error ? 'eg. 1-5, 8, 9' : ''}
				value={currentValue}
				variant="outlined"
				onChange={handleChange}
				onBlur={triggerSelfValidation}
				onPaste={handlePaste}
				required={true}
				className={classes.textField}
				error={!!error}
			/>
			<ErrorMessageMui errorMessage={error} />
		</Box>
	);
};

export default PagesIndexTextInput;
