import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import GoldButton from '@components/GoldButton/GoldButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import BlueOutlineButton from '@components/BlueOutlineButton/BlueOutlineButton';
import { ReportProblemOutlined } from '@material-ui/icons';
import { useManageHoldEventContext } from '@components/RequestForm/ManageHoldEventContext/ManageHoldEventContext';
import { WarningModalType } from '@components/RequestForm/ManageHoldEventContext/ManageHoldEventContextType';
import BlockTimeChangeModalBody from '@components/RequestForm/WarningModal/BlockTimeChangeModalBody';
import LocationChangeModalBody from '@components/RequestForm/WarningModal/LocationChangeModalBody';

const useStyles = makeStyles(theme => createStyles({
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	paper: {
		padding: theme.spacing(4),
	},
	content: {
		letterSpacing: '.5px',
		lineHeight: '2rem',
	},
	button: {
		'height': '48px',
		'&&': {
			marginLeft: theme.spacing(3)
		}
	},
	warningIcon: {
		color: theme.palette.warning.main,
		marginRight: theme.spacing(2),
		verticalAlign: 'middle',
		height: '30px',
		width: '30px',
	},
}));

const WarningModal: React.FC = () => {
	const classes = useStyles();
	const { handleCancelValueChange, warningType, isModalOpen, handleClearDateTime } = useManageHoldEventContext();

	return (
		<Dialog
			open={isModalOpen}
			onClose={handleCancelValueChange}
			PaperProps={{
				classes: {
					root: classes.paper
				},
			}}
			data-field={`requestForm-${warningType}Warning-modal`}
		>
			<DialogTitle disableTypography>
				<Typography component="h2" variant="h5">
					<ReportProblemOutlined className={classes.warningIcon}/>
					Request a new date & time
				</Typography>
				<IconButton
					aria-label="close"
					className={classes.closeButton}
					onClick={handleCancelValueChange}
					data-field={`requestForm-${warningType}Warning-closeButton`}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent className={classes.content}>
				{ warningType === WarningModalType.BLOCK_TIME ?
					<BlockTimeChangeModalBody/> : <LocationChangeModalBody/>
				}
			</DialogContent>
			<DialogActions>
				<BlueOutlineButton
					onClick={handleCancelValueChange}
					data-field={`requestForm-${warningType}Warning-cancelButton`}
					className={classes.button}
				>
					Cancel
				</BlueOutlineButton>
				<GoldButton
					onClick={handleClearDateTime}
					data-field={`requestForm-${warningType}Warning-requestButton`}
					className={classes.button}
				>
					Clear date & time
				</GoldButton>
			</DialogActions>
		</Dialog>
	);
};

export default WarningModal;
