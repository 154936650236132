import { Error as ErrorIcon } from '@material-ui/icons';
import { FormHelperText } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
	errorMessage: {
		'color': theme.palette.error.main,
		'display': 'flex',
		'alignItems': 'start',
		'& > .MuiSvgIcon-root': {
			height: '16px',
			width: '16px',
			marginRight: theme.spacing(1),
			marginTop: theme.spacing(0.25),
		},
	},
}));

interface ErrorMessageMuiProps {
	children?: string;
	errorMessage?: string;
	icon?: React.ElementType;
	displayIcon?: boolean;
	className?: string;
}

const ErrorMessageMui: React.FC<ErrorMessageMuiProps> = ({ icon: Icon = ErrorIcon, children, errorMessage, displayIcon, className }) => {
	const classes = useStyles();
	if (!errorMessage && !children) { return null; }
	return (
		<FormHelperText className={classNames(classes.errorMessage, className)}>
			{displayIcon && <Icon />}
			{errorMessage || children}
		</FormHelperText>
	);
};

export default ErrorMessageMui;
