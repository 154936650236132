import React from 'react';
import { useSelector } from 'react-redux';
import { CaseDetailsInfoSelector } from '@store/selectors/CaseDetailsSelector';

interface UseHoldEventAdditionalParamsProps {
	isEdit?: boolean;
}

const useHoldEventAdditionalParams = ({ isEdit }: UseHoldEventAdditionalParamsProps): Record<string, string> | undefined => {
	const { caseNumber, id } = useSelector(CaseDetailsInfoSelector);

	return React.useMemo(() => {
		let additionalParams: Record<string, string> | undefined;

		if (!isEdit) {
			// do not add any params if it is a new request
			additionalParams = undefined;
		} else if (id) {
			// add requestId param when editing all cases
			additionalParams = {
				requestId: id
			};
			if (caseNumber) {
				// add caseNumber param when editing scheduled case
				additionalParams = {
					...additionalParams,
					caseNumber
				};
			}
		}

		return additionalParams;
	}, [caseNumber, id, isEdit]);
};

export default useHoldEventAdditionalParams;
