import React from 'react';
import { Box, BoxProps } from '@material-ui/core';
import type { SvgIcon } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => createStyles({
	iconMessage: {
		display: 'flex',
		gap: theme.spacing(1),
		color: theme.palette.grey[800],
	}
}));

const IconMessage: React.FC<BoxProps & { icon: typeof SvgIcon }> = ({ icon: Icon, children, ...props }) => {
	const classes = useStyles();

	return (
		<Box {...props} className={classNames(classes.iconMessage, props.className)}>
			<Icon />
			<Box>
				{children}
			</Box>
		</Box>
	);
};

export default IconMessage;
