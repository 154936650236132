import React from 'react';
import { useAuthMethods } from '@ascension/ascensionid-sdk';
import { StorageKey } from '../../authConstants';
import { CASES_ENTITY_NAME, TEMPLATE_ENTITY_NAME } from '../../constants';

/***
 * Used to retrieve logout method for user requested logout (as opposed to automatic idle logout).
 * Clears auto redirect navigation.
 *
 * @returns {() => void} logout - call returned method to log user out
 */
export const useInteractiveLogout = () => {
	const { logout } = useAuthMethods();

	return React.useCallback(() => {
		window.sessionStorage.removeItem(StorageKey.FILTER_QUERY + CASES_ENTITY_NAME);
		window.sessionStorage.removeItem(StorageKey.FILTER_QUERY + TEMPLATE_ENTITY_NAME);
		window.sessionStorage.removeItem(StorageKey.QUEUE_QUERY);
		window.sessionStorage.removeItem(StorageKey.NAV_TO);
		window.sessionStorage.removeItem(StorageKey.SCHEDULE_FACILITY);
		window.sessionStorage.removeItem(StorageKey.BLOCK_DATE);
		window.sessionStorage.removeItem(StorageKey.BLOCK_SURGEONS);
		void logout();
	}, [logout]);
};
