import { Box, createStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import ScrollIndicatorGradientProps
	from '@components/BoxScrollHorizontal/ScrollIndicatorGradient/ScrollIndicatorGradientProps';
import { alpha, makeStyles } from '@material-ui/core/styles';
import RightLeftDirectionEnum from '@interfaces/RightLeftDirectionEnum';
import {
	useBoxScrollHorizontalContextDirection,
} from '@components/BoxScrollHorizontal/BoxScrollHorizontal';
import { GRADIENT_WIDTH } from '@theme/themeConstants';

const useStyles = makeStyles(theme => createStyles({
	root: {
		'opacity': 0,
		'background': `linear-gradient(-90deg, transparent 0%, ${alpha(theme.palette.common.white, .4)} 25%,  ${theme.palette.common.white} 85%, ${theme.palette.common.white} 100%)`,
		'position': 'absolute',
		'top': 0,
		'width': `${GRADIENT_WIDTH}px`,
		'height': '100%',
		'zIndex': 1,
		'transition': '200ms',
		'&:not(.right)': {
			'left': 35,
			'&.show': {
				left: 50,
			}
		},
		'&.right': {
			'transform': 'rotate(180deg)',
			'right': 35,
			'&.show': {
				right: 50,
			},
		},
		'&.show': {
			opacity: 1,
		}
	},
}));

const ScrollIndicatorGradient: React.FC<ScrollIndicatorGradientProps> = ({ direction }) => {
	const classes = useStyles();

	const { hasHiddenContent } = useBoxScrollHorizontalContextDirection(direction);

	return (
		<Box
			data-field={`boxScrollHorizontal-gradientIndicator-${direction}`}
			className={classNames(classes.root, {
				show: hasHiddenContent,
				right: direction === RightLeftDirectionEnum.RIGHT
			})}
		/>
	);
};

export default ScrollIndicatorGradient;

