import { ToastStatusState, ToastType } from '../../interfaces/ToastStatus/ToastStatusState';
import { CLEAR_TOAST_STATUS, SET_TOAST_STATUS } from '../actions/types';
import { ToastStatusAction } from '../../interfaces/ToastStatus/ToastStatusAction';

const initialState: ToastStatusState = {
	toastType: ToastType.NONE,
};

const ToastStatusReducer = (state: ToastStatusState = initialState, action: ToastStatusAction): ToastStatusState =>{
	switch (action.type) {
	case CLEAR_TOAST_STATUS:
		return { ...initialState };
	case SET_TOAST_STATUS:
		return {
			...action.payload
		};
	}
	return state;
};

export default ToastStatusReducer;
