import React from 'react';
import { ManageCaseOptionProps } from '@components/CaseDetails/ManageCaseDropDown/ManageCaseOptionProps';
import { MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => createStyles({
	menuItemStyle: {
		'&.MuiListItem-root': {
			fontSize: '14px',
			padding: theme.spacing(1, 2)
		},
	},
}));
export const ManageCaseOption: React.FC<ManageCaseOptionProps> = ({ onClick, fieldName, disabled, children }) => {
	const { menuItemStyle } = useStyles();
	return (
		<MenuItem className={menuItemStyle} data-field={`manageCase-option-${fieldName}`} onClick={onClick} disabled={disabled}>
			{children}
		</MenuItem>
	);
};
