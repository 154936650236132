import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { COLUMN_OFFSET, TICK_OFFSET } from '@calendar/utilities/CalendarConstants';
import classNames from 'classnames';
import { FocusType, RoundingAlgorithm, useCalendarContext } from '@calendar';
import { useScrollWithinBox } from '@components/ScrollWithinBox';
import EventCardProps from '@components/EventCard/EventCardProps';
import { TEMP_STUB_EVENT_ID } from '@components/UnitCalendarPage/utilities/calendarConstants';

const useStyles = makeStyles({
	eventContainer: {
		gridColumnEnd: 'span 1',
		borderRadius: '4px',
		marginRight: '5px',
		marginLeft: '3px',
		marginTop: '2px',
		border: '2px solid #BDBDBD',
		zIndex: 200,
		overflow: 'hidden',
		paddingLeft: '8px',
		paddingTop: '8px',
		cursor: 'pointer',
	},
});

const EventCard: React.FC<EventCardProps> = ({ children, displayWhenZero, onCardClick, onElmUpdate, ...eventInfo }) => {
	const { id, column, start, end, additionalClassName = '', type } = eventInfo;
	const classes = useStyles();
	const { columnNames, findTimeTickIndex, focusOn } = useCalendarContext();

	const ref = React.useRef<HTMLDivElement | null>(null);
	const { gridColumnStart, gridRowEnd, gridRowStart } = React.useMemo(() => ({
		gridColumnStart: columnNames.indexOf(column) + COLUMN_OFFSET,
		gridRowStart: findTimeTickIndex(start, RoundingAlgorithm.FLOOR) + TICK_OFFSET,
		gridRowEnd: findTimeTickIndex(end) + TICK_OFFSET,
	}), [columnNames, column, findTimeTickIndex, start, end]);
	const { setScrollInfo } = useScrollWithinBox();

	React.useLayoutEffect(() => {
		if (
			!focusOn ||
			focusOn.type !== FocusType.EVENT ||
			focusOn.id !== id
		) {
			return;
		}

		setScrollInfo({ scrollTo: ref });
	}, [focusOn, id, setScrollInfo]);

	const handleOnClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (eventInfo.id === TEMP_STUB_EVENT_ID || !onCardClick) { return; }
		onCardClick({ eventInfo, elmCard: event.target as HTMLDivElement });
	};

	const localRefSetter = React.useCallback((elm: HTMLDivElement | null) => {
		ref.current = elm;
		if (onElmUpdate) onElmUpdate(elm);
	}, [onElmUpdate]);

	if (!displayWhenZero && gridRowStart === gridRowEnd) {
		return null;
	}

	const strippedEventId = id.split('::')[0];

	return (
		<div
			ref={localRefSetter}
			className={classNames(
				additionalClassName,
				classes.eventContainer
			)}
			style={{
				gridColumnStart,
				gridRowEnd,
				gridRowStart,
			}}
			onClick={handleOnClick}
			data-field={`eventCard-${type}-${strippedEventId}`}
		>
			{children}
		</div>
	);
};

export default EventCard;
