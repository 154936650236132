import React, { ChangeEvent, Dispatch } from 'react';
import { Box, FormLabel, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { TextField } from '@ascension/ui-library';
import { SCROLLBAR_SIZE } from '@theme/themeConstants';
import { formatInTimeZone } from 'date-fns-tz';
import { isEqual } from 'date-fns';
import { TIME_ONLY_FORMAT } from '@utilities/constants';
import getTimeScale from '@calendar/utilities/getTimeScale/getTimeScale';
import { NEXT_DAY_TIME } from '@utilities/constants';
import { getZonedTimeFromTimeAndDate } from '@utilities/dateUtilities';
import NoParamNoReturnCallback from '@interfaces/NoParamNoReturnCallback';

const useStyles = makeStyles((theme) => ({
	label: {
		fontWeight: 600,
		paddingBottom: theme.spacing(.5),
		alignSelf: 'baseline',
	},
	separator: {
		marginRight: theme.spacing(1),
		marginLeft: theme.spacing(1),
	},
	input: {
		'width': '110px',
		'& .MuiInputAdornment-root': {
			display: 'none',
		},
		'& .MuiFormHelperText-contained': {
			marginLeft: 0,
		},
		'paddingRight': 0,
	},
	wrapper: {
		'display': 'flex',
		'flexDirection': 'column',
		'justifyContent': 'start',
		'&:focus-within #time-input-label:not(.Mui-error)': {
			color: theme.palette.primary.main,
		},
	},
	timeSpanContainer: {
		'display': 'flex',
		'& span': {
			paddingTop: theme.spacing(2),
		},
		'& .MuiAutocomplete-endAdornment': {
			display: 'none',
		},
		'& .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
			paddingRight: 0,
		},
	},
	autocompleteListPopper: {
		'width': '240px',
		'fontFamily': theme.typography.fontFamily,
		'fontWeight': 500,
		'boxShadow': theme.shadows[8],
		'& li': {
			paddingTop: theme.spacing(1.5),
			paddingBottom: theme.spacing(1.5),
		},
		'& ::-webkit-scrollbar': {
			width: SCROLLBAR_SIZE,
		},
		'& ::-webkit-scrollbar-thumb': {
			backgroundColor: theme.palette.grey[400],
			borderRadius: '100px',
		},
		// Firefox specific scrollbar styles
		'scrollbarWidth': 'thin',
		'scrollbarColor': `${theme.palette.grey[400]} transparent`,
	},
	nextDay: {
		...theme.typography.subtext1,
		marginLeft: theme.spacing(1.5),
		opacity: 0.6,
		marginTop: theme.spacing(2),
		whiteSpace: 'nowrap',
	}
}));

interface TimeInputProps {
	helperText: string;
	autocompleteParams: AutocompleteRenderInputParams;
	hasError: boolean;
}

export interface TimeSpanAutoCompleteInputProps {
	label?: string;
	defaultStartOption: Date;
	defaultEndOption: Date;
	currentStartInputValue: string;
	currentEndInputValue: string;
	onStartInputValueChange: Dispatch<string>;
	onEndInputValueChange: Dispatch<string>;
	hasStartErr?: boolean;
	hasEndErr?: boolean;
	hospitalTimeZone: string;
	interval: number; // in minutes
	triggerValidation?: NoParamNoReturnCallback;
}

interface AutocompleteInputProps extends
	Pick<TimeInputProps, 'helperText' | 'hasError'>,
	Pick<TimeSpanAutoCompleteInputProps, 'hospitalTimeZone' | 'interval'>
{
	inputValue: string;
	onValueChange: Dispatch<string>;
	defaultOption: Date;
	triggerValidation?: NoParamNoReturnCallback;
}

const TimeInput: React.FC<TimeInputProps> = ({ helperText, autocompleteParams, hasError }) => {
	const classes = useStyles();
	return (
		<TextField
			translate="yes"
			variant="outlined"
			placeholder="--:-- --"
			className={classes.input}
			{...autocompleteParams}
			inputProps={{ ...autocompleteParams.inputProps }}
			required
			error={hasError}
			helperText={helperText}
		/>
	);
};

const CustomPaper: React.FC = ({ children }) => {
	const classes = useStyles();
	return (
		<Paper className={classes.autocompleteListPopper}>
			{children}
		</Paper>
	);
};

const handleGetOptionSelected: (option: Date, value: Date) => boolean = (option, value) => {
	// required to select the correct option for the right date since there can be duplicate display values (i.e. 12:00 AM)
	return isEqual(option, value);
};

const AutocompleteInput: React.FC<AutocompleteInputProps> = (
	{
		helperText,
		defaultOption,
		inputValue,
		onValueChange,
		hasError,
		hospitalTimeZone,
		interval,
		triggerValidation,
	}) => {
	const timeOptions = React.useMemo(() => {
		const options = getTimeScale(hospitalTimeZone, interval);
		// Removed first option "12:00 AM" for Start Time and removed last option "12:00 AM" for End Time
		if (helperText === 'Start') {
			options.pop();
		} else {
			options.shift();
		}
		return options;
	}, [helperText, hospitalTimeZone, interval]);

	const selectedTime = getZonedTimeFromTimeAndDate(inputValue, hospitalTimeZone, defaultOption);

	// Get default option for suggested dropdown options to make sure correct option is selected for default input value.
	// Updated formField from Date option to String input because we need to validate input value. Option is just suggestions for input.
	const [selectedOption, setSelectedOption] = React.useState<Date>(selectedTime || defaultOption);

	const handleChange = (event: ChangeEvent<Record<string, never>>, newValue: Date | null) => {
		if (newValue) {
			setSelectedOption(newValue);
			triggerValidation && triggerValidation();
		}
	};

	const handleInputChange = (ev: ChangeEvent<Record<string, never>>, newValue: string) => {
		if (!ev) { return; }
		onValueChange(newValue.toUpperCase());
	};

	const handleGetOptionLabel = (option: Date): string => {
		// options are Dates, but only time should display
		return formatInTimeZone(option, hospitalTimeZone, TIME_ONLY_FORMAT);
	};

	const handleRenderTimeInput = (params: AutocompleteRenderInputParams) => (
		<TimeInput
			autocompleteParams={params}
			helperText={helperText}
			hasError={hasError}
		/>
	);

	return (
		<Autocomplete
			id={`${helperText}-time-input`}
			aria-labelledby="time-input-label"
			value={selectedOption}
			onChange={handleChange}
			inputValue={inputValue}
			onInputChange={handleInputChange}
			options={timeOptions}
			renderInput={handleRenderTimeInput}
			getOptionLabel={handleGetOptionLabel}
			getOptionSelected={handleGetOptionSelected}
			closeIcon={null}
			forcePopupIcon={false}
			PaperComponent={CustomPaper}
			autoHighlight={true}
			autoSelect={!!inputValue}
			onBlur={triggerValidation}
			clearOnBlur={false}
			noOptionsText="No results"
		/>
	);
};

const TimeSpanAutocompleteInput: React.FC<TimeSpanAutoCompleteInputProps> = (
	{
		label,
		defaultStartOption,
		defaultEndOption,
		currentStartInputValue,
		currentEndInputValue,
		onStartInputValueChange,
		onEndInputValueChange,
		hasStartErr = false,
		hasEndErr = false,
		hospitalTimeZone,
		interval,
		triggerValidation,
	}) => {
	const classes = useStyles();

	return (
		<Box className={classes.wrapper}>
			{ label &&
				<FormLabel required id="time-input-label" className={classes.label} error={hasStartErr || hasEndErr}>
					{label}
				</FormLabel>
			}
			<Box className={classes.timeSpanContainer}>
				<AutocompleteInput
					helperText="Start"
					defaultOption={defaultStartOption}
					inputValue={currentStartInputValue}
					onValueChange={onStartInputValueChange}
					hasError={hasStartErr}
					hospitalTimeZone={hospitalTimeZone}
					interval={interval}
					triggerValidation={triggerValidation}
				/>
				<span className={classes.separator}>-</span>
				<AutocompleteInput
					helperText="End"
					defaultOption={defaultEndOption}
					inputValue={currentEndInputValue}
					onValueChange={onEndInputValueChange}
					hasError={hasEndErr}
					hospitalTimeZone={hospitalTimeZone}
					interval={interval}
					triggerValidation={triggerValidation}
				/>
				{currentEndInputValue === NEXT_DAY_TIME &&
					<Typography className={classes.nextDay} data-field="nextDay">
						(Next day)
					</Typography>}
			</Box>
		</Box>
	);
};

export default TimeSpanAutocompleteInput;
