import { WebRequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import { RequestFormDefaultValues } from '../RequestFormDefaultValues';
import { FormSection } from '../interface/FormSection';

function objectIsRequestFormValidatedState(x: WebRequestFormValidatedState | object): x is WebRequestFormValidatedState {
	return Object.keys(x).length > 0;
}

const sectionFields: Record<FormSection, Array<keyof WebRequestFormValidatedState>> = {
	[FormSection.insurance]: [
		'insuredLastNamePrimary',
		'insuredFirstNamePrimary',
		'healthPlanNamePrimary',
		'policyNumberPrimary',
		'groupNumberPrimary',
		'insuredLastNameSecondary',
		'insuredFirstNameSecondary',
		'healthPlanNameSecondary',
		'policyNumberSecondary',
		'groupNumberSecondary',
	],
	[FormSection.patient]: [
		'patientLastName',
		'patientFirstName',
		'patientDOB',
		'patientEmail',
		'patientGender',
		'patientInterpreterNeeded',
		'patientInterpretLanguage',
		'patientLatexAllergy',
		'patientMiddleName',
		'patientPhoneNumber',
		'patientSSN',
		'patientSSN1',
		'patientSSN2',
		'patientSSN3',
		'patientIodineAllergy',
		'patientAddressLine1',
		'patientAddressLine2',
		'patientCity',
		'patientState',
		'patientZipCode'
	],
};

export default function copyCaseSections(caseInfo: WebRequestFormValidatedState | object, sections: FormSection[]): WebRequestFormValidatedState {
	if (!objectIsRequestFormValidatedState(caseInfo)) { return RequestFormDefaultValues as WebRequestFormValidatedState; }

	const fieldsToCopy: string[] = sections.map((section) => sectionFields[section]).flat();
	const filteredEntries = Object.entries(caseInfo)
		.filter(([keyProp]) => fieldsToCopy.includes(keyProp));
	const filteredObject = Object.fromEntries(filteredEntries) as WebRequestFormValidatedState;

	return {
		...RequestFormDefaultValues,
		...filteredObject,
	};
}
