import { Box, Button, Flex, Text } from '@ascension/web';
import React from 'react';
import { useInteractiveLogout } from '../../../utilities/hooks/useInteractiveLogout/useInteractiveLogout';
import { useModalContext } from '@components/Modal/ModalContextProvider';
import { ACCOUNT_LOCK_MODAL } from '@components/Modal/modalConstants';

export default function AccountLockedModal () {
	const { closeModal } = useModalContext();
	const logout = useInteractiveLogout();

	const handleLogout = () => {
		closeModal(ACCOUNT_LOCK_MODAL);
		logout();
	};

	return (
		<Flex width="520px" flexDirection="column">
			<Text fontSize={24} mb={4}>
				Account Locked
			</Text>
			<Text fontSize={2}>
				Contact your manager to update your Ascension ID in the active directory.
			</Text>
			<Box mt={48} ml = "auto">
				<Button onClick={handleLogout} id="acceptLock"> OKAY </Button>
			</Box>
		</Flex>
	);
};
