import CalendarErrorContainer from '@components/CalendarError/presentationalComponents/CalendarErrorContainer';
import CalendarErrorTitle from '@components/CalendarError/presentationalComponents/CalendarErrorTitle';
import CalendarErrorBody from '@components/CalendarError/presentationalComponents/CalendarErrorBody';
import React from 'react';

const OutOfRangePastCalendarError = () => {
	return (
		<CalendarErrorContainer>
			<CalendarErrorTitle>
				Requested date must be at least 48 hours from now
			</CalendarErrorTitle>
			<CalendarErrorBody>
				For sooner, call Scheduling Support.
			</CalendarErrorBody>
		</CalendarErrorContainer>
	);
};

export default OutOfRangePastCalendarError;
