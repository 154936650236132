import React from 'react';
import { EventInfo } from '@calendar';
import EventCard from '@components/EventCard/EventCard';
import { makeStyles } from '@material-ui/core/styles';
import EventCardContent from '@components/EventCard/EventCardContent';
import { useOfficeCalendarViewContext } from '@components/OfficeCalendarView/OfficeCalendarViewContext/OfficeCalendarViewContext';

const useStyles = makeStyles(theme => ({
	card: {
		backgroundColor: '#E8F5FE',
		border: `1px solid ${theme.palette.primary.main}`,
		boxShadow: theme.shadows[23],
	},
}));

const HoldEventCard: React.FC<EventInfo> = (event: EventInfo) => {
	const classes = useStyles();
	const { setHoldEventCardElm, setIsPopoverOpen } = useOfficeCalendarViewContext();

	const handleCardClick = () => {
		setIsPopoverOpen(true);
	};

	return (
		<EventCard
			{...event}
			additionalClassName={classes.card}
			onElmUpdate={setHoldEventCardElm}
			onCardClick={handleCardClick}
		>
			<EventCardContent title={event.title} timeSpan={event.timeSpanDisplay}/>
		</EventCard>
	);
};

export default HoldEventCard;
