import React, { forwardRef } from 'react';
import LockIcon from '@material-ui/icons/Lock';
import { Box, Card, Flex, Text } from '@ascension/web';
import { AbbreviatedProcedureCardProps } from './AbbreviatedProcedureCardProps';
import {
	AbbreviatedProcedureCardArea,
	AbbreviateProcedureCardLayoutSettings
} from './AbbreviatedProcedureCardLayout';
import AbbreviatedProcedureCardTag from './AbbreviatedProcedureCardTag/AbbreviatedProcedureCardTag';
import useSurgeonName from './useSurgeonName/useSurgeonName';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { dateParse, utcToLocalTime } from '@utilities/dateUtilities';
import { useCommonStyles } from '@utilities/hooks/useCommonStyles/useCommonStyles';
import { DropdownOption } from '@definitions/DropdownOption';

const DEFAULT_TEXT = '---';

const useStyles = makeStyles({
	smallLabel: {
		fontFamily: 'Lato',
		fontSize: '10px',
		fontStyle: 'italic',
		whiteSpace: 'nowrap',
		letterSpacing: 0,
		lineHeight: 1.2,
	},
	rightLabel: {
		textAlign: 'right',
	},
	boldLabel: {
		fontWeight: 700,
	},
	smallIcon: {
		fontSize: '10px',
		top: '1px',
		position: 'relative',
	},
	gridItemEndAlign: {
		justifySelf: 'end',
	},
});

interface ValueDisplayProps {
	label: string;
	value?: string;
	style?: React.CSSProperties;
	stylingClassNames?: string;
}
const ValueDisplay = ({ label, value, stylingClassNames, ...rest }: ValueDisplayProps) => (
	<Flex {...rest} flexDirection="column">
		<Text
			fontSize={0}
			fontWeight={700}
			lineHeight={1.25}
			letterSpacing={2}
			color="#999"
			className={stylingClassNames}
			mb={2}
		>
			{label}
		</Text>
		<Text>{!(value || '').trim() ? DEFAULT_TEXT : value}</Text>
	</Flex>
);

function isValidAccessor (key: string, obj: AbbreviatedProcedureCardProps['info']): key is keyof AbbreviatedProcedureCardProps['info'] {
	return key in obj;
}

const AbbreviatedProcedureCard = ({ info, menuComponent, layout, tagType, onClick }: AbbreviatedProcedureCardProps, ref: React.Ref<HTMLDivElement>) => {
	const surgeonObj = info.surgeon || {};
	const surgeonId = 'value' in surgeonObj ? surgeonObj.value : surgeonObj.id;
	const comments = 'additionalComments' in info ? info.additionalComments : info.comments ? info.comments : '';
	const surgeonName = useSurgeonName(surgeonId);
	const { smallLabel, rightLabel, boldLabel, smallIcon, gridItemEndAlign } = useStyles();
	const jointAnesthesiaType: (string | DropdownOption)[] | undefined = info.anesthesiaType;
	const anesthesiaType = Array.isArray(jointAnesthesiaType) ? jointAnesthesiaType.map((option: DropdownOption | string) => typeof option === 'string' ? option : option.value).join( ' + ' ) : jointAnesthesiaType;

	const { hideTextOverflow } = useCommonStyles();

	const theme = useTheme();

	const {
		gridTemplateAreas,
		gridTemplateColumns,
		gridTemplateRows,
		titleProperty,
		...restStyles
	} = AbbreviateProcedureCardLayoutSettings[layout](theme);

	const cardStyle = {
		display: 'grid',
		alignItems: 'start',
		gridTemplateColumns,
		gridTemplateRows,
		gridTemplateAreas,
		...restStyles
	};

	const title = isValidAccessor(titleProperty, info) ? info[titleProperty] : '';

	const lastTimeUsed = 'lastTimeUsed' in info ? info.lastTimeUsed : undefined;
	const lastDateUsed = React.useMemo(() => {
		if (!lastTimeUsed) { return DEFAULT_TEXT; }
		const lastTimeUsedLocal = utcToLocalTime(lastTimeUsed) as string;
		const [dateParsed] = dateParse(lastTimeUsedLocal);
		return /\d{2}\/\d{2}\/\d{4}/.test(dateParsed) ? dateParsed : DEFAULT_TEXT;
	}, [lastTimeUsed]);

	return (
		<Box ref={ref}>
			<Card
				mb={8}
				background="rgba(166, 195, 237, 0.15)"
				style={cardStyle}
				disableInteraction={!onClick}
				onClick={onClick}
			>
				<Flex alignItems="center" style={{ gridArea: AbbreviatedProcedureCardArea.TITLE }}>
					<Text data-field="title" fontSize={16} fontWeight={700} width="fit-content" maxWidth={344} pr={2} className={hideTextOverflow}>
						{title || DEFAULT_TEXT}
					</Text>
					<AbbreviatedProcedureCardTag tagType={tagType} style={{ gridArea: AbbreviatedProcedureCardArea.TAGS }} />
				</Flex>
				<Text data-field="surgeon" fontSize={16} fontWeight={500} style={{ gridArea: AbbreviatedProcedureCardArea.SURGEON }}>
					{surgeonName}
				</Text>
				{titleProperty !== 'procedureName' &&
					<Text fontSize={ 16 } fontWeight={ 500 } className={[hideTextOverflow]}
						style={ { gridArea: AbbreviatedProcedureCardArea.PROCEDURE } }>
						{ info.procedureName || DEFAULT_TEXT }
					</Text>
				}
				<Box style={{ gridArea: AbbreviatedProcedureCardArea.MENU }} className={gridItemEndAlign}>
					{menuComponent}
				</Box>
				<ValueDisplay data-field="anesthesiaType" label="Anesthesia Type" value={anesthesiaType} style={{ gridArea: AbbreviatedProcedureCardArea.ANESTHESIA }}/>
				<ValueDisplay data-field="additionalComments" label="Additional Procedure Comments"
					style={{ gridArea: AbbreviatedProcedureCardArea.COMMENTS }}
					value={
						comments ? (comments.length < 32
							? comments
							: comments.substring(0, 23) + ' . . . . . . . . .') : ''
					}
				/>
				{
					'lastTimeUsed' in info &&
					<Flex
						style={{ gridArea: AbbreviatedProcedureCardArea.LAST_USED }}
						flexDirection="column">
						<Text className={smallLabel}>Last used on</Text>
						<Text className={smallLabel}>{lastDateUsed}</Text>
					</Flex>
				}
				{
					'lockStatus' in info && info.lockStatus && info.lockStatus.locked &&
					<Flex
						style={{ gridArea: AbbreviatedProcedureCardArea.LOCKED_STATUS }}
						className={gridItemEndAlign}
						flexDirection="column">
						<Text className={[smallLabel, rightLabel, boldLabel]}><LockIcon className={smallIcon}/> Template locked</Text>
						<Text className={[smallLabel, rightLabel]}>{`${info.lockStatus.firstName} ${info.lockStatus.lastName} is currently editing`}</Text>
					</Flex>
				}
			</Card>
		</Box>
	);
};

export default forwardRef(AbbreviatedProcedureCard);
