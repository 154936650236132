import React from 'react';
import { useSelector } from 'react-redux';
import { addDays, format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { AxiosError, AxiosResponse } from 'axios';
import { useFormContext } from 'react-hook-form';
import { getZonedTimeFromTimeAndDate } from '@utilities/dateUtilities';
import {
	useOfficeCalendarViewContext,
} from '@components/OfficeCalendarView/OfficeCalendarViewContext/OfficeCalendarViewContext';
import { UserProfilePrimaryPracticeSelector } from '@store/selectors/UserProfileSelector';
import useCreateHoldEvent from '@data/holdEvent/hooks/useCreateHoldEvent/useCreateHoldEvent';
import convertHoldEventResponseToDetails from '@components/RequestForm/utilities/convertHoldEventResponseToDetails';
import {
	HoldEventDetailsCreateRequest, HoldEventDetailsResponse
} from '@data/holdEvent/types/HoldEventApiPayload';
import { OfficeCalendarError } from '@components/OfficeCalendarView/OfficeCalendarError';
import { HOLD_EVENT_OVERLAPPING_TIME } from '@utilities/Validation/ValidationMessages';
import SubmitHoldEventHookResult from '@data/openCalendar/SubmitHoldEventHookResult';
import { decorateErrMsgBasedOnOverlaps } from '@components/OfficeCalendarView/SelectOpenTimePopover/PreBookEventTimeSpanInput/HoldTimeValidationSchema';
import useUpdateHoldEvent from '@data/holdEvent/hooks/useUpdateHoldEvent/useUpdateHoldEvent';
import CurrentHoldEventType from '@components/OfficeCalendarView/utilities/CurrentHoldEventType';
import useHoldEventAdditionalParams from '@utilities/hooks/useHoldEventAdditionalParams/useHoldEventAdditionalParams';
import { OfficeCalendarViewDrawerProps } from '@components/OfficeCalendarView/OfficeCalendarView';
import { PartialTimeSpan } from '@utilities/Validation/TimeSpanValidationUtilities/TimeSpanValidationUtilities';

const useSubmitHoldEvent = ({ onSubmitHoldEventSuccess, isEdit }: OfficeCalendarViewDrawerProps): SubmitHoldEventHookResult => {
	const createHoldEvent = useCreateHoldEvent();
	const updateHoldEvent = useUpdateHoldEvent();
	const { getValues, setError } = useFormContext<{ timeSpan: PartialTimeSpan }>();
	const {
		selectedDate,
		selectedRoom,
		unit: { id: unitId, hospital: hospitalId, hospitalTimeZone },
		rooms,
		toggleDrawer,
		getUnitEventsFromApi,
		setCalendarError,
		events,
		currentHoldEvent,
		currentHoldEventType,
	} = useOfficeCalendarViewContext();

	const { name: practiceName } = useSelector(UserProfilePrimaryPracticeSelector);

	const roomId = rooms[selectedRoom || ''];

	const { timeSpan } = getValues();
	const setTimeSpanAPIError = React.useCallback(() => {
		const errorMsg = decorateErrMsgBasedOnOverlaps({
			timeSpan,
			overlapEvents: events.filter(e => e.column === selectedRoom),
			overlapMsg: HOLD_EVENT_OVERLAPPING_TIME
		});

		errorMsg && setError('timeSpan', { type: 'validate', message: errorMsg });
	}, [events, selectedRoom, setError, timeSpan]);
	const hasAPIConflictError = React.useRef<boolean>(false);
	const additionalParams = useHoldEventAdditionalParams({ isEdit });

	const submitHoldEvent = React.useCallback(() => {
		const { timeSpan } = getValues();
		const { start, end } = timeSpan;
		if (!onSubmitHoldEventSuccess || !selectedDate || !start || !end || !hospitalTimeZone) { return; }

		const startTime = getZonedTimeFromTimeAndDate(start, hospitalTimeZone, selectedDate);
		const endTime = getZonedTimeFromTimeAndDate(end, hospitalTimeZone, selectedDate);
		const endTimeVal = endTime && end === '12:00 AM' ? addDays(endTime, 1) : endTime;

		if (!startTime || !endTimeVal) { return; }

		const formattedStartTime = formatInTimeZone(startTime, 'Z', 'yyyy-MM-dd HH:mm');
		const formattedEndTime =  formatInTimeZone(endTimeVal, 'Z', 'yyyy-MM-dd HH:mm');

		const onSuccess = (res: AxiosResponse<HoldEventDetailsResponse>) => {
			onSubmitHoldEventSuccess(convertHoldEventResponseToDetails(res.data));
			toggleDrawer(false)();
		};

		const onFailure = (error: AxiosError) => {
			getUnitEventsFromApi();
			const status = error.response?.status;
			if (status === 409) {
				setCalendarError(OfficeCalendarError.TIME_NO_LONGER_AVAIL);
				setTimeSpanAPIError();
				hasAPIConflictError.current = true;
			}
		};

		const createPayload: HoldEventDetailsCreateRequest = {
			hospitalId,
			unitId,
			hospitalTimeZone,
			room: selectedRoom || '',
			roomId,
			date: format(selectedDate, 'yyyy-MM-dd'),
			startTime: formattedStartTime,
			endTime: formattedEndTime,
			description: practiceName,
		};

		if (!currentHoldEvent || currentHoldEventType !== CurrentHoldEventType.UPDATE) {
			return createHoldEvent({
				data: createPayload,
				onSuccess,
				onFailure,
				additionalParams
			});
		}

		return updateHoldEvent({
			data: {
				...createPayload,
				id: currentHoldEvent?.id || '',
			},
			onSuccess,
			onFailure
		});
	}, [
		getValues,
		onSubmitHoldEventSuccess,
		selectedDate,
		hospitalTimeZone,
		hospitalId,
		unitId,
		selectedRoom,
		roomId,
		practiceName,
		currentHoldEvent,
		currentHoldEventType,
		updateHoldEvent,
		toggleDrawer,
		getUnitEventsFromApi,
		setCalendarError,
		setTimeSpanAPIError,
		createHoldEvent,
		additionalParams,
	]);

	return {
		submitHoldEvent,
		hasAPIConflictError,
		setTimeSpanAPIError
	};
};

export default useSubmitHoldEvent;
