import React from 'react';
import ModalViewer from '@components/Modal/ModalViewer';
import { useModalContext } from '@components/Modal/ModalContextProvider';
import { SELECT_PRACTICE_CONFIRM_MODAL } from '@components/Modal/modalConstants';
import { Box, createStyles, Typography } from '@material-ui/core';
import BlueOutlineButton from '@components/BlueOutlineButton/BlueOutlineButton';
import GoldButton from '@components/GoldButton/GoldButton';
import { useChangePractice } from '@utilities/hooks/useChangePractice/useChangePractice';
import { useSelector } from 'react-redux';
import { UserPreferenceSelector } from '@store/selectors/UserProfileSelector';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => createStyles({
	container: {
		'display': 'flex',
		'flexDirection': 'column',
		'maxWidth': '528px',
		'padding': theme.spacing(1, 4.5, 0, 4.5),
		'& h5': {
			marginTop: theme.spacing(-4),
		},
		'& > p': {
			'marginTop': theme.spacing(2),
			'&:first-child': {
				marginTop: theme.spacing(4),
			}
		},
	},
	buttonContainer: {
		'display': 'flex',
		'justifyContent': 'flex-end',
		'marginTop': theme.spacing(3),
		'& button': {
			'height': '48px',
			'&:last-child': {
				marginLeft: theme.spacing(2),
			},
		},
	},
	locationRow: {
		'display': 'flex',
		'marginTop': theme.spacing(3),
		'& > strong': {
			width: 'fit-content',
			whiteSpace: 'nowrap',
			fontWeight: 600,
			marginRight: theme.spacing(1),
		},
	},
}));

interface ConfirmSelectionModalProps {
	oldLocationId: string;
	newLocationId: string;
}

const ConfirmSelectionModal: React.FC<ConfirmSelectionModalProps> = ({ oldLocationId, newLocationId }) => {
	const classes = useStyles();
	const { closeModal } = useModalContext();
	const { setPractice } = useChangePractice();
	const { practices } = useSelector(UserPreferenceSelector);

	const oldLocationName = practices && practices.find(p => p.id === oldLocationId)?.name;
	const newLocationName = practices && practices.find(p => p.id === newLocationId)?.name;

	return (
		<ModalViewer
			id={SELECT_PRACTICE_CONFIRM_MODAL}
			onClose={()=> closeModal(SELECT_PRACTICE_CONFIRM_MODAL)}
			shouldCloseOnBackdropClick={true}
			shouldCloseOnEscapeKeyDown={true}
		>
			<Box className={classes.container}>
				<Typography variant="h5">Are you sure you want to change your practice location?</Typography>
				<Typography>You are about to change your office practice location.</Typography>
				<Box className={classes.locationRow}>
					<Typography component="strong" id="oldPracticeLabel">Current Practice: </Typography>
					<Typography aria-labelledby="oldPracticeLabel">{oldLocationName}</Typography>
				</Box>
				<Box className={classes.locationRow}>
					<Typography component="strong" id="newPracticeLabel">New Practice:</Typography>
					<Typography aria-labelledby="newPracticeLabel">{newLocationName}</Typography>
				</Box>
				<Typography>
					When changing locations, all settings and filters will be reset, any unsaved changes will be lost, and you will be redirected to the homepage.
				</Typography>
				<Typography>Filters and settings associated with the newly selected location will be applied.</Typography>
				<Box className={classes.buttonContainer}>
					<BlueOutlineButton onClick={() => closeModal(SELECT_PRACTICE_CONFIRM_MODAL)}>Cancel</BlueOutlineButton>
					<GoldButton onClick={() => setPractice({ id: newLocationId })}>Yes, Change Location</GoldButton>
				</Box>
			</Box>
		</ModalViewer>
	);
};

export default ConfirmSelectionModal;
