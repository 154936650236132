import React from 'react';
import {
	Box,
	Checkbox,
	createStyles,
	FormControl,
	InputLabel,
	ListItemText,
	makeStyles,
	MenuItem,
	Select
} from '@material-ui/core';
import { Surgeon } from '@data/surgeon/Surgeon';
import { alphaByDisplayName, getFormattedName } from '@utilities/commonFunction';
import { SurgeonMultiSelectProps } from '@components/SurgeonMultiSelect/SurgeonMultiSelectProps';

const useStyles = makeStyles(theme => createStyles({
	container: {
		display: 'flex',
		flexDirection: 'column',
		width: '305px',
		[theme.breakpoints.down('md')]: {
			flex: 'auto',
		},
	},
	inputLabel: {
		color: theme.palette.grey[700],
		fontWeight: 600,
		marginBottom: theme.spacing(1),
	},
	select: {
		'height': '48px',
		'color': theme.palette.grey[900],
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.palette.grey[700],
		},
		'& .MuiSelect-iconOutlined': {
			color: theme.palette.grey[700],
		},
	},
}));

const SurgeonMultiSelect: React.FC<SurgeonMultiSelectProps> = ({ selectedSurgeons, setSelectedSurgeons, surgeonList }) => {
	const classes = useStyles();

	const options = React.useMemo(() => {
		if (!surgeonList || !surgeonList.length) { return []; }
		return surgeonList.map((surgeon: Surgeon) => ({
			...surgeon,
			npi: surgeon.npi,
			displayName: getFormattedName(surgeon),
		})).sort(alphaByDisplayName);
	}, [surgeonList]);

	const npis = React.useMemo(() => options.map((o) => o.npi), [options]);

	const handleSelect = React.useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
		const { target: { value } } = event;

		if (!Array.isArray(value) || value.includes('all')) {
			setSelectedSurgeons(npis);
			return;
		}

		setSelectedSurgeons(value.map(Number));
	}, [npis, setSelectedSurgeons]);

	const checkedSurgeons = React.useMemo(() => {
		if (options.length > 0 && selectedSurgeons?.length === options.length) {
			return [];
		}

		return selectedSurgeons;
	}, [options.length, selectedSurgeons]);

	const placeholder = React.useCallback(() => {
		if (!checkedSurgeons || !checkedSurgeons.length || checkedSurgeons.length === options.length) {
			return `ALL PREFERRED (${options.length})`;
		}

		if (checkedSurgeons.length === 1) {
			const surgeon = options.find((o) => o.npi === checkedSurgeons[0]);

			return surgeon?.displayName;
		}

		return `${checkedSurgeons.length} Surgeons Selected (${options.length})`;
	}, [checkedSurgeons, options]);

	return (
		<Box className={classes.container}>
			<InputLabel
				id="surgeon-multiselect-label"
				className={classes.inputLabel}
			>
				Surgeons
			</InputLabel>
			<FormControl
				variant="outlined"
				data-field="surgeon-multiselect-formcontrol"
			>
				<Select
					id="surgeon-multiselect"
					labelId="surgeon-multiselect-label"
					multiple
					value={checkedSurgeons}
					renderValue={placeholder}
					className={classes.select}
					onChange={handleSelect}
					displayEmpty={true}
					MenuProps={{
						anchorOrigin: {
							vertical: 'bottom',
							horizontal: 'left',
						},
						transformOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},
						getContentAnchorEl: null,
						PaperProps: {
							style: {
								maxHeight: 392,
								width: 250,
							},
						},
					}}
				>
					<MenuItem value="all">
						<Checkbox
							value="all"
							checked={!checkedSurgeons.length}
							color="primary"
							data-field="surgeonMultiselect-option-all"
						/>
						<ListItemText primary={`All Preferred (${surgeonList.length})`} />
					</MenuItem>
					{!!options?.length && (
						options.map(o =>
							<MenuItem key={o.npi} value={o.npi}>
								<Checkbox
									value={o.npi}
									checked={checkedSurgeons.includes(o.npi)}
									color="primary"
									data-field={`surgeonMultiselect-option-${o.npi}`}
								/>
								<ListItemText primary={o.displayName} />
							</MenuItem>
						)
					)}
				</Select>
			</FormControl>
		</Box>
	);
};

export default SurgeonMultiSelect;
