import { HoldEventDetails } from '@data/holdEvent/types/HoldEventDetails';

export function isHoldEventDetailsRequest(u: unknown): u is HoldEventDetailsRequest {
	return !!(u && typeof u === 'object' && 'hospitalId' in u && 'startTime' in u);
}

interface HoldEventDetailsRequest extends Omit<HoldEventDetails, 'duration' | 'eventId'> {
	id?: string; // used for PUT request only
	hospitalId: string;
	unitId: string;
	roomId: string;
	hospitalTimeZone: string;
	startTime: string; // 'yyyy-MM-dd HH:mm', '2023-03-13 03:15' or '2023-03-13 16:30' - different from 12hr version in HoldEventDetails
	endTime: string; // 'yyyy-MM-dd HH:mm', '2023-03-13 03:15' or '2023-03-13 16:30'
	description: string; // Practice name
}

export default HoldEventDetailsRequest;
