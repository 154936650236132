import React, { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { useFormContext } from 'react-hook-form';
import MultiFormAdapterProps from '@components/RequestForm/utilities/MultiFormAdapter/MultiFormAdapterProps';
import MultiFormAdapterContextType
	from '@components/RequestForm/utilities/MultiFormAdapter/MultiFormAdapterContextType';
import CommonRequestForm from '@interfaces/RequestForm/forms/CommonRequestForm';

const APPOINTMENT_TYPE_KEY: keyof CommonRequestForm = 'appointmentType';

const MultiFormAdapterContext = React.createContext<MultiFormAdapterContextType | null>(null);

const useMultiFormAdapterContext = () => {
	const ctx = React.useContext(MultiFormAdapterContext);
	if (!ctx) { throw new Error(`useMultiFormValue must be used within a child of the MultiFormAdapter component.`); }
	return ctx;
};

export function useMultiFormValueSetter<T extends keyof CommonRequestForm>(name: T): Dispatch<SetStateAction<unknown>> {
	const ctx = useMultiFormAdapterContext();

	const { setValue } = useFormContext<Partial<CommonRequestForm>>();
	const { commonPropertiesRef } = ctx;

	return React.useCallback((setter: SetStateAction<unknown>) => {
		const val: unknown = typeof setter === 'function' ? setter(commonPropertiesRef.current[name]) : setter;
		commonPropertiesRef.current[name] = val as Partial<CommonRequestForm>[T];
		setValue(name, val as never, { shouldDirty: true });
	}, [commonPropertiesRef, name, setValue]);
}

const useAutoMultiForm = (shouldAutoUpdate: boolean, commonPropertiesRef: MutableRefObject<Partial<CommonRequestForm>>) => {
	const { reset, watch } = useFormContext();

	const { [APPOINTMENT_TYPE_KEY]: appointmentType } = commonPropertiesRef.current;

	React.useEffect(() => {
		if (!shouldAutoUpdate) { return; }
		reset(commonPropertiesRef.current, { keepDirtyValues: true });
	}, [appointmentType, commonPropertiesRef, reset, shouldAutoUpdate]);

	const currentVals = watch();
	React.useEffect(() => {
		commonPropertiesRef.current = currentVals;
	}, [commonPropertiesRef, currentVals]);
};

//Should reset commonProperties ref every time request is submitted (formId changes) - i.e. a successful surgery request is made.
const useAutoClearForm = (formId: string, commonPropertiesRef: MutableRefObject<Partial<CommonRequestForm>>) => {
	React.useEffect(() => {
		return () => {
			commonPropertiesRef.current = {};
		};
	}, [commonPropertiesRef, formId]);

	return undefined;
};

const MultiFormAdapter: React.FC<MultiFormAdapterProps> = ({ children, formId, shouldAutoUpdate }) => {
	const commonPropertiesRef = React.useRef<Partial<CommonRequestForm>>({});
	useAutoMultiForm(shouldAutoUpdate, commonPropertiesRef);
	useAutoClearForm(formId, commonPropertiesRef);

	return (
		<MultiFormAdapterContext.Provider value={{
			commonPropertiesRef,
		}}>
			{children}
		</MultiFormAdapterContext.Provider>
	);
};

export default MultiFormAdapter;
