import React from 'react';
import { AlertBox } from '@ascension/ui-library';
import { Typography } from '@material-ui/core';

const HeaderAlert: React.FC = ({ children }) => (
	<AlertBox
		translate="yes"
		leftBorderAccent
		severity="info"
	>
		<Typography>{children}</Typography>
	</AlertBox>
);

export default HeaderAlert;
