import { Box, Flex, Text } from '@ascension/web';
import React from 'react';
import { dateParse, getDayMonthDateFromString } from '../../../utilities/dateUtilities';
import { AlertBodyProps } from './AlertBodyProps';

export const AlertBody = ({
	alertUser,
	alertTime,
	alertMessage,
	body,
}: AlertBodyProps) => {
	const [, alertDisplayMonth, alertDisplayDate] = React.useMemo(
		() => alertTime ? getDayMonthDateFromString(alertTime, false) : [],
		[alertTime]);
	const [, alertDisplayTime, alertDisplayMeridiem] = React.useMemo(
		() => alertTime ? dateParse(alertTime) : [],
		[alertTime]);

	return (
		<Box px="24px" py="6px" className="alert-body">
			{(alertUser || alertTime) &&
			<Flex flexDirection="row" testId="alertBodyHeader">
				<Text
					fontSize={14}
					fontWeight={2}
					color="#222"
				>
					{alertUser}
				</Text>
				<Text
					fontSize={0}
					py={1}
					px={2}
					color="#777"
					fontStyle="italic"
					name="alert-display-time"
				>
					{alertDisplayTime}
					{' '}
					<Text textTransform="uppercase">
						{alertDisplayMeridiem}
					</Text>
					{' '}
					{alertDisplayMonth} {alertDisplayDate}
				</Text>
			</Flex>
			}
			{alertMessage &&
			<Flex flexDirection="row" testId="messageBodyHeader">
				<Text
					fontSize={14}
					fontWeight={1}
					color="#222"
				>
					{alertMessage}
				</Text>
			</Flex>
			}
			{body}
		</Box>
	);
};
