import React from 'react';
import { ScrollWithinBoxContext } from './state/ScrollWithinBoxContext';
import { ScrollWithinBoxProps } from './types/ScrollWithinBoxProps';
import { useScrollToFocus } from './utilities/useScrollToFocus';

export const ScrollWithinBox: React.FC<ScrollWithinBoxProps> = ({
	children,
	scrollWhenPropUpdates,
	...rest
}) => {
	const {
		scrollBoxRef,
		scrollIntoFocus,
		setScrollInfo,
		setRelativeScrollInfo,
	} = useScrollToFocus();

	React.useLayoutEffect(() => {
		scrollIntoFocus();
	}, [scrollIntoFocus, scrollWhenPropUpdates]);

	return (<div ref={scrollBoxRef} {...rest}>
		<ScrollWithinBoxContext.Provider value={{
			scrollIntoFocus,
			setScrollInfo,
			setRelativeScrollInfo,
		}}>
			{children}
		</ScrollWithinBoxContext.Provider>
	</div>);
};
