import { ManagedByMessage } from '@components/ManagedByMessage/ManagedByMessage';
import React from 'react';
import useCaseEditLockName from '@components/CaseDetails/CaseEditLockMessage/useCaseEditLockName';

const CaseEditLockMessage = () => {
	const editorName = useCaseEditLockName();

	return <ManagedByMessage managedByName={editorName} />;
};

export default CaseEditLockMessage;
