import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { UserRegistrationFormDefaultValues, UserRegistrationFormState } from '@components/UserRegistrationPage/UserRegistration';
import RegistrationFormContainer from '@components/UserRegistrationPage/RegistrationFormContainer/RegistrationFormContainer';
import { Box, Paper, Typography } from '@material-ui/core';
import BasicUserInfoForm from '@components/UserRegistrationPage/BasicUserInfoForm/BasicUserInfoForm';
import { makeStyles } from '@material-ui/core/styles';
import {
	ManageUsersListContextProvider,
	useManageUsersListContext,
} from '@components/ManageUsersList/ManageUsersListContext/ManageUsersListContext';
import { ManagedUser } from '@components/ManageUsersList/ManageUsersListContext/ManageUsersListContextType';
import { UserRegistrationRole, UserRole } from '@interfaces/UserProfile/UserRoleEnum';
import BlueOutlineButton from '@components/BlueOutlineButton/BlueOutlineButton';
import GoldButton from '@components/GoldButton/GoldButton';
import { deepObjectEquality } from '@utilities/deepObjectEquality';
import useGetEditUserIdFromPathname from '@components/ManageUsersList/utilities/useGetEditUserIdFromPathname';
import { useHistory } from 'react-router-dom';
import { ModalContextProvider } from '@components/Modal/ModalContextProvider';
import { ROUTE_CONSTANTS } from '@utilities/RouteConstants';

const PAGE_WIDTH = '718px';

const useStyles = makeStyles((theme) => ({
	formContainer: {
		minHeight: '759px',
		width: PAGE_WIDTH,
		margin: theme.spacing(3, 'auto'),
		padding: theme.spacing(4),
		boxSizing: 'border-box',
	},
	alignRight: {
		display: 'flex',
		justifyContent: 'flex-end',
		gap: theme.spacing(2),
	},
}));

export const mapRoleToRadioValue: Partial<Record<UserRole, UserRegistrationRole>> = {
	[UserRole.OFFICE_SCHEDULER]: UserRegistrationRole.OFFICE_SCHEDULER,
	[UserRole.KSWIC_OFFICE_SCHEDULER]: UserRegistrationRole.OFFICE_SCHEDULER,
	[UserRole.MIROC_OFFICE_SCHEDULER]: UserRegistrationRole.OFFICE_SCHEDULER,

	[UserRole.OFFICE_MANAGER]: UserRegistrationRole.OFFICE_MANAGER,
	[UserRole.KSWIC_OFFICE_MANAGER]: UserRegistrationRole.OFFICE_MANAGER,
	[UserRole.MIROC_OFFICE_MANAGER]: UserRegistrationRole.OFFICE_MANAGER,

	[UserRole.OFFICE_VIEWER]: UserRegistrationRole.OFFICE_VIEWER,
	[UserRole.KSWIC_OFFICE_VIEWER]: UserRegistrationRole.OFFICE_VIEWER,
	[UserRole.MIROC_OFFICE_VIEWER]: UserRegistrationRole.OFFICE_VIEWER,
};

const userRegistrationApiToFormState = (values: ManagedUser | undefined): UserRegistrationFormState => {
	if (!values) return UserRegistrationFormDefaultValues;
	return {
		...values,
		userRole: values.userRoles && mapRoleToRadioValue[values.userRoles[0]] || null,
		lastFourSsn: values.lastFourSsn || '',
	};
};

interface EditUserButtonsProps {
	disableSubmit: boolean;
}
const disabledBtnStyles = { color: 'white', cursor: 'not-allowed' };
const EditUserButtons: React.FC<EditUserButtonsProps> = ({ disableSubmit }) => {
	const history = useHistory();
	const handleCancel = () => history.push(ROUTE_CONSTANTS.MANAGE_USERS);

	return (
		<>
			<BlueOutlineButton
				data-field="reset-edit-user-form"
				style={{ background: 'inherit' }}
				onClick={handleCancel}
			>
				Cancel
			</BlueOutlineButton>
			<GoldButton
				data-field="submit-edit-user-form"
				type="submit"
				disabled={disableSubmit}
				style={ disableSubmit ? { ...disabledBtnStyles } : undefined }
			>
				Send Request
			</GoldButton>
		</>
	);
};

const AddUserButton = () => <GoldButton type="submit" data-field="submit-add-user-form">Submit user</GoldButton>;

interface UserRegistrationFormProps {
	isEdit: boolean;
}

const UserRegistrationFormInner: React.FC<UserRegistrationFormProps> = ({ isEdit }) => {
	const classes = useStyles();
	const { users } = useManageUsersListContext();
	const userId = useGetEditUserIdFromPathname();
	const userInfo = React.useMemo(() => {
		return users?.find((user) => {
			return user.userId === userId;
		});
	}, [users, userId]);

	const formMethods = useForm<UserRegistrationFormState>({
		mode: 'onBlur',
		defaultValues: UserRegistrationFormDefaultValues,
	});

	const { reset, watch } = formMethods;

	const handleResetEditForm = React.useCallback(() => {
		return reset({ ...userRegistrationApiToFormState(userInfo) });
	}, [reset, userInfo]);

	React.useEffect(() => {
		// reset form with edit values if userInfo found based on pathname
		handleResetEditForm();
	}, [handleResetEditForm]);

	const currentValues = watch();
	const editFormValuesLoaded = React.useMemo(() => (
		!deepObjectEquality(currentValues, UserRegistrationFormDefaultValues)
	), [currentValues]);
	const initialEditFormState = React.useRef<UserRegistrationFormState>(UserRegistrationFormDefaultValues);
	const [shouldDisableSubmit, setShouldDisableSubmit] = React.useState(false);
	const [formStateInitiated, setFormStateInitiated] = React.useState(false);
	React.useEffect(() => {
		// capture initial edit form state to determine when shouldDisableSubmit
		if (isEdit && editFormValuesLoaded && !formStateInitiated) {
			handleResetEditForm(); // this is necessary (again) to get conditional username field to initiate with value as expected
			initialEditFormState.current = { ...currentValues };
			setFormStateInitiated(true);
			setShouldDisableSubmit(true);
		} else {
			// re-check if shouldDisableSubmit every time currentValues changes
			setShouldDisableSubmit(deepObjectEquality(currentValues, initialEditFormState.current));
		}
	}, [editFormValuesLoaded, currentValues, formStateInitiated, handleResetEditForm, isEdit]);

	return (
		<FormProvider {...formMethods}>
			<RegistrationFormContainer isEdit={isEdit}>
				<Paper className={classes.formContainer}>
					<Typography variant="h2" component="h2">
						User information
					</Typography>
					<BasicUserInfoForm/>
				</Paper>
				<Box className={classes.alignRight}>
					{ isEdit ?
						<EditUserButtons disableSubmit={shouldDisableSubmit}/>
						:
						<AddUserButton/>
					}
				</Box>
			</RegistrationFormContainer>
		</FormProvider>
	);
};

const UserRegistrationForm: React.FC<UserRegistrationFormProps> = ({ isEdit }) => {
	return (
		<ManageUsersListContextProvider>
			<ModalContextProvider>
				<UserRegistrationFormInner isEdit={isEdit}/>
			</ModalContextProvider>
		</ManageUsersListContextProvider>
	);
};

export default UserRegistrationForm;
