import React from 'react';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import AppointmentTypeRadios from '@components/RequestForm/inputs/AppointmentTypeRadios';
import useDoesSelectedUnitSupportAppointmentTypes from '@components/RequestForm/utilities/useDoesSelectedUnitSupportAppointmentTypes';
import { ATTACHMENT_REQUEST_TYPES } from '@utilities/constants';
import ComparisonOption from '@utilities/arrays/ComparisonOption';
import RequestDetailsProps from '@components/RequestForm/formSections/RequestDetailsProps';
import { PrimarySurgeon } from '@components/RequestForm/inputs/PrimarySurgeon/PrimarySurgeon';
import { FindATimeAccordion } from '@components/RequestForm/FindATime/FindATimeAccordion/FindATimeAccordion';
import { FindATimeButton } from '@components/RequestForm/FindATime/FindATimeButton/FindATimeButton';
import { RequestedDate } from '@components/RequestForm/inputs/RequestedDate/RequestedDate';
import { RequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import { RequestFormProcedureName } from '@components/RequestForm/inputs/RequestFormProcedureName/RequestFormProcedureName';
import { Duration } from '@components/RequestForm/inputs/Duration/Duration';
import {
	useDoesUnitSupportOpenTime
} from '@components/RequestForm/utilities/hooks/useDoesUnitSupportOpenTime/useDoesUnitSupportOpenTime';
import {
	CustomProcedureInput
} from '@components/RequestForm/inputs/ProcedureName/CustomProcedureInput/CustomProcedureInput';
import { AnchorProvider } from '@components/AnchorProvider/AnchorProvider';
import { RequestFormLocation } from '@components/RequestForm/RequestFormLocation/RequestFormLocation';
import { useRequestFormContext } from '@components/RequestForm/RequestForm';
import {
	useEditBlockOverlapValidation
} from '@components/RequestForm/utilities/hooks/useCheckBlockTimeOverlap/useEditBlockOverlapValidation/useEditBlockOverlapValidation';

const useRequestDetailsStyles = makeStyles((theme) => ({
	sectionLayout: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(4),
	},
	blockNameLabel: {
		fontSize: '12px',
		textTransform: 'uppercase',
		letterSpacing: '1px',
		fontWeight: 600,
		marginBottom: '5px',
	},
	dateSurgeonRow: {
		'display': 'flex',
		'flexDirection': 'row',
		'justifyContent': 'space-evenly',
		'flexWrap': 'wrap',
		'gap': theme.spacing(3),
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
		'& > *': {
			flex: 1,
		},
	},
	procedureDurationRow: {
		'display': 'flex',
		'flexDirection': 'row',
		'gap': theme.spacing(3),
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
		'& > *:first-child': {
			flex: '0 1 65%',
		},
		'& > *:not(:first-child)': {
			flex: '0 1 35%',
		},
	},
	instructionText: {
		color: theme.palette.grey[600],
	},
	requiredText: {
		color: theme.palette.error.main,
	},
	procedureInput: {
		background: theme.palette.common.white,
	},
}));

const RequestDetails: React.FC<RequestDetailsProps> = ({ canChangeAppointmentType }) => {
	const classes = useRequestDetailsStyles();

	const { watch, setValue } = useFormContext<RequestFormValidatedState>();
	const [appointmentLocation] = watch(['appointmentLocation']);

	const shouldShowAppointmentTypeRadios =
		useDoesSelectedUnitSupportAppointmentTypes(ATTACHMENT_REQUEST_TYPES, ComparisonOption.EXACT)
		&& canChangeAppointmentType;

	const fatEnabled = useDoesUnitSupportOpenTime();

	const { isEdit } = useRequestFormContext();

	const doesBlockOverlap = useEditBlockOverlapValidation();
	React.useEffect(() => {
		setValue('appointmentBlockTime', !!doesBlockOverlap);
	}, [doesBlockOverlap, setValue]);

	return <Box data-field="requestForm-requestDetailsSection" className={classes.sectionLayout} >
		<Typography className={classes.instructionText}>
			Requested date/time must be at least 48 hours from now. For sooner, call Scheduling Support.
		</Typography>
		<Typography className={classes.requiredText}>
			* Indicates required fields
		</Typography>

		<RequestFormLocation />

		{ appointmentLocation &&
			<>
				<Box className={classes.dateSurgeonRow}>
					<RequestedDate />
					<PrimarySurgeon
						dataField="requestForm-requestDetails-primarySurgeonSelect"
					/>
				</Box>
				{fatEnabled &&
					<AnchorProvider>
						<Box className={classes.procedureDurationRow}>
							<RequestFormProcedureName />
							<Duration
								autoUpdate={!isEdit}
								label="Total case duration in minutes"
								dataField="requestForm-requestDetails-durationTextInput"
							/>
						</Box>

						<CustomProcedureInput />
					</AnchorProvider>
				}
				<FindATimeButton />
				<FindATimeAccordion />
				{shouldShowAppointmentTypeRadios && <AppointmentTypeRadios />}
			</>
		}
	</Box>;
};

export default RequestDetails;
