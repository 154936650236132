import React from 'react';
import { StatefulTextInput } from '@components/StatefulInputs/StatefulTextInput/StatefulTextInput';
import { OutlinedInputProps } from '@material-ui/core';
import { FieldValues } from 'react-hook-form';
import { BaseStatefulInputProps } from '@components/StatefulInputs/Base/BaseStatefulInputProps';

interface ProcedureNameInputProps<FormType extends FieldValues> extends Pick<BaseStatefulInputProps<FormType>, 'controllerOptions' | 'name'> {
	label: string;
	inputProps?: OutlinedInputProps;
	shouldShowError?: boolean;
	dataField: string;
}

export function ProcedureNameInput<FormType extends FieldValues>({ name, label, inputProps, controllerOptions, shouldShowError, dataField }: ProcedureNameInputProps<FormType>) {
	return (
		<StatefulTextInput<FormType>
			controllerOptions={controllerOptions}
			label={label}
			name={name}
			shouldShowError={shouldShowError}
			styleAsRequired={true}
			textInputProps={{
				...inputProps,
			}}
			dataField={dataField}
		/>
	);
};
