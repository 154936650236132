import { createStyles, makeStyles } from '@material-ui/core/styles';
import { LEGACY_MAX_WIDTH } from './themeConstants';

export const maxWidthContainerStyle = () => ({
	maxWidth: LEGACY_MAX_WIDTH,
	width: '100%',
});

const useStyles = makeStyles(() => createStyles({
	maxWidthContainer: maxWidthContainerStyle(),
}));

/***
 * useMaxWidthContainerStyle hook can be used to retrieve a class that consistently
 * provides a maxWidth based on the theme.
 *
 * @example
 * const MyComponent = () => {
 *     const maxWidthContainer = useMaxWidthContainerStyle();
 *     return <>
 *         <Box className={maxWidthContainer}>Box width is 100% with limited max width.</Box>
 *     </>;
 * };
 */
const useMaxWidthContainerStyle = () => {
	const { maxWidthContainer } = useStyles();
	return maxWidthContainer;
};

export default useMaxWidthContainerStyle;
