import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from '@material-ui/core';
import { Button, Flex, Text } from '@ascension/web';
import { useSaveFiltersContext } from './SaveFiltersContext/SaveFiltersContext';
import { hospitalsListFromSavedUnits, practicesListFromSavedSurgeons } from '@utilities/filtersUtilities';
import {
	updateHospitalsInHospitalUserProfile,
	updatePracticesInOfficeUserProfile
} from '@store/actions/UserProfileActionCreators';
import { ToastType } from '@interfaces/ToastStatus/ToastStatusState';
import { ToastConstants } from '@utilities/toastConstants';
import { useToast } from '@utilities/hooks/useToast/useToast';
import {
	UserProfileHasARoleSelector,
	UserProfilePrimaryHospitalSelectorId,
	UserProfilePrimaryPracticeSelectorId
} from '@store/selectors/UserProfileSelector';
import { OfficeRoles } from '@interfaces/UserProfile/UserRoleEnum';
import { RootState } from '@interfaces/rootState';
import { FullSurgeonsSelector } from '@store/selectors/SurgeonListSelector';
import { MyFiltersSelector } from '@store/selectors/MyFiltersSelector';
import { MyFiltersDefaultValues } from './MyFiltersDefaultValues';
import { StickyContainer } from '../../StickyContainer/StickyContainer';
import FormError from '../../FormComponents/FormError/FormError';
import { getAllUnitListData } from '@store/actions/UnitListActionCreators';
import { CacheType } from '@interfaces/CacheType';
import { MyFiltersState } from '@interfaces/MyFilters/MyFiltersState';
import { getSurgeonListData } from '@store/actions/SurgeonListActionCreators';

const useStyles = makeStyles((theme) => ({
	resetButton: {
		fontSize: '14px',
		fontWeight: 'bold',
		cursor: 'pointer',
		color: `${theme.palette.primary.main}`,
	},
	disabled: {
		cursor: 'not-allowed',
		color: 'rgba(0, 0, 0, 0.5)',
	}
}));

export default function MyFiltersButtons() {
	const dispatch = useDispatch();
	const displayToast = useToast();
	const { resetButton, disabled } = useStyles();
	const defaultFilters = MyFiltersDefaultValues;
	const lastSavedFilters = useSelector(MyFiltersSelector);

	const { disableSaveAndCancel, isFormStateSameAsDefaultState, reset } = useSaveFiltersContext();

	const handleReset = () => {
		reset(defaultFilters);
	};

	const handleCancel = () => {
		reset(lastSavedFilters);
	};

	const { getValues, formState: { errors } } = useFormContext<MyFiltersState>();
	const isOfficeRole = useSelector(UserProfileHasARoleSelector(OfficeRoles));
	const primaryHospitalId = useSelector(UserProfilePrimaryHospitalSelectorId);
	const primaryPracticeId = useSelector(UserProfilePrimaryPracticeSelectorId);
	const fullUnitList = useSelector((state: RootState) => state.unitList.groupedUnitList);
	const fullSurgeonList = useSelector(FullSurgeonsSelector);

	const handleSaveFilters = () => {
		const formState = getValues();
		const { unit: selectedUnits, surgeon: selectedSurgeons } = formState;
		const onSuccessCallback = () => {
			dispatch(getAllUnitListData({
				cachePolicy: CacheType.UPDATE_CACHE,
			}));
			dispatch(getSurgeonListData({
				cachePolicy: CacheType.UPDATE_CACHE,
			}));
			displayToast(ToastType.SUCCESS, ToastConstants.SUCCESSFULLY_SAVED_FILTERS);
		};
		const onFailureCallback = () => displayToast(ToastType.ERROR, ToastConstants.SAVE_ERROR);
		if (isOfficeRole) {
			const practices = practicesListFromSavedSurgeons(selectedSurgeons, fullSurgeonList, primaryPracticeId);
			dispatch(updatePracticesInOfficeUserProfile(formState, practices, onSuccessCallback, onFailureCallback));
		}
		else {
			const hospitals = hospitalsListFromSavedUnits(selectedUnits, fullUnitList, primaryHospitalId);
			dispatch(updateHospitalsInHospitalUserProfile(formState, hospitals, onSuccessCallback, onFailureCallback));
		}
	};

	const resetClasses = React.useMemo(() => isFormStateSameAsDefaultState ?
		[resetButton, disabled] :
		resetButton,
	[isFormStateSameAsDefaultState, disabled, resetButton]);

	const isInvalid = React.useMemo(() => !!Object.keys(errors).length, [errors]);

	return (
		<StickyContainer>
			{isInvalid && <FormError>Please check the page for errors.</FormError>}
			<Flex
				alignItems="center"
				backgroundColor="#FFF"
				justifyContent="flex-end"
				flexDirection="row"
			>
				<Text
					mr="5"
					data-field="resetMyFilters"
					className={resetClasses}
					onClick={handleReset}
				>
					RESET TO DEFAULT
				</Text>
				<Button
					secondary
					disabled={disableSaveAndCancel}
					className="margin-right-10"
					id="cancel"
					data-field="cancelMyFilters"
					onClick={handleCancel}
				>
					Cancel
				</Button>
				<Button
					id="saveInfo"
					disabled={disableSaveAndCancel || isInvalid}
					onClick={handleSaveFilters}
				>
					Save
				</Button>
			</Flex>
		</StickyContainer>
	);
}
