import React from 'react';
import { SetupCardSection } from '../SetupCardSection/SetupCardSection';
import { MyNotificationsCheckbox } from '../../AccountSettings/MyNotifications/MyNotificationsCheckbox/MyNotificationsCheckbox';
import { MyNotificationsCheckboxProps } from '../../AccountSettings/MyNotifications/MyNotificationsCheckbox/MyNotificationsCheckboxProps';

export const SetupMyNotifications = ({ checked, onCheck }: MyNotificationsCheckboxProps) => {
	return (
		<SetupCardSection sectionTitle="My Notifications">
			<MyNotificationsCheckbox checked={checked} onCheck={onCheck}/>
		</SetupCardSection>
	);
};
