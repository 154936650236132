import React from 'react';
import { ProcedureNameMainInput } from '@components/RequestForm/inputs/ProcedureName/ProcedureNameMainInput/ProcedureNameMainInput';
import { ProcedureNameSelectProps } from '@components/RequestForm/inputs/ProcedureName/ProcedureNameSelect/ProcedureNameSelectProps';
import { ProcedureNamePopover } from '@components/RequestForm/inputs/ProcedureName/ProcedureNamePopover/ProcedureNamePopover';

export const ProcedureNameSelect: React.FC<ProcedureNameSelectProps> = ({ onOptionClick, label, dataField }) => {
	return (
		<>
			<ProcedureNameMainInput label={label} dataField={dataField}/>
			<ProcedureNamePopover onOptionClick={onOptionClick} />
		</>
	);
};
