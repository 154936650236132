import StartEndDate from '@interfaces/StartEndTime';
import { differenceInMinutes } from 'date-fns';

/***
 *
 * @param startEnd {StartEndDate} - when either value is undefined, result is undefined.
 *
 * @returns {undefined | number}
 *  - undefined when either start or end undefined or invalid format (not 12hr string)
 */
export const calculateDuration = (startEnd: StartEndDate | undefined): number | undefined => {
	if (!startEnd) {
		return;
	}
	const { startTime, endTime } = startEnd;

	if (!startTime || !endTime || startTime > endTime) { return; }

	return differenceInMinutes(endTime, startTime);
};
