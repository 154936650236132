import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import IconMessage from '@components/IconMessage/IconMessage';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Typography } from '@material-ui/core';
import {
	Box,
} from '@material-ui/core';


const useStyles = makeStyles((theme) => createStyles({
	emptySchedulesContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center'
	},
	emptySchedulesMessage: {
		'paddingBottom': theme.spacing(1.25),
		'maxWidth': theme.breakpoints.values.sm,
		'marginTop': theme.spacing(6),
		'& .MuiTypography-root': {
			lineHeight: 1,
		}
	},
}));

const EmptyScheduleMessage = () => {
	const classes = useStyles();

	return (
		<Box className={classes.emptySchedulesContainer}>
			<IconMessage
				className={classes.emptySchedulesMessage}
				icon={InfoOutlinedIcon}>
				<Typography variant="h5">There are no blocks available on this day for the selected Surgeons.</Typography>
			</IconMessage>
		</Box>
	);
};

export default EmptyScheduleMessage;
