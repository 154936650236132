import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, BoxProps, createStyles } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles(() =>
	createStyles({
		footer: {
			position: 'sticky',
			bottom: 0
		},
	}),
);

export const  CustomPopoverFooter: React.FC<BoxProps> = ({ children, className }) => {
	const classes = useStyles();

	return (
		<Box className={classNames(classes.footer, className)}>
			{children}
		</Box>
	);
};
