import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Box, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { AxiosResponse } from 'axios';
import theme from '@theme/theme';
import { fetchTermsOfUse } from '@store/actions/UtilityActionCreators';

const useStyles = makeStyles({
	modalHeader: {
		fontWeight: 600,
		paddingBottom: theme.spacing(4),
	},
	readMeClass: {
		'& h3': {
			textAlign: 'center',
			fontSize: '20px'
		},
		'& p': {
			textAlign: 'justify',
			fontSize: '16px'
		},
		'& h4': {
			fontSize: '16px',
			textDecoration: 'underline'
		},
		'& h5': {
			fontSize: '16px'
		}
	}
});

export default function TermsOfUseContent() {
	const dispatch = useDispatch();
	const [termsAndConditions = '', setTermsAndConditions] = React.useState<string>();
	const classes = useStyles();

	React.useEffect(() => {
		const onSuccess = (response: AxiosResponse<Blob>) => {
			void response.data.text().then((text: string) => {
				setTermsAndConditions(text);
			});
		};
		dispatch(fetchTermsOfUse(onSuccess));
	}, [dispatch]);

	return (
		<Box className={classes.readMeClass}>
			<Typography component="h1" variant="h5" className={classes.modalHeader}>
				EULA & Terms of Use
			</Typography>
			<Divider variant="fullWidth"/>
			<ReactMarkdown disallowedTypes={[]}>{termsAndConditions}</ReactMarkdown>
		</Box>
	);
}
