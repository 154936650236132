import React from 'react';
import classNames from 'classnames';
import { Box, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DeleteOutlined, EditOutlined } from '@material-ui/icons';
import { ProcedureTileProps, SurgeryProcedureInterface } from '@components/RequestForm/SurgeryInformation/SurgeryInformationInterface';
import RequestSurgeryProcedureForm from '@components/RequestForm/SurgeryInformation/RequestSurgeryProcedureForm/RequestSurgeryProcedureForm';
import ModalViewer from '@components/Modal/ModalViewer';
import { useModalContext } from '@components/Modal/ModalContextProvider';
import NoParamNoReturnCallback from '@interfaces/NoParamNoReturnCallback';
import { SURGERY_PROCEDURE_TILE_MODAL } from '@components/Modal/modalConstants';
import { ProcedureCard } from '@components/ProcedureCard/ProcedureCard';
import TextLink from '@components/TextLink/TextLink';
import CommonRequestForm from '@interfaces/RequestForm/forms/CommonRequestForm';
import { useFormContext } from 'react-hook-form';

const useStyles = makeStyles(theme => createStyles({
	buttonWrapper: {
		alignItems: 'center',
		display: 'flex',
		flex: 0,
		flexDirection: 'row',
		justifyContent: 'start',
	},
	deleteButton: {
		marginRight: theme.spacing(1),
	},
	modalWrapper: {
		maxHeight: '90vh',
	},
	textLink: {
		'borderBottomColor': 'transparent',
		'borderRadius': '20px',
		'padding': theme.spacing(.75, 2.5),
		'&:hover, &:focus': {
			borderBottomColor: 'transparent',
			backgroundColor: 'transparent',
			color: theme.palette.primary[800],
		},
		'&.MuiButton-root .MuiButton-label': {
			fontSize: '1rem',
		},
	},
}));

interface MenuComponentProps {
	onEditClick: NoParamNoReturnCallback;
	onRemoveClick: (id: number) => void;
	index: number;
}

function MenuComponent({ onEditClick, index, onRemoveClick }: MenuComponentProps) {
	const classes = useStyles();

	const injectIdToCallback = (cb: ((id: number) => void) | (() => void)) => {
		return cb && (() => cb(index));
	};

	return (
		<Box className={classes.buttonWrapper}>
			<TextLink
				className={classNames(classes.textLink, classes.deleteButton)}
				data-field={'Delete' + index}
				onClick={injectIdToCallback(onRemoveClick)}
				prefixIcon={DeleteOutlined}
			>
				Delete
			</TextLink>
			<TextLink
				className={classes.textLink}
				data-field={'Edit' + index}
				onClick={injectIdToCallback(onEditClick)}
				prefixIcon={EditOutlined}
			>
				Edit
			</TextLink>
		</Box>
	);
}

const ProcedureTile = ({ surgeryProcedureInfo, setSurgeryProcedureInfo, index }: ProcedureTileProps) => {
	const classes = useStyles();
	const { closeModal, openModal } = useModalContext();
	const surgeryProcedureTileModalId = SURGERY_PROCEDURE_TILE_MODAL + index;

	const handleEditClick = React.useCallback(() => {
		openModal(surgeryProcedureTileModalId);
	}, [openModal, surgeryProcedureTileModalId]);

	const removeSurgeryCard = React.useCallback((idx: number) => {
		setSurgeryProcedureInfo((prev: SurgeryProcedureInterface[]) => (prev.filter((_, i) => i !== idx)));
	}, [setSurgeryProcedureInfo]);

	const { watch } = useFormContext<CommonRequestForm>();
	const [primarySurgeon] = watch(['primarySurgeon']);

	// This is to modify the surgeryProcedureInfo to set isPrimaryProcedure to null if the surgeon is not the primary surgeon
	const modifiedSurgeryProcedureInfo = React.useMemo(() => {
		return {
			...surgeryProcedureInfo,
			isPrimaryProcedure:
				surgeryProcedureInfo.isPrimaryProcedure === 1 && surgeryProcedureInfo.surgeon.value !== primarySurgeon ?
					null :
					surgeryProcedureInfo.isPrimaryProcedure
		};
	}, [primarySurgeon, surgeryProcedureInfo]);

	return (
		<Box>
			<ModalViewer id={surgeryProcedureTileModalId} className={classes.modalWrapper}>
				<Box width={800} minHeight={300}>
					<RequestSurgeryProcedureForm
						close={() => closeModal(surgeryProcedureTileModalId)}
						surgeryProcedure={modifiedSurgeryProcedureInfo}
						setSurgeryProcedure={setSurgeryProcedureInfo}
						surgeryProcedureIndex={index}
					/>
				</Box>
			</ModalViewer>
			<ProcedureCard
				id={index}
				info={surgeryProcedureInfo}
				menuComponent={<MenuComponent index={index} onEditClick={handleEditClick} onRemoveClick={removeSurgeryCard}/>}
			/>
		</Box>
	);
};

export default ProcedureTile;
