import { createStyles, makeStyles } from '@material-ui/core/styles';
import DetailSection from '../DetailSection';

type GridSection = Exclude<DetailSection, DetailSection.SECONDARY_INSURANCE>;

interface GridAreasConfigInterface {
	expanded: string;
	modal: string;
	condensed: string;
}

export enum AppointmentGridFields {
	ROOM = 'room',
	LOCATION = 'location',
	DATE = 'date',
	TIME = 'time',
	DURATION = 'duration',
	ORDER_STATUS = 'order_status',
	CPT_CODES = 'cpt_codes',
	PRIMARY_DIAGNOSIS = 'primary_diagnosis',
	PRE_CERT = 'pre_cert',
}

export enum PatientGridFields {
	NAME = 'name',
	DOB = 'dob',
	GENDER = 'gender',
	SSN = 'ssn',
	PHONE ='phone',
	EMAIL ='email',
	TRANSLATOR_NEEDED = 'translator_needed',
	LATEX_ALLERGY = 'latex_allergy',
	IODINE_ALLERGY = 'iodine_allergy',
	OTHER_DETAILS = 'other_details',
	ADDRESS_LINE_1 = 'address_line_1',
	ADDRESS_LINE_2 = 'address_line_2',
	CITY = 'city',
	STATE = 'state',
	ZIP = 'zip',
}

export enum InsuranceGridFields {
	PLAN_NAME = 'plan_name',
	INSURED_NAME = 'insured_name',
	POLICY_NUM = 'policy_num',
	GROUP_NUM = 'group_num',
	PRE_AUTH = 'pre_auth',
	PRE_CERT = 'pre_cert',
}

export enum ProcedureGridFields {
	PROC_NAME = ' proc_name',
	SURGEON = 'surgeon',
	CPT_CODES = 'cpt_codes',
	PRIM_PROC = 'prim_proc',
	MODIFIER = 'modifier',
	APPROACH = 'approach',
	ANESTHESIA = 'anesthesia',
	IMPLANTS_NEEDED = 'implants_needed',
	VENDOR = 'vendor',
	VENDOR_CONTACT = 'vendor_contact',
	ROBOTIC = 'robotic',
	EQUIPMENT = 'equipment',
	OTHER_EQUIPMENT = 'other_equipment',
}

export enum PatGridFields {
	BED_NEEDED = 'bed_needed',
	DATE = 'date',
	LOCATION = 'location',
	PAT_NEEDED = 'pat_needed',
}

export enum AttachmentGridFields {
	ACTIONS = 'actions',
	DATE_UPLOADED = 'createdAt',
	DOCUMENT_NAME = 'file',
	UPLOADED_BY = 'createdBy',
	STATUS = 'status',
}

const DetailSectionGridConfig: Record<GridSection, GridAreasConfigInterface> = {
	[DetailSection.APPOINTMENT]: {
		expanded: `
			"${AppointmentGridFields.LOCATION} ${AppointmentGridFields.ROOM} . ."
			"${AppointmentGridFields.DATE} ${AppointmentGridFields.TIME} . ."
			"${AppointmentGridFields.DURATION} . . ."
			"${AppointmentGridFields.ORDER_STATUS} . . ."
			"${AppointmentGridFields.PRIMARY_DIAGNOSIS} ${AppointmentGridFields.PRIMARY_DIAGNOSIS} . ."
			"${AppointmentGridFields.CPT_CODES} ${AppointmentGridFields.CPT_CODES} ${AppointmentGridFields.CPT_CODES} ${AppointmentGridFields.CPT_CODES}"
			"${AppointmentGridFields.PRE_CERT} ${AppointmentGridFields.PRE_CERT} . ."
		`,
		modal: `
			"${AppointmentGridFields.LOCATION} ${AppointmentGridFields.ROOM} ${AppointmentGridFields.DATE} ${AppointmentGridFields.TIME}"
			"${AppointmentGridFields.DURATION} ${AppointmentGridFields.ORDER_STATUS} . ."
			"${AppointmentGridFields.PRIMARY_DIAGNOSIS} ${AppointmentGridFields.CPT_CODES} ${AppointmentGridFields.CPT_CODES} ${AppointmentGridFields.PRE_CERT}"
			
		`,
		condensed: `
			"${AppointmentGridFields.LOCATION} ${AppointmentGridFields.LOCATION}"
			"${AppointmentGridFields.ROOM} ${AppointmentGridFields.ROOM}"
			"${AppointmentGridFields.DATE} ${AppointmentGridFields.TIME}"
			"${AppointmentGridFields.DURATION} ."
			"${AppointmentGridFields.ORDER_STATUS} ."
			"${AppointmentGridFields.PRIMARY_DIAGNOSIS} ${AppointmentGridFields.PRIMARY_DIAGNOSIS}"
			"${AppointmentGridFields.CPT_CODES} ${AppointmentGridFields.CPT_CODES}"
			"${AppointmentGridFields.PRE_CERT} ${AppointmentGridFields.PRE_CERT}"
		`
	},
	[DetailSection.PATIENT]: {
		expanded: `
			"${PatientGridFields.NAME} ${PatientGridFields.NAME} . ."
			"${PatientGridFields.DOB} ${PatientGridFields.GENDER} . ."
			"${PatientGridFields.SSN} . . ."
			"${PatientGridFields.PHONE} ${PatientGridFields.EMAIL} . ."
			"${PatientGridFields.ADDRESS_LINE_1} ${PatientGridFields.ADDRESS_LINE_2} . ."
			"${PatientGridFields.ZIP} . . ."
			"${PatientGridFields.CITY} ${PatientGridFields.STATE} . ."
			"${PatientGridFields.TRANSLATOR_NEEDED} ${PatientGridFields.LATEX_ALLERGY} . ."
			"${PatientGridFields.IODINE_ALLERGY} . . ."
			"${PatientGridFields.OTHER_DETAILS} ${PatientGridFields.OTHER_DETAILS} ${PatientGridFields.OTHER_DETAILS} ${PatientGridFields.OTHER_DETAILS}"
		`,
		modal: `
			"${PatientGridFields.NAME} ${PatientGridFields.DOB} ${PatientGridFields.GENDER} ${PatientGridFields.SSN}"
			"${PatientGridFields.PHONE} ${PatientGridFields.EMAIL} ${PatientGridFields.TRANSLATOR_NEEDED} ${PatientGridFields.LATEX_ALLERGY}"
			"${PatientGridFields.ADDRESS_LINE_1} ${PatientGridFields.ADDRESS_LINE_2} ${PatientGridFields.ZIP} ."
			" ${PatientGridFields.CITY} ${PatientGridFields.STATE} . ."
			"${PatientGridFields.IODINE_ALLERGY} . . ."
			"${PatientGridFields.OTHER_DETAILS} ${PatientGridFields.OTHER_DETAILS} ${PatientGridFields.OTHER_DETAILS} ${PatientGridFields.OTHER_DETAILS}"
		`,
		condensed: `
			"${PatientGridFields.NAME} ${PatientGridFields.NAME}"
			"${PatientGridFields.DOB} ${PatientGridFields.GENDER}"
			"${PatientGridFields.SSN} ."
			"${PatientGridFields.PHONE} ${PatientGridFields.EMAIL}"
			"${PatientGridFields.ADDRESS_LINE_1} ${PatientGridFields.ADDRESS_LINE_1}"
			"${PatientGridFields.ADDRESS_LINE_2} ${PatientGridFields.ADDRESS_LINE_2}"
			"${PatientGridFields.ZIP} ${PatientGridFields.ZIP}"
			"${PatientGridFields.CITY} ${PatientGridFields.STATE}"
			"${PatientGridFields.TRANSLATOR_NEEDED} ${PatientGridFields.LATEX_ALLERGY}"
			"${PatientGridFields.IODINE_ALLERGY} ."
			"${PatientGridFields.OTHER_DETAILS} ${PatientGridFields.OTHER_DETAILS}"
		`
	},
	[DetailSection.PRIMARY_INSURANCE]: {
		expanded: `
			"${InsuranceGridFields.PLAN_NAME} ${InsuranceGridFields.PLAN_NAME} . ."
			"${InsuranceGridFields.INSURED_NAME} ${InsuranceGridFields.INSURED_NAME} . ."
			"${InsuranceGridFields.POLICY_NUM} ${InsuranceGridFields.GROUP_NUM} . ."
			"${InsuranceGridFields.PRE_AUTH} ${InsuranceGridFields.PRE_CERT} . ."
		`,
		modal: `
			"${InsuranceGridFields.PLAN_NAME} ${InsuranceGridFields.INSURED_NAME} ${InsuranceGridFields.POLICY_NUM} ${InsuranceGridFields.GROUP_NUM}"
			"${InsuranceGridFields.PRE_AUTH} ${InsuranceGridFields.PRE_CERT} . ."
		`,
		condensed: `
			"${InsuranceGridFields.PLAN_NAME} ${InsuranceGridFields.PLAN_NAME}"
			"${InsuranceGridFields.INSURED_NAME} ${InsuranceGridFields.INSURED_NAME}"
			"${InsuranceGridFields.POLICY_NUM} ${InsuranceGridFields.GROUP_NUM}"
			"${InsuranceGridFields.PRE_AUTH} ${InsuranceGridFields.PRE_CERT}"
		`
	},
	[DetailSection.PROCEDURE]: {
		expanded: `
			"${ProcedureGridFields.PROC_NAME} ${ProcedureGridFields.PROC_NAME} ${ProcedureGridFields.PROC_NAME} ${ProcedureGridFields.PROC_NAME}"
			"${ProcedureGridFields.SURGEON} ${ProcedureGridFields.SURGEON} . ."
			"${ProcedureGridFields.CPT_CODES} ${ProcedureGridFields.PRIM_PROC} . ."
			"${ProcedureGridFields.MODIFIER} ${ProcedureGridFields.APPROACH} . ."
			"${ProcedureGridFields.ANESTHESIA} ${ProcedureGridFields.ANESTHESIA} ${ProcedureGridFields.ANESTHESIA} ${ProcedureGridFields.ANESTHESIA}"
			"${ProcedureGridFields.IMPLANTS_NEEDED} ${ProcedureGridFields.VENDOR} ${ProcedureGridFields.VENDOR_CONTACT} ."
			"${ProcedureGridFields.ROBOTIC} . . ."
			"${ProcedureGridFields.EQUIPMENT} ${ProcedureGridFields.OTHER_EQUIPMENT} . ."
		`,
		modal: `
			"${ProcedureGridFields.PROC_NAME} ${ProcedureGridFields.SURGEON} ${ProcedureGridFields.CPT_CODES} ${ProcedureGridFields.PRIM_PROC}"
			"${ProcedureGridFields.MODIFIER} ${ProcedureGridFields.APPROACH} ${ProcedureGridFields.ANESTHESIA} ${ProcedureGridFields.IMPLANTS_NEEDED}"
			"${ProcedureGridFields.VENDOR} ${ProcedureGridFields.VENDOR_CONTACT} . ."
			"${ProcedureGridFields.ROBOTIC} . . ."
			"${ProcedureGridFields.EQUIPMENT} ${ProcedureGridFields.EQUIPMENT} ${ProcedureGridFields.OTHER_EQUIPMENT} ${ProcedureGridFields.OTHER_EQUIPMENT}"
		`,
		condensed: `
			"${ProcedureGridFields.PROC_NAME} ${ProcedureGridFields.PROC_NAME}"
			"${ProcedureGridFields.SURGEON} ${ProcedureGridFields.SURGEON}"
			"${ProcedureGridFields.CPT_CODES} ${ProcedureGridFields.PRIM_PROC}"
			"${ProcedureGridFields.MODIFIER} ${ProcedureGridFields.APPROACH}"
			"${ProcedureGridFields.ANESTHESIA} ${ProcedureGridFields.ANESTHESIA}"
			"${ProcedureGridFields.IMPLANTS_NEEDED} ."
			"${ProcedureGridFields.VENDOR} ${ProcedureGridFields.VENDOR_CONTACT}"
			"${ProcedureGridFields.ROBOTIC} ."
			"${ProcedureGridFields.EQUIPMENT} ${ProcedureGridFields.EQUIPMENT}"
			"${ProcedureGridFields.OTHER_EQUIPMENT} ${ProcedureGridFields.OTHER_EQUIPMENT}"
		`
	},
	[DetailSection.PAT]: {
		expanded: `
			"${PatGridFields.BED_NEEDED} . . ."
			"${PatGridFields.DATE} ${PatGridFields.LOCATION} . ."
			"${PatGridFields.PAT_NEEDED} . . ."
		`,
		modal: `
			"${PatGridFields.BED_NEEDED} ${PatGridFields.DATE} ${PatGridFields.LOCATION} ${PatGridFields.PAT_NEEDED}"
		`,
		condensed: `
			"${PatGridFields.BED_NEEDED} ."
			"${PatGridFields.DATE} ${PatGridFields.LOCATION}"
			"${PatGridFields.PAT_NEEDED} ."
		`
	},
	[DetailSection.ATTACHMENTS]: {
		expanded: ``,
		modal: ``,
		condensed: `
			"${AttachmentGridFields.STATUS} ."
			"${AttachmentGridFields.DATE_UPLOADED} ."
			"${AttachmentGridFields.UPLOADED_BY} ."
		`
	},
};

interface UseStylesProps {
	section: GridSection;
}
const useStyles = makeStyles((theme) => createStyles({
	gridContainer: ({ section }: UseStylesProps) => ({
		'display': 'grid',
		'gridTemplateColumns': 'repeat(4, 1fr)',
		'gridTemplateAreas': DetailSectionGridConfig[section].expanded,
		'columnGap': theme.spacing(3),
		'rowGap': theme.spacing(3.5),
		'.modal &': {
			gridTemplateAreas: DetailSectionGridConfig[section].modal
		},
		'.condensed-section &': {
			gridTemplateColumns: 'repeat(2, 1fr)',
			gridTemplateAreas: DetailSectionGridConfig[section].condensed
		},
	}),
}));
const useDetailSectionGrid = (section: GridSection) => {
	return useStyles({ section });
};

export default useDetailSectionGrid;
