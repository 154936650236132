import React from 'react';
import { Box, BoxProps, Divider } from '@material-ui/core';
import EventLegendItem from '@components/UnitCalendarPage/EventLegend/EventLegendItem/EventLegendItem';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import EventStyleConfig from '@interfaces/OpenCalendar/EventStyleConfig';
import EventConfig from '@calendar/types/EventConfig';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
		boxShadow: theme.shadows[5],
		gap: theme.spacing(3),
		padding: theme.spacing(3, 3, 3, 9),
		color: theme.palette.common.black,
	},
	divider: {
		height: '32px',
		width: '2px',
		backgroundColor: theme.palette.grey[600],
		marginLeft: 0,
		marginRight: theme.spacing(3),
	},
	itemWrapper: {
		display:'flex',
	},
}));

interface EventLegendProps<EventEnum> extends BoxProps {
	config: EventConfig<EventEnum>;
	styleConfig: EventStyleConfig<EventEnum>;
}

function EventLegend<EventEnum>({
	config,
	className,
	styleConfig,
	...boxProps
}: EventLegendProps<EventEnum>) {
	const classes = useStyles();

	const types = React.useMemo(
		() => Object.keys(config) as Array<EventEnum & string>,
		[config],
	);

	return (
		<Box className={classNames(classes.container, className)} {...boxProps}>
			{types.map((type) => {
				return (
					config[type].legendLabel && (
						<Box key={type} className={classes.itemWrapper}>
							{styleConfig[type].hasDivider && (
								<Divider
									className={classes.divider}
									orientation="vertical"
								/>
							)}
							<EventLegendItem
								type={type}
								config={config[type]}
								styleConfig={styleConfig[type].legend}
							/>
						</Box>
					)
				);
			})}
		</Box>
	);
}

export default EventLegend;
