import React from 'react';
import CaseIdentifierProps from './CaseIdentifierProps';
import { Box, Text } from '@ascension/web';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';
import useHasEhrIntegrationAndRole from '../../utilities/hooks/useHasEhrIntegrationAndRole/useHasEhrIntegrationAndRole';
import { HospitalRoles } from '../../interfaces/UserProfile/UserRoleEnum';

const useStyles = makeStyles((theme) => createStyles({
	caseIdentifier: {
		...theme.typography.subtext1,
		'marginLeft': theme.spacing(2),
		'&:first-child': {
			marginLeft: theme.spacing(0),
		},
	},
}));

const DEFAULT_VAL = '--';

const CaseIdentifier: React.FC<CaseIdentifierProps> = ({ caseDetails = {} }) => {
	const classes = useStyles();
	let { caseNumber, fin } = caseDetails;
	caseNumber = caseNumber || DEFAULT_VAL;
	fin = fin || DEFAULT_VAL;
	const isHospitalUserWithEhrIntegration = useHasEhrIntegrationAndRole(caseDetails, HospitalRoles);

	return <Box data-field="caseIdentifier" className="case-identifier-group">
		{isHospitalUserWithEhrIntegration && <Text
			className={classes.caseIdentifier}
			data-field="caseNumber"
		>
			Case Number: {caseNumber}
		</Text>}
		<Text
			className={classes.caseIdentifier}
			data-field="fin"
		>
			FIN: {fin}
		</Text>
	</Box>;
};

export default CaseIdentifier;
