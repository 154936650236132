import React from 'react';
import UseIsLoadingProps from '@utilities/hooks/useIsLoading/UseIsLoadingProps';
import { useSelector } from 'react-redux';
import { apiCallsInFlightSelector } from '@store/selectors/ApiStatusSelector';

/***
 * @param isLoadingParams {UseIsLoadingProps} - optional - when undefined, returns true when globalLoadingCount > 0
 * @param isLoadingParams.label {string | string[]} - labels that should indicate loading true
 * @param isLoadingParams.endpoint {RegExp | RegExp[]} - regular expressions used to match an endpoint loading status
 */
const useIsLoading = (isLoadingParams: UseIsLoadingProps) => {
	const callsInFlightStatus = useSelector(apiCallsInFlightSelector);

	return React.useMemo(() => {
		const { countByLabel, countByEndpoint, globalLoadingCount } = callsInFlightStatus;
		// When no or only empty options passed, return globalLoading true/false
		if (!isLoadingParams || !Object.keys(isLoadingParams) || !(isLoadingParams.label || isLoadingParams.endpoint)) {
			return globalLoadingCount > 0;
		}

		const { label, endpoint } = isLoadingParams;

		if (label) {
			const labels = Array.isArray(label) ? label : [label];
			const isLoading = labels.some(l => countByLabel[l] > 0);
			// Only return true when label found loading. Otherwise, check for endpoints instead.
			if (isLoading) { return true; }
		}

		if (endpoint) {
			const endpointsRegExp = Array.isArray(endpoint) ? endpoint : [endpoint];
			// For each endpoint currently in flight
			return Object.keys(countByEndpoint).some((endpoint) => {
				// When the endpoint matches one of the regular expressions
				return endpointsRegExp.some((regExp) => regExp.test(endpoint))
					// Return true when a matching endpoint has a count
					&& countByEndpoint[endpoint] > 0;
			});
		}
	}, [callsInFlightStatus, isLoadingParams]);

};

export default useIsLoading;
