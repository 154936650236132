import { Reducer } from 'redux';
import { AuthState } from '../../interfaces/Auth/AuthState';
import { AuthAction } from '../../interfaces/Auth/AuthAction';
import {
	AUTHENTICATION_ERROR,
	AUTHENTICATION_LOADING,
	LOGOUT,
	REQUIRE_AUTHENTICATION,
	SET_TOKEN
} from '../actions/types';
import { AuthStatus } from '../../interfaces/Auth/AuthStatus';

const initialState: AuthState = {
	accessToken: undefined,
	authStatus: AuthStatus.UNKNOWN
};

export const AuthReducer: Reducer<AuthState, AuthAction> = (state: AuthState = initialState, action: AuthAction): AuthState => {
	switch (action.type) {
	case LOGOUT:
	case AUTHENTICATION_ERROR:
		return {
			authStatus: AuthStatus.LOGGED_OUT,
		};
	case SET_TOKEN:
		return {
			accessToken: action.payload,
			authStatus: AuthStatus.AUTHENTICATED,
		};
	case REQUIRE_AUTHENTICATION:
		return {
			authStatus: AuthStatus.AUTHENTICATION_REQUIRED
		};
	case AUTHENTICATION_LOADING:
		return {
			authStatus: AuthStatus.UNKNOWN,
		};
	}
	return state;
};
