import React from 'react';
import CalendarErrorContainer from '@components/CalendarError/presentationalComponents/CalendarErrorContainer';
import CalendarErrorTitle from '@components/CalendarError/presentationalComponents/CalendarErrorTitle';
import {
	useOfficeCalendarViewContext
} from '@components/OfficeCalendarView/OfficeCalendarViewContext/OfficeCalendarViewContext';
import { format } from 'date-fns';

const NoAvailableTimesCalendarError = () => {
	const { selectedDate } = useOfficeCalendarViewContext();

	const formattedDate = React.useMemo(() => format(selectedDate, 'eeee, MMMM d'), [selectedDate]);

	return (
		<CalendarErrorContainer>
			<CalendarErrorTitle>There are no available times on</CalendarErrorTitle>
			<CalendarErrorTitle>{formattedDate}</CalendarErrorTitle>
		</CalendarErrorContainer>
	);
};

export default NoAvailableTimesCalendarError;
