import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { caseLockStatus } from '@store/actions/CaseDetailsActionCreators';
import { ApiActionPayload } from '@interfaces/ApiAction/ApiActionPayload';

export function useCaseLockStatus() {
	const dispatch = useDispatch();

	return useCallback((caseId: string, requestOverRides?: Partial<ApiActionPayload>) => {
		dispatch(caseLockStatus(caseId, true, requestOverRides));
	}, [dispatch]);
}
