import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Flex } from '@ascension/web';
import MyFiltersCheckbox from '../../MyFiltersCheckbox';
import { Unit } from '@data/unit/Unit';
import { useIsAllSelected } from '../../useIsAllSelected/useIsAllSelected';

interface UnitCheckboxProps {
	id: string;
	name: string;
	onCheck: (id: string) => unknown;
	allSelected: boolean;
}
const UnitCheckbox: React.FC<UnitCheckboxProps> = ({ id, name, onCheck, allSelected }) => {
	const { watch } = useFormContext<{ unit: string[] }>();

	const selectedUnits = watch('unit') || [];
	const isChecked = !allSelected && selectedUnits.includes(id);

	return React.useMemo(() => <MyFiltersCheckbox
		id={id}
		name={name}
		onCheck={onCheck}
		checked={isChecked}
	/>, [id, name, onCheck, isChecked]);
};

interface AllUnitsCheckboxProps {
	onCheck: (u: string) => unknown;
	allSelected: boolean;
}
const AllUnitsCheckbox: React.FC<AllUnitsCheckboxProps> = ({ onCheck, allSelected }) => {
	return React.useMemo(() => <MyFiltersCheckbox
		id="all"
		name="All"
		onCheck={onCheck}
		checked={allSelected}
	/>, [allSelected, onCheck]);
};

export interface UnitsListByHospitalProps {
	units: Unit[];
	onToggleUnit: (id: string) => unknown;
}
export default function UnitsListByHospital({ units, onToggleUnit }: UnitsListByHospitalProps) {
	const unitIds = React.useMemo(() => units.map(u => u.id), [units]);
	const allSelected = useIsAllSelected('unit', unitIds);

	return (
		<Flex flexDirection="row" flexWrap="wrap" width={1} pb={10}>
			<AllUnitsCheckbox onCheck={onToggleUnit} allSelected={allSelected}/>
			{units.map(({ id, name }: Unit) => <UnitCheckbox id={id} key={id} name={name} onCheck={onToggleUnit} allSelected={allSelected}/>)}
		</Flex>
	);
}
