import {
	DocumentSurgeryRequestGet,
	SurgeryRequestGet,
	WebSurgeryRequestGet
} from '@interfaces/SurgeryRequest/SurgeryRequestGet';
import { AppointmentType } from '@data/request/AppointmentType';

export const isValueAString = (val: unknown): val is string => {
	return typeof val === 'string';
};

export function isWebRequest(req: SurgeryRequestGet): req is WebSurgeryRequestGet {
	return req && typeof req === 'object' && 'patientInfo' in req && 'birthDate' in req.patientInfo && [AppointmentType.WEB, AppointmentType.LEGACY_WEB].includes(req['appointmentType']);
}

export function isDocumentRequest(req: SurgeryRequestGet): req is DocumentSurgeryRequestGet {
	return req && typeof req === 'object' && [AppointmentType.DOCUMENT].includes(req['appointmentType']);
}
