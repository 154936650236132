import React from 'react';
import EventCard from '@components/EventCard/EventCard';
import { makeStyles } from '@material-ui/core/styles';
import { EventInfo } from '@calendar';
import { HospitalEventStyleConfig } from '@components/UnitCalendarPage/HospitalEventStyleConfig';
import classNames from 'classnames';
import EventCardContent from '@components/EventCard/EventCardContent';

const useStyles = makeStyles({
	card: {
		...HospitalEventStyleConfig.UNSCHEDULED.card as Record<string, unknown>
	},
});

const UnscheduledEventCard: React.FC<EventInfo> = (event: EventInfo) => {
	const classes = useStyles();

	const {
		additionalClassName = '',
		title,
		subTitle,
		timeSpanDisplay,
	} = event;

	return (
		<EventCard
			{...event}
			additionalClassName={classNames(classes.card, {
				[additionalClassName]: !!additionalClassName
			})}
		>
			<EventCardContent title={title} subTitle={subTitle} timeSpan={timeSpanDisplay}/>
		</EventCard>
	);
};

export default UnscheduledEventCard;
