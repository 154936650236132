import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useCommonStyles } from '@utilities/hooks/useCommonStyles/useCommonStyles';
import useDetailSectionGrid, {
	AttachmentGridFields,
} from '@components/CaseDetails/useDetailSectionGrid/useDetailSectionGrid';
import DetailSection from '@components/CaseDetails/DetailSection';
import { Box, Divider, Typography } from '@material-ui/core';
import DocumentStatusField
	from '@components/CaseDetails/DocumentInformationDisplay/DocumentDetails/fields/DocumentStatusField';
import DocumentDateUploadedField
	from '@components/CaseDetails/DocumentInformationDisplay/DocumentDetails/fields/DocumentDateUploadedField';
import DocumentUploadedByField
	from '@components/CaseDetails/DocumentInformationDisplay/DocumentDetails/fields/DocumentUploadedByField';
import {
	useDocumentDetailsContext
} from '@components/CaseDetails/DocumentInformationDisplay/DocumentDetails/DocumentDetails';
import DocumentActions
	from '@components/CaseDetails/DocumentInformationDisplay/DocumentDetails/DocumentActions/DocumentActions';

const useDetailStyles = makeStyles((theme) => ({
	actionRow: {
		margin: theme.spacing(2, 0),
	},
	fileName: {
		marginBottom: theme.spacing(2),
		fontSize: '1.125rem',
		marginTop: theme.spacing(4),
		overflowWrap: 'break-word',
	},
}));

const DocumentDetailsGrid = () => {
	const classes = useDetailStyles();
	const { rowGap } = useCommonStyles();
	const { gridContainer } = useDetailSectionGrid(DetailSection.ATTACHMENTS);
	const { file } = useDocumentDetailsContext();

	return (
		<>
			<Box>
				<Typography variant="h3" className={classes.fileName}>{file}</Typography>
				<Box className={classes.actionRow}><DocumentActions /></Box>
				<Box className={`${gridContainer} ${rowGap}`}>
					<DocumentStatusField
						label="Status"
						style={{ gridArea: AttachmentGridFields.STATUS }}
					/>
					<DocumentDateUploadedField
						label="Date Uploaded"
						style={{ gridArea: AttachmentGridFields.DATE_UPLOADED }}
					/>
					<DocumentUploadedByField
						label="Uploaded by"
						style={{ gridArea: AttachmentGridFields.UPLOADED_BY }}
					/>
				</Box>
			</Box>
			<Divider />
		</>
	);
};

export default DocumentDetailsGrid;
