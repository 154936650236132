import React from 'react';
import {
	Accordion,
	Box,
	AccordionSummary,
	Typography,
	makeStyles,
	createStyles,
	AccordionDetails,
} from '@material-ui/core';
import { format } from 'date-fns-tz';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FindATimeAccordionProps } from '@components/RequestForm/FindATime/FindATimeAccordion/FindATimeAccordionProps';
import { FindATimeResults } from '@components/RequestForm/FindATime/FindATimeAccordion/FindATimeResults/FindATimeResults';
import { useFindATimeContext } from '@components/FindATime/FindATimeContext/FindATimeContextProvider';
import {
	useDoesUnitSupportOpenTime
} from '@components/RequestForm/utilities/hooks/useDoesUnitSupportOpenTime/useDoesUnitSupportOpenTime';
import {
	useClearSelectionAndCloseAccordion
} from '@components/RequestForm/utilities/hooks/useClearSelectionAndCloseAccordion/useClearSelectionAndCloseAccordion';
import {
	useProcedureTimeSetter
} from '@components/RequestForm/utilities/hooks/useProcedureTimeSetter/useProcedureTimeSetter';
import { AvailableTimeOption } from '@data/findATime/types/AvailableTimeOption';
import { useRequestFormContext } from '@components/RequestForm/RequestForm';
import { useFormContext } from 'react-hook-form';
import CommonRequestForm from '@interfaces/RequestForm/forms/CommonRequestForm';
import { track } from '@amplitude/analytics-browser';

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			'border': `1px solid ${theme.palette.grey[500]}`,
			'borderRadius': '4px',
			'& .MuiAccordion-root': {
				'& .MuiAccordionSummary-root': {
					minHeight: '32px',
					height: '32px',
				},
				'& .MuiAccordionSummary-root.Mui-disabled': {
					'opacity': 1,
					'color': theme.palette.grey[500],
					'& .MuiSvgIcon-root': {
						color: theme.palette.grey[500],
					},
				},
				'& .MuiAccordionSummary-expandIcon.Mui-expanded': {
					transform: 'rotate(90deg)',
				},
			},
			'& .MuiPaper-root': {
				boxShadow: 'none',
				border: 'none',
			},
			'& .MuiAccordionDetails-root': {
				maxHeight: '528px',
			}
		},
		accordionSummaryContainer: {
			paddingLeft: theme.spacing(3),
			color: theme.palette.primary.main,
		},
		accordionSummary: {
			fontWeight: 600,
		},
		expandIcon: {
			color: theme.palette.primary.main,
			transform: 'rotate(-90deg)',
		},
		accordionDetails: {
			borderTop: `1px solid ${theme.palette.grey[500]}`,
			borderRadius: '4px',
			boxShadow: '0px 0px 8px 0px rgba(15, 60, 151, 0.25) inset',
			background: theme.palette.common.white,
			// We need to turn off the accordion padding so that the fancy scroll in FindATimeResults can work properly.
			padding: 0,
		},
	}),
);

export const FindATimeAccordion = ({ disabled }: FindATimeAccordionProps) => {
	const classes = useStyles();
	const {
		accordionExpanded,
		findATimeResponse,
		lastSelectedTimeSlot,
		selectedTimeSlot,
		setAccordionExpanded,
		setFindATimeResponse,
		setSelectedTimeSlot,
	} = useFindATimeContext();
	const { setPeriodValue, setTimeValue } = useProcedureTimeSetter();
	const accordionDisabled = disabled || !findATimeResponse;
	const supportsOpenTime = useDoesUnitSupportOpenTime();
	const { setValue } = useFormContext<CommonRequestForm>();
	const { currentFindATimeResults, currentSelectedTimeSlot } = useRequestFormContext();
	useClearSelectionAndCloseAccordion({ setAccordionExpanded, setFindATimeResponse });

	const handleAccordionChange = React.useCallback(() => {
		setAccordionExpanded(!accordionExpanded);
		track('accordion-toggle', { fieldName: 'requestForm-requestDetails-findATimeAccordionToggle', eventType: !accordionExpanded ? 'expand' : 'collapse' });
	}, [accordionExpanded, setAccordionExpanded]);

	const handleSelectTime = React.useCallback((time: AvailableTimeOption, timeSlotId: string) => {
		// if the selectedTimeSlot is not the same as the timeSlotId, set the time and period values for the time inputs to the new timeSlotId
		// This is necessary for passing the values from the FindATime component to the TimePicker component for selected time slot
		if (selectedTimeSlot !== timeSlotId) {
			const requestFormStartTime = format(new Date(`01-01-1970 ${time.start}`), 'hh:mm aaa');
			const timeParts = requestFormStartTime.split(' ');
			const startTimeOnly = timeParts[0];
			const periodValue = timeParts[1];
			setTimeValue(startTimeOnly);
			setPeriodValue(periodValue);
		}

		setValue('findATimeUsed', true);
		setSelectedTimeSlot(timeSlotId);
		lastSelectedTimeSlot.current = timeSlotId;
		track('timeSlotCard-click', { fieldName: 'requestForm-findATime-timeSlotCard', eventType: 'click' });
	}, [
		setValue,
		selectedTimeSlot,
		setPeriodValue,
		setSelectedTimeSlot,
		setTimeValue,
		lastSelectedTimeSlot,
	]);

	const handleSetFindATimeUsed = React.useCallback((newValue: boolean)=> {
		setValue('findATimeUsed', newValue);
	}, [setValue]);

	React.useEffect(() => {
		if (currentFindATimeResults) {
			setFindATimeResponse(currentFindATimeResults);

			if (currentSelectedTimeSlot) {
				lastSelectedTimeSlot.current = currentSelectedTimeSlot;
				setSelectedTimeSlot(currentSelectedTimeSlot);
			}
		}

		if (findATimeResponse && !currentFindATimeResults) {
			setAccordionExpanded(true);
		}
	}, [
		currentFindATimeResults,
		currentSelectedTimeSlot,
		findATimeResponse,
		lastSelectedTimeSlot,
		setAccordionExpanded,
		setFindATimeResponse,
		setSelectedTimeSlot
	]);

	if (!supportsOpenTime) {
		return null;
	}

	return (
		<Box className={classes.root}>
			<Accordion expanded={accordionExpanded} onChange={handleAccordionChange}>
				<AccordionSummary
					disabled={accordionDisabled}
					className={classes.accordionSummaryContainer}
					expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
				>
					<Typography variant="body2" className={classes.accordionSummary}>
						Select a time
					</Typography>
				</AccordionSummary>
				<AccordionDetails className={classes.accordionDetails}>
					<FindATimeResults handleSelectTime={handleSelectTime} handleSetFindATimeUsed={handleSetFindATimeUsed} />
				</AccordionDetails>
			</Accordion>
		</Box>
	);
};
