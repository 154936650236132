import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles, Typography, TypographyProps } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles(() => createStyles({
	root: {
		fontSize: '18px',
	},
}));

const CalendarErrorSubtitle: React.FC<TypographyProps> = ({ children, className }) => {
	const classes = useStyles();

	return (
		<Typography
			align="center"
			component="h3"
			variant="h2"
			className={classNames(classes.root, className)}
		>
			{children}
		</Typography>
	);
};

export default CalendarErrorSubtitle;
