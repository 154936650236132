import { useCollectionEntity } from '@utilities/hooks/useCollectionEntity/useCollectionEntity';
import { Unit } from '@data/unit/Unit';
import { ENDPOINT_USER_UNITS } from '@utilities/apiConstants';
import { AppointmentType } from '@data/request/AppointmentType';
import compareArrays from '@utilities/arrays/compareArrays';
import ComparisonOption from '@utilities/arrays/ComparisonOption';

/***
 * useDoesUnitSupportAppointmentTypes -
 * @param unitId
 * @param types {AppointmentType[]} - Check list for these appointment types. All types provided must be available
 * @param comparisonOption {ComparisonOption: ComparisonOption.ALL}
 */
const useDoesUnitSupportAppointmentTypes = (unitId: string, types: AppointmentType[], comparisonOption = ComparisonOption.ANY) => {
	const { requestTypes } = useCollectionEntity<Unit>(ENDPOINT_USER_UNITS, unitId);

	return compareArrays(types, requestTypes, comparisonOption);
};

export default useDoesUnitSupportAppointmentTypes;
