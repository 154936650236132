import React from 'react';
import {
	Box,
	Card,
	Flex,
	Text,
} from '@ascension/web';
import { QueueNavigateProps } from './QueueNavigateProps';
import { NavigateNext, NavigateBefore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useCommonStyles } from '../../utilities/hooks/useCommonStyles/useCommonStyles';

const useStyles = makeStyles({
	textWrapper: {
		maxWidth: '224px',
	},
});

export const QueueNavigate = ({ caseInfo: { dateDisplay, timeDisplay, procedureName, surgeonName }, ...rest }: QueueNavigateProps) => {
	const { textWrapper } = useStyles();
	const { hideTextOverflow } = useCommonStyles();
	const textStyles = `${textWrapper} ${hideTextOverflow}`;

	return (
		<Card
			height="75px"
			width="276px"
			mt="12px"
			borderWidth={1}
			borderStyle="solid"
			borderColor="#C9C9C9"
			p="8px 16px"
			borderRadius="4px"
			justifyContent="space-between"
			{...rest}
		>
			<Flex
				className="nav-icon previous"
				alignItems="center"
			>
				<NavigateBefore
					style={{ color: '#1E69D2' }}
					fontSize="large"
				/>
			</Flex>
			<Flex
				flexDirection="column"
				className="nav-descriptor"
			>
				<Box
					pb="4px"
				>
					<Text
						fontSize={12}
						color="#1E69D2"
						lineHeight="1.25"
					>
						{dateDisplay}
					</Text>
					<Text
						color="#C9C9C9"
						fontSize={12}
						pl="8px"
						pr="8px"
					>
						|
					</Text>
					<Text
						fontSize={12}
						color="#1E69D2"
						lineHeight="1.25"
					>
						{timeDisplay}
					</Text>
				</Box>
				<Text
					fontWeight="bold"
					fontSize={14}
					lineHeight="1.43"
					className={textStyles}
				>
					{surgeonName}
				</Text>
				<Text
					fontSize={14}
					lineHeight="1.43"
					className={textStyles}
				>
					{procedureName}
				</Text>
			</Flex>
			<Flex
				className="nav-icon next"
				alignItems="center"
			>
				<Text
					color="#1E69D2"
				>
					<NavigateNext
						style={{ color: '#1E69D2' }}
						fontSize="large"
					/>
				</Text>
			</Flex>
		</Card>
	);
};
