import React, { useContext } from 'react';
import {
	Index,
	IndexingSectionContextType,
} from '@components/PdfViewerPage/IndexingSection/IndexingSectionContext/IndexingSectionContextType';
import FormContextProvider from '@components/FormContextProvider/FormContextProvider';
import { AxiosResponse } from 'axios';
import { useFormContext } from 'react-hook-form';
import getFormStateFromIndexes, {
	DOCUMENT_TYPE_PREFIX,
	PAGES_PREFIX,
} from '@components/PdfViewerPage/IndexingSection/utilities/getFormStateFromIndexes';
import { documentTypeValidation, pagesIndexValidation } from '@utilities/Validation/validationSchema';
import useGetDocIndexes from '@data/docIndexes/hooks/useGetDocIndexes';
import useSaveDocIndexes from '@data/docIndexes/hooks/useSaveDocIndexes';
import { ToastType } from '@interfaces/ToastStatus/ToastStatusState';
import { useToast } from '@utilities/hooks/useToast/useToast';
import { ToastConstants } from '@utilities/toastConstants';
import useDisableSave from '@utilities/hooks/useDisableSave/useDisableSave';
import useManageEditableList from '@utilities/hooks/useManageEditableList/useManageEditableList';
import useProviderEventManager from '@utilities/hooks/useProviderEventManager/useProviderEventManager';
import { PermittedAction } from '@utilities/PermittedAction';
import {
	useGetCasePostIndexEditorAccessor,
} from '@components/CaseDetails/CaseInUse/hooks/useGetCaseAsAccessor/useGetCasePostAccessor';
import useGetAccessor from '@components/CaseDetails/CaseInUse/hooks/useGetAccessor/useGetAccessor';
import { UserAccessEnum } from '@interfaces/SurgeryRequest/UserAccessEnum';

export interface IndexingSectionContextProps {
	attachmentId: string;
}

const IndexingSectionContext = React.createContext<IndexingSectionContextType | null>(null);

export const useIndexingSectionContext = () => {
	const ctx = useContext(IndexingSectionContext);

	if (!ctx) {
		throw new Error('useIndexingSectionContext must be used in subcomponent of IndexingSectionContextProvider');
	}

	return ctx;
};

const IndexingSectionContextProviderInner: React.FC<IndexingSectionContextProps> = ({ children, attachmentId }) => {
	const { reset, register, unregister, getValues } = useFormContext<Record<string, string>>();
	const [showEdit, setShowEdit] = React.useState(false);

	const {
		registerEventHandler: registerAddItemHandler,
		runAllHandlers: runAllAddItemHandlers,
	} = useProviderEventManager();

	const {
		initialList,
		setInitialList,
		addItem,
		removeId: removeIndexRow,
		resetListToInitial,
		idList: allIdList,
	} = useManageEditableList<Index>(true);

	const addIndexRow = React.useCallback(() => {
		addItem();
		runAllAddItemHandlers(undefined);
	}, [addItem, runAllAddItemHandlers]);

	const getDocIndexes = useGetDocIndexes();
	const saveDocIndexes = useSaveDocIndexes();
	const showToast = useToast();

	useGetCasePostIndexEditorAccessor(true);

	// Disable edit option when someone else is editing OR no permission for role
	const {
		hasPermission,
		isAccessorDifferentUser,
	} = useGetAccessor([UserAccessEnum.EDIT_INDEX, UserAccessEnum.CONFIRMER], PermittedAction.EDIT_INDEX);
	const hasAccessAndPermittedEdit = hasPermission && !isAccessorDifferentUser;

	// This controls UI, show when not disabled AND either no indexes from API or user requested. Both conditions must be false to show read only.
	/***
	 * hasAccessAndPermittedEdit is true AND
	 *   | Index API returns | showEdit | then should edit result is |
	 *   | ----------------- | -------- | -------------------------- |
	 *   | [] (0 indexes)    | false    | TRUE                       |
	 *   | [] (0 indexes)    | true     | TRUE                       |
	 *   | null / undefined  | false    | TRUE                       |
	 *   | null / undefined  | true     | TRUE                       |
	 *   | 1+ indexes        | true     | TRUE                       |
	 *   | 1+ indexes        | false    | FALSE                      |
	 */
	const shouldShowEdit = hasAccessAndPermittedEdit && (!initialList?.length || showEdit);

	React.useEffect(() => {
		const onSuccess = (res: AxiosResponse<Index[]>) => {
			setInitialList(res.data);
		};
		getDocIndexes(attachmentId, { onSuccess });
	}, [attachmentId, getDocIndexes, setInitialList]);

	const initialFormState = React.useMemo(() => getFormStateFromIndexes(initialList), [initialList]);

	// Automatically register fields using IDs provided
	React.useEffect(() => {
		initialList.forEach(({ id }) => {
			register(`${PAGES_PREFIX}${id}`, pagesIndexValidation);
			register(`${DOCUMENT_TYPE_PREFIX}${id}`, documentTypeValidation);
		});
		return () => {
			initialList.forEach(({ id }) => {
				unregister(`${PAGES_PREFIX}${id}`);
				unregister(`${DOCUMENT_TYPE_PREFIX}${id}`);
			});
		};
	}, [initialList, register, unregister]);

	// Reset form using the state provided on load and when edit view toggled
	React.useEffect(() => {
		reset(initialFormState);
	}, [initialFormState, reset, shouldShowEdit]);

	const cancelEdit = React.useCallback(() => {
		setShowEdit(false);
		resetListToInitial();
	}, [resetListToInitial]);

	const fieldNamesToCheck = React.useMemo(() => {
		return allIdList.reduce<string[]>((allFields, id) => {
			allFields.push(`${PAGES_PREFIX}${id}`);
			allFields.push(`${DOCUMENT_TYPE_PREFIX}${id}`);
			return allFields;
		}, []);
	}, [allIdList]);
	const shouldDisableSave = useDisableSave<Record<string, string>>(initialFormState, { fieldNamesToCheck, fieldsRequired: true });
	const saveIndexes = React.useCallback(() => {
		const formValues = getValues();
		const payload: Index[] = allIdList.map((id) => ({
			page: formValues[`${PAGES_PREFIX}${id}`],
			type: formValues[`${DOCUMENT_TYPE_PREFIX}${id}`]
		}));

		const onSuccess = (res: AxiosResponse<Index[] | undefined>) => {
			setInitialList(res.data);
			setShowEdit(false);
			showToast(ToastType.SUCCESS, ToastConstants.SAVE_INDEX_SUCCESS);
		};
		const onFailure = () => {
			showToast(ToastType.ERROR, ToastConstants.SAVE_INDEX_ERROR);
		};
		saveDocIndexes(attachmentId, { data: payload, onSuccess, onFailure });
	}, [getValues, allIdList, saveDocIndexes, attachmentId, setInitialList, showToast]);

	return (
		<IndexingSectionContext.Provider value={{
			shouldDisableEditToggle: !hasAccessAndPermittedEdit || shouldShowEdit,
			shouldShowEdit,
			setShowEdit,
			saveIndexes,
			shouldDisableSave,
			initialIndexListApi: initialList,
			allIdList,
			registerAddItemHandler,
			addIndexRow,
			cancelEdit,
			removeIndexRow,
		}}>
			{children}
		</IndexingSectionContext.Provider>
	);
};

const IndexingSectionContextProvider: React.FC<IndexingSectionContextProps> = ({ children, attachmentId }) => {
	const formOptions = {
		defaultValues: {},
	};

	return (
		<FormContextProvider {...formOptions}>
			<IndexingSectionContextProviderInner attachmentId={attachmentId}>
				{children}
			</IndexingSectionContextProviderInner>
		</FormContextProvider>
	);
};
export default IndexingSectionContextProvider;
