import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ColumnHeaderProps from '@components/SchedulePage/types/ColumnHeaderProps';
import NonBlockColumnHeader from '@components/SchedulePage/NonBlockColumnHeader/NonBlockColumnHeader';

const useStyles = makeStyles( theme => createStyles({
	root: {
		padding: theme.spacing(0.5, 1, 0.5, 1),
		backgroundColor: theme.palette.grey[800],
		color: theme.palette.common.white,
		display: 'flex',
		flexDirection: 'column',
		borderRadius: '4px 4px 0 0',
	},
	blockText: {
		fontWeight: 600,
	},
}));

const BlockReleasedColumnHeader: React.FC<ColumnHeaderProps> = ({ blockInfo, children }) => {
	const classes = useStyles();

	return (
		<>
			<Box className={classes.root} data-field="block-released-column-header" component="header">
				<Typography variant="body2" component="h3" className={classes.blockText}>Block Time - RELEASED</Typography>
				{ children }
			</Box>
			<NonBlockColumnHeader blockInfo={blockInfo} />
		</>
	);
};

export default BlockReleasedColumnHeader;
