import { Unit } from '@data/unit/Unit';

import { ENDPOINT_USER_UNITS } from '@utilities/apiConstants';
import useCollection from '@utilities/hooks/useCollection/useCollection';
import React from 'react';

const useHasUnitWithOpenTime = () => {
	const units = useCollection<Unit>(ENDPOINT_USER_UNITS);

	// We can rely on the units endpoint to always return data,
	// so if it's an empty array it's in a loading state
	const isLoading = units.length === 0;

	const data = React.useMemo(() => {
		return units.some(
			(unit) => !unit.officeOpenTimeDisabled && unit.openTimeConfig,
		);
	}, [units]);

	return { isLoading, data };
};

export default useHasUnitWithOpenTime;
