import { validators } from '@utilities/Validation/validators';
import { RegisterOptions } from 'react-hook-form';
import { DropdownOption } from '@definitions/DropdownOption';
import {
	INVALID_DATE_FORMAT,
	INVALID_EMAIL,
	INVALID_FIRST_NAME,
	INVALID_GENDER,
	INVALID_LANGUAGE,
	INVALID_LAST_NAME,
	INVALID_MIDDLE_NAME,
	INVALID_NUMBER,
	INVALID_PAST_DATE,
	INVALID_SSN,
	INVALID_SSN_LONG,
	INVALID_ZIP_CODE,
	REQUIRED_ADDRESS_1,
	REQUIRED_CITY,
	REQUIRED_STATE,
} from '@utilities/Validation/ValidationMessages';

export const PatientDetailsValidationSchema: {[selector: string]: Partial<RegisterOptions>} = {
	patientLastName: {
		required: INVALID_LAST_NAME,
		minLength: {
			value: 2,
			message: INVALID_LAST_NAME,
		},
		validate: {
			invalidName: (nameVal: string) => validators.nameValidator(nameVal) === null || INVALID_LAST_NAME,
		},
	},
	patientFirstName: {
		required: INVALID_FIRST_NAME,
		minLength: {
			value: 2,
			message: INVALID_FIRST_NAME,
		},
		validate: {
			invalidName: (nameVal: string) => validators.nameValidator(nameVal) === null || INVALID_FIRST_NAME,
		},
	},
	patientMiddleName: {
		validate: {
			invalidName: (nameVal: string) => validators.nameValidator(nameVal) === null || INVALID_MIDDLE_NAME,
		},
	},
	patientDOB: {
		required: INVALID_DATE_FORMAT,
		validate: {
			invalidDOB: (val: string) => validators.dobValidator(val) === null || INVALID_PAST_DATE,
			invalidDate: (val: string) => validators.dateValidator(val) === null || INVALID_DATE_FORMAT,
		},
	},
	patientSSN: {
		validate: {
			invalidSSN: (val: string) => {
				if (validators.ssnValidator(val) === null) {
					return true;
				} else if (validators.ssnValidator(val) === 'invalidSSNLength') {
					return INVALID_SSN_LONG;
				} else {
					return INVALID_SSN;
				}
			}
		}
	},
	patientSSN1: {
		minLength: {
			value: 3,
			message: INVALID_SSN_LONG
		}
	},
	patientSSN2: {
		minLength: {
			value: 2,
			message: INVALID_SSN_LONG
		}
	},
	patientSSN3: {
		minLength: {
			value: 4,
			message: INVALID_SSN_LONG
		}
	},
	patientGender: {
		required: INVALID_GENDER,
		validate: {
			invalidGender: (input: DropdownOption) => validators.genderValidator(input) === null || INVALID_GENDER
		}
	},

	patientPhoneNumber: {
		required: INVALID_NUMBER,
		validate: {
			invalidPhone: (val: string) => validators.phoneValidator(val) === null || INVALID_NUMBER
		}
	},
	patientEmail: {
		validate: {
			invalidEmail: (val: string) => validators.emailValidator(val) === null || INVALID_EMAIL
		}
	},
	patientInterpretLanguage: {
		validate: {
			invalidLanguage: (val: string) => validators.languageValidator(val) === null || INVALID_LANGUAGE,
		}
	},
	patientInterpretLanguageRequired: {
		required: INVALID_LANGUAGE,
		validate: {
			invalidLanguage: (val: string) => validators.languageValidator(val) === null || INVALID_LANGUAGE,
		}
	},
	patientAddressLine1: {
		required: REQUIRED_ADDRESS_1,
	},
	patientCity: {
		required: REQUIRED_CITY,
	},
	patientState: {
		required: REQUIRED_STATE,
	},
	patientZipCode: {
		required: INVALID_ZIP_CODE,
		minLength: {
			value: 5,
			message: INVALID_ZIP_CODE
		},
		maxLength: {
			value: 5,
			message: INVALID_ZIP_CODE
		},
		validate: {
			invalidZipCode: (val: string) => validators.zipCodeValidator(val) === null || INVALID_ZIP_CODE,
		}
	},
};
