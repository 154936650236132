import { SurgeryRequestGet } from '@interfaces/SurgeryRequest/SurgeryRequestGet';
import { IntegrationType } from '@interfaces/IntegrationType';

export interface ConfirmationInputDescriptor {
	label: string;
	invalidCharacterErrorMessage: string;
	validCharacterTest: string;
	requestDetailsProperty: keyof SurgeryRequestGet;
}

export const CONFIRMATION_INPUT_CONFIG: Record<IntegrationType, ConfirmationInputDescriptor> = {
	[IntegrationType.EHR]: {
		label: 'EHR Case Number',
		invalidCharacterErrorMessage: 'Case Number must contain only letters, numbers and dashes.',
		validCharacterTest: '\\dA-Za-z-',
		requestDetailsProperty: 'caseNumber',
	},
	[IntegrationType.MANUAL]: {
		label: 'FIN',
		invalidCharacterErrorMessage: 'FIN must contain only numbers or letters.',
		validCharacterTest: '\\dA-Za-z',
		requestDetailsProperty: 'fin',
	}
};
