import React from 'react';
import { useDispatch } from 'react-redux';
import { updateUserProfile } from '@store/actions/UserProfileActionCreators';
import {
	PracticeInterface,
	UserDetailsApiInterface,
	UserDetailsOffice,
} from '@interfaces/UserPrefInterface';
import { useHistory } from 'react-router-dom';
import { ROUTE_CONSTANTS } from '@utilities/RouteConstants';

export const useChangePractice = () => {
	const dispatch = useDispatch();
	const history= useHistory();

	const setPractice = React.useCallback((practiceId: Partial<PracticeInterface>) => {
		const onChangePracticeSuccess = () => {
			history.push(ROUTE_CONSTANTS.HOME, {
				shouldReloadApp: true,
			});
		};

		const payload: Partial<UserDetailsOffice> = {
			primaryPractice: practiceId as PracticeInterface,
		};

		dispatch(updateUserProfile<UserDetailsOffice, UserDetailsApiInterface>(payload, onChangePracticeSuccess));
	}, [dispatch, history]);

	return { setPractice };
};
