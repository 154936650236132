import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, BoxProps } from '@material-ui/core';
import { BODY_GRID_AREA } from '@components/MainPageLayout/presentationalComponents/MainPageLayoutContainer';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		gridArea: BODY_GRID_AREA,
		padding: theme.spacing(0, 5),
		overflow: 'hidden'
	},
}));

const MainPageLayoutBody: React.FC<BoxProps> = ({ children, ...boxProps }) => {
	const classes = useStyles();

	return (
		<Box className={classNames(classes.root, boxProps.className)} {...boxProps}>
			{children}
		</Box>
	);
};

export default MainPageLayoutBody;
