import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BackToHomeLink } from '../AppPage/BackToHomeLink/BackToHomeLink';
import UserRegistrationForm from './UserRegistrationForm/UserRegistrationForm';

const PAGE_WIDTH = '718px';

const useStyles = makeStyles((theme) => ({
	pageContainer: {
		'margin': theme.spacing(0, 'auto', 10, 'auto'),
		'width': PAGE_WIDTH,
		'& .MuiTypography-h1': {
			fontSize: '3rem',
			lineHeight: '3.5rem',
			marginTop: theme.spacing(1),
		},
		'& .MuiTypography-h2': {
			fontSize: '1.5rem',
			lineHeight: '1.5rem',
			fontWeight: 450,
		},
	},
}));


const UserRegistrationPage: React.FC = () => {
	const classes = useStyles();

	return (
		<Box className={classes.pageContainer}>
			<BackToHomeLink/>
			<Typography variant="h1" component="h1">
				Add users
			</Typography>
			<UserRegistrationForm isEdit={false}/>
		</Box>
	);
};

export default UserRegistrationPage;
