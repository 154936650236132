import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import DocumentField from '@components/CaseDetails/DocumentInformationDisplay/DocumentDetails/DocumentField/DocumentField';
import DocumentDateUploadedField
	from '@components/CaseDetails/DocumentInformationDisplay/DocumentDetails/fields/DocumentDateUploadedField';
import DocumentUploadedByField
	from '@components/CaseDetails/DocumentInformationDisplay/DocumentDetails/fields/DocumentUploadedByField';
import {
	useDocumentDetailsContext
} from '@components/CaseDetails/DocumentInformationDisplay/DocumentDetails/DocumentDetails';
import DocumentStatusField
	from '@components/CaseDetails/DocumentInformationDisplay/DocumentDetails/fields/DocumentStatusField';
import DocumentActions
	from '@components/CaseDetails/DocumentInformationDisplay/DocumentDetails/DocumentActions/DocumentActions';

const DocumentDetailsRow: React.FC = () => {
	const {
		file,
	} = useDocumentDetailsContext();
	return (
		<TableRow>
			<TableCell>
				<DocumentDateUploadedField />
			</TableCell>
			<TableCell>
				<DocumentUploadedByField />
			</TableCell>
			<TableCell>
				<DocumentField fieldName="file">
					{file}
				</DocumentField>
			</TableCell>
			<TableCell>
				<DocumentStatusField />
			</TableCell>
			<TableCell>
				<DocumentActions />
			</TableCell>
		</TableRow>
	);
};

export default DocumentDetailsRow;
