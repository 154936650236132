import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, createStyles } from '@material-ui/core';
import ColumnHeaderProps from '@components/SchedulePage/types/ColumnHeaderProps';
import DisplaySelectedUnitTimeFrame from '@components/SchedulePage/DisplaySelectedUnitTimeFrame/DisplaySelectedUnitTimeFrame';

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
		},
	}),
);

type BlockColumnHeaderCommonProps = ColumnHeaderProps;

const ColumnHeaderCommon: React.FC<BlockColumnHeaderCommonProps> = ({ blockInfo }) => {
	const classes = useStyles();

	return (
		<Box className={classes.root} data-field="block-column-header-top">
			<Box>
				{!blockInfo.isReleased && blockInfo.timeframes.map((timeframe, i) => <DisplaySelectedUnitTimeFrame key={`${timeframe.start.toISOString()}::${timeframe.end.toISOString()}::${i}`} timeFrame={timeframe} variant="body2"/>)}
			</Box>
		</Box>
	);
};

export default ColumnHeaderCommon;
