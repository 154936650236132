import endpointTemplateToUrl from '@utilities/endpointTemplateToUrl';
import { ENDPOINT_DOC_PRINT } from '@utilities/apiConstants';
import { apiAction } from '@store/middlewares/api/ApiActionCreators';
import ApiService from '@utilities/apiEndpoints/ApiService';
import { DOC_PRINT } from '@store/actions/types';
import { defaultCallback } from '@utilities/defaultCallback';
import { DocPrintApiPayload } from '@data/docPrint/types/DocPrintRequest';
import ApiRestMethod from '@utilities/apiEndpoints/ApiRestMethod';

export const getDocPrintApiAction = (requestOverrides: DocPrintApiPayload) => {
	const url = endpointTemplateToUrl(ENDPOINT_DOC_PRINT, { documentId: requestOverrides.data.id });
	return apiAction({
		apiService: ApiService.DS,
		includeAccessToken: true,
		label: DOC_PRINT,
		method: ApiRestMethod.POST,
		onSuccess: defaultCallback,
		onFailure: defaultCallback,
		shouldDisableErrorHandler: true,
		url,
	});
};
