import { useDispatch } from 'react-redux';
import React from 'react';
import {
	getHoldEventApiUpdateAction,
} from '@data/holdEvent/actionCreators/HoldEventActionCreators';
import { UpdateHoldEventApiPayload } from '@data/holdEvent/types/HoldEventApiPayload';

const useUpdateHoldEvent = () => {
	const dispatch = useDispatch();

	return React.useCallback((overrideRequest: UpdateHoldEventApiPayload) => {
		dispatch(getHoldEventApiUpdateAction(overrideRequest));
	}, [dispatch]);
};

export default useUpdateHoldEvent;
