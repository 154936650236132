import { createTheme } from '@material-ui/core';
import { ascensionTheme } from '@ascension/ui-library';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';

const theme = createTheme({
	...ascensionTheme,
	breakpoints: {
		keys: ['xs', 'sm', 'md', 'lg', 'xl'],
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1536,
		},
	},
	spacing: factor => factor * 8,
	typography: {
		...ascensionTheme.typography,
		subtext1: {
			color: 'rgba(0,0,0,.87)',
			lineHeight: '1.25rem',
			fontSize: '1rem',
			letterSpacing: '.03125rem'
		},
		subtitle1: {
			fontSize: '15px',
		},
		h1: {
			fontSize: '2rem',
			lineHeight: '1.875rem',
			letterSpacing: '.015rem',
			fontWeight: 'normal',
		},
		h2: {
			fontSize: '1.25rem',
			lineHeight: '1.5rem',
			letterSpacing: '.01rem',
			fontWeight: 600,

		},
		h3: {
			fontSize: '1rem',
			fontWeight: 600,
		}
	},
	palette: {
		...ascensionTheme.palette,
		error: {
			...ascensionTheme.palette?.error,
			50: '#FEEBE4',
			600: '#BA0128'
		},
		primary: {
			...ascensionTheme.palette?.primary,
			50: '#E8F5FE',
			100: '#D1EAFC',
			400: '#5194E3',
			600: '#155184',
			800: '#092A79',
		},
		secondary: {
			...ascensionTheme.palette?.secondary,
			50: '#FDE6EE',
			100: '#FBCDDC',
		},
		success: {
			...ascensionTheme.palette?.success,
			50: '#E9FBE7',
		},
		warning: {
			...ascensionTheme.palette?.warning,
			50: '#FFF7E6',
		},
		grey: {
			...ascensionTheme.palette?.grey,
			400: '#BDBDBD',
		}
	} as PaletteOptions,
	drawerPane: {
		breakpoint: 'lg',
		widthDown: 340,
		widthUp: 544,
	},
	overrides: {
		MuiFormLabel: {
			asterisk: {
				color: '#E00019',
			},
		},
	},
});
export default theme;
