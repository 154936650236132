import React from 'react';
import { Box, TextLink } from '@ascension/web';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useHomeNavigationContext } from '@components/HeaderSection/NavigationOptions/HomeNavigationContext/HomeNavigationContext';

interface BackToHomeLinkProps {
	navToHomeText?: string;
}

const useStyles = makeStyles({
	homeBtn: {
		flex: 'auto 0 1'
	}
});

export const BackToHomeLink = ({ navToHomeText }: BackToHomeLinkProps) => {
	const history = useHistory();
	const { homeRoute } = useHomeNavigationContext();
	const navigateToHomeText = navToHomeText || '< Home';
	const classes = useStyles();
	return (
		<Box mt={31} pt={10} pb={8} display="inline-block" className={classes.homeBtn}>
			<TextLink onClick={() => history.push(homeRoute)} data-field="backHome">
				{navigateToHomeText}
			</TextLink>
		</Box>
	);
};

