/***
 * minuteDurationToHrMinStr - returns string with hours and minutes formatted for display
 *
 * @example
 * minuteDurationToHrMinStr(155); // returns "2H 35M"
 *
 * @returns {string}:
 * 		- "#H #M" (i.e. "5H 45M") when duration >= 60
 * 		- "#M" (i.e. "15M") when 0 < duration < 60
 * 		- "0M" when duration <= 0
 *
 ***/
const minuteDurationToHrMinStr = (minutes: number) => {
	if (!minutes || minutes <= 0) { return '0M'; }
	const hoursCount = Math.floor(minutes / 60);
	let displayStr = `${minutes % 60}M`;
	if (hoursCount) {
		displayStr = `${hoursCount}H ${displayStr}`;
	}
	return displayStr;
};

export default minuteDurationToHrMinStr;
