import { formatInTimeZone } from 'date-fns-tz';
import { doEventsOverlap, EventInfo, splitRemoveByOverlapEvents } from '@calendar';
import { RoomInfo } from '@data/openCalendar/RoomInfo';
import EventParser from '@utilities/hooks/useUnitEvents/EventParser';
import { RoomEventInfo } from '@data/openCalendar/RoomEventInfo';
import OREventType from '@data/openCalendar/OREventType';
import classNames from 'classnames';
import { TIME_ONLY_FORMAT } from '@utilities/constants';
import getDateFromApiDateTime from '@calendar/utilities/getDateFromApiDateTime/getDateFromApiDateTime';
import roundEventTime from '@calendar/utilities/roundEventTime/roundEventTime';

/***
 * getORCalEventParser - returns EventParser to be used as part of useUnitEvents hook to translate response to EventInfo[]
 *
 * @param eventOverlapClassName {string} className for overlap style. This is applied to events that should be indented.
 * @param unavailableOverlapClassName {string} className for unavailable overlap style. This is applied to events that should be indented
 * @returns {(rooms: RoomInfo[], timezone: string) => EventInfo[]} Also referred to as EventParser. EventParser will flatten events to
 * EventInfo that can be used by Calendar component
 *
 * @example
 * useUnitEvent(undefined, undefined, getORCalEventParser(classes.overlap))
 *
 * @example
 * const roomInfo: RoomInfo[] = [
 *     {
 *       "name": "BH OR 01",
 *       "events": [
 *         {
 *           "id": "blockId_01",
 *           "name": "Block Name (surgeon or group)",
 *           "description": "",
 *           "start": "2023-03-27T12:00:00Z",
 *     		 "end": "2023-03-27T14:00:00Z",
 *           "type": "BLOCKTIME",
 *           "dateCreated": ""
 *         }
 *       ]
 *     }
 * ];
 * getORCalEventParser(classes.overlap)(roomEvents, timezone)
 * // result
 * {
 *     id: 'blockId_01',
 *     title: 'Block Name (surgeon or group)',
 *     subTitle: '',
 *     "start": "2023-03-27T12:00:00Z",
 *     "end": "2023-03-27T14:00:00Z",
 *     column: 'BH OR 01',
 *     type: 'BLOCKTIME'
 * }
 */
const getORCalEventParser: (eventOverlapClassName: string, unavailableOverlapClassName: string) => EventParser<string> = (eventOverlapClassName, unavailableOverlapClassName) =>
	(rooms: RoomInfo[], timezone: string, interval: number): EventInfo[] =>
		rooms.reduce((events: EventInfo[], room) => {
			const toEventInfo = (e: RoomEventInfo): EventInfo => {
				const start = getDateFromApiDateTime(e.start);
				const end = roundEventTime(getDateFromApiDateTime(e.end), interval);
				return {
					id: e.id,
					owner: e.owner,
					title: e.owner,
					subTitle: e.description,
					start: start,
					end: end,
					column: room.name,
					type: e.type,
					timeSpanDisplay: `${formatInTimeZone(start, timezone, TIME_ONLY_FORMAT)} - ${formatInTimeZone(end, timezone, TIME_ONLY_FORMAT)}`,
					dateCreated: e.dateCreated ? getDateFromApiDateTime(e.dateCreated) : undefined,
				};
			};

			const otherEvents = room.events.filter(e => e.type !== OREventType.UNAVAILABLE);
			const unavailableEvents: EventInfo[] = room.events
				.filter(e => e.type === OREventType.UNAVAILABLE)
				.map(e => ({
					...toEventInfo(e),
					additionalClassName: classNames({
						[unavailableOverlapClassName]: otherEvents.some((u) => doEventsOverlap(u, e)),
					})
				}));
			const standardEvents = otherEvents
				.filter(e => ![ OREventType.UNAVAILABLE, OREventType.BLOCKTIME ].includes(e.type));
			const modifiedBlockEvents: EventInfo[] = otherEvents
				.filter(e => e.type === OREventType.BLOCKTIME)
				.reduce<EventInfo[]>((modBlocks, block) => {
					const blockEventInfo = toEventInfo(block);
					const splitBlocks = splitRemoveByOverlapEvents(blockEventInfo, standardEvents)
						.map((evInfo) => ({
							...evInfo,
							additionalClassName: classNames({
								[eventOverlapClassName]: unavailableEvents.some((u) => doEventsOverlap(u, evInfo)),
							})
						}));

					return modBlocks.concat(splitBlocks);
				}, []);
			return events.concat(
				standardEvents.map((e) => {
					return {
						...toEventInfo(e),
						additionalClassName: classNames({
							[eventOverlapClassName]: unavailableEvents.some((u) => doEventsOverlap(u, e)),
						}),
					};
				}),
				unavailableEvents,
				modifiedBlockEvents,
			);
		}, []);

export default getORCalEventParser;
