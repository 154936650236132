import TimeSpanFormValue from '@components/UnitCalendarPage/EventTimeSpanInput/TimeSpanFormValue';
import { getZonedTimeFromTimeAndDate } from '@utilities/dateUtilities';
import { defaultTimeSpanValidationConfigs, NEXT_DAY_TIME, TIME_12_REGEX } from '@utilities/constants';
import { add } from 'date-fns';
import {
	INVALID_END_TIME,
	INVALID_START_TIME, INVALID_TIME_INCREMENTS,
	INVALID_TIME_SPAN,
	REQUIRED_END_TIME,
	REQUIRED_START_TIME
} from '@utilities/Validation/ValidationMessages';
import { EventInfo } from '@calendar';
import endpointTemplateToUrl from '@utilities/endpointTemplateToUrl';
import StartEndDate from '@interfaces/StartEndTime';

export interface CheckTimeRangeProps {
	timeSpan: PartialTimeSpan;
	overlapEvents: EventInfo[];
	overlapMsg: string;
}

export type PartialTimeSpan = Partial<TimeSpanFormValue>;

interface TimeErrorMessages {
	required: string;
	invalid: string;
}

export interface TimeSpanValidationConfigs {
	ignoreIncrements?: boolean;
}

const checkIndividualTime = (time: string | undefined, { required, invalid }: TimeErrorMessages) => {
	if (!time) {
		return [required];
	}

	if (!TIME_12_REGEX.test(time)) {
		return [invalid];
	}

	return [];
};

const checkTimeIncrement = (time: string, increment: number, msg: string): string | void => {
	const timeParts = TIME_12_REGEX.exec(time);
	if (!timeParts) {
		return;
	}

	const mins = parseInt(timeParts[2]);
	if (mins % increment !== 0) {
		return msg;
	}

	return;
};

export const getStartEndFromTimeSpan = (timeSpan: PartialTimeSpan): (StartEndDate | undefined) => {
	const { start, end, date = new Date(), timezone = 'America/Chicago' } = timeSpan;
	if (!start || !end) { return; }
	const startTime = getZonedTimeFromTimeAndDate(start, timezone, date);
	const endDate = end === NEXT_DAY_TIME ? add(date, { days: 1 }) : date;
	const endTime = getZonedTimeFromTimeAndDate(end, timezone, endDate);
	return {
		startTime,
		endTime
	};
};

export const validateStartEndTimeSpan = (timeSpan: PartialTimeSpan) => {
	const { startTime, endTime } = getStartEndFromTimeSpan(timeSpan) || {};
	if (!startTime || !endTime) { return; }
	if (startTime.getTime() >= endTime.getTime()) {
		return INVALID_TIME_SPAN;
	}
};

export enum Indicator {
	START = 'S',
	END = 'E',
}
export const decorateWithIndicator = (indicator: Indicator, msg: string) => {
	return `##${indicator}##${msg}`;
};

export const handleCommonTimeSpanValidation = (currentValue: PartialTimeSpan = {}, validationConfig: TimeSpanValidationConfigs = defaultTimeSpanValidationConfigs) => {
	const { start, end, interval = 15 } = currentValue;

	const errors: string[] =
		checkIndividualTime(start, {
			required: decorateWithIndicator(Indicator.START, REQUIRED_START_TIME),
			invalid: decorateWithIndicator(Indicator.START, INVALID_START_TIME),
		}).concat(
			checkIndividualTime(end, {
				required: decorateWithIndicator(Indicator.END, REQUIRED_END_TIME),
				invalid: decorateWithIndicator(Indicator.END, INVALID_END_TIME),
			})
		);

	if (!validationConfig.ignoreIncrements) {
		const incrementMsg = endpointTemplateToUrl(INVALID_TIME_INCREMENTS, { increment: interval.toString() });
		const startIncrementErr = checkTimeIncrement(start || '', interval, decorateWithIndicator(Indicator.START, incrementMsg));
		const endIncrementErr = checkTimeIncrement(end || '', interval, decorateWithIndicator(Indicator.END, startIncrementErr || incrementMsg));
		const hasIncrementErrorWithMsg = endIncrementErr || startIncrementErr; // only add once but use end when present since will include start indicator

		if (hasIncrementErrorWithMsg) {
			errors.push(hasIncrementErrorWithMsg);
		}
	}

	return errors;
};
