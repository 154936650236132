import React from 'react';
import { Button } from '@ascension/ui-library';
import { useHistory, useLocation } from 'react-router-dom';
import { navigateCaseQueue } from '../../../store/actions/CaseQueueActionCreators';
import { useDispatch, useSelector } from 'react-redux';
import { CaseQueueSelector } from '../../../store/selectors/CaseQueueSelector';
import { ROUTE_CONSTANTS } from '@utilities/RouteConstants';

const DynamicModalNavButton: React.FC = () => {
	const dispatch = useDispatch();
	const historyMethods = useHistory();
	const { search } = useLocation();
	const location = useLocation();
	const isQueueView = location.pathname.includes('queue');
	const { nextCaseIndex } = useSelector(CaseQueueSelector);
	const isAtEndOfQueue = nextCaseIndex === undefined;
	const isInQueueNotAtEnd = isQueueView && !isAtEndOfQueue;
	const innerText = isInQueueNotAtEnd ? 'Skip to Next Case' : 'Go Home';

	// This function will only be called when not at end of queue, which is when nextCaseIndex would be undefined
	// Therefore it is safe to assert as a number below
	const handleNavToNextQueueCase = () => {
		dispatch(navigateCaseQueue(nextCaseIndex as number));
		historyMethods.push({
			pathname: `/queue/${nextCaseIndex as number}`,
			search,
		});
	};

	const handleNavToHome = () => {
		historyMethods.push(ROUTE_CONSTANTS.HOME);
	};

	return (
		<Button
			data-field="case-access-nav-btn"
			translate="yes"
			variant="outlined"
			color="primary"
			onClick={isInQueueNotAtEnd ? handleNavToNextQueueCase : handleNavToHome}
		>
			{innerText}
		</Button>
	);
};

export default DynamicModalNavButton;
