import React from 'react';
import CalendarErrorTitle from '@components/CalendarError/presentationalComponents/CalendarErrorTitle';
import CalendarErrorContainer from '@components/CalendarError/presentationalComponents/CalendarErrorContainer';
import CalendarErrorBody from '@components/CalendarError/presentationalComponents/CalendarErrorBody';
import CalendarErrorButton from '@components/CalendarError/presentationalComponents/CalendarErrorButton';
import NoParamNoReturnCallback from '@interfaces/NoParamNoReturnCallback';

const UnableToLoadCalendarError: React.FC<{ onReloadClick: NoParamNoReturnCallback }> = ({ onReloadClick }) => {
	return (
		<CalendarErrorContainer>
			<CalendarErrorTitle>
				We are unable to load the calendar.
			</CalendarErrorTitle>
			<CalendarErrorBody>
				Please try reloading the page.
			</CalendarErrorBody>
			<CalendarErrorButton onClick={onReloadClick}>
				Reload
			</CalendarErrorButton>
		</CalendarErrorContainer>
	);
};

export default UnableToLoadCalendarError;
