import ComparisonOption from '@utilities/arrays/ComparisonOption';
import hasExactSameElements from '@utilities/arrays/hasExactSameElements';

/***
 * compareArrays with the given comparison option.
 * Exact equal (via reference or both values the same) return true in all comparison options.
 *
 * @param referenceArray {unknown[] | undefined}
 * @param unknownArray {unknown[] | undefined}
 * @param comparisonOption {ComparisonOption}
 *      - UNDEFINED - throws error
 *      - EXACT - true when exact number and items exist in both arrays
 *      - ALL - true when all options in referenceArray exist in unknownArray
 *      - ANY - true when any options match (partial overlap)
 *
 * @throws error when comparisonOption falsy
 * @returns {boolean} true when both undefined or match
 */
const compareArrays = (referenceArray: unknown[] | undefined, unknownArray: unknown[] | undefined, comparisonOption: ComparisonOption): boolean => {
	if (!comparisonOption) {
		throw new Error('comparisonOption required to compare arrays');
	}

	// No need to attempt expensive loops when they're the same. Includes both arrays undefined
	if (referenceArray === unknownArray) {
		return true;
	}

	// If either is not an array or only one is undefined, return false
	if (!Array.isArray(referenceArray) || !Array.isArray(unknownArray)) {
		return false;
	}

	if (!referenceArray.length && !unknownArray.length) {
		return true;
	}

	switch (comparisonOption) {
	case ComparisonOption.EXACT:
		return hasExactSameElements(referenceArray, unknownArray);

	// All elements in referenceArray exist in the unknownArray
	case ComparisonOption.ALL:
		return referenceArray
			.every(elm => unknownArray.includes(elm));

	// Any one element in referenceArray exists in the unknownArray
	case ComparisonOption.ANY:
		return referenceArray
			.some(rElm => unknownArray.includes(rElm));
	}
};



export default compareArrays;
