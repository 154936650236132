import { AxiosError } from 'axios';
import { AlertMessage } from './alertMessages';

const GENERIC_ERROR = 'error';
const templateErrorToAlertMessage = (err: AxiosError<string>) => {
	let normalizedApiError = err.response ? err.response.status.toString() : GENERIC_ERROR;
	if (normalizedApiError === '500') {
		normalizedApiError = err.response && err.response.data ?
			err.response.data :
			GENERIC_ERROR;
		normalizedApiError = normalizedApiError.substr(-14, 14);
	}

	switch (normalizedApiError) {
	case '417':
	case 'n\'t be deleted':
	case '\'t be deleted.':
		return AlertMessage.TEMPLATE_IN_USE;
	case '400':
	case ' doesn\'t exist':
		return AlertMessage.TEMPLATE_NOT_FOUND;
	}
	return AlertMessage.UNKNOWN;
};

export default templateErrorToAlertMessage;
