import OREventType from '@data/openCalendar/OREventType';
import EventStyleDescriptor from '@interfaces/OpenCalendar/EventStyleDescriptor';

const OfficeEventStyleConfig: Record<OREventType.UNAVAILABLE | OREventType.AVAILABLE, EventStyleDescriptor> = {
	[OREventType.AVAILABLE]: {
		legend: {
			background: '#FFF',
			border: '2px solid #E0E0E0',
		},
	},
	[OREventType.UNAVAILABLE]: {
		legend: {
			background: 'repeating-linear-gradient(-45deg, #EEEEEE, #EEEEEE 5px, #E0E0E0 5px, #E0E0E0 10px)',
			border: '1px solid #9E9E9E',
		},
	},
};

export default OfficeEventStyleConfig;
