import React from 'react';
import { ManageCaseOption } from '@components/CaseDetails/ManageCaseDropDown/ManageCaseOption';
import { useCopyCaseState } from '@components/CaseDetails/CopyCaseContext/CopyCaseContext';
import { useSelector } from 'react-redux';
import { CaseDetailsIsAttachmentType } from '@store/selectors/CaseDetailsSelector';
import { RenderAsProps } from '@components/CaseDetails/ManageCaseDropDown/DropDownOptions/DropDownOptionProps';

export const CopyCaseOption = ({ closeDropdown }: RenderAsProps) => {
	const { openModal } = useCopyCaseState();
	const hideOption = useSelector(CaseDetailsIsAttachmentType);

	const handleOpenCopyModal = () => {
		openModal();
		closeDropdown();
	};

	return (
		<>
			{ !hideOption && <ManageCaseOption onClick={handleOpenCopyModal} fieldName="copyCase">Copy</ManageCaseOption> }
		</>
	);
};
