import React from 'react';
import { Popover } from '@material-ui/core';
import { CustomPopoverProps } from '@components/CustomPopover/CustomPopoverProps';
import { useAnchorContext } from '@components/AnchorProvider/AnchorProvider';

export const CustomPopover: React.FC<CustomPopoverProps> = ({ onClose, paperProps, children }) => {
	const { anchorEl, handleClearAnchorEl } = useAnchorContext();

	const handlePopoverClose = () => {
		handleClearAnchorEl();
		if (onClose) {
			onClose();
		}
	};

	return (
		<Popover
			open={!!anchorEl}
			anchorEl={anchorEl}
			onClose={handlePopoverClose}
			PaperProps={{
				...paperProps,
				style: {
					width: anchorEl?.offsetWidth
				}
			}}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
			transitionDuration={0}
		>
			{children}
		</Popover>
	);
};
