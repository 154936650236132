import React from 'react';
import { Box, Chip as MuiChip, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { ChipProps } from '@components/Chip/ChipProps';

const useStyles = makeStyles((theme: Theme) => ({
	chipWrapper: {
		'&& .MuiChip-root': {
			color: theme.palette.grey[900],
			fontWeight: 500,
			textTransform: 'capitalize',
		},
		'&& .default': {
			border: `1px solid ${theme.palette.grey[500]}`,
			backgroundColor: theme.palette.grey[50],
		},
		'&& .error': {
			border: `1px solid ${theme.palette.error.main}`,
			backgroundColor: theme.palette.error[50],
		},
		'&& .primary': {
			border: `1px solid ${theme.palette.primary.main}`,
			backgroundColor: theme.palette.primary[50],
		},
		'&& .secondary': {
			border: `1px solid ${theme.palette.secondary.main}`,
			backgroundColor: theme.palette.secondary[50],
		},
		'&& .success': {
			border: `1px solid ${theme.palette.success.main}`,
			backgroundColor: theme.palette.success[50],
		},
		'&& .warning': {
			border: `1px solid ${theme.palette.warning.main}`,
			backgroundColor: theme.palette.warning[50],
		},
	},
}));

const Chip: React.FC<ChipProps> = (
	{
		color,
		children,
		className,
		onDelete,
	}
) => {
	const classes = useStyles();

	return (
		<Box className={classes.chipWrapper}>
			<MuiChip
				label={children}
				onDelete={onDelete}
				className={classNames(color, { default: !color }, className)}
			/>
		</Box>
	);
};

export default Chip;
