import React from 'react';
import { Box, FormHelperText, InputLabel, MenuItem } from '@material-ui/core';
import { Dropdown } from '@ascension/ui-library';
import { makeStyles } from '@material-ui/core/styles';
import { DOBMonthDayInputProps } from './DOBMonthDayInputProps';
import classNames from 'classnames';
import ErrorMessageMui from '../ErrorMessageMui/ErrorMessageMui';

const useStyles = makeStyles(theme => ({
	container: {
		'display': 'flex',
		'flexDirection': 'column',
		'& .MuiInputLabel-root': {
			marginBottom: theme.spacing(1.5),
			fontWeight: 600,
		},
		'& .MuiFormHelperText-root.Mui-error': {
			display: 'none',
		},
		'&:focus-within #date-of-birth-label:not(.Mui-error)': {
			color: theme.palette.primary.main,
		},
		'&:focus-within .MuiFormHelperText-root:not(.Mui-error)': {
			color: theme.palette.primary.main,
		},
	},

	disableBorderOverride: {
		'& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
			borderColor: 'rgba(0, 0, 0, 0.23)',
		},
	},
	errorBorder: {
		'& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
			borderColor: theme.palette.error.main,
		},
	},
	monthDropdown: {
		minWidth: '238px',
		marginRight: theme.spacing(2),
	},
	dayDropdown: {
		minWidth: '149px',
	},
	formControl: {
		margin: 'unset',
	},
}));

const monthDayLimitsConfig = [
	{ month: 'January', maxDays: 31 },
	{ month: 'February', maxDays: 29 },
	{ month: 'March', maxDays: 31 },
	{ month: 'April', maxDays: 30 },
	{ month: 'May', maxDays: 31 },
	{ month: 'June', maxDays: 30 },
	{ month: 'July', maxDays: 31 },
	{ month: 'August', maxDays: 31 },
	{ month: 'September', maxDays: 30 },
	{ month: 'October', maxDays: 31 },
	{ month: 'November', maxDays: 30 },
	{ month: 'December', maxDays: 31 },
];

const DOBMonthDayInput: React.FC<DOBMonthDayInputProps> = ({
	dayVal,
	setDayVal,
	dayError,
	monthVal,
	setMonthVal,
	monthError,
	required = false,
	label = 'Date of birth',
}) => {
	const classes = useStyles();

	const monthOptions = monthDayLimitsConfig.map(m => m.month);

	const dayOptions = React.useMemo(() => {
		if (typeof monthVal !== 'number') { return; }
		const dayMenuItems = [];
		for (let i = 1; i <= monthDayLimitsConfig[monthVal - 1].maxDays; i++) { dayMenuItems.push(`${i}`); }
		return dayMenuItems;
	}, [monthVal]);

	const setSelectedMonth = (val: string) => {
		const index = monthDayLimitsConfig.findIndex(m => m.month === val);
		setMonthVal(index + 1);
	};

	const setSelectedDay = (val: string) => {
		setDayVal(Number(val));
	};

	const errorMessage = monthError || dayError;

	return (
		<Box
			className={classNames(classes.container, {
				[classes.errorBorder]: !!dayError,
				[classes.disableBorderOverride]: !dayError && typeof monthVal !== 'number'
			})}
		>
			<InputLabel id="date-of-birth-label" required={required} error={!!errorMessage}>{label}</InputLabel>
			<Box>
				<Dropdown
					label="Month"
					value={typeof monthVal === 'number' ? monthDayLimitsConfig[monthVal - 1].month : ''}
					select={setSelectedMonth}
					translate="yes"
					className={classes.monthDropdown}
					formControlStyle={classes.formControl}
					labelId="date-of-birth-label"
					error={!!monthError}
				>
					{ monthOptions.map(m => <MenuItem key={m} value={m}>{m}</MenuItem>) }
				</Dropdown>
				<Dropdown
					label="Day"
					value={dayVal ? dayVal.toString() : ''}
					select={setSelectedDay}
					disabled={typeof monthVal !== 'number'}
					translate="yes"
					className={classes.dayDropdown}
					formControlStyle={classes.formControl}
					error={!!dayError}
				>
					{ dayOptions && dayOptions.map(d => <MenuItem key={d} value={d}>{d}</MenuItem>) }
				</Dropdown>
			</Box>
			<FormHelperText>Only birth month and day are needed</FormHelperText>
			{errorMessage && <ErrorMessageMui errorMessage={errorMessage}/>}
		</Box>
	);
};

export default DOBMonthDayInput;
