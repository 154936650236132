import {
	CLEAR_CASE_QUEUE, GET_CASE_QUEUE_LIST,
	NAVIGATE_CASE_QUEUE, QUEUE_STATUS_CLEAR,
	SET_CASE_QUEUE,
} from './types';
import { CaseQueueInfo } from '@interfaces/CaseQueue/CaseQueueInfo';
import {
	CaseTrackerListInterface,
} from '@components/CaseTrackerList/CaseTrackerListInterface';
import { ApiActionRequest } from '@interfaces/ApiAction/ApiAction';
import { apiAction } from '../middlewares/api/ApiActionCreators';
import { ENDPOINT_SURGERY_SEARCH_API } from '@utilities/apiConstants';
import { ApiResponseType } from '@interfaces/ApiAction/ApiActionPayload';
import { mapCaseDetailsApiToQueueInfo } from '@utilities/caseQueue/mapCaseDetailsApiToQueueInfo';
import { Action } from 'redux';
import {
	CaseQueueAction,
	CaseQueueActionNavigate,
	CaseQueueActionSet,
} from '@interfaces/CaseQueue/CaseQueueAction';
import { FilterApiParams } from '@interfaces/FilterParams';

export const setCaseQueue = (caseQueue: CaseQueueInfo[] = [], startIndex = 0): CaseQueueActionSet => {
	return {
		type: SET_CASE_QUEUE,
		payload: {
			queueList: caseQueue,
			positionIndex: startIndex,
		}
	};
};

export const clearCaseQueue = () => {
	return {
		type: CLEAR_CASE_QUEUE,
	};
};

export const navigateCaseQueue = (toIndex: number): CaseQueueActionNavigate => {
	return {
		type: NAVIGATE_CASE_QUEUE,
		payload: {
			positionIndex: toIndex,
		}
	};
};

export function updateCaseQueue(startIndex: number): (response: ApiResponseType<CaseTrackerListInterface[]>) => Action {
	return ({ data }: ApiResponseType<CaseTrackerListInterface[]>) =>
		setCaseQueue(mapCaseDetailsApiToQueueInfo(data), startIndex);
}

export function getQueueList(startIndex = 0, params?: FilterApiParams): ApiActionRequest<FilterApiParams | undefined, unknown, CaseTrackerListInterface[]> {
	return apiAction<FilterApiParams | undefined, unknown, CaseTrackerListInterface[]>({
		url: ENDPOINT_SURGERY_SEARCH_API,
		method: 'POST',
		label: GET_CASE_QUEUE_LIST,
		data: params,
		includeAccessToken: true,
		onSuccess: updateCaseQueue(startIndex),
	});
}

export function clearQueueStatusSubmitted (): CaseQueueAction {
	return {
		type: QUEUE_STATUS_CLEAR
	};
}
