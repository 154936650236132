import React from 'react';
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import { TextField } from '@ascension/ui-library';
import { makeStyles } from '@material-ui/core/styles';
import { useFieldFormContext } from '../../../utilities/hooks/useFieldFormContext/useFieldFormContext';
import { PatientDetailsValidationSchema } from '../../RequestForm/PatientDetails/PatientDetailsValidationSchema';
import { useMaskFormContext } from '../../../utilities/hooks/useMaskFormContext/useMaskFormContext';
import { INVALID_EMAIL, REQUIRED_DROPDOWN_NO_OPTION_SELECTED } from '../../../utilities/Validation/ValidationMessages';
import ErrorMessageMui from '../../ErrorMessageMui/ErrorMessageMui';
import { UserRegistrationRole } from '../../../interfaces/UserProfile/UserRoleEnum';
import { RegisterOptions } from 'react-hook-form';
import DOBMonthDayInputWrapper from './DOBMonthDayInputWrapper/DOBMonthDayInputWrapper';
import LastFourSSNInput from './LastFourSSNInput/LastFourSSNInput';
import AccountInformation from './AccountInformation/AccountInformation';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
	},
	label: {
		fontWeight: 600,
		paddingBottom: theme.spacing(1.5),
	},
	radioGroup: {
		'width': 'fit-content',
		'& .MuiRadio-root': {
			paddingTop: theme.spacing(.5),
			paddingBottom: theme.spacing(.5),
		},
	},
	fullNameRow: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		gap: theme.spacing(3),
	},
	phoneExtRow: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'start',
		alignItems: 'baseline',
		gap: theme.spacing(3),
	},
	rowSpacing: {
		marginTop: theme.spacing(4),
	},
	halfWidthInput: {
		width: '49%',
	},
	flexColumn: {
		display: 'flex',
		flexDirection: 'column',
	},
	nameInput: {
		flexGrow: 1,
	},
	lastNameInput: {
		marginTop: theme.spacing(3),
	},
	phoneInput: {
		width: '240px',
	},
	extInput: {
		width: '148px',
	},
	dobInput: {
		marginBottom: theme.spacing(4),
		marginTop: theme.spacing(4),
	},
	lastFourSsnInput: {
		marginBottom: theme.spacing(4),
	},
}));

const validationRules: Record<string, RegisterOptions> = {
	userRole: {
		required: REQUIRED_DROPDOWN_NO_OPTION_SELECTED
	},
	email: {
		...PatientDetailsValidationSchema.patientEmail,
		required: INVALID_EMAIL
	}
};

const BasicUserInfoForm: React.FC = () => {
	const {
		currentValue: userRoleCurrentValue = null,
		setCurrentValue: userRoleSetCurrentValue,
		error: userRoleError,
	} = useFieldFormContext<null | UserRegistrationRole>('userRole', {
		validationRules: validationRules.userRole,
		triggerValidationOnChange: true,
	});

	const {
		currentValue: firstNameCurrentValue = '',
		setCurrentValue: setFirstNameCurrentValue,
		error: firstNameError,
		triggerSelfValidation: firstNameTriggerSelfValidation,
	} = useFieldFormContext<string>('firstName', {
		validationRules: PatientDetailsValidationSchema.patientFirstName,
	});

	const {
		currentValue: lastNameCurrentValue = '',
		setCurrentValue: setLastNameCurrentValue,
		error: lastNameError,
		triggerSelfValidation: lastNameTriggerSelfValidation,
	} = useFieldFormContext<string>('lastName', {
		validationRules: PatientDetailsValidationSchema.patientLastName,
	});

	const {
		currentValue: emailCurrentValue = '',
		setCurrentValue: setEmailCurrentValue,
		error: emailError,
		triggerSelfValidation: emailTriggerSelfValidation,
	} = useFieldFormContext<string>('email', {
		validationRules: validationRules.email,
	});

	const [
		phoneNumberCurrentValue = '',
		setPhoneNumberCurrentValue,
		phoneNumberError,
		phoneNumberPlaceholder,
		phoneNumberTriggerSelfValidation
	] = useMaskFormContext('phone', 'phone', {
		validationRules: PatientDetailsValidationSchema.patientPhoneNumber,
		triggerValidationOnChange: false,
	});

	const {
		currentValue: phoneExtensionCurrentValue = '',
		setCurrentValue: setPhoneExtensionCurrentValue,
	} = useFieldFormContext<string>('ext');

	const classes = useStyles();

	return (
		<Box className={classes.container}>
			<FormControl required className={classes.rowSpacing} error={!!userRoleError}>
				<FormLabel id="user-role-radio-buttons-group" className={classes.label}>User role</FormLabel>
				<RadioGroup
					aria-labelledby="user-role-radio-buttons-group"
					aria-describedby="user-role-error"
					id="userRole"
					name="userRole"
					className={classes.radioGroup}
					value={userRoleCurrentValue}
					onChange={(e) => userRoleSetCurrentValue(e.target.value as UserRegistrationRole)}
				>
					<FormControlLabel value={UserRegistrationRole.OFFICE_MANAGER} control={<Radio color="primary"/>} label="Office Manager" data-field="manager-role-option"/>
					<FormControlLabel value={UserRegistrationRole.OFFICE_SCHEDULER} control={<Radio color="primary"/>} label="Office Scheduler" data-field="scheduler-role-option"/>
					<FormControlLabel value={UserRegistrationRole.OFFICE_VIEWER} control={<Radio color="primary"/>} label="Office Viewer" data-field="viewer-role-option"/>
				</RadioGroup>
				{ userRoleError &&
					<span id="user-role-error">
						<ErrorMessageMui errorMessage={userRoleError}/>
					</span>
				}
			</FormControl>
			<Box className={`${classes.fullNameRow} ${classes.rowSpacing}`}>
				<Box className={`${classes.flexColumn} ${classes.nameInput}`}>
					<TextField
						translate="yes"
						type="text"
						variant="static"
						id="firstName"
						name="firstName"
						data-field="firstNameInput"
						label="User's full legal name"
						required
						value={firstNameCurrentValue}
						onChange={(e) => setFirstNameCurrentValue(e.target.value)}
						onBlur={firstNameTriggerSelfValidation}
						error={!!firstNameError}
						helperText="First name"
						aria-describedby="first-name-error"
					/>
					{ firstNameError &&
						<span id="first-name-error">
							<ErrorMessageMui errorMessage={firstNameError}/>
						</span>
					}
				</Box>
				<Box className={`${classes.nameInput} ${classes.lastNameInput} ${classes.flexColumn}`}>
					<TextField
						translate="yes"
						type="text"
						variant="static"
						id="lastName"
						name="lastName"
						data-field="lastNameInput"
						required
						value={lastNameCurrentValue}
						onChange={(e) => setLastNameCurrentValue(e.target.value)}
						onBlur={lastNameTriggerSelfValidation}
						error={!!lastNameError}
						helperText="Last name"
						aria-describedby="last-name-error"
					/>
					{ lastNameError &&
						<span id="last-name-error">
							<ErrorMessageMui errorMessage={lastNameError}/>
						</span>
					}
				</Box>
			</Box>
			<TextField
				translate="yes"
				type="text"
				variant="static"
				label="User's email address"
				name="email"
				id="email"
				data-field="emailInput"
				required
				className={`${classes.rowSpacing} ${classes.halfWidthInput}`}
				value={emailCurrentValue}
				onChange={(e) => setEmailCurrentValue(e.target.value)}
				onBlur={emailTriggerSelfValidation}
				error={!!emailError}
				aria-describedby="email-error"
			/>
			{ emailError &&
				<span id="email-error">
					<ErrorMessageMui errorMessage={emailError}/>
				</span>
			}
			<Box className={classes.phoneExtRow}>
				<Box className={classes.flexColumn}>
					<TextField
						translate="yes"
						type="text"
						variant="static"
						label="User's phone number"
						id="phone"
						name="phone"
						data-field="userPhoneInput"
						required
						className={`${classes.rowSpacing} ${classes.phoneInput}`}
						value={phoneNumberCurrentValue}
						placeholder={phoneNumberPlaceholder}
						onChange={(e) => setPhoneNumberCurrentValue(e.target.value)}
						onBlur={phoneNumberTriggerSelfValidation}
						error={!!phoneNumberError}
						aria-describedby="phone-error"
					/>
					{ phoneNumberError &&
						<span id="phone-error">
							<ErrorMessageMui errorMessage={phoneNumberError}/>
						</span>
					}
				</Box>
				<TextField
					translate="yes"
					type="text"
					variant="static"
					label="Extension"
					id="ext"
					name="ext"
					data-field="userExtensionInput"
					className={`${classes.extInput}`}
					value={phoneExtensionCurrentValue}
					onChange={(e) => setPhoneExtensionCurrentValue(e.target.value)}
				/>
			</Box>
			<Box className={classes.dobInput}>
				<DOBMonthDayInputWrapper />
			</Box>
			<Box className={classes.lastFourSsnInput}>
				<LastFourSSNInput />
			</Box>
			<AccountInformation />
		</Box>
	);
};

export default BasicUserInfoForm;
