import MyInformation from '../MyInformation/MyInformation';
import { RoleOptionDescriptor } from './RoleOptionDescriptorInterface';
import { FunctionComponent } from 'react';
import MyNotifications from '../MyNotifications/MyNotifications';
import MyTemplates from '../MyTemplates/MyTemplates';
import MyFilters from '../MyFilters/MyFilters';

export const OFFICE_OPTIONS: RoleOptionDescriptor[] = [
	{
		path: '/accountSettings/myInformation',
		linkText: 'My Information',
		card: MyInformation as FunctionComponent,
		id: 'myInformation',
	},
	{
		path: '/accountSettings/myNotifications',
		linkText: 'My Notifications',
		card: MyNotifications as FunctionComponent,
		id: 'myNotifications',
	},
	{
		path: '/accountSettings/myFilters',
		linkText: 'My Filters',
		card: MyFilters as FunctionComponent,
		id: 'myFilters'
	},
	{
		path: '/accountSettings/myTemplates',
		linkText: 'My Templates',
		card: MyTemplates as FunctionComponent,
		id: 'myTemplates',
	}
];
