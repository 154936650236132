import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Icon, Flex } from '@ascension/web';
import { RibbonHollowProps } from './RibbonHollowProps';
import { bookmarkCase } from '@store/actions/CaseDetailsActionCreators';

export const RibbonHollow= (props: RibbonHollowProps) => {
	const dispatch = useDispatch();
	const [active, setActive] = React.useState(false);
	React.useEffect(()=>{
		setActive(props.bookmarked);
	}, [props.bookmarked]);
	const functionCallback = React.useCallback( ()=>{
		setActive(!active);
	}, [active, setActive]);
	const handleClick = React.useCallback((event: Event)=> {
		event.stopPropagation();
		dispatch(bookmarkCase(props.requestId, functionCallback));
	}, [dispatch, props.requestId, functionCallback]);
	return (
		<Box  className="ribbonhollow">
			<Flex onClick={handleClick} data-field="ribbon-container"><Icon name={active ? 'ribbonsolid' : 'ribbonhollow' } color={active ? '#1E69D2' : '#999999' } size={16}/></Flex>
		</Box>
	);
};
