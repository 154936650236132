import { ScheduleEventCardData } from '@data/schedules/types/ScheduleEventCardData';
import minuteDurationToHrMinStr from '@utilities/minuteDurationToHrMinStr/minuteDurationToHrMinStr';
import { getInverseEvents } from '@calendar';
import { formatInTimeZone } from 'date-fns-tz';

export const decorateEventsWithGaps = (events: ScheduleEventCardData[], hospitalTimeZone: string): ScheduleEventCardData[] => {
	// get gaps between events
	const gapsBetweenEvents = getInverseEvents([...events.map(e => ({ start: e.startDate, end: e.endDate }))]);
	return events.map((event, index) => {
		// find the gap after the event
		const gapAfter = gapsBetweenEvents.find(e => e.start.getTime() === event.endDate.getTime());
		const nextEvent = events[index + 1];
		// verify the following event is after the current event
		if (gapAfter && nextEvent.startDate.getTime() > event.endDate.getTime()) {
			const gapStart = gapAfter.start;
			const gapEnd = gapAfter.end;
			const durationMinutes = (gapEnd.getTime() - gapStart.getTime())/(1000*60);
			// gap must be at least 45 minutes to be displayed
			if (durationMinutes < 45) return event;
			const rangeDisplay = `${formatInTimeZone(gapStart, hospitalTimeZone, 'h:mm a')} - ${formatInTimeZone(gapEnd, hospitalTimeZone, 'h:mm a')}`;
			const durationDisplay = minuteDurationToHrMinStr(durationMinutes);
			event.gapAfterDisplay = {
				timeRange: rangeDisplay,
				durationHrsMins: durationDisplay,
			};
		}
		return event;
	});
};
