import React, { useContext } from 'react';
import {
	DocumentRequestContextType,
	SelectedDocIndexAction,
	SelectedDocumentAction
} from './DocumentRequestContextType';
import { useFormContext } from 'react-hook-form';
import { RequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import { useMultiFormValueSetter } from '@components/RequestForm/utilities/MultiFormAdapter/MultiFormAdapter';
const DocumentRequestContext = React.createContext<DocumentRequestContextType | null>(null);

export const useDocumentRequestContext = () => {
	const ctx = useContext(DocumentRequestContext);

	if (!ctx) {
		throw new Error('DocumentRequestContext must be used in a sub component of DocumentRequestContextProvider');
	}

	return ctx;
};

const DEFAULT_SELECTED_INDEX_ACTION: SelectedDocIndexAction = {
	index: null,
	action: SelectedDocumentAction.NONE,
};

export const DocumentRequestContextProvider: React.FC = ({ children }) => {
	const [selectedIndexAndAction, setSelectedIndexAndAction] = React.useState<SelectedDocIndexAction>(DEFAULT_SELECTED_INDEX_ACTION);
	const { getValues } = useFormContext<RequestFormValidatedState>();

	const setDocuments = useMultiFormValueSetter('documents');
	const handleCloseActionModal = React.useCallback(() => {
		setSelectedIndexAndAction({
			index: null, // Set selected to null so that modal closes
			action: SelectedDocumentAction.NONE,
		});
	}, []);

	const handleDeleteDocument = React.useCallback(() => {
		if (selectedIndexAndAction.index === null) return; // No document to delete - modal shouldn't even be open

		const { documents = [] } = getValues();

		const documentsLeftOver = documents.filter((i, index) => index !== selectedIndexAndAction.index);
		setDocuments(documentsLeftOver);
		handleCloseActionModal();
	}, [getValues, handleCloseActionModal, selectedIndexAndAction.index, setDocuments]);

	const selectedDocument = React.useMemo(() => {
		const { documents = [] } = getValues();

		if (selectedIndexAndAction.index === null) { return; }

		return documents[selectedIndexAndAction.index];
	}, [getValues, selectedIndexAndAction.index]);

	return (
		<DocumentRequestContext.Provider value={{
			selectedDocument,
			handleDeleteDocument,
			handleCloseActionModal,
			selectedIndexAndAction,
			setSelectedIndexAndAction,
		}}>
			{children}
		</DocumentRequestContext.Provider>
	);
};
