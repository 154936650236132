const hasExactSameElements = (val1: unknown, val2: unknown): boolean => {
	if (val1 === val2) {
		return true;
	}

	if (!Array.isArray(val1) || !Array.isArray(val2)) {
		return false;
	}

	return JSON.stringify(val1.sort()) === JSON.stringify(val2.sort());
};

export default hasExactSameElements;
