import { useSelector } from 'react-redux';
import { Surgeon } from '@data/surgeon/Surgeon';
import { useCollectionEntity } from '@utilities/hooks/useCollectionEntity/useCollectionEntity';
import { ENDPOINT_ALL_SURGEONS } from '@utilities/apiConstants';
import { RootState } from '@interfaces/rootState';
import { UserProfilePrimaryPracticeSelectorId } from '@store/selectors/UserProfileSelector';
import { getFormattedName } from '@utilities/commonFunction';

const useSurgeonName = (surgeonId: string, defaultVal = '---') => {
	const practiceId = useSelector<RootState, string>(UserProfilePrimaryPracticeSelectorId);

	const surgeon = useCollectionEntity<Surgeon>(ENDPOINT_ALL_SURGEONS + practiceId, surgeonId) || {};

	return getFormattedName(surgeon, defaultVal);
};

export default useSurgeonName;
