import React from 'react';
import { createStyles, Typography, TypographyProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(theme => createStyles({
	root: {
		marginTop: theme.spacing(1),
		textAlign: 'center'
	},
}));

const CalendarErrorBody: React.FC<TypographyProps> = ({ children, className }) => {
	const classes = useStyles();

	return (
		<Typography
			variant="body1"
			className={classNames(classes.root, className)}
		>
			{children}
		</Typography>
	);
};

export default CalendarErrorBody;
