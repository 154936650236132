import { Reducer } from 'redux';
import {
	UPDATE_USER_PROFILE,
	USER_PROFILE_ERROR,
	UPDATE_USER_PROFILE_NOTIFICATION,
	UPDATE_USER_PROFILE_TERMS_OF_USE,
	UPDATE_USER_SAVED_FILTER,
	UPDATE_USER_PROFILE_STATUS, UPDATE_USER_DOC_VIEW_PERMISSION
} from '../actions/types';
import { UserProfileState, UserProfileActionTypes } from '../../interfaces/UserProfile/UserProfileStoreInterface';
import { UserDetailsApiInterface } from '../../interfaces/UserPrefInterface';
import { parseFilterUrl } from '../../utilities/filtersUtilities';

const initialState: UserProfileState = {
	userProfileDetails: {} as UserDetailsApiInterface,
	error: null
};

export const UserProfileReducer: Reducer<UserProfileState, UserProfileActionTypes>
	= (state: UserProfileState = initialState, action: UserProfileActionTypes) => {
		switch (action.type) {
		case UPDATE_USER_PROFILE:
			return {
				...state,
				userProfileDetails: {
					...action.payload,
					primaryHospital: action.payload.preference?.hospitals?.[0],
					savedFilters: parseFilterUrl(action.payload.filterUrl || '')
				} as UserDetailsApiInterface,
			};
		case UPDATE_USER_SAVED_FILTER:
			return {
				...state,
				userProfileDetails: {
					...state.userProfileDetails,
					savedFilters: {
						...state.userProfileDetails.savedFilters,
						...action.payload
					}
				}
			};
		case UPDATE_USER_PROFILE_NOTIFICATION: {
			return {
				...state,
				userProfileDetails: {
					...state.userProfileDetails,
					preference: {
						...state.userProfileDetails.preference,
						emailNotification: action.payload
					}
				} as UserDetailsApiInterface
			};
		}
		case UPDATE_USER_PROFILE_TERMS_OF_USE: {
			return {
				...state,
				userProfileDetails: {
					...state.userProfileDetails,
					termsOfUseAccepted: action.payload
				} as UserDetailsApiInterface
			};
		}
		case USER_PROFILE_ERROR:
			return {
				...state,
				error: action.error
			};
		case UPDATE_USER_PROFILE_STATUS:
			return {
				...state,
				userProfileDetails: {
					...state.userProfileDetails,
					status: action.payload
				} as UserDetailsApiInterface
			};
		case UPDATE_USER_DOC_VIEW_PERMISSION:
			return {
				...state,
				userProfileDetails: {
					...state.userProfileDetails,
					docViewPermission: action.payload
				} as UserDetailsApiInterface
			};
		}

		return state;
	};
