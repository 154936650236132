import Interval from '@interfaces/Interval';
import getOverlapInterval from '@utilities/getOverlapInterval/getOverlapInterval';
import { add, addMinutes, differenceInMinutes, isAfter, isWithinInterval, sub } from 'date-fns';
import { isSameMinute } from 'date-fns/fp';
import { ScheduleEventCardData } from '@data/schedules/types/ScheduleEventCardData';

const TO_FOLLOW_GAP_DURATION = { minutes: 1 };

/**
 * calculateBlockDetails
 *
 * @param timeFrames {Interval[]} - Array<{start: Date, end: Date}> - the time frames for the single block
 * @param events {BlockEventCardData[]} - Events that belong to the same block sorted with the earliest start time first
 * @param gapDuration {Duration} - default {minutes: 1} - Duration description to use to determine if there is a gap.
 *
 * @returns {
 *     overlapMinutes: number;
 *     toFollowTime: Date | undefined;
 * }
 * 		- overlap minutes is the amount of time that the events are overlapping one of the blocks.
 * 		- toFollowTime is the first time when there is at least 1 minute gap, hugs to start of first block
 * 			or returns start of first block when no other gaps
 *
 * @example
 * calculateBlockDetails(
 * 	[
 * 	    { start: new Date(2020-10-05T07:00), end: new Date(2020-10-05T12:00) }, // 5 hrs
 * 	    { start: new Date(2020-10-05T14:00), end: new Date(2020-10-05T16:00) }, // 2 hrs
 * 	],
 * 	[
 * 		{ start: new Date(2020-10-05T006:00), end: new Date(2020-10-05T08:30) }, // 2.5 hrs, only 1.5 within block
 * 		{ start: new Date(2020-10-05T008:30), end: new Date(2020-10-05T09:30) }, // 1 hr all within block
 * 	    { start: new Date(2020-10-05T011:00), end: new Date(2020-10-05T12:30) }, // 1.5 hrs, only 1 within block
 * 	    { start: new Date(2020-10-05T14:00), end: new Date(2020-10-05T15:00) }, // 1 hr all within block
 * 	]
 * );
 * // Returns { overlapMinutes: 270, toFollowTime: new Date(2020-10-05T09:30) }
 */
const calculateBlockDetails = (timeFrames: Interval[], events: ScheduleEventCardData[], gapDuration: Duration = TO_FOLLOW_GAP_DURATION) => {
	let overlapMinutes = 0;
	let toFollowTime: Date | undefined | null = undefined;

	for (const e of events) {
		const normalizedEvent = {
			start: e.startDate,
			end: e.duration && e.duration < e.durationCalcMins ? addMinutes(e.startDate, e.durationCalcMins) : e.endDate,
		};

		for (const timeFrame of timeFrames) {
			const overlapInterval = getOverlapInterval(normalizedEvent, timeFrame);
			if (!overlapInterval) { continue; }

			// FIRST EVENT - first event within timeframe. Will be null or defined otherwise
			const isFirstEvent = toFollowTime === undefined;

			// (ALREADY) FULL or START GAP - already determined to be full or start gap so leave null so that default to start time
			const isAlreadyDeterminedFullOrStartGap = toFollowTime === null;

			// FULL: ends at or past the (end - gap) so block is considered full
			const isFull = isWithinInterval(overlapInterval.end, { start: sub(timeFrame.end, gapDuration), end: timeFrame.end });

			// SCHEDULE GAP - leave as is. Event occurs after the (toFollow + gap) - this is a big enough gap to schedule into
			const hasGapToPreviousFollow = toFollowTime && isAfter(overlapInterval.start, add(toFollowTime, gapDuration));

			// CONTAINED: Current follow time is after - this event is contained within a previous event - skip
			const isContainedInPrevEvent = toFollowTime && isAfter(toFollowTime, overlapInterval.end);

			if (isFirstEvent) {
				// START GAP - Event doesn't start at beginning (+defined allowed gap) of block
				const hasGapAtStart =
					!isWithinInterval(overlapInterval.start, { start: timeFrame.start, end: add(timeFrame.start, gapDuration) });

				if (hasGapAtStart) {
					toFollowTime = null;
				}
				else if (isFull) {
					toFollowTime = null;
				}
				else {
					toFollowTime = overlapInterval.end;
				}
			}
			else if (isAlreadyDeterminedFullOrStartGap) {
				toFollowTime = null;
			}
			else if (hasGapToPreviousFollow) {
				toFollowTime = toFollowTime && new Date(toFollowTime);
			}
			else if (isContainedInPrevEvent) {
				toFollowTime = toFollowTime && new Date(toFollowTime);
			}
			// FULL: ends at or past the (end - gap) so block is considered full
			else if (isFull) {
				toFollowTime = null;
			}
			else {
				// No gap added because it falls within timeframe
				toFollowTime = overlapInterval.end;
			}

			overlapMinutes += differenceInMinutes(overlapInterval.end, overlapInterval.start);

			if (isSameMinute(normalizedEvent.end, overlapInterval.end)) { break; }
			// Set normalized event start equal to overlap interval end in order to avoid double counting the block/event overlap time
			normalizedEvent.start = overlapInterval.end;
		}
	}

	return {
		overlapMinutes,
		toFollowTime: toFollowTime || undefined,
	};
};

export default calculateBlockDetails;
