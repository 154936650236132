import React from 'react';
import { Flex, Text } from '@ascension/web';
import InfoIcon from '@material-ui/icons/Info';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	error: {
		alignItems: 'center',
		justifyContent: 'flex-end',
		background: '#FFF',
		color: '#E00019',
	},
});

const FormError: React.FC = ({ children }) => {
	const classes = useStyles();

	return <Flex className={ classes.error }>
		<InfoIcon/>
		<Text> {children}</Text>
	</Flex>;
};

export default FormError;
