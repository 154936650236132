import React from 'react';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';
import { Box, Button, Flex, Text } from '@ascension/web';
import { deleteTemplate } from '@store/actions/TemplateFormActionCreators';
import { useToast } from '@utilities/hooks/useToast/useToast';
import { ToastType } from '@interfaces/ToastStatus/ToastStatusState';
import useAppAlert from '@utilities/hooks/useAppAlert/useAppAlert';
import { DeleteTemplateModalProps } from './DeleteTemplateModalProps';
import { useFilterState } from '../Filters/FiltersContext/FiltersContext';
import templateErrorToAlertMessage from '@utilities/templateErrorToAlertMessage';
import ModalContentWrapper from '../ModalContentWrapper/ModalContentWrapper';
import { useModalContext } from '@components/Modal/ModalContextProvider';
import ModalViewer from '@components/Modal/ModalViewer';
import { DELETE_TEMPLATE_MODAL } from '@components/Modal/modalConstants';

/**
 * Component for managing the Delete Template functionality. Component contains two modals: confirmation modal and error modal.
 * Error modal will be displayed only when API returns error in the response after user confirmed attempt to delete specified template.
 * @param {() => void} doneCallback - function that will be executed when component fulfilled its functionality: either when user cancels confirmation, closes any of the modals, clicks OK button in error modal or when the deletion was performed successfully.
 * @param {string} templateId - id of the template that user is attempting to delete
 */
export default function DeleteTemplateModal({ doneCallback, templateId }: DeleteTemplateModalProps) {
	const dispatch = useDispatch();
	const displayToast = useToast();
	const displayAppAlert = useAppAlert();
	const { loadListWithFilters } = useFilterState();
	const { closeModal } = useModalContext();

	const onFailureDeleteTemplate = (err: AxiosError<string>) => {
		displayAppAlert(templateErrorToAlertMessage(err), loadListWithFilters);
	};

	const onSuccessDeleteTemplate = () => {
		loadListWithFilters();
		displayToast(ToastType.SUCCESS, 'Template successfully deleted');
	};

	const handleConfirmDelete = () => {
		//close confirmation modal without triggering onClose function to prevent running doneCallback. Ticket has been open to allow "just" close modal without running onClose function: DSSC-927
		closeModal(DELETE_TEMPLATE_MODAL);
		doneCallback();
		dispatch(deleteTemplate(templateId, onSuccessDeleteTemplate, onFailureDeleteTemplate));
	};

	const handleCancel = () =>{
		closeModal(DELETE_TEMPLATE_MODAL);
		doneCallback();
	};

	return (
		<>
			<ModalViewer id={DELETE_TEMPLATE_MODAL} onClose={doneCallback}>
				<ModalContentWrapper>
					<Box minWidth={500}>
						<Flex justifyContent="space-between" letterSpacing={1} width={1} alignItems="start">
							<Box>
								<Text fontSize={26} fontWeight="bold" color = "black">
                            Delete Template
								</Text>
							</Box>
						</Flex>
						<Flex justifyContent="space-between" letterSpacing={1}  width={1} alignItems="start" pt= {8}>
							<Box>
								<Text fontSize={16}>
                            Are you sure you want to delete this template?
								</Text>
							</Box>
						</Flex>
						<Flex justifyContent="flex-end" pt={12}>
							<Box>
								<Button id="cancelDeleteTemplate" onClick={handleCancel} secondary>Cancel</Button>
							</Box>
							<Box ml="10px">
								<Button id="confirmDeleteTemplate" onClick={handleConfirmDelete}>Delete</Button>
							</Box>
						</Flex>
					</Box>
				</ModalContentWrapper>
			</ModalViewer>
		</>
	);

}
