import React from 'react';
import { AppointmentDetailsValidationSchema } from '@components/RequestForm/AppointmentDetails/AppointmentDetailsValidationSchema';
import { Unit } from '@data/unit/Unit';
import { ENDPOINT_USER_UNITS } from '@utilities/apiConstants';
import useCollection from '@utilities/hooks/useCollection/useCollection';
import CommonRequestForm from '@interfaces/RequestForm/forms/CommonRequestForm';
import {
	SelectOption,
	StatefulSelectProps,
} from '@components/StatefulInputs/StatefulSelect/StatefulSelect.types';
import { StatefulSelect } from '@components/StatefulInputs/StatefulSelect/StatefulSelect';
import { getUnitsWithOpenTime } from '@utilities/getUnitsWithOpenTime';

interface SurgeryLocationProps
	extends Partial<Omit<StatefulSelectProps<CommonRequestForm>, 'options'>> {
	label?: string;
	onlyUnitsWithOpenTime?: boolean;
	dataField: string;
}

export function SurgeryLocation({
	label = 'Surgery location',
	onlyUnitsWithOpenTime = false,
	controllerOptions,
	...rest
}: SurgeryLocationProps) {
	const units = useCollection<Unit>(ENDPOINT_USER_UNITS);
	const unitsWithOpenTime = React.useMemo(() => {
		return getUnitsWithOpenTime(units);
	}, [units]);

	const options: SelectOption[] = React.useMemo(() => {
		return (onlyUnitsWithOpenTime ? unitsWithOpenTime : units)
			.map(({ id, hospitalName, name }) => ({
				value: id,
				label: `${hospitalName}, ${name}`,
			}))
			.sort(({ label: aLabel }, { label: bLabel }) => aLabel.localeCompare(bLabel));
	}, [onlyUnitsWithOpenTime, units, unitsWithOpenTime]);

	return (
		<StatefulSelect<CommonRequestForm>
			controllerOptions={{
				...controllerOptions,
				rules: {
					required: AppointmentDetailsValidationSchema.appointmentLocation.required,
					...controllerOptions?.rules,
				},
			}}
			label={label}
			name="appointmentLocation"
			options={options}
			styleAsRequired={true}
			{...rest}
		/>
	);
}
