import React, { Dispatch, SetStateAction, useCallback, useContext, useState } from 'react';
import { ROUTE_CONSTANTS } from '@utilities/RouteConstants';
import { useHistory } from 'react-router-dom';

interface HomeNavigationContextType {
	homeRoute: string;
	setHomeRoute: Dispatch<SetStateAction<string>>;
	handleGoBack: () => void;
}

const HomeNavigationContext = React.createContext<null | HomeNavigationContextType>(null);

export const useHomeNavigationContext = () => {
	const ctx = useContext(HomeNavigationContext);
	if (!ctx) {
		throw new Error(
			'useHomeNavigationContext must be used within a child component of HomeNavigationContextProvider',
		);
	}
	return ctx;
};

export const HomeNavigationContextProvider: React.FC = ({ children }) => {
	const [homeRoute, setHomeRoute] = useState<string>(ROUTE_CONSTANTS.HOME);
	const history = useHistory();
	const [navigationHistory, setNavigationHistory] = React.useState<string[]>([history.location.pathname]);

	React.useEffect(() => {
		return history.listen((location) => {
			setNavigationHistory((prevHistory) => [...prevHistory, location.pathname]);
		});
	}, [history, setNavigationHistory]);

	const handleGoBack = useCallback(() => {
		if (navigationHistory.length > 1) {
			history.goBack();
		} else {
			history.push('/');
		}
	}, [history, navigationHistory]);

	return (
		<HomeNavigationContext.Provider value={{ homeRoute, setHomeRoute, handleGoBack }}>
			{children}
		</HomeNavigationContext.Provider>
	);
};
