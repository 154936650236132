import { RootState } from '@interfaces/rootState';
import AppointmentDetailApi from '../../interfaces/SurgeryRequest/AppointmentDetailApi';
import { PatientInformationApi } from '@interfaces/SurgeryRequest/PatientInformationApi';
import { SurgeryInformationApi } from '@interfaces/SurgeryRequest/SurgeryInformationApi';
import { PatInformationDetailApi } from '@interfaces/SurgeryRequest/PatInformationApi';
import { PerformerApi } from '@interfaces/SurgeryRequest/PerformerApi';
import { SurgeryRequestGet } from '@interfaces/SurgeryRequest/SurgeryRequestGet';
import { AppointmentType } from '@data/request/AppointmentType';
import { Attachment } from '@interfaces/SurgeryRequest/Attachment';
import { isWebRequest } from '@utilities/typeAssertionUtilities';
import { CaseLockDescriptor } from '@interfaces/CaseDetails/CaseLockDescriptor';

export const CaseDetailsSelector = ({ caseDetails }: RootState) => caseDetails;

export const CaseDetailsInfoSelector = ({ caseDetails }: RootState): SurgeryRequestGet => caseDetails && caseDetails.details ? caseDetails.details : {} as SurgeryRequestGet;

export const CaseDetailsAppointmentSelector = ({ caseDetails }: RootState): AppointmentDetailApi => caseDetails && caseDetails.details ? caseDetails.details.appointmentDetail : {} as AppointmentDetailApi;

export const CaseDetailsPatientInformationSelector = ({ caseDetails }: RootState): PatientInformationApi => {
	if (caseDetails && caseDetails.details && isWebRequest(caseDetails.details)) {
		return caseDetails.details.patientInfo;
	} else {
		return {} as PatientInformationApi;
	}
};

export const CaseDetailsSurgeryInformationSelector = ({ caseDetails }: RootState): SurgeryInformationApi => {
	if (caseDetails && caseDetails.details) {
		return caseDetails.details.surgeryInformation;
	} else {
		return {} as SurgeryInformationApi;
	}
};

export const CaseDetailsPatDetailSelector = ({ caseDetails }: RootState): PatInformationDetailApi => {
	if (caseDetails && caseDetails.details && isWebRequest(caseDetails.details)) {
		return caseDetails.details.patDetail;
	} else {
		return {} as PatInformationDetailApi;
	}
};

export const CaseDetailsPerformerSelector = ({ caseDetails }: RootState): PerformerApi => caseDetails && caseDetails.details ? caseDetails.details.performer : {} as PerformerApi;

export const CaseDetailsCancelStatusSelector = ({ caseDetails }: RootState): string | null => caseDetails && caseDetails.details && caseDetails.details.cancellation ? caseDetails.details.cancellation.status : null;

export const CaseDetailsLockStatusSelector = ({ caseDetails }: RootState): Partial<CaseLockDescriptor> => caseDetails?.lockStatus ?? {};

export const IsCaseDetailsLockedSelector = ({ caseDetails }: RootState): boolean => caseDetails && caseDetails.lockStatus && caseDetails.lockStatus.locked ? caseDetails.lockStatus.locked : false;

export const CaseDetailsEditorName = ({ caseDetails }: RootState): string => caseDetails && caseDetails.lockStatus && caseDetails.lockStatus.firstName ? caseDetails.lockStatus.firstName + ' ' +caseDetails.lockStatus.lastName : '';

export const CaseDetailsIsAttachmentType = ({ caseDetails }: RootState): boolean => !!(caseDetails && caseDetails.details && caseDetails.details.appointmentType && caseDetails.details.appointmentType === AppointmentType.DOCUMENT);

export const CaseDetailsAttachmentSelector = ({ caseDetails }: RootState): Attachment[] => caseDetails?.details?.attachments?.length ? caseDetails?.details?.attachments : [] as Attachment[];

