import React from 'react';
import { useDispatch } from 'react-redux';
import { getProcedureDuration } from '@data/procedureDuration/actionCreators/getProcedureDuration';
import { ProcedureDurationRequest } from '@data/procedureDuration/types/ProcedureDurationRequest';

export const useGetProcedureDuration = () => {
	const dispatch = useDispatch();

	return React.useCallback((requestOverrides?: Partial<ProcedureDurationRequest>) => {
		dispatch(getProcedureDuration(requestOverrides));
	}, [dispatch]);
};
