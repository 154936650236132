import React from 'react';
import { Button as AscButton } from '@ascension/ui-library';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core';
import { useManageUsersListContext } from '../../ManageUsersListContext/ManageUsersListContext';
import useNavToEditUser from '../../utilities/useNavToEditUser';

const useStyles = makeStyles((theme) => createStyles({
	btnStyle: {
		width: 'fit-content',
		height: '48px',
		fontSize: '14px',
		lineHeight: '16px',
		marginRight: theme.spacing(2),
	},
	icon: {
		height: '16px',
		width: '16px',
		paddingRight: theme.spacing(1.5),
		color: theme.palette.primary.main,
		cursor: 'pointer'
	},
}));

export const EditUserButton: React.FC = () => {
	const { btnStyle, icon } = useStyles();
	const { managedUser } = useManageUsersListContext();
	const editUser = useNavToEditUser();

	const handleEditUser = () => {
		if (managedUser?.userId) {
			editUser(managedUser.userId);
		}
	};

	return (
		<AscButton
			translate="yes"
			color="primary"
			variant="outlined"
			className={btnStyle}
			data-field="EditUserButton"
			onClick={handleEditUser}
		>
			<EditIcon className={icon} />
				Edit
		</AscButton>
	);
};
