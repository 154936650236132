import { addDays } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { getZonedTimeFromTimeAndDate } from '@utilities/dateUtilities';
import UnavailableEventFormType from '@components/UnitCalendarPage/utilities/UnavailableEventFormType';

interface UnavailableEventFormConverterResponse {
	description?: string;
	hospitalId: string;
	id?: string;
	endTime: string;
	roomId: string;
	startTime: string;
	unitId: string;
}

const unavailableEventFormToApiConverter = (
	{
		date,
		description,
		id,
		timeSpan: {
			start,
			end,
			timezone
		} = {},
	}: UnavailableEventFormType,
	hospitalId: string,
	roomId: string,
	unitId: string): UnavailableEventFormConverterResponse | undefined => {
	if (!date || !start || !end || !timezone) { return; }

	const startTime = getZonedTimeFromTimeAndDate(start, timezone, date);
	const endTime = getZonedTimeFromTimeAndDate(end, timezone, date);
	const endTimeVal = endTime && end === '12:00 AM' ? addDays(endTime, 1) : endTime;

	if (!startTime || !endTimeVal) { return; }

	const formattedStartTime = formatInTimeZone(startTime, 'Z', 'yyyy-MM-dd HH:mm');
	const formattedEndTime =  formatInTimeZone(endTimeVal, 'Z', 'yyyy-MM-dd HH:mm');

	return {
		description,
		endTime: formattedEndTime,
		hospitalId,
		id,
		roomId,
		startTime: formattedStartTime,
		unitId,
	};
};

export default unavailableEventFormToApiConverter;
