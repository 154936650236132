export enum CaseActivityGridFields {
	AVATAR = 'avatar',
	TITLE = 'title',
	STATUS = 'status',
	CHANGES = 'changes',
	REASON = 'reason',
}

export interface CaseActivityContainerProps {
	hasChanges: boolean;
}
