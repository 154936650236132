import React from 'react';
import { Button } from '@ascension/ui-library';
import TextLinkProps from './TextLinkProps';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { SvgIcon } from '@material-ui/core';

const useStyles = makeStyles((theme) => createStyles({
	textLink: {
		'padding': 0,
		'borderRadius': 0,
		'borderBottom': '2px solid',
		'borderBottomColor': 'transparent',
		'lineHeight': 1,
		'& .MuiButton-label': {
			letterSpacing: '1.25px',
		},
		'&:hover, &:focus': {
			borderBottomColor: theme.palette.primary.main,
			backgroundColor: 'transparent',
		},
	},
	prefixIcon: {
		transform: `translateX(-5px)`,
	},
}));

const TextLink: React.FC<TextLinkProps> = ({
	children,
	prefixIcon,
	className,
	...rest
}) => {
	const classes = useStyles();

	return <Button
		translate="yes"
		component="a"
		color="primary"
		variant="text"
		className={classNames(className, classes.textLink)}
		{...rest}
	>
		{prefixIcon && <SvgIcon component={prefixIcon} fontSize="small" className={classes.prefixIcon}/>}
		{children}
	</Button>;
};

export default TextLink;
