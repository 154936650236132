import { TaskCardConfig, TaskName } from '../../../interfaces/CaseListTypes';
import { HospitalRoles, OfficeRoles } from '../../../interfaces/UserProfile/UserRoleEnum';

export const TASK_CARDS_CONFIG: Record<TaskName, TaskCardConfig> = {
	[TaskName.warning]: {
		label: 'Warnings',
		order: 0,
		filterLink: '?state=WARNING'
	},
	[TaskName.unscheduled]: {
		label: 'Unscheduled',
		order: 1,
		filterLink: '?status=UNSCHEDULED'
	},
	[TaskName.needRevision]: {
		label: 'Needs Revision',
		showForRoles: OfficeRoles,
		order: 2,
		filterLink: '?state=NEED_REVISION'
	},
	[TaskName.revised]: {
		label: 'Revised',
		showForRoles: HospitalRoles,
		order: 2,
		filterLink: '?state=REVISED'
	},
	[TaskName.pendingLink]: {
		label: 'Pending Link',
		showForRoles: HospitalRoles,
		order: 3,
		filterLink: '?state=PENDING_LINK'
	},
	[TaskName.updatedDate]: {
		label: 'Updated Date',
		showForRoles: OfficeRoles,
		order: 3,
		filterLink: '?state=UPDATED_DATE'
	},
};

export const ORDERED_TASKS = (Object.keys(TASK_CARDS_CONFIG) as TaskName[]).sort((aTask, bTask) => {
	return TASK_CARDS_CONFIG[aTask].order - TASK_CARDS_CONFIG[bTask].order;
});

export default TASK_CARDS_CONFIG;
