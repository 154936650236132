import React from 'react';
import useTimeTicks from '@calendar/utilities/useTimeTicks/useTimeTicks';
import findClosestNumberIndex from '@calendar/utilities/findClosestNumberIndex/findClosestNumberIndex';
import CalendarContext from '@calendar/state/CalendarContext';
import FocusDescriptor from '@calendar/types/FocusDescriptor';
import FocusType from '@calendar/types/FocusType';
import CalendarProviderProps from '@calendar/state/CalendarProvider/CalendarProviderProps';
import { ROW_HEIGHT_PX } from '@calendar/utilities/CalendarConstants';
import { useScrollWithinBox } from '@components/ScrollWithinBox';
import { CalendarClickHandler } from '@calendar/utilities/handleTimeOrColumnClickType';
import MemoizedTimeTick from '@calendar/types/MemoizedTimeTick';
import { RoundingAlgorithm } from '@calendar';

const CalendarProvider: React.FC<CalendarProviderProps> =
	({
		tickStart,
		events,
		columns,
		children,
		focusOn: focusOnDesc,
		timezone,
		interval = 15,
		eventConfig,
		onCalendarClick,
		onHeaderHeightChange,
	}) => {
		const ticks = useTimeTicks(timezone, interval, tickStart);

		const [memoizedTicks, setMemoizedTicks] = React.useState<MemoizedTimeTick[]>([]);
		React.useEffect(() => {
			setMemoizedTicks((mTicks): MemoizedTimeTick[] =>
				mTicks.length ?
					mTicks :
					ticks.map((t) => ({
						...t,
						number: undefined,
					})));
		}, [ticks]);

		const findTimeTickIndex = React.useCallback((searchDate: Date | string | number, roundingAlgorithm?: RoundingAlgorithm) => {
			return findClosestNumberIndex(ticks)(searchDate, roundingAlgorithm);
		}, [ticks]);

		const columnNames = React.useMemo(() => columns.map(c => c.name), [columns]);

		const focusOn: FocusDescriptor = React.useMemo(() => {
			if (!focusOnDesc) {
				return focusOnDesc;
			}
			let { type, id } = focusOnDesc;

			if (type === FocusType.FIRST_EVENT) {
				type = FocusType.EVENT;
				id = events.sort((a, b) => a.start.getTime() - b.start.getTime())[0]?.id;
			}

			if (type === FocusType.TIME) {
				let minutesSinceMidnight = 0;
				//since the 'TimeTick' to which the page scrolls is under the header section,
				//it is necessary to pass the previous tick to correctly display the resulting time.
				if (focusOnDesc.id) {
					const [hours, minutes]: string[] = focusOnDesc.id.split(':');

					//total num of minutes floored to the nearest interval
					minutesSinceMidnight = interval * Math.floor((parseInt(hours) * 60 + parseInt(minutes)) / interval);
				}
				type = FocusType.TIME;
				id = minutesSinceMidnight.toString();
			}

			return { type, id };
		}, [focusOnDesc, events, interval]);

		const [headerHeight, setHeaderHeightInternal] = React.useState(ROW_HEIGHT_PX);

		const setHeaderHeight = React.useCallback((height: number) => {
			setHeaderHeightInternal(height);
			if (onHeaderHeightChange) { onHeaderHeightChange(height); }
		}, [onHeaderHeightChange]);

		const { setRelativeScrollInfo } = useScrollWithinBox();
		React.useEffect(() => {
			setRelativeScrollInfo({ y: -headerHeight - 2 });
		}, [headerHeight, setRelativeScrollInfo]);

		const timeOrColumnClick: CalendarClickHandler = React.useCallback((column, timeTick) => {
			if (onCalendarClick) return onCalendarClick(column, timeTick);
			return;
		}, [onCalendarClick]);

		return <CalendarContext.Provider value={{
			datedTicks: ticks,
			memoizedTicks,
			columns,
			columnNames,
			events,
			focusOn,
			findTimeTickIndex,
			eventConfig,
			headerHeight,
			setHeaderHeight,
			onCalendarClick: timeOrColumnClick,
		}}>
			{children}
		</CalendarContext.Provider>;
	};
export default CalendarProvider;
