import { ACCESS_DENIED, API, API_END, API_ERROR, API_START } from '@store/actions/types';
import { ApiActionPayload } from '@interfaces/ApiAction/ApiActionPayload';
import { ApiActionRequest } from '@interfaces/ApiAction/ApiAction';
import { defaultCallback } from '@utilities/defaultCallback';
import { ApiStatusEndInFlightAction, ApiStatusStartInFlightAction } from '@interfaces/ApiStatus/ApiStatusAction';

export const apiAction = <RequestPayload = unknown, ErrorPayload = RequestPayload, ResponsePayload = RequestPayload>({
	url = '',
	method = 'GET',
	data = null,
	includeAccessToken,
	label = '',
	onSuccess,
	onFailure = defaultCallback,
	cachePolicy,
	baseURL,
	responseType,
	asDataOrParams,
	cancelPolicy,
	shouldDisableErrorHandler,
	shouldDisableLoadIndicator,
	apiService,
	additionalParams,
}: ApiActionPayload<RequestPayload, ErrorPayload, ResponsePayload>): ApiActionRequest<RequestPayload, ErrorPayload, ResponsePayload> => ({
		type: API,
		payload: {
			url,
			method,
			data,
			includeAccessToken: includeAccessToken,
			label,
			onSuccess,
			onFailure,
			cachePolicy,
			asDataOrParams,
			responseType,
			baseURL,
			cancelPolicy,
			shouldDisableErrorHandler,
			shouldDisableLoadIndicator,
			apiService,
			additionalParams,
		}
	});

export const apiStart = (id: string, label: string, endpoint: string, noGlobalLoader = false): ApiStatusStartInFlightAction => ({
	type: API_START,
	payload: {
		id,
		label,
		endpoint,
		noGlobalLoader,
	},
});

export const apiEnd = (id: string): ApiStatusEndInFlightAction => ({
	type: API_END,
	payload: id,
});

export const accessDenied = (url: string) => ({
	type: ACCESS_DENIED,
	payload: {
		url,
	},
});

export const apiError = (error: unknown, label?: string) => ({
	type: API_ERROR,
	payload: label,
	error
});
