import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	lineBreakBottom: {
		marginBottom: theme.spacing(4),
	}
}));

const BlockTimeChangeModalBody: React.FC = () => {
	const classes = useStyles();
	return (
		<>
			<Typography className={classes.lineBreakBottom}>
				You originally requested a time and date into <strong>non-Block Time.</strong>
			</Typography>
			<Typography>
				If you schedule a procedure into <strong>Block Time</strong>, you will need to request a new date & time.
			</Typography>
		</>
	);
};

export default BlockTimeChangeModalBody;
