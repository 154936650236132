import React from 'react';
import { addBusinessDays } from 'date-fns';

import { StatefulDateRangePicker } from '@components/StatefulInputs/StatefulDateRangePicker/StatefulDateRangePicker';
import { AnchorProvider } from '@components/AnchorProvider/AnchorProvider';
import {
	EndDateValidation,
	StartDateValidation,
} from '@components/FindATime/utilities/FindATimeFormValidation';
import { FindATimeForm } from '@components/FindATime/FindATimeForm';
import { isLessThanTwoBusinessDaysFromToday, TODAY, TWO_BUSINESS_DAYS_FROM_NOW } from '@utilities/dateUtilities';

export function DateRangeSection() {
	return (
		<AnchorProvider>
			<StatefulDateRangePicker<FindATimeForm>
				startName="startDate"
				endName="endDate"
				startControllerOptions={{
					defaultValue: TWO_BUSINESS_DAYS_FROM_NOW,
					rules: StartDateValidation,
				}}
				endControllerOptions={{
					defaultValue: addBusinessDays(TODAY, 6),
					rules: EndDateValidation,
				}}
				startLabel="Date Range: Begin"
				endLabel="Date Range: End (Maximum 2 weeks)"
				showActionButtons={false}
				previewRangeDays={10}
				handleDisableDates={isLessThanTwoBusinessDaysFromToday}
				dataField="findATimePage-searchSection-dateRangePicker"
			/>
		</AnchorProvider>
	);
}
