import React from 'react';
import { HeaderContextType } from './HeaderContextType';
import { Box } from '@material-ui/core';

const HeaderContext = React.createContext<null | HeaderContextType>(null);

export const useHeaderIsShrunk = () => {
	const context = React.useContext(HeaderContext);

	if (!context) {
		throw new Error('useIsShrunk must be used inside a child component of HeaderContextProvider');
	}

	const { headerShrunk } = context;

	return headerShrunk;
};

export const useScrollHandler = () => {
	const context = React.useContext(HeaderContext);

	if (!context) {
		throw new Error('useScrollHandler must be used inside a child component of HeaderContextProvider');
	}

	const { updateHeaderShrunk } = context;

	return React.useCallback((event?: Event) => {
		if (!event) {
			return updateHeaderShrunk(false);
		}
		const { scrollTop } = event.target as HTMLTextAreaElement || { scrollTop: -1 };

		if ( scrollTop < 0  ) return;
		const shouldShrink = scrollTop > 0;
		updateHeaderShrunk(shouldShrink);
	}, [updateHeaderShrunk]);
};

export const HeaderContextProvider: React.FC = ({ children }) => {
	const [ headerShrunk, setHeaderShrunk ] = React.useState(false);
	const updateHeaderShrunk = React.useCallback(
		(input: boolean) => setHeaderShrunk(input), [setHeaderShrunk]);

	return <HeaderContext.Provider value={{
		headerShrunk,
		updateHeaderShrunk,
	}}>
		<Box display="flex" flexDirection="column" height="100%">
			{children}
		</Box>
	</HeaderContext.Provider>;
};
