import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { PageHeaderProps } from '@components/PageHeader/PageHeaderProps';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => createStyles({
	root: {
		'display': 'flex',
		'alignItems': 'center',
		'justifyContent': 'space-between',
		'backgroundColor': theme.palette.primary[50],
		'padding': theme.spacing(2, 5),
		'& > h1': {
			fontSize: '34px',
			lineHeight: '36px',
			fontWeight: 500,
		},
	},
}));

const PageHeader: React.FC<PageHeaderProps> = ({ title, children, className,  ...boxProps }) => {
	const classes = useStyles();

	return (
		<Box {...boxProps} className={classNames(classes.root, className)}>
			<Typography variant="h1">{title}</Typography>
			{children}
		</Box>
	);
};

export default PageHeader;
