import { validators } from './validators';
import { RegisterOptions } from 'react-hook-form';
import {
	ChipValues, ProcedureInfo,
	SurgeryProcedureInterface, TemplateInfo
} from '@components/RequestForm/SurgeryInformation/SurgeryInformationInterface';
import { DropdownOption } from '@definitions/DropdownOption';
import {
	ADDITIONAL_COMMENTS_LENGTH_EXCEEDED,
	ANESTHESIA_TYPE_NOT_SELECTED, DOC_REQUIRED, DOCUMENT_TYPE_REQUIRED,
	IMPLANTS_NEEDED_NOT_SELECTED, INVALID_CPT_CODE, INVALID_EXTENSION,
	INVALID_FIRST_NAME,
	INVALID_LAST_NAME,
	INVALID_MIDDLE_NAME, INVALID_PHONE_NUMBER,
	INVALID_PRIMARY_PROCEDURE,
	INVALID_PROCEDURE_NAME, INVALID_REVISION_MESSAGE_FOR_OR,
	OTHER_EQUIPMENT_LENGTH_EXCEEDED,
	PAGES_INDEX_REQUIRED,
	PROCEDURE_LENGTH_EXCEEDED, REVISION_CHECKBOXES_NONE_SELECTED,
	SURGEON_NOT_SELECTED,
	TEMPLATE_NAME_LENGTH_EXCEEDED,
	TEMPLATE_NAME_MISSING,
	VENDOR_CONTACTED_NOT_SELECTED,
} from '@utilities/Validation/ValidationMessages';

const multiDropdownRequired = (message: string) => {
	return {
		required: (values: DropdownOption[] = []) => {
			return values.length ? true : message;
		},
	};
};

const dropdownRequired = (message: string) => {
	return {
		required: ({ value }: Partial<DropdownOption> = {}) => {
			return value ? true : message;
		},
	};
};

export const pagesIndexValidation: RegisterOptions = {
	validate: {
		pagesTextValidate: (pages: string)=> {
			return validators.pagesIndexValidator(pages) === null || PAGES_INDEX_REQUIRED;
		}
	}
};

export const documentTypeValidation: RegisterOptions = {
	required: DOCUMENT_TYPE_REQUIRED
};

export const docRequiredValidation: RegisterOptions = {
	validate:{
		requireAtLeastOne: (list: File[]) =>
			!!(list && list.length) || DOC_REQUIRED,
	},
};

const ProcedureSchema: Record<keyof SurgeryProcedureInterface, RegisterOptions> = {
	appointmentLocation: {},
	additionalComments: {
		maxLength: {
			value: 1000,
			message: ADDITIONAL_COMMENTS_LENGTH_EXCEEDED
		}
	},
	otherEquipment: {
		maxLength: {
			value: 1000,
			message: OTHER_EQUIPMENT_LENGTH_EXCEEDED
		}
	},
	anesthesiaType: {},
	implantsNeeded: {},
	isPrimaryProcedure: {},
	surgeon: {},
	cptCodes: {},
	procedureName: {
		maxLength: {
			value: 1000,
			message: PROCEDURE_LENGTH_EXCEEDED
		},
		required: INVALID_PROCEDURE_NAME
	},
	modifierSide: {},
	modifierApproach: {},
	vendorName: {},
	robotic: {},
	equipment: {}
};

export const DocumentSchema: Record<'cptCodes', RegisterOptions> = {
	cptCodes: {
		...ProcedureSchema.cptCodes,
		validate: {
			required:  ({ currentEntry = '', entriesList = [] }: ChipValues = {}) => {
				return validators.cptValidator(currentEntry, entriesList) === null || INVALID_CPT_CODE;
			},
		},
	},
};

export const RequestProcedureSchema: Record<keyof ProcedureInfo, RegisterOptions> = {
	...ProcedureSchema,
	...DocumentSchema,
	isPrimaryProcedure: {
		...ProcedureSchema.isPrimaryProcedure,
		required: INVALID_PRIMARY_PROCEDURE
	},
	surgeon: {
		...ProcedureSchema.surgeon,
		validate: {
			...ProcedureSchema.anesthesiaType.validate,
			...dropdownRequired(SURGEON_NOT_SELECTED),
		},
	},
	anesthesiaType: {
		...ProcedureSchema.anesthesiaType,
		validate: {
			...ProcedureSchema.anesthesiaType.validate,
			...multiDropdownRequired(ANESTHESIA_TYPE_NOT_SELECTED),
		},
	},
	implantsNeeded: {
		...ProcedureSchema.implantsNeeded,
		required: IMPLANTS_NEEDED_NOT_SELECTED,
	},
	vendorContacted: {
		required: VENDOR_CONTACTED_NOT_SELECTED,
	},
};

export const TemplateSchema: Record<keyof TemplateInfo, RegisterOptions> = {
	...ProcedureSchema,
	templateName: {
		maxLength : {
			value: 1000,
			message: TEMPLATE_NAME_LENGTH_EXCEEDED
		},
		required: TEMPLATE_NAME_MISSING
	},
};

export const RequestRevisionSchema = {
	message: {
		required: INVALID_REVISION_MESSAGE_FOR_OR,
		maxLength: {
			value: 500,
			message: INVALID_REVISION_MESSAGE_FOR_OR
		}
	},
	listOfRevisions: {
		validate: {
			invalidCheckBoxSelection: (value: Array<string>) => validators.arrayHasValue(value) === null || REVISION_CHECKBOXES_NONE_SELECTED,
		},
	}
};

export const userPrefValidationSchema: {[selector: string]: Partial<RegisterOptions>} = {
	lastName: {
		required: INVALID_LAST_NAME,
		minLength: {
			value: 2,
			message: INVALID_LAST_NAME,
		},
		validate: {
			invalidName: (nameVal: string) => validators.nameValidator(nameVal) === null || INVALID_LAST_NAME,
		},
	},
	firstName: {
		required: INVALID_FIRST_NAME,
		minLength: {
			value: 2,
			message: INVALID_FIRST_NAME,
		},
		validate: {
			invalidName: (nameVal: string) => validators.nameValidator(nameVal) === null || INVALID_FIRST_NAME,
		},
	},
	middleName: {
		validate: {
			invalidName: (nameVal: string) => validators.nameValidator(nameVal) === null || INVALID_MIDDLE_NAME,
		},
	},
	phoneNumber: {
		validate: {
			invalidPhoneNumber: (val: string) => validators.phoneValidator(val) === null || INVALID_PHONE_NUMBER
		},
	},
	ext: {
		maxLength: {
			value: 4,
			message: INVALID_EXTENSION
		},
	}
};
