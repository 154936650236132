import { StartEndEvent } from '@calendar/types';
import getDateFromApiDateTime from '@calendar/utilities/getDateFromApiDateTime/getDateFromApiDateTime';

/***
 * doEventsOverlap to determine if event A and B overlap
 * @param a {StartEndEvent} Event to check. Order does not matter.
 * @param b {StartEndEvent} Event to check. Order does not matter.
 * @param countSameMinuteAsOverlap {boolean} Should we consider the events overlapping if either one event ends in same minute that other starts or one event starts in same minute that other ends
 *
 * @return {boolean} true/false indicating events overlap.
 * When events are adjacent and touch but do not overlap, result is false.
 * Start is included and end is considered non-inclusive.
 *
 * @example
 * doEventsOverlap(
 *  {
 *      start: new Date('2021-05-04T07:00:00Z'),
 *      end: new Date('2021-05-04T08:00:00Z')
 *  },
 *  {
 *      start: new Date('2021-05-04T10:00:00Z'),
 *      end: new Date('2021-05-04T15:00:00Z')
 *  }
 * ); // false
 *
 * @example
 * doEventsOverlap(
 *  {
 *      start: new Date('2021-05-04T07:00:00Z'),
 *      end: new Date('2021-05-04T08:00:00Z')
 *  },
 *  {
 *      start: new Date('2021-05-04T08:00:00Z'),
 *      end: new Date('2021-05-04T09:00:00Z')
 *  }
 * ); // false
 *
 * @example
 * doEventsOverlap(
 *  {
 *      start: new Date('2021-05-04T07:00:00Z'),
 *      end: new Date('2021-05-04T08:00:00Z')
 *  },
 *  {
 *      start: new Date('2021-05-04T07:30:00Z'),
 *      end: new Date('2021-05-04T09:00:00Z')
 *  }
 * ); // true
 *
 * @example w/ countSameMinuteAsOverlap = true; notice event A ends in same minute as event B starts
 * doEventsOverlap(
 *  {
 *      start: new Date('2021-05-04T07:00:00Z'),
 *      end: new Date('2021-05-04T08:00:00Z')
 *  },
 *  {
 *      start: new Date('2021-05-04T08:00:00Z'),
 *      end: new Date('2021-05-04T09:00:00Z')
 *  },
 *  true
 * ); // true
 *
 * @example w/ countSameMinuteAsOverlap = false; notice event A ends in same minute as event B starts
 * doEventsOverlap(
 * {
 *      start: new Date('2021-05-04T07:00:00Z'),
 *      end: new Date('2021-05-04T08:00:00Z')
 *  },
 *  {
 *      start: new Date('2021-05-04T08:00:00Z'),
 *      end: new Date('2021-05-04T09:00:00Z')
 *  },
 *  false
 * ); // false
 */
const doEventsOverlap = (a: StartEndEvent, b: StartEndEvent, countSameMinuteAsOverlap = false) => {
	const aStart = getDateFromApiDateTime(a.start).getTime();
	const aEnd = getDateFromApiDateTime(a.end).getTime();
	const bStart = getDateFromApiDateTime(b.start).getTime();
	const bEnd = getDateFromApiDateTime(b.end).getTime();
	if (countSameMinuteAsOverlap) {
		return aStart <= bEnd && aEnd >= bStart;
	}
	return aStart < bEnd && aEnd > bStart;
};

export default doEventsOverlap;
