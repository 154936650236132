import React, { useContext } from 'react';
import useUnitEvents from '@utilities/hooks/useUnitEvents/useUnitEvents';
import { ENDPOINT_USER_UNITS } from '@utilities/apiConstants';
import { addBusinessDays, differenceInCalendarDays } from 'date-fns';
import OfficeCalendarViewContextType
	from '@components/OfficeCalendarView/OfficeCalendarViewContext/OfficeCalendarViewContextType';
import getOfficeCalEventParser from '@components/OfficeCalendarView/utilities/getOfficeCalEventParser';
import { Unit } from '@data/unit/Unit';
import { useCollectionEntity } from '@utilities/hooks/useCollectionEntity/useCollectionEntity';
import { isUnitMinimal } from '@interfaces/Unit/UnitMinimal';
import { OfficeCalendarError } from '@components/OfficeCalendarView/OfficeCalendarError';
import { useFormContext } from 'react-hook-form';
import SelectOpenTimeFormValues from '@components/OfficeCalendarView/SelectOpenTimeFormValues';
import useTimeSpanAutoCalculate from '@utilities/hooks/useTimeSpanAutoCalculate/useTimeSpanAutoCalculate';
import { DATE_ONLY_FORMATTER, TIME_ONLY_FORMAT } from '@utilities/constants';
import OfficeCalendarViewContextProviderProps
	from '@components/OfficeCalendarView/OfficeCalendarViewContext/OfficeCalendarViewContextProviderProps';
import { formatInTimeZone } from 'date-fns-tz';
import CurrentHoldEventType from '@components/OfficeCalendarView/utilities/CurrentHoldEventType';

const OfficeCalendarViewContext = React.createContext<OfficeCalendarViewContextType | null>(null);

export const useOfficeCalendarViewContext = () => {
	const ctx = useContext(OfficeCalendarViewContext);

	if (!ctx) {
		throw new Error('useOfficeCalendarViewContext must be used in a sub component of OfficeCalendarViewContextProvider');
	}

	return ctx;
};

const DEFAULT_UNIT = {
	id: '',
	name: '',
	hospital: '',
	hospitalName: '',
	hospitalTimeZone: 'America/Chicago',
};

const twoBusinessDaysFromToday = addBusinessDays(new Date(), 2);

const errorParser = () => OfficeCalendarError.GENERAL_FAIL;

export const OfficeCalendarViewContextProvider: React.FC<OfficeCalendarViewContextProviderProps> = ({
	children,
	unitId,
	parentSelectedDate,
	currentEventConfig: { currentHoldEventSelector, currentHoldEvent: providedEvent, getUpdateType } = {},
	isEdit
}) => {
	const { register, unregister, watch } = useFormContext<SelectOpenTimeFormValues>();

	// Initialize to 2 business days per business rules
	// User is unable to schedule less than 48hrs so this prevents error from showing immediately on open
	const [selectedDate, setSelectedDate] = React.useState(twoBusinessDaysFromToday);
	const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
	const [selectedRoom, setSelectedRoom] = React.useState<string>();
	const [temporaryError, setTemporaryError] = React.useState<OfficeCalendarError>(OfficeCalendarError.NONE);
	const [holdEventCardElm, setHoldEventCardElm] = React.useState<HTMLDivElement | null>(null);
	const shouldPopoverDisplayOpen = !!selectedRoom;
	const [isPopoverOpen, setIsPopoverOpen] = React.useState(shouldPopoverDisplayOpen);
	const [isLoading, setIsLoading] = React.useState(true);
	const [ timeSpan ] = watch(['timeSpan']);
	const { start, end } = timeSpan || {};

	React.useEffect(() => {
		setIsPopoverOpen(shouldPopoverDisplayOpen);
	}, [shouldPopoverDisplayOpen]);

	React.useEffect(() => {
		if (!parentSelectedDate) { return; }
		setSelectedDate(parentSelectedDate);
	}, [parentSelectedDate]);

	const collectionUnit = useCollectionEntity<Unit>(ENDPOINT_USER_UNITS, unitId);
	const unit = isUnitMinimal(collectionUnit) ? collectionUnit : DEFAULT_UNIT;
	const { hospitalTimeZone } = unit;

	const dateError = React.useMemo(() => {
		if (differenceInCalendarDays(selectedDate, new Date()) < 2) {
			return OfficeCalendarError.OUT_OF_RANGE_PAST_DATE;
		}
		if (differenceInCalendarDays(selectedDate, new Date()) > 30) {
			return OfficeCalendarError.OUT_OF_RANGE_FUTURE_DATE;
		}
		return OfficeCalendarError.NONE;
	}, [selectedDate]);

	const handleOnSuccessUnitResponse = React.useCallback(() => {
		setIsLoading(false);
	}, [setIsLoading]);

	const eventParser = React.useMemo(() => getOfficeCalEventParser(currentHoldEventSelector), [currentHoldEventSelector]);
	const {
		error = OfficeCalendarError.NONE,
		...unitEventProps
	}  = useUnitEvents<OfficeCalendarError>(
		selectedDate,
		unit,
		eventParser,
		{
			shouldAutoLoad: !dateError && isDrawerOpen,
			errorParser,
		},
		isEdit,
		handleOnSuccessUnitResponse
	);

	const {
		setStartTime,
		setEndTime,
	} = useTimeSpanAutoCalculate(hospitalTimeZone, selectedDate);

	React.useEffect(() => {
		register('timeSpan');
		return () => unregister('timeSpan');
	}, [register, unregister]);

	const removeHoldEvent = React.useCallback(() => {
		setSelectedRoom(undefined);
		setStartTime(undefined);
		setEndTime(undefined);
	}, [setEndTime, setStartTime]);

	const toggleDrawer = (open: boolean) => () => {
		setIsDrawerOpen(open);
		if (!open) {
			removeHoldEvent();
		}
	};

	const { currentHoldEvent, currentHoldEventType } = React.useMemo(() => {
		if (!currentHoldEventSelector || !getUpdateType) return { currentHoldEventType: CurrentHoldEventType.NONE };
		const foundEvent = unitEventProps.events.find(currentHoldEventSelector);
		return {
			currentHoldEvent: foundEvent || providedEvent,
			currentHoldEventType: getUpdateType(foundEvent)
		};
	}, [currentHoldEventSelector, getUpdateType, providedEvent, unitEventProps.events]);

	const dateFormatter = React.useCallback((date: Date) => {
		return formatInTimeZone(date, hospitalTimeZone, DATE_ONLY_FORMATTER);
	}, [hospitalTimeZone]);

	const isCurrentHoldEventSameDay = React.useMemo(() =>
		currentHoldEvent
			&& currentHoldEvent.start
			&& dateFormatter(currentHoldEvent.start) === dateFormatter(selectedDate),
	[currentHoldEvent, dateFormatter, selectedDate]);

	const resetHoldEvent = React.useCallback(() => {
		if (!currentHoldEvent || !isCurrentHoldEventSameDay || !isDrawerOpen) { return false; }
		setSelectedRoom(currentHoldEvent.column);
		setStartTime(formatInTimeZone(currentHoldEvent.start, hospitalTimeZone, TIME_ONLY_FORMAT));
		setEndTime(formatInTimeZone(currentHoldEvent.end, hospitalTimeZone, TIME_ONLY_FORMAT));
		return true;
	}, [currentHoldEvent, hospitalTimeZone, isCurrentHoldEventSameDay, isDrawerOpen, setEndTime, setStartTime]);

	React.useEffect(() => {
		const resetResult = resetHoldEvent();
		// setSelectedRoom, setStartTime, and setEndTime based on currentHoldEvent if it exists
		if (!resetResult) {
			removeHoldEvent();
		}
	}, [removeHoldEvent, resetHoldEvent]);

	React.useEffect(() => {
		if (
			currentHoldEvent && isCurrentHoldEventSameDay &&
			selectedRoom !== currentHoldEvent?.column &&
			start !== formatInTimeZone(currentHoldEvent?.start, hospitalTimeZone, TIME_ONLY_FORMAT) &&
			end !== formatInTimeZone(currentHoldEvent.end, hospitalTimeZone, TIME_ONLY_FORMAT)
		) { setIsPopoverOpen(true); }
	}, [currentHoldEvent, end, hospitalTimeZone, isCurrentHoldEventSameDay, selectedRoom, start]);

	React.useEffect(() => {
		// hold event should be removed from calendar when date changes
		if (isCurrentHoldEventSameDay) { return; }
		removeHoldEvent();
	}, [isCurrentHoldEventSameDay, removeHoldEvent]);

	React.useEffect(() => {
		if (isDrawerOpen) {
			setIsLoading(true);
		}
	}, [isDrawerOpen, setIsLoading]);

	const isPopoverOpenConditions =
		!!selectedRoom && // Room is selected
		!(dateError || error) && // dateError or calendarError does not exist (temporaryError that causes modal doesn't close this)
		!!unitEventProps.columns.length && // Calendar columns have loaded
		isPopoverOpen; // And should be open per state

	return (
		<OfficeCalendarViewContext.Provider value={{
			isDrawerOpen,
			toggleDrawer,
			selectedDate,
			setSelectedDate,
			selectedRoom,
			setSelectedRoom,
			setStartTime,
			setEndTime,
			unit,
			calendarError: temporaryError || dateError || error,
			setCalendarError: setTemporaryError,
			setHoldEventCardElm,
			holdEventCardElm,
			currentHoldEvent,
			currentHoldEventType,
			resetHoldEvent,
			isPopoverOpen: isPopoverOpenConditions,
			isLoading,
			setIsPopoverOpen,
			...unitEventProps,
		}}>
			{children}
		</OfficeCalendarViewContext.Provider>
	);
};
