import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
		color: theme.palette.error.main,
	}
}));

export function NonCredentialedMessage() {
	const classes = useStyles();

	return (
		<Typography className={classes.root} data-field="non-credentialed-message">
			Surgeon is not credentialed for this facility. Please select a facility where this surgeon is credentialed.
		</Typography>
	);
}
