import React from 'react';
import { Flex } from '@ascension/web';
import { Surgeon } from '../../../../../data/surgeon/Surgeon';
import { getFormattedName } from '../../../../../utilities/commonFunction';
import { useFormContext } from 'react-hook-form';
import { useSaveFiltersContext } from '../../SaveFiltersContext/SaveFiltersContext';
import { getNewSelectList, SectionName } from '../../MyFilterUtilities';
import { useSurgeonContext } from '../SurgeonContext/SurgeonContext';
import { AllSurgeonCheckbox } from '../AllSurgeonCheckbox/AllSurgeonCheckbox';
import { SurgeonCheckbox } from '../SurgeonCheckbox/SurgeonCheckbox';
import { useIsAllSelected } from '../../useIsAllSelected/useIsAllSelected';

const DefaultSurgeonList: Surgeon[] = [];
interface SurgeonsForSpecialtyCheckboxListProps {
	specialtyId: string;
}

const SpecialtySurgeonList = ({ specialtyId }: SurgeonsForSpecialtyCheckboxListProps) => {
	const { setValue, getValues } = useFormContext<{surgeon: string[] | null}>();
	const { allSurgeonIds } = useSurgeonContext();
	const { specialtyList } = useSaveFiltersContext();
	const { surgeons = DefaultSurgeonList } = specialtyList[specialtyId];
	const specialtySurgeons = React.useMemo(() =>  surgeons.map((surgeon: Surgeon) => surgeon.id), [surgeons]);
	const allSelected = useIsAllSelected('surgeon', specialtySurgeons);

	const getSelectedSurgeons = React.useMemo(() => getNewSelectList.bind(null, allSurgeonIds, specialtySurgeons), [allSurgeonIds, specialtySurgeons]);

	const handleToggleCheckbox = React.useCallback((surgeonId: string) => {
		let { surgeon: currentSurgeons } = getValues();

		/* To solve this bug, if all elements are selected but the array is empty,
		   fill the array with all elements from the array of all ids. */
		if (currentSurgeons && currentSurgeons?.length == 0) {
			currentSurgeons = allSurgeonIds;
		}
		setValue('surgeon', getSelectedSurgeons(currentSurgeons, surgeonId, SectionName.SURGEONS), { shouldValidate: true });
	}, [getSelectedSurgeons, getValues, setValue, allSurgeonIds]);

	return (
		<Flex flexDirection="row" flexWrap="wrap" width={1}>
			<AllSurgeonCheckbox onCheck={handleToggleCheckbox} allSelected={allSelected} />
			{surgeons.map((surgeon: Surgeon) => <SurgeonCheckbox
				key={surgeon.id}
				id={surgeon.id}
				name={getFormattedName(surgeon)}
				onCheck={handleToggleCheckbox}
				allSelected={allSelected}
			/>)}
		</Flex>
	);
};

export default SpecialtySurgeonList;
