import React from 'react';
import { useDispatch } from 'react-redux';
import { DocPrintApiPayload } from '@data/docPrint/types/DocPrintRequest';
import { getDocPrintApiAction } from '@data/docPrint/actionCreators/DocPrintActionCreators';

const useGetDocPrint = () => {
	const dispatch = useDispatch();

	return React.useCallback((requestOverrides: DocPrintApiPayload) => {
		dispatch(getDocPrintApiAction(requestOverrides));
	}, [dispatch]);
};

export default useGetDocPrint;
