import { ROUTE_CONSTANTS } from '@utilities/RouteConstants';

export interface NavOptionProps {
	route: string;
	label: string;
}

export const NAV_CONFIG_OPTIONS: NavOptionProps[] = [
	{
		route: ROUTE_CONSTANTS.CASE_LIST,
		label: 'Case List',
	},
];
