import React from 'react';
import { HospitalCalendarEventConfig } from '@components/UnitCalendarPage/HospitalCalendarEventConfig';
import { FocusDescriptor, FocusType } from '@calendar';
import {
	useUnitCalendarPageContext
} from '@components/UnitCalendarPage/UnitCalendarPageContext/UnitCalendarPageContext';
import UnableToLoadCalendarError from '@components/CalendarError/content/UnableToLoadCalendarError';
import { CalendarClickHandler } from '@calendar/utilities/handleTimeOrColumnClickType';
import UnavailableEventCardDetailsPopover
	from '@components/UnitCalendarPage/EventCard/UnavailableEventCardDetailsPopover';
import useCalendarEventsWithNewUnavailable
	from '@components/UnitCalendarPage/utilities/useCalendarEventsWithNewUnavailable';
import CalendarLayout from '@components/UnitCalendarPage/CalendarLayout/CalendarLayout';
import LocationSelection from '@components/UnitCalendarPage/LocationSelection/LocationSelection';
import { HospitalEventStyleConfig } from '@components/UnitCalendarPage/HospitalEventStyleConfig';
import { useFormContext } from 'react-hook-form';
import UnavailableEventFormType from '@components/UnitCalendarPage/utilities/UnavailableEventFormType';
import { FOCUS_TO_STUB_EVENT } from '@components/UnitCalendarPage/utilities/calendarConstants';
import { getNormalizedTime } from '@utilities/dateUtilities';
import PreviewOfficeCalendar from '@components/UnitCalendarPage/PreviewOfficeCalendar/PreviewOfficeCalendar';

const HospitalUnitCalendar = () => {
	const {
		error,
		columns,
		unitConfig,
		selectedUnit,
		selectedDate,
		setSelectedDate,
		setSelectedRoom,
		selectedRoom,
		getUnitEventsFromApi,
	} = useUnitCalendarPageContext();
	const {
		watch,
		setValue
	} = useFormContext<UnavailableEventFormType>();

	// Obtain form start/date to enable jump to date
	const [
		date,
		timeSpan,
		id,
	] = watch(['date', 'timeSpan', 'id']);

	const handleCalendarClick: CalendarClickHandler = React.useCallback((column: string) => {
		if (!date || date.toString() !== selectedDate.toString()) {
			setValue('date', selectedDate);
		}
		setSelectedRoom(column);
	}, [date, selectedDate, setSelectedRoom, setValue]);

	const events = useCalendarEventsWithNewUnavailable();

	const { hospitalTimeZone, start } = unitConfig;

	const focusOn: FocusDescriptor = React.useMemo(() => {
		const defaultUnitStart = { type: FocusType.TIME, id: start };

		if ( // use the unit default start in the following situations:
			!selectedRoom || // No selected room (drawer is not open)
			!date || // No form date
			!timeSpan || // No form timeSpan - used to trigger update when start/end are updated
			getNormalizedTime(date, hospitalTimeZone) !== getNormalizedTime(selectedDate, hospitalTimeZone) // The calendar is on a different day from the event being edited
		) { return defaultUnitStart; }

		return id ? { ...FOCUS_TO_STUB_EVENT, id } : FOCUS_TO_STUB_EVENT;
	}, [date, hospitalTimeZone, id, selectedDate, selectedRoom, start, timeSpan]);

	return <>
		<UnavailableEventCardDetailsPopover />
		<CalendarLayout
			title="OR availability calendar"
			errorContent={error && <UnableToLoadCalendarError onReloadClick={getUnitEventsFromApi}/> || undefined}
			eventStyleConfig={HospitalEventStyleConfig}
			eventConfig={HospitalCalendarEventConfig}
			events={events}
			columns={columns}
			unitConfig={unitConfig}
			selectedDate={selectedDate}
			onSelectedDateChange={setSelectedDate}
			autoScrollWhenUpdate={selectedUnit}
			onCalendarClick={handleCalendarClick}
			rightUpperHeader={<PreviewOfficeCalendar />}
			rightLowerHeader={<LocationSelection data-field="unitCalendarPage-location-selection"/>}
			focusOn={focusOn}
		/>
	</>;
};

export default HospitalUnitCalendar;
