import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';

const useStyles = makeStyles((theme) => (createStyles({
	editorNameBlock: {
		flex: 'auto 1 1',
		marginLeft: theme.spacing(3),
	},
	editorNameStyle: {
		fontSize: '1rem',
		paddingTop: theme.spacing(1.4),
		textAlign: 'left',
		lineHeight: '1.5em',
		height: theme.spacing(3),
		marginTop: theme.spacing(.125),
		marginBottom: theme.spacing(.125)
	},
	lockIcon: {
		position: 'relative',
		right: '.8rem',
		bottom: '.07rem',
		alignContent: 'left',
		width: '24px',
		height: '24px',
		top: theme.spacing(.875),
		marginRight: theme.spacing(.15),
		marginTop: theme.spacing(.625)
	},
})));

interface ManagedByMessageProps {
    managedByName: undefined | string | null;
}


export const ManagedByMessage: React.FC<ManagedByMessageProps> = ({ managedByName }) => {
	const classes = useStyles();

	return (
		<Box data-field="inUseMessage" hidden={!managedByName} className={classes.editorNameBlock}>
			<Box display="flex">
				<LockIcon className={classes.lockIcon}/>
				<Typography className={classes.editorNameStyle} data-field="editorName">
                    Case is currently being managed by {managedByName}
				</Typography>
			</Box>
		</Box>
	);
};
