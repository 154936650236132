/* General */
export const LEGACY_MAX_WIDTH = '944px';
export const SCROLLBAR_SIZE = '4px';
export const VERTICAL_PAGE_MARGIN = 68;

/* Horizontal Scroll */
export const GRADIENT_WIDTH = 24; // px

/* Case Details - Condensed/Expanded Section Sizing */
export const CONDENSED_MIN_HEIGHT = '540px';
export const CONDENSED_MAX_HEIGHT = '76vh';
export const CONDENSED_SECTION_MIN_WIDTH = '320px';
export const CONDENSED_CONTAINER_MIN_WIDTH = '656px';
export const EXPANDED_WIDTH = '100%';
export const EXPAND_MODAL_MAX_WIDTH = '900px';

/* Responsive Page Max Widths */
export const DEFAULT_MAX_WIDTH = '856px';
export const LARGE_MAX_WIDTH = '1152px';
export const X_LARGE_MAX_WIDTH = '1488px';

/* Manage User Page */
export const PAGE_WIDTH = '1150px';

/* MUI style variants */
export type MUI_STYLE_VARIANTS = 'default' | 'error' | 'primary' | 'secondary' | 'success' | 'warning';
