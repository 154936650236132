import { FormEvent, SyntheticEvent } from 'react';

/***
 * Used to prevent submit event from propagating up causing the nested form submit button to submit the parent too
 *
 * @param callback {(e: FormEvent) => unknown}
 *
 * @example
 * <form onSubmit="parentSubmitHandler" />
 *     <form onSubmit="preventEventPropagation(handleSubmit(submitHandler)))">
 *         <input type="text" label="Child Name"/>
 *         <button type="submit">Submit only nested form</button>
 *     </form>
 *     <input type="text" label="Parent Name" />
 *     <button type="submit"> Submit parent form</button>
 * </form>
 */
function preventEventPropagation<EventType extends SyntheticEvent = FormEvent>(callback: (e: EventType) => unknown) {
	return (e: EventType) => {
		e.stopPropagation();
		e.preventDefault();
		return callback(e);
	};
}

export default preventEventPropagation;
