import React from 'react';
import { Box, Button, createStyles, makeStyles } from '@material-ui/core';
import { AddRounded } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { AddBlockRequestButtonProps } from '@components/SchedulePage/ColumnHeaderCommon/AddBlockRequestButton/AddBlockRequestButtonProps';
import useHasPermission from '@utilities/hooks/useHasPermission/useHasPermission';
import { PermittedAction } from '@utilities/PermittedAction';
import RequestFormPageState, {
	RequestFormPageSources,
} from '@components/RequestForm/RequestFormPage.types';

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			paddingRight: (hideButton) => (!hideButton ? theme.spacing(3) : 0),
		},
		button: {
			'right': theme.spacing(1),
			'bottom': theme.spacing(-1),
			'position': 'absolute',
			'borderRadius': '50%',
			'minWidth': 'unset',
			'height': '40px',
			'width': '40px',
			'color': theme.palette.primary.main,
			'backgroundColor': theme.palette.common.white,
			'border': `1px solid ${theme.palette.primary.main}`,
			'&:hover': {
				color: theme.palette.primary[600],
				backgroundColor: theme.palette.primary[50],
				border: `1px solid ${theme.palette.primary.dark}`,
			},
		},
	}),
);

const AddBlockRequestButton: React.FC<AddBlockRequestButtonProps> = ({ blockInfo }) => {
	const createCasePermission = useHasPermission(PermittedAction.CREATE_CASE);
	const hideButton = !blockInfo.credentialed || !createCasePermission;
	const classes = useStyles(hideButton);

	const history = useHistory<RequestFormPageState>();

	const handleClick = () => {
		history.push('/request', {
			blockInfo,
			from: RequestFormPageSources.DailySurgeonCalendarAddButton,
		});
	};

	if (hideButton) {
		return null;
	}

	return (
		<Box className={classes.root}>
			<Button
				className={classes.button}
				onClick={handleClick}
				data-field="blockPage-blockColumnHeader-addButton"
			>
				<AddRounded />
			</Button>
		</Box>
	);
};

export default AddBlockRequestButton;
