import { apiAction } from '@store/middlewares/api/ApiActionCreators';
import { ENDPOINT_PROCEDURE_DURATION } from '@utilities/apiConstants';
import ApiService from '@utilities/apiEndpoints/ApiService';
import { GET_PROCEDURE_DURATION } from '@store/actions/types';
import { ProcedureDurationRequest } from '@data/procedureDuration/types/ProcedureDurationRequest';

export const getProcedureDuration = (apiActionRequest?: Partial<ProcedureDurationRequest>) => {
	return apiAction({
		url: ENDPOINT_PROCEDURE_DURATION,
		apiService: ApiService.SSM,
		method: 'POST',
		includeAccessToken: true,
		label: GET_PROCEDURE_DURATION,
		...apiActionRequest,
	});
};
