import React, { ReactElement } from 'react';
import { StatefulTextInput } from '@components/StatefulInputs/StatefulTextInput/StatefulTextInput';
import { FieldValues } from 'react-hook-form';
import { StatefulTextInputProps } from '@components/StatefulInputs/StatefulTextInput/StatefulTextInput.types';

// While the UI appearance is based on the browser and operating system, the features are the same.
// The value is always a 24-hour hh:mm or hh:mm:ss formatted time, with leading zeros, regardless of the UI's input format.
export function StatefulTimePicker<FormType extends FieldValues>({
	controllerOptions,
	textInputProps,
	...statefulProviderProps
}: StatefulTextInputProps<FormType>): ReactElement {

	return (
		<StatefulTextInput
			controllerOptions={controllerOptions}
			textInputProps={{
				type: 'time',
				...textInputProps,
			}}
			{...statefulProviderProps}
		/>
	);
}
