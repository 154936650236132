export default
(error: unknown, defaultErrorMessage: string | null = null): string | null => {
	if (!error) {
		return defaultErrorMessage;
	}

	if (typeof error === 'string') {
		return error;
	}

	if ((error as Error).message) {
		return (error as Error).message;
	}

	return defaultErrorMessage;
};
