import React, { ReactNode, useContext } from 'react';
import { ProcedureFormInfo } from '../../../interfaces/ProcedureFormInfo';

const ProcedureFormContext = React.createContext<ProcedureFormInfo | null>(null);

export const useProcedureFormInfo = () => {
	const context = useContext(ProcedureFormContext);

	if (!context) {
		throw new Error('useProcedureModalInfo must be used within a child component of ProcedureModalInfoProvider');
	}

	return context;
};

type ProcedureFormProviderProps = ProcedureFormInfo & { children: ReactNode };
export const ProcedureFormProvider = ({ children, ...rest }: ProcedureFormProviderProps) => {
	return (
		<ProcedureFormContext.Provider value={rest}>
			{children}
		</ProcedureFormContext.Provider>
	);
};
