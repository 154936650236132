import React, { useContext } from 'react';
import { ProcedureNameContextType } from '@components/RequestForm/ProcedureName/ProcedureNameContextType';
import { useGetProcedureNames } from '@components/RequestForm/utilities/hooks/useGetProcedureNames/useGetProcedureNames';
import { ProcedureNameInfo } from '@components/RequestForm/ProcedureName/ProcedureNameInfo';
import { useFormContext } from 'react-hook-form';
import { RequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import { ProcedureNameProviderProps } from '@components/RequestForm/ProcedureName/ProcedureNameProviderProps';

export const CUSTOM_PROCEDURE_OPTION = 'Custom Procedure';

const ProcedureNameContext = React.createContext<ProcedureNameContextType | undefined>(undefined);

export const useProcedureNameContext = () => {
	const ctx = useContext(ProcedureNameContext);

	if (!ctx) {
		throw new Error(
			'useProcedureNameContext must be used in a child of ProcedureNameProvider.',
		);
	}

	return ctx;
};

function checkCustomProcedure(procedureName: string): boolean {
	return procedureName === CUSTOM_PROCEDURE_OPTION;
}

function searchAllTermsPresent(text: string, searchTerms: string[]) {
	const lowerCaseText = text.toLowerCase();
	return searchTerms.every((term) => lowerCaseText.includes(term.toLowerCase()));
}

export const ProcedureNameProvider: React.FC<ProcedureNameProviderProps> = ({ children, unitId, allowCustom }) => {
	const [selectedProcedureInfo, setSelectedProcedureInfo] = React.useState<{ isCustomProcedure: boolean, selectedProcedureName: string }>({
		isCustomProcedure: false,
		selectedProcedureName: ''
	});
	const { setValue, trigger, watch, getValues, clearErrors } = useFormContext<RequestFormValidatedState>();
	const previousProcedureName = React.useRef('');
	const [procedureFilter, setProcedureFilter] = React.useState<string>('');

	const { procedures, getProceduresFromApi } = useGetProcedureNames();
	const procedureNameRequestForm = watch('procedureName');

	const isProcedureOption = React.useCallback((option: string) => {
		return procedures.some(proc => proc.procedureName === option);
	}, [procedures]);

	const filteredProcedures = React.useMemo(() => {
		return procedureFilter
			? procedures.filter((procedure) =>
				searchAllTermsPresent(procedure.procedureName, procedureFilter.split(' ')),
			)
			: procedures;
	}, [procedures, procedureFilter]);

	const getProcedures = React.useCallback(() => {
		unitId && void getProceduresFromApi(unitId);
	}, [unitId, getProceduresFromApi]);

	const selectedProcedure = React.useMemo(() => {
		if (checkCustomProcedure(selectedProcedureInfo.selectedProcedureName)) {
			return { procedureName: selectedProcedureInfo.selectedProcedureName };
		}

		return procedures.find(
			(procedure: ProcedureNameInfo) => procedure?.procedureName === procedureNameRequestForm,
		);
	}, [procedures, selectedProcedureInfo.selectedProcedureName, procedureNameRequestForm]);

	const resetProcedureName = React.useCallback(() => {
		setValue('procedureName', '');
		clearErrors('procedureName');
	}, [setValue, clearErrors]);

	const triggerProcedureNameValidation = React.useCallback(() => {
		void trigger('procedureName');
	}, [trigger]);

	const setCustomProcedure = React.useCallback((procedureName: string) => {
		setSelectedProcedureInfo((current) => ({
			...current,
			isCustomProcedure: true,
			selectedProcedureName: CUSTOM_PROCEDURE_OPTION
		}));
		setValue('procedureName', procedureName);
	}, [setValue]);

	const handleSetSelectedProcedureName = React.useCallback((procedureName: string) => {
		const isCustom = checkCustomProcedure(procedureName);
		const { procedureName: currentProcedureName } = getValues();

		setSelectedProcedureInfo(({
			selectedProcedureName,
			isCustomProcedure,
		}) => {
			if (!isCustomProcedure) {
				previousProcedureName.current = selectedProcedureName;
			} else {
				previousProcedureName.current = currentProcedureName;
			}

			return {
				selectedProcedureName: procedureName,
				isCustomProcedure: isCustom,
			};
		});

		if (isCustom) {
			resetProcedureName();
			return;
		}

		// Without this condition, on FaT page an error appears for procedure name input immediately after selecting location
		if (!allowCustom) {
			setValue('procedureName', procedureName);
			return;
		}

		setValue('procedureName', procedureName, { shouldValidate: true, shouldDirty: true });
	}, [resetProcedureName, setValue, getValues, allowCustom]);

	React.useEffect(() => {
		getProcedures();
	}, [getProcedures]);

	return (
		<ProcedureNameContext.Provider
			value={{
				procedures: filteredProcedures,
				selectedProcedure,
				setSelectedProcedureName: handleSetSelectedProcedureName,
				isCustomProcedure: selectedProcedureInfo.isCustomProcedure,
				setCustomProcedure,
				resetProcedureName,
				triggerProcedureNameValidation,
				previousProcedureName: previousProcedureName,
				setProcedureFilter,
				allowCustom,
				isProcedureOption,
			}}
		>
			{children}
		</ProcedureNameContext.Provider>
	);
};
