import React from 'react';
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import PageHeader from '@components/PageHeader/PageHeader';

const useStyles = makeStyles((theme) => createStyles({
	root: {
		width: '100%',
		justifyContent: 'flex-start',
		alignItems: 'flex-end',
		gap: theme.spacing(1),
	},
}));


export const FindATimePageHeader: React.FC = () => {
	const classes = useStyles();

	return (
		<PageHeader title="Find A Time" className={classes.root}>
			<Typography>
				(Single Surgeon, Single Procedure Edition)
			</Typography>
		</PageHeader>
	);
};
