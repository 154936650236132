import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Flex, Text } from '@ascension/web';
import { clearAppAlert } from '@store/actions/AppAlertActionCreators';
import AppAlertSelector from '../../store/selectors/AppAlertSelector';
import ModalViewer from '@components/Modal/ModalViewer';
import { ModalContextProvider, useModalContext } from '@components/Modal/ModalContextProvider';
import { ALERT_MODAL } from '@components/Modal/modalConstants';

const useStyles = makeStyles((theme) => ({
	modalWrapper: {
		padding: theme.spacing(2, 4, 5, 4),
	},
}));

const AlertModalInner = () => {
	const { title, message, onDismiss } = useSelector(AppAlertSelector);
	const dispatch = useDispatch();
	const onDismissRef = React.useRef<() => void>();
	const { closeModal, openModal } = useModalContext();

	React.useEffect(() => {
		const clearModal = () => {
			onDismissRef.current && onDismissRef.current();
			closeModal(ALERT_MODAL);
			onDismissRef.current = undefined;
		};
		title ? openModal(ALERT_MODAL) : clearModal();
	}, [closeModal, openModal, title]);

	const classes = useStyles();

	const handleOkClick = React.useCallback(() => {
		onDismissRef.current = onDismiss;
		dispatch(clearAppAlert());
		closeModal(ALERT_MODAL);
	}, [closeModal, dispatch, onDismiss]);

	return (
		<ModalViewer className={classes.modalWrapper} id={ALERT_MODAL}>
			<Box minWidth={500}>
				<Flex justifyContent="space-between" letterSpacing={1} width={1} alignItems="start">
					<Box>
						<Text fontSize={26} fontWeight="bold" color = "black">
							{title}
						</Text>
					</Box>
				</Flex>
				<Flex justifyContent="space-between" letterSpacing={1}  width={1} alignItems="start" pt= {8}>
					<Box>
						<Text fontSize={16}>
							{message}
						</Text>
					</Box>
				</Flex>
				<Flex justifyContent="flex-end" pt={12}>
					<Box>
						<Button id="OKErrorButton" onClick={handleOkClick}>OK</Button>
					</Box>
				</Flex>
			</Box>
		</ModalViewer>
	);
};

const AlertModal = () => {
	return (
		<ModalContextProvider>
			<AlertModalInner/>
		</ModalContextProvider>
	);
};

export default AlertModal;
