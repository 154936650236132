import React from 'react';
import { Box } from '@material-ui/core';
import { StatefulTimePicker } from '@components/StatefulInputs/StatefulTimePicker/StatefulTimePicker';
import { makeStyles } from '@material-ui/core/styles';
import {
	EndTimeValidation,
	StartTimeValidation,
} from '@components/FindATime/utilities/FindATimeFormValidation';
import { useFormContext } from 'react-hook-form';
import { FindATimeForm } from '@components/FindATime/FindATimeForm';
import useCollection from '@utilities/hooks/useCollection/useCollection';
import { Unit } from '@data/unit/Unit';
import { ENDPOINT_USER_UNITS } from '@utilities/apiConstants';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: theme.spacing(3),
	},
	input: {
		flex: 1,
		flexBasis: 148,
	},
	hideErrorIcon: {
		'& .MuiSvgIcon-root': {
			display: 'none',
		}
	},
}));

const START_FIELD_NAME: keyof FindATimeForm = 'startTime';
const END_FIELD_NAME: keyof FindATimeForm = 'endTime';
const LOCATION_FIELD_NAME: keyof FindATimeForm = 'appointmentLocation';
const META_DATA_FIELD_NAME: keyof FindATimeForm = 'metaData';

export const FindATimeTimeRange: React.FC = () => {
	const classes = useStyles();
	const {
		watch,
		setValue,
		trigger,
		formState: { errors },
	} = useFormContext<FindATimeForm>();
	const [
		appointmentLocation,
		startTime,
		endTime
	] = watch([
		LOCATION_FIELD_NAME,
		START_FIELD_NAME,
		END_FIELD_NAME
	]);
	const units = useCollection<Unit>(ENDPOINT_USER_UNITS);

	const selectedUnitInfo = React.useMemo(() => units?.find((u) => u.id === appointmentLocation), [appointmentLocation, units]);

	React.useEffect(() => {
		if (!selectedUnitInfo) {
			// reset form metaData if no unit info
			setValue(META_DATA_FIELD_NAME, undefined);
			return;
		}
		const { scheduleConfig } = selectedUnitInfo;
		if (!scheduleConfig) {
			// reset form metaData if no prime time info
			setValue(META_DATA_FIELD_NAME, undefined);
			return;
		}

		// set metaData to use for field validation if data exists
		setValue(META_DATA_FIELD_NAME, {
			primeTimeStart: scheduleConfig?.start,
			primeTimeEnd: scheduleConfig?.end,
		});
	}, [setValue, selectedUnitInfo]);

	React.useEffect(() => {
		// if start time, end time, or location changes
		// run validation for both fields to ensure time range error displays properly
		void trigger(START_FIELD_NAME);
		void trigger(END_FIELD_NAME);
	}, [startTime, endTime, appointmentLocation, trigger]);

	const hasTimeRangeError = errors.startTime?.type === 'minRange' || errors.endTime?.type === 'minRange';

	const defaultStartTime = selectedUnitInfo?.scheduleConfig?.start;
	const defaultEndTime = selectedUnitInfo?.scheduleConfig?.end;

	return (
		<Box className={classes.root}>
			<StatefulTimePicker<FindATimeForm>
				controllerOptions={{
					defaultValue: defaultStartTime,
					rules: StartTimeValidation,
				}}
				label="Time Range: Begin"
				name={START_FIELD_NAME}
				wrapperClassName={classNames(classes.input, {
					[classes.hideErrorIcon]: hasTimeRangeError,
				})}
				dataField="findATimePage-searchSection-startTimeInput"
			/>
			<StatefulTimePicker
				controllerOptions={{
					defaultValue: defaultEndTime,
					rules: EndTimeValidation,
				}}
				label="Time Range: End"
				name={END_FIELD_NAME}
				wrapperClassName={classes.input}
				dataField="findATimePage-searchSection-endTimeInput"
			/>
		</Box>
	);
};
