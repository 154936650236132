export const BASE_API_URL = window.config.BASE_API_URL;
export const ASCENSION_ID_INSTANCE = window.config.ASCENSION_ID_INSTANCE;
export const ASCENSION_ID_APPLICATION_ID = window.config.ASCENSION_ID_APPLICATION_ID;
export const ASCENSION_ID_BASE_DOMAIN = window.config.ASCENSION_ID_BASE_DOMAIN;
export const SURGERY_API_ENDPOINT = '/v2/surgery-requests/';
export const ENDPOINT_SURGERY_SEARCH_API = '/v2/surgery-requests/search';
export const DEFAULT_PAGE = 1;
export const DEFAULT_SIZE = 25;
export const DEFAULT_CASES_SORT = 'procedureDate:asc';
export const DEFAULT_TEMPLATES_SORT = 'name:asc';
export const ALL = 'all';
export const ENDPOINT_USER_UNITS = '/hospital/user/unit/';
export const ENDPOINT_USER_PROFILE = 'user/profile/';
export const ENDPOINT_USER_PROFILE_DETAILS = 'user/profile/detail';
export const ENDPOINT_USER_PROFILE_NOTIFICATION = '/user/profile/notification';
export const ENDPOINT_USER_ACTIVATE = 'user/profile/activate/';
export const ENDPOINT_USER_PROFILE_TERMS_OF_USE = '/user/profile/termsAccepted/true';
export const ENDPOINT_REQUEST_CONFIRM = '/v2/surgery-requests/{requestId}/confirmations/{confirmId}';
export const ENDPOINT_SURGEONS = '/surgeons/';
export const ENDPOINT_ALL_SURGEONS = '/surgeons/practice/';
export const ENDPOINT_ALL_TEMPLATE = '/templates/';
export const ENDPOINT_LOCK_TEMPLATE = '/templates/{id}/lock';
export const ENDPOINT_UNLOCK_TEMPLATE = '/templates/{id}/unlock';
export const ENDPOINT_DELETE_TEMPLATE = '/templates/inactive/{id}';
export const ENDPOINT_UPDATE_REVISION = '/v2/surgery-requests/{requestId}/revisions/request';
export const ENDPOINT_REVISION_CLEAR = '/v2/surgery-requests/{requestId}/revisions/{revisionId}';
export const ENDPOINT_APPROVE_CANCELLATION = '/v2/surgery-requests/{requestId}/cancellations/approve';
export const ENDPOINT_MARK_AS_SEEN_CANCELLED = '/v2/surgery-requests/{requestId}/cancellations/mark-as-seen';
export const ENDPOINT_CANCEL_SURGERY = '/v2/surgery-requests/{requestId}/cancellations/pending';
export const ENDPOINT_LOCK_CASE = '/surgery/schedule/{requestId}/lock';
export const BOOKMARK_CASE = '/surgery/schedule/{requestId}/bookmark';
export const ENDPOINT_UNLOCK_CASE = '/surgery/schedule/{requestId}/unlock';
export const ENDPOINT_LOCK_CASE_STATUS = '/surgery/schedule/{requestId}/lock/status';
export const ENDPOINT_OTHER_FILTERS = '/surgery/schedule/other-filters';
export const HEADERS_TOTAL_COUNT = 'x-total-count';
export const ENDPOINT_UPDATE_CASE_IN_USE_STATUS = '/v2/surgery-requests/{requestId}/users';
export const ENDPOINT_DELETE_CASE_IN_USE_STATUS = '/v2/surgery-requests/{requestId}/users/{userId}';

export const ENDPOINT_OR_OPENTIME = 'v1/opentime';
export const ENDPOINT_UNAVAILABLE_EVENT = '/v1/opentime/unavailable';
export const ENDPOINT_EDIT_UNAVAILABLE_EVENT = '/v1/opentime/unavailable/{unavailableEventId}';
export const ENDPOINT_DELETE_UNAVAILABLE_EVENT = '/v1/opentime/unavailable/{unavailableEventId}';
export const ENDPOINT_OFFICE_OPENTIME = 'v1/calendars/availabletime';
export const ENDPOINT_CREATE_HOLD_EVENT = 'v1/opentime/hold-events';
export const ENDPOINT_UPDATE_DELETE_HOLD_EVENT = 'v1/opentime/hold-events/{holdEventId}';

export const ENDPOINT_SURGEON_TIMES = 'v1/calendars/surgeontimes';
export const ENDPOINT_FIND_A_TIME = 'v1/calendars/findtimes';
export const ENDPOINT_SLOTS = 'v1/calendars/events/slots';

export const ENDPOINT_DOC = '/v1/documents/{id}';
export const ENDPOINT_DOC_PRINT = '/v1/documents/{documentId}/print';
export const ENDPOINT_DOC_INDEX = '/v1/documents/{documentId}/index';
export const ENDPOINT_SAVE_DOC_INDEX = '/v1/documents/{documentId}/index';

export const ENDPOINT_DOC_VIEW_PERMISSION = '/v1/documents/CHECKFORPERMISSION/print';

export const ENDPOINT_CASE_ACTIVITY = '/v2/surgery-requests/{requestId}/activities';

export const ENDPOINT_PROCEDURES = '/v2/procedures';

export const ENDPOINT_PROCEDURE_DURATION = '/v2/procedure-times';

export const ENDPOINT_EQUIPMENT_LIST = '/equipment';
