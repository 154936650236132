import { alpha, createStyles, makeStyles } from '@material-ui/core/styles';
import { RowsColumnsInfo } from '@calendar/types/RowsColumnsInfo';
import { ROW_HEIGHT_PX, TICK_OFFSET } from '@calendar/utilities/CalendarConstants';
import React from 'react';
import { useCalendarContext } from '@calendar/state';
import { Box } from '@material-ui/core';
import classNames from 'classnames';
import { TimeAxisLabel } from '@calendar/components/TimeAxisLabels/TimeAxisLabel';
import type { Theme as DefaultTheme } from '@material-ui/core/styles/createTheme';

const useStyles = makeStyles<DefaultTheme, Pick<RowsColumnsInfo, 'rows' | 'headerHeight'>>((theme) => createStyles({
	timeAxis: ({ rows, headerHeight }) => ({
		display: 'grid',
		gridTemplateRows: `${headerHeight}px 5px repeat(${rows - 1}, ${ROW_HEIGHT_PX}px)`,
		minWidth: '70px',
	}),
	tick: {
		fontSize: '10px',
		lineHeight: '16px',
		fontFamily: 'HCo Whitney SSm',
		letterSpacing: '1.25px',
		gridColumn: '1',
		gridRowEnd: 'span 1',
		paddingRight: '10px',
		textAlign: 'right',
		color: alpha(theme.palette.common.black, .60),
		background: theme.palette.common.white,
	},
	blank: {
		gridRowStart: 1,
		gridRowEnd: TICK_OFFSET,
		position: 'sticky',
		top: 0,
	},
	tickHour: {
		color: alpha(theme.palette.common.black, .86),
		fontWeight: 500,
	},
}));

const TimeAxisLabels: React.FC = () => {
	const { memoizedTicks: ticks, headerHeight } = useCalendarContext();
	const classes = useStyles({ rows: ticks.length, headerHeight });
	return <Box className={classes.timeAxis}>
		{ticks.map(({ formatted, minute, minutesSinceMidnight }, index) =>
			<TimeAxisLabel
				key={minutesSinceMidnight}
				minutesSinceMidnight={minutesSinceMidnight}
				style={{
					gridRowStart: index + TICK_OFFSET,
				}}
				className={classNames(
					{ [classes.tickHour]: minute === 0 },
					classes.tick
				)}
			>
				{formatted}
			</TimeAxisLabel>
		)}
		<Box className={classNames(classes.tick, classes.blank)}/>
	</Box>;
};

export default TimeAxisLabels;
