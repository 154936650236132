import React from 'react';
import { DrawerPane } from '@ascension/ui-library';
import {
	OfficeCalendarViewContextProvider,
	useOfficeCalendarViewContext
} from '@components/OfficeCalendarView/OfficeCalendarViewContext/OfficeCalendarViewContext';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core';
import BlueOutlineButton from '@components/BlueOutlineButton/BlueOutlineButton';
import OfficeCalendarViewLayout from '@components/OfficeCalendarView/OfficeCalendarViewLayout/OfficeCalendarViewLayout';
import OfficeCalendarViewProps from '@components/OfficeCalendarView/OfficeCalendarViewProps';
import { ClickCoordinateBox } from '@components/ClickCoordinateBox/ClickCoordinateBox';
import FormContextProvider from '@components/FormContextProvider/FormContextProvider';
import SelectOpenTimeFormValues from '@components/OfficeCalendarView/SelectOpenTimeFormValues';

const useStyles = makeStyles((theme) => ({
	container: {
		'& .MuiAscensionDrawerPane-drawerPaper': {
			boxSizing: 'border-box',
			padding: theme.spacing(1, 0, 0, 0),
			width: '83%',
		},
	},
	closeIcon: {
		'top' : theme.spacing(1),
		'right' : theme.spacing(1),
		'position' : 'absolute',
		'fill': theme.palette.grey[600],
		'&:hover': {
			cursor: 'pointer',
		}
	},
}));

export type OfficeCalendarViewDrawerProps = Pick<OfficeCalendarViewProps, 'onSubmitHoldEventSuccess' | 'isEdit'>;

const OfficeCalendarViewDrawer: React.FC<OfficeCalendarViewDrawerProps> = ({ onSubmitHoldEventSuccess, isEdit }) => {
	const classes = useStyles();
	const { isDrawerOpen, toggleDrawer } = useOfficeCalendarViewContext();

	const handleClose = () => {
		toggleDrawer(false)();
	};

	return (
		<DrawerPane
			translate="yes"
			open={isDrawerOpen}
			variant="temporary"
			anchor="right"
			className={classes.container}
			onClose={handleClose}
		>
			<CloseIcon onClick={handleClose} className={classes.closeIcon} data-field="officeCalendar-drawer-closeIcon"/>
			<ClickCoordinateBox>
				<OfficeCalendarViewLayout onSubmitHoldEventSuccess={onSubmitHoldEventSuccess} isEdit={isEdit}/>
			</ClickCoordinateBox>
		</DrawerPane>

	);
};

type OfficeCalendarViewButtonProps = Omit<OfficeCalendarViewProps, 'unitId' | 'onSubmitHoldEventSuccess' | 'parentSelectedDate' | 'showButton' | 'isEdit'>;

const OfficeCalendarViewButton: React.FC<OfficeCalendarViewButtonProps> = ({ buttonText, ...rest }) => {
	const { toggleDrawer } = useOfficeCalendarViewContext();

	return (
		<BlueOutlineButton
			data-field="officeCalendar-openDrawer-button"
			onClick={toggleDrawer(true)}
			{...rest}
		>
			{ buttonText }
		</BlueOutlineButton>
	);
};

const OfficeCalendarView: React.FC<OfficeCalendarViewProps> = ({
	buttonText,
	unitId,
	showButton,
	onSubmitHoldEventSuccess,
	parentSelectedDate,
	currentEventConfig,
	isEdit,
	...rest
}) => {
	return (
		<FormContextProvider<SelectOpenTimeFormValues> defaultValues={{ timeSpan: {} }}>
			<OfficeCalendarViewContextProvider
				unitId={unitId}
				parentSelectedDate={parentSelectedDate}
				currentEventConfig={currentEventConfig}
				isEdit={isEdit}
			>
				{ showButton && <OfficeCalendarViewButton buttonText={buttonText} {...rest}/> }
				<OfficeCalendarViewDrawer onSubmitHoldEventSuccess={onSubmitHoldEventSuccess} isEdit={isEdit}/>
			</OfficeCalendarViewContextProvider>
		</FormContextProvider>
	);
};

export default OfficeCalendarView;
