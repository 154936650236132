import React from 'react';
import { DropdownOption } from '@definitions/DropdownOption';
import { UseDropdownFormContextConfigInterface } from '@utilities/hooks/useDropdownFormContext/UseDropdownFormContextConfigInterface';
import { UseDropdownFormContextReturnInterface } from '@utilities/hooks/useDropdownFormContext/UseDropdownFormContextReturnInterface';
import { useFieldFormContext } from '@utilities/hooks/useFieldFormContext/useFieldFormContext';

export const useDropdownFormContext = <ValueType = string>(name: string, initialDropdownOptions: DropdownOption<ValueType>[] = [], { validationRules }: Partial<UseDropdownFormContextConfigInterface> = {}): UseDropdownFormContextReturnInterface<ValueType> => {
	const [dropdownOptions, setDropdownOptions] = React.useState<DropdownOption<ValueType>[]>(initialDropdownOptions);
	const {
		currentValue,
		setCurrentValue,
		unregisterSelf,
		error,
	} = useFieldFormContext<DropdownOption<ValueType>>(name, { validationRules, triggerValidationOnChange: true });

	const setSelectedValue = (newSelectedVal: ValueType) => {
		const newOption: DropdownOption<ValueType> | undefined = dropdownOptions.find((o: DropdownOption<ValueType>) => o.value === newSelectedVal);
		if (newOption !== undefined) {
			setCurrentValue(newOption);
		}
	};

	return {
		selectedDropdownOption: currentValue || { label: '', value: '' } as unknown as DropdownOption<ValueType>,
		setSelectedValue,
		dropdownOptions,
		setDropdownOptions,
		error,
		unregisterSelf,
	};
};
