import React from 'react';
import { useFormContext } from 'react-hook-form';
import { add } from 'date-fns';
import { useUnitCalendarPageContext } from '@components/UnitCalendarPage/UnitCalendarPageContext/UnitCalendarPageContext';
import getMidnightLocal from '@calendar/utilities/getMidnightLocal/getMidnightLocal';
import TimeSpanAutocompleteInput from '@components/TimeSpanAutocompleteInput/TimeSpanAutocompleteInput';
import { OVERLAPPING_SCHEDULED_TIME } from '@utilities/Validation/ValidationMessages';
import ErrorMessageMui from '@components/ErrorMessageMui/ErrorMessageMui';
import WarningMessageMui from '@components/WarningMessageMui/WarningMessageMui';
import { useFieldFormContext } from '@utilities/hooks/useFieldFormContext/useFieldFormContext';
import OREventType from '@data/openCalendar/OREventType';
import {
	checkEventTimeRange,
	EventTimeValidationSchema
} from './EventTimeValidationSchema';
import {
	START_INDICATOR_STR,
	START_INDICATOR_REGEXP,
	END_INDICATOR_REGEXP,
	END_INDICATOR_STR,
} from '@utilities/constants';
import SubmitUnavailableEventHookResult from '@data/openCalendar/SubmitUnavailableEventHookResult';
import { PartialTimeSpan } from '@utilities/Validation/TimeSpanValidationUtilities/TimeSpanValidationUtilities';
import useTimeSpanAutoCalculate from '@utilities/hooks/useTimeSpanAutoCalculate/useTimeSpanAutoCalculate';

interface EventTimeSpanInputProps extends Pick<SubmitUnavailableEventHookResult, 'hasAPIConflictError' | 'setTimeSpanAPIError'> {
	label?: string;
}

const EventTimeSpanInput: React.FC<EventTimeSpanInputProps> = ({ label, hasAPIConflictError, setTimeSpanAPIError }) => {
	const {
		selectedRoom,
		events,
		unitConfig: { interval, hospitalTimeZone }
	} = useUnitCalendarPageContext();
	const { watch } = useFormContext<{ date: Date; id: string }>();
	const [ date, id ] = watch(['date', 'id']);

	const [warning, setWarning] = React.useState(false);

	const defaultStartTime = React.useMemo(() => getMidnightLocal(hospitalTimeZone), [hospitalTimeZone]);
	const defaultEndTime = React.useMemo(() => add(defaultStartTime, { days: 1 }), [defaultStartTime]);

	const {
		currentValue: timeSpanValue = {},
		setCurrentValue: setTimeSpanValue,
		triggerSelfValidation: triggerTimeSpanValidation,
		error: timeSpanError,
	} = useFieldFormContext<PartialTimeSpan>(
		'timeSpan',
		{
			validationRules: EventTimeValidationSchema.timeSpan,
			triggerValidationOnChange: true
		}
	);

	const {
		start: currentStartInputValue = '',
		end: currentEndInputValue = '',
	} = timeSpanValue;

	const {
		setStartTime: handleStartInputValueChange,
		setEndTime: handleEndInputValueChange,
	} = useTimeSpanAutoCalculate(hospitalTimeZone, date);

	React.useEffect(() => {
		const unavailableRoomEvents = events.filter(e => e.type === OREventType.UNAVAILABLE.toString() && e.column === selectedRoom && e.id !== id);

		setTimeSpanValue((current: PartialTimeSpan) => ({
			...current,
			interval,
			date,
			timezone: hospitalTimeZone,
			overlapEvents: unavailableRoomEvents,
		}));
	}, [events, hospitalTimeZone, interval, date, selectedRoom, setTimeSpanValue, id]);

	const triggerValidation = React.useCallback(() => {
		void triggerTimeSpanValidation();

		const validation = checkEventTimeRange({
			timeSpan: timeSpanValue,
			overlapEvents: events.filter(e => e.column === selectedRoom && e.type === OREventType.SCHEDULED.toString()),
			overlapMsg: OVERLAPPING_SCHEDULED_TIME
		});
		setWarning(validation === OVERLAPPING_SCHEDULED_TIME);
	}, [events, selectedRoom, timeSpanValue, triggerTimeSpanValidation]);

	React.useEffect(() => {
		// trigger validation for overlapping scheduled events when component is mounted (i.e. when creating new unavailable event)
		triggerValidation();
	}, [triggerValidation]);

	const hasStartErr = timeSpanError.includes(START_INDICATOR_STR);
	const hasEndErr = timeSpanError.includes(END_INDICATOR_STR);

	const errors = React.useMemo(() => timeSpanError
		.replace(START_INDICATOR_REGEXP, '')
		.replace(END_INDICATOR_REGEXP, '')
		.split(';')
		.filter(e => e), [timeSpanError]
	);

	React.useEffect(() => {
		// manually set timeSpan field error when we last received 409 conflict response status from API
		if (hasAPIConflictError.current) {
			setTimeSpanAPIError();
		}
	});

	React.useEffect(() => {
		// manually clear API error anytime room, date, or start/end time change
		hasAPIConflictError.current = false;
	}, [hasAPIConflictError, selectedRoom, timeSpanValue.start, timeSpanValue.end, date]);

	return (
		<>
			<TimeSpanAutocompleteInput
				label={label}
				defaultStartOption={defaultStartTime}
				currentStartInputValue={currentStartInputValue}
				onStartInputValueChange={handleStartInputValueChange}
				defaultEndOption={defaultEndTime}
				currentEndInputValue={currentEndInputValue}
				onEndInputValueChange={handleEndInputValueChange}
				hasStartErr={hasStartErr}
				hasEndErr={hasEndErr}
				hospitalTimeZone={hospitalTimeZone}
				interval={interval}
				triggerValidation={triggerValidation}
			/>
			{errors.map((msg) =>
				<ErrorMessageMui key={msg} errorMessage={msg} />
			)}
			{warning &&
				<WarningMessageMui warningMessage={OVERLAPPING_SCHEDULED_TIME} />
			}
		</>
	);
};

export default EventTimeSpanInput;
