import { Box, Link, makeStyles, Divider } from '@material-ui/core';
import React from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { useHomeNavigationContext } from './HomeNavigationContext/HomeNavigationContext';
import { NAV_CONFIG_OPTIONS, NavOptionProps } from './NavigationOptionsConfig';
import classNames from 'classnames';
import PreferencesMenu from '@components/HeaderSection/NavigationOptions/PreferencesMenu/PreferencesMenu';
import { SchedulingMenu } from '@components/HeaderSection/NavigationOptions/SchedulingMenu/SchedulingMenu';

const useStyles = makeStyles((theme) => {
	return {
		navigationContainer: {
			'display': 'flex',
			'color': theme.palette.common.white,
			'cursor': 'pointer',
			'gap': 10,
			'& hr:last-child': {
				display: 'none',
			},
			'alignItems': 'center',
			'justifyContent': 'center',
		},
		navOption: {
			textTransform: 'uppercase',
			color: theme.palette.primary[100],
			fontWeight: 700,
		},
		activeNav: {
			color: theme.palette.common.white,
		},
		verticalDivider: {
			height: '2rem',
			background: theme.palette.common.white,
			display: 'block',
		},
	};
});

const NavOption: React.FC<NavOptionProps> = ({ label, route }) => {
	const classes = useStyles();

	const { pathname } = useLocation();

	const { setHomeRoute } = useHomeNavigationContext();

	const handleClick = React.useCallback(() => {
		setHomeRoute(route);
	}, [setHomeRoute, route]);

	const isActive = pathname === route;

	return (
		<>
			<Link
				component={RouterLink}
				to={route}
				className={classNames(classes.navOption, {
					[classes.activeNav]: isActive,
				})}
				variant="body1"
				onClick={handleClick}
			>
				{label}
			</Link>
			<Divider
				orientation="vertical"
				className={classes.verticalDivider}
			/>
		</>
	);
};

const NavigationOptions = () => {
	const classes = useStyles();

	return (
		<Box className={classes.navigationContainer}>
			{NAV_CONFIG_OPTIONS.map((options) => (
				<NavOption {...options} key={options.label} />
			))}
			<SchedulingMenu />
			<PreferencesMenu />
		</Box>
	);
};

export default NavigationOptions;
