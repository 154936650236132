import React from 'react';
import { useDrawerContext } from '@components/DrawerWrapper/DrawerContextProvider';
import { DrawerPane } from '@ascension/ui-library';
import { DrawerPaneToggleEvent } from '@ascension/ui-library/dist/components/Drawer';
import { useAppPageBodyClassSetter } from '@components/AppPage/AppPageContext/AppPageContext';
import { Box, makeStyles, Theme } from '@material-ui/core';

interface DrawerWrapperProps {
	drawerId: string;
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		'& .MuiAscensionDrawerPane-root > .MuiAscensionDrawerPane-drawerPaper': {
			boxSizing: 'border-box',
			padding: theme.spacing(3),
		},
	},
}));
const DrawerWrapper: React.FC<DrawerWrapperProps> = ({ children, drawerId }) => {
	const classes = useStyles();

	const { activeDrawer } = useDrawerContext();
	const isOpen = activeDrawer === drawerId;
	const { setBodyClass, setShouldApplyToHeader } = useAppPageBodyClassSetter(drawerId);

	const handleTogglePane = React.useCallback(({ classNames: { marginSize } }: DrawerPaneToggleEvent) => {
		setBodyClass(marginSize);
		setShouldApplyToHeader(isOpen);
	}, [setBodyClass, setShouldApplyToHeader, isOpen]);

	return (
		<Box className={classes.root}>
			<DrawerPane
				translate="yes"
				open={isOpen}
				variant="persistent"
				anchor="right"
				onTogglePane={handleTogglePane}
			>
				{children}
			</DrawerPane>
		</Box>
	);
};

export default DrawerWrapper;
