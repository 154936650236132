import React, { PropsWithChildren } from 'react';
import { ProcedureNameProvider } from '@components/RequestForm/ProcedureName/ProcedureNameContext';
import { useFormContext } from 'react-hook-form';
import { FindATimeForm } from '@components/FindATime/FindATimeForm';

export const ProcedureNameSearchFilterProvider: React.FC<PropsWithChildren<Record<never, never>>> = ({ children }) => {
	const { watch } = useFormContext<FindATimeForm>();
	const [unitId] = watch(['appointmentLocation']);

	return (
		<ProcedureNameProvider unitId={unitId} allowCustom={false}>
			{children}
		</ProcedureNameProvider>
	);
};
