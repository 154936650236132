import React from 'react';
import { ScrollDescriptor } from '../types/ScrollDescriptor';
import { Coordinates } from '../types/Coordinates';

const getCoordinates = (preferredCoordinates: Partial<Coordinates>, secondaryCoordinates: Partial<Coordinates> = {}): Coordinates => {
	let finalX = 0;
	let finalY = 0;
	const { x: preferredX, y: preferredY } = preferredCoordinates;
	const { x: secondaryX, y: secondaryY } = secondaryCoordinates;

	if (typeof preferredX === 'number') {
		finalX = preferredX;
	} else if (typeof secondaryX === 'number') {
		finalX = secondaryX;
	}

	if (typeof preferredY === 'number') {
		finalY = preferredY;
	} else if (typeof secondaryY === 'number') {
		finalY = secondaryY;
	}

	return {
		x: finalX,
		y: finalY,
	};
};

/***
 * useScrollToFocus - used by context to control scroll behavior
 *
 * @returns {
 *     calendarUiRef: MutableRefObject<HTMLDivElement>, // used by CalendarUI component to specify ref of element  for scroll
 *     scrollIntoFocus: () => unknown, // Method to trigger scroll to element
 *     setScrollInfo: Dispatch<SetStateActionScrollDescriptor>, // change data and trigger scrollIntoFocus
 *     setRelativeScrollInfo: Dispatch<SetStateActionScrollDescriptor>, // set relative scroll location.
 *          Only used when scrollTo element provided without the corresponding value.
 * }
 */
export const useScrollToFocus = () => {
	const scrollBoxRef = React.useRef<HTMLDivElement>(null);
	const [scrollInfo, setScrollInfo] = React.useState<ScrollDescriptor>({ x: 0, y: 0 });
	const [relativeScrollInfo, setRelativeScrollInfo] = React.useState<ScrollDescriptor>();

	const scrollIntoFocus = React.useCallback(() => {
		const { scrollTo } = scrollInfo;
		if (!scrollBoxRef.current) {
			return;
		}

		if (scrollTo && scrollTo.current) {
			const { x, y } = getCoordinates(scrollInfo, relativeScrollInfo);

			scrollTo.current.scrollIntoView({ block: 'start' });
			if (scrollBoxRef.current.scrollHeight - scrollBoxRef.current.scrollTop > scrollBoxRef.current.getBoundingClientRect().height - y) {
				scrollBoxRef.current.scrollBy(x, y);
			}
			return;
		}

		const { x, y } = scrollInfo;

		if (typeof x !== 'number' || typeof y !== 'number') {
			return;
		}

		scrollBoxRef.current.scrollTo(x, y);
	}, [relativeScrollInfo, scrollInfo]);

	return {
		scrollBoxRef,
		scrollIntoFocus,
		setScrollInfo,
		setRelativeScrollInfo
	};
};
