import React from 'react';
import { useFormContext } from 'react-hook-form';
import { UserRegistrationFormState } from '../UserRegistration';
import userRegistrationFormStateToUserRegistration from '../utilities/userRegistrationFormStateToUserRegistration';
import useEditUserRegistration from '../../ManageUsersList/utilities/useEditUserRegistration';
import { useModalContext } from '@components/Modal/ModalContextProvider';
import { USER_LOCATION_CONFIRMATION_MODAL } from '@components/Modal/modalConstants';
import UserLocationConfirmationModal
	from '@components/UserRegistrationPage/BasicUserInfoForm/UserLocationConfirmationModal/UserLocationConfirmationModal';
import useCreateUserRegistration from '@components/UserRegistrationPage/utilities/useCreateUserRegistration';
import { useSelector } from 'react-redux';
import { UserPreferenceSelector } from '@store/selectors/UserProfileSelector';

interface RegistrationFormContainerProps {
	isEdit: boolean;
	children?: React.ReactNode;
}

const RegistrationFormContainer: React.FC<RegistrationFormContainerProps> = ({ isEdit, children }) => {
	const { editUserRegistration } = useEditUserRegistration();
	const { handleSubmit, getValues } = useFormContext<UserRegistrationFormState>();
	const { openModal } = useModalContext();
	const { practices } = useSelector(UserPreferenceSelector);
	const { createUserRegistration } = useCreateUserRegistration();

	const onSubmit = React.useCallback(() => {
		const vals = userRegistrationFormStateToUserRegistration(getValues());
		if (isEdit) {
			editUserRegistration(vals);
		} else {
			if (practices && practices.length > 1) {
				return openModal(USER_LOCATION_CONFIRMATION_MODAL);
			}
			createUserRegistration(vals);
		}
	}, [getValues, isEdit, editUserRegistration, practices, createUserRegistration, openModal]);

	return (
		<>
			<UserLocationConfirmationModal />
			<form onSubmit={handleSubmit(onSubmit)} noValidate>
				{children}
			</form>
		</>
	);
};

export default RegistrationFormContainer;
