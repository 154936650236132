import React from 'react';
import { TextLink, Text } from '@ascension/web';
import { TaskCard } from '@ascension/web/components/base/TaskCard';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getTaskCount } from '@store/actions/CaseListActionCreators';
import { CaseListSelector } from '@store/selectors/CaseListSelector';
import { LastUpdateMins } from '@components/SchedulePage/LastUpdate/LastUpdateMins/LastUpdateMins';
import { UserProfileHasARoleSelector } from '@store/selectors/UserProfileSelector';
import { QUEUE_BASE_ROUTE } from '@utilities/constants';
import { StorageKey } from '@utilities/authConstants';
import { Grid, Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TaskName } from '@interfaces/CaseListTypes';
import TASK_CARDS_CONFIG, { ORDERED_TASKS } from './TaskCardConfig';

const useStyles = makeStyles((theme) => createStyles({
	root: {
		'&.MuiGrid-container, & > .MuiGrid-container': {
			gridGap: theme.spacing(3),
		},
	},
}));

interface TaskCardWrapperProps {
	task: TaskName;
}
const TaskCardWrapper = ({ task }: TaskCardWrapperProps) => {
	const { label, showForRoles, filterLink } = TASK_CARDS_CONFIG[task];

	const { taskCount: { [task]: count } } = useSelector(CaseListSelector);

	const shouldShowForUsersRole = useSelector(UserProfileHasARoleSelector(showForRoles)) || !showForRoles;
	const shouldSeeTaskCard = shouldShowForUsersRole && count >= 0;

	const history = useHistory();
	const taskReviewClickHandler = React.useCallback(() => {
		sessionStorage.setItem(StorageKey.QUEUE_QUERY, filterLink);
		history.push(QUEUE_BASE_ROUTE);
	}, [filterLink, history]);

	return shouldSeeTaskCard ? (
		<Box data-field={`task-${task}-box`} flex={1}>
			<TaskCard
				data-field={`task-${task}-card`}
				label={label}
				filterLabel="Review all"
				onClick={taskReviewClickHandler}
				tally={count}
			/>
		</Box>
	) : null;
};

export default function TaskSection() {
	const dispatch = useDispatch();
	React.useEffect(() => {
		dispatch(getTaskCount());
	},
	[dispatch]);

	const { lastUpdate } = useSelector(CaseListSelector);

	const history = useHistory();
	const handleReviewAll = React.useCallback(() => {
		sessionStorage.setItem(StorageKey.QUEUE_QUERY, '?task=1');
		history.push(QUEUE_BASE_ROUTE);
	}, [history]);

	const classes = useStyles();

	return (
		<Grid container direction="column" className={classes.root}>
			<Grid item container direction="row" justifyContent="space-between">
				<Box>
					<Text className="section-title" fontSize={24} fontWeight="550">
						Tasks
					</Text>
					{lastUpdate && <Text
						color="#222"
						ml="7px"
						fontSize="14px"
					>
						Last Updated <LastUpdateMins lastUpdateTime={lastUpdate}/> minutes ago.
					</Text>}
				</Box>
				<Box>
					<TextLink data-field="task-reviewall-link" onMouseDown={handleReviewAll}>
						Review All Tasks &gt;
					</TextLink>
				</Box>
			</Grid>
			<Grid item container direction="row" justifyContent="space-between">
				{ORDERED_TASKS.map((taskName) => <TaskCardWrapper task={taskName} key={taskName}/>)}
			</Grid>
		</Grid>
	);
}
