import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@ascension/ui-library';
import { Add as AddIcon } from '@material-ui/icons';
import { Box } from '@material-ui/core';
import { useIndexingSectionContext } from '@components/PdfViewerPage/IndexingSection/IndexingSectionContext/IndexingSectionContext';

const useStyles = makeStyles(theme => ({
	button: {
		'& .MuiSvgIcon-root': {
			fill: theme.palette.common.white,
		},
		'&.MuiButton-root': {
			'textTransform': 'unset',
			'fontSize': '1rem',
			'fontWeight': 500,
			'padding': theme.spacing(0.5, 1),
			'& > span:first-child': {
				gap: theme.spacing(1),
			},
		},
	},
	addIcon: {
		backgroundColor: theme.palette.primary.main,
		height: '32px',
		width: '32px',
		borderRadius: '32px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

const AddIndexButton: React.FC = () => {
	const classes = useStyles();

	const { addIndexRow } = useIndexingSectionContext();

	return (
		<Button
			translate="yes"
			data-field="pdfViewerPage-editIndexes-addIndexBtn"
			onClick={addIndexRow}
			className={classes.button}
		>
			<Box className={classes.addIcon}><AddIcon /></Box>
			Add
		</Button>
	);
};

export default AddIndexButton;
