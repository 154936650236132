import { SurgeryProcedureInterface } from '@components/RequestForm/SurgeryInformation/SurgeryInformationInterface';
import { TemplateApi } from '@interfaces/Procedure/TemplateApi';
import { NO_SURGEON_DROPDOWN } from './constants';
import {
	transformDropdownOptionsToEquipmentApi
} from '@components/ProcedureForm/inputs/EquipmentMultiSelect/EquipmentMultiSelectTypeConverters';

export const surgeryProcedureToTemplateApiConverter = (surgProc: SurgeryProcedureInterface): TemplateApi  => {
	return {
		name: 'templateName' in surgProc && surgProc.templateName ? surgProc.templateName : '',
		procedureName: surgProc.procedureName,
		anesthesiaType: surgProc.anesthesiaType ? surgProc.anesthesiaType.map( option => option.value ) : [],
		comments: surgProc.additionalComments ? surgProc.additionalComments : '',
		cptCodes: (surgProc.cptCodes.entriesList && surgProc.cptCodes.entriesList.length) ? [...surgProc.cptCodes.entriesList] : [],
		implantsNeeded: (surgProc.implantsNeeded === 0 || surgProc.implantsNeeded === 1) ? (surgProc.implantsNeeded === 1) : undefined,
		modifierSide: surgProc.modifierSide ? surgProc.modifierSide.value : '',
		modifierApproach: surgProc.modifierApproach ? surgProc.modifierApproach.value : '',
		otherEquipments: surgProc.otherEquipment ? surgProc.otherEquipment : '',
		surgeonId: (surgProc.surgeon && surgProc.surgeon.value && surgProc.surgeon.value !== NO_SURGEON_DROPDOWN.value) ? surgProc.surgeon.value: undefined,
		vendor: surgProc.vendorName,
		robotic: surgProc.robotic,
		equipment: surgProc.equipment ? transformDropdownOptionsToEquipmentApi(surgProc.equipment) : [],
	};
};


