import { Dropdown } from '@ascension/ui-library';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { FormControl } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import {
	useUnitCalendarPageContext
} from '@components/UnitCalendarPage/UnitCalendarPageContext/UnitCalendarPageContext';
import { FormLabel } from '@material-ui/core';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	formControlStyle: {
		'marginTop': '-1px',
		'marginBottom': '-5px',
		'& legend': {
			display: 'none'
		}
	},
	locationSelection: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
		paddingRight: theme.spacing(1),
	},
	formControl: {
		marginTop: theme.spacing(1.25),
		marginLeft: theme.spacing(1),
	},
	locationText: {
		fontWeight: 600,
		paddingBottom: -theme.spacing(2),
		color: 'rgba(0, 0, 0, 0.6)',
	},
	dropDown: {
		padding: theme.spacing(0.5),
		top: -theme.spacing(0.5),
	}
}));

const LocationSelection = () => {
	const classes = useStyles();

	const { selectedUnit, setSelectedUnitId, units, selectedRoom } = useUnitCalendarPageContext();
	const { id: selectedUnitId } = selectedUnit;

	return (
		!selectedRoom ? <Box className={classes.locationSelection}>
			<FormLabel className={classes.locationText}>Location</FormLabel>
			<FormControl variant="outlined"
				className={classes.formControl}>
				<Dropdown
					data-field="unitCalendar-location-selector"
					label=""
					value={selectedUnitId}
					select={setSelectedUnitId}
					translate="yes"
					formControlStyle={classes.formControlStyle}
					labelId="location-selector-label"
					className={classes.dropDown}
					size="small"
				>
					{units.map(unit =>
						<MenuItem key={unit.id} value={unit.id}>{unit.hospitalName} {unit.name}</MenuItem>
					)}
				</Dropdown>
			</FormControl>
		</Box> : null
	);
};

export default LocationSelection;
