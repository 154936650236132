import React from 'react';
import MyFiltersCheckbox from '../../MyFiltersCheckbox';

interface AllSurgeonCheckboxProps {
	onCheck: (i: string) => unknown;
	allSelected: boolean;
}

export const AllSurgeonCheckbox: React.FC<AllSurgeonCheckboxProps> = ({ onCheck, allSelected }) => {
	return React.useMemo(() => <MyFiltersCheckbox
		id="all"
		name="All"
		onCheck={onCheck}
		checked={allSelected}
	/>, [onCheck, allSelected]);
};
