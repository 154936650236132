import React, { ReactNode } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { Box, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { WarningRounded } from '@material-ui/icons';
import GoldButton from '@components/GoldButton/GoldButton';
import BlueOutlineButton from '@components/BlueOutlineButton/BlueOutlineButton';
import NoParamNoReturnCallback from '@interfaces/NoParamNoReturnCallback';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		actions: {
			marginTop: theme.spacing(4),
		},
		cancelBtn: {
			height: 48,
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		},
		closeIcon: {
			position: 'absolute',
			right: theme.spacing(2.5),
			top: theme.spacing(2.5),
			fill: theme.palette.grey[600],
			cursor: 'pointer',
		},
		container: {
			padding: theme.spacing(5),
			paddingBottom: theme.spacing(4),
		},
		content: {
			marginTop: theme.spacing(3),
			color: theme.palette.common.black,
			lineHeight: '1.5rem',
		},
		icon: {
			color: theme.palette.warning.main,
			fontSize: '2rem',
			marginRight: theme.spacing(2),
		},
		submitBtn: {
			height: 48,
			marginLeft: theme.spacing(2),
		},
		title: {
			fontSize: '1.5rem',
			lineHeight: '24px',
		},
	}),
);

interface ConfirmDialogProps {
	buttonText?: string;
	content?: ReactNode;
	icon?: boolean;
	onClose: NoParamNoReturnCallback;
	onConfirm: NoParamNoReturnCallback;
	open: boolean;
	title?: string;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
	buttonText,
	content,
	icon,
	open,
	onClose,
	onConfirm,
	title
}) => {
	const classes = useStyles();

	return (
		<Dialog
			maxWidth="sm"
			fullWidth
			open={open}
			onClose={onClose}
		>
			<CloseIcon onClick={onClose} className={classes.closeIcon} data-field="confirmDialog-closeButton" />
			<div className={classes.container}>
				<Box display="flex" alignItems="center">
					{icon && (
						<WarningRounded className={classes.icon} data-field="confirmDialog-icon" />
					)}
					<Typography
						variant="h4"
						component="h1"
						data-field="confirmDialog-title"
						className={classes.title}
					>
						{title || 'Confirm'}
					</Typography>
				</Box>
				<Box className={classes.content} data-field="confirmDialog-content">
					{content || 'Are you sure?'}
				</Box>
				<Box display="flex" justifyContent="flex-end" alignItems="center" className={classes.actions}>
					<BlueOutlineButton onClick={onClose} className={classes.cancelBtn} data-field="confirmDialog-cancelButton">
							cancel
					</BlueOutlineButton>
					<GoldButton className={classes.submitBtn} data-field="confirmDialog-confirmButton" onClick={onConfirm}>
						{buttonText || 'Confirm'}
					</GoldButton>
				</Box>
			</div>
		</Dialog>
	);
};

export default ConfirmDialog;
