import React from 'react';
import { deepObjectEquality } from '@utilities/deepObjectEquality';
import { useFormContext } from 'react-hook-form';
import DisableSaveConfiguration from '@utilities/hooks/useDisableSave/DisableSaveConfiguration';

export default function useDisableSave<T extends Record<keyof T, unknown>>(defaultFormValues: T, { fieldNamesToCheck, fieldsRequired }: DisableSaveConfiguration = {}) {
	const { watch, formState: { errors } } = useFormContext();
	const watchValues = watch();

	return React.useMemo(() => {
		// Check for errors
		if (Object.keys(errors).filter(fieldName => // Only include errors that fit the criteria
			!!errors[fieldName]?.message && // error key has a value (not a blank error that wouldn't show)
			(!fieldNamesToCheck || fieldNamesToCheck?.includes(fieldName)) // No fieldNamesToCheck (check all) or included in list to check
		).length) {
			return true; // When true, not necessary to continue to check the rest
		}

		// For remaining checks, filter the watched values
		const filteredWatchValues = fieldNamesToCheck ?
			Object.fromEntries( // recombine inside filtered list to new object
				Object.entries(watchValues)
					.filter(([fieldName]) => fieldNamesToCheck?.includes(fieldName)) // only return entries for those that match list to include
			) : watchValues; // When no fieldNamesToCheck, use all watchValues
		const filteredWatchFieldsNames = Object.keys(filteredWatchValues);

		const requiredFieldNames: string[] = (() => {
			if (Array.isArray(fieldsRequired)) { // Already a list, then use that
				return fieldsRequired;
			}
			if (fieldsRequired === true) { // When it's simply true, then use fieldNamesToCheck otherwise the entire watch list
				return fieldNamesToCheck || filteredWatchFieldsNames;
			}
			return []; // When not required, use empty array which will not check for any
		})(); // use IIFE to use if gates for cleaner code

		// Missing ID from watch value should fail validation so iterate through required list rather than filtered
		if (!requiredFieldNames.every(id => !!filteredWatchValues[id])) {
			return true;
		}

		return deepObjectEquality(filteredWatchValues, defaultFormValues);
	}, [defaultFormValues, errors, fieldsRequired, fieldNamesToCheck, watchValues]);
}
