import React from 'react';
import { useDispatch } from 'react-redux';
import { ProcedureRequest } from '@data/procedures/types/ProcedureRequest';
import { getProcedures } from '@data/procedures/actionCreators/getProcedures';

export const useGetProcedures = () => {
	const dispatch = useDispatch();

	return React.useCallback((requestOverrides?: ProcedureRequest) => {
		dispatch(getProcedures(requestOverrides));
	}, [dispatch]);
};
