import React from 'react';
import { Attachment } from '@interfaces/SurgeryRequest/Attachment';
import DocumentDetailsProps
	from '@components/CaseDetails/DocumentInformationDisplay/DocumentDetails/DocumentDetailsProps';

const DocumentDetailsContext = React.createContext<Attachment| null>(null);

export const useDocumentDetailsContext = () => {
	const ctx = React.useContext(DocumentDetailsContext);

	if (!ctx) {
		throw new Error('useDocumentDetailsContext must be used in a child of DocumentDetails.');
	}

	return ctx;
};

const DocumentDetails: React.FC<DocumentDetailsProps> = ({ children, renderAs: RenderAs, ...attachment }) => {
	return <DocumentDetailsContext.Provider value={attachment}>
		{children || <RenderAs />}
	</DocumentDetailsContext.Provider>;
};

export default DocumentDetails;
