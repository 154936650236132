import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserProfileDetailsSelector } from '../../../store/selectors/UserProfileSelector';
import { UserRegistration, UserRegistrationFormDefaultValues } from '../UserRegistration';
import endpointTemplateToUrl from '../../../utilities/endpointTemplateToUrl';
import { apiAction } from '../../../store/middlewares/api/ApiActionCreators';
import { CacheType } from '../../../interfaces/CacheType';
import { ApiActionPayload } from '../../../interfaces/ApiAction/ApiActionPayload';
import ApiEndpoint from '../../../utilities/apiEndpoints/ApiEndpoint';
import apiEndpointConfig from '../../../utilities/apiEndpoints/apiEndpointConfig';
import ApiRestMethod from '../../../utilities/apiEndpoints/ApiRestMethod';
import ApiService from '../../../utilities/apiEndpoints/ApiService';
import { useToast } from '../../../utilities/hooks/useToast/useToast';
import { ToastType } from '../../../interfaces/ToastStatus/ToastStatusState';
import { useFormContext } from 'react-hook-form';
import { ToastConstants } from '../../../utilities/toastConstants';

const createUserActionCreator = (userId: string, teamId: string, newUserInfo: UserRegistration, apiActionRequest: Partial<ApiActionPayload> & Pick<ApiActionPayload, 'onSuccess'>) => {
	const url = endpointTemplateToUrl(apiEndpointConfig[ApiEndpoint.USER_REGISTRATION].path, { userId, teamId });

	return apiAction({
		url,
		method: ApiRestMethod.POST,
		data: newUserInfo,
		cachePolicy: CacheType.NO_CACHE,
		includeAccessToken: true,
		shouldDisableErrorHandler: false,
		label: ApiEndpoint.USER_REGISTRATION,
		apiService: ApiService.USER,
		...apiActionRequest,
	});
};

const useCreateUserRegistration = () => {
	const dispatch = useDispatch();
	const { reset } = useFormContext();
	const { userId, primaryPractice } = useSelector(UserProfileDetailsSelector);
	const showToast = useToast();

	const { id: practiceId } = primaryPractice || {};

	const createUserRegistration = React.useCallback((newUser: UserRegistration) => {
		if (!practiceId) {
			return;
		}

		const onSuccess = () => {
			showToast(ToastType.SUCCESS, ToastConstants.SUBMIT_REGISTRATION_SUCCESS);
			reset(UserRegistrationFormDefaultValues);
		};

		dispatch(createUserActionCreator(userId, practiceId, newUser, {
			onSuccess,
		}));
	}, [dispatch, practiceId, reset, showToast, userId]);

	return { createUserRegistration };
};

export default useCreateUserRegistration;
