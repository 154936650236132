import { ApiActionPayload } from '@interfaces/ApiAction/ApiActionPayload';
import HoldEventDetailsRequest, { isHoldEventDetailsRequest } from '@data/holdEvent/types/HoldEventDetailsRequest';
import { ErrorStatusState } from '@interfaces/ErrorStatus/ErrorStatusState';

export function isHoldEventDetailsCreateRequest(u: unknown): u is HoldEventDetailsUpdateRequest {
	return isHoldEventDetailsRequest(u) && (!('id' in u) || !u.id);
}
export interface HoldEventDetailsCreateRequest extends HoldEventDetailsRequest {
	id?: never;
}
export interface CreateHoldEventApiPayload extends Partial<ApiActionPayload<HoldEventDetailsCreateRequest, ErrorStatusState, HoldEventDetailsResponse>> {
	data: HoldEventDetailsCreateRequest;
}

export function isHoldEventDetailsUpdateRequest(u: unknown): u is HoldEventDetailsUpdateRequest {
	return isHoldEventDetailsRequest(u) && 'id' in u && !!u.id;
}

export interface HoldEventDetailsUpdateRequest extends HoldEventDetailsRequest {
	id: string;
}

export type HoldEventDetailsResponse = HoldEventDetailsUpdateRequest;

export interface UpdateHoldEventApiPayload extends Partial<ApiActionPayload<HoldEventDetailsUpdateRequest, ErrorStatusState, HoldEventDetailsResponse>> {
	data: HoldEventDetailsUpdateRequest;
}

export interface DeleteHoldEventApiPayload extends Partial<ApiActionPayload<never, ErrorStatusState, HoldEventDetailsResponse>> {
	data?: never;
}
