import { FormSection } from './interface/FormSection';
import { ScheduleColumnData } from '@data/schedules/types/ScheduleColumnData';
import { FindATimeForm } from '@components/FindATime/FindATimeForm';
import CommonRequestForm from '@interfaces/RequestForm/forms/CommonRequestForm';
import { FindATimeResponse } from '@data/findATime/types/FindATimeResponse';

export enum RequestFormPageSources {
	FindATime = 'FindATime',
	DailySurgeonCalendarAddButton = 'DailySurgeonCalendarAddButton',
}

interface CopyFrom {
	sections: FormSection[];
}

interface FindATimeResultsToRequestFormValues extends Partial<CommonRequestForm> {
	currentFindATimeResults?: FindATimeResponse,
	currentSelectedTimeSlot: string,
}

interface PreviousFindATimeValues extends FindATimeForm {
	previousFindATimeResults?: FindATimeResponse;
}

interface RequestFormPageState {
	blockInfo?: ScheduleColumnData;
	copyFrom?: CopyFrom;
	findATimeRequestFormValues?: FindATimeResultsToRequestFormValues;
	from?: RequestFormPageSources;
	isSchedulingEdit?: boolean;
	previousFindATimeValues?: PreviousFindATimeValues;
}

export default RequestFormPageState;
