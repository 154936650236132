import React from 'react';
import { deepObjectEquality } from '@utilities/deepObjectEquality';
import CommonRequestForm from '@interfaces/RequestForm/forms/CommonRequestForm';
import { RequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';

/**
 * Hook to determine if a form is dirty.
 *
 * This hook compares the current values of a form with its initial values to determine if the form is dirty.
 * A form is considered dirty if the current values and initial values are not deeply equal.
 *
 * @param {CommonRequestForm | RequestFormValidatedState} currentValues - The current values of the form.
 * @param {CommonRequestForm | RequestFormValidatedState} initialValues - The initial values of the form.
 * @returns {boolean} - A boolean indicating whether the form is dirty.
 */
export const useIsDirtyForm = (
	currentValues: CommonRequestForm | RequestFormValidatedState,
	initialValues?: CommonRequestForm | RequestFormValidatedState,
): boolean => {
	const [isDirtyForm, setIsDirtyForm] = React.useState(false);

	React.useEffect(() => {
		if (currentValues && initialValues) {
			return setIsDirtyForm(!deepObjectEquality(currentValues, initialValues));
		}

		setIsDirtyForm(false);
	}, [currentValues, initialValues]);

	return isDirtyForm;
};
