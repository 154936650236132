import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useCollection from '@utilities/hooks/useCollection/useCollection';
import { Unit } from '@data/unit/Unit';
import { ENDPOINT_USER_UNITS } from '@utilities/apiConstants';
import { getFacilitiesFromUnits } from '@utilities/getFacilitiesFromUnits';
import useSessionStorageCache from '@utilities/hooks/useSessionStorageCache/useSessionStorageCache';
import { StorageKey } from '@utilities/authConstants';
import { updateSelectedFacility } from '@store/actions/SharedFiltersActionCreators';
import {
	UseFacilitySelectionReturn
} from '@components/SchedulePage/utilities/useFacilitySelection/UseFacilitySelectionReturn';
import { SharedFiltersSelector } from '@store/selectors/SharedFiltersSelector';
import { getUnitsWithOpenTime } from '@utilities/getUnitsWithOpenTime';

export const useFacilitySelection = (): UseFacilitySelectionReturn => {
	const units = useCollection<Unit>(ENDPOINT_USER_UNITS);
	const unitsWithOpenTime = React.useMemo(() => {
		return getUnitsWithOpenTime(units);
	}, [units]);

	const facilities = React.useMemo(() => getFacilitiesFromUnits(unitsWithOpenTime), [unitsWithOpenTime]);

	const facilitiesSorted = React.useMemo(() =>
		facilities?.sort((a, b) => {
			if (a.name > b.name) return 1;
			if (a.name < b.name) return -1;
			return 0;
		}), [facilities]);

	const {
		storedValue: storedFacilityId,
		setStoredValue: setStoredFacilityId,
	} = useSessionStorageCache(StorageKey.SCHEDULE_FACILITY);

	const sharedFilter = useSelector(SharedFiltersSelector);
	const [selectedFacilityId, setSelectedFacilityId] = React.useState<string>(sharedFilter.selectedFacilityId);

	const facilityId: string | undefined = React.useMemo(() => {
		// if selectedFacility exists in redux, return that
		if (selectedFacilityId) { return selectedFacilityId; }

		if (storedFacilityId) {
			// Or use facility from session storage when it exists
			return facilitiesSorted.find(f => f.id === storedFacilityId)?.id;
		} else {
			// otherwise, set to first in the list
			return facilitiesSorted[0]?.id;
		}
	}, [storedFacilityId, selectedFacilityId, facilitiesSorted]);

	const selectedFacility = React.useMemo(() => {
		return facilitiesSorted.find((hospital) =>
			hospital.units.some((unit) => unit.id === facilityId) || hospital.id === facilityId
		);
	}, [facilitiesSorted, facilityId]);

	const dispatch = useDispatch();
	const setSelectedFacility = React.useCallback((facilityId: string, updateFacility = true) => {
		if (updateFacility) {
			setStoredFacilityId(facilityId);
			dispatch(updateSelectedFacility(facilityId));
		}
		setSelectedFacilityId(facilityId);
	}, [setStoredFacilityId, dispatch]);

	return {
		selectedFacility,
		setSelectedFacility,
		facilitiesSorted
	};
};
