import React from 'react';
import { Dialog, DialogProps, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Theme as DefaultTheme } from '@material-ui/core/styles/createTheme';
import classNames from 'classnames';

interface EventErrorDialogStylesProps {
	columnHeight?: number;
}

const useEventErrorStyles = makeStyles<DefaultTheme, EventErrorDialogStylesProps>((theme: Theme) => ({
	root: {
		height: 'max-content',
		minHeight: '100%',
	},
	dialogContainer: ({ columnHeight }) => ({
		paddingTop: `${columnHeight ? columnHeight + 6 : 0}px`,
		paddingLeft: theme.spacing(8.75),
	}),
	errorBackdrop: ({ columnHeight }) => ({
		position: 'absolute',
		background: 'rgba(0, 0, 0, 0.6)',
		top: `${columnHeight ? columnHeight + 6 : 0}px`,
		left: theme.spacing(8.75),
		bottom: '0',
		right: '0',
	}),
	errorPaper: {
		backgroundColor: 'transparent',
		marginTop: '8%',
	},
}));

interface EventErrorDialogProps extends Omit<DialogProps, 'open'> {
	headerHeight?: number;
}

const EventErrorDialog: React.FC<EventErrorDialogProps> = ({ children, className, headerHeight: columnHeight, ...dialogProps }) => {
	const classes = useEventErrorStyles({ columnHeight });

	return (
		// style tag must be here. During render, position: fixed always takes precedence on the component level, regardless of specificity.
		<Dialog
			open={!!children}
			disablePortal
			className={classNames(classes.root, className)}
			classes={{ container: classes.dialogContainer }}
			style={{ position: 'absolute' }}
			BackdropProps={{ classes: { root: classes.errorBackdrop } }}
			PaperProps={{ elevation: 0, classes: { root: classes.errorPaper } }}
			{...dialogProps}
		>
			{children}
		</Dialog>
	);
};

export default EventErrorDialog;
