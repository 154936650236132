function filterEmptyValues(obj: Record<string, unknown>) {
	const copy = { ...obj };

	Object.entries(copy).forEach((entry) => {
		const key = entry[0];
		const val = entry[1];
		if (val === undefined || val ==='') {
			delete copy[key];
		}
	});

	return copy;
}

export default filterEmptyValues;
