import React from 'react';
import { MenuItem } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CUSTOM_PROCEDURE_OPTION } from '@components/RequestForm/ProcedureName/ProcedureNameContext';

const useStyles = makeStyles((theme) => createStyles({
	option: {
		lineHeight: '24px',
		fontSize: '18px',
		fontWeight: 400,
		bottom: 0,
		paddingBottom: theme.spacing(2),
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.background.paper,
	}
}));

interface CustomProcedureOptionProps {
	onClick: (value: string) => void;
	selected: boolean;
}

export const CustomProcedureOption: React.FC<CustomProcedureOptionProps> = ({ onClick, selected }) => {
	const classes = useStyles();

	return (
		<MenuItem
			className={classes.option}
			onClick={() => onClick(CUSTOM_PROCEDURE_OPTION)}
			selected={selected}
		>
			+ Custom Procedure
		</MenuItem>
	);
};
