import { intervalToDuration } from 'date-fns';
import { HoldEventDetails } from '@data/holdEvent/types/HoldEventDetails';
import HoldEventDetailsRequest from '@data/holdEvent/types/HoldEventDetailsRequest';
import { getDateFromApiDateTime } from '@calendar';
import { formatInTimeZone } from 'date-fns-tz';

const convertHoldEventApiToDetails = (event: HoldEventDetailsRequest & { id: string }): HoldEventDetails => {
	const durationParts = intervalToDuration({ start: new Date(event.startTime), end: new Date(event.endTime) });
	const { hours, minutes } = durationParts;
	const totalDurationMinutes = (minutes || 0) + ((hours || 0) * 60);

	const startTime = formatInTimeZone(getDateFromApiDateTime(event.startTime), event.hospitalTimeZone, 'hh:mm aa');

	return {
		eventId: event.id,
		date: event.date,
		startTime,
		duration: totalDurationMinutes,
		room: event.room,
		roomId: event.roomId,
	};
};

export default convertHoldEventApiToDetails;
