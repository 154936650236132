import React from 'react';
import { makeStyles } from '@material-ui/core';
import { UserStatusChipProps } from './UserStatusChipProps';
import { createStyles } from '@material-ui/core';
import { Chip } from '@material-ui/core';
import { mapStatusStrToStyle } from '../../MapStatusToString';

const useStyles = makeStyles((theme) => createStyles({
	root: {
		backgroundColor: '#FFF4E6',
		padding: theme.spacing(.5),
		border: '1px solid #DB6200',
		textTransform: 'capitalize',
		fontSize: '14px',
		marginRight: theme.spacing(2),
		fontWeight: 450,
		borderWidth: 1,
		borderStyle: 'solid'
	},
}));

export const UserStatusChip = ({ status }: UserStatusChipProps) => {
	const classes = useStyles();

	return <Chip className={classes.root} style={{
		borderColor: mapStatusStrToStyle[status? status : '']?.borderColor,
		backgroundColor: mapStatusStrToStyle[status? status : '']?.backgroundColor
	}} label={status}/>;
};
