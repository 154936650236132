import { ModalValues } from '@interfaces/ModalValues';

export const ALERT_MODAL = 'ALERT_MODAL';
export const CANCEL_MODAL = 'CANCEL_MODAL';
export const SCHEDULING_CANCEL_MODAL = 'SCHEDULING_CANCEL_MODAL';
export const CASE_LOCK_MODAL = 'CASE_LOCK_MODAL';
export const DELETE_TEMPLATE_MODAL = 'DELETE_TEMPLATE_MODAL';
export const NEEDS_REVISION_MODAL = 'NEEDS_REVISION_MODAL';
export const MY_TEMPLATES_MODAL = 'MY_TEMPLATES_MODAL';
export const REQUEST_REVISION_MODAL = 'REQUEST_REVISION_MODAL';
export const SURGERY_PROCEDURE_MODAL = 'SURGERY_PROCEDURE_MODAL';
export const SURGERY_PROCEDURE_MODAL_MY_TEMPLATES = 'SURGERY_PROCEDURE_MODAL_MY_TEMPLATES';
export const SURGERY_PROCEDURE_TILE_MODAL = 'SURGERY_PROCEDURE_TILE_MODAL';
export const TERMS_OF_USE_MODAL = 'TERMS_OF_USE_MODAL';
export const SELECT_PRACTICE_CONFIRM_MODAL = 'SELECT_PRACTICE_CONFIRM_MODAL';
export const USER_LOCATION_CONFIRMATION_MODAL = 'USER_LOCATION_CONFIRMATION_MODAL';
export const ACCOUNT_LOCK_MODAL = 'ACCOUNT_LOCK_MODAL';
export const COPY_CASE_MODAL = 'COPY_CASE_MODAL';
export const PRIMARY_PROCEDURE_CHANGED_MODAL: ModalValues = {
	id: 'PRIMARY_PROCEDURE_CHANGED_MODAL',
	title: 'Primary Procedure Changed',
	message: 'When changing Primary Procedure, the current Primary Procedure Card will be removed. Do you want to continue with the new primary procedure?',
};
export const SURGERY_LOCATION_CHANGED_MODAL: ModalValues = {
	id: 'SURGERY_LOCATION_CHANGED_MODAL',
	title: 'Surgery Location Changed',
	message: 'When changing Location, all Procedure Cards will be removed. Do you want to continue with the new location?',
};
export const NAVIGATION_WARN_MODAL = 'NAVIGATION_WARN_MODAL';
