import React from 'react';
import { Theme, Typography, TypographyProps } from '@material-ui/core';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		[theme.breakpoints.down('md')]: {
			fontSize: '14px',
		},
	},
}));
const CaseActivityUserAndRole: React.FC<Partial<TypographyProps>> = ({ children, className, ...props }) => {
	const classes = useStyles();

	return (
		<Typography
			variant="h3"
			className={classNames(classes.root, className)}
			{...props}
		>
			{children}
		</Typography>
	);
};

export default CaseActivityUserAndRole;
