import React, { Dispatch, SetStateAction } from 'react';
import { ToggleButton } from '@material-ui/lab';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { IconButton, IconButtonProps, Toolbar } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Typography } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LocalizedUtils } from './CalendarDayUtils';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import CalendarPopoverDay from './CalendarPopoverDay';
import { format } from 'date-fns-tz';
import CalendarDayNavigationProps from '@components/CalendarDayNavigation/CalendarDayNavigationProps';
import { twoBusinessDaysFromToday } from '@components/SchedulePage/SchedulePageContext/SchedulePageContext';
import { Theme as DefaultTheme } from '@material-ui/core/styles/createTheme';

const useStyles = makeStyles<DefaultTheme, Record<'displayDateFormat', string | undefined>>(theme=> ({
	dateString: {
		color: '#000000',
		margin: theme.spacing(0, 2),
		whiteSpace: 'nowrap',
	},
	datePicker: {
		'& .MuiPickersCalendarHeader-switchHeader': {
			'& .MuiPickersCalendarHeader-transitionContainer': {
				order: 3,
			},
			'& .MuiTypography-body1': {
				...theme.typography['h6'],
				textAlign: 'initial',
				fontSize: '1.125em',
				lineHeight: '1.25em',
			},
			'width': '280px',
			'margin': 'auto',
			'marginTop': theme.spacing(1),
		},
		'& .MuiPickersCalendarHeader-daysHeader ': {
			'& .MuiPickersCalendarHeader-dayLabel.MuiTypography-caption': {
				fontWeight: 700,
				color: '#00000099',
			},
			'marginTop': theme.spacing(2),
		},
	},
	leftChevron: {
		'marginLeft': theme.spacing(-2),
		'color': theme.palette.primary.main,
		'&.MuiButtonBase-root.Mui-disabled': {
			pointerEvents: 'unset',
			cursor: 'not-allowed',
		},
	},
	displayDate: ({ displayDateFormat }) => ({
		width: displayDateFormat === 'EEEE, MMM d' ? '270px' : '348px',
	}),
}));

const twoBusinessDaysFromTodayWithoutTime = new Date(twoBusinessDaysFromToday.toDateString());

const shouldDisableDate = (day: MaterialUiPickersDate) => {
	return !!day && day < twoBusinessDaysFromTodayWithoutTime;
};

function handleRenderDay(onSelectedDateChange: Dispatch<SetStateAction<Date>>, shouldDisablePreviousDays?: boolean) {
	return function DayPopover(day: MaterialUiPickersDate, selectedDay: MaterialUiPickersDate, dayInCurrentMonth: boolean) {
		const disabled = shouldDisablePreviousDays && shouldDisableDate(day);
		return <CalendarPopoverDay
			date={day}
			selectedDate={selectedDay}
			dayInCurrentMonth={dayInCurrentMonth}
			onSelectedDateChange={onSelectedDateChange}
			disabled={disabled}
		/>;
	};
}

const TODAY = new Date();

const calendarSwitcherIconStyles: IconButtonProps = { size: 'small', color: 'primary' };

const CalendarDayNavigation: React.FC<CalendarDayNavigationProps> = ({ selectedDate, onSelectedDateChange, disablePastNavigation, shouldDisablePreviousDays, displayDateFormat }) => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
	const classes = useStyles({ displayDateFormat });
	const closeDatePicker = React.useCallback(() => setAnchorEl(null), []);
	const handleDateChange = React.useCallback((date: MaterialUiPickersDate) => {
		onSelectedDateChange(date ? date : TODAY);
	}, [onSelectedDateChange]);
	React.useEffect(() => {
		closeDatePicker();
	}, [closeDatePicker, selectedDate]);
	const handleOpenDatePickerClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl((current) => {
			if (current) { return null; }
			return event.currentTarget;
		});
	};

	const handleNavigateDate = React.useCallback((dayModifier: number) => {
		onSelectedDateChange((currentDate) => {
			const newDate = new Date(currentDate);
			newDate.setDate(newDate.getDate() + dayModifier);
			return newDate;
		});
	}, [onSelectedDateChange]);
	const handleNavigateDateBack = React.useCallback(() => handleNavigateDate(-1), [handleNavigateDate]);
	const handleNavigateDateForward = React.useCallback(() => handleNavigateDate(1), [handleNavigateDate]);
	const renderDay = React.useMemo(() => handleRenderDay(onSelectedDateChange, shouldDisablePreviousDays), [onSelectedDateChange, shouldDisablePreviousDays]);

	const displayDate = React.useMemo(() => format(selectedDate, displayDateFormat || 'EEEE, MMMM d'), [selectedDate, displayDateFormat]);

	return (
		<Box>
			<Toolbar disableGutters={true} variant="dense">
				<IconButton
					data-field="unitCalendar-datePicker-chevronLeft"
					edge="start"
					aria-label="Previous Day"
					onClick={handleNavigateDateBack}
					className={classes.leftChevron}
					disabled={disablePastNavigation}
				>
					<ChevronLeftIcon fontSize="small"/>
				</IconButton>
				<IconButton
					data-field="unitCalendar-datePicker-chevronRight"
					color="inherit"
					aria-label="Next Day"
					onClick={handleNavigateDateForward}
				>
					<ChevronRightIcon fontSize="small" color="primary"/>
				</IconButton>
				<Box className={classes.displayDate}>
					<Typography id="displayDate" variant="h5" component="h2" className={classes.dateString}>
						{displayDate}
					</Typography>
				</Box>
				<ToggleButton
					data-field="unitCalendar-datePicker-toggleButton"
					selected={!!anchorEl}
					value="cal"
					aria-label="Open Date Picker"
					onClick={handleOpenDatePickerClick}
				>
					<CalendarTodayIcon color="primary"/>
				</ToggleButton>
			</Toolbar>
			<MuiPickersUtilsProvider utils={LocalizedUtils}>
				<DatePicker
					data-field="unitCalendar-datePicker-container"
					PopoverProps={{
						anchorEl: anchorEl,
						anchorOrigin: {
							vertical: 'bottom',
							horizontal: 'left',
						},
						transformOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},
						className: classes.datePicker,
					}}
					renderDay={renderDay}
					open={!!anchorEl}
					onClose={closeDatePicker}
					variant="inline"
					disableToolbar
					placeholder="Day, Month Date"
					value={selectedDate}
					onChange={handleDateChange}
					format="yyyy/MM/dd"
					leftArrowButtonProps={calendarSwitcherIconStyles}
					rightArrowButtonProps={calendarSwitcherIconStyles}
					TextFieldComponent={() => null}
					shouldDisableDate={disablePastNavigation ? shouldDisableDate : undefined}

				/>
			</MuiPickersUtilsProvider>
		</Box>);
};

export default CalendarDayNavigation;
