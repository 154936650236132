import { UserRegistrationRole } from '../../../interfaces/UserProfile/UserRoleEnum';
import { UserRegistration, UserRegistrationFormState } from '../UserRegistration';

function valueIsUserRole(userRole: unknown): userRole is UserRegistrationRole {
	return !!(userRole && Object.values(UserRegistrationRole).includes(userRole as UserRegistrationRole));
}

const userRegistrationFormStateToUserRegistration = ({ userRole, ...vals }: UserRegistrationFormState): UserRegistration => {
	return {
		...vals,
		userRoles: valueIsUserRole(userRole) ? [userRole] : null,
	};
};

export default userRegistrationFormStateToUserRegistration;
