import timezoneAsHoursString from '@calendar/utilities/timezoneAsHoursString/timezoneAsHoursString';
import { formatInTimeZone } from 'date-fns-tz';

/***
 * getMidnightLocal - given timezone and date, return Date at midnight for that timezone on the specified date
 *
 * @param timeZone {string} - valid timezone string for date-fns
 * @param date {Date: new Date()} - Date at with any time
 *
 * @returns {Date} Date at midnight for the timezone on the date specified
 *
 * @example
 * getMidnightLocal('America/Chicago') // returns something like new Date('YYYY-MM-DDT00:00:00-05:00')
 *
 * @example
 * getMidnightLocal('America/Montreal', new Date(2022, 5, 4)) // returns something like new Date('2022-05-4T00:00:00-04:00')
 */
const getMidnightLocal = (timeZone: string, date: Date = new Date()) => {
	return new Date(`${
		formatInTimeZone(date, timeZone, 'yyyy-MM-dd' )
	}T00:00:00${
		timezoneAsHoursString(timeZone, date)
	}`);
};

export default getMidnightLocal;
