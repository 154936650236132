import { AxiosResponse } from 'axios';
import {
	POPULATE_TEMPLATE_LIST,
	GET_TEMPLATE_LIST,
	POPULATE_TEMPLATE_LIST_ERROR,
	GET_TEMPLATE_UPDATE_LIST,
	REPLACE_TEMPLATE_IN_LIST
} from './types';
import { ApiActionRequest } from '@interfaces/ApiAction/ApiAction';
import { apiAction } from '../middlewares/api/ApiActionCreators';
import { ENDPOINT_ALL_TEMPLATE, HEADERS_TOTAL_COUNT } from '@utilities/apiConstants';
import errorToString from '../../utilities/errorToString';
import { ReplaceTemplateInListAction, TemplateListActions } from '@interfaces/TemplateListTypes';
import { TemplateInfoApi } from '@interfaces/Procedure/TemplateInfoApi';
import { FilterApiParams } from '@interfaces/FilterParams';
import { ErrorStatusState } from '@interfaces/ErrorStatus/ErrorStatusState';

export function populateTemplateList({ data, headers }: AxiosResponse<TemplateInfoApi[]>): TemplateListActions {
	return {
		type: POPULATE_TEMPLATE_LIST,
		payload: {
			data,
			totalCount: parseInt(headers[HEADERS_TOTAL_COUNT] as string) || 0,
		}
	};
}

export function populateTemplateListError(error: unknown): TemplateListActions {
	return {
		type: POPULATE_TEMPLATE_LIST_ERROR,
		error: errorToString(error)
	};
}

export function getTemplateList (params?: FilterApiParams): ApiActionRequest<unknown, ErrorStatusState, TemplateInfoApi[]> {
	return apiAction<unknown, ErrorStatusState, TemplateInfoApi[]>({
		url: ENDPOINT_ALL_TEMPLATE,
		method: 'GET',
		label: GET_TEMPLATE_LIST,
		data: params,
		includeAccessToken: true,
		onSuccess: populateTemplateList,
		onFailure: populateTemplateListError
	});
}

export function updateTemplateListItem(id: string) {
	const replaceTemplateInList = ({ data }: AxiosResponse<TemplateInfoApi>): ReplaceTemplateInListAction => {
		return {
			type: REPLACE_TEMPLATE_IN_LIST,
			payload: data,
		};
	};

	const endpoint: string = ENDPOINT_ALL_TEMPLATE + id;
	return apiAction<TemplateInfoApi>({
		url: endpoint,
		method: 'GET',
		label: GET_TEMPLATE_UPDATE_LIST,
		includeAccessToken: true,
		onSuccess: replaceTemplateInList,
		onFailure: populateTemplateListError
	});
}

