import React from 'react';
import { ManageCaseOption } from '@components/CaseDetails/ManageCaseDropDown/ManageCaseOption';
import { useCaseActivityContext } from '@components/CaseActivityDrawer/CaseActivityContext/CaseActivityContext';
import { RenderAsProps } from '@components/CaseDetails/ManageCaseDropDown/DropDownOptions/DropDownOptionProps';
import { useDrawerContext } from '@components/DrawerWrapper/DrawerContextProvider';
import { CASE_ACTIVITY_DRAWER } from '@components/DrawerWrapper/drawerConstants';

export const RefreshActivity = ({ closeDropdown }: RenderAsProps) => {
	const { handleGetCaseActivities } = useCaseActivityContext();
	const { activeDrawer } = useDrawerContext();
	const showOption = activeDrawer === CASE_ACTIVITY_DRAWER;

	const handleOnClick = () => {
		handleGetCaseActivities();
		closeDropdown();
	};

	return (
		<>
			{ showOption &&
                <ManageCaseOption onClick={handleOnClick} fieldName="viewActivity">
                    Refresh Case Activity
                </ManageCaseOption>
			}
		</>
	);
};
