import {
	REVISION_INIT_BY_NOT_SELECTED,
	REVISION_MESSAGE_LENGTH_EXCEEDED,
	REVISION_MESSAGE_MISSING, REVISION_REASON_MISSING,
} from '../../../utilities/Validation/ValidationMessages';

export const RequestRevisionValidationSchema = {
	revisionInitiatedBy: {
		required: REVISION_INIT_BY_NOT_SELECTED
	},
	revisionReason: {
		required: REVISION_REASON_MISSING,
	},
	revisionMessage: {
		required: REVISION_MESSAGE_MISSING,
		maxLength: {
			value: 500,
			message: REVISION_MESSAGE_LENGTH_EXCEEDED,
		},
	}
};
