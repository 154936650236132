export enum AlertMessage {
	UNKNOWN,
	TEMPLATE_NOT_FOUND,
	TEMPLATE_IN_USE,
}

export interface AlertMessageDescriptor {
	title: string;
	message: string;
}

export const AlertMessageToDescriptor: Record<AlertMessage, AlertMessageDescriptor> = {
	[AlertMessage.UNKNOWN]: {
		title: 'Unknown error',
		message: 'Unknown error has occurred. Please try again.',
	},
	[AlertMessage.TEMPLATE_IN_USE]: {
		title: 'Template In Use',
		message: 'This template is being used by someone else. Please refresh your page.',
	},
	[AlertMessage.TEMPLATE_NOT_FOUND]: {
		title: 'Template Not Found',
		message: 'This template may have been deleted by someone else. Please refresh your page.',
	}
};
