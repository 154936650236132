import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useCollection from '@utilities/hooks/useCollection/useCollection';
import { Surgeon } from '@data/surgeon/Surgeon';
import { ENDPOINT_SURGEONS } from '@utilities/apiConstants';
import { SharedFiltersSelector } from '@store/selectors/SharedFiltersSelector';
import { updateSelectedSurgeons } from '@store/actions/SharedFiltersActionCreators';

const useSurgeonsSelection = () => {
	const dispatch = useDispatch();
	const prefSurgeons = useCollection<Surgeon>(ENDPOINT_SURGEONS);
	const { selectedSurgeons } = useSelector(SharedFiltersSelector);
	const selectedSurgeonsRef = React.useRef(selectedSurgeons);
	selectedSurgeonsRef.current = selectedSurgeons;

	const setSelectedSurgeons = React.useCallback((surgeonsSelection: Surgeon[] | ((currentlySelected: Surgeon[]) => Surgeon[])) => {
		dispatch(updateSelectedSurgeons(typeof surgeonsSelection === 'function' ? surgeonsSelection(selectedSurgeonsRef.current) : surgeonsSelection));
	}, [dispatch]);

	const filteredSelectedSurgeons = React.useMemo(() => {
		if (!prefSurgeons.length) {
			return selectedSurgeons;
		}
		const prefSurgeonIds = prefSurgeons.map(s => s.id);
		return selectedSurgeons.filter(s => prefSurgeonIds.includes(s.id));
	}, [prefSurgeons, selectedSurgeons]);

	return {
		prefSurgeons,
		selectedSurgeons: filteredSelectedSurgeons,
		setSelectedSurgeons,
	};
};

export default useSurgeonsSelection;
