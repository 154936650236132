import React from 'react';
import useSurgeonsSelectionByIds from '@components/CaseTrackerList/utilities/useSurgeonsSelectionByIds/useSurgeonsSelectionByIds';
import { getSelectionUpdateWithAll } from '@utilities/commonFunction';
import { StorageKey } from '@utilities/authConstants';

const EMPTY_ARRAY: string[] = [];

const useSurgeonsFilterUpdate = () => {
	const { prefSurgeons, selectedSurgeonsByIds: selectedIdList, setSelectedSurgeonsByIds } = useSurgeonsSelectionByIds();
	const prefSurgeonIds = React.useMemo(() => prefSurgeons.map(s => s.id), [prefSurgeons]);

	const updateSurgeonsWithIds = React.useCallback((newSurgeons: string[]) => {
		window.sessionStorage.removeItem(StorageKey.BLOCK_SURGEONS);
		setSelectedSurgeonsByIds((currentlySelected) =>
			getSelectionUpdateWithAll(newSurgeons, prefSurgeonIds, currentlySelected, 'all'));
	}, [prefSurgeonIds, setSelectedSurgeonsByIds]);

	const filterMappedIdList: string[] | undefined = React.useMemo(() => {
		const isAllSelected = selectedIdList.length === prefSurgeonIds.length;
		if (isAllSelected && selectedIdList.length > 0) {
			return EMPTY_ARRAY;
		}

		const isNoneSelected = !selectedIdList.length;
		if (isNoneSelected) {
			return undefined;
		}

		return selectedIdList;
	}, [prefSurgeonIds.length, selectedIdList]);

	return {
		filterMappedIdList,
		selectedIdList: selectedIdList,
		updateSurgeonsWithIds,
	};
};

export default useSurgeonsFilterUpdate;
