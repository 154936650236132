import BuildIcon from '@material-ui/icons/Build';
import EventConfig from '@calendar/types/EventConfig';
import EventStyleConfig from '@interfaces/OpenCalendar/EventStyleConfig';
import { BlockPageLegendType } from '@components/SchedulePage/types/BlockPageConfigType';

export const BlockPageEventConfig: EventConfig<BlockPageLegendType> = {
	SCHEDULED: {
		legendLabel: 'Scheduled',
	},
	UNSCHEDULED: {
		legendLabel: 'Pending',
	},
	NEEDS_REVISION: {
		legendLabel: 'Needs Revision',
		icon: BuildIcon,
	},
};

export const BlockPageStyleConfig: EventStyleConfig<BlockPageLegendType> = {
	SCHEDULED: {
		legend: {
			background: '#E9FBE7',
			border: '2px solid #126E36',
			color: '#006F5F',
		},
	},
	UNSCHEDULED: {
		legend: {
			background: '#FFF7E6',
			border: '2px dashed #FF7F00',
			color: '#B74900',
		},
	},
	NEEDS_REVISION: {
		legend: {
			background: '#B40F87',
			border: '2px solid #B40F87',
			fill: '#fff',
		},
		hasDivider: true,
	},
};
