import {
	ENDPOINT_REVISION_CLEAR,
	ENDPOINT_UPDATE_REVISION
} from '@utilities/apiConstants';
import { apiAction } from '@store/middlewares/api/ApiActionCreators';
import { CacheType } from '@interfaces/CacheType';
import { getCaseDetails } from '@store/actions/CaseDetailsActionCreators';
import { RevisionRequestInterface } from '@interfaces/HospitalUnscheduledAlertStoreInterface';
import endpointTemplateToUrl from '@utilities/endpointTemplateToUrl';
import NoParamNoReturnCallback from '@interfaces/NoParamNoReturnCallback';

export const setRevisionToCleared = (requestId: string, revisionId: string, accepted: boolean) => {
	const url = endpointTemplateToUrl(ENDPOINT_REVISION_CLEAR, { requestId, revisionId });
	return apiAction({
		url: url,
		method: 'DELETE',
		label: 'CLEAR_REVISION',
		includeAccessToken: true,
		asDataOrParams: 'params',
		data: {
			accepted,
		},
		onSuccess: () => getCaseDetails(requestId),
		cachePolicy: CacheType.NO_CACHE,
	});
};

export const setRevision = (requestId: string, payload: RevisionRequestInterface, onSuccess?: NoParamNoReturnCallback) => {
	const url = endpointTemplateToUrl(ENDPOINT_UPDATE_REVISION, { requestId });
	const onSuccessHandler = () => {
		if (onSuccess) {
			onSuccess();
		}
		return getCaseDetails(requestId);
	};
	return apiAction({
		url,
		method: 'POST',
		label: 'UPDATE_REVISION',
		data: payload,
		includeAccessToken: true,
		onSuccess: onSuccessHandler,
		cachePolicy: CacheType.NO_CACHE,
	});
};
