import React from 'react';
import classNames from 'classnames';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import EventCard from '@components/EventCard/EventCard';
import { EventInfo } from '@calendar';
import { HospitalEventStyleConfig } from '@components/UnitCalendarPage/HospitalEventStyleConfig';
import { HospitalCalendarEventConfig } from '@components/UnitCalendarPage/HospitalCalendarEventConfig';
import OREventType from '@data/openCalendar/OREventType';
import EventCardContentWithIcon from '@components/EventCard/EventCardContentWithIcon';

const useStyles = makeStyles((theme) => createStyles({
	card: {
		...HospitalEventStyleConfig.BLOCKTIME.card as Record<string, unknown>,
		paddingLeft: theme.spacing(1),
	},
	icon: {
		color: HospitalEventStyleConfig[OREventType.BLOCKTIME].legend.fill,
	},
}));

const BlockTimeEventCard: React.FC<EventInfo> = (event: EventInfo) => {
	const classes = useStyles();

	const {
		additionalClassName = '',
		title,
		timeSpanDisplay,
	} = event;

	const Icon = HospitalCalendarEventConfig[OREventType.BLOCKTIME].icon;

	return (
		<EventCard
			{...event}
			additionalClassName={classNames(classes.card, {
				[additionalClassName]: !!additionalClassName
			})}
		>
			<EventCardContentWithIcon title={title} timeSpan={timeSpanDisplay} icon={Icon && <Icon className={classes.icon}/>} />
		</EventCard>
	);
};

export default BlockTimeEventCard;
