import React from 'react';
import { Box, Theme } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { useCommonStyles } from '../../../utilities/hooks/useCommonStyles/useCommonStyles';
import { useHistory, useLocation } from 'react-router-dom';
import { SwitchToolTipContentProps } from './SwitchToolTipProps';


const useStyles = makeStyles((theme: Theme) => ({
	wrapperStyle: {
		margin: theme.spacing(1)
	},
	closeIconStyle: {
		fontSize: theme.spacing(1.5),
		cursor: 'pointer'
	},
	titleStyle: {
		fontSize: theme.spacing(1.5),
	},
	setFilterTextStyle: {
		margin: '20px 10px',
		fontSize: theme.spacing(1.5),
		cursor: 'pointer'
	}
}));

export default function SwitchToolTipContent({ titleBody, handleClose }: SwitchToolTipContentProps) {
	const { wrapperStyle, closeIconStyle, titleStyle, setFilterTextStyle } = useStyles();
	const { capitalize } = useCommonStyles();
	const history = useHistory();
	const { search } = useLocation();

	const handleClick = () => {
		history.push({
			pathname: '/accountSettings/myFilters',
			search
		});
	};

	return (
		<Box className={wrapperStyle}>
			<Box textAlign="right" data-field="switch-tooltip-content-close-area">
				<CloseOutlinedIcon className={closeIconStyle} onClick={handleClose}/>
			</Box>
			<Box className={titleStyle} data-field="switch-tooltip-content-titleBody">{titleBody}</Box>
			<Box
				className={`${capitalize} ${setFilterTextStyle}`}
				onClick={handleClick}
				data-field="switch-tooltip-content-set-my-filters"
			>
				Set My Filters
			</Box>
		</Box>
	);
}
