import React, { forwardRef, useCallback } from 'react';
import { Box, Flex, Text, TextLink } from '@ascension/web';
import ModalContentWrapper from '@components/ModalContentWrapper/ModalContentWrapper';
import TemplateForm from '@components/AccountSettings/MyTemplates/TemplateForm/TemplateForm';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@interfaces/rootState';
import { UserProfilePrimaryPracticeSelectorId } from '@store/selectors/UserProfileSelector';
import { getAllSurgeonListData } from '@store/actions/SurgeonListActionCreators';
import { getTemplateList } from '@store/actions/TemplateListActionCreators';
import { TemplateListCountSelector, TemplateListSelector } from '@store/selectors/TemplateListSelector';
import TemplateTile from '@components/AccountSettings/MyTemplates/TemplateTile/TemplateTile';
import { TemplateInfoApi } from '@interfaces/Procedure/TemplateInfoApi';
import { DEFAULT_SIZE, DEFAULT_TEMPLATES_SORT } from '@utilities/apiConstants';
import {
	createDuplicateTemplate,
	createNewTemplate, formatApiTemplateFormError,
	lockTemplate,
	unlockTemplate,
	updateTemplate
} from '@store/actions/TemplateFormActionCreators';
import { ProcedureFormProvider } from '@components/ProcedureForm/ProcedureFormContext/ProcedureFormContext';
import {
	SurgeryProcedureInterface,
	TemplateInfo
} from '@components/RequestForm/SurgeryInformation/SurgeryInformationInterface';
import { surgeryProcedureToTemplateApiConverter } from '@utilities/typeConverters/surgeryProcedureToTemplateApiConverter';
import { SelectedAction, TemplateAction } from '@components/AccountSettings/MyTemplates/TemplateAction';
import { FiltersProvider, useFilterState, useResetAllFilters } from '@components/Filters/FiltersContext/FiltersContext';
import { AllSurgeonFilter } from '@components/Filters/SurgeonFilter/SurgeonFilter';
import usePageEffects from '@utilities/hooks/usePageEffects/usePageEffects';
import { FilterApiParams } from '@interfaces/FilterParams';
import TemplateFilters from '@components/Filters/TemplateFilters/TemplateFilters';
import TemplateListSection from '@components/Templates/TemplateListSection';
import NoTemplatesMessage from '@components/Templates/NoTemplatesMessage';
import { TemplateTileProps } from '@components/AccountSettings/MyTemplates/TemplateTile/TemplateTileProps';
import DeleteTemplateModal from '@components/Templates/DeleteTemplateModal';
import { AxiosError, AxiosResponse } from 'axios';
import { useToast } from '@utilities/hooks/useToast/useToast';
import { ToastType } from '@interfaces/ToastStatus/ToastStatusState';
import templateErrorToAlertMessage from '@utilities/templateErrorToAlertMessage';
import useAppAlert from '@utilities/hooks/useAppAlert/useAppAlert';
import { deepObjectEquality } from '@utilities/deepObjectEquality';
import useHasPermission from '@utilities/hooks/useHasPermission/useHasPermission';
import { PermittedAction } from '@utilities/PermittedAction';
import { TEMPLATE_ENTITY_NAME } from '@utilities/constants';
import ModalViewer from '@components/Modal/ModalViewer';
import {
	DELETE_TEMPLATE_MODAL,
	SURGERY_PROCEDURE_MODAL_MY_TEMPLATES,
} from '@components/Modal/modalConstants';
import { ModalContextProvider, useModalContext } from '@components/Modal/ModalContextProvider';
import { makeStyles } from '@material-ui/core/styles';
import { ErrorStatusState } from '@interfaces/ErrorStatus/ErrorStatusState';

const actionToHeaderText: { [a in TemplateAction]?: string } = {
	[TemplateAction.EDIT]: 'Edit Template',
	[TemplateAction.NEW]: 'Create Template',
	[TemplateAction.DUPLICATE]: 'Duplicate Template',
	[TemplateAction.DELETE]: 'Delete Template',
};

const shouldDisableSubmitOnError = (errors: Record<string, unknown>) => {
	return !!Object.values(errors).length;
};

const shouldDisableSubmit = (errors: Record<string, unknown>, currentVals: TemplateInfo, original: TemplateInfo) => {
	// Disable when errors are present
	return  (shouldDisableSubmitOnError(errors)
		// OR no values object to check
		|| !currentVals
		// OR no template name
		|| !(currentVals.templateName && currentVals.templateName.length)
		// OR no procedure name
		|| !(currentVals.procedureName && currentVals.procedureName.length))
		// OR The current values match the original values
		|| deepObjectEquality(currentVals, original, {
			excludeKeys: ['templateId'],
			treatMissingPropsAsUndefined: true,
		});
};

const useStyles = makeStyles({
	modalWrapper: {
		maxHeight: '90vh',
	}
});

function MyTemplatesInner ()  {
	const classes = useStyles();
	const dispatch = useDispatch();
	const displayToast = useToast();
	const displayAlert = useAppAlert();
	const [selectedAction, setSelectedAction] = React.useState<SelectedAction>({ action: TemplateAction.NONE });
	const { action, templateId, initialData } = selectedAction;
	const practiceId = useSelector<RootState, string>(UserProfilePrimaryPracticeSelectorId);
	const templateList = useSelector(TemplateListSelector);
	const { closeModal, isModalOpen, openModal } = useModalContext();
	const shouldDisableChecker = selectedAction.action === TemplateAction.DUPLICATE ? shouldDisableSubmitOnError : shouldDisableSubmit;
	const resetFilters = useResetAllFilters();
	const templateTileRefs = React.useRef<{[id: string]: HTMLDivElement}>({});
	const [scrollToId, setScrollToId] = React.useState<string | undefined>();
	const [templateFormError, setTemplateFormError] = React.useState<ErrorStatusState | undefined>();
	const hasTemplateModifyPermission = useHasPermission(PermittedAction.MODIFY_TEMPLATE);
	const { loadListWithFilters: updateList } = useFilterState();

	// No dependencies so that this runs on each layout change
	// eslint-disable-next-line react-hooks/exhaustive-deps
	React.useLayoutEffect(() => {
		if (!scrollToId) { return; }
		const templateTileElm = templateTileRefs.current[scrollToId];
		if (templateTileElm) {
			templateTileElm.scrollIntoView();
			document.getElementsByTagName('html')[0].scrollTop -= 150;
			setScrollToId(undefined);
		}
	});

	// Retrieve all surgeons for practice
	React.useEffect(() => {
		if (practiceId !== '') {
			dispatch(getAllSurgeonListData(practiceId));
		}
	}, [dispatch, practiceId]);

	usePageEffects('My Templates');

	const resetActionToNone = () => {
		setSelectedAction({ action: TemplateAction.NONE });
	};

	// Respond to action updates
	React.useEffect(() => {
		const findTemplate = (id: string): Partial<TemplateInfoApi> => {
			return templateList.find(t => t.id === id) || {};
		};
		const copiedTempName = templateId && `COPY OF ${findTemplate(templateId).name}`;

		switch (action) {
		case TemplateAction.DUPLICATE_PENDING:
			if (!templateId) {
				resetActionToNone();
				break;
			}
			setSelectedAction({ templateId, action: TemplateAction.DUPLICATE, initialData: { ...findTemplate(templateId), id: undefined, name: copiedTempName } });
			break;
		case TemplateAction.EDIT_PENDING: {
			if (!templateId) {
				resetActionToNone();
				break;
			}
			const handleLockSuccess = () => {
				const foundTemplate = findTemplate(templateId);
				setSelectedAction({ templateId, action: TemplateAction.EDIT, initialData: foundTemplate });
			};

			const handleLockFail = (err: AxiosError<string>) => {
				resetActionToNone();
				displayAlert(templateErrorToAlertMessage(err), updateList);
			};

			dispatch(lockTemplate(templateId, handleLockSuccess, handleLockFail));
			break;
		}
		}
	}, [action, dispatch, displayAlert, templateId, templateList, updateList]);

	const isSurgeryProcedureModalOpen = React.useMemo(() => {
		return isModalOpen(SURGERY_PROCEDURE_MODAL_MY_TEMPLATES);
	}, [isModalOpen]);

	// Open/close Surgery Procedure Modal based on action
	React.useEffect(() => {
		if (TemplateAction.DELETE === action && templateId) {
			openModal(DELETE_TEMPLATE_MODAL);
			return;
		}

		if ([TemplateAction.EDIT, TemplateAction.DUPLICATE, TemplateAction.NEW].includes(action)) {
			openModal(SURGERY_PROCEDURE_MODAL_MY_TEMPLATES);
			return;
		}

		if (isSurgeryProcedureModalOpen) {
			closeModal(SURGERY_PROCEDURE_MODAL_MY_TEMPLATES);
		}

	}, [action, closeModal, isSurgeryProcedureModalOpen, openModal, templateId]);

	const handleCreateNewClick = () => {
		setSelectedAction({ action: TemplateAction.NEW });
	};

	const onSubmit = React.useCallback((submittedProcedureInfo: SurgeryProcedureInterface) => {
		const forceReloadAndReset = ({ indexPosition, id }: Partial<TemplateInfoApi> = {}) => {
			const params: FilterApiParams = {};

			if (indexPosition && id) {
				params.page = Math.ceil((indexPosition + 1) / DEFAULT_SIZE).toString();
				setScrollToId(id);
			}

			resetFilters(params);
		};

		function templateSuccess(cb: (p?: TemplateInfoApi) => void, toastMsg: string) {
			return ({ data }: AxiosResponse<TemplateInfoApi> = { data: {} } as AxiosResponse<TemplateInfoApi>) => {
				displayToast(ToastType.SUCCESS, toastMsg);
				// Close template modal
				resetActionToNone();
				cb(data);
			};
		}

		function templateFailure(err: AxiosError<ErrorStatusState> | undefined) {
			setTemplateFormError(formatApiTemplateFormError(err));
		}

		switch (action) {
		case TemplateAction.EDIT: {
			const updatedTemplate = {
				...surgeryProcedureToTemplateApiConverter(submittedProcedureInfo),
				id: templateId,
			};
			dispatch(updateTemplate(updatedTemplate, templateSuccess(updateList, 'Template successfully edited'), templateFailure));
			break;
		}
		case TemplateAction.DUPLICATE: {
			const duplicatedTemplate = {
				...surgeryProcedureToTemplateApiConverter(submittedProcedureInfo),
			};
			dispatch(createDuplicateTemplate(duplicatedTemplate, templateSuccess(forceReloadAndReset, 'Template successfully duplicated'), templateFailure));
			break;
		}
		case TemplateAction.NEW: {
			dispatch(createNewTemplate(
				surgeryProcedureToTemplateApiConverter(submittedProcedureInfo),
				templateSuccess(forceReloadAndReset, 'Template successfully created'),
				templateFailure
			));
			break;
		}
		}

	}, [action, updateList, templateId, dispatch, resetFilters, displayToast]);

	const handleOnModalCloseEvent = React.useCallback(() => {
		// clear form state every time modal is closed
		setTemplateFormError(undefined);
		if (templateId && [TemplateAction.EDIT].includes(action)) {
			dispatch(unlockTemplate(templateId));
		}
		resetActionToNone();
	}, [action, dispatch, templateId]);

	const handleEditSelected = React.useCallback((id: string) => {
		setSelectedAction({
			action: TemplateAction.EDIT_PENDING,
			templateId: id,
		});
	}, [setSelectedAction]);

	const handleDeleteSelected  = React.useCallback( (templateIdString: string) => {
		setSelectedAction({
			action: TemplateAction.DELETE,
			templateId: templateIdString,
		});
	}, [setSelectedAction]);

	const handleDuplicateSelected = React.useCallback((id: string) => {
		setSelectedAction({
			action: TemplateAction.DUPLICATE_PENDING,
			templateId: id,
		});
	}, [setSelectedAction]);

	const handleModalCancel = React.useCallback(() => {
		if (templateId && action === TemplateAction.EDIT) {
			dispatch(unlockTemplate(templateId));
		}
		resetActionToNone();
		closeModal(SURGERY_PROCEDURE_MODAL_MY_TEMPLATES);
	}, [templateId, action, dispatch, closeModal]);

	const TemplateDisplay = React.useMemo(() => {
		function MyTemplateInfoDisplay(
			{ info }: Pick<TemplateTileProps, 'info' >,
			myTemplateInfoDisplayRef: React.Ref<HTMLDivElement>,
		) {
			return (
				<TemplateTile
					info={info}
					onEditSelected={handleEditSelected}
					onDeleteSelected={handleDeleteSelected}
					onDuplicateSelected={handleDuplicateSelected}
					ref={myTemplateInfoDisplayRef}
				/>);
		}
		return forwardRef(MyTemplateInfoDisplay);
	}, [handleDeleteSelected, handleEditSelected, handleDuplicateSelected]);

	const submitButtonText = action === TemplateAction.EDIT || action === TemplateAction.DUPLICATE ? 'Update' : 'Create';

	const headerText = actionToHeaderText[action] || 'Create a Template';

	return (
		<Flex flexDirection="row" justifyContent="center" alignItems="start">
			<ModalViewer
				className={classes.modalWrapper}
				id={SURGERY_PROCEDURE_MODAL_MY_TEMPLATES}
				onClose={handleOnModalCloseEvent}
			>
				<ModalContentWrapper>
					<Box width={800} minHeight={300}>
						<ProcedureFormProvider
							onSubmit={onSubmit}
							onCancel={handleModalCancel}
							submitButtonText={submitButtonText}
							headerText={headerText}
							shouldDisableSubmit={shouldDisableChecker}
						>
							<TemplateForm templateInitVals={initialData} templateFormError={templateFormError}/>
						</ProcedureFormProvider>
					</Box>
				</ModalContentWrapper>
			</ModalViewer>
			<Box
				className="card"
				width={1}
				ml="24px"
				borderRadius="4px"
			>
				<Flex flexDirection="column" px={40} py={40}>
					<Flex justifyContent="space-between" width={1} alignItems="start" >
						<Box>
							<Text fontSize={7} letterSpacing={1} fontWeight="bold">
								My Templates
							</Text>
						</Box>
						{hasTemplateModifyPermission &&
							(<Box>
								<TextLink mt={1} data-field="createNewTemplatePageLink" onClick={handleCreateNewClick}>
									CREATE TEMPLATE
								</TextLink>
							</Box>)
						}
					</Flex>
					<Flex justifyContent="space-between" letterSpacing={1} width={1} alignItems="start" pt={4} pb="32px">
						<Box>
							<Text fontSize={2}>
								The templates listed here will also appear when you are scheduling a new case.
							</Text>
						</Box>
					</Flex>
					<TemplateFilters surgeonFilter={AllSurgeonFilter} />
					{templateList.length === 0 ?
						<NoTemplatesMessage /> :
						<TemplateListSection templateList={templateList} templateDisplay={TemplateDisplay} refList={templateTileRefs}/>
					}
				</Flex>
			</Box>
			{ action === TemplateAction.DELETE && templateId &&
				<DeleteTemplateModal doneCallback={resetActionToNone} templateId={templateId}/>
			}
		</Flex>
	);
}

export default function MyTemplates() {
	const dispatch = useDispatch();

	const listLoadHandler = useCallback((filter: FilterApiParams) => {
		dispatch(getTemplateList(filter));
	}, [dispatch]);

	return (
		<FiltersProvider
			entityName={TEMPLATE_ENTITY_NAME}
			defaultSort={DEFAULT_TEMPLATES_SORT}
			totalCountSelector={TemplateListCountSelector}
			onListLoadRequest={listLoadHandler}
			disableDefaultFilters={true}
		>
			<ModalContextProvider>
				<MyTemplatesInner/>
			</ModalContextProvider>
		</FiltersProvider>
	);
}
