import React from 'react';
import { Box, BoxProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		padding: theme.spacing(5),
	},
}));

const MainPageLayoutSubsection: React.FC<BoxProps> = ({ children, ...boxProps  }) => {
	const classes = useStyles();

	return (
		<Box {...boxProps} className={classNames(classes.root, boxProps.className)} >
			{children}
		</Box>
	);
};

export default MainPageLayoutSubsection;
