import React from 'react';
import { Link, makeStyles, Menu, MenuItem } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import usePreferencesMenuConfig
	from '@components/HeaderSection/NavigationOptions/PreferencesMenu/utilities/usePreferencesMenuConfig';
import { useSelector } from 'react-redux';
import { UserProfileRoleSelector } from '@store/selectors/UserProfileSelector';
import { arraysOverlap } from '@utilities/commonFunction';
import {
	PreferencesMenuOptionConfig
} from '@components/HeaderSection/NavigationOptions/PreferencesMenu/utilities/UsePreferencesMenuConfigReturnType';

const useStyles = makeStyles((theme) => ({
	navOption: {
		paddingRight: theme.spacing(1),
		textTransform: 'uppercase',
		color: theme.palette.primary[100],
		fontWeight: 700,
		display: 'flex',
		alignItems: 'center',
	},
	menu: {
		'& .MuiMenu-paper > ul': {
			'padding': 0,
			'& .MuiSvgIcon-root': {
				height: '20px',
				marginRight: theme.spacing(0.5),
			},
		},
		'& li': {
			'padding': theme.spacing(1, 2),
			'&:not(li:last-child)': {
				borderBottom: `1px solid ${theme.palette.grey[300]}`,
			},
		},
	},
}));

const PreferencesMenuOption: React.FC<PreferencesMenuOptionConfig> = ({ showForRoles, shouldHide, id, onClick, label, icon: Icon, autoFocus }) => {
	const userRoles = useSelector(UserProfileRoleSelector);
	if (shouldHide) return null;
	if (showForRoles && !arraysOverlap(userRoles, showForRoles)) return null;
	return (
		<MenuItem onClick={onClick} data-field={id} autoFocus={autoFocus}>
			<Icon/>
			{label}
		</MenuItem>
	);
};
const PreferencesMenu: React.FC = () => {
	const classes = useStyles();
	const {
		options,
		anchorEl,
		setAnchorEl,
	} = usePreferencesMenuConfig();

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Link
				className={classes.navOption}
				variant="body1"
				onClick={handleClick}
				component="button"
				data-field="header-navOptions-preferencesMenu"
				aria-controls="preferences-menu"
				aria-haspopup="true"
			>
				Preferences {anchorEl ? <ArrowDropUp data-field="arrowUp"/> :  <ArrowDropDown data-field="arrowDown"/>}
			</Link>
			<Menu
				id="preferences-menu"
				open={!!anchorEl}
				anchorEl={anchorEl}
				onClose={handleClose}
				className={classes.menu}
				getContentAnchorEl={null}
				transformOrigin={{
					vertical: -8,
					horizontal: 'right',
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
			>
				{options.map(({ id, ...optionProps }, index) =>
					<PreferencesMenuOption
						key={id}
						id={id}
						autoFocus={index === 0}
						{...optionProps}
					/>
				)}
			</Menu>
		</>
	);
};

export default PreferencesMenu;
