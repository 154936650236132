import {
	OVERLAPPING_UNAVAILABLE_TIME, REQUIRED_DATE
} from '@utilities/Validation/ValidationMessages';
import doEventsOverlap from '@calendar/utilities/doEventsOverlap/doEventsOverlap';
import {
	CheckTimeRangeProps,
	decorateWithIndicator,
	getStartEndFromTimeSpan,
	handleCommonTimeSpanValidation,
	Indicator,
	PartialTimeSpan, TimeSpanValidationConfigs,
	validateStartEndTimeSpan
} from '@utilities/Validation/TimeSpanValidationUtilities/TimeSpanValidationUtilities';
import { defaultTimeSpanValidationConfigs } from '@utilities/constants';

export const checkEventTimeRange = ({
	timeSpan,
	overlapEvents,
	overlapMsg,
}: CheckTimeRangeProps): string | void => {
	const { startTime, endTime } = getStartEndFromTimeSpan(timeSpan) || {};
	const basicValidationResult = validateStartEndTimeSpan(timeSpan);
	if (basicValidationResult) {
		return basicValidationResult;
	}
	if (!startTime || !endTime) { return; }
	if (overlapEvents.some(event => doEventsOverlap(event, { start: startTime, end: endTime }))) {
		return overlapMsg;
	}
	return;
};

const TimeSpanValidationSchema = {
	validate: {
		checkAll: (currentValue: PartialTimeSpan = {}, validationConfig: TimeSpanValidationConfigs = defaultTimeSpanValidationConfigs) => {
			const { start, end, overlapEvents = [] } = currentValue;
			const errors = handleCommonTimeSpanValidation(currentValue, validationConfig);

			if (start && end) {
				const timeRangeMsg = checkEventTimeRange({
					timeSpan: currentValue,
					overlapEvents,
					overlapMsg: OVERLAPPING_UNAVAILABLE_TIME,
				});

				if (timeRangeMsg) {
					errors.push(decorateWithIndicator(Indicator.START, decorateWithIndicator(Indicator.END, timeRangeMsg)));
				}
			}

			return errors.join(';') || true;
		}
	}
};

export const EventTimeValidationSchema = {
	date: {
		required: REQUIRED_DATE,
		validate: {
			validTime: (d: Date) => !d || !isNaN(d.getTime()) || REQUIRED_DATE,
		},
	},
	timeSpan: TimeSpanValidationSchema,
};
