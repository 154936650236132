import React from 'react';
import { useFormContext } from 'react-hook-form';
import MyFiltersCheckbox from '../../MyFiltersCheckbox';

interface SurgeonCheckboxProps {
	id: string;
	name: string;
	onCheck: (i: string) => unknown;
	allSelected: boolean;
}

export const SurgeonCheckbox: React.FC<SurgeonCheckboxProps> = ({ id, name, onCheck, allSelected }) => {
	const { watch } = useFormContext<{ surgeon: string[] }>();

	const selectedSurgeons = watch('surgeon') || [];

	const isChecked = allSelected || selectedSurgeons.includes(id);

	return React.useMemo(() => <MyFiltersCheckbox
		id={id}
		name={name}
		onCheck={onCheck}
		checked={isChecked}
	/>, [id, name, onCheck, isChecked]);
};
