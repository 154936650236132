import React from 'react';
import { useCalendarContext } from '@calendar';
import FocusType from '@calendar/types/FocusType';
import { useScrollWithinBox } from '@components/ScrollWithinBox';

type InnerProps = {
	minutesSinceMidnight: number;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const TimeAxisLabel: React.FC<InnerProps> = ({ minutesSinceMidnight, children,  ...divProps }) => {
	const { focusOn: { id, type } } = useCalendarContext();
	const { setScrollInfo } = useScrollWithinBox();

	const ref = React.useRef<HTMLDivElement>(null);
	React.useLayoutEffect(()=>{
		if (
			!id ||
			type !== FocusType.TIME ||
			!ref.current ||
			id !== minutesSinceMidnight.toString()
		) { return; }

		setScrollInfo({
			scrollTo: ref,
		});
	}, [ref, setScrollInfo, minutesSinceMidnight, id, type]);

	return (
		<div
			ref={ref}
			{...divProps}
		>
			{children}
		</div>
	);
};

