import { CaseDetailsLockStatusSelector } from '@store/selectors/CaseDetailsSelector';
import { useSelector } from 'react-redux';
import useHasPermission from '@utilities/hooks/useHasPermission/useHasPermission';
import { PermittedAction } from '@utilities/PermittedAction';

const useCaseEditLockName = () => {
	const hasEditPermission = useHasPermission(PermittedAction.CREATE_CASE);
	const { locked, firstName, lastName } = useSelector(CaseDetailsLockStatusSelector);

	return locked && hasEditPermission && firstName && lastName ?
		`${firstName} ${lastName}`
		: undefined;
};

export default useCaseEditLockName;
