import React from 'react';
import { Box, Typography, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BlockColumnHeader from '@components/SchedulePage/BlockColumnHeader/BlockColumnHeader';
import BlockColumnProps from '@components/SchedulePage/BlockColumn/BlockColumnProps';
import BoxFancyScroll from '@components/BoxFancyScroll/BoxFancyScroll';
import NonBlockColumnHeader from '@components/SchedulePage/NonBlockColumnHeader/NonBlockColumnHeader';
import BlockEvent from '@components/SchedulePage/BlockEvent/BlockEvent';
import { NonCredentialedMessage } from '@components/SchedulePage/NonCredentialedMessage/NonCredentialedMessage';
import { SectionedBoxWrapper } from '@components/SectionedBox/SectionedBoxWrapper';
import { SectionedBoxHeader } from '@components/SectionedBox/SectionedBoxHeader/SectionedBoxHeader';
import { SectionedBoxBody } from '@components/SectionedBox/SectionedBoxBody/SectionedBoxBody';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => createStyles({
	eventContainer: {
		overflowY: 'auto',
		paddingTop: theme.spacing(2),
		margin: theme.spacing(0, 1),
		flex: 1,
	},
	surgeonHeaderContainer: {
		backgroundColor: theme.palette.grey[900],
		borderRadius: '4px 4px 0 0',
		overflow: 'hidden',
	},
	surgeonHeader: {
		display: 'flex',
		flexDirection: 'row',
		padding: theme.spacing(1),
		position: 'relative',
	},
	surgeonName: {
		fontWeight: 600,
		color: theme.palette.common.white,
	},
	customBody: {
		padding: 0,
		borderBottom: 0,
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',
	},
}));

const DailySurgeonCalendarFilled: React.FC<BlockColumnProps> = ({ blockInfo }) => {
	const classes = useStyles();
	const formattedDate = format(new Date(blockInfo.selectedDate), 'EEEE, MMMM dd, yyyy');

	return (
		<SectionedBoxWrapper hasFooter={false}>
			<SectionedBoxHeader title={formattedDate} lowHeaderPadding={true} />
			<SectionedBoxBody className={classes.customBody}>
				<Box className={classes.surgeonHeaderContainer}>
					<Box className={classes.surgeonHeader}>
						<Typography variant="body1" component="h3" className={classes.surgeonName}>
							{blockInfo.displayName}
						</Typography>
					</Box>

					{blockInfo.credentialed && (
						<>
							{blockInfo.scheduleHasBlock
								? <BlockColumnHeader blockInfo={blockInfo} />
								: <NonBlockColumnHeader blockInfo={blockInfo} />}
						</>
					)}
				</Box>

				{blockInfo.credentialed ? (
					<BoxFancyScroll className={classes.eventContainer}>
						{blockInfo.events.map((e) => (
							<BlockEvent key={e.id} eventInfo={e} displayMenu={false} />
						))}
					</BoxFancyScroll>
				) : (
					<NonCredentialedMessage />
				)}
			</SectionedBoxBody>
		</SectionedBoxWrapper>
	);
};

export default DailySurgeonCalendarFilled;

