import React from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import DocumentActionButton
	from '@components/CaseDetails/DocumentInformationDisplay/DocumentDetails/DocumentActions/DocumentActionButton';
import {
	useDocumentDetailsContext
} from '@components/CaseDetails/DocumentInformationDisplay/DocumentDetails/DocumentDetails';
import { makeStyles } from '@material-ui/core/styles';
import useGetDocument from '@data/document/hooks/useGetDocument/useGetDocument';
import { UserProfileDocViewPermissionSelector } from '@store/selectors/UserProfileSelector';
import { useSelector } from 'react-redux';
import DownloadDocumentProps
	from '@components/CaseDetails/DocumentInformationDisplay/DocumentDetails/DocumentActions/DownloadDocumentProps';

const useStyles = makeStyles({
	displayNone: {
		display: 'none',
	},
});

const DownloadDocument: React.FC<DownloadDocumentProps> = ({ fieldName = 'action-download' }) => {
	const classes = useStyles();
	const { id, file: fileName } = useDocumentDetailsContext();
	const anchorRef = React.useRef<HTMLAnchorElement | null>(null);

	const hasDownloadPermission = useSelector(UserProfileDocViewPermissionSelector);

	const getDoc = useGetDocument();

	const handleClick = React.useCallback(() => {
		const onSuccess = (doc: Blob) => {
			if (!anchorRef.current) { return; }

			const url = window.URL.createObjectURL(doc);
			anchorRef.current.href = url;
			anchorRef.current.click();

			window.URL.revokeObjectURL(url);
		};
		getDoc(id, onSuccess);
	}, [getDoc, id]);

	if (!hasDownloadPermission) { return null; }

	return <>
		<a ref={anchorRef} download={fileName} className={classes.displayNone} />
		<DocumentActionButton fieldName={fieldName} onClick={handleClick}><GetAppIcon />Download</DocumentActionButton>
	</>;
};

export default DownloadDocument;
