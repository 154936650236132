import React from 'react';
import { Button } from '@ascension/ui-library';
import { track } from '@amplitude/analytics-browser';
import { BaseButtonProps } from './BaseButton.types';

export const BaseButton: React.FC<BaseButtonProps> = ({
	dataField,
	onClick,
	children,
	...rest
}) => {
	const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		track('button-click', { fieldName: dataField, eventType: e.type });
		if (onClick) {
			onClick(e);
		}
	};

	return (
		<Button data-field={dataField} translate="yes" onClick={handleClick} {...rest}>
			{children}
		</Button>
	);
};
