import React from 'react';
import {
	useCanForceConfirm,
	useConfirmationCaseNumber, useForceConfirm
} from '../../CaseDetailsContext/ConfirmCaseContext/ConfirmCaseUtilities';
import { Button } from '@ascension/ui-library';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	button: {
		'margin': theme.spacing(2, 2, 0, 0),
		'backgroundColor': theme.palette.gold.light,
		'&:hover': {
			backgroundColor: theme.palette.gold.dark,
		},
	},
}));

interface ForceConfirmButtonProps {
	children: string;
	optionalFin?: string;
}

export const ForceConfirmButton = ({ children, optionalFin }: ForceConfirmButtonProps) => {
	const classes = useStyles();

	const caseNumber = useConfirmationCaseNumber() || '';
	const canForceConfirm = useCanForceConfirm();
	const forceConfirm = useForceConfirm();

	const handleConfirm = React.useCallback(() => {
		void forceConfirm(caseNumber, optionalFin);
	}, [forceConfirm, caseNumber, optionalFin]);

	return (
		<Button
			translate="yes"
			className={classes.button}
			variant="contained"
			disabled={!canForceConfirm}
			onClick={handleConfirm}
			data-field="forceConfirmBtn"
		>
			{children}
		</Button>
	);
};
