import React from 'react';
import { ManageUsersListHeaderProps } from './ManageUsersListHeaderProps';
import { TableHead, TableRow, TableCell, TableSortLabel, makeStyles } from '@material-ui/core';
import { SortOrderType } from '../SortOrderType';
import { HeadCell } from '../HeadCell';

const useStyles = makeStyles({
	sortableHeader: {
		cursor: 'pointer',
	},
	headerCell: {
		whiteSpace: 'nowrap',
	},
	sortLabel: {
		'&.MuiTableSortLabel-root:hover': {
			color: 'inherit',
		},
	},
});


const headCells: readonly HeadCell[] = [
	{
		id: 'dateCreatedNum',
		isSortable: true,
		label: 'Date added',
	},
	{
		id: 'userRolesStr',
		isSortable: true,
		label: 'Role',
	},
	{
		id: 'lastName',
		isSortable: true,
		label: 'Last name',
	},
	{
		id: 'firstName',
		isSortable: true,
		label: 'First name',
	},
	{
		id: 'status',
		isSortable: true,
		label: 'Status',
	},
	{
		isSortable: false,
		label: 'Actions',
	},
];

export const ManageUsersListHeader = ({ order, orderBy, handleRequestSort }: ManageUsersListHeaderProps) => {
	const { sortableHeader, headerCell, sortLabel } = useStyles();

	return (<TableHead>
		<TableRow>
			{headCells.map((headCell) => (
				<TableCell
					key={headCell.label}
					className={headerCell}
					sortDirection={orderBy === headCell.id ? order : false}
				>
					<TableSortLabel
						className={[sortLabel, headCell.isSortable ? sortableHeader : ''].join(' ')}
						active={orderBy === headCell.id}
						direction={orderBy === headCell.id ? order : SortOrderType.DESC}
						disabled={!headCell.isSortable}
						onClick={() => headCell.id && handleRequestSort(headCell.id)}
					>
						{headCell.label}
					</TableSortLabel>
				</TableCell>
			))}
		</TableRow>
	</TableHead>
	);
};
