import { Reducer } from 'redux';
import { CaseQueueState } from '../../interfaces/CaseQueue/CaseQueueState';
import { CaseQueueAction } from '../../interfaces/CaseQueue/CaseQueueAction';
import {
	CLEAR_CASE_QUEUE,
	NAVIGATE_CASE_QUEUE,
	NAVIGATE_NEXT_CASE_QUEUE,
	SET_CASE_QUEUE,
	QUEUE_STATUS_CLEAR,
	SAVED_CONFIRM_NUMBER_ERROR,
} from '../actions/types';
import { calculateDerivedQueueProperties } from '../../utilities/caseQueue/calculateDerivedQueueProperties';
import { RequestFormStatus } from '../../interfaces/RequestForm/RequestFormStatus';

const initialState: CaseQueueState = {
	queueList: [],
	totalCount: 0,
	queueStatus: RequestFormStatus.PRISTINE
};

export const CaseQueueReducer: Reducer<CaseQueueState, CaseQueueAction> = (state: CaseQueueState = initialState, action: CaseQueueAction) => {
	switch (action.type) {
	case CLEAR_CASE_QUEUE:
		return initialState;
	case SET_CASE_QUEUE: {
		let newState = { ...initialState };
		const position = action.payload.positionIndex || 0;
		newState = {
			...state,
			queueList: action.payload.queueList,
			totalCount: action.payload.queueList.length,
			currentReviewAtCount: action.payload.queueList[position] ? position + 1 : undefined,
			...calculateDerivedQueueProperties(action.payload.queueList, position),
		};
		return newState;
	}
	case NAVIGATE_CASE_QUEUE: {
		const position = action.payload.positionIndex;
		return {
			...state,
			currentReviewAtCount: state.queueList[position] ? position + 1 : undefined,
			...calculateDerivedQueueProperties(state.queueList, position),
		};
	}
	case NAVIGATE_NEXT_CASE_QUEUE: {
		const position = state.nextCaseIndex;
		return {
			...state,
			currentReviewAtCount: position && state.queueList[position] ? position + 1 : undefined,
			...calculateDerivedQueueProperties(state.queueList, position),
			queueStatus: RequestFormStatus.SUBMITTED
		};
	}
	case SAVED_CONFIRM_NUMBER_ERROR: {
		return {
			...state,
			queueStatus: RequestFormStatus.ERROR
		};
	}
	case QUEUE_STATUS_CLEAR: {
		return {
			...state,
			queueStatus: RequestFormStatus.PRISTINE
		};
	} }
	return state;
};
