import { apiAction } from '@store/middlewares/api/ApiActionCreators';
import { ENDPOINT_FIND_A_TIME } from '@utilities/apiConstants';
import ApiService from '@utilities/apiEndpoints/ApiService';
import { POST_FIND_A_TIME_SEARCH } from '@store/actions/types';
import { FindATimeRequest } from '@data/findATime/types/FindATimeRequest';

/***
 * getFindATimeResponse - api action creator to submit a FindATime Search
 *
 * @param apiActionRequest {Partial<FindATimeRequest>} data with a FindATimeRequest
 *
 * @returns {ApiAction} api action to get FindATimeResponse data (search results)
 */
export const getFindATimeResponse = (apiActionRequest?: Partial<FindATimeRequest>) => {
	return apiAction({
		url: ENDPOINT_FIND_A_TIME,
		method: 'POST',
		apiService: ApiService.BTS,
		includeAccessToken: true,
		label: POST_FIND_A_TIME_SEARCH,
		...apiActionRequest,
	});
};
