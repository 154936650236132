import { makeStyles } from '@material-ui/core/styles';

export const useCommonStyles = makeStyles((theme) => ({
	upperCase: { textTransform: 'uppercase' },
	hideTextOverflow: { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
	allowTextBreak: { wordBreak: 'break-word' },
	checkBoxStyle: {
		padding: '0px',
		marginRight: '8px'
	},
	capitalize: { textTransform: 'capitalize' },
	inputBorderStyle: {
		border: `1px solid #777777`,
		borderRadius: '4px'
	},
	rowGap: {
		marginBottom: theme.spacing(3.5),
	}
}));
