import { CaseQueueInfo } from '../../interfaces/CaseQueue/CaseQueueInfo';
import { DerivedQueueProperties } from '../../interfaces/CaseQueue/DerivedQueueProperties';

const defaultProperties: DerivedQueueProperties = {
	currentCaseId: undefined,
	nextCaseDetails: undefined,
	previousCaseDetails: undefined,
};

export const calculateDerivedQueueProperties = (caseQueue: CaseQueueInfo[], currentIndex?: number): DerivedQueueProperties => {
	if (currentIndex === undefined || caseQueue.length === 0) {
		return { ...defaultProperties };
	}
	if (currentIndex >= caseQueue.length) {
		currentIndex = caseQueue.length - 1;
	}

	const previousCaseIndex = currentIndex - 1;
	const nextCaseIndex = currentIndex + 1;
	const lastCaseIndex = caseQueue.length - 1;

	return {
		currentCaseId: caseQueue[currentIndex].caseId,
		previousCaseDetails: caseQueue[previousCaseIndex],
		nextCaseDetails: caseQueue[nextCaseIndex],
		previousCaseIndex: caseQueue[previousCaseIndex] ? previousCaseIndex : undefined,
		nextCaseIndex: caseQueue[nextCaseIndex] ? nextCaseIndex : undefined,
		lastCaseIndex,
	};
};
