import React, { ReactElement } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	content: {
		'display': 'flex',
		'flexWrap': 'nowrap',
		'& .MuiSvgIcon-root': {
			height: '16px',
			width: '16px',
			margin: theme.spacing(0.25, 0.5, 0, 0),
		},
	},
	textContainer: {
		display: 'flex',
		flexDirection: 'column',
		whiteSpace: 'nowrap',
	},
	title: {
		fontWeight: 600,
	},
}));

interface EventCardContentWithIconProps {
	title?: string;
	timeSpan?: string;
	icon?: ReactElement;
}

const EventCardContentWithIcon: React.FC<EventCardContentWithIconProps> = ({ title, timeSpan, icon }) => {
	const classes = useStyles();

	return (
		<Box className={classes.content}>
			{icon}
			<Box className={classes.textContainer}>
				<Typography variant="body2" component="h4" className={classes.title}>{title}</Typography>
				<Typography variant="caption" component="p">{timeSpan}</Typography>
			</Box>
		</Box>
	);
};

export default EventCardContentWithIcon;
