import {
	GET_USER_PROFILE,
	SAVED_USER_PROFILE,
	UPDATE_USER_PROFILE,
	USER_PROFILE_ERROR,
	SAVED_USER_PROFILE_NOTIFICATION,
	UPDATE_USER_PROFILE_NOTIFICATION,
	UPDATE_USER_PROFILE_TERMS_OF_USE,
	SAVED_USER_PROFILE_TERMS_OF_USE,
	UPDATE_USER_SAVED_FILTER,
	UPDATE_USER_PROFILE_STATUS,
} from '@store/actions/types';
import { Dispatch } from 'redux';
import errorToString from '@utilities/errorToString';
import {
	ApiActionRequest,
	CaseTrackerThunkAction,
	CaseTrackerThunkDispatch
} from '@interfaces/ApiAction/ApiAction';
import { apiAction } from '@store/middlewares/api/ApiActionCreators';
import { UserProfileActionTypes } from '@interfaces/UserProfile/UserProfileStoreInterface';
import {
	UserDetailsApiInterface,
	UserDetailsHospitalUpdate,
	UserDetailsNotificationUpdateApiInterface,
	UserDetailsOffice,
	UserFormStateInterface,
	UserDetailsTermsOfUseAcceptedApiInterface,
	UserDetailsFilterUrlPreferenceUpdateOffice,
	UserDetailsFilterUrlPreferenceUpdateHospital, UserFormPractice, UserHospitalUpdate,
	UserDetailsStatusApiInterface,
} from '@interfaces/UserPrefInterface';
import { ApiResponseType } from '@interfaces/ApiAction/ApiActionPayload';
import {
	ENDPOINT_USER_ACTIVATE,
	ENDPOINT_USER_PROFILE,
	ENDPOINT_USER_PROFILE_DETAILS,
	ENDPOINT_USER_PROFILE_NOTIFICATION,
	ENDPOINT_USER_PROFILE_TERMS_OF_USE,
} from '@utilities/apiConstants';
import { getAndUpdateAllSurgeonListData } from '@store/actions/SurgeonListActionCreators';
import { getAllUnitListData } from './UnitListActionCreators';
import { AxiosResponse } from 'axios';
import { MyFiltersState } from '@interfaces/MyFilters/MyFiltersState';
import { composeFilterUrl } from '@utilities/filtersUtilities';
import { UserStatus } from '@interfaces/UserProfile/UserStatusEnum';
import { getDocPermissionApiAction } from '@data/docPermission/actionCreators/DocPermissionActionCreators';
import NoParamNoReturnCallback from '@interfaces/NoParamNoReturnCallback';
import { ErrorStatusState } from '@interfaces/ErrorStatus/ErrorStatusState';

function updateUserDetails({ data }: ApiResponseType<UserDetailsApiInterface>): UserProfileActionTypes {
	return {
		type: UPDATE_USER_PROFILE,
		payload: data
	};
}

export function updateUserSavedFilters(filterValues: Partial<MyFiltersState>): UserProfileActionTypes {
	return {
		type: UPDATE_USER_SAVED_FILTER,
		payload: filterValues
	};
}

export function userDetailsError(error: unknown): UserProfileActionTypes {
	return {
		type: USER_PROFILE_ERROR,
		error: errorToString(error)
	};
}

export function updateUserProfileAndUnits(response: ApiResponseType<UserDetailsApiInterface>) {
	return (dispatch: Dispatch) => {
		dispatch(updateUserDetails(response));
		dispatch(getAllUnitListData());
	};
}

export function updateUserDetailsAndDocPermission(response: ApiResponseType<UserDetailsApiInterface>) {
	return (dispatch: Dispatch) => {
		dispatch(updateUserDetails(response));
		dispatch(getDocPermissionApiAction());
	};
}

export function getUserProfileDetails (successAction?: (r: AxiosResponse<UserDetailsApiInterface>) => void): ApiActionRequest<UserDetailsApiInterface> {
	return apiAction<UserDetailsApiInterface>({
		url: ENDPOINT_USER_PROFILE_DETAILS,
		method: 'GET',
		label: GET_USER_PROFILE,
		includeAccessToken: true,
		onSuccess: successAction || updateUserDetails,
		onFailure: userDetailsError,
	});
}

export function fetchUserProfileDetailsAndSurgeons(): CaseTrackerThunkAction {
	return (dispatch: CaseTrackerThunkDispatch): void => {
		dispatch(getUserProfileDetails());
		dispatch(getAndUpdateAllSurgeonListData());
	};
}

export function updateUserProfile<T extends UserDetailsApiInterface | UserFormStateInterface, R = T>(payload: Partial<T>, successAction: (r: AxiosResponse<R>) => void, onFailureCallback?: NoParamNoReturnCallback): ApiActionRequest<Partial<T>, ErrorStatusState, R> {
	const onFailure = (error: unknown): CaseTrackerThunkAction => {
		return (dispatch: CaseTrackerThunkDispatch): void => {
			dispatch(userDetailsError(error));
			if (onFailureCallback) {
				onFailureCallback();
			}
		};
	};

	return apiAction<Partial<T>, ErrorStatusState, R>({
		url: ENDPOINT_USER_PROFILE,
		method: 'PUT',
		label: SAVED_USER_PROFILE,
		data: payload,
		includeAccessToken: true,
		onSuccess: successAction,
		onFailure: onFailure,
	});
}
/***
 * Action creator that updates array of hospitals in user profile for current Hospital User
 * @param {MyFiltersState} formState - MyFilters form state
 * @param {UserHospitalUpdate[]} hospitals - list of hospitals from selected surgeons
 * @param {Function} successCallback - callback function that will execute after successful API call to update user profile
 * @param failureCallback - callback function that will execute after failed API call to update user profile
 * @returns API action that will be dispatched to update user profile with hospitals
 */
export function updateHospitalsInHospitalUserProfile(formState: MyFiltersState, hospitals: UserHospitalUpdate[], successCallback: NoParamNoReturnCallback, failureCallback: NoParamNoReturnCallback): ApiActionRequest<Partial<UserDetailsApiInterface | UserFormStateInterface>, ErrorStatusState, UserDetailsHospitalUpdate> {

	const payload: UserDetailsFilterUrlPreferenceUpdateHospital = {
		filterUrl: composeFilterUrl(formState),
		preference: {
			hospitals: hospitals
		}
	};

	const onSuccessAction = (): CaseTrackerThunkAction => {
		return (dispatch: CaseTrackerThunkDispatch): void => {
			dispatch(getUserProfileDetails(updateUserProfileAndUnits));
			successCallback();
		};
	};
	return updateUserProfile(payload, onSuccessAction, failureCallback);
}

/***
 * Action creator that get DocViewPermission after profile loaded
 * @returns API action that will be dispatched to update user profile and doc view permission
 */
export function getUserProfileAndDocPermission(): ApiActionRequest<UserDetailsApiInterface> {
	return getUserProfileDetails(updateUserDetailsAndDocPermission);
}

/***
 * Action creator that updates array of practices in user profile for current Office User
 * @param {MyFiltersState} formState - MyFilters form state
 * @param {UserFormPractice[]} practices - list of practices from selected surgeons
 * @param {Function} successCallback - callback function that will execute after successful API call to update user profile
 * @param failureCallback - callback function that will execute after failed API call to update user profile
 * @returns API action that will be dispatched to update user profile with practices
 */
export function updatePracticesInOfficeUserProfile(formState: MyFiltersState, practices: UserFormPractice[], successCallback: NoParamNoReturnCallback, failureCallback: NoParamNoReturnCallback): ApiActionRequest<Partial<UserDetailsApiInterface | UserFormStateInterface>, ErrorStatusState, UserDetailsOffice> {

	const payload: UserDetailsFilterUrlPreferenceUpdateOffice = {
		filterUrl: composeFilterUrl(formState),
		preference: {
			practices: practices
		}
	};

	const onSuccessAction = (): CaseTrackerThunkAction => {
		return (dispatch: CaseTrackerThunkDispatch) => {
			dispatch(fetchUserProfileDetailsAndSurgeons());
			successCallback();
		};
	};
	return updateUserProfile(payload, onSuccessAction, failureCallback);
}

/***
 * Action creator to update user profile with new value for emailNotification
 * @param {boolean | undefined} emailNotificationNewValue - new value for emailNotification to be set in store
 * @returns {UserProfileActionTypes} action to be dispatched in order to update the user profile with new value for emailNotification
 */
export function updateUserProfileNotificationInStore(emailNotificationNewValue: boolean | undefined): UserProfileActionTypes {
	return {
		type: UPDATE_USER_PROFILE_NOTIFICATION,
		payload: emailNotificationNewValue
	};
}

/***
 * Action creator to call the API on route /user/profile/notification to update the value of emailNotification in the database
 * @param {UserDetailsNotificationUpdateApiInterface} payload - object that is accepted by API in the payload on this route
 * @param {Function} onSuccessCallback - function that will be executed if successful call to API occurs (along with updating store with user notifications options selected)
 * @param {Function} onFailureCallback - function that will be executed if failure occurs after calling API
 * @returns {apiAction<UserDetailsApiInterface>} action to be dispatched by API in order to update emailNotification of current user
 */
export function updateUserProfileNotificationApi (payload: UserDetailsNotificationUpdateApiInterface, onSuccessCallback?: NoParamNoReturnCallback, onFailureCallback?: NoParamNoReturnCallback): ApiActionRequest<UserDetailsNotificationUpdateApiInterface, ErrorStatusState, UserDetailsApiInterface> {
	const onSuccess = (response: AxiosResponse<UserDetailsApiInterface>): CaseTrackerThunkAction => {
		return (dispatch: CaseTrackerThunkDispatch): void => {
			// update only emailNotification to avoid additional calls to API in order to improve performance
			const newNotificationValue = response.data.preference ? response.data.preference.emailNotification : undefined;
			dispatch(updateUserProfileNotificationInStore(newNotificationValue));
			onSuccessCallback && onSuccessCallback();
		};
	};

	const onFailure = (error: unknown): CaseTrackerThunkAction => {
		return (dispatch: CaseTrackerThunkDispatch): void => {
			dispatch(userDetailsError(error));
			onFailureCallback && onFailureCallback();
		};
	};

	return apiAction<UserDetailsNotificationUpdateApiInterface, ErrorStatusState, UserDetailsApiInterface>({
		url: ENDPOINT_USER_PROFILE_NOTIFICATION,
		method: 'PUT',
		label: SAVED_USER_PROFILE_NOTIFICATION,
		data: payload,
		includeAccessToken: true,
		onSuccess: onSuccess,
		onFailure: onFailure,
	});
}

export function activateUserProfile (onSuccessCallback: NoParamNoReturnCallback, onFailureCallback: NoParamNoReturnCallback): ApiActionRequest<UserDetailsApiInterface> {
	const onSuccess = (response: ApiResponseType<UserDetailsApiInterface>): CaseTrackerThunkAction => {
		return (dispatch: CaseTrackerThunkDispatch): void => {
			dispatch(updateUserDetails(response));
			onSuccessCallback();
		};
	};

	const onFailure = (error: unknown): CaseTrackerThunkAction => {
		return (dispatch: CaseTrackerThunkDispatch): void => {
			dispatch(userDetailsError(error));
			onFailureCallback();
		};
	};

	return apiAction<UserDetailsApiInterface>({
		url: ENDPOINT_USER_ACTIVATE,
		method: 'PUT',
		label: SAVED_USER_PROFILE,
		includeAccessToken: true,
		onSuccess: onSuccess,
		onFailure: onFailure,
	});
}

/***
 * Action creator to update user profile with new value for termsOfUseAccepted
 * @param {boolean | undefined} termsOfUseAcceptedNewValue - new value for termsOfUseAccepted to be set in store
 * @returns {UserProfileActionTypes} action to be dispatched in order to update the user profile with new value for termsOfUseAccepted
 */
export function updateUserProfileTermsOfUseAcceptedInStore(termsOfUseAcceptedNewValue: boolean | undefined): UserProfileActionTypes {
	return {
		type: UPDATE_USER_PROFILE_TERMS_OF_USE,
		payload: termsOfUseAcceptedNewValue
	};
}

/***
 * Action creator to call the API on route /user/profile/termsAccepted/true to update the value of termsOfUseAccepted in the database
 * @param {UserDetailsTermsOfUseAcceptedApiInterface} payload - object that is accepted by API in the payload on this route
 * @param {Function} onSuccessCallback - function that will be executed if successful call to API occurs (along with updating store with user notifications options selected)
 * @param {Function} onFailureCallback - function that will be executed if failure occurs after calling API
 * @returns {apiAction<UserDetailsApiInterface>} action to be dispatched by API in order to update termsOfUseAccepted of current user
 */
export function updateUserProfileTermsOfUseAcceptedApi (payload: UserDetailsTermsOfUseAcceptedApiInterface, onSuccessCallback?: NoParamNoReturnCallback, onFailureCallback?: NoParamNoReturnCallback): ApiActionRequest<UserDetailsTermsOfUseAcceptedApiInterface, ErrorStatusState, UserDetailsApiInterface> {
	const onSuccess = (response: AxiosResponse<UserDetailsApiInterface>): CaseTrackerThunkAction => {
		return (dispatch: CaseTrackerThunkDispatch): void => {
			// update only termsOfUseAccepted to avoid additional calls to API in order to improve performance
			const newValue = response.data.termsOfUseAccepted ? response.data.termsOfUseAccepted : false;
			dispatch(updateUserProfileTermsOfUseAcceptedInStore(newValue));
			if (onSuccessCallback) {
				onSuccessCallback();
			}
		};
	};

	const onFailure = (error: unknown): CaseTrackerThunkAction => {
		return (dispatch: CaseTrackerThunkDispatch): void => {
			dispatch(userDetailsError(error));
			if (onFailureCallback) {
				onFailureCallback();
			}
		};
	};

	return apiAction<UserDetailsTermsOfUseAcceptedApiInterface, ErrorStatusState, UserDetailsApiInterface>({
		url: ENDPOINT_USER_PROFILE_TERMS_OF_USE,
		method: 'PUT',
		label: SAVED_USER_PROFILE_TERMS_OF_USE,
		data: payload,
		includeAccessToken: true,
		onSuccess: onSuccess,
		onFailure: onFailure,
	});
}

/***
 * updateUserProfileStatus - Action creator that updates status of user profile to activate or lock
 * @param payload {UserDetailsStatusApiInterface}
 * @param successCallback {Function} - callback function that will execute after successful API call to update user profile
 * @param failureCallback {Function} - optional callback function that will execute after failed API call to update user profile
 * @returns API action that will be dispatched to update user profile status
 */
export function updateUserProfileStatus(payload: UserDetailsStatusApiInterface, successCallback: NoParamNoReturnCallback, failureCallback?: NoParamNoReturnCallback): ApiActionRequest<Partial<UserDetailsApiInterface | UserFormStateInterface>, ErrorStatusState, UserDetailsApiInterface> {
	const onSuccessAction = (response: AxiosResponse<UserDetailsApiInterface>): CaseTrackerThunkAction => {
		return (dispatch: CaseTrackerThunkDispatch) => {
			dispatch(updateUserDetails(response));
			successCallback();
		};
	};

	return updateUserProfile(payload, onSuccessAction, failureCallback);
}

/***
 * updateLocalUserProfileStatus - Action creator that updates local status (store) of user profile to ACTIVE | PENDING_ACTIVE | LOCK
 * @param payload {UserStatus}
 * @returns action that will be dispatched to update local (store) user profile status
 */
export function updateLocalUserProfileStatus(payload: UserStatus) {
	return {
		type: UPDATE_USER_PROFILE_STATUS,
		payload: payload
	};
}

/***
 * updateUserProfileEmail - Action creator that updates user profile email
 * @param payload {email: string}
 * @param successCallback {Function} - callback function that will execute after successful API call to update user profile
 * @param failureCallback {Function} - optional callback function that will execute after failed API call to update user profile
 * @returns API action that will be dispatched to update user profile status
 */
export function updateUserProfileEmail(payload: { email?: string }, successCallback: NoParamNoReturnCallback, failureCallback?: NoParamNoReturnCallback): ApiActionRequest<Partial<UserDetailsApiInterface | UserFormStateInterface>, ErrorStatusState, UserDetailsApiInterface> {
	const onSuccessAction = (response: AxiosResponse<UserDetailsApiInterface>): CaseTrackerThunkAction => {
		return (dispatch: CaseTrackerThunkDispatch) => {
			dispatch(updateUserDetails(response));
			successCallback();
		};
	};

	return updateUserProfile(payload, onSuccessAction, failureCallback);
}
