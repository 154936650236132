import { useFormContext } from 'react-hook-form';
import { FieldError, FieldValues } from 'react-hook-form';

type ErrorType<FormType> = Partial<Record<keyof FormType, FieldError>>;

function firstError<FormType>(errors: ErrorType<FormType>) {
	return (errorRes: FieldError | undefined, fieldName: keyof FormType): FieldError | undefined=> {
		return errorRes || errors[fieldName] || undefined;
	};
}

/***
 * @example
 * const unitError = useFieldError<FormState>('unit');
 * return unitError ? <ErrorDisplay>{unitError}</ErrorDisplay> : null;
 *
 * @param  {keyof FormType} field - Property in FormType
 *
 * @returns {string | boolean} error message when error present (true or string from validation schema), otherwise false.
 */
function useFieldError<FormType extends FieldValues>(field: keyof FormType | Array<keyof FormType>): string | boolean {
	// Soft assert error type to allow FormType to be used as an index

	const { formState: { errors } } = useFormContext<FormType>() as { formState: { errors: ErrorType<FormType> } };

	const error: FieldError | undefined = Array.isArray(field) ?
		field.reduce(firstError(errors), undefined) :
		errors[field];

	// Return message if available, otherwise return false
	return error ? 'message' in error && error.message && error.message  || true : false;
}

export default useFieldError;
