import React from 'react';

/***
 * useClickAway - used for monitoring when there is a click outside the given ref, then calling passed onClickAway method.
 *
 * When ref is not attached, onClickAway and the listener will not run.
 *
 * @param onClickAway {(e: MouseEvent) => unknown} - called when click is outside of ref
 * @param disableAutoListen {boolean: false} - do not automatically mount the click away listener - should then be mounted manually with returned methods
 *
 * @returns {
 *     ref: RefObject<HTMLDivElement>,
 *     mountEventListener: () => void,
 *     dismountEventListener: () => void,
 * }
 *      - ref: should be attached to div element that wraps component that should be monitored for click away
 *      - mountEventListener() can optionally be called to manually mount when disableAutoListen is enabled
 *      - dismountEventListener() should be used to unmount listener if needed
 *
 * @example
 * const CustomPopover = (onClose, open) => {
 *     const { ref } = useClickAway(onClose);
 *
 *     return open ? <div ref={ref}>I'm a popover</div> : null;
 * }
 *
 * @example
 * const ComplexPopover = (onClose, open) => {
 *     const { ref, mountEventListener, dismountEventListener } = useClickAway(onClose);
 *
 *     React.useEffect(() => {
 *         if (open) { mountEventListener(); }
 *         return dismountEventListener;
 *     }, [mountEventListener, dismountEventListener, open]);
 *
 *     return open ? <div ref={ref}>I'm a popover</div> : null;
 * }
 */
const useClickAway = (onClickAway: (e: MouseEvent) => unknown, disableAutoListen = false) => {
	const ref = React.useRef<HTMLDivElement>(null);

	const handleEvent = React.useCallback((e: MouseEvent) => {
		if (!ref.current || !e.target || ref.current.contains(e.target as Node)) {
			return;
		}
		onClickAway(e);
	}, [onClickAway]);

	const dismountRef = React.useRef<() => void>();

	const dismountEventListener = React.useCallback(() => {
		dismountRef.current && dismountRef.current();
		dismountRef.current = undefined;
	}, []);

	const mountEventListener = React.useCallback(() => {
		dismountEventListener();
		dismountRef.current = () => document.removeEventListener('click', handleEvent, { capture: true });
		document.addEventListener('click', handleEvent, { capture: true });
	}, [handleEvent, dismountEventListener]);

	// auto mount unless disabled
	React.useLayoutEffect(() => {
		if (disableAutoListen) { return; }
		mountEventListener();
	}, [disableAutoListen, mountEventListener]);

	// Final cleanup on dismount
	React.useLayoutEffect(() => {
		return dismountEventListener;
	}, [dismountEventListener]);

	return { ref, mountEventListener, dismountEventListener };
};

export default useClickAway;
