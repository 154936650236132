import React from 'react';
import { Box, Flex, Text } from '@ascension/web';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { Surgeon } from '../../../../../data/surgeon/Surgeon';
import SpecialtySurgeonList from './SpecialtySurgeonList';
import { useSaveFiltersContext } from '../../SaveFiltersContext/SaveFiltersContext';
import { useFormContext } from 'react-hook-form';

interface HospitalSurgeonsSpecialtyGroupProps{
	specialtyId: string;
}

const useStyles = makeStyles({
	mainRoot: {
		'boxShadow': 'none',
		'borderBottom': 'none',
		'&:before': {
			opacity: 0,
		},
		'&$mainExpanded' : {
			margin: 0,
		},
		'&$mainDisabled': {
			backgroundColor: 'transparent',
			color: 'rgba(0, 0, 0, 0.5)'
		}
	},
	mainExpanded: {},
	mainDisabled: {},
	summaryRoot:{
		'padding': 0,
		'&> div:first-child': {
			margin: 0
		},
		'&$summaryExpanded': {
			minHeight: 'auto'
		}
	},
	summaryContent: {},
	summaryExpanded: {},
	detailsRoot: {
		padding: '0 0 16px 0'
	}
});

const SummarySubheader = ({ specialtyId }: {specialtyId: string}): JSX.Element  => {
	const { specialtyList } = useSaveFiltersContext();
	const subheader = React.useMemo(() => {
		const sortedListOfSurgeons: Surgeon[] = specialtyList[specialtyId] && specialtyList[specialtyId].surgeons ;

		let sh = 'Drs ' + sortedListOfSurgeons.slice(0, 3).map((surgeon: Surgeon) => surgeon.lastName).join(', ');
		if (sortedListOfSurgeons.length > 3) {
			sh += ` (+${sortedListOfSurgeons.length - 3} More)`;
		}

		return sh;
	}, [specialtyList, specialtyId]);

	return React.useMemo(() =>
		<Text fontSize={12} color="rgba(0, 0, 0, 0.6)" fontWeight="bold" lineHeight="16px" letterSpacing="0.4px">{subheader}</Text>, [subheader]
	);
};

const emptyArray: unknown[] = [];

function SpecialtySurgeonSection({ specialtyId }: HospitalSurgeonsSpecialtyGroupProps) {
	const classes = useStyles();
	const [isExpanded, setIsExpanded ] = React.useState<boolean>(false);
	const { specialtyList } = useSaveFiltersContext();
	const specialtyName: string = specialtyList[specialtyId] && specialtyList[specialtyId].specialtyName;
	const sortedListOfSurgeons: Surgeon[] = specialtyList[specialtyId] && specialtyList[specialtyId].surgeons || emptyArray;

	const toggleExpanded = React.useCallback(() => setIsExpanded(e => !e), []);

	const { watch } = useFormContext<{ specialty: string[] | null }>();
	const selectedSpecialties: string[] | null = watch('specialty');
	const isSpecialtyChecked = !!selectedSpecialties && selectedSpecialties.includes(specialtyId);
	React.useEffect(() => setIsExpanded(isSpecialtyChecked), [isSpecialtyChecked]);
	const shouldDisable = !!(selectedSpecialties === null || (selectedSpecialties.length && !isSpecialtyChecked));

	return React.useMemo(() => (
		<Box borderBottom="1px solid rgba(0, 0, 0, 0.12)">
			<Accordion
				classes={{
					root: classes.mainRoot,
					expanded: classes.mainExpanded,
					disabled: classes.mainDisabled
				}}
				disabled={shouldDisable}
				expanded={isExpanded}
				onChange={toggleExpanded}
				square
				data-field={`Accordion_for_${specialtyId}`}
			>
				<AccordionSummary
					classes={{
						root: classes.summaryRoot,
						content: classes.summaryContent,
						expanded: classes.summaryExpanded
					}}
					expandIcon={ <ExpandMoreIcon/> }
					aria-controls={`surgeonAccordion${specialtyId}`}
					id={`surgeonAccordionHeader${specialtyId}`}
				>
					<Flex flexDirection="column">
						<Text fontSize={14} letterSpacing="1.4px" color="#222222" fontWeight="bold">
							All {specialtyName} Surgeons ({sortedListOfSurgeons.length})
						</Text>
						{!isExpanded && sortedListOfSurgeons.length > 0 && <SummarySubheader specialtyId={specialtyId} />}
					</Flex>
				</AccordionSummary>
				<AccordionDetails className={classes.detailsRoot}>
					<Box pt={2} width={1}>
						{isExpanded && <SpecialtySurgeonList
							specialtyId={specialtyId}
						/>}
					</Box>
				</AccordionDetails>
			</Accordion>
		</Box>
	), [classes, isExpanded, shouldDisable, sortedListOfSurgeons.length, specialtyId, specialtyName, toggleExpanded]);
}

export default SpecialtySurgeonSection;
