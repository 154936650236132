import React from 'react';
import { CaseDetailsInfoContextProviderProps, CaseDetailsInfoContextType } from './CaseDetailsInfoContextType';
const CaseDetailsInfoContext = React.createContext<CaseDetailsInfoContextType | null>(null);

/**
 * useCaseDetailsInfoContext - should be used to access/manage state related to case details data (what is stored in DB)
 *
 * @returns CaseDetailsInfoContextType
 */
export const useCaseDetailsInfoContext = () => {
	const ctx = React.useContext(CaseDetailsInfoContext);

	if (!ctx) {
		throw new Error('useCaseDetailsInfoContext must be used in a subcomponent of CaseDetailsInfoContextProvider');
	}

	return ctx;
};

// TODO - For DSSC-2013, should migrate the rest of case details info state from redux to this context
export const CaseDetailsInfoContextProvider: React.FC<CaseDetailsInfoContextProviderProps> = ({ children, caseId }) => {

	const caseDetailsInfoValues: CaseDetailsInfoContextType = {
		caseId,
	};

	return (
		<CaseDetailsInfoContext.Provider value={caseDetailsInfoValues}>
			{children}
		</CaseDetailsInfoContext.Provider>
	);
};
