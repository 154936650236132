import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { UserStatus } from '../../../interfaces/UserProfile/UserStatusEnum';
import { useManageUsersListContext } from '../ManageUsersListContext/ManageUsersListContext';
import { ActionTokenDropdownProps } from './ActionTokenDropdownProps';
import useNavToEditUser from '../../ManageUsersList/utilities/useNavToEditUser';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		margin: {
			margin: theme.spacing(1),
		},
		border: {
			'color': '#1E69D2',
			'borderRadius': '2px',
			'&:hover': {
				background: 'rgba(30, 105, 210, 0.1)',
			}
		},
		listItemText: {
			width: 150,
		}
	}),
);

function ActionTokenDropdown( { user }: ActionTokenDropdownProps ) {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const { viewUser, deactivateUser } = useManageUsersListContext();
	const viewUserClick = () => {
		handleClose();
		viewUser(user);
	};

	const handleDeactivateClick = () => {
		handleClose();
		deactivateUser(user.userId);
	};

	const editUser = useNavToEditUser();
	const handleEditUser = () => {
		handleClose();
		editUser(user.userId);
	};

	return (
		<div>
			<IconButton size="medium" onClick={handleClick} className={classes.border}>
				<MoreHorizIcon fontSize="inherit" />
			</IconButton>

			<Menu
				id="action-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				elevation={3}
				getContentAnchorEl={null}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
			>

				<MenuItem id="view-menu-item" onClick={viewUserClick}>
					<ListItemIcon>
						<VisibilityIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText className={classes.listItemText} primary="View" />
				</MenuItem>
				{user.status == UserStatus.PRE_REGISTERED ? (
					<MenuItem onClick={handleEditUser} id="edit-menu-item">
						<ListItemIcon>
							<EditIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText className={classes.listItemText} primary="Edit" />
					</MenuItem> ) : (
					<MenuItem onClick={handleDeactivateClick} id="deactivate-menu-item">
						<ListItemIcon>
							<DeleteIcon fontSize="small"/>
						</ListItemIcon>
						<ListItemText className={classes.listItemText} primary="Deactivate"/>
					</MenuItem>
				)
				}
			</Menu>
		</div>
	);
}

export default ActionTokenDropdown;
