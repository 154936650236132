export interface Mask {
	placeholder: string;
	validate?: (input: string) => string;
}

export enum MaskType {
	PHONE = 'phone',
	DATE = 'date',
	TIME = 'time',
	SSN = 'ssn'
}

export const maskConfigurations: Record<MaskType, Mask> = {
	time: {
		placeholder: 'HH:MM',
		validate: (inputValue) => {
			if (inputValue === '') {
				return '';
			}
			let [hours, minutes] = inputValue.split(':');

			if (parseInt(hours) > 12) {
				hours = hours.charAt(0);
			}
			if (hours.length === 1 && parseInt(hours) > 1) {
				hours = '0' + hours;
			}
			if (hours.length === 2 && parseInt(hours) == 0) {
				hours = '0';
			}
			if (minutes) {
				if (minutes.length === 1 && parseInt(minutes) > 5) {
					minutes = '0' + minutes;
				}

				if (parseInt(minutes) > 59) {
					minutes = minutes.charAt(0);
				}
				if (parseInt(hours) == 0) {
					minutes = '';
				}
			}
			return minutes ? `${hours}:${minutes}` : hours;
		}
	},
	date: {
		placeholder: 'MM / DD / YYYY',
		validate: (inputValue) => {
			if (inputValue === '') {
				return '';
			}

			let [month = '', day = '', year = ''] = inputValue.split('/').map(item => item.trim());

			month = parseInt(month) > 0 && month.length === 1 && month !== '1' ? '0' + month : month;
			day = parseInt(day) > 3 && day.length === 1 ? '0' + day : day;

			if (parseInt(month) > 12) {
				month = month[0];
				return `${month}`;
			}

			if (parseInt(day) > 31) {
				day = day[0];
				return `${month} / ${day}`;
			}

			year = parseInt(year) < 1 && year.length === 1 ? '' : year;

			if (year === '') {
				if (day === '') {
					return `${month}`;
				}
				return `${month} / ${day}`;
			}
			return `${month} / ${day} / ${year}`;
		}
	},
	ssn: {
		placeholder: '####'
	},
	phone: {
		placeholder: '(###) - ### - ####'
	},
};
