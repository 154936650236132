import React from 'react';
import { useBoxScrollHorizontalContext } from '../BoxScrollHorizontal';

const BoxScrollItem: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => {
	const { observeItem } = useBoxScrollHorizontalContext();
	const itemRef = React.useRef<null | HTMLDivElement>(null);

	const stopObserveItemRef = React.useRef<() => void>();
	React.useEffect(() => {
		return () => {
			if (!stopObserveItemRef.current) { return; }
			stopObserveItemRef.current();
		};
	}, []);

	const registerItem = React.useCallback((elm: HTMLDivElement) => {
		itemRef.current = elm;
		stopObserveItemRef.current = observeItem(elm);
	}, [observeItem]);

	return (
		<div {...props} ref={registerItem} style={{ scrollSnapAlign: 'start' }}>
			{children}
		</div>
	);
};

export default BoxScrollItem;
