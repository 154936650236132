import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles, IconButton, IconButtonProps } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles(theme => createStyles({
	root: {
		'&.MuiIconButton-root': {
			'color': theme.palette.primary.main,
			'backgroundColor': theme.palette.common.white,
			'border': '1px solid currentColor',
			'padding': theme.spacing(1),
			'&:hover': {
				backgroundColor: theme.palette.primary[50],
				color: theme.palette.primary.dark,
			},
			'&.Mui-disabled': {
				backgroundColor: theme.palette.grey[50],
				color: theme.palette.grey[400],
			},
		},
	},
}));

const IconButtonStyled: React.FC<IconButtonProps> = ({ children, ...props }) => {
	const classes = useStyles();

	return (
		<IconButton {...props} className={classNames(classes.root, props.className)}>{children}</IconButton>
	);
};

export default IconButtonStyled;
