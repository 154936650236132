import React from 'react';
import useUpdateHoldEvent from '@data/holdEvent/hooks/useUpdateHoldEvent/useUpdateHoldEvent';
import useHoldEventRequest from '@components/RequestForm/utilities/hooks/useHoldEventRequest/useHoldEventRequest';
import { isHoldEventDetailsUpdateRequest, UpdateHoldEventApiPayload } from '@data/holdEvent/types/HoldEventApiPayload';

const useRefreshHoldEvent = () => {
	const buildRequest = useHoldEventRequest();
	const updateRequest = useUpdateHoldEvent();

	return React.useCallback(({ data, ...override }: Partial<UpdateHoldEventApiPayload> = {}) => {
		const requestData = data || buildRequest();

		isHoldEventDetailsUpdateRequest(requestData) && requestData.id && updateRequest({
			data: requestData,
			...override,
		});
	}, [buildRequest, updateRequest]);
};

export default useRefreshHoldEvent;
