import { apiAction } from '@store/middlewares/api/ApiActionCreators';
import ApiService from '@utilities/apiEndpoints/ApiService';
import {
	ENDPOINT_DOC_VIEW_PERMISSION,
} from '@utilities/apiConstants';
import { GET_DOC_PERMISSION, UPDATE_USER_DOC_VIEW_PERMISSION } from '@store/actions/types';
import { defaultCallback } from '@utilities/defaultCallback';
import { AxiosError } from 'axios';
import { CaseTrackerThunkAction, CaseTrackerThunkDispatch } from '@interfaces/ApiAction/ApiAction';

export function updateDocViewPermission(docViewPermission: boolean | undefined) {
	return {
		type: UPDATE_USER_DOC_VIEW_PERMISSION,
		payload: docViewPermission,
	};
}

export const getDocPermissionApiAction = () => {
	const onFailure = (error: AxiosError): CaseTrackerThunkAction => {
		let docViewPermission = false;
		if (error.response) {
			switch (error.response.status) {
			case 403:
				docViewPermission = false;
				break;
			case 404:
				docViewPermission = true;
				break;
			}
		}
		return (dispatch: CaseTrackerThunkDispatch): void => {
			dispatch(updateDocViewPermission(docViewPermission));
		};
	};

	return apiAction({
		url: ENDPOINT_DOC_VIEW_PERMISSION,
		method: 'POST',
		label: GET_DOC_PERMISSION,
		apiService: ApiService.DS,
		includeAccessToken: true,
		shouldDisableErrorHandler: true,
		onSuccess: defaultCallback,
		onFailure: onFailure,
	});
};
