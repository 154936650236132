import React from 'react';
import { Box, Flex, Text } from '@ascension/web';
import SortingComponent from '../../SortingComponent/SortingComponent';
import SearchComponent from '../../SearchComponent/SearchComponent';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => {
	return {
		sortingDropdown: {
			'& > div > div > div > div > div:first-child': {
				borderStyle: 'unset',
			},
		},
	};
});

const sortOptions = [
	{ label: 'TEMPLATE NAME' },
	{ value: 'name:asc', label: 'TEMPLATE NAME A-Z' },
	{ value: 'name:desc', label: 'TEMPLATE NAME Z-A' },
	{ label: 'SURGEON' },
	{ value: 'lastName:asc,firstName:asc,name:asc', label: 'SURGEON A-Z' },
	{ value: 'lastName:desc,firstName:asc,name:asc', label: 'SURGEON Z-A' },
	{ label: 'PROCEDURE' },
	{ value: 'procedureName:asc,name:asc', label: 'PROCEDURE NAME A-Z' },
	{ value: 'procedureName:desc,name:asc', label: 'PROCEDURE NAME Z-A' },
	{ label: 'DATE CREATED' },
	{ value: 'dateCreated:asc,name:asc', label: 'OLDEST' },
	{ value: 'dateCreated:desc,name:asc', label: 'NEWEST' }
];

const TemplateFilters = ({ surgeonFilter: SurgeonFilter }: {surgeonFilter: React.ElementType}) => {
	const classes = useStyles();
	return (
		<>
			<Flex mb = {10} width={1} flexDirection="column">
				<Box flex="1" width={1}>
					<SearchComponent hasMaxValue={true} placeholder="Search by Keyword"/>
				</Box>
				<Box>
					<Text letterSpacing={2} color="#999999" fontSize="12px" pb={4} fontWeight="bold">
					50 characters max
					</Text>
				</Box>
			</Flex>
			<Flex width={1} style={{ gap: '16px' }}>
				<Box flex="1 1 50%">
					<SurgeonFilter borderless={false} label="Surgeons"/>
				</Box>
				<Box flex="1 1 50%" className={classes.sortingDropdown}>
					<SortingComponent options={sortOptions} borderless={false} />
				</Box>
			</Flex>
		</>
	);
};

export default TemplateFilters;
