import GetDocumentOptions from '@data/document/types/GetDocumentOptions';
import { apiAction } from '@store/middlewares/api/ApiActionCreators';
import ApiService from '@utilities/apiEndpoints/ApiService';
import endpointTemplateToUrl from '@utilities/endpointTemplateToUrl';
import { ENDPOINT_DOC } from '@utilities/apiConstants';
import { GET_DOC } from '@store/actions/types';
import { ErrorStatusState } from '@interfaces/ErrorStatus/ErrorStatusState';
import ApiRestMethod from '@utilities/apiEndpoints/ApiRestMethod';
import DocumentRequestOptions from '@data/document/types/DocumentRequestOptions';

function getDocumentAction<T>(options: DocumentRequestOptions<T>) {
	return apiAction<unknown, ErrorStatusState, T>({
		apiService: ApiService.DS,
		method: ApiRestMethod.GET,
		includeAccessToken: true,
		...options,
	});
}

export const getDocumentGetAction = ({ data: { id, ...data }, ...options }: GetDocumentOptions) => {
	const url = endpointTemplateToUrl(ENDPOINT_DOC, { id });
	return getDocumentAction<Blob>({
		url,
		label: GET_DOC,
		responseType: 'blob',
		data,
		...options,
	});
};
