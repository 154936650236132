import { Reducer } from 'redux';
import { ApiCacheState } from '../../interfaces/ApiCache/ApiCacheState';
import { ApiCacheAction } from '../../interfaces/ApiCache/ApiCacheAction';
import { CLEAR_ALL_CACHE, REPLACE_ENDPOINT_CACHE, SINGLE_APPEND_OR_REPLACE_CACHE } from '../actions/types';

const initialState: ApiCacheState = {
	cache: {},
};

interface UnknownWithId {
	[keyProp: string]: string;
}

export const ApiCacheReducer: Reducer<ApiCacheState, ApiCacheAction> = (state: ApiCacheState = initialState, action: ApiCacheAction) => {
	switch (action.type) {
	case REPLACE_ENDPOINT_CACHE: {
		const updatedState: ApiCacheState = {
			...state,
			cache: {
				...state.cache,
			}
		};
		updatedState.cache[action.payload.endpoint] = action.payload.data;
		return updatedState;
	}
	case SINGLE_APPEND_OR_REPLACE_CACHE: {
		const updatedState: ApiCacheState = {
			...state,
			cache: {
				...state.cache,
			}
		};

		const cacheKeyProp = action.payload.keyProp || 'id';
		const endpointCache: UnknownWithId[] =
			updatedState.cache[action.payload.endpoint] as UnknownWithId[];

		if (!endpointCache || !endpointCache.length) {
			updatedState.cache[action.payload.endpoint] = [ action.payload.data ];
			return updatedState;
		}

		const updatedEndpointCache = [ ...endpointCache ];
		const foundIndex = updatedEndpointCache.findIndex((element: UnknownWithId) =>
			element[cacheKeyProp] === (action.payload.data as UnknownWithId)[cacheKeyProp]);

		if (foundIndex >= 0) {
			updatedEndpointCache[foundIndex] = action.payload.data as UnknownWithId;
		} else {
			updatedEndpointCache.push(action.payload.data as UnknownWithId);
		}

		updatedState.cache[action.payload.endpoint] = updatedEndpointCache;

		return updatedState;
	}
	case CLEAR_ALL_CACHE:
		return initialState;
	}
	return state;
};
