import { Box, Flex, Text } from '@ascension/web';
import { UnscheduledAlertBodyProps } from './UnscheduledAlertBodyProps';
import React from 'react';
export const UnscheduledAlertBody = (descriptionProps: UnscheduledAlertBodyProps) => {

	return (
		<Box px="14px" py="10px" className="alert-body">
			<Flex flexDirection="row" testId="alertBodyHeader">
				<Text
					fontSize={14}
					fontWeight={1}
					color="#222"
				>
					{descriptionProps.description}
				</Text>
			</Flex>
		</Box>
	);
};
