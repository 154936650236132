import React from 'react';
import { useDispatch } from 'react-redux';
import { unlockCase } from '@store/actions/CaseDetailsActionCreators';

const useUnlockCase = () => {
	const dispatch = useDispatch();

	return React.useCallback(
		(caseId: string) => {
			dispatch(unlockCase(caseId));
		},
		[dispatch],
	);
};

export default useUnlockCase;
