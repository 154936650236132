import { useHistory } from 'react-router-dom';
import React from 'react';

const useNavToEditUser = () => {
	const history = useHistory();
	return React.useCallback((userId: string) => {
		history.push(`/users/edit/${userId}`);
	}, [history]);
};

export default useNavToEditUser;
