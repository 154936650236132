import { useDispatch, useSelector } from 'react-redux';
import { ApiCacheSelector } from '@store/selectors/ApiCacheSelector';
import React from 'react';
import { getCollection } from '@store/actions/CollectionActionCreators';
import { AxiosError } from 'axios';
import { DescriptiveError } from '@utilities/DescriptiveError';

/***
 * useAsyncCollection
 * @description
 * Behaves similar to useDeferredCollection but returns a promise that resolves when the collection is ready. No more default empty values while waiting.
 * @template EntityType - The type of the entity in the collection.
 * @param collectionEndpoint {string} - should be the path for an endpoint that returns an array from the SSM service
 * @returns {Promise<EntityType[]>} - Promise that resolves when the collection is ready.
 */
export const useAsyncCollection = <EntityType extends object>(collectionEndpoint: string): Promise<EntityType[]> => {
	const dispatch = useDispatch();
	const { cache: { [collectionEndpoint]: collectionCache } } = useSelector(ApiCacheSelector);

	return React.useMemo(() => {
		const controller = new AbortController();
		const signal = controller.signal;

		// Collection already exists so just return that
		if (collectionCache) {
			return Promise.resolve(collectionCache as EntityType[]);
		}

		// Otherwise, need to make API request to grab collection
		return new Promise((resolve, reject) => {
			const onSuccess = ({ data }: { data: EntityType[] }) => {
				if (signal.aborted) {
					reject(new DescriptiveError('Aborted', 'COLLECTION_REQUEST_ABORTED'));
					return;
				}

				resolve(data);
				return;
			};
			const onFailure = (e: AxiosError) => {
				reject(e);
				return;
			};

			dispatch(getCollection<EntityType[]>(collectionEndpoint, {
				onSuccess,
				onFailure,
			}));
		});
	}, [collectionEndpoint, collectionCache, dispatch]);
};
