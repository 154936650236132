import React from 'react';
import { Box, Button, Flex, Text } from '@ascension/web';
import { useDispatch, useSelector } from 'react-redux';
import {
	UserPrefEmailNotificationSelector,
} from '../../../store/selectors/UserProfileSelector';
import { updateUserProfileNotificationApi } from '../../../store/actions/UserProfileActionCreators';
import { UserDetailsNotificationUpdateApiInterface } from '../../../interfaces/UserPrefInterface';
import usePageEffects from '../../../utilities/hooks/usePageEffects/usePageEffects';
import { useToast } from '../../../utilities/hooks/useToast/useToast';
import { ToastType } from '../../../interfaces/ToastStatus/ToastStatusState';
import { ToastConstants } from '../../../utilities/toastConstants';
import { MyNotificationsCheckbox } from './MyNotificationsCheckbox/MyNotificationsCheckbox';

export default function MyNotifications ()  {
	const dispatch = useDispatch();
	const displayToast = useToast();
	const savedEnableSetting = useSelector(UserPrefEmailNotificationSelector);
	const [currentEnableSetting, setCurrentEnableSetting] = React.useState(savedEnableSetting);
	const isEachButtonDisabled = savedEnableSetting === currentEnableSetting;

	usePageEffects('My Preferences');

	// Update updatable state of a checkbox using data from API when data is received
	React.useEffect(() => {
		setCurrentEnableSetting(savedEnableSetting);
	}, [savedEnableSetting]);

	const onClickSave = () => {
		const payload: UserDetailsNotificationUpdateApiInterface = {
			preference: {
				emailNotification: currentEnableSetting
			}
		};
		const onSuccessCallback = () => displayToast(ToastType.SUCCESS, ToastConstants.SAVE_SUCCESS);
		const onFailureCallback = () => displayToast(ToastType.ERROR, ToastConstants.SAVE_ERROR);
		dispatch(updateUserProfileNotificationApi(payload, onSuccessCallback, onFailureCallback));
	};

	const onClickCancel = () => {
		setCurrentEnableSetting(savedEnableSetting);
	};

	const toggleNotificationCheckbox = React.useCallback(() => {
		setCurrentEnableSetting((current) => !current);
	}, [setCurrentEnableSetting]);

	return (
		<Flex flexDirection="row" justifyContent="center" alignItems="start">
			<Box
				className="card"
				width={1}
				ml="24px"
				borderRadius="4px"
			>
				<Flex flexDirection="column" px={40} py={40}>
					<Flex justifyContent="space-between" width={1} alignItems="start">
						<Box>
							<Text fontSize={7} letterSpacing={1} fontWeight="bold">
                                My Notifications
							</Text>
						</Box>
					</Flex>
					<MyNotificationsCheckbox data-field="myNotificationsCheckbox" checked={currentEnableSetting} onCheck={toggleNotificationCheckbox} showEditLink={true}/>
					<Box textAlign="right" mt="44px" mb="24px">
						<Button id="cancel" onClick={onClickCancel} disabled={isEachButtonDisabled} secondary className="margin-right-16">Cancel</Button>
						<Button id="saveInfo" onClick={onClickSave} disabled={isEachButtonDisabled}> Save </Button>
					</Box>
				</Flex>
			</Box>
		</Flex>
	);
}
