import { combineConsecutiveEvents, doEventsOverlap, EventInfo, getInverseEvents, StartEndEvent } from '@calendar';
import getDateFromApiDateTime from '@calendar/utilities/getDateFromApiDateTime/getDateFromApiDateTime';

/***
 * splitRemoveByOverlapEvents - take single event (eventToSplit), determine gaps between replacementEvents, return list
 * with copies of original event that fills gaps between replacementEvents.
 *
 * @param eventToSplit {EventInfo} Single event that should be split by overlapping events
 * @param replacementEvents {StartEndEvent[]}
 *
 * @returns {EventInfo[]} List of eventToSplit copies with start/end adjusted to fill gaps around replacementEvents.
 *
 * @example
 * splitRemoveByOverlapEvents(
 *  {
 *      id: 'EVENT_ID',
 *      title: 'Dr. Johnson Block',
 *      subTitle: '',
 *      start: new Date('2022-05-04T07:00:00Z'),
 *      end: new Date('2022-05-04T16:00:00Z'),
 *      column: 'OR 1',
 *      type: 'KNOWN_EVENT',
 *  },
 *  [
 *      {
 *          start: new Date('2022-05-04T09:00:00Z'),
 *          end: new Date('2022-05-04T10:00:00Z'),
 *      },
 *      {
 *          start: new Date('2022-05-04T10:00:00Z'),
 *          end: new Date('2022-05-04T12:00:00Z'),
 *      },
 *      {
 *          start: new Date('2022-05-04T15:00:00Z'),
 *          end: new Date('2022-05-04T17:00:00Z'),
 *      }
 *  ]
 * );
 * // returns
 * [
 *  {
 *      id: 'EVENT_ID::0',
 *      title: 'Dr. Johnson Block',
 *      subTitle: '',
 *      start: new Date('2022-05-04T07:00:00Z'),
 *      end: new Date('2022-05-04T09:00:00Z'),
 *      column: 'OR 1',
 *      type: 'KNOWN_EVENT',
 *  },
 *  {
 *      id: 'EVENT_ID::1',
 *      title: 'Dr. Johnson Block',
 *      subTitle: '',
 *      start: new Date('2022-05-04T12:00:00Z'),
 *      end: new Date('2022-05-04T15:00:00Z'),
 *      column: 'OR 1',
 *      type: 'KNOWN_EVENT',
 *  }
 * ];
 */
const splitRemoveByOverlapEvents = (eventToSplit: EventInfo, replacementEvents: StartEndEvent[]): EventInfo[] => {
	const overlapEvents = combineConsecutiveEvents(replacementEvents.filter(e => doEventsOverlap(eventToSplit, e)));

	// No overlaps - return original
	if (overlapEvents.length === 0) {
		return [eventToSplit];
	}

	const isOverlapStart = eventToSplit.start >= getDateFromApiDateTime(overlapEvents[0].start);
	const isOverlapEnd = eventToSplit.end <= getDateFromApiDateTime(overlapEvents[overlapEvents.length - 1].end);

	// Block event is completely covered by other events
	if (isOverlapStart && isOverlapEnd && overlapEvents.length === 1) {
		return [];
	}

	// Does not overlap start so shorten to start of first
	const splitBlocks: EventInfo[] = [];
	if (!isOverlapStart) {
		const startBlock = {
			...eventToSplit,
			id: `${eventToSplit.id}::${splitBlocks.length}`,
			end: new Date(overlapEvents[0].start),
		};
		splitBlocks.push(startBlock);
	}

	// Fill gaps for overlaps
	if (overlapEvents.length > 1) {
		getInverseEvents(overlapEvents).forEach((invEv) => {
			const blockSplit = {
				...eventToSplit,
				start: new Date(invEv.start),
				end: new Date(invEv.end),
				id: `${eventToSplit.id}::${splitBlocks.length}`,
			};
			splitBlocks.push(blockSplit);
		});
	}

	// Shorten block to after last event
	if (!isOverlapEnd) {
		const endBlock = {
			...eventToSplit,
			id: `${eventToSplit.id}::${splitBlocks.length}`,
			start: new Date(overlapEvents[overlapEvents.length - 1].end),
		};
		splitBlocks.push(endBlock);
	}

	return splitBlocks;
};

export default splitRemoveByOverlapEvents;
