import React from 'react';
import useGetDocument from '@data/document/hooks/useGetDocument/useGetDocument';

const useGetDocumentObjectUrl = (documentId: string | undefined) => {
	const getDoc = useGetDocument();

	const [docUrl, setDocUrl] = React.useState<string | undefined>(undefined);

	React.useEffect(() => {
		const onSuccess = (doc: Blob) => {
			setDocUrl(window.URL.createObjectURL(doc));
		};
		if (!documentId) { return; }
		getDoc(documentId, onSuccess);
	}, [documentId, getDoc]);

	React.useEffect(() => {
		const prevUrl = docUrl;
		return () => {
			if (!prevUrl) { return; }
			window.URL.revokeObjectURL(prevUrl);
		};
	}, [docUrl]);

	return docUrl;
};

export default useGetDocumentObjectUrl;
