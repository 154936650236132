import { TemplateTagType } from '../../components/AccountSettings/MyTemplates/TemplateTile/TemplateTagType';
import { UPDATE_TEMPLATE_STATUS } from './types';

export const updateTemplateStatus = (templateId: string, status: TemplateTagType) => {
	return {
		type: UPDATE_TEMPLATE_STATUS,
		payload: {
			templateId,
			status,
		},
	};
};
