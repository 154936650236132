import React, { RefObject } from 'react';
import { Box, Flex } from '@ascension/web';
import { TabDescriptor } from './Tabs/TabDescriptor';
import { Tabs } from './Tabs/Tabs';
import { AppointmentRequestDisplay } from './AppointmentRequestDisplay/AppointmentRequestDisplay';
import { useDispatch, useSelector } from 'react-redux';
import { CaseDetailsAppointmentSelector, CaseDetailsSelector } from '@store/selectors/CaseDetailsSelector';
import { CaseDetailsState } from '@interfaces/CaseDetails/CaseDetailsState';
import { RootState } from '@interfaces/rootState';
import { caseLockStatus, clearCaseDetails } from '@store/actions/CaseDetailsActionCreators';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { CaseDetailsProps } from './CaseDetailsProps';
import { PatientInformationDisplay } from './PatientInformationDisplay/PatientInformationDisplay';
import { SurgeryInformationDisplay } from './SurgeryInformationDisplay/SurgeryInformationDisplay';
import { AbbreviatedCaseCard } from './AbbreviatedCaseCard/AbbreviatedCaseCard';
import CardSection from '../CardSection/CardSection';
import { UnscheduledAlert } from '../Alerts/UnscheduledAlert/UnscheduledAlert';
import { RevisionAlert } from '../Alerts/RevisionAlert/RevisionAlert';
import { RevisionStatus } from '@interfaces/SurgeryRequest/RevisionStatusEnum';
import { PatInformationDisplay } from './PatInformationDisplay/PatInformationDisplay';
import { DocumentInformationDisplay } from './DocumentInformationDisplay/DocumentInformationDisplay';
import { Notification } from '@interfaces/SurgeryRequest/Notification';
import { NotificationAlert } from '../Alerts/NotificationAlert/NotificationAlert';
import { InsuranceInformationDisplay } from './InsuranceInformationDisplay/InsuranceInformationDisplay';
import { CaseQueueSelector } from '@store/selectors/CaseQueueSelector';
import { RequestFormStatus } from '@interfaces/RequestForm/RequestFormStatus';
import usePageEffects from '@utilities/hooks/usePageEffects/usePageEffects';
import { clearQueueStatusSubmitted } from '@store/actions/CaseQueueActionCreators';
import { CopyCaseProvider } from './CopyCaseContext/CopyCaseContext';
import {
	CaseDetailsPageContextProvider,
	useCaseDetailsPageContext,
} from './CaseDetailsContext/CaseDetailsPageContext/CaseDetailsPageContext';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { createStyles, IconButton } from '@material-ui/core';
import PendingLinkAlert from '../Alerts/PendingLinkAlert/PendingLinkAlert';
import { NotificationTypeEnum } from '@interfaces/SurgeryRequest/NotificationTypeEnum';
import { UserProfileHasARoleSelector } from '@store/selectors/UserProfileSelector';
import { HospitalRoles } from '@interfaces/UserProfile/UserRoleEnum';
import {
	CaseDetailsInfoContextProvider,
} from './CaseDetailsContext/CaseDetailsInfoContext/CaseDetailsInfoContext';
import ConfirmCaseProvider from './CaseDetailsContext/ConfirmCaseContext/ConfirmCaseContext';
import { ConfirmationDrawer } from './ConfirmationDrawer/ConfirmationDrawer';
import { useAppPageBodyClassSetter } from '../AppPage/AppPageContext/AppPageContext';
import { CondensedViewToggle } from './CondensedViewToggle/CondensedViewToggle';
import { BackToHomeLink } from '../AppPage/BackToHomeLink/BackToHomeLink';
import {
	CONDENSED_CONTAINER_MIN_WIDTH,
	CONDENSED_MAX_HEIGHT,
	CONDENSED_MIN_HEIGHT,
	CONDENSED_SECTION_MIN_WIDTH,
	DEFAULT_MAX_WIDTH,
	EXPAND_MODAL_MAX_WIDTH,
	EXPANDED_WIDTH,
	LARGE_MAX_WIDTH,
	SCROLLBAR_SIZE,
	VERTICAL_PAGE_MARGIN,
	X_LARGE_MAX_WIDTH,
} from '@theme/themeConstants';
import { CropFree } from '@material-ui/icons';
import DetailSection from './DetailSection';
import useExpandDetailsContent from './ExpandDetailsModal/useExpandDetailsContent';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import classNames from 'classnames';
import { InformationModal } from '../InformationModal/InformationModal';
import CardSectionTitle from '../CardSection/CardSectionTitle/CardSectionTitle';
import CaseDetailsAccessModal from './CaseDetailsAccessModal/CaseDetailsAccessModal';
import OptionsWithDivider from '@components/OptionsWithDivider/OptionsWithDivider';
import { AppointmentType } from '@data/request/AppointmentType';
import { PdfViewerPage } from '@components/PdfViewerPage/PdfViewerPage';
import { isWebRequest } from '@utilities/typeAssertionUtilities';
import { CaseInUseMessage } from '@components/CaseDetails/CaseInUse/CaseInUseMessage/CaseInUseMessage';
import CaseActivityDrawer from '@components/CaseActivityDrawer/CaseActivityDrawer';
import { ManageCaseDropDown } from '@components/CaseDetails/ManageCaseDropDown/ManageCaseDropDown';
import CopyCaseModal
	from '@components/CaseDetails/ManageCaseDropDown/DropDownOptions/CopyCase/CopyCaseModal/CopyCaseModal';
import {
	EditRequestForm
} from '@components/CaseDetails/ManageCaseDropDown/DropDownOptions/EditCase/EditRequestForm/EditRequestForm';
import CaseActivityContextProvider from '@components/CaseActivityDrawer/CaseActivityContext/CaseActivityContext';
import { ModalContextProvider, useModalContext } from '@components/Modal/ModalContextProvider';
import CancelCaseModal
	from '@components/CaseDetails/ManageCaseDropDown/DropDownOptions/CancelCase/CancelCaseModal/CancelCaseModal';
import {
	CaseLockModal
} from '@components/CaseDetails/ManageCaseDropDown/DropDownOptions/EditCase/CaseLockModal/CaseLockModal';
import RequestRevisionModal from '@components/CaseDetails/RequestRevisionModal/RequestRevisionModal';
import { REQUEST_REVISION_MODAL } from '@components/Modal/modalConstants';
import ModalViewer from '@components/Modal/ModalViewer';
import { DrawerContextProvider, useDrawerContext } from '@components/DrawerWrapper/DrawerContextProvider';
import {
	useGetCasePostConfirmerAccessor
} from '@components/CaseDetails/CaseInUse/hooks/useGetCaseAsAccessor/useGetCasePostAccessor';
import CaseEditLockMessage from '@components/CaseDetails/CaseEditLockMessage/CaseEditLockMessage';
import { useLocation } from 'react-router-dom';
import RequestFormPageState from '@components/RequestForm/RequestFormPage.types';

const SERVICE_ERROR = 'There was an error retrieving the case. Please try again.';

const useStyles = makeStyles((theme) => (createStyles({
	fullWidth: {
		'& > div': {
			[theme.breakpoints.up('lg')]: {
				maxWidth: LARGE_MAX_WIDTH,
			},
			[theme.breakpoints.up('xl')]: {
				maxWidth: X_LARGE_MAX_WIDTH,
			},
			maxWidth: DEFAULT_MAX_WIDTH,
		}
	},
	pageWrapper: {
		display: 'grid',
		position: 'relative',
		gridTemplateColumns: '100%',
		gridTemplateAreas: `
			"buttons"
			"alerts"
			"summaryCard"
			"details"
		`,
		margin: theme.spacing(0, 2),
	},
	headerBtnContainer: {
		display: 'inline-flex',
		justifyContent: 'space-between',
		alignItems: 'baseline',
		paddingBottom: theme.spacing(2),
	},
	divider: {
		margin: theme.spacing(0, 2),
		height: '36px',
	},
	caseDetailsCondensed: {
		'display': 'flex',
		'flexDirection': 'row',
		'gap': theme.spacing(2),
		'overflowX': 'scroll',
		'scrollSnapType': 'x proximity',
		'overflowY': 'hidden',
		'minWidth': CONDENSED_CONTAINER_MIN_WIDTH,
		'minHeight': CONDENSED_MIN_HEIGHT,
		'maxHeight': CONDENSED_MAX_HEIGHT,
		'&::-webkit-scrollbar': {
			height: SCROLLBAR_SIZE,
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.palette.grey[400],
			borderRadius: '100px',
		},
		// Firefox specific scrollbar styles
		'scrollbarWidth': 'thin',
		'scrollbarColor': `${theme.palette.grey[400]} transparent`,
	},
	closedDrawerCondensedContainer: {
		marginLeft: `-${theme.spacing(9)}px`,
		width: 'calc(100% + 144px)',
	},
	condensedSection: {
		'minWidth': CONDENSED_SECTION_MIN_WIDTH,
		'width': CONDENSED_SECTION_MIN_WIDTH,
		'minHeight': CONDENSED_MIN_HEIGHT,
		'& .sectionTitle': {
			'backgroundColor': theme.palette.primary[50],
			'position': 'sticky',
			'top': 0,
			'zIndex': 1,
			'display': 'flex',
			'justifyContent': 'space-between',
			'alignItems': 'center',
			'paddingRight': '12px',
			'maxHeight': '63px',
			'& .section-title': {
				fontSize: '18px',
				paddingTop: '20px',
				paddingBottom: '20px',
				paddingLeft: theme.spacing(3),
			},
			'& .MuiSvgIcon-root': {
				fill: '#1E69D2',
				width: '20px',
				height: '20px',
			},
		},
		'& .sectionBody': {
			'overflowY': 'scroll',
			'padding': theme.spacing(2, 3),
			'&::-webkit-scrollbar': {
				width: SCROLLBAR_SIZE,
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: theme.palette.grey[400],
				borderRadius: '100px',
			},
			// Firefox specific scrollbar styles
			'scrollbarWidth': 'thin',
			'scrollbarColor': `${theme.palette.grey[400]} transparent`,
		},
	},
	caseDetailsExpanded: {
		'display': 'flex',
		'flexDirection': 'column',
		'flex': `${theme.spacing(Number.MAX_VALUE)}px 1 1`,
		'position': 'relative',
		'marginTop': theme.spacing(4),
		'&> div:first-child': {
			display: 'none',
		},
		'&> div:last-child': {
			display: 'none',
		},
		'& .MuiIconButton-root': {
			visibility: 'hidden',
		},
	},
	expandedSection: {
		'width': EXPANDED_WIDTH,
		'& .sectionTitle': {
			padding: theme.spacing(3, 0, 2, 4),
		},
		'& .sectionBody': {
			paddingLeft: theme.spacing(4),
			paddingRight: theme.spacing(4),
		}
	},
	arrowBtn: {
		'color': '#1E69D2',
		'&.Mui-disabled': {
			color: theme.palette.grey['600'],
		},
		'& .MuiSvgIcon-root': {
			height: '2em',
			width: '2em',
		},
		'&.MuiIconButton-root:hover': {
			backgroundColor: 'unset',
		},
	},
	containerGradient: {
		background: `linear-gradient(-90deg, ${alpha(theme.palette.common.white, 0)} 0%, #EFF0F4 100%)`,
		position: 'absolute',
		height: '100%',
		width: '24px',
	},
	rightSideGradient: {
		right: theme.spacing(9),
		transform: 'rotate(180deg)',
	},
	hidden: {
		display: 'none',
	},
	modal: {
		'& [class*="SsmDialogHeader-root"]': {
			backgroundColor: theme.palette.primary[50],
		},
		'& [class*="SsmDialogHeader-controls"] .MuiIconButton-root': {
			color: theme.palette.primary.main,
		},
		'& .MuiDialog-paper': {
			width: EXPAND_MODAL_MAX_WIDTH,
			maxWidth: '100vw',
			maxHeight: `calc(100vh - ${VERTICAL_PAGE_MARGIN * 2}px)`
		}
	},
	hideComponent: {
		display: 'none',
	}
})));

enum ScrollSnapAlignOptions {
	END = 'end',
	START = 'start'
}
enum ScrollDirection {
	LEFT = 'left',
	RIGHT = 'right',
	BOTH = 'both'
}

const CaseDetailsInner = ({ caseId }: CaseDetailsProps) => {
	const classes = useStyles();
	const { error, details } = useSelector<RootState, CaseDetailsState>(CaseDetailsSelector);
	const { status } = useSelector(CaseDetailsAppointmentSelector);
	const { queueStatus } = useSelector(CaseQueueSelector);
	const dispatch = useDispatch();
	const hasHospitalRole = useSelector(UserProfileHasARoleSelector(HospitalRoles));
	const { showCondensedView, shouldShowEdit, selectedAttachment, showCondensedViewToggle } = useCaseDetailsPageContext();
	const showUnscheduled = status === 'UNSCHEDULED';
	const showPendingLinkAlert = hasHospitalRole && details?.notifications.some(notification => notification.type === NotificationTypeEnum.PENDING_LINK);
	const { closeModal } = useModalContext();
	const { activeDrawer, closeDrawer } = useDrawerContext();
	const { state } = useLocation<RequestFormPageState>();

	const { setBodyClass } = useAppPageBodyClassSetter('CaseDetails');

	React.useEffect(() => {
		setBodyClass(classes.fullWidth);
	}, [classes, setBodyClass]);

	usePageEffects('Case Details');

	const updateCaseLockStatusOnSuccess = React.useMemo(() => ({
		onSuccess: () => dispatch(caseLockStatus(caseId, false)),
	}), [caseId, dispatch]);
	const { getCaseDetails } = useGetCasePostConfirmerAccessor(caseId, updateCaseLockStatusOnSuccess, false);
	React.useEffect(() => {
		getCaseDetails();

		return () => void dispatch(clearCaseDetails());
	}, [dispatch, getCaseDetails]);

	const appointmentRef = React.useRef<HTMLDivElement>(null);
	const patientRef = React.useRef<HTMLDivElement>(null);
	const surgeryRef = React.useRef<HTMLDivElement>(null);
	const patRef = React.useRef<HTMLDivElement>(null);
	const docRef = React.useRef<HTMLDivElement>(null);
	const primaryInsuranceRef = React.useRef<HTMLDivElement>(null);
	const secondaryInsuranceRef = React.useRef<HTMLDivElement>(null);

	const TAB_DESCRIPTORS = React.useMemo<TabDescriptor[]>(() => {
		let tabs: TabDescriptor[] = [
			{
				value: 'appointment',
				label: 'Appointment Request',
				sectionTitle: 'Appointment Request',
				dataSection: DetailSection.APPOINTMENT,
				ref: appointmentRef,
				innerComponent: <AppointmentRequestDisplay/>
			},
			{
				value: 'patient',
				label: 'Patient Info',
				sectionTitle: 'Patient Information',
				dataSection: DetailSection.PATIENT,
				ref: patientRef,
				innerComponent: <PatientInformationDisplay/>
			},
			{
				value: 'surgery',
				label: 'Surgery Info',
				sectionTitle: 'Surgery Information',
				dataSection: DetailSection.PROCEDURE,
				ref: surgeryRef,
				innerComponent: <SurgeryInformationDisplay/>
			},
			{
				value: 'pat',
				label: 'PAT Info',
				sectionTitle: 'PAT Information',
				dataSection: DetailSection.PAT,
				ref: patRef,
				innerComponent: <PatInformationDisplay/>
			},
		];
		if (details && isWebRequest(details)) {
			const { insurances } = details;
			const primaryInsurance = insurances?.filter(insurance => insurance.primary);
			const secondaryInsurance = insurances?.filter(insurance => !insurance.primary);

			const primaryInsuranceInfo = {
				value: 'primary insurance',
				sectionTitle: 'Primary Insurance Information',
				dataSection: DetailSection.PRIMARY_INSURANCE,
				ref: primaryInsuranceRef,
				innerComponent: <InsuranceInformationDisplay insurance={(primaryInsurance && primaryInsurance[0]) && primaryInsurance[0]}/>
			};
			const secondaryInsuranceInfo = {
				value: 'secondary insurance',
				sectionTitle: 'Secondary Insurance Information',
				dataSection: DetailSection.SECONDARY_INSURANCE,
				ref: secondaryInsuranceRef,
				innerComponent: <InsuranceInformationDisplay insurance={(secondaryInsurance && secondaryInsurance[0]) && secondaryInsurance[0]}/>
			};

			(insurances && insurances.length === 2)
				? tabs.splice(2, 0, primaryInsuranceInfo, secondaryInsuranceInfo)
				: tabs.splice(2, 0, primaryInsuranceInfo);
		}

		if (details?.appointmentType && details.appointmentType !== AppointmentType.LEGACY_WEB) {
			const appointmentConfig = {
				value: 'appointment',
				label: 'Appointment Request',
				sectionTitle: 'Appointment Request',
				dataSection: DetailSection.APPOINTMENT,
				ref: appointmentRef,
				innerComponent: <AppointmentRequestDisplay />
			};
			const documentConfig = {
				value: 'attachments',
				label: `Documents (${details?.attachments?.length || 0})`,
				sectionTitle: `Documents (${details?.attachments?.length || 0})`,
				dataSection: DetailSection.ATTACHMENTS,
				ref: docRef,
				innerComponent: <DocumentInformationDisplay />
			};
			if (details.appointmentType === AppointmentType.WEB) {
				tabs.push(documentConfig);
			} else if (details.appointmentType === AppointmentType.DOCUMENT) {
				tabs = [appointmentConfig, documentConfig];
			}
		}

		return tabs;
	}, [details]);

	const caseDetailsHeaderRef = React.useRef<HTMLElement>(null);
	const tabsRef = React.useRef<HTMLElement>(null);

	const [ topSectionOffset, setTopSectionOffset ] = React.useState(0);

	React.useEffect(() => {
		const getRefHeight = (ref: RefObject<HTMLElement>) => {
			return ref && ref.current ? ref.current.offsetHeight : 0;
		};

		setTopSectionOffset(getRefHeight(caseDetailsHeaderRef) + getRefHeight(tabsRef) + 15);
	}, [TAB_DESCRIPTORS, details]);

	React.useEffect(() => {
		if (queueStatus === RequestFormStatus.SUBMITTED) {
			dispatch(clearQueueStatusSubmitted());
			getCaseDetails();
		}
	}, [queueStatus, dispatch, getCaseDetails]);

	const condensedContainerRef = React.useRef<HTMLDivElement | null>(null);
	const [scrollSnapAlignment, setScrollSnapAlignment] = React.useState<ScrollSnapAlignOptions>(ScrollSnapAlignOptions.END);
	const handleHorizontalSnapScroll = React.useCallback((direction: ScrollDirection): void => {
		const scrollWidth = 300;
		const operator = direction === ScrollDirection.RIGHT ? 1 : -1;
		const condensedContainerEl = condensedContainerRef.current;
		if (condensedContainerEl) {
			const scrollToXCoordinate = condensedContainerEl.scrollLeft + (scrollWidth * operator);
			condensedContainerEl.scrollTo({
				top: 0,
				left: scrollToXCoordinate,
				behavior: 'smooth'
			});
		}
	}, []);

	const handleArrowKeyScroll = React.useCallback((e: KeyboardEvent): void => {
		if (e.key === 'ArrowRight') {
			setScrollSnapAlignment(ScrollSnapAlignOptions.END);
			handleHorizontalSnapScroll(ScrollDirection.RIGHT);
		} else if (e.key === 'ArrowLeft') {
			setScrollSnapAlignment(ScrollSnapAlignOptions.START);
			handleHorizontalSnapScroll(ScrollDirection.LEFT);
		}
	}, [handleHorizontalSnapScroll]);
	React.useEffect(() => {
		void document.addEventListener('keydown', handleArrowKeyScroll);

		return () => document.removeEventListener('keydown', handleArrowKeyScroll);
	}, [handleArrowKeyScroll]);
	const [disabledScrollBtn, setDisabledScrollBtn] = React.useState<ScrollDirection | null>(ScrollDirection.LEFT);

	const condensedContainerEl = condensedContainerRef.current;

	const handleDisableScrollBtns = React.useCallback(() => {
		if (condensedContainerEl) {
			if (condensedContainerEl.scrollWidth <= condensedContainerEl.offsetWidth) {
				// disable both arrows if card contents are equal to container width or narrower
				setDisabledScrollBtn(ScrollDirection.BOTH);
			} else if (condensedContainerEl.scrollLeft <= 2) {
				// disable left arrow if contents scrolled all the way to left
				setDisabledScrollBtn(ScrollDirection.LEFT);
			} else if (condensedContainerEl && (condensedContainerEl.offsetWidth + condensedContainerEl.scrollLeft >= condensedContainerEl.scrollWidth - 2)) {
				// disable right arrow if contents scrolled all the way to right
				setDisabledScrollBtn(ScrollDirection.RIGHT);
			} else {
				// do not disable either arrow if none of above conditions met
				setDisabledScrollBtn(null);
			}
		}
	}, [condensedContainerEl]);
	React.useEffect(() => {
		void handleDisableScrollBtns();
		void condensedContainerEl?.addEventListener('scroll', handleDisableScrollBtns);
		return () => condensedContainerEl?.removeEventListener('scroll', handleDisableScrollBtns);
	}, [condensedContainerEl, details, handleDisableScrollBtns]);

	const handleExpandClick = useExpandDetailsContent();

	const disableLeftArrow = React.useMemo(() => (disabledScrollBtn === ScrollDirection.LEFT || disabledScrollBtn === ScrollDirection.BOTH), [disabledScrollBtn]);
	const disableRightArrow = React.useMemo(() => (disabledScrollBtn === ScrollDirection.RIGHT || disabledScrollBtn === ScrollDirection.BOTH), [disabledScrollBtn]);

	if (shouldShowEdit || state?.isSchedulingEdit) {
		if (activeDrawer) {
			closeDrawer(activeDrawer);
		}
		return <EditRequestForm />;
	}

	const shouldDisplayTabs = !showCondensedView && (TAB_DESCRIPTORS.length > 1);

	const handleCloseRequestRevisionModal = () => {
		closeModal(REQUEST_REVISION_MODAL);
	};

	return (<>
		{selectedAttachment && <PdfViewerPage />}
		<div className={classNames(classes.pageWrapper, { [classes.hideComponent]: !!selectedAttachment })}>
			<div className={classes.headerBtnContainer} style={{ gridArea: 'buttons' }}>
				<BackToHomeLink navToHomeText="< Back"/>
				<CaseInUseMessage />
				<CaseEditLockMessage />
				<OptionsWithDivider>
					{ showCondensedViewToggle && <CondensedViewToggle/> }
					<CopyCaseProvider>
						<CopyCaseModal/>
						<ManageCaseDropDown />
					</CopyCaseProvider>
				</OptionsWithDivider>
			</div>

			<CancelCaseModal/>
			<CaseLockModal/>

			<ModalViewer id={REQUEST_REVISION_MODAL}>
				<RequestRevisionModal close={handleCloseRequestRevisionModal}/>
			</ModalViewer>

			<Box data-section="CaseDetailsHeader" ref={caseDetailsHeaderRef}>
				<div style={{ gridArea: 'alerts' }}>
					{
						error && <ErrorMessage errorField={{ message: SERVICE_ERROR }} />
					}
					{details && details.notifications.map((item: Notification, index: number) => (
						<Box data-field={'caseCardDetailsNotification' + item.id} key={index} >
							<NotificationAlert notification={item}/>
						</Box>
					))}
					{ showPendingLinkAlert && <PendingLinkAlert /> }
					<RevisionAlert revisionStatus={RevisionStatus.NEED_REVISION}/>
					<RevisionAlert revisionStatus={RevisionStatus.REVISED}/>
					{ showUnscheduled && <UnscheduledAlert/> }
				</div>
				<div style={{ gridArea: 'summaryCard' }}>
					{ !showCondensedView && <AbbreviatedCaseCard/> }
				</div>
			</Box>

			<div style={{ gridArea: 'details' }}>
				{ shouldDisplayTabs &&
						<Box
							position="sticky"
							top="68px"
							zIndex={2}
							width="100%"
							ref={tabsRef}
						>
							<Box>
								<Tabs
									tabDescriptions={TAB_DESCRIPTORS}
									className="btn-small-container"
									data-section="CaseDetailsTabs"
									height="50px"
									alignSelf="flex-start"
									alignItems="flex-end"
									borderBottom="2px solid #C9C9C9"
									topSectionOffset={topSectionOffset}
								>
								</Tabs>
							</Box>
						</Box>
				}
				<Flex
					data-section="CaseDetailsMain"
					flex="auto 1 1"
					position="relative"
					flexDirection="row"
					className={showCondensedView && !activeDrawer ? classes.closedDrawerCondensedContainer : ''}
				>
					{ showCondensedView &&
							<IconButton
								id="left-nav-btn"
								onMouseDown={() => setScrollSnapAlignment(ScrollSnapAlignOptions.START)}
								onClick={() => handleHorizontalSnapScroll(ScrollDirection.LEFT)}
								disabled={disableLeftArrow}
								aria-disabled={disableLeftArrow}
								className={classes.arrowBtn}
								disableRipple
							>
								<KeyboardArrowLeftIcon/>
							</IconButton>
					}
					<Box
						id="CaseDetailsBody"
						data-section="CaseDetailsBody"
						minWidth="50%"
						width="100%"
						className={showCondensedView ? classes.caseDetailsCondensed : classes.caseDetailsExpanded}
						ref={condensedContainerRef}
					>
						<Box className={classNames({ [classes.containerGradient]: true, [classes.hidden]: disableLeftArrow })}/>
						{TAB_DESCRIPTORS && TAB_DESCRIPTORS.map( (tab: TabDescriptor) => {
							return (
								<CardSection
									key={tab.value}
									id={`${tab.value}-card-section`}
									ref={tab.ref}
									sectionTitle={
										<CardSectionTitle title={tab.sectionTitle}>
											<IconButton
												onClick={() => handleExpandClick(tab.dataSection)}
												data-field="expand-section-btn"
												aria-label={`expand-${tab.value}-section`}
											>
												<CropFree />
											</IconButton>
										</CardSectionTitle>
									}
									data-section={tab.dataSection}
									className={showCondensedView ? `${classes.condensedSection} condensed-section` : classes.expandedSection}
									style={{ scrollSnapAlign: scrollSnapAlignment }}
								>
									{tab.innerComponent}
								</CardSection>
							);
						})}
						<Box className={classNames({ [classes.containerGradient]: true, [classes.rightSideGradient]: true, [classes.hidden]: disableRightArrow })}/>
					</Box>

					{ showCondensedView &&
							<IconButton
								id="right-nav-btn"
								onMouseDown={() => setScrollSnapAlignment(ScrollSnapAlignOptions.END)}
								onClick={() => handleHorizontalSnapScroll(ScrollDirection.RIGHT)}
								disabled={disableRightArrow}
								aria-disabled={disableRightArrow}
								className={classes.arrowBtn}
								disableRipple
							>
								<KeyboardArrowRightIcon/>
							</IconButton>
					}
				</Flex>
			</div>
			<ConfirmationDrawer/>
			<CaseActivityDrawer appointmentType={details?.appointmentType}/>
			<CaseDetailsAccessModal/>
		</div>
	</>);
};

export const CaseDetails = ({ caseId }: CaseDetailsProps) => {
	const classes = useStyles();
	return (
		<CaseDetailsInfoContextProvider caseId={caseId}>
			<CaseDetailsPageContextProvider>
				<DrawerContextProvider>
					<CaseActivityContextProvider>
						<ConfirmCaseProvider>
							<ModalContextProvider>
								<InformationModal className={`${classes.modal} modal`}>
									<CaseDetailsInner caseId={caseId}/>
								</InformationModal>
							</ModalContextProvider>
						</ConfirmCaseProvider>
					</CaseActivityContextProvider>
				</DrawerContextProvider>
			</CaseDetailsPageContextProvider>
		</CaseDetailsInfoContextProvider>
	);
};
