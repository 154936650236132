import { FieldValues, useController } from 'react-hook-form';
import React, { ReactElement } from 'react';
import { StandardInputWrapper } from '@components/StatefulInputs/Base/StandardInputWrapper/StandardInputWrapper';
import { OutlinedInput } from '@material-ui/core';
import { StatefulTextInputProps } from '@components/StatefulInputs/StatefulTextInput/StatefulTextInput.types';

export function StatefulTextInput<FormType extends FieldValues>({
	controllerOptions = {},
	textInputProps,
	...statefulProviderProps
}: StatefulTextInputProps<FormType>): ReactElement {
	const {
		field
	} = useController<FormType>({ name: statefulProviderProps.name, ...controllerOptions });

	React.useEffect(() => {
		if (controllerOptions.defaultValue && !field.value) {
			field.onChange(controllerOptions.defaultValue);
		}
	}, [controllerOptions.defaultValue, field]);
	const handleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		field.onChange(event.target.value);
		textInputProps?.onChange && textInputProps?.onChange(event);
	}, [field, textInputProps]);

	return (
		<StandardInputWrapper<FormType>
			{...statefulProviderProps}
			labelProps={{
				id: `textInput-label-${statefulProviderProps.name}`,
				htmlFor: `textInput-field-${statefulProviderProps.name}`,
			}}
			analyticsInputName="textInput"
		>
			<OutlinedInput
				{...field}
				{...textInputProps}
				name={statefulProviderProps.name}
				id={`textInput-field-${statefulProviderProps.name}`}
				value={textInputProps?.value || field.value || controllerOptions.defaultValue || ''}
				disabled={controllerOptions.disabled || textInputProps?.disabled}
				onChange={handleChange}
			/>
		</StandardInputWrapper>
	);
}
