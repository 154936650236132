import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReportProblemOutlined } from '@material-ui/icons';
import BlueOutlineButton from '../../../BlueOutlineButton/BlueOutlineButton';
import GoldButton from '../../../GoldButton/GoldButton';
import { useManageUsersListContext } from '../../ManageUsersListContext/ManageUsersListContext';

const useStyles = makeStyles(theme => ({
	warningIcon: {
		color: theme.palette.warning.main,
		marginRight: theme.spacing(1.5),
	},
	titleContainer: {
		'marginLeft': theme.spacing(5),
		'display': 'flex',
		'alignItems': 'center',
		'& .MuiTypography-h3': {
			fontSize: '24px',
			fontWeight: 400,
		},
	},
	message: {
		'margin': theme.spacing(3, 5, 0, 5),
		'fontWeight': 350,
		'lineHeight': '21.6px',
		'letterSpacing': '0.15px',
		'& > strong': {
			fontWeight: 600,
		},
	},
	buttonContainer: {
		'height': '48px',
		'display': 'flex',
		'justifyContent': 'flex-end',
		'margin': theme.spacing(5.75, 2, 5, 0),
		'& .MuiButton-root': {
			height: '100%',
		},
		'& .MuiButton-outlined': {
			marginRight: theme.spacing(2),
		},
	},
}));

const DeactivateUserContent: React.FC = () => {
	const classes = useStyles();

	const { managedUser, resetInteractionState, deactivateUserApiCall } = useManageUsersListContext();

	return (
		<>
			<Box display="flex" className={classes.titleContainer}>
				<ReportProblemOutlined className={classes.warningIcon}/>
				<Typography variant="h3">Deactivate user</Typography>
			</Box>
			<Typography className={classes.message}>Are you sure you want to deactivate <strong>{managedUser?.firstName} {managedUser?.lastName}</strong> from Surgery Scheduling Manager? This cannot be undone.</Typography>
			<Box className={classes.buttonContainer}>
				<BlueOutlineButton data-field="deactivate-cancel-btn" onClick={resetInteractionState}>NO, CANCEL</BlueOutlineButton>
				<GoldButton data-field="deactivate-user-btn" onClick={() => deactivateUserApiCall()}>YES, DEACTIVATE USER</GoldButton>
			</Box>
		</>
	);
};

export default DeactivateUserContent;
