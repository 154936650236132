import React from 'react';
import { StandardInputWrapperProps } from '@components/StatefulInputs/Base/StandardInputWrapper/StandardInputWrapper.types';
import { InputLabel, InputLabelProps, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { alpha, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
	root: {
		...theme.typography.h3,
		'display': 'inline-flex',
		'gap': theme.spacing(.5),
		'& > .MuiTypography-root': {
			...theme.typography.h3,
			color: alpha(theme.palette.text.primary, 0.6),
		},
	},
	extraLabel: {
		'&.MuiTypography-root': {
			color: alpha(theme.palette.text.primary, 0.38),
		}
	},
}));

export const StatefulInputLabel: React.FC<
	Pick<StandardInputWrapperProps<unknown>, 'label' | 'extraLabel'> & InputLabelProps
> = ({
	label,
	extraLabel,
	...inputLabelProps
}) => {
	const classes = useStyles();
	const rootClass = classNames(classes.root, inputLabelProps.className);

	if (!label) {
		return null;
	}
	if (!extraLabel) {
		return <InputLabel {...inputLabelProps} className={rootClass}>{label}</InputLabel>;
	}
	return (
		<InputLabel {...inputLabelProps} className={rootClass}>
			<Typography>{label}</Typography>
			<Typography className={classes.extraLabel}>{extraLabel}</Typography>
		</InputLabel>
	);
};
