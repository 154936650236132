import React from 'react';
import { Box } from '@material-ui/core';
import { Multiselect } from '@ascension/web';
import { filterSortOptions } from '../../../../utilities/constants';
import { useFieldFormContextWithPrevious } from '../../../../utilities/hooks/useFieldFormContextWithPrevious/useFieldFormContextWithPrevious';
import { useSelector } from 'react-redux';
import { MyFiltersSelector } from '../../../../store/selectors/MyFiltersSelector';
import { DEFAULT_CASES_SORT } from '../../../../utilities/apiConstants';
import { DropdownOption } from '../../../../definitions/DropdownOption';
import { useCommonStyles } from '../../../../utilities/hooks/useCommonStyles/useCommonStyles';

export default function SortBy() {
	const { inputBorderStyle } = useCommonStyles();
	const { sortModel: lastSavedSortModel } = useSelector(MyFiltersSelector);
	const defaultCurrentValue = lastSavedSortModel || DEFAULT_CASES_SORT;
	const {
		currentValue,
		setCurrentValue: myFilterSortBySetCurrentValue,
	} = useFieldFormContextWithPrevious('sortModel') as { currentValue: string; setCurrentValue: (v: string) => void};

	const [ selectedValue, setSelectedValue ] = React.useState(defaultCurrentValue);

	const handleChange = React.useCallback((newValue: DropdownOption[]) => {
		const newSortModelValue = newValue[0].value;

		setSelectedValue(newSortModelValue);
		myFilterSortBySetCurrentValue(newSortModelValue);
	}, [myFilterSortBySetCurrentValue, setSelectedValue]);

	const option = filterSortOptions.filter( item => item.value == selectedValue);

	React.useEffect(() => {
		setSelectedValue(currentValue);
	}, [currentValue]);

	return (
		<Box display="flex" width={1} py={2} style={{ gap: '16px' }}>
			<Box flex="1 1 50%">
				<Box minWidth={120} width={0.5} data-field="SORT_BY" className={inputBorderStyle}>
					<Multiselect single value={option} onChange={handleChange} label="Sort by" options={filterSortOptions} />
				</Box>
			</Box>
		</Box>
	);
}


