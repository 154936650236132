import React, { RefObject, useMemo } from 'react';
import { useMaskFormContext } from '@utilities/hooks/useMaskFormContext/useMaskFormContext';
import { PatientDetailsValidationSchema } from '../PatientDetailsValidationSchema';
import { Label, Box, Flex, Text } from '@ascension/web';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { useFieldFormContext } from '@utilities/hooks/useFieldFormContext/useFieldFormContext';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';
import { INVALID_SSN_LONG } from '@utilities/Validation/ValidationMessages';
import TextFieldInput from '@components/TextFieldInput/TextFieldInput';

const FIRST_SPACING = 10;
const SECOND_SPACING = 8;
const THIRD_SPACING = 11;
const DASH_SPACING = 3;
const useStyles = makeStyles((theme) => createStyles({
	marginBottomZero: {
		marginBottom: 0
	},
	ssn1: {
		width: theme.spacing(FIRST_SPACING),
	},
	ssn2: {
		width: theme.spacing(SECOND_SPACING),
	},
	ssn3: {
		width: theme.spacing(THIRD_SPACING),
	},
	dash: {
		width: theme.spacing(DASH_SPACING),
		alignItems: 'center',
		justifyContent: 'center',
	},
	container: {
		paddingBottom: theme.spacing(3),
		width: theme.spacing(FIRST_SPACING + SECOND_SPACING + THIRD_SPACING + DASH_SPACING * 2),
		alignItems: 'center',
		textAlign: 'center'
	},
	ssnErrorOrAssistiveArea: {
		paddingTop: theme.spacing(1),
		marginTop: theme.spacing(-3),
		marginBottom: theme.spacing(1)
	},
	assistiveText: {
		color: 'rgba(0, 0, 0, 0.6)',
		fontSize: '12px',
		fontWeight: 700,
		textTransform: 'none',
	}
}));

const ASSISTIVE_TEXT_MESSAGE = 'Please enter the full 9 digits or last 4 digits.';

export default function SSNInput () {
	const [
		patientSSNCurrentValue1,
		patientSSNSetCurrentValue1,
		patientSSNError1,
		patientSSNPlaceholder1,
		patientSSNTriggerSelfValidation1
	] = useMaskFormContext('patientSSN1', 'ssn', {
		validationRules: PatientDetailsValidationSchema.patientSSN1,
		triggerValidationOnChange: false,
	});

	const [
		patientSSNCurrentValue2,
		patientSSNSetCurrentValue2,
		patientSSNError2,
		patientSSNPlaceholder2,
		patientSSNTriggerSelfValidation2
	] = useMaskFormContext('patientSSN2', 'ssn', {
		validationRules: PatientDetailsValidationSchema.patientSSN2,
		triggerValidationOnChange: false,
	});

	const [
		patientSSNCurrentValue3,
		patientSSNSetCurrentValue3,
		patientSSNError3,
		patientSSNPlaceholder3,
		patientSSNTriggerSelfValidation3
	] = useMaskFormContext('patientSSN3', 'ssn', {
		validationRules: PatientDetailsValidationSchema.patientSSN3,
		triggerValidationOnChange: false,
	});

	const {
		setCurrentValue: patientFullSSNSetCurrentValue,
		error: patientFullSSNError,
		setErrorMessage: setErrorMessage,
		clearErrorMessage: clearErrorMessage
	} = useFieldFormContext('patientSSN', {
		validationRules: PatientDetailsValidationSchema.patientSSN,
		triggerValidationOnChange: true,
	});

	const ssn1Ref: RefObject<HTMLInputElement> = React.useRef<HTMLInputElement>(null);
	const ssn2Ref: RefObject<HTMLInputElement> = React.useRef<HTMLInputElement>(null);
	const ssn3Ref: RefObject<HTMLInputElement> = React.useRef<HTMLInputElement>(null);

	React.useEffect(() => {
		const ssnInputEl1 = ssn1Ref.current;
		const maxLength1 = ssnInputEl1 && ssnInputEl1.maxLength;

		const ssnInputEl2 = ssn2Ref.current;
		const maxLength2 = ssnInputEl2 && ssnInputEl2.maxLength;

		const ssnInputEl3 = ssn3Ref.current;

		if (document.activeElement === ssnInputEl1 &&
			patientSSNCurrentValue1 &&
			patientSSNCurrentValue1.length === maxLength1 &&
			ssnInputEl2)
		{
			ssnInputEl2.focus();
		}

		if (document.activeElement === ssnInputEl2 &&
			patientSSNCurrentValue2 &&
			patientSSNCurrentValue2.length === maxLength2 &&
			ssnInputEl3)
		{
			ssnInputEl3.focus();
		}

	}, [patientSSNCurrentValue1, patientSSNCurrentValue2, patientSSNCurrentValue3]);

	const handlePasteToFirstSSNField = (event: ClipboardEvent) => {
		//prevent event from reaching onChange listener because we are handling change of state explicitly in this function
		event.preventDefault();
		const pasteData = event.clipboardData ? event.clipboardData.getData('text') : '';
		const trimmedData = pasteData.replace(/\s+|-+/g, '');
		//allow only pasting 4 or 9 digits
		if (/^\d{9}$|^\d{4}$/g.test(trimmedData)) {
			//valid paste, clear errors and populate all the fields
			clearErrorMessage();
			if (trimmedData.length == 4) {
				patientSSNSetCurrentValue3(trimmedData);
			}
			else {
				patientSSNSetCurrentValue1(trimmedData.slice(0, 3));
				patientSSNSetCurrentValue2(trimmedData.slice(3, 5));
				patientSSNSetCurrentValue3(trimmedData.slice(5, 9));
			}
		}
		else {
			setErrorMessage('InvalidPaste', INVALID_SSN_LONG);
		}
	};

	React.useLayoutEffect(() => {
		const ssn1Current = ssn1Ref.current;

		if (ssn1Current) {
			ssn1Current.addEventListener('paste', handlePasteToFirstSSNField);

			return () => {
				ssn1Current.removeEventListener('paste', handlePasteToFirstSSNField);
			};
		}
	});

	const [showSSNMask, setShowSSNMask] = React.useState(true);

	const getMaskedValue = (val: string) => {
		return '*'.repeat(val.length);
	};

	const getFullSSN = React.useCallback(() => {
		const fullSSN = `${patientSSNCurrentValue1}${patientSSNCurrentValue2}${patientSSNCurrentValue3}`;

		if (fullSSN.length === 9) {
			return `${patientSSNCurrentValue1}-${patientSSNCurrentValue2}-${patientSSNCurrentValue3}`;
		} else {
			return fullSSN;
		}
	}, [patientSSNCurrentValue1, patientSSNCurrentValue2, patientSSNCurrentValue3]);

	const handleValidationAndBlur = React.useCallback(() => {
		const fullSSN = getFullSSN();

		patientFullSSNSetCurrentValue(fullSSN);

		patientSSNTriggerSelfValidation1();
		patientSSNTriggerSelfValidation2();
		patientSSNTriggerSelfValidation3();

		setShowSSNMask(true);
	}, [getFullSSN, patientFullSSNSetCurrentValue, patientSSNTriggerSelfValidation1, patientSSNTriggerSelfValidation2, patientSSNTriggerSelfValidation3]);

	const [touched, setTouched] = React.useState(false);
	const [isActive, setIsActive] = React.useState(false);
	const handleFocus = () => {
		setShowSSNMask(false);
		setIsActive(true);
	};
	const handleBlur = () => {
		setTouched(true);
		setIsActive(false);
	};

	React.useEffect(() => {
		if (!touched) { return; }

		if (touched && !isActive) {
			handleValidationAndBlur();
		}
	}, [touched, isActive, handleValidationAndBlur]);

	const {
		marginBottomZero,
		ssn1,
		ssn2,
		ssn3,
		dash,
		container,
		ssnErrorOrAssistiveArea,
		assistiveText
	} = useStyles();

	const shouldDisplayErrorMessage = useMemo(() => (patientSSNError1 || patientSSNError2 || patientSSNError3 || patientFullSSNError)
		, [patientSSNError1, patientSSNError2, patientSSNError3, patientFullSSNError]);

	return (
		<Flex flexDirection="column" data-field="patientSSNContainer">
			<Label>SSN</Label>
			<Flex alignItems="stretch" className={container}>
				<Box data-field="patientSSN1" className={ssn1}>
					<TextFieldInput
						className={marginBottomZero}
						placeholder={patientSSNPlaceholder1.slice(0, 3)}
						id="patientSSN1"
						name="patientSSN1"
						fieldName="patientSSN1"
						value={ patientSSNCurrentValue1 && showSSNMask ? getMaskedValue(patientSSNCurrentValue1) : patientSSNCurrentValue1}
						onChange={patientSSNSetCurrentValue1}
						inputProps={{
							maxLength: 3
						}}
						inputRef={ssn1Ref}
						onFocus={handleFocus}
						onBlur={handleBlur}
					/>
				</Box>
				<Flex className={dash}>
					<Text>-</Text>
				</Flex>
				<Box data-field="patientSSN2" className={ssn2}>
					<TextFieldInput
						className={marginBottomZero}
						placeholder={patientSSNPlaceholder2.slice(0, 2)}
						id="patientSSN2"
						name="patientSSN2"
						fieldName="patientSSN2"
						value={ patientSSNCurrentValue2 && showSSNMask ? getMaskedValue(patientSSNCurrentValue2) : patientSSNCurrentValue2}
						onChange={patientSSNSetCurrentValue2}
						inputRef={ssn2Ref}
						onFocus={handleFocus}
						onBlur={handleBlur}
						inputProps={{
							maxLength: 2
						}}
					/>
				</Box>
				<Flex className={dash}>
					<Text>-</Text>
				</Flex>
				<Box data-field="patientSSN3" className={ssn3}>
					<TextFieldInput
						className={marginBottomZero}
						placeholder={patientSSNPlaceholder3}
						id="patientSSN3"
						name="patientSSN3"
						fieldName="patientSSN3"
						value={patientSSNCurrentValue3}
						onChange={patientSSNSetCurrentValue3}
						inputProps={{
							maxLength: 4
						}}
						inputRef={ssn3Ref}
						onFocus={handleFocus}
						onBlur={handleBlur}
					/>
				</Box>
			</Flex>

			<Box data-field="ssnErrorOrAssistiveArea" className={ssnErrorOrAssistiveArea}>
				{ shouldDisplayErrorMessage ?
					<ErrorMessage errorField={ {
						message: patientSSNError1 || patientSSNError2 || patientSSNError3 || patientFullSSNError
					} }/>
					: <div className={assistiveText}>{ ASSISTIVE_TEXT_MESSAGE }</div>
				}
			</Box>
		</Flex>
	);
}
