import { ErrorStatusState } from '../../interfaces/ErrorStatus/ErrorStatusState';
import { Reducer } from 'redux';
import { ErrorStatusAction } from '../../interfaces/ErrorStatus/ErrorStatusAction';
import { CLEAR_ERROR_STATUS, SET_ERROR_STATUS } from '../actions/types';

const initialState: ErrorStatusState = {
	timestamp: undefined,
	status: undefined,
	errorCode: undefined,
	message: undefined
};

export const ErrorStatusReducer: Reducer<ErrorStatusState, ErrorStatusAction> = (
	state: ErrorStatusState = initialState, action: ErrorStatusAction
) => {
	switch (action.type) {
	case SET_ERROR_STATUS:
		return action.payload;
	case CLEAR_ERROR_STATUS:
		return initialState;
	}
	return state;
};
