import React from 'react';
import { SurgeryProcedureInterface } from '../../RequestForm/SurgeryInformation/SurgeryInformationInterface';
import { DropdownOption } from '../../../definitions/DropdownOption';
import { defaultDropdownOptionsIndexed } from './procedureOptions';
import { useSelector } from 'react-redux';
import { Surgeon } from '../../../data/surgeon/Surgeon';
import { RootState } from '../../../interfaces/rootState';
import { getFormattedName } from '../../../utilities/commonFunction';

const defaultAdditionalOptions: DropdownOption[] = [];

const useProcedureFormOptions = (
	surgeonsSelector: (r: RootState) => Surgeon[],
	additionalOptions: DropdownOption[] = defaultAdditionalOptions,
) => {
	const surgeonList = useSelector(surgeonsSelector);

	const surgeonOptions: DropdownOption[] = React.useMemo(() => {
		if (!surgeonList || !surgeonList.length) { return []; }
		const formatted: DropdownOption[] = surgeonList.map((item: Surgeon) => ({
			value: item.id,
			label: getFormattedName(item),
		}));
		return formatted;
	}, [surgeonList]);

	const [availableOptions, setAvailableOptions] = React.useState<Partial<Record<keyof SurgeryProcedureInterface, DropdownOption[]>>>(defaultDropdownOptionsIndexed);

	React.useEffect(() => {
		setAvailableOptions((options) => ({
			...options,
			surgeon: Array.prototype.concat(surgeonOptions, additionalOptions),
		}));
	}, [surgeonOptions, setAvailableOptions, additionalOptions]);

	return availableOptions;
};

export default useProcedureFormOptions;
