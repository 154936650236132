import React, { Context } from 'react';
import { MyFiltersFormState } from '../../../../utilities/constants';
import { SaveFiltersContextType, SpecialtiesIndex } from './SaveFiltersProps';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { MyFiltersSelector } from '../../../../store/selectors/MyFiltersSelector';
import { arrayDeepEqual } from '../../../../utilities/commonFunction';
import { PreferredSurgeonsSelector } from '../../../../store/selectors/SurgeonListSelector';
import { formatSpecialtyWithSurgeons } from '../../../../utilities/formUtilities';
import { filterStateEqualToDefault, sortSpecialtyIdsByName } from '../../../../utilities/filtersUtilities';
import { getSurgeonListData } from '../../../../store/actions/SurgeonListActionCreators';
import { getAllUnitListData } from '../../../../store/actions/UnitListActionCreators';
import { MyFiltersDefaultValues } from '../MyFiltersDefaultValues';

const SaveFiltersContext = React.createContext<SaveFiltersContextType | null>(null);

export function useSaveFiltersContext() {
	const context = React.useContext(SaveFiltersContext as Context<SaveFiltersContextType>);
	if (!context) {
		throw new Error('useMyFiltersContext must be used within a SaveFiltersProvider child.');
	}

	return context;
}

export const SaveFiltersProvider: React.FC = ({ children }) => {
	const dispatch = useDispatch();
	const [ formState, setFormState ] = React.useState(MyFiltersFormState.NO_CHANGE);
	const lastSavedFilters = useSelector(MyFiltersSelector);
	const { watch, reset } = useFormContext();
	const formContextValues = watch();
	const initialLoadCompleteRef = React.useRef(false);

	React.useEffect(() => {
		if (!initialLoadCompleteRef.current) {
			reset(lastSavedFilters || MyFiltersDefaultValues);
			initialLoadCompleteRef.current = true;
			return;
		}

		const formContextValuesFlat = Object.values(formContextValues).flat();
		const lastSavedFilterValuesFlat = Object.values(lastSavedFilters).flat();
		const valuesEqualLastSaved = arrayDeepEqual(lastSavedFilterValuesFlat, formContextValuesFlat);

		setFormState(valuesEqualLastSaved ? MyFiltersFormState.NO_CHANGE : MyFiltersFormState.UPDATED);
	}, [ formContextValues, lastSavedFilters, reset ]);

	const disableSaveAndCancel = formState === MyFiltersFormState.NO_CHANGE;

	const isFormStateSameAsDefaultState = React.useMemo(() => {
		return filterStateEqualToDefault(formContextValues);
	}, [formContextValues]);

	const surgeonList = useSelector(PreferredSurgeonsSelector);
	const [sortedSpecialtyIds, setSortedSpecialtyIds] = React.useState<string[]>([]);
	const [specialtyList, setSpecialtyList] = React.useState<SpecialtiesIndex>({});

	const initSurgeons = React.useRef(false);
	React.useEffect(() => {
		if (!initSurgeons.current && (!surgeonList || !surgeonList.length)) {
			initSurgeons.current = true;
			dispatch(getSurgeonListData());
		}
		const specList = formatSpecialtyWithSurgeons(surgeonList);
		setSpecialtyList(specList);
		const sortedIds: string[] = sortSpecialtyIdsByName(specList);
		setSortedSpecialtyIds(sortedIds);
	}, [dispatch, surgeonList]);

	React.useEffect(() => {
		dispatch(getAllUnitListData());
	}, [dispatch]);

	return (
		<SaveFiltersContext.Provider value={{
			disableSaveAndCancel,
			sortedSpecialtyIds,
			specialtyList,
			reset,
			isFormStateSameAsDefaultState
		}}>
			{children}
		</SaveFiltersContext.Provider>
	);
};
