import React, { useContext } from 'react';
import {
	UnitCalendarPageContextType,
} from '@components/UnitCalendarPage/UnitCalendarPageContext/UnitCalendarPageContextType';
import { EventCardDescription } from '@components/UnitCalendarPage/UnitCalendarPageContext/EventCardDescriptionType';
import useUnitEvents from '@utilities/hooks/useUnitEvents/useUnitEvents';
import getORCalEventParser from '@components/UnitCalendarPage/utilities/getORCalEventParser';
import ArrayFilter from '@interfaces/ArrayFilter';
import UnitMinimal from '@interfaces/Unit/UnitMinimal';
import usePreferredUnits from '@components/UnitCalendarPage/utilities/usePreferredUnits';
import UnitEventHookResult from '@data/openCalendar/UnitEventHookResult';
import FormProviderProps from '@components/FormContextProvider/FormProviderProps';
import UnavailableEventFormProvider
	from '@components/UnitCalendarPage/UnavailableEventFormProvider/UnavailableEventFormProvider';
import DEFAULT_SELECTED_EVENT from '@components/UnitCalendarPage/UnitCalendarPageContext/DefaultSelectedEvent';
import useSelectedEventStyles from '@components/UnitCalendarPage/utilities/useSelectedEventStyles';
import { ErrorStatusState } from '@interfaces/ErrorStatus/ErrorStatusState';
import { AxiosError } from 'axios';

const UnitCalendarPageContext = React.createContext<UnitCalendarPageContextType | null>(null);

export const useUnitCalendarPageContext = () => {
	const ctx = useContext(UnitCalendarPageContext);

	if (!ctx) {
		throw new Error('useUnitCalendarPageContext must be used in a sub component of UnitCalendarPageContextProvider');
	}

	return ctx;
};

const isUnitOpenTimeEnabled: ArrayFilter<UnitMinimal> = (unit) => unit.openTimeConfig || unit.openTimeConfig === undefined;

const errorParser = (e: AxiosError<ErrorStatusState>) => e.response?.data.message || 'Error retrieving data';

export const UnitCalendarPageContextProvider: React.FC<Pick<FormProviderProps, 'children'>> = ({ children }) => {
	const classes = useSelectedEventStyles();
	const { list: units, indexedById: unitsById } = usePreferredUnits(isUnitOpenTimeEnabled);

	const [selectedEventId, setSelectedEventId] = React.useState<string>('');
	const [selectedDate, setSelectedDate] = React.useState<Date>(new Date());
	const [selectedEventDescription, setSelectedEventDescription] = React.useState<EventCardDescription>(DEFAULT_SELECTED_EVENT);
	const [selectedUnitId, setSelectedUnitId] = React.useState<string>('');
	const eventParser = React.useMemo(() => getORCalEventParser(classes.overlap, classes.unavailableOverlap), [classes]);
	const [selectedRoom, setSelectedRoom] = React.useState<string>();

	const selectedUnit = unitsById[selectedUnitId] || units[0];

	const unitEventProps = useUnitEvents(selectedDate, selectedUnit, eventParser, { errorParser });

	// Use Omit to ensure unitEventProps unintentionally overwritten due to same name
	const additionalContextProps: Omit<UnitCalendarPageContextType, keyof UnitEventHookResult> = {
		selectedEventId,
		setSelectedEventId,
		selectedDate,
		setSelectedDate,
		selectedUnit,
		setSelectedUnitId,
		selectedRoom,
		setSelectedRoom,
		units,
		selectedEventDescription,
		setSelectedEventDescription,
	};

	return (
		<UnitCalendarPageContext.Provider value={{
			...unitEventProps,
			...additionalContextProps,
		}}>
			<UnavailableEventFormProvider>
				{children}
			</UnavailableEventFormProvider>
		</UnitCalendarPageContext.Provider>
	);
};
