import React from 'react';
import { Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import EventCard from '@components/EventCard/EventCard';
import { EventInfo } from '@calendar';
import { HospitalEventStyleConfig } from '@components/UnitCalendarPage/HospitalEventStyleConfig';
import classNames from 'classnames';
import EventCardContent from '@components/EventCard/EventCardContent';
import { HospitalCalendarEventConfig } from '@components/UnitCalendarPage/HospitalCalendarEventConfig';
import OREventType from '@data/openCalendar/OREventType';

const useStyles = makeStyles((theme) => createStyles({
	card: {
		...HospitalEventStyleConfig.WARNING.card as Record<string, unknown>,
		position: 'relative',
	},
	iconBox: {
		backgroundColor: theme.palette.error.main,
		width: '32px',
		height: '32px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '4px',
		position: 'absolute',
		top: '-1px',
		right: '-1px',
	},
	icon: {
		color: theme.palette.common.white,
	},
}));

const WarningEventCard: React.FC<EventInfo> = (event: EventInfo) => {
	const classes = useStyles();

	const {
		additionalClassName = '',
		title,
		subTitle,
		timeSpanDisplay,
	} = event;

	const Icon = HospitalCalendarEventConfig[OREventType.WARNING].icon;

	return (
		<EventCard
			{...event}
			additionalClassName={classNames(classes.card, {
				[additionalClassName]: !!additionalClassName
			})}
		>
			<Box className={classes.iconBox}>
				{Icon && <Icon className={classes.icon}/>}
			</Box>
			<EventCardContent title={title} subTitle={subTitle} timeSpan={timeSpanDisplay}/>
		</EventCard>
	);
};

export default WarningEventCard;
