import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ErrorRounded } from '@material-ui/icons';
import GoldButton from '../../../GoldButton/GoldButton';
import { useManageUsersListContext } from '../../ManageUsersListContext/ManageUsersListContext';

const useStyles = makeStyles(theme => ({
	errorIcon: {
		color: theme.palette.error.main,
		marginRight: theme.spacing(1.5),
		paddingTop: '2px',
	},
	titleContainer: {
		'marginLeft': theme.spacing(5),
		'marginRight': theme.spacing(5),
		'display': 'flex',
		'alignItems': 'flex-start',
		'& .MuiTypography-h3': {
			fontSize: '24px',
			fontWeight: 400,
		},
	},
	message: {
		margin: theme.spacing(3, 5, 0, 5),
		fontWeight: 350,
		lineHeight: '21.6px',
		letterSpacing: '0.15px',
	},
	buttonContainer: {
		'height': '48px',
		'display': 'flex',
		'justifyContent': 'flex-end',
		'margin': theme.spacing(5.75, 4, 5, 0),
		'& .MuiButton-root': {
			height: '100%',
		},
	},
}));

const DeactivateSelfContent: React.FC = () => {
	const classes = useStyles();

	const { resetInteractionState } = useManageUsersListContext();

	return (
		<>
			<Box display="flex" className={classes.titleContainer}>
				<ErrorRounded className={classes.errorIcon}/>
				<Typography variant="h3">You do not have authorization to deactivate your account</Typography>
			</Box>
			<Typography className={classes.message}>Please see your practice administrator to deactivate your account.</Typography>
			<Box className={classes.buttonContainer}>
				<GoldButton onClick={resetInteractionState} data-field="deactivate-modal-okay-btn">OKAY</GoldButton>
			</Box>
		</>
	);
};

export default DeactivateSelfContent;
