import React, { ReactNode } from 'react';
import { clearToastStatus } from '../../store/actions/ToastStatusActionCreators';
import { useDispatch } from 'react-redux';
/***
 * ModalContentWrapper wraps the content of the Modal element from Ascension Web components (aka Athlon Components)
 * in order to clear lingering toast when route has not changed, but modal has been opened and Toast no longer should be displayed.
 * @param children - children elements/components of the ModalContentWrapper
 */
const ModalContentWrapper = ({ children }: {children: ReactNode}) =>{
	const dispatch = useDispatch();

	//clear Toast when component is mounted - when Modal opens. This is to prevent old/obsolete toast from lingering
	React.useEffect(() =>{
		dispatch(clearToastStatus());
	}, [dispatch]);

	return ( <> { children } </>);
};

export default ModalContentWrapper;
