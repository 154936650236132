import React, { useContext } from 'react';
import { AxiosResponse } from 'axios';
import { FindATimeContextType } from '@components/FindATime/FindATimeContext/FindATimeContextType';
import { FindATimeResponse } from '@data/findATime/types/FindATimeResponse';
import { useGetFindATime } from '@data/findATime/hooks/useGetFindATime';
import { useFindATimeRequest } from '@components/FindATime/utilities/useFindATimeRequest/useFindATimeRequest';
import { SelectedDateAndTime } from '@data/findATime/types/SelectedDateAndTime';

const FindATimeContext = React.createContext<null | FindATimeContextType>(null);
FindATimeContext.displayName = 'FindATimeContext';

export const useFindATimeContext = (): FindATimeContextType => {
	const ctx = useContext(FindATimeContext);
	if (!ctx) {
		throw new Error('useFindATimeContext must be used within a child component of FindATimeProvider');
	}
	return ctx;
};

export const FindATimeProvider: React.FC = ({ children }) => {
	const [accordionExpanded, setAccordionExpanded] = React.useState<boolean>(false);
	const [findATimeResponse, setFindATimeResponse] = React.useState<FindATimeResponse>();
	const [selectedDateAndTime, setSelectedDateAndTime] = React.useState<SelectedDateAndTime>();
	const [selectedTimeSlot, setSelectedTimeSlot] = React.useState<string>('');
	const [durationFallback, setDurationFallback] = React.useState<string>();
	const lastSelectedTimeSlot = React.useRef<string>('');

	const getFindATimeResponse = useGetFindATime();

	const findATimeRequest = useFindATimeRequest(durationFallback);

	const submitFindATimeSearch = React.useCallback(() => {
		setSelectedTimeSlot('');
		getFindATimeResponse({
			...findATimeRequest,
			onSuccess: (response: AxiosResponse<FindATimeResponse>) => {
				setFindATimeResponse(response.data);
			},
		});
	}, [getFindATimeResponse, findATimeRequest]);

	return (
		<FindATimeContext.Provider value={{
			accordionExpanded,
			durationFallback,
			findATimeResponse,
			lastSelectedTimeSlot,
			selectedDateAndTime,
			selectedTimeSlot,
			setAccordionExpanded,
			setDurationFallback,
			setFindATimeResponse,
			setSelectedDateAndTime,
			setSelectedTimeSlot,
			submitFindATimeSearch,
		}}>
			{children}
		</FindATimeContext.Provider>
	);
};
