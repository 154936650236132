import React from 'react';

import {
	Text,
	Box, Flex
} from '@ascension/web';
import { CaseTrackerSectionProps } from './CaseTrackerSectionProps';
import SearchComponent from '../SearchComponent/SearchComponent';
import useHasPermission from '@utilities/hooks/useHasPermission/useHasPermission';
import { PermittedAction } from '@utilities/PermittedAction';

export const CaseTrackerSection = ({ sectionTitle, children, ...rest }: CaseTrackerSectionProps) => {
	const canViewByCaseNumber = useHasPermission(PermittedAction.VIEW_CONDENSED);
	const placeholder = `Patient last, first name${canViewByCaseNumber ? ', FIN or EHR Case Number' : ' or FIN'}`;

	return (
		<Flex justifyContent="center" width={1} alignItems="center" flexDirection="column" >
			<Box mt={14} {...rest}>
				{sectionTitle &&
				<Flex width={1} alignItems="start" mb={8}>
					<Flex flexDirection="row" data-field="Data_Information">
						<Text className="section-title" fontSize={24} fontWeight="550">
							{sectionTitle}
						</Text>
					</Flex>
					<Box flex="1" textAlign="right">
						<Box width={465} textAlign="right" className="search-container">
							<SearchComponent hasMaxValue={false} placeholder={placeholder}/>
						</Box>
					</Box>
				</Flex>
				}
				{children}
			</Box>
		</Flex>
	);
};
