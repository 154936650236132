import { useFormContext } from 'react-hook-form';
import { format } from 'date-fns-tz';
import React from 'react';
import { FindATimeRequestParams } from '@data/findATime/types/FindATimeRequestParams';
import CommonRequestForm from '@interfaces/RequestForm/forms/CommonRequestForm';
import useGetPrimarySurgeonNpi
	from '@components/RequestForm/utilities/hooks/useCheckBlockTimeOverlap/useGetPrimarySurgeonNpi/useGetPrimarySurgeonNpi';
import useGetSelectedUnitInfo
	from '@components/RequestForm/utilities/hooks/useCheckBlockTimeOverlap/useGetSelectedUnitInfo/useGetSelectedUnitInfo';
import { FindATimeRequest } from '@data/findATime/types/FindATimeRequest';
import { useProcedureNameContext } from '@components/RequestForm/ProcedureName/ProcedureNameContext';
import { FindATimeForm } from '@components/FindATime/FindATimeForm';
import { DAYS_OF_WEEK_NUMBERS } from '@utilities/constants';

export type FindATimeRequestFormState = Pick<CommonRequestForm,
	'procedureDate' |
	'duration' |
	'room'
>;

const validate = (params: FindATimeRequestParams): boolean => {
	return !!params
		&& !!params.hospitalId
		&& !!params.unitId
		&& !!params.rooms
		&& !!params.startDate
		&& !!params.endDate
		&& !!params.startTime
		&& !!params.endTime
		&& !!params.dow
		&& !!params.duration
		&& !!params.npi;
};
/**
 * useFindATimeRequest - gathers form data and builds a FindATimeRequest.
 * This can be shared by other pages/forms because default request params
 * are provided where optional form inputs may not be present. <br /><p />
 *
 * This hook is shared between the RequestFormPage and FindATimePage,
 * so be aware when modifying.
 *
 * @returns {FindATimeRequest} request with params derived from form inputs.
 * Check *`isValid`* for your logic (enable a button, etc.) before submitting.
 */
export const useFindATimeRequest = (durationFallback: string | undefined): FindATimeRequest => {
	const { watch } = useFormContext<FindATimeRequestFormState | FindATimeForm>();
	const [procedureDate, duration, startDate, endDate, startTime, endTime, dow] = watch([
		'procedureDate',
		'duration',
		'startDate',
		'endDate',
		'startTime',
		'endTime',
		'dow'
	]);
	//watches primarySurgeon for us
	const primarySurgeonNpi = useGetPrimarySurgeonNpi();
	//watches appointmentLocation for us
	const selectedUnitInfo = useGetSelectedUnitInfo();
	const { selectedProcedure } = useProcedureNameContext();

	return React.useMemo((): FindATimeRequest => {
		const selectedDateString = procedureDate && format(procedureDate, 'yyyy-MM-dd');

		const startDateValue = startDate ? format(startDate, 'yyyy-MM-dd') : selectedDateString;
		const endDateValue = endDate ? format(endDate, 'yyyy-MM-dd') : selectedDateString;

		const durationInt = duration ? parseInt(duration) : durationFallback ? parseInt(durationFallback) : 60;
		const durationParam = isNaN(durationInt) ? 60 : durationInt;

		const daysOfWeek = dow && dow.length > 0 ? dow : DAYS_OF_WEEK_NUMBERS;

		const fatRequestParams: FindATimeRequestParams = {
			hospitalId: selectedUnitInfo?.hospital,
			unitId: selectedUnitInfo?.id,
			rooms: selectedProcedure?.roomName || [],
			startDate: startDateValue, // 'yyyy-MM-dd', '2024-04-16'
			endDate: endDateValue,   // 'yyyy-MM-dd', '2024-04-16'
			startTime: startTime || selectedUnitInfo?.scheduleConfig?.start, // 'HH:mm' - '14:00' to represent '02:00 PM'
			endTime: endTime || selectedUnitInfo?.scheduleConfig?.end,   // 'HH:mm' - '14:00' to represent '02:00 PM'
			dow: daysOfWeek,
			duration: durationParam,
			npi: primarySurgeonNpi,
		};
		const isValid = validate(fatRequestParams);

		return {
			data: fatRequestParams,
			isValid,
		};
	}, [
		procedureDate,
		startDate,
		endDate,
		startTime,
		endTime,
		duration,
		durationFallback,
		dow,
		selectedUnitInfo?.hospital,
		selectedUnitInfo?.id,
		selectedUnitInfo?.scheduleConfig?.start,
		selectedUnitInfo?.scheduleConfig?.end,
		selectedProcedure?.roomName,
		primarySurgeonNpi
	]);
};
