import React, { ChangeEvent } from 'react';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import { useFieldFormContext } from '../../../../utilities/hooks/useFieldFormContext/useFieldFormContext';
import UsernameInput from './UsernameInput/UsernameInput';
import ErrorMessageMui from '../../../ErrorMessageMui/ErrorMessageMui';
import { BasicUserInfoFormValidationSchema } from '../BasicUserInfoFormValidationSchema';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	container: {
		'& .MuiRadio-root': {
			padding: theme.spacing(0.5),
		},
		'marginLeft': theme.spacing(1),
		'marginTop': theme.spacing(1),
	},
	form: {
		'& .MuiFormLabel-root': {
			fontWeight: 600,
		},
	},
}));

const mapStringToNumber: Record<string, number> = {
	No: 0,
	Yes: 1,
	Unsure: 2,
};

const numToString = ['No', 'Yes', 'Unsure'];

const AccountInformation: React.FC = () => {
	const classes = useStyles();
	const {
		currentValue = null,
		setCurrentValue,
		error,
	} = useFieldFormContext<number | null>('hasAscensionUsername', {
		validationRules: BasicUserInfoFormValidationSchema.hasAscensionUsername,
		triggerValidationOnChange: true,
	});

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setCurrentValue(mapStringToNumber[e.target.value]);
	};

	return (
		<FormControl className={classes.form}>
			<FormLabel required id="account-info-radio-buttons-group" error={!!error}>Does the user have an Ascension username (also known as the Ascension Active Directory ID)?</FormLabel>
			<RadioGroup
				value={typeof currentValue === 'number' ? numToString[currentValue] : ''}
				onChange={handleChange}
				aria-labelledby="account-info-radio-buttons-group"
				aria-describedby="account-info-error"
				className={classes.container}
			>
				<FormControlLabel value="Yes" control={<Radio color="primary"/>} label={<Typography><strong>Yes</strong>, the user has an Ascension username</Typography>}/>
				<UsernameInput hasAscensionUsername={currentValue}/>
				<FormControlLabel value="No" control={<Radio color="primary"/>} label={<Typography><strong>No</strong>, the user does not have an Ascension username</Typography>} />
				<FormControlLabel value="Unsure" control={<Radio color="primary"/>} label={<Typography><strong>Unsure</strong> if the user has an Ascension username</Typography>} />
			</RadioGroup>
			{ error && <span id="account-info-error"><ErrorMessageMui errorMessage={error} /></span> }
		</FormControl>
	);
};

export default AccountInformation;
