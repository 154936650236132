import React from 'react';
import { Box, InputLabel, MenuItem, MenuProps } from '@material-ui/core';
import { alpha, createStyles, makeStyles } from '@material-ui/core/styles';
import { Dropdown } from '@ascension/ui-library';
import { DOCUMENT_TYPE_PREFIX } from '@components/PdfViewerPage/IndexingSection/utilities/getFormStateFromIndexes';
import { documentTypeOptions } from '@components/PdfViewerPage/IndexingSection/EditIndexes/inputs/DocumentTypeOptions';
import ErrorMessageMui from '@components/ErrorMessageMui/ErrorMessageMui';
import { useFieldFormContext } from '@utilities/hooks/useFieldFormContext/useFieldFormContext';
import { documentTypeValidation } from '@utilities/Validation/validationSchema';

const useStyles = makeStyles(theme => {
	return createStyles({
		dropdownContainer: {
			'& .MuiAscensionDropdown-formControlDefault': {
				margin: theme.spacing(1, 0, 0),
				width: '164px',
				minWidth: '164px',
			},
			'& .MuiOutlinedInput-input': {
				padding: theme.spacing(1.9, 3, 1.9, 1.9),
				background: theme.palette.common.white,
			},
			'& .MuiOutlinedInput-notchedOutline': {
				top: 0,
			},
			'& .MuiInputLabel-outlined': {
				transform: 'translate(15px, 16px)',
				color: theme.palette.common.black
			},
			'& .MuiFormHelperText-root.Mui-error': {
				display: 'none'
			},
			'& label.MuiInputLabel-root': {
				'&.MuiFormLabel-filled': {
					color: theme.palette.grey[400],
					fontSize: '1rem',
				},
			},
			'&  .MuiOutlinedInput-notchedOutline>legend': {
				display: 'none',
			},
		},
		labelText: {
			color: alpha(theme.palette.common.black, .6),
			fontWeight: theme.typography.fontWeightMedium,
			fontSize: '1rem',
			lineHeight: 1.5,
			display: 'flex',
			alignItems: 'end'
		},
		paper: {
			// The !important declaration is the only way to override inline styles from this class selector
			transition: 'none !important',
		},
	});
});

export interface DocumentTypeProp {
	id: string;
}
const DocumentTypeSelect: React.FC<DocumentTypeProp> = ({ id }) => {
	const { currentValue, setCurrentValue, error, triggerSelfValidation } =
		useFieldFormContext(`${DOCUMENT_TYPE_PREFIX}${id}`, {
			validationRules: documentTypeValidation
		});

	const classes = useStyles();

	const menuProps = {
		classes: {
			paper: classes.paper,
		},
		// cast to MenuProps to workaround bug in ui-library requiring open prop
	} as Partial<MenuProps> as MenuProps;

	const handleInput = (selected: string) => {
		setCurrentValue(selected);
		void triggerSelfValidation();
	};

	return (
		<Box className={classes.dropdownContainer}>
			<InputLabel
				required={true}
				className={classes.labelText}
				htmlFor={`documentTypeInput-${id}`}
			>
				Document type
			</InputLabel>
			<Dropdown
				id={`documentTypeInput-${id}`}
				className={classes.dropdownContainer}
				value={ typeof currentValue === 'string' ? currentValue : '' }
				label={!currentValue? 'Select type' : ''}
				onBlur={triggerSelfValidation}
				select={handleInput}
				translate="yes"
				error={!!error}
				MenuProps={menuProps}
				variant="outlined"
			>
				{documentTypeOptions.map(({ key, value }) =>
					<MenuItem key={key} value={value}>{key}</MenuItem>
				)};
			</Dropdown>
			<ErrorMessageMui errorMessage={error} />
		</Box>
	);
};

export default DocumentTypeSelect;
