import React from 'react';
import FormContextProvider from '@components/FormContextProvider/FormContextProvider';
import FormPropsChildren from '@interfaces/FormPropsChildren';
import UnavailableEventFormType from '@components/UnitCalendarPage/utilities/UnavailableEventFormType';
import defaultUnavailableEventFormValues from '@components/UnitCalendarPage/utilities/defaultUnavailableEventFormValues';
import { useFormContext } from 'react-hook-form';
import getMidnightLocal from '@calendar/utilities/getMidnightLocal/getMidnightLocal';
import {
	useUnitCalendarPageContext
} from '@components/UnitCalendarPage/UnitCalendarPageContext/UnitCalendarPageContext';
import { add } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { TIME_ONLY_FORMAT } from '@utilities/constants';

const UnavailableEventFormProviderInner: React.FC = ({ children }) => {
	const { reset } = useFormContext<UnavailableEventFormType>();
	const {
		selectedDate,
		selectedUnit: { id: selectedUnitId, hospital: hospitalId },
		unitConfig: { hospitalTimeZone },
		selectedEventId,
		selectedRoom,
	} = useUnitCalendarPageContext();

	// Automatically update form when calendar view changes
	React.useEffect(() => {
		if (selectedRoom) { return; }
		const startTime = getMidnightLocal(hospitalTimeZone, selectedDate);
		const endTime = add(startTime, { hours: 24 });
		reset({
			...defaultUnavailableEventFormValues,
			date: selectedDate,
			timeSpan: {
				start: formatInTimeZone(startTime, hospitalTimeZone, TIME_ONLY_FORMAT),
				end: formatInTimeZone(endTime, hospitalTimeZone, TIME_ONLY_FORMAT),
			},
			hospitalId,
			unitId: selectedUnitId,
			id: selectedEventId,
		});
	}, [hospitalId, hospitalTimeZone, reset, selectedDate, selectedEventId, selectedRoom, selectedUnitId]);

	return <>{children}</>;
};

const UnavailableEventFormProvider: React.FC<FormPropsChildren> = ({ children }) => {
	return <FormContextProvider<UnavailableEventFormType> defaultValues={defaultUnavailableEventFormValues}>
		<UnavailableEventFormProviderInner>
			{children}
		</UnavailableEventFormProviderInner>
	</FormContextProvider>;
};

export default UnavailableEventFormProvider;
