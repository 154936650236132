import React from 'react';
import { useCalendarContext } from '@calendar';
import ColumnHeader from '@calendar/components/ColumnHeader/ColumnHeader';
import { COLUMN_OFFSET } from '@calendar/utilities/CalendarConstants';
import useHeaderHeightSetWithRef from '@calendar/utilities/useSetHeaderHeightWithRef/useSetHeaderHeightWithRef';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	columnHeader: {
		backgroundColor: 'white',
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(1),
		gridColumnEnd: 'span 1',
		gridRow: '1',
		justifyContent: 'end',
		position: 'sticky',
		top: 0,
		zIndex: 1000,
	},
	columnHeaderCenter: {
		justifyContent: 'center',
	},
}));

const ColumnHeaders: React.FC = () => {
	const { columns } = useCalendarContext();
	const { getElmAdderFor } = useHeaderHeightSetWithRef();
	const hasLabels = columns.some(c => c?.labels?.length);
	const classes = useStyles();

	return <>
		{columns.map((c, index) => (
			<div key={ c.id }
				style={{ gridColumnStart: index + COLUMN_OFFSET }}
				className={`${classes.columnHeader} ${!hasLabels ? classes.columnHeaderCenter : '' }`}
				ref={getElmAdderFor(c.id)}
				data-field="columnHeader"
			>
				<ColumnHeader
					id={c.id}
					labels={c.labels}
					name={c.name}
				/>
			</div>
		))}
	</>;
};

export default ColumnHeaders;
