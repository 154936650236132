import React from 'react';
import { useFormContext } from 'react-hook-form';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import EventCard from '@components/EventCard/EventCard';
import { EventInfo } from '@calendar';
import { HospitalEventStyleConfig } from '@components/UnitCalendarPage/HospitalEventStyleConfig';
import { HospitalCalendarEventConfig } from '@components/UnitCalendarPage/HospitalCalendarEventConfig';
import OREventType from '@data/openCalendar/OREventType';
import EventCardContentWithIcon from '@components/EventCard/EventCardContentWithIcon';
import {
	useUnitCalendarPageContext
} from '@components/UnitCalendarPage/UnitCalendarPageContext/UnitCalendarPageContext';
import useSelectedEventStyles from '@components/UnitCalendarPage/utilities/useSelectedEventStyles';

const useStyles = makeStyles((theme) => createStyles({
	card: {
		...HospitalEventStyleConfig.UNAVAILABLE.card as Record<string, unknown>,
		paddingLeft: theme.spacing(0.5),
	},
}));

const UnavailableEventCard: React.FC<EventInfo> = (event: EventInfo) => {
	const { selectedEventDescription: { eventInfo }, setSelectedEventDescription } = useUnitCalendarPageContext();
	const { watch } = useFormContext();
	const editUnavailableEventFormData = watch();
	const selected = eventInfo?.id === event.id || editUnavailableEventFormData?.id === event.id;
	const classes = useStyles();
	const { selected: selectedClassName } = useSelectedEventStyles();
	const {
		additionalClassName = '',
		timeSpanDisplay,
	} = event;

	const Icon = HospitalCalendarEventConfig[OREventType.UNAVAILABLE].icon;

	return (
		<EventCard
			{...event}
			displayWhenZero={true}
			additionalClassName={classNames(classes.card, {
				[additionalClassName]: !!additionalClassName,
				[selectedClassName]: selected,
			})}
			onCardClick={setSelectedEventDescription}
		>
			<EventCardContentWithIcon title="Unavailable" timeSpan={timeSpanDisplay} icon={Icon && <Icon/>} />
		</EventCard>
	);
};

export default UnavailableEventCard;
