import React from 'react';
import {
	Box,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography
} from '@material-ui/core';
import { BackToHomeLink } from '../AppPage/BackToHomeLink/BackToHomeLink';
import { makeStyles } from '@material-ui/core/styles';
import {
	ManageUsersListContextProvider,
	useManageUsersListContext
} from './ManageUsersListContext/ManageUsersListContext';
import { LARGE_MAX_WIDTH, PAGE_WIDTH } from '../../theme/themeConstants';
import { useAppPageBodyClassSetter } from '../AppPage/AppPageContext/AppPageContext';
import { useHistory } from 'react-router-dom';
import { Add as AddIcon } from '@material-ui/icons';
import BlueOutlineButton from '../BlueOutlineButton/BlueOutlineButton';
import { useSelector } from 'react-redux';
import { UserProfileDetailsSelector } from '../../store/selectors/UserProfileSelector';
import ActionTokenDropdown from './ActionTokenDropdown/ActionTokenDropdown';
import { UserDetailsDrawer } from './UserDetailsDrawer/UserDetailsDrawer';
import { UserStatusChip } from './UserDetailsDrawer/UserStatusChip/UserStatusChip';
import DeactivateUserModal from './DeactivateUserModal/DeactivateUserModal';
import { ManageUsersListHeader } from './ManageUsersListHeader/ManageUsersListHeader';
import { ManagedUser } from './ManageUsersListContext/ManageUsersListContextType';
import { getComparator } from './ManageUsersComparator/ManageUsersComparator';
import { SortOrderType } from './SortOrderType';
import { ROUTE_CONSTANTS } from '@utilities/RouteConstants';

const useStyles = makeStyles((theme) => ({
	pageContainer: {
		'margin': theme.spacing(0, 'auto'),
		'maxWidth': PAGE_WIDTH,
		'& .MuiTypography-h1': {
			fontSize: '3rem',
			lineHeight: '3.5rem',
			marginTop: theme.spacing(1),
		}
	},
	listContainer: {
		'marginTop': theme.spacing(6),
		'& .MuiTypography-h2': {
			padding: theme.spacing(4, 3.75),
			fontSize: '2.125rem',
			fontWeight: 450,
		},
		'whiteSpace': 'normal',
		'wordBreak': 'break-word',
	},
	fullWidth: {
		'& > div': {
			maxWidth: LARGE_MAX_WIDTH,
		},
	},
	tableContainer: {
		'padding': theme.spacing(0, 3.75, 4, 3.75),
		'width': 'unset',
		'& > table > tbody > tr:last-child > .MuiTableCell-root': {
			borderBottom: 'none',
		},
		'& > table > thead > tr > th': {
			fontWeight: 450,
			letterSpacing: '0.17px',
			borderBottomColor: 'rgba(0, 0, 0, 0.12)',
			paddingLeft: theme.spacing(1),
		},
		'& > table > tbody > tr > td': {
			fontWeight: 450,
			letterSpacing: '0.25px',
			borderBottomColor: 'rgba(33, 33, 33, 0.08)',
			padding: theme.spacing(1)
		},
		'& > table > tbody > tr > td:first-child': {
			whiteSpace: 'nowrap',
		},
	},
}));

const ManageUsersListPageInner: React.FC = () => {
	const classes = useStyles();

	const { setBodyClass } = useAppPageBodyClassSetter('ManageUsers');
	const { users } = useManageUsersListContext();
	const history = useHistory();
	const { userId: loggedInUserId } = useSelector(UserProfileDetailsSelector);

	React.useEffect(() => {
		setBodyClass(classes.fullWidth);
	}, [classes, setBodyClass]);

	const [order, setOrder] = React.useState<SortOrderType>(SortOrderType.DESC);
	const [orderBy, setOrderBy] = React.useState<keyof ManagedUser>('dateCreatedNum');
	const handleRequestSort = (property: keyof ManagedUser) => {
		const isDesc = orderBy === property && order === SortOrderType.DESC;
		setOrder(isDesc ? SortOrderType.ASC : SortOrderType.DESC);
		setOrderBy(property);
	};

	const sortedList = React.useMemo(() => {
		return users?.slice().sort(getComparator(order, orderBy)) || [];
	}, [order, orderBy, users]);

	return (
		<Box className={classes.pageContainer}>
			<BackToHomeLink/>
			<Box display="flex" justifyContent="space-between" alignItems="flex-end">
				<Typography variant="h1" component="h1">
					Manage users
				</Typography>
				<Box>
					<BlueOutlineButton
						onClick={() => history.push(ROUTE_CONSTANTS.USER_REGISTRATION)}
						data-field="add-users-btn"
					>
						<AddIcon fontSize="inherit"/>&nbsp;&nbsp;ADD USERS
					</BlueOutlineButton>
				</Box>
			</Box>
			<Paper className={classes.listContainer}>
				<Typography variant="h2" component="h2">
					Users ({users?.length || 0})
				</Typography>
				<TableContainer className={classes.tableContainer}>
					<Table>
						<ManageUsersListHeader orderBy={orderBy} order={order} handleRequestSort={handleRequestSort}/>
						<TableBody>
							{sortedList.map((user) => (
								<TableRow key={user.userId}>
									<TableCell>{user.dateCreated || '--'}</TableCell>
									<TableCell>{user.userRolesStr || '--'}</TableCell>
									<TableCell>{user.lastName || '--'}</TableCell>
									<TableCell>{`${user.firstName}${user.userId === loggedInUserId ? ' (You)' : ''}` || '--'}</TableCell>
									<TableCell>{user.statusStr ? <UserStatusChip status={user.statusStr}/> : '--'}</TableCell>
									<TableCell><ActionTokenDropdown user={user}/></TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
			<UserDetailsDrawer />
			<DeactivateUserModal />
		</Box>
	);
};

const ManageUsersListPage = () => {
	return (
		<ManageUsersListContextProvider>
			<ManageUsersListPageInner />
		</ManageUsersListContextProvider>
	);
};

export default ManageUsersListPage;
