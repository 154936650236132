import { UserStatus } from '../../interfaces/UserProfile/UserStatusEnum';


export const mapStatusToString: Record<UserStatus, string> = {
	[UserStatus.ACTIVE]: 'Active',
	[UserStatus.PENDING_ACTIVE]: 'Pending',
	[UserStatus.LOCK]: 'Locked',
	[UserStatus.PRE_REGISTERED]: 'Pending',
	[UserStatus.DEACTIVATED]: 'Deactivated',
};

export const mapStatusStrToStyle: Record<string, MapStatusStrToStyle> = {
	[mapStatusToString[UserStatus.ACTIVE]]: { borderColor: '#126E36', backgroundColor: '#EEF7EE' },
	[mapStatusToString[UserStatus.PENDING_ACTIVE]]: {  borderColor: '#DB6200', backgroundColor: '#FFF4E6' },
	[mapStatusToString[UserStatus.LOCK]]: {  borderColor: '#E00019', backgroundColor: '#FDECEB' },
	[mapStatusToString[UserStatus.PRE_REGISTERED]]: {  borderColor: '#DB6200', backgroundColor: '#FFF4E6' },
};

interface MapStatusStrToStyle {
	borderColor: string;
	backgroundColor: string;
}
