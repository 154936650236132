import { Box } from '@ascension/web';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';
import PatientNamePartInput from '@components/RequestForm/inputs/PatientNamePartInput/PatientNamePartInput';

const useStyles = makeStyles((theme) => (createStyles({
	root: {
		width: '100%',
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'row',
		columnGap: theme.spacing(2),
	},
	flexItem: {
		marginBottom: theme.spacing(2),
		flex: '1 1 100%',
		[theme.breakpoints.up('sm')]: {
			flex: '1 1 47%',
		},
		[theme.breakpoints.up('lg')]: {
			flex: '1 1 32%',
		},
	},
})));

const FullPatientNameInput = () => {
	const classes = useStyles();

	return <Box className={classes.root}>
		<Box className={classes.flexItem} data-field="patientLastName">
			<PatientNamePartInput
				namePart="Last"
				label="NAME"
				required={true}
			/>
		</Box>
		<Box className={classes.flexItem} alignSelf="flex-end" data-field="patientFirstName">
			<PatientNamePartInput
				namePart="First"
				required={true}
			/>
		</Box>
		<Box className={classes.flexItem} data-field="patientMiddleName">
			<PatientNamePartInput
				namePart="Middle"
				label="(OPTIONAL)"
			/>
		</Box>
	</Box>;
};

export default FullPatientNameInput;
