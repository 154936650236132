import React from 'react';

/**
 * useAsyncUntilValidValue
 * @description
 * Return a promise that resolves when the value is not an invalid value
 * (typically null or undefined). Will NOT continue to emit new values when
 * the value changes.
 * @template T Type of value
 * @param value {T} current value (dynamic)
 * @param invalidValue {T} typically undefined or null - default value that is not possible as an actual value.
 *
 * @returns Promise that resolves with the first value that does not match the invalidValue. Promise does not reject.
 */
export const useAsyncFirstValidValue = <T = unknown>(value: T, invalidValue: T): Promise<T> => {
	const resolveRef = React.useRef<((value: T) => void)>();
	const promiseRef = React.useRef<Promise<T>>(new Promise((resolve) => {
		resolveRef.current = resolve;
	}));

	React.useEffect(() => {
		if (value !== invalidValue) {
			void resolveRef.current?.(value);
		}
	}, [invalidValue, value]);

	return promiseRef.current;
};
