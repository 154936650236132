import { useDispatch } from 'react-redux';
import React from 'react';
import { getDocumentIndexes } from '@data/docIndexes/actionCreators/DocIndexesActionCreators';
import { ApiActionPayload } from '@interfaces/ApiAction/ApiActionPayload';
import { Index } from '@components/PdfViewerPage/IndexingSection/IndexingSectionContext/IndexingSectionContextType';

const useGetDocIndexes = () => {
	const dispatch = useDispatch();

	return React.useCallback((documentId: string, apiActionRequest: Partial<ApiActionPayload<Index[]>>) => {
		dispatch(getDocumentIndexes(documentId, apiActionRequest));
	}, [dispatch]);
};

export default useGetDocIndexes;
