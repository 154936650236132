import React from 'react';
import { Theme, Typography, TypographyProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		minWidth: '158px',
		fontWeight: 600,
		[theme.breakpoints.down('md')]: {
			fontSize: '10px',
			minWidth: '120px',
		},
	},
}));

const CaseActivityDateAndTime: React.FC<Partial<TypographyProps>> = ({ children, className, ...props }) => {
	const classes = useStyles();

	return (
		<Typography
			variant="caption"
			className={classNames(classes.root, className)}
			{...props}
		>
			{children}
		</Typography>
	);
};

export default CaseActivityDateAndTime;
