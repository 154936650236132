import { Box, Link, Typography, BoxProps, createStyles } from '@material-ui/core';
import { AlertBox } from '@ascension/ui-library';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
	helpText: {
		marginTop: theme.spacing(3),
	},
	alertBox: {
		'& .MuiTypography-body1': {
			fontSize: '14px',
		},
	},
}));

interface LoginNotificationBannerProps extends BoxProps {
	notAfter?: Date;
}
const LoginNotificationBanner: React.FC<LoginNotificationBannerProps> = ({ notAfter, ...boxProps }) => {
	const classes = useStyles();

	const disableAlert = React.useMemo(() => {
		return notAfter && notAfter < new Date();
	}, [notAfter]);

	return disableAlert ? null : (<Box {...boxProps}>
		<AlertBox
			severity="info"
			translate="yes"
			leftBorderAccent={true}
			className={classes.alertBox}
		>
			<Typography>
				As part of new Ascension security requirements, <strong>
					on October 13 all SSM users will be required to use Duo Mobile multi-factor authentication (MFA) to
					login to SSM.
				</strong>* If you need assistance setting up Duo or are experiencing issues,
				please fill out <Link href="https://app.smartsheet.com/b/form/e659f145eb40458caddb02fe2b8ceee3"  target="_blank" rel="noopener noreferrer">
				this form</Link> and we will help you as soon as possible.
			</Typography>
			<Typography className={classes.helpText}>
				*If you already use Duo Mobile for access to other Ascension applications, no action is required.
			</Typography>
		</AlertBox>
	</Box>);
};
export default LoginNotificationBanner;
