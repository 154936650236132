import { apiAction } from '@store/middlewares/api/ApiActionCreators';
import endpointTemplateToUrl from '@utilities/endpointTemplateToUrl';
import { ENDPOINT_DOC_INDEX, ENDPOINT_SAVE_DOC_INDEX } from '@utilities/apiConstants';
import ApiService from '@utilities/apiEndpoints/ApiService';
import { GET_DOC_INDEXES, SAVE_DOC_INDEXES } from '@store/actions/types';
import { ApiActionPayload } from '@interfaces/ApiAction/ApiActionPayload';
import { DocumentIndexPayload } from '@data/docIndexes/types/DocumentIndexPayload';
import { Index } from '@components/PdfViewerPage/IndexingSection/IndexingSectionContext/IndexingSectionContextType';

export const getDocumentIndexes = (documentId: string, apiActionRequest: Partial<ApiActionPayload<Index[]>>) => {
	const url = endpointTemplateToUrl(ENDPOINT_DOC_INDEX, { documentId });
	return apiAction<Index[]>({
		url,
		apiService: ApiService.DS,
		includeAccessToken: true,
		label: GET_DOC_INDEXES,
		...apiActionRequest,
	});
};

export const saveDocumentIndexes = (documentId: string, requestOverrides: DocumentIndexPayload) => {
	const url = endpointTemplateToUrl(ENDPOINT_SAVE_DOC_INDEX, { documentId });
	return apiAction({
		url,
		method: 'POST',
		apiService: ApiService.DS,
		includeAccessToken: true,
		label: SAVE_DOC_INDEXES,
		shouldDisableErrorHandler: true,
		...requestOverrides
	});
};
