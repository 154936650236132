import React from 'react';
import CompareRequestedSurgeon from './CompareRequestedSurgeon';
import { ConflictAttributeEnum } from '../../CaseDetailsContext/CaseDetailsPageContext/ConflictAttrConfig';

interface SurgeonListProps {
	conflictName?: string;
	surgeons: string;
	crossAllAffected?: boolean;
	ehrSurgeons?: string;
}

const SurgeonList: React.FC<SurgeonListProps> = ({ surgeons, ehrSurgeons, crossAllAffected, conflictName }) => {
	const formatSurgeons = React.useMemo(() => {
		const uniqueOccurrenceCounter: Record<string, number> = {};
		return surgeons.split(';').map((surgeon, index): { surgeon: string; uniqueOccurrenceCount: number } => {
			surgeon = surgeon.trim();
			if (!uniqueOccurrenceCounter[surgeon]) {
				uniqueOccurrenceCounter[surgeon] = 0;
			}
			if (index == 0 && conflictName == ConflictAttributeEnum.PRIMARY_PRACTITIONER) {
				surgeon=`${surgeon} (Primary)`;
			}
			return { surgeon, uniqueOccurrenceCount: ++uniqueOccurrenceCounter[surgeon] };
		});
	}, [surgeons, conflictName]);
	const compareSurgeons = ehrSurgeons ? ehrSurgeons.split(';').map(s => s.trim()) : undefined;
	return (<div>
		{
			formatSurgeons.map(({ surgeon, uniqueOccurrenceCount }) => {

				return <div key={`${surgeon}_${uniqueOccurrenceCount}`}>
					<CompareRequestedSurgeon
						compareSurgeons={compareSurgeons}
						crossAllAffected={crossAllAffected}
						uniqueOccurrenceCount={uniqueOccurrenceCount}
					>
						{surgeon}
					</CompareRequestedSurgeon>
				</div>;
			})
		}
	</div>);
};

export default SurgeonList;
