import { Box, Flex, Text } from '@ascension/web';
import React from 'react';

const NoTemplatesMessage: React.FC = ({ children }) => (
	<Flex justifyContent="space-between" alignItems="center" flexDirection="column" data-field="NoTemplate" py="32px">
		<Box py="4px">
			<Text fontSize={5} letterSpacing={1} fontWeight="bold">
				You have no templates created.
			</Text>
		</Box>
		<Box py="4px">
			<Text fontSize={3}>
				Create templates to help speed up your workflow.
			</Text>
		</Box>
		{children}
	</Flex>
);

export default NoTemplatesMessage;
