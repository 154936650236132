import { Reducer } from 'redux';
import {
	UPDATE_ALL_UNIT, UPDATE_UNIT_LIST_ERROR
} from '../actions/types';
import { Unit } from '../../data/unit/Unit';
import { UnitListState, UnitListTypes } from '../../interfaces/Unit/UnitAction';
import { HospitalWithUnits } from '../../interfaces/Hospital';

const initialState: UnitListState = {
	error: null,
	groupedUnitList: []
};


const groupUnitsByHospital = (unitsArray: Unit[], payloadUnits: Unit[]) => unitsArray.reduce((hospitalsArray: HospitalWithUnits[], currUnit: Unit) => {
	const foundHospitalIndex = hospitalsArray.findIndex(hospital => hospital.id === currUnit.hospital);
	currUnit.isPreferred = payloadUnits.some((data: Unit) => data.id === currUnit.id);
	if (foundHospitalIndex < 0) {
		return [
			...hospitalsArray,
			{
				id: currUnit.hospital,
				name: currUnit.hospitalName,
				market: currUnit.market,
				ministry: currUnit.ministry,
				ministryLocation: currUnit.ministryLocation,
				units: [currUnit],
			}
		];
	}
	hospitalsArray[foundHospitalIndex].units.push(currUnit);
	return hospitalsArray;
}, []);

export const UnitListReducer: Reducer<UnitListState, UnitListTypes>
	= ( state = initialState, action: UnitListTypes) => {
		switch (action.type) {
		case UPDATE_ALL_UNIT:
			return {
				...state,
				groupedUnitList: groupUnitsByHospital([...action.payload.data], action.payload.units)
			};
		case UPDATE_UNIT_LIST_ERROR:
			return { ...state, error: action.error };
		default:
			return state;
		}
	};



