/**
 * Generates anonymous function for sorting an array of objects by a field in the object
 * @param {string} fieldName - The field to sort by
 * @param {boolean} descending - Whether to sort in descending order (ascending by default)
 */
export function sortObjectsByField<ObjectType>(
	fieldName: keyof ObjectType,
	descending = false,
) {
	return (
		{ [fieldName]: valueA }: ObjectType,
		{ [fieldName]: valueB }: ObjectType,
	) => {
		if (typeof valueA === 'string' && typeof valueB === 'string') {
			return (descending ? valueB : valueA).localeCompare((descending ? valueA : valueB), 'en', { sensitivity: 'base' });
		}
		if (valueA > valueB) {
			return descending ? -1 : 1;
		}
		if (valueA < valueB) {
			return descending ? 1 : -1;
		}
		return 0;
	};
}
