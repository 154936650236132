import React from 'react';
import { Button } from '@ascension/ui-library';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import DocumentActionButtonProps
	from '@components/CaseDetails/DocumentInformationDisplay/DocumentDetails/DocumentActions/DocumentActionButtonProps';

const useStyles = makeStyles((theme) => createStyles({
	button: {
		'color': theme.palette.primary.main,
		'& .MuiButton-label': {
			display: 'flex',
			gap: theme.spacing(1),
		}
	},
}));

const DocumentActionButton: React.FC<DocumentActionButtonProps> = ({ children, className, fieldName, ...props }) => {
	const classes = useStyles();
	return <Button
		translate="yes"
		data-field={`document-${fieldName}Button`}
		variant="text"
		className={classNames(classes.button, className)}
		{...props}
	>
		{children}
	</Button>;
};

export default DocumentActionButton;
