import React from 'react';
import {
	Text,
} from '@ascension/web';
import { DisplayText } from '../../DisplayText/DisplayText';
import { dateParse } from '@utilities/dateUtilities';
import { useSelector } from 'react-redux';
import {
	CaseDetailsInfoSelector,
	CaseDetailsPatientInformationSelector,
} from '@store/selectors/CaseDetailsSelector';
import { AllergyOptionsEnum } from '@interfaces/SurgeryRequest/AllergyOptionsEnum';
import useDetailSectionGrid, { PatientGridFields } from '../useDetailSectionGrid/useDetailSectionGrid';
import DetailSection from '../DetailSection';
import { isWebRequest } from '@utilities/typeAssertionUtilities';
import { Address } from '@interfaces/Address';

const PHONE_PARTS_REG_EXP = /(\d{3})\D*(\d{3})\D*(\d{4})$/;

const ALLERGY_OPTION_TEXT: Omit<Record<AllergyOptionsEnum, string>, AllergyOptionsEnum.UNDEFINED> = {
	[AllergyOptionsEnum.YES]: 'YES',
	[AllergyOptionsEnum.NO]: 'NO',
	[AllergyOptionsEnum.UNABLE_TO_OBTAIN]: 'Unable to Obtain',
};

export const PatientInformationDisplay = () => {
	const {
		birthDate,
		email,
		family,
		gender,
		interpreterLanguage,
		latexAllergy,
		iodineAllergy,
		middleName,
		name,
		phone,
		ssn,
		otherPatientDetails,
	} = useSelector(CaseDetailsPatientInformationSelector);
	const caseDetails = useSelector(CaseDetailsInfoSelector);
	let address: Address | undefined;
	if (isWebRequest(caseDetails)) {
		address = caseDetails.address;
	}

	const birthDateParsed = React.useMemo(
		() => {
			const parsedDate = birthDate && dateParse(birthDate + ' 00:00');
			return parsedDate ? parsedDate[0] : '';
		},
		[birthDate]);
	const middleInitial = middleName ? middleName[0] + '.' : '';
	const phoneFormatted = React.useMemo(() => {
		if (!phone) { return ''; }
		const phoneStripped = PHONE_PARTS_REG_EXP.exec(phone);
		return phoneStripped ? '(' + phoneStripped[1] + ') ' + phoneStripped[2] + '-' + phoneStripped[3] : '';
	}, [phone]);

	const { gridContainer } = useDetailSectionGrid(DetailSection.PATIENT);

	return (
		<div className={gridContainer}>
			<DisplayText
				label="LAST NAME, FIRST NAME MI"
				defaultValue="--"
				displayDefault={!name}
				data-field="Patient_Full_Name"
				style={{ gridArea: PatientGridFields.NAME }}
			>
				{family + ', ' + name + ' ' + middleInitial}
			</DisplayText>
			<DisplayText
				label="DATE OF BIRTH"
				defaultValue="--"
				displayDefault={!birthDateParsed}
				data-field="Patient_DOB"
				style={{ gridArea: PatientGridFields.DOB }}

			>
				{birthDateParsed}
			</DisplayText>
			<DisplayText
				label="Gender"
				defaultValue="--"
				displayDefault={!gender}
				data-field="Patient_Gender"
				style={{ gridArea: PatientGridFields.GENDER }}
			>
				<Text>{gender}</Text>
			</DisplayText>
			<DisplayText
				label="SOCIAL SECURITY NUMBER"
				defaultValue="--"
				displayDefault={!ssn}
				data-field="Patient_SSN"
				style={{ gridArea: PatientGridFields.SSN }}
			>
				<Text>{ssn}</Text>
			</DisplayText>
			<DisplayText
				label="HOME PHONE NUMBER"
				defaultValue="--"
				displayDefault={!phoneFormatted}
				data-field="Patient_HPN"
				style={{ gridArea: PatientGridFields.PHONE }}
			>
				<Text>{phoneFormatted}</Text>
			</DisplayText>
			<DisplayText
				label="EMAIL ADDRESS"
				defaultValue="--"
				displayDefault={!email}
				data-field="Patient_Email"
				style={{ gridArea: PatientGridFields.EMAIL }}
			>
				<Text>{email}</Text>
			</DisplayText>
			<DisplayText
				label="Address Line 1"
				defaultValue="--"
				displayDefault={!address?.addressLine1}
				data-field="Patient_Address_Line_1"
				style={{ gridArea: PatientGridFields.ADDRESS_LINE_1 }}
			>
				<Text>{address?.addressLine1}</Text>
			</DisplayText>
			<DisplayText
				label="Address Line 2"
				defaultValue="--"
				displayDefault={!address?.addressLine2}
				data-field="Patient_Address_Line_2"
				style={{ gridArea: PatientGridFields.ADDRESS_LINE_2 }}
			>
				<Text>{address?.addressLine2}</Text>
			</DisplayText>
			<DisplayText
				label="ZIP Code"
				defaultValue="--"
				displayDefault={!address?.zipCode}
				data-field="Patient_ZIP_Code"
				style={{ gridArea: PatientGridFields.ZIP }}
			>
				<Text>{address?.zipCode}</Text>
			</DisplayText>
			<DisplayText
				label="City"
				defaultValue="--"
				displayDefault={!address?.city}
				data-field="Patient_City"
				style={{ gridArea: PatientGridFields.CITY }}
			>
				<Text>{address?.city}</Text>
			</DisplayText>
			<DisplayText
				label="State"
				defaultValue="--"
				displayDefault={!address?.state}
				data-field="Patient_State"
				style={{ gridArea: PatientGridFields.STATE }}
			>
				<Text>{address?.state}</Text>
			</DisplayText>
			<DisplayText
				label="TRANSLATOR NEEDED"
				defaultValue="--"
				displayDefault={!interpreterLanguage}
				data-field="Patient_TN"
				style={{ gridArea: PatientGridFields.TRANSLATOR_NEEDED }}
			>
				<Text>{interpreterLanguage}</Text>
			</DisplayText>
			<DisplayText
				label="LATEX ALLERGY"
				defaultValue="--"
				data-field="Patient_LA"
				displayDefault={latexAllergy === AllergyOptionsEnum.UNDEFINED}
				style={{ gridArea: PatientGridFields.LATEX_ALLERGY }}
			>
				<Text>{latexAllergy && ALLERGY_OPTION_TEXT[latexAllergy]}</Text>
			</DisplayText>
			<DisplayText
				label="IODINE ALLERGY"
				defaultValue="--"
				data-field="Patient_IA"
				displayDefault={iodineAllergy === AllergyOptionsEnum.UNDEFINED}
				style={{ gridArea: PatientGridFields.IODINE_ALLERGY }}
			>
				<Text>{iodineAllergy && ALLERGY_OPTION_TEXT[iodineAllergy]}</Text>
			</DisplayText>
			<DisplayText
				label="Other Patient Details"
				defaultValue="--"
				data-field="Patient_OD"
				displayDefault={!otherPatientDetails || otherPatientDetails.trim() === ''}
				style={{ gridArea: PatientGridFields.OTHER_DETAILS }}
			>
				<Text>{otherPatientDetails}</Text>
			</DisplayText>
		</div>
	);
};
