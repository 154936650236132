import React from 'react';

/***
 * useListElmRefs - can be used to get a list of element refs on a dynamic mapped list
 *
 * @example
 * const TASK_LIST = [
 *      { id: 1, task: 'Laundry' },
 *      { id: 2, task: 'Dishes' },
 * ];
 * const MyListComponent = () => {
 *      const {
 *          getElmAdderFor,
 *          elmList,
 *          clearList,
 *      } = useListElmRefs();
 *
 *      React.useEffect(() => clearList, [clearList]); // clean list on dismount
 *
 *
 *      React.useEffect(() => {
 *          // set all list items to blue
 *          elmList.forEach(e => e.setAttribute('style', 'background:blue');
 *      }, [elmList]);
 *
 *      return (
 *          <ul>
 *              {TASK_LIST.map(({ id, task }) => (
 *                  <li ref={getElmAdderFor(id)}>{task}</li>
 *              ))}
 *          </ul>
 *      )
 * }
 *
 * @returns {
 *     getElmAdderFor: (index: number | string) => (elm: HTMLDivElement) => void; // returns ref updater for given index
 *     elmList: HTMLDivElement[]; // Returns updated list when index names change
 *     clearList: () => void; // Reset list of refs to empty
 * }
 */
const useListElmRefs = () => {
	const elmRefs = React.useRef<Record<string | number, HTMLDivElement>>({});

	const getElmAdderFor = React.useCallback((index: number | string) => {
		return (elm: HTMLDivElement) => {
			elmRefs.current[index] = elm;
		};
	}, []);

	const elmRefsIndex = Object.keys(elmRefs.current).sort().join();
	const elmList = React.useMemo(() => {
		return elmRefsIndex ? Object.values(elmRefs.current) : [];
	}, [elmRefsIndex]);

	const clearList = React.useCallback(() => { elmRefs.current = {}; }, []);

	return {
		getElmAdderFor,
		elmList,
		clearList,
	};
};

export default useListElmRefs;
