import React from 'react';
import {
	useDocumentDetailsContext
} from '@components/CaseDetails/DocumentInformationDisplay/DocumentDetails/DocumentDetails';
import DocumentField
	from '@components/CaseDetails/DocumentInformationDisplay/DocumentDetails/DocumentField/DocumentField';
import { mapAttachmentStatusToString } from '@interfaces/SurgeryRequest/mapAttachmentStatusToString';
import { DisplayTextProps } from '@components/DisplayText/DisplayTextProps';

const DocumentStatusField: React.FC<Partial<DisplayTextProps>> = (props) => {
	const { status } = useDocumentDetailsContext();
	return <DocumentField fieldName="status" {...props}>
		{mapAttachmentStatusToString[status]}
	</DocumentField>;
};

export default DocumentStatusField;
