import { apiAction } from '@store/middlewares/api/ApiActionCreators';
import { ENDPOINT_SLOTS } from '@utilities/apiConstants';
import ApiService from '@utilities/apiEndpoints/ApiService';
import { GET_SLOTS } from '@store/actions/types';
import { CancelPolicy } from '@interfaces/CancelPolicy';
import SlotRequest from '@data/schedules/types/SlotRequest';

/***
 * getSlots - api action creator to get block time slots; slots are essentially what getSchedules returns, but without events
 *
 * @param apiActionRequest {Partial<SlotRequest>} data with SlotRequest query params
 *
 * @returns {ApiAction} api action to get slots data
 */
const getSlots = (apiActionRequest?: Partial<SlotRequest>) => {
	return apiAction({
		url: ENDPOINT_SLOTS,
		apiService: ApiService.BTS,
		includeAccessToken: true,
		label: GET_SLOTS,
		cancelPolicy: CancelPolicy.NEVER_CANCEL,
		shouldDisableLoadIndicator: true,
		...apiActionRequest,
	});
};

export default getSlots;
