import { Box, FormHelperText, InputLabel, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import TextFieldInputProps from '@components/TextFieldInput/TextFieldInputProps';
import { TextField } from '@ascension/ui-library';
import getRequestFormLabelStyle from '@components/RequestForm/utilities/getRequestFormLabelStyle';
import ErrorMessageMui from '@components/ErrorMessageMui/ErrorMessageMui';
import { CancelOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
	container: {
		position: 'relative'
	},
	textInput: {
		'marginBottom': theme.spacing(2.5),
		'& .MuiInputBase-root': {
			'&.Mui-disabled': {
				'backgroundColor': 'rgb(239, 240, 244)',
				'color': 'rgb(119, 119, 119)',
				'& .MuiInputBase-input': {
					cursor: 'not-allowed'
				},
				'& fieldset': {
					borderColor: theme.palette.grey[500]
				},
				'&:hover fieldset': {
					borderColor: theme.palette.grey[500],
					borderWidth: theme.spacing(.125)
				},
			},
		},
		'& .MuiOutlinedInput-root': {
			'height': '52px',
			'& fieldset': {
				borderColor: theme.palette.grey[500]
			},
			'&:hover fieldset': {
				borderColor: theme.palette.primary.main,
				borderWidth: theme.spacing(0.25)
			},
		},
		'& .MuiFormHelperText-contained': {
			marginLeft: 0
		},
		'& .Mui-error': {
			'& fieldset': {
				borderWidth: theme.spacing(0.25),
			},
		}
	},
	text: {
		color: theme.palette.grey[900],
	},
	errorMessage: {
		'position': 'absolute',
		'marginTop': theme.spacing(-2),
		'marginLeft': theme.spacing(0),
		'alignItems': 'center',
		'fontWeight': theme.typography.fontWeightBold,
		'fontSize': theme.typography.caption.fontSize,
		'& > .MuiSvgIcon-root': {
			height: '18px',
			width: '18px',
			marginRight: theme.spacing(0.25),
			marginTop: theme.spacing(0)
		},
		'&.Mui-error': {
			color: theme.palette.error[600],
		},
	},
	labelContainer: {
		display: 'flex',
		flexDirection: 'column',
		paddingBottom: theme.spacing(1),
		width: '100%',
	},
	label: {
		...getRequestFormLabelStyle(theme),
		paddingBottom: 0,
		textTransform: 'uppercase'
	},
	subLabelStyle: {
		color: theme.palette.text.secondary,
		marginTop: theme.spacing(0.5),
		marginLeft: theme.spacing(0),
		fontSize: theme.typography.caption.fontSize,
		whiteSpace: 'nowrap',
	}
}));

const TextFieldInput: React.FC<TextFieldInputProps> = ({ className, fieldName, value, label, onChange, required,  InputProps, subLabel, errorMessage, ...props }) => {
	const classes = useStyles();

	const handleInputChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		onChange(e.target.value);
	}, [onChange]);

	return (
		<Box className={classes.container}>
			{label && (
				<Box className={classes.labelContainer} data-field="label-container">
					<InputLabel required={!!label && required} className={classes.label}>{label}</InputLabel>
					{subLabel && (
						<FormHelperText className={classes.subLabelStyle} data-field="textField-sublabel">
							{subLabel}
						</FormHelperText>
					)}
				</Box>
			)}
			<TextField
				translate="yes"
				type="text"
				variant="outlined"
				fullWidth
				error={!!errorMessage}
				data-field={`${fieldName}-input`}
				value={value || ''}
				onChange={handleInputChange}
				className={classNames(classes.textInput, className)}
				InputProps={{
					className: classNames(classes.text, InputProps?.className),
					...InputProps,
				}}
				{...props}
			/>
			<ErrorMessageMui icon={CancelOutlined} displayIcon={true} className={classes.errorMessage}>{errorMessage}</ErrorMessageMui>
		</Box>
	);
};

export default TextFieldInput;
