import React from 'react';
import { Chip, Grid, Typography } from '@material-ui/core';
import ColumnInfo from '@calendar/types/ColumnInfo';
import { useCalendarContext } from '@calendar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	chip: {
		'minWidth': '0',

		'&&.MuiChip-root': {
			'background': theme.palette.primary.main,
			'color': theme.palette.common.white,
			'fontWeight': 500,
			'height': 'auto',
			'paddingTop': '5px',
			'paddingBottom': '5px',
			'textAlign': 'center',

			'&& .MuiChip-label': {
				overflowWrap: 'break-word',
				textOverflow: 'clip',
				whiteSpace: 'normal',
			},
		},
	},
	chipContainer: {
		alignItems: 'center',
		flexWrap: 'wrap-reverse',
		gap: theme.spacing(0.5),
		justifyContent: 'start',
		padding: theme.spacing(0, 1),
	},
	columnLabel: {
		cursor: 'pointer',
		paddingLeft: theme.spacing(1),
		textDecoration: 'underline',
	},
}));

const ColumnHeader: React.FC<ColumnInfo> = ({ labels, name }) => {
	const { onCalendarClick } = useCalendarContext();
	const classes = useStyles();

	return (
		<>
			{!!labels?.length && (
				<Grid container direction="row" className={classes.chipContainer}>
					{labels.map((label, index) => (
						<Chip key={index} label={label} size="small" className={classes.chip}/>
					))}
				</Grid>
			)}

			<Typography
				data-field="columnName"
				className={classes.columnLabel}
				onClick={() => onCalendarClick(name)}
			>
				{name}
			</Typography>
		</>
	);
};

export default ColumnHeader;
