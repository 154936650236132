import { utcToZonedTime, format, zonedTimeToUtc, formatInTimeZone } from 'date-fns-tz';
import { addBusinessDays, parse, startOfDay } from 'date-fns';
import {
	TIME_ONLY_FORMAT,
	TIME_12_REGEX,
	TIME_24_REGEX,
} from '@utilities/constants';

const zeroPadding: (numberToPad: number | string) => string = (numberToPad) => {
	return ('00' + numberToPad).substr(-2, 2);
};

export const dateObjectToDateString = (dateObj: Date) => {
	let dateStr: string = dateObj.getFullYear().toString();
	dateStr += '-' + ('0' + (dateObj.getMonth() + 1)).substr(-2, 2);
	dateStr += '-' + ('0' + dateObj.getDate()).substr(-2, 2);
	return dateStr;
};

export const dateToString = (date: Date | undefined) => {
	if (date) {
		return format(date, 'MM/dd/yyyy');
	}
	return '';
};

export const formatDate = (providedDate: string = new Date().toISOString()): string => {
	const dateParseRegExp = /^(\d{4}-\d{2}-\d{2})(?:T\d{2}:\d{2}.*)?/;
	const dateParts = dateParseRegExp.exec(providedDate);
	if (!dateParts) { throw new Error('Invalid date format'); } // Never hit based on match above
	const dateAtMidnight = dateParts[1] + 'T00:00';
	return dateObjectToDateString(new Date(dateAtMidnight));
};

export const formatTimeToFullApiDate = (initialDateStr: string = formatDate(), newTime = '12:00 am'): string => {
	initialDateStr = initialDateStr.split(' ')[0];

	initialDateStr += ' ' + newTime;
	return initialDateStr;
};

export const formatDateToFullApiDate = (initialDateStr = '0 12:00 am', newDate: string = new Date().toISOString()): string => {
	const dateParts: string[] = initialDateStr.split(' ');
	const currentTime: string = dateParts[1];
	const meridiem: string = dateParts[2] || 'am';

	return formatDate(newDate) + ' ' + currentTime + ' ' + meridiem;
};

const dateTo12Hr: (asDate: Date) => string = (asDate) => {
	let timeStr: string = (
		'0' + (
			asDate.getHours() <= 12 && asDate.getHours() > 0 ?
				asDate.getHours() :
				Math.abs(asDate.getHours() - 12)
		)).substr(-2, 2);
	timeStr += ':' + ('00' + asDate.getMinutes()).substr(-2, 2);
	return timeStr;
};

const dateToMeridiem: (asDate: Date) => ('am' | 'pm') = (asDate): ('am' | 'pm') => {
	return (asDate.getHours() >= 12 ? 'pm' : 'am');
};

export const timeParse:
	(timeToParse: string, dateBase?: Date) =>
		[string /* 12 hr time */, 'am' | 'pm' /* meridiem */]
	= (timeToParse, dateBase = new Date()) => {
		const asDate: Date = new Date(dateBase.toISOString().split('T')[0] + 'T' + timeToParse);

		return [dateTo12Hr(asDate), dateToMeridiem(asDate)];
	};

export const dateParse: (dateToParse: string | Date) => [string /* date */, string /* 12 hr time */, string /* meridiem */] = (dateToParse) => {
	const asDate: Date = new Date(dateToParse);

	let dateStr: string = ('0' + (asDate.getMonth() + 1)).substr(-2, 2);
	dateStr += '/' + ('0' + asDate.getDate()).substr(-2, 2);
	dateStr += '/' + asDate.getFullYear().toString();

	return [dateStr, dateTo12Hr(asDate), dateToMeridiem(asDate)];
};

export const dateToInputString: (dateToParse: string | Date) => string = (dateToParse: Date | string) => {
	const asDate: Date = new Date(dateToParse);

	let dateStr: string = ('0' + (asDate.getMonth() + 1)).substr(-2, 2);
	dateStr += ' / ' + ('0' + asDate.getDate()).substr(-2, 2);
	dateStr += ' / ' + asDate.getFullYear().toString();

	return dateStr;
};

export const parsedTimeTo24Hr: (timeStr: string/* HH:MM where HH < 12 */, meridiemStr: string) => string = (timeStr, meridiemStr) => {
	const timeFormatError = new Error('Time must be in 12 hour format');
	const meridiemFormatError = new Error('Meridiem value must be either \'am\' or \'pm\'');
	if (!timeStr) {
		timeStr = '12:00';
	}

	const timeSplit: [string, string] = timeStr.split(':') as [string, string];
	if (!timeSplit || timeSplit.length !== 2) {
		throw timeFormatError;
	}

	const hour12Num = Number(timeSplit[0]);
	if (!(hour12Num > 0 && hour12Num <= 12)) {
		throw timeFormatError;
	}

	switch (meridiemStr) {
	case 'am':
		return zeroPadding(hour12Num === 12 ? 0 : hour12Num) + ':' + timeSplit[1];
	case 'pm':
		return zeroPadding(hour12Num === 12 ? 12 : hour12Num + 12) + ':' + timeSplit[1];
	default:
		throw meridiemFormatError;
	}
};

export const prevTimeToUpdateTime = (prevTimeValue?: string, currentDate?: Date, timeUpdate?: string) => {
	if (!timeUpdate) {
		return prevTimeValue || '00:00';
	}
	const prevTimePeriod = prevTimeValue ? timeParse(prevTimeValue, currentDate)[1] : currentDate ? dateToMeridiem(currentDate) : 'am';
	timeUpdate = timeUpdate + '00:00'.substring(timeUpdate.length, 5);
	if (timeUpdate === '00:00') {
		return prevTimePeriod  === 'am' ? '00:00' : '12:00';
	}
	return parsedTimeTo24Hr(timeUpdate, prevTimePeriod);
};

export const prevTimeToUpdatePeriod = (prevTimeValue?: string, currentDate?: Date, periodUpdate?: string) => {
	if (!periodUpdate) {
		return prevTimeValue || '00:00';
	}
	const prevTime12hr = prevTimeValue ? timeParse(prevTimeValue, currentDate)[0] : '12:00';
	return parsedTimeTo24Hr(prevTime12hr, periodUpdate);
};

export const getDayMonthDateFromString = (date: string, useMonthDot?: boolean) => {
	const formattedDate = new Date(date);
	const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
	const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
	let monthSelected = month[formattedDate.getMonth()];
	monthSelected = useMonthDot && !['May', 'June', 'July'].includes(monthSelected) ?
		monthSelected + '.' : monthSelected;
	return [weekday[formattedDate.getDay()], monthSelected, formattedDate.getDate()];
};

/**
 * getMonthNameDateFullYearFromString - converts date string to long date parts - [monthName, dayNum, fullYear]
 * e.g. '2021-01-02T12:00:00  ->  ["January", "2", "2021"]
 * @param date {string} - date string in ISO-string format "2022-01-26T16:05:33.028" or any typical date format
 * @returns [monthName {string}, dayNum {string}, fullYear {string}] - date parts array with month name, day number, and full year
 */
export const getMonthNameDateFullYearFromString = (date: string) => {
	const formattedDate = new Date(date);
	return format(formattedDate, 'MMMM d yyyy').split(' ');
};

/***
 * parseAsUtcDate - converts date string to new UTC date object
 * @param inputDate {string} - date string in the following format: "2022-06-25 03:30"
 * @returns {Date} - new UTC date object
 */
export const parseAsUtcDate = (inputDate: string): Date => {
	const [date, time, meridiem] = dateParse(inputDate);
	const time24Hr = parsedTimeTo24Hr(time, meridiem);
	const splitDate = date.split('/').map((datePiece) => Number(datePiece));
	const splitTime = time24Hr.split(':').map((timePiece) => Number(timePiece));
	const month = splitDate[0] - 1;
	const utcDate = Date.UTC(splitDate[2], month, splitDate[1], splitTime[0], splitTime[1]);

	return new Date(utcDate);
};

/***
 * utcToTimezone - returns formatted date string converted to specified timezone
 * @param date {string} - date string (handled as UTC) in the following format: "2022-06-25 03:30"
 * @param timezone {string} - one of the Time Zone IDs from this list: https://docs.oracle.com/middleware/12211/wcs/tag-ref/MISC/TimeZones.html
 * @returns {string} - date string converted to the provided timezone in the following format: "Fri, Jun 24, 2022, 22:30"
 */
export const utcToTimezone = (date: string, timezone: string): string => {
	const utcDate = parseAsUtcDate(date);

	return format(utcToZonedTime(utcDate, timezone), 'eee, MMM d, yyyy, HH:mm');
};

/***
 * utcToLocalTime - converts date string (assumed according to UTC) to user's local time and changes to new format
 * @param date {string | undefined} - date string in the following format: "2022-06-25 03:30"; also handles undefined
 * @returns {string | undefined} - new date string in the following format: "Fri, Jun 24, 2022, 22:30"; undefined if input date undefined
 */
export const utcToLocalTime = (date: string | undefined): string | undefined => {
	if (date) {
		const utcDate = parseAsUtcDate(date);

		return format(utcDate, 'eee, MMM d, yyyy, HH:mm');
	}
};

/***
 * convertAndFormatDateToApi - provided a date/time and a timezone, returns date string according to UTC and formats for API
 * @param date {Date} - date object to convert and format
 * @param timeZone {string} - one of the time zone IDs from this list: https://docs.oracle.com/middleware/12211/wcs/tag-ref/MISC/TimeZones.html
 * @returns {string} - date string in format API expects e.g. "2021-10-11 03:01"
 */
export const convertAndFormatDateToApi = (date: Date, timeZone: string): string => {
	const convertedDate = zonedTimeToUtc(date, timeZone).toISOString();
	const dateTimeSplit = convertedDate.split('T');

	return `${dateTimeSplit[0]} ${dateTimeSplit[1].slice(0, 5)}`;
};

/***
 * timeStringAs24Hr - provided string with time in 'hh:mm aa' or 'h:mm aa' format, returns string representing time in 'H:mm' format.
 *
 * @param time {String} - string representing time in 'hh:mm aa' or 'h:mm aa' format
 *
 * @throws {Error} when string is not in the `h:mm aa` format.
 *
 * @returns {string} representing time in HH:mm format
 *
 * @example
 * timeStringAs24Hr('3:05 AM') // returns '03:05'
 *
 * @example
 * timeStringAs24Hr('03:05 PM') // returns '15:05'
 *
 * @example
 * timeStringAs24Hr('3:05') // throws Error "Expected time in the format 'h:mm aa' but received '3:05'." because the string is missing AM/PM
 *
 * @example
 * timeStringAs24Hr('12:00 AM') // returns '00:00'
 *
 * @example
 * timeStringAs24Hr('12:00 PM') // returns '12:00'
 */
export function timeStringAs24Hr(time: string): string;
export function timeStringAs24Hr(time: string, suppressError: false): string;
export function timeStringAs24Hr(time: string, suppressError: true): string | undefined;
export function timeStringAs24Hr(time: string, suppressError = false) {
	if (!TIME_12_REGEX.test(time)) {
		if (suppressError) {
			return undefined;
		}
		throw new Error(`Expected time in the format '${TIME_ONLY_FORMAT}' but received '${time}'.`);
	}
	const timeFormat = time.startsWith('0') ? 'hh:mm aa' : 'h:mm aa';
	return format(parse(time, timeFormat, new Date()), 'HH:mm');
}

/***
 * convert24TimeToDateObj - convert 24-hour time string to Date object i.e. '16:00' -> new Date(...T16:00...)
 *
 * @param time {string} - valid time in 24-hour format.
 *      - '12:00' - valid
 *      - '16:00' - valid
 *      - '07:00' - valid
 *      - '7:00' - invalid
 *      - '06:30 AM' - invalid
 * @param timezone {string} - valid timezone string for date-fns
 * @param date {Date} - valid date to use as base
 *
 * @returns {Date}
 */
export const convert24TimeToDateObj = (time: string, timezone: string, date: Date): Date | undefined => {
	if (!TIME_24_REGEX.test(time) || !timezone || !date || date.toString() === 'Invalid Date') { return; }

	return new Date(`${formatInTimeZone(date, timezone, 'yyyy-MM-dd')}T${time}${formatInTimeZone(date, timezone, 'XXX')}`);
};

/***
 * timeStringAs12Hr - provided string with time in 'HH:mm' format, returns string representing time in 'h:mm aa' format.
 *
 * @param time {String} - string representing time in 'HH:mm'
 *
 * @returns {string} representing time in HH:mm format
 *
 * @example
 * timeStringAs12Hr('3:05') // returns '3:05 AM'
 *
 * @example
 * timeStringAs12Hr('15:05') // returns '3:00 PM'
 */
export const timeStringAs12Hr = (time: string): string | undefined => {
	if (!TIME_24_REGEX.test(time)) return;
	const timeParts = time.split(':');
	const date = new Date();
	date.setHours(Number(timeParts[0]));
	date.setMinutes(Number(timeParts[1]));
	return format(date, 'h:mm aa');
};

/***
 * getZonedTimeFromTimeAndDate - get time in timezone which has same time and same date with input time and input date.
 * time is string like "12:00 AM" and date is Date.
 *
 * ex: getZonedTimeFromTimeAndDate('12:00 AM', new Date(2022-12-01), 'EST') => new Date("2022-12-01 12:00 AM EST")
 *
 * @param time {string} - valid time  ex: '12:00 AM'
 * @param timezone {string} - valid timezone string for date-fns
 * @param date {Date: new Date()} - valid time
 *
 * @returns {Date} Date in timezone
 */
export const getZonedTimeFromTimeAndDate = (time: string, timezone: string, date: Date | null = new Date()): Date | undefined => {
	if (TIME_12_REGEX.test((time)) && date !== null && date.toString() !== 'Invalid Date') {
		return new Date(`${formatInTimeZone(date, timezone, 'yyyy-MM-dd')}T${timeStringAs24Hr(time)}${formatInTimeZone(date, timezone, 'XXX')}`);
	}
};

/***
 * getNormalizedTime - used within calendar to compare dates accurately using the same timezone
 *
 * @param d {Date | null}
 * @param hospitalTimeZone {string} - valid timezone string for date-fns
 *
 * @returns {string} date string in timezone
 */
export const getNormalizedTime = (d: Date | null, hospitalTimeZone: string): string | undefined => {
	if (!d || d.toString() === 'Invalid Date') return;
	return formatInTimeZone(d, hospitalTimeZone, 'yyyy-MM-dd');
};

export const TODAY = startOfDay(new Date());
export const TWO_BUSINESS_DAYS_FROM_NOW = addBusinessDays(TODAY, 2);
export const isLessThanTwoBusinessDaysFromToday = (date: Date) => date < TWO_BUSINESS_DAYS_FROM_NOW;
