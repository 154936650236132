import { UseFieldFormContextReturnInterface } from './UseFieldFormContextReturnInterface';
import React, { useCallback, useMemo } from 'react';
import { FieldValues, Path, PathValue, useFormContext } from 'react-hook-form';
import { UseFieldFormContextConfigInterface } from './UseFieldFormContextConfigInterface';
import { ValueSetter } from './UseFieldFormContextValueSetter';

function isValueType<ValueType>(value: unknown): value is ValueType {
	return typeof value !== 'function';
}

export const useFieldFormContext = <ValueType extends PathValue<FormStateType, Path<FormStateType>>, FormStateType extends FieldValues = Record<string, ValueType>>(name: Path<FormStateType>, { validationRules, triggerValidationOnChange }: Partial<UseFieldFormContextConfigInterface> = {}): UseFieldFormContextReturnInterface<ValueType, FormStateType> => {
	const {
		register,
		setValue,
		unregister,
		watch,
		formState: { errors },
		setError,
		clearErrors,
		trigger,
	} = useFormContext<FormStateType>();

	const formCtxRef = React.useRef({
		validationRules,
		unregister,
		register,
	});

	React.useEffect(() => {
		formCtxRef.current.register(name, formCtxRef.current.validationRules);
		const { unregister } = formCtxRef.current;
		return () => {
			unregister(name);
		};
	}, [name]);

	const currentValue = watch(name) as ValueType;
	const currentValueRef = React.useRef(currentValue);

	React.useEffect(() => {
		currentValueRef.current = currentValue;
	}, [currentValue]);

	const setCurrentValue = useCallback((newVal: ValueSetter<ValueType, FormStateType> | ValueType) => {
		if (!isValueType<ValueType>(newVal)) {
			const v = newVal(currentValueRef.current);
			setValue(name, v, { shouldValidate: !!triggerValidationOnChange });
			return;
		}

		setValue(name, newVal, { shouldValidate: !!triggerValidationOnChange });
	}, [setValue, name, triggerValidationOnChange]);

	const unregisterSelf = React.useCallback(() => unregister(name), [unregister, name]);

	const errorsDesc = errors[name];
	const error = React.useMemo(() => {
		if (errorsDesc) {
			return typeof errorsDesc.message === 'string' ? errorsDesc.message : 'Invalid';
		}
		return '';
	}, [errorsDesc]);

	const triggerSelfValidation = useMemo(() => () => trigger(name), [name, trigger]);

	const setErrorMessage = useCallback((errorType: string, errorMessage: string) => {
		setError(name, { type: errorType, message: errorMessage });
	}, [name, setError]);

	const clearErrorMessage = useCallback( () => clearErrors(name), [clearErrors, name]);

	return {
		currentValue,
		setCurrentValue,
		error,
		setErrorMessage,
		clearErrorMessage,
		unregisterSelf,
		triggerSelfValidation,
	};
};
