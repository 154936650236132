import React from 'react';
import { Box, Flex, Text } from '@ascension/web';
import { DisplayText } from '@components/DisplayText/DisplayText';
import { dateParse } from '@utilities/dateUtilities';
import { useSelector } from 'react-redux';
import {
	CaseDetailsAppointmentSelector,
	CaseDetailsInfoSelector,
	CaseDetailsSurgeryInformationSelector
} from '@store/selectors/CaseDetailsSelector';
import { useCollectionEntity } from '@utilities/hooks/useCollectionEntity/useCollectionEntity';
import { Unit } from '@data/unit/Unit';
import { ENDPOINT_USER_UNITS } from '@utilities/apiConstants';
import { useCommonStyles } from '@utilities/hooks/useCommonStyles/useCommonStyles';
import useHasEhrIntegrationAndRole
	from '@utilities/hooks/useHasEhrIntegrationAndRole/useHasEhrIntegrationAndRole';
import EditConfirmationNumber from '@components/CaseDetails/EditConfirmationNumber/EditConfirmationNumber';
import { IntegrationType } from '@interfaces/IntegrationType';
import { HospitalInteractiveRoles, HospitalRoles } from '@interfaces/UserProfile/UserRoleEnum';
import { UserProfileHasARoleSelector } from '@store/selectors/UserProfileSelector';
import useDetailSectionGrid, { AppointmentGridFields } from '@components/CaseDetails/useDetailSectionGrid/useDetailSectionGrid';
import DetailSection from '@components/CaseDetails/DetailSection';
import { AppointmentType } from '@data/request/AppointmentType';
import { DiagnosisApi } from '@interfaces/SurgeryRequest/DiagnosesApi';

const FULL_CONFIRMATION_TYPES = [IntegrationType.EHR, IntegrationType.MANUAL]; // Order affects display order
const LIMITED_CONFIRMATION_TYPES = [IntegrationType.MANUAL];

export const AppointmentRequestDisplay: React.FC = () => {
	const {
		authStatus,
		blockTime,
		procedureDate,
		toFollow,
		duration,
	} = useSelector(CaseDetailsAppointmentSelector);
	const caseInfo = useSelector(CaseDetailsInfoSelector);
	const { diagnosis } = useSelector(CaseDetailsSurgeryInformationSelector);
	const { unit, room, procedures, insurances } = caseInfo;
	const { hospitalName, name: unitName } = useCollectionEntity<Unit>(ENDPOINT_USER_UNITS, unit, 'id');
	const isHospitalUserWithEhrIntegration = useHasEhrIntegrationAndRole(caseInfo, HospitalRoles);
	const hasEditConfirmationRole = useSelector(UserProfileHasARoleSelector(HospitalInteractiveRoles));

	const { code: primaryCode } = (diagnosis && diagnosis.length ? diagnosis[0] : {}) as DiagnosisApi;

	const cptCodes = procedures && procedures.length && procedures[0].cptCodes && procedures[0].cptCodes.length ? procedures[0].cptCodes.join(', ') : '';
	const primaryInsurance = insurances && insurances[0];
	const confirmationTypes = isHospitalUserWithEhrIntegration ? FULL_CONFIRMATION_TYPES : LIMITED_CONFIRMATION_TYPES;

	const parsedDate = React.useMemo(
		() => procedureDate && dateParse(procedureDate),
		[procedureDate]);

	const { upperCase } = useCommonStyles();
	const { gridContainer } = useDetailSectionGrid(DetailSection.APPOINTMENT);

	const isDocRequest = React.useMemo(() => caseInfo?.appointmentType === AppointmentType.DOCUMENT, [caseInfo]);

	return (
		<Flex flexDirection="column">
			<Flex flexWrap="wrap" width={1}>
				{confirmationTypes.map((type, index) =>
					<EditConfirmationNumber type={type} key={type} allowEdit={index === 0 && hasEditConfirmationRole}/>)}
			</Flex>
			<div className={gridContainer}>
				<DisplayText
					label="Appointment Location"
					defaultValue="--"
					displayDefault={!unitName}
					data-field="location"
					style={{ gridArea: AppointmentGridFields.LOCATION }}
				>
					{unitName + ', ' + hospitalName}
				</DisplayText>
				<DisplayText
					label="Room"
					defaultValue="--"
					displayDefault={!room}
					data-field="room"
					style={{ gridArea: AppointmentGridFields.ROOM }}
				>
					{room}
				</DisplayText>
				<DisplayText
					label="Requested Surgery Date"
					defaultValue="--"
					displayDefault={!parsedDate}
					data-field="date"
					style={{ gridArea: AppointmentGridFields.DATE }}

				>
					{parsedDate ? parsedDate[0] : ''}
				</DisplayText>
				<DisplayText
					label="Requested Surgery Time"
					defaultValue="--"
					displayDefault={!parsedDate}
					style={{ gridArea: AppointmentGridFields.TIME }}
				>
					<Flex flexDirection="column">
						{parsedDate &&
						<Box data-field="time">
							<Text>{parsedDate[1]}</Text> <Text className={upperCase}>{parsedDate[2]}</Text>
						</Box>
						}
						{blockTime && <Box><Text data-field="blockTime">Block Time</Text></Box>}
						{toFollow && <Box><Text data-field="toFollow">To Follow</Text></Box>}
					</Flex>
				</DisplayText>
				<DisplayText
					label="Total Case Duration"
					defaultValue="--"
					displayDefault={!duration}
					data-field="Surgery_duration"
					style={{ gridArea: AppointmentGridFields.DURATION }}
				>
					{`${duration} min`}
				</DisplayText>
				{!isDocRequest && (
					<DisplayText
						label="Intended Order Status/Auth Status"
						defaultValue="--"
						displayDefault={!authStatus}
						data-field="patientStatus"
						style={{ gridArea: AppointmentGridFields.ORDER_STATUS }}
					>
						<Text>Scheduled {authStatus}</Text>
					</DisplayText>
				)}
				{isDocRequest && (
					<>
						<DisplayText
							label="ICD-10 Code Primary Diagnosis"
							defaultValue="--"
							displayDefault={!primaryCode}
							data-field="Surgery_diagnosesPrimaryCode"
							style={{ gridArea: AppointmentGridFields.PRIMARY_DIAGNOSIS }}
						>
							<Text>{primaryCode}</Text>
						</DisplayText>
						<DisplayText
							label="CPT Codes"
							defaultValue="--"
							displayDefault={!cptCodes}
							data-field="Surgery_CPTCODE"
							style={{ gridArea: AppointmentGridFields.CPT_CODES }}
						>
							<Text>{cptCodes}</Text>
						</DisplayText>
						<DisplayText
							label="Pre-Cert Number"
							defaultValue="--"
							displayDefault={!primaryInsurance.preCert}
							data-field="Precert_Number"
							style={{ gridArea: AppointmentGridFields.PRE_CERT }}
						>
							<Text>{primaryInsurance.preCert}</Text>
						</DisplayText>
					</>
				)}
			</div>
		</Flex>
	);
};
