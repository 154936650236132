import { SetToastStatus, ToastStatusAction } from '../../interfaces/ToastStatus/ToastStatusAction';
import { CLEAR_TOAST_STATUS, SET_TOAST_STATUS } from './types';
import { ActiveToastType, ToastType } from '../../interfaces/ToastStatus/ToastStatusState';
import {
	DEFAULT_TOAST_ERROR_TIMEOUT,
	DEFAULT_TOAST_SUCCESS_TIMEOUT,
	DEFAULT_TOAST_WARNING_TIMEOUT
} from '../../utilities/constants';

export const clearToastStatus = (): ToastStatusAction => {
	return {
		type: CLEAR_TOAST_STATUS
	};
};

/***
*   Private function to use only by other functions within this file.
*   This function dispatches Toast with specified toast type, message and the timeout.
*   @param {number} alertType - enumeration number value representing alert type
*   @param {string} message -  toast message to be displayed
*   @param {number} timeout - timeout after which toast should disappear
*   @return {ToastStatusAction} - Redux action object that will be used to display toast
 */
const setToastStatus = (alertType: ActiveToastType, message: string, timeout: number): SetToastStatus =>{
	const timestamp: Date = new Date();
	return {
		type: SET_TOAST_STATUS,
		payload: {
			toastType: alertType,
			message: message,
			timeout: timeout,
			timestamp
		}
	};
};

/***
 *  Returns Redux action object that will be used to display toast of a type Success
 *  @param {string} message - message that will be displayed inside toast
 *  @param {number} timeout - timeout after which toast should disappear. If value not provided it will use default value for toast success
 *  @return {ToastStatusAction} - Redux action object that will be used to set toast status
 */
export const setToastSuccessStatus = (message: string, timeout = DEFAULT_TOAST_SUCCESS_TIMEOUT): SetToastStatus =>{
	return setToastStatus(ToastType.SUCCESS, message, timeout);
};

/***
 *  Returns Redux action object that will be used to display toast of a type Success
 *  @param {string} message - message that will be displayed inside toast
 *  @param {number} timeout - timeout after which toast should disappear. If value not provided it will use default value for toast warning
 *  @return {ToastStatusAction} - Redux action object that will be used to set toast status
 */
export const setToastWarningStatus = (message: string, timeout = DEFAULT_TOAST_WARNING_TIMEOUT): SetToastStatus =>{
	return setToastStatus(ToastType.WARNING, message, timeout);
};

/***
 *  Returns Redux action object that will be used to display toast of a type Success
 *  @param {string} message - message that will be displayed inside toast
 *  @param {number} timeout - timeout after which toast should disappear. If value not provided it will use default value for toast error
 *  @return {ToastStatusAction} - Redux action object that will be used to set toast status
 */
export const setToastErrorStatus = (message: string, timeout = DEFAULT_TOAST_ERROR_TIMEOUT): SetToastStatus =>{
	return setToastStatus(ToastType.ERROR, message, timeout);
};

