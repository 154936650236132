import CardSection from '@components/CardSection/CardSection';
import CardSectionTitle from '@components/CardSection/CardSectionTitle/CardSectionTitle';
import AppointmentDetails from '@components/RequestForm/AppointmentDetails/AppointmentDetails';
import RequestAuthStatus from '@components/RequestForm/formSections/RequestAuthStatus';
import PatientDetails from '@components/RequestForm/PatientDetails/PatientDetails';
import { Divider, Text } from '@ascension/web';
import InsuranceInformation from '@components/RequestForm/InsuranceInformation/InsuranceInformation';
import AddableSection from '@components/RequestForm/AddableSection/AddableSection';
import SurgeryInformation from '@components/RequestForm/SurgeryInformation/SurgeryInformation';
import PreAdmitTestDetails from '@components/RequestForm/PreAdmitTestDetails/PreAdmitTestDetails';
import React from 'react';
import { useRequestFormContext } from '@components/RequestForm/RequestForm';

const ComposedCommonWebSections = () => {
	const { isEdit, showSecondaryInsurance, setShowSecondaryInsurance } = useRequestFormContext();

	return <>
		<CardSection sectionTitle={<CardSectionTitle title="Appointment Details" />} >
			<AppointmentDetails />
			<RequestAuthStatus />
		</CardSection>
		<CardSection sectionTitle={<CardSectionTitle title="Patient Details" />}>
			<PatientDetails data-field="patientDetails"/>
			<Divider my={15}/>
			<Text fontSize={18} fontWeight={500} display="block">
				Primary Insurance Details
			</Text>
			<InsuranceInformation data-field="insuranceInformation" type="Primary"/>
			<Divider my={15}/>
			<AddableSection
				show={showSecondaryInsurance}
				onUpdateShow={setShowSecondaryInsurance}
				title="Secondary Insurance"
				addButtonText="Add Secondary Insurance"
				data-field="secondaryInsuranceInformation"
			>
				<InsuranceInformation type="Secondary"/>
			</AddableSection>
		</CardSection>
		<CardSection sectionTitle={<CardSectionTitle title="Surgery Information" />}>
			<SurgeryInformation
				data-field="surgeryInformation"
			/>
		</CardSection>
		<CardSection sectionTitle={<CardSectionTitle title="Pre-Admit Testing Details" />}>
			<PreAdmitTestDetails isEdit={isEdit}/>
		</CardSection>
	</>;
};

export default ComposedCommonWebSections;
