import { getRandomString } from '@utilities/commonFunction';
import React from 'react';
import ManageableListDescriptor from '@utilities/hooks/useManageEditableList/ManageableListDescriptor';

// Use get so new random ID generated each time it needs to be reset
const getEmptyListDescriptor: (atLeastSingleItem?: boolean) => ManageableListDescriptor<never> = (atLeastSingleItem) => ({
	initialList: [],
	addedIds: atLeastSingleItem ? [getRandomString(10)] : [],
	removedIds: [],
});

function getListWithIds<ListItemType>(list: ListItemType[]): Array<ListItemType & { id: string }> {
	return list.map((listItem) => ({
		...listItem,
		id: getRandomString(10)
	}));
}

function getDescriptorWithInitial<ListItemType>(initialList: undefined | ListItemType[], defaultToSingleItem: boolean) {
	const empty = getEmptyListDescriptor(defaultToSingleItem);

	return Array.isArray(initialList) && initialList.length ? { // Initial list exists?
		...empty,
		addedIds: [], // addedIds should be empty
		initialList: getListWithIds(initialList), // and use initialList but add generated IDs
	}: empty; // otherwise use the default empty with single random ID for added
}

function useManageEditableList<ListItemType>(defaultToSingleItem = true) {
	const [listDescriptor, setListDescriptor] = React.useState<ManageableListDescriptor<ListItemType>>(getEmptyListDescriptor(defaultToSingleItem));

	// List can be cleared with this method as well
	const setInitialList = React.useCallback((initialList?: ListItemType[]) => {
		setListDescriptor(getDescriptorWithInitial(initialList, defaultToSingleItem));
	}, [defaultToSingleItem]);

	const addItem = React.useCallback(() => {
		setListDescriptor((existingDescriptor) => ({
			...existingDescriptor,
			addedIds: [...existingDescriptor.addedIds, getRandomString(10)],
		}));
	}, []);

	const removeId = React.useCallback((id: string) => {
		setListDescriptor((existingDescriptor) => ({
			...existingDescriptor,
			removedIds: [...existingDescriptor.removedIds, id],
		}));
	}, []);

	// Reset keeps initial list in place but clears other items
	const resetListToInitial = React.useCallback(() => {
		setListDescriptor((existingDescriptor) => getDescriptorWithInitial(existingDescriptor.initialList, defaultToSingleItem));
	}, [defaultToSingleItem]);

	const idList = React.useMemo(() => {
		return listDescriptor.initialList.map(l => l.id)
			.concat(listDescriptor.addedIds)
			.filter((id) => !listDescriptor.removedIds.includes(id));
	}, [listDescriptor]);

	return {
		setInitialList,
		initialList: listDescriptor.initialList,
		addItem,
		removeId,
		resetListToInitial,
		idList,
	};
}

export default useManageEditableList;
