import LocationSelection from '@components/LocationSelection/LocationSelection';
import React from 'react';
import { useSchedulePageContext } from '@components/SchedulePage/SchedulePageContext/SchedulePageContext';

const ScheduleLocationSelection = () => {
	const {
		selectedFacility,
		setSelectedFacility,
		facilitiesSorted,
	} = useSchedulePageContext();

	return (
		<LocationSelection
			selectedFacility={selectedFacility?.id || ''}
			setSelectedFacility={setSelectedFacility}
			facilityList={facilitiesSorted}
		/>
	);
};

export default ScheduleLocationSelection;
