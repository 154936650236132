import UnavailableEventFormType from '@components/UnitCalendarPage/utilities/UnavailableEventFormType';

const defaultUnavailableEventFormValues: Required<UnavailableEventFormType> = {
	date: new Date(),
	description: '',
	timeSpan: {},
	hospitalId: '',
	roomId: '',
	unitId: '',
	id: '',
};

export default defaultUnavailableEventFormValues;
