import React, { ChangeEvent, ReactElement, useState, useCallback, MouseEventHandler, ChangeEventHandler } from 'react';
import { Checkbox, MenuItem, Select, ListItemText } from '@material-ui/core';
import { StandardInputWrapper } from '@components/StatefulInputs/Base/StandardInputWrapper/StandardInputWrapper';
import { FieldValues, useController } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { StatefulMultiSelectProps } from '@components/StatefulInputs/StatefulMultiSelect/StatefulMultiSelectProps';
import { Theme as DefaultTheme } from '@material-ui/core/styles/createTheme';
import {
	getOptionLabelFromValue,
	getOptionValueFromLabel,
	isOptionSelected,
	renderSelectedValues
} from '@components/StatefulInputs/StatefulMultiSelect/statefulMultiSelectUtilities';
import { SelectOption } from '@components/StatefulInputs/StatefulSelect/StatefulSelect.types';

const useStyles = makeStyles<DefaultTheme, Record<'columns', number | undefined>>((theme) => ({
	menu: ({ columns }) => ({
		'& .MuiPaper-root .MuiList-root': {
			columns: columns,
			padding: theme.spacing(2),
		},
		'& .MuiPaper-root.MuiMenu-paper': {
			width: '40%',
		},
		'& li.MuiMenuItem-root': {
			'textWrap': 'wrap',
			'alignItems': 'flex-start',
			'& .MuiListItemText-root': {
				marginTop: theme.spacing(1),
			},
			'paddingTop': 0,
			'paddingBottom': 0,
		},
		'& .MuiListItem-root.Mui-selected': {
			backgroundColor: 'unset'
		}
	}),
	formControlLabel: {
		'& .MuiTypography-root': {
			fontWeight: 400,
		}
	},
}));

export function StatefulMultiSelect<FormType extends FieldValues>({
	options,
	controllerOptions,
	columns,
	...statefulProviderProps
}: StatefulMultiSelectProps<FormType>): ReactElement {
	const classes = useStyles({ columns });
	const { field } = useController<FormType>(
		{ name: statefulProviderProps.name, ...controllerOptions }
	);
	const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>(field.value || []);

	const handleOptionChange = useCallback((option: SelectOption) => {
		setSelectedOptions((prevSelected) => {
			const isSelected = isOptionSelected(prevSelected, option);
			if (isSelected) {
				return prevSelected.filter((item) => item.value !== option.value);
			} else {
				return [...prevSelected, option];
			}
		});
	}, []);

	const handleCheckboxClick: ChangeEventHandler<HTMLInputElement> = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		const label = getOptionLabelFromValue(value, options);
		if (!label) { return; }

		handleOptionChange({ value, label });
	}, [handleOptionChange, options]);

	const handleLabelClick: MouseEventHandler<HTMLDivElement> = useCallback((
		event: React.MouseEvent<HTMLDivElement> & { target: { innerText?: string }}
	) => {
		const label = event.target?.innerText;
		if (!label) { return; }
		const value = getOptionValueFromLabel(label, options);
		if (!value) { return; }

		handleOptionChange({ label, value });
	}, [handleOptionChange, options]);

	const handleBlur = useCallback(() => {
		field.onChange(selectedOptions);
	}, [field, selectedOptions]);

	const handleEnterKeyToSelect = useCallback((event: React.KeyboardEvent<HTMLLIElement>, option: SelectOption) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			handleOptionChange(option);
		}
	}, [handleOptionChange]);

	return (
		<StandardInputWrapper<FormType>
			{...statefulProviderProps}
			labelProps={{
				id: `multiselect-label-${statefulProviderProps.name}`,
				htmlFor: `multiselect-field-${statefulProviderProps.name}`,
			}}
		>
			<Select
				{...field}
				name={statefulProviderProps.name}
				id={`multiselect-field-${statefulProviderProps.name}`}
				labelId={`multiselect-label-${statefulProviderProps.name}`}
				value={field.value || controllerOptions.defaultValue || ''}
				multiple
				MenuProps={{
					className: classes.menu,
					// disableAutoFocusItem & getContentAnchorEl are required
					// to prevent the menu from auto scrolling on option click
					disableAutoFocusItem: true,
					getContentAnchorEl: null,
				}}
				renderValue={() => renderSelectedValues(field.value)}
				onBlurCapture={handleBlur}
			>
				{options.map((option) => (
					<MenuItem
						key={option.value}
						value={option.value}
						onKeyDown={(event) => handleEnterKeyToSelect(event, option)}
					>
						<Checkbox
							color="primary"
							value={option.value}
							checked={isOptionSelected(selectedOptions, option)}
							onChange={handleCheckboxClick}
							aria-labelledby={option.label}
						/>
						<ListItemText
							aria-label={option.label}
							primary={option.label}
							onClick={handleLabelClick}
						/>
					</MenuItem>
				))}
			</Select>
		</StandardInputWrapper>
	);
}
