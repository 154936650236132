import React from 'react';
import {
	Settings as SettingsIcon,
	KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Menu } from '@ascension/web';
import { Box, ClickAwayListener, Popper } from '@material-ui/core';
import GoldButton from '@components/GoldButton/GoldButton';
import { useSelector } from 'react-redux';
import { UserProfilePrimaryRoleSelector } from '@store/selectors/UserProfileSelector';
import manageCaseOptionsByRole from '@components/CaseDetails/ManageCaseDropDown/utilities/manageCaseOptionsByRole';
import { CaseDetailsAppointmentSelector } from '@store/selectors/CaseDetailsSelector';

const useStyles = makeStyles((theme) => ({
	menuButton: {
		'&.MuiButton-contained': {
			padding: theme.spacing(1, 2),
		},
	},
	menuContainer: {
		width: '240px',
		zIndex: 1,
		padding: theme.spacing(0.5, 0),
	},
}));

export const ManageCaseDropDown: React.FC = () => {
	const classes = useStyles();
	const [isOpen, setIsOpen] = React.useState(false);
	const anchorRef = React.useRef<HTMLDivElement>(null);

	const handleToggle = () => {
		setIsOpen((prevOpen) => !prevOpen);
	};

	const handleClose = () => {
		setIsOpen(false);
	};

	const { status } = useSelector(CaseDetailsAppointmentSelector);
	const role = useSelector(UserProfilePrimaryRoleSelector);

	const options = React.useMemo(() => {
		if (!role) {
			return null;
		}
		return manageCaseOptionsByRole(status)[role];
	}, [role, status]);

	if (!options?.length) {  return null; }

	return (
		<Box display="flex" alignItems="center">
			<ClickAwayListener onClickAway={handleClose}>
				<div ref={anchorRef}>
					<GoldButton
						data-field="manageCase-dropDown-toggleButton"
						className={classes.menuButton}
						onClick={handleToggle}
						startIcon={<SettingsIcon />}
						endIcon={<KeyboardArrowDownIcon />}
					>
						Manage Case
					</GoldButton>
					<Popper open={isOpen} anchorEl={anchorRef.current} placement="bottom-end" className={classes.menuContainer}>
						<Menu standalone isOpen={isOpen} data-field="manageCase-dropDown-menu">
							{options.map(({ renderAs: RenderAs, id }) => <RenderAs key={id} closeDropdown={handleClose}/>)}
						</Menu>
					</Popper>
				</div>
			</ClickAwayListener>
		</Box>
	);
};
