import { AppointmentType } from '@data/request/AppointmentType';
import { useFormContext } from 'react-hook-form';
import CommonRequestForm from '@interfaces/RequestForm/forms/CommonRequestForm';
import { useRequestFormContext } from '@components/RequestForm/RequestForm';

const useIsDocRequired = () => {
	const { watch } = useFormContext<CommonRequestForm>();
	const [ appointmentType ] = watch(['appointmentType']);
	const { isEdit } = useRequestFormContext();

	return !isEdit && appointmentType === AppointmentType.DOCUMENT;
};

export default useIsDocRequired;
