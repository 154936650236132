import React from 'react';
import {
	CaseDetailsPageContextType
} from '@components/CaseDetails/CaseDetailsContext/CaseDetailsPageContext/CaseDetailsPageContextType';
import { PermittedAction } from '@utilities/PermittedAction';
import useEnableWithPermission from '@utilities/hooks/useEnableWithPermission/useEnableWithPermission';
import { useSelector } from 'react-redux';
import { CaseDetailsIsAttachmentType } from '@store/selectors/CaseDetailsSelector';
import useHasPermission from '@utilities/hooks/useHasPermission/useHasPermission';
import { Attachment } from '@interfaces/SurgeryRequest/Attachment';

const CaseDetailsPageContext = React.createContext<CaseDetailsPageContextType | null>(null);

/**
 * useCaseDetailsPageContext - should be used to access/manage state related to case details page UI (i.e. drawer, button state)
 *
 * @returns CaseDetailsPageContextType
 */
export const useCaseDetailsPageContext = () => {
	const ctx = React.useContext(CaseDetailsPageContext);

	if (!ctx) {
		throw new Error('useCaseDetailsPageContext must be used in a subcomponent of CaseDetailsPageContextProvider');
	}

	return ctx;
};

export const CaseDetailsPageContextProvider: React.FC = ({ children }) => {
	const isAttachmentType = useSelector(CaseDetailsIsAttachmentType);
	const [showCondensedView, setShowCondensedView] = useEnableWithPermission(PermittedAction.VIEW_CONDENSED, true);

	const [shouldShowEdit, showEdit] = useEnableWithPermission(PermittedAction.MANAGE_CASE);
	const showCondensedViewToggle = useHasPermission(PermittedAction.VIEW_CONDENSED);

	const [selectedAttachment, setSelectedAttachment] = React.useState<undefined | Attachment>(undefined);

	React.useEffect(() => {
		setShowCondensedView(!isAttachmentType);
	}, [isAttachmentType, setShowCondensedView]);

	return (
		<CaseDetailsPageContext.Provider value={{
			showCondensedView,
			setShowCondensedView,
			showEdit,
			shouldShowEdit,
			showCondensedViewToggle,
			selectedAttachment,
			setSelectedAttachment,
		}}>
			{children}
		</CaseDetailsPageContext.Provider>
	);
};
