import React   from 'react';
import { Box, Button, Flex, Text } from '@ascension/web';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ErrorMessage from '@components/ErrorMessage/ErrorMessage';
import { CaseDetailsInfoSelector } from '@store/selectors/CaseDetailsSelector';
import { cancelCase } from '@store/actions/CaseDetailsActionCreators';
import { RequestRevisionSchema } from '@utilities/Validation/validationSchema';
import ModalViewer from '@components/Modal/ModalViewer';
import { CANCEL_MODAL } from '@components/Modal/modalConstants';
import { useModalContext } from '@components/Modal/ModalContextProvider';
import TextFieldInput from '@components/TextFieldInput/TextFieldInput';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	textArea: {
		'& .MuiOutlinedInput-inputMultiline': {
			overflowY: 'auto',
			padding: theme.spacing(0)
		},
		'& .MuiOutlinedInput-root': {
			height: 'auto',
		},
		'& .MuiInputLabel-outlined': {
			transform: `translate(0, 0px) scale(${theme.spacing(0.1)})`,
		},
		'&> .MuiInputLabel-root': {
			fontSize: '16px',
			color: theme.palette.grey[600]
		}
	}
}));

export default function CancelCaseModal() {
	const classes = useStyles();
	const { closeModal } = useModalContext();
	const { register, formState: { errors }, handleSubmit, setValue, getValues } = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		defaultValues:  {
			message: ''
		}
	});
	const { id: caseId } = useSelector(CaseDetailsInfoSelector);
	const [disableButtons, setDisableButtons] = React.useState(true);
	const dispatch = useDispatch();

	const onSubmit = () => {
		dispatch(cancelCase(caseId, getValues().message));
		closeModal(CANCEL_MODAL);
	};

	const handleCancelBtnClick = () => {
		closeModal(CANCEL_MODAL);
	};

	const updateMessage = ((value: string) => {
		if (value === '') {
			setDisableButtons(true);
		} else {
			setDisableButtons(false);
		}
		setValue('message', value, { shouldValidate: true });
	});

	React.useLayoutEffect(() => {
		register('message', RequestRevisionSchema.message);
	}, [register]);

	return (
		<ModalViewer id={CANCEL_MODAL}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Flex flexDirection="row" justifyContent="center" alignItems="start" mx="40px" data-section="RequestRevision">
					<Flex flexDirection="column">
						<Flex justifyContent="space-between" letterSpacing={1} width={1} alignItems="start">
							<Box>
								<Text fontSize="26px" fontWeight="bold" color="black">
									Cancel Case
								</Text>
							</Box>
						</Flex>
						<Flex justifyContent="space-between" letterSpacing={1} width={1} alignItems="start" pb="24px">
							<Box>
								<Text fontSize={14}>
									This action cannot be undone. The hospital scheduler will be notified of this cancellation.
								</Text>
							</Box>
						</Flex>
						<Box data-field="message">
							<TextFieldInput
								fieldName="cancelMessage"
								className={classes.textArea}
								multiline
								label="MESSAGE"
								id="message"
								required
								minRows={3}
								value={getValues().message}
								onChange={updateMessage}
								fullWidth
							/>
							<ErrorMessage errorField={errors.message}/>
						</Box>
						<Box textAlign="right" mt="30px">
							<Button className="margin-right-24" type="button" id="cancel" onClick={handleCancelBtnClick} secondary>
								Cancel
							</Button>
							<Button id="cancelCase" type="submit" disabled={disableButtons} buttonColor="#BF0000">
								<Text style={{ color:'white' }}>Confirm Cancellation</Text>
							</Button>
						</Box>
					</Flex>
				</Flex>
			</form>
		</ModalViewer>
	);
}
