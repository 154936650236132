import React, { useCallback } from 'react';
import {
	Box,
	Dropdown,
	Label,
	Flex,
	Radio,
} from '@ascension/web';
import PreAdmitTestSubform from './PreAdmitTestSubform/PreAdmitTestSubform';
import { useFieldFormContext } from '../../../utilities/hooks/useFieldFormContext/useFieldFormContext';
import { useDropdownFormContext } from '../../../utilities/hooks/useDropdownFormContext/useDropdownFormContext';
import { PreAdmitTestDetailsProps } from './PreAdmitTestDetailsProps';
import { YesNoAnswer } from '../../../interfaces/SurgeryRequest/YesNoAnswer';

const PreAdmitTestDetails = ({ isEdit }: PreAdmitTestDetailsProps) => {

	const {
		currentValue: patTestingNeeded,
		setCurrentValue: patTestingNeededSetCurrentValue,
	} = useFieldFormContext<string>('patTestingNeeded');

	const {
		selectedDropdownOption: bedNeededSelectedOption,
		setSelectedValue: setBedNeededVal,
		dropdownOptions: bedNeededOptions,
	} = useDropdownFormContext('patBedNeeded',
		[
			{
				label: 'Direct Admit',
				value: 'Direct Admit',
			},
			{
				label: 'Pre-Hospital Bed',
				value: 'Pre-Hospital Bed',
			},
			{
				label: 'None Needed',
				value: 'None Needed',
			},
		],
	);

	const handleRadioYes = useCallback((checked: boolean) => {
		if (checked) {
			patTestingNeededSetCurrentValue(YesNoAnswer.YES);
		}
	}, [patTestingNeededSetCurrentValue]);

	const handleRadioNo = useCallback((checked: boolean) => {
		if (checked) {
			patTestingNeededSetCurrentValue(YesNoAnswer.NO);
		}
	}, [patTestingNeededSetCurrentValue]);

	return React.useMemo(() => (<>
		<Box data-field="PAT_BedNeeded">
			<Dropdown
				label="Is a pre-hospital bed needed?"
				placeholder="Select"
				options={bedNeededOptions}
				selected={bedNeededSelectedOption}
				onSelect={setBedNeededVal}
			/>
		</Box>
		<Box data-field="PAT_TestingNeeded" pt={8}>
			<Label>Is pre-admission testing needed</Label>
			<Flex flexDirection="row">
				<Box pr={5}>
					<Radio
						name="patTestingNeeded"
						key="Yes"
						label="Yes"
						value="Yes"
						checked={patTestingNeeded === YesNoAnswer.YES || false}
						onChange={handleRadioYes}
					/>
				</Box>
				<Radio
					name="patTestingNeeded"
					key="No"
					label="No"
					value="No"
					checked={patTestingNeeded === YesNoAnswer.NO || false}
					onChange={handleRadioNo}
				/>
			</Flex>
		</Box>
		{patTestingNeeded === YesNoAnswer.YES &&
			<PreAdmitTestSubform isEdit={isEdit}/>
		}
	</>), [
		bedNeededOptions,
		bedNeededSelectedOption,
		setBedNeededVal,
		patTestingNeeded,
		handleRadioYes,
		handleRadioNo,
		isEdit
	]);
};

export default PreAdmitTestDetails;
