import React, { ChangeEvent } from 'react';
import { Box } from '@material-ui/core';
import { TextField } from '@ascension/ui-library';
import { useFieldFormContext } from '../../../../utilities/hooks/useFieldFormContext/useFieldFormContext';
import { makeStyles } from '@material-ui/core/styles';
import ErrorMessageMui from '../../../ErrorMessageMui/ErrorMessageMui';
import { BasicUserInfoFormValidationSchema } from '../BasicUserInfoFormValidationSchema';

const useStyles = makeStyles({
	input: {
		width: '149px',
	},
});

const LastFourSSNInput: React.FC = () => {
	const classes = useStyles();
	const {
		currentValue = '',
		setCurrentValue,
		error,
		triggerSelfValidation,
	} = useFieldFormContext<string>('lastFourSsn', {
		validationRules: BasicUserInfoFormValidationSchema.lastFourSsn,
	});

	const filterChangeRegex = new RegExp('[^0-9]');
	const validPasteRegex = new RegExp('^[0-9]+$');

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const filtered = e.target.value.replace(filterChangeRegex, '');
		if (filtered.length > 4) { return; }
		setCurrentValue(filtered);
	};

	const handlePaste = (event: React.ClipboardEvent) => {
		event.preventDefault();
		const pasteData = event.clipboardData ? event.clipboardData.getData('text') : '';
		if (!validPasteRegex.test(pasteData)) {
			setCurrentValue('');
			return;
		}
		setCurrentValue(pasteData.substring(0, 4));
	};

	return (
		<Box>
			<TextField
				label="User&apos;s last 4 digits of Social Security Number"
				translate="yes"
				variant="static"
				required
				value={currentValue}
				onChange={handleChange}
				onPaste={handlePaste}
				onBlur={triggerSelfValidation}
				InputProps={{ classes: { root: classes.input } }}
				error={!!error}
				id="last-four-ssn"
				data-field="last-four-ssn-input"
				aria-describedby="last-four-ssn-input-error"
				aria-labelledby="last-four-ssn-input-label"
			/>
			{ error && <span id="last-four-ssn-input-error"><ErrorMessageMui errorMessage={error} /></span> }
		</Box>
	);
};

export default LastFourSSNInput;
