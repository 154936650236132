import React from 'react';
import {
	ConfirmationStatus,
	ConfirmationStatusInfo,
	ConfirmCaseContextType,
	SaveConfirmOptions,
} from '@components/CaseDetails/CaseDetailsContext/ConfirmCaseContext/ConfirmCaseContextType';
import { useDispatch } from 'react-redux';
import { useToast } from '@utilities/hooks/useToast/useToast';
import { useCaseDetailsInfoContext } from '@components/CaseDetails/CaseDetailsContext/CaseDetailsInfoContext/CaseDetailsInfoContext';
import { AxiosResponse } from 'axios';
import { confirmIdSave } from '@store/actions/HospitalUnscheduledAlertActionCreators';
import { DEFAULT_ERROR_CONFIGURATION } from '@components/CaseDetails/CaseDetailsContext/ConfirmCaseContext/ConfirmCaseConfiguration';
import { useConfirmationConfiguration } from '@components/CaseDetails/CaseDetailsContext/ConfirmCaseContext/ConfirmCaseUtilities';
import { useDrawerContext } from '@components/DrawerWrapper/DrawerContextProvider';
import NoParamNoReturnCallback from '@interfaces/NoParamNoReturnCallback';

const ConfirmCaseContext = React.createContext<ConfirmCaseContextType | null>(null);

interface ResponseResolutionCallbacks {
	successResolve: NoParamNoReturnCallback;
	errorReject: NoParamNoReturnCallback;
}

const INITIAL_CONFIRMATION_STATUS: ConfirmationStatusInfo = {
	status: ConfirmationStatus.UNDEFINED,
};
const useConfirmCaseMethods = (): ConfirmCaseContextType => {
	const [confirmationCaseNumber, setConfirmationCaseNumber] = React.useState<string>('');

	const dispatch = useDispatch();
	const displayToast = useToast();
	const [confirmationStatusInfo, setConfirmationStatusInfo] = React.useState<ConfirmationStatusInfo>(INITIAL_CONFIRMATION_STATUS);
	const { caseId } = useCaseDetailsInfoContext();
	const { openDrawer } = useDrawerContext();
	const responseResolutionCallbackRef = React.useRef<ResponseResolutionCallbacks | null>(null);

	const  { indexByApiStatus: responseConfig } = useConfirmationConfiguration();

	const saveConfirmId = React.useCallback((confirmId: string, { force = false, fin: optionalFin, getResponseConfig }: SaveConfirmOptions = {}) => {
		const handleResponse = (response?: AxiosResponse) => {
			const currentResponseConfig =
				response && (
					// Use the custom getResponseConfig handler
					(getResponseConfig && getResponseConfig(response)) ||
					// Otherwise use the config in the configuration based on the status code
					responseConfig[response.status]
				) ||
				// Otherwise use the default error configuration
				DEFAULT_ERROR_CONFIGURATION;

			const {
				confirmationStatus,
				toast,
				drawer,
				error: isErrorResponse,
				modifiedResponse,
			} = currentResponseConfig;

			setConfirmationStatusInfo({
				status: confirmationStatus,
				latestResponse: modifiedResponse || response,
			});

			if (drawer?.openOnApiResponse) {
				openDrawer(drawer.name);
			}

			if (toast) {
				displayToast(toast.type, toast.message);
			}

			const responseResolutionCallback = responseResolutionCallbackRef.current?.[isErrorResponse ? 'errorReject' : 'successResolve'];
			if (responseResolutionCallback) {
				responseResolutionCallback();
			}

			responseResolutionCallbackRef.current = null;
		};

		const responseResolution = new Promise<void>((successResolve, errorReject) => {
			responseResolutionCallbackRef.current = { successResolve, errorReject };
		});
		setConfirmationStatusInfo(INITIAL_CONFIRMATION_STATUS);
		setConfirmationCaseNumber(confirmId);
		dispatch(confirmIdSave(caseId, confirmId, handleResponse, force, optionalFin));

		return responseResolution;
	}, [dispatch, caseId, responseConfig, openDrawer, displayToast]);

	return {
		saveConfirmId,
		confirmationStatus: confirmationStatusInfo.status,
		latestConfirmationResponse: confirmationStatusInfo.latestResponse,
		confirmationCaseNumber,
	};
};

export const useConfirmCaseContext = () => {
	const ctx = React.useContext(ConfirmCaseContext);

	if (!ctx) {
		throw new Error(`useConfirmCaseContext and dependent hooks must be used within a subcomponent of ConfirmCaseProvider`);
	}

	return ctx;
};

const ConfirmCaseProvider: React.FC = ({ children }) => {
	const confirmCaseValues = useConfirmCaseMethods();

	return <ConfirmCaseContext.Provider value={confirmCaseValues}>
		{children}
	</ConfirmCaseContext.Provider>;
};

export default ConfirmCaseProvider;
