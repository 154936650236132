import { useSelector } from 'react-redux';
import { UserPreferenceSelector } from '@store/selectors/UserProfileSelector';
import React from 'react';
import ArrayFilter from '@interfaces/ArrayFilter';
import UnitMinimal from '@interfaces/Unit/UnitMinimal';
import ListAndById from '@interfaces/ListAndById';
import { UserFormHospital } from '@interfaces/UserPrefInterface';


function listIsUserFormHospital(list: unknown): list is UserFormHospital[] {
	return !!list && Array.isArray(list) && (list.length === 0 || 'units' in list[0]);
}

const usePreferredUnits = (filter?: ArrayFilter<UnitMinimal>): ListAndById<UnitMinimal> => {
	const { hospitals } = useSelector(UserPreferenceSelector);

	const indexedById = React.useMemo(() => {
		if (!hospitals || hospitals.length === 0 || !listIsUserFormHospital(hospitals)) { return {}; }

		return hospitals.reduce((unitsIndexed: Record<string, UnitMinimal>, { id, units }) => {
			// copy list so it's not modified
			const duplicateUnitIndexed = { ...unitsIndexed };
			units.forEach((u) => {
				// Add hospital id
				const augmentedUnit: UnitMinimal = {
					...u,
					hospital: id,
				};

				// filter provided and false from filter
				if (filter && !filter(augmentedUnit)) {
					// then don't push to list, move to next
					return;
				}

				duplicateUnitIndexed[u.id] = augmentedUnit;
			});

			return duplicateUnitIndexed;
		}, {});
	}, [filter, hospitals]);

	const list = React.useMemo(() => Object.values(indexedById), [indexedById]);

	return { indexedById, list };
};

export default usePreferredUnits;
