import React from 'react';
import {
	DEFAULT_DURATION_MINS
} from '@components/SchedulePage/utilities/convertEventResponseToUiData/convertEventResponseToUiData';
import getDateFromApiDateTime from '@calendar/utilities/getDateFromApiDateTime/getDateFromApiDateTime';
import { addMinutes, set } from 'date-fns';
import { doEventsOverlap } from '@calendar';
import { useFormContext } from 'react-hook-form';
import { RequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import DocumentRequestFormType from '@interfaces/RequestForm/forms/DocumentRequestFormType';
import useRequestFormSlots from '@components/RequestForm/utilities/hooks/useCheckBlockTimeOverlap/useRequestFormSlots/useRequestFormSlots';
import SlotResponse from '@data/schedules/types/SlotResponse';
import { useCollectionEntity } from '@utilities/hooks/useCollectionEntity/useCollectionEntity';
import { Unit } from '@data/unit/Unit';
import { ENDPOINT_USER_UNITS } from '@utilities/apiConstants';

export const useCheckBlockTimeOverlap = (releasedSlots?: SlotResponse[]): boolean | undefined => {
	const { slots } = useRequestFormSlots();

	const { watch } = useFormContext<RequestFormValidatedState | DocumentRequestFormType>();
	const [
		procedureDate,
		procedureTime,
		duration,
		appointmentLocation,
	] = watch([
		'procedureDate',
		'procedureTime',
		'duration',
		'appointmentLocation',
	]);

	const selectedFacility = useCollectionEntity<Unit>(ENDPOINT_USER_UNITS, appointmentLocation || '', 'id');

	return React.useMemo((): boolean | undefined => {
		// returns true if validation passes (i.e. there IS overlap)
		// returns false if validation fails (i.e. there is NO overlap)
		// returns undefined if missing data and validation shouldn't continue to run
		if (
			!slots ||
			!procedureDate ||
			!procedureTime
		) { return undefined; }


		const [hours, minutes] = procedureTime[0].split(':').map(Number);
		const selectedDateAndTimeHospital = set(procedureDate, { hours, minutes });

		const validationDuration = Number(duration) || DEFAULT_DURATION_MINS;

		const mergedSlots = slots.concat(releasedSlots || []);
		return mergedSlots.some((slotInterval) => {
			return doEventsOverlap(
				// Slot Interval
				{
					start: getDateFromApiDateTime(slotInterval.start, selectedFacility?.hospitalTimeZone),
					end: getDateFromApiDateTime(slotInterval.end, selectedFacility?.hospitalTimeZone)
				},
				// Requested Appointment Time
				{
					start: selectedDateAndTimeHospital,
					end: addMinutes(selectedDateAndTimeHospital, validationDuration),
				},
			);
		});
	}, [duration, procedureDate, procedureTime, releasedSlots, selectedFacility, slots]);
};

