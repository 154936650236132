import React, { Context } from 'react';
import { FormProvider, RegisterOptions } from 'react-hook-form';
import { FormMetaProviderProps } from './FormMetaProviderProps';
import { FieldValues } from 'react-hook-form';
import { FormMeta } from './FormMeta';

const FormMetaContext = React.createContext<FormMeta<unknown> | null>(null);

/***
 * useFormMeta must be used within a FormMetaProvider child.
 * It provides the context provided in a parent component to FormMetaProvider regardless of depth.
 * This eliminates the need for prop drilling or cluttering the global state.
 *
 * @example
 * const { schema } = useFormMeta();
 *
 * @throws Will throw an error if not used within a FormMetaProvider child component.
 * @returns FormMetaContext
 */
export function useFormMeta<T = unknown>(): FormMeta<T> {
	const context = React.useContext(FormMetaContext as Context<FormMeta<T>>);
	if (!context) {
		throw new Error('useFormMeta must be used within a FormMetaProvider child.');
	}

	return context;
}

/***
 * useFormMetaSchema must be used within a FormMetaProvider child.
 * It provides the schema for the provided field.
 *
 * @example
 * const validation = useFormMetaSchema('unit');
 *
 * @throws Error if not used within a FormMetaProvider child component.
 * @returns RegisterOptions that can be passed to the registration
 */
export function useFormMetaSchema<T = unknown>(field: keyof T): RegisterOptions {
	const context = React.useContext(FormMetaContext as Context<FormMeta<T>>);
	if (!context) {
		throw new Error('useFormMetaSchema must be used within a FormMetaProvider child.');
	}

	return context.schema[field] || {};
}

/***
 * FormMetaProvider component should be used in place of FormProvider.
 * This component allows for passing additional properties to sub forms and components with a local context.
 * This should be easier to manage where global state may complicate the logic.
 *
 * @example
 * const formMethods = useForm();
 * const requestProcedureSchema = { subForm: { required: true } };
 * <FormMetaProvider schema={requestProcedureSchema} {...formMethods}>
 *     <SomeSubForm />
 * </FormMetaProvider>
 *
 * @param schema: Object defining validation rules in format {[name: string]: Partial<RegisterOptions>};
 * @param {{ [name: string]: DropdownOption[] }} options: DropdownOptions by name
 * @param children
 * @param rest: UseFormReturn as returned from useForm hook
 * @constructor
 */
export function FormMetaProvider<T extends FieldValues>({ schema, options = {}, children, ...rest }: FormMetaProviderProps<T>) {
	return (
		<FormMetaContext.Provider value={{ schema, options }}>
			<FormProvider<T> {...rest}>
				{children}
			</FormProvider>
		</FormMetaContext.Provider>
	);
}
