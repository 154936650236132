import { PermittedAction } from '../../PermittedAction';
import useHasPermission from '../useHasPermission/useHasPermission';
import React, { SetStateAction } from 'react';

type UseEnableWithPermissionReturn = [boolean, (setTo?: SetStateAction<boolean>) => void];


/***
 * useEnableWithPermission - hook to abstract logic for conditionally toggling value only when permission.
 *
 * @param permission {PermittedAction} - Name of permitted action used to find the permissions from PermittedActionToUserRole mapping.
 * @param initialState {boolean} - Initial value for enableRequested value. Still requires permission to match to return true for enabled.
 *
 * @example
 * const [showCondensed, requestEnable: requestShowCondensed] = useEnableWithPermission(PermittedAction.ENTER_FIN, false);
 *
 * const toggleCondensed = requestShowCondensed((val) => !val);
 *
 * return showCondensed && <CondensedView />;
 *
 * @returns [boolean, (setTo?: SetStateAction<boolean>) => void] - array containing current value based on permission and requested value, method to set requested value
 */
const useEnableWithPermission = (permission: PermittedAction, initialState = false): UseEnableWithPermissionReturn => {
	const hasPermission = useHasPermission(permission);
	const [enableRequested, setEnableRequested] = React.useState(initialState);

	const enabled = hasPermission && enableRequested;
	const requestEnable = React.useCallback((enable: SetStateAction<boolean> = true) => {
		setEnableRequested(enable);
	}, []);

	return [enabled, requestEnable];
};

export default useEnableWithPermission;
