import React from 'react';
import { Banner } from '@components/Banner/Banner';
import { useSelector } from 'react-redux';
import { UserProfileDetailsSelector } from '@store/selectors/UserProfileSelector';
import { bannerConstants } from '@components/AuthenticatedBanner/bannerConstants';
import { Box, makeStyles, Typography } from '@material-ui/core';
import CopyText from '@components/CopyText/CopyText';
import { SecurityAlertBadge } from '@components/SecurityAlertBadge/SecurityAlertBadge';
import classNames from 'classnames';
import { useSsmUiFlags } from '@utilities/hooks/useSsmUiFlags/useSsmUiFlags';

const useStyles = makeStyles((theme) => ({
	subTitle: {
		marginBottom: theme.spacing(1),
	},
	titleText: {
		fontWeight: 600,
	},
	copyTextClass: {
		flex: 'auto 1 1',
		flexDirection: 'row-reverse',
		justifyContent: 'flex-end',
		gap: '5px',
		fontWeight: 600,
	},
	copyIcon: {
		height: '18px',
		width: '18px',
	},
}));

export const AuthenticatedBanner: React.FC = () => {
	const classes = useStyles();

	const { ssmuiShowAuthenticatedBanner } = useSsmUiFlags();

	const { adEmail } = useSelector(UserProfileDetailsSelector);

	const [showBannerState, setshowBannerState] = React.useState(true);

	const showBanner = showBannerState && ssmuiShowAuthenticatedBanner;

	React.useEffect(() => {
		const hideBanner = sessionStorage.getItem('showAuthenticatedBanner') === 'false';
		setshowBannerState(!hideBanner);
	}, []);

	const handleClose = React.useCallback(() => {
		setshowBannerState(false);
		sessionStorage.setItem('showAuthenticatedBanner', 'false');
	}, []);

	if (!showBanner) {
		return null;
	}

	return (
		<Banner
			img={() => <SecurityAlertBadge />}
			title={bannerConstants.TITLE}
			onClose={handleClose}
		>
			{adEmail && <Box>
				<Typography variant="body2" className={classes.titleText}>{bannerConstants.BODY_TITLE}</Typography>
				<Typography variant="body2" className={classNames(classes.subTitle, classes.titleText)}>{bannerConstants.SUB_TITLE}</Typography>
				<CopyText
					successMessage="Ascension email address has been copied"
					className={classes.copyTextClass}
					typographyProps={{ variant: 'body2', color: 'primary', className: classes.copyTextClass }}
					iconButtonProps={{ className: classes.copyIcon }}
				>
					{adEmail}
				</CopyText>
			</Box>}
		</Banner>
	);
};
