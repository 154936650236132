import React from 'react';
import { RequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import { useDispatch, useSelector } from 'react-redux';
import { saveForm, updateForm } from '@store/actions/RequestFormActionCreators';
import { useToast } from '@utilities/hooks/useToast/useToast';
import { ToastType } from '@interfaces/ToastStatus/ToastStatusState';
import { ApiActionPayload } from '@interfaces/ApiAction/ApiActionPayload';
import { AxiosError, AxiosResponse } from 'axios';
import { ApiResultHandlerReturn } from '@interfaces/ApiAction/ApiAction';
import { CaseDetailsInfoSelector } from '@store/selectors/CaseDetailsSelector';
import useSupplementalInfoFromUnit from '@utilities/hooks/useSupplementalInfoFromUnit/useSupplementalInfoFromUnit';
import { apiEnd, apiStart } from '@store/middlewares/api/ApiActionCreators';
import { REQUEST_FORM_DUMMY_LOADER } from '@store/actions/types';
import { ErrorStatusState } from '@interfaces/ErrorStatus/ErrorStatusState';
import { SurgeryRequestGet } from '@interfaces/SurgeryRequest/SurgeryRequestGet';

interface UseSubmitRequestProps extends Partial<ApiActionPayload<FormData, ErrorStatusState, SurgeryRequestGet>> {
	/**
	 * WARNING: adding a delay to the success callback may lead to
	 *  unexpected behavior especially if any action happens outside the
	 *  submit handler. For example, do NOT use formState.SUBMITTED
	 *  to redirect. Use the success  handler instead  */
	successDelayMs?: number;
	successToastMessage: string;
	errorToastMessage: string;
	isNew?: boolean;
}

const useSubmitRequest = ({
	successToastMessage,
	errorToastMessage,
	isNew = false,
	successDelayMs = 0,
	onSuccess: providedOnSuccess,
	onFailure,
	...extendedAction
}: UseSubmitRequestProps) => {
	const dispatch = useDispatch();
	const showToast = useToast();

	const [formBeginTimestamp, setFormBeginTimestamp] = React.useState(new Date());

	const resetStartTime = React.useCallback(() => {
		setFormBeginTimestamp(new Date());
	}, []);

	const getSupplementalInfo = useSupplementalInfoFromUnit();

	const lastTimeoutRef = React.useRef<ReturnType<typeof setTimeout>>();
	React.useEffect(() => {
		// To avoid race condition, do not run timeout when component unmounts
		return () => {
			if (lastTimeoutRef.current) {
				clearTimeout(lastTimeoutRef.current);
			}
		};
	}, []);

	const apiActionCreatorProps = React.useMemo(() => {
		const successHandler = (response: AxiosResponse<SurgeryRequestGet>): ApiResultHandlerReturn => {
			resetStartTime();
			showToast(ToastType.SUCCESS, successToastMessage);
			dispatch(apiEnd(REQUEST_FORM_DUMMY_LOADER));
			return providedOnSuccess?.(response);
		};

		const onSuccess = (response: AxiosResponse<SurgeryRequestGet>): ApiResultHandlerReturn => {
			lastTimeoutRef.current = setTimeout(() => {
				successHandler(response);
			}, successDelayMs);
		};

		const failureHandler = (e: AxiosError<ErrorStatusState>): ApiResultHandlerReturn => {
			dispatch(apiEnd(REQUEST_FORM_DUMMY_LOADER));
			showToast(ToastType.ERROR, errorToastMessage);
			return onFailure?.(e);
		};

		return ({
			...extendedAction,
			onSuccess,
			onFailure: failureHandler,
		});
	}, [dispatch, errorToastMessage, extendedAction, onFailure, providedOnSuccess, resetStartTime, showToast, successDelayMs, successToastMessage]);

	const createSaveFormAction = React.useMemo(() => saveForm(apiActionCreatorProps), [apiActionCreatorProps]);

	const createUpdateFormAction = React.useMemo(() => updateForm(apiActionCreatorProps), [apiActionCreatorProps]);

	// Memoize case info - only retrieved when save initiated
	const currentCaseInfo = useSelector(CaseDetailsInfoSelector);
	const caseInfoRef = React.useRef(currentCaseInfo);
	React.useEffect(() => {
		caseInfoRef.current = currentCaseInfo;
	}, [currentCaseInfo]);

	const save = React.useMemo(() => {
		const saveNew = (formResults: RequestFormValidatedState) => {
			const timeToSchedule = formBeginTimestamp && Math.round((+new Date() - +formBeginTimestamp)/1000);
			const formWithTime = timeToSchedule ? { ...formResults, timeToSchedule } : formResults;
			dispatch(apiStart(REQUEST_FORM_DUMMY_LOADER, REQUEST_FORM_DUMMY_LOADER, REQUEST_FORM_DUMMY_LOADER, false));
			dispatch(createSaveFormAction(formWithTime, getSupplementalInfo(formResults.appointmentLocation)));
		};

		const saveUpdate = (formResults: RequestFormValidatedState) => {
			dispatch(apiStart(REQUEST_FORM_DUMMY_LOADER, REQUEST_FORM_DUMMY_LOADER, REQUEST_FORM_DUMMY_LOADER, false));
			dispatch(createUpdateFormAction(formResults, getSupplementalInfo(formResults.appointmentLocation), caseInfoRef.current));
		};

		return isNew ? saveNew : saveUpdate;
	}, [createSaveFormAction, createUpdateFormAction, dispatch, getSupplementalInfo, isNew, formBeginTimestamp]);

	return { resetStartTime, save, formId: `${formBeginTimestamp.getTime()}` };
};

export default useSubmitRequest;
