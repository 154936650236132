import React from 'react';
import { useModalContext } from '@components/Modal/ModalContextProvider';

export const useControlModal = (modalId: string, shouldOpen: boolean) => {
	const { openModal, closeModal } = useModalContext();

	React.useEffect(() => {
		const toggleModal = shouldOpen ? openModal : closeModal;
		toggleModal(modalId);
	}, [closeModal, modalId, openModal, shouldOpen]);
};
