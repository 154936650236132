import React from 'react';
import { Button as AscButton } from '@ascension/ui-library';
import { makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core';
import { useManageUsersListContext } from '../../ManageUsersListContext/ManageUsersListContext';

const useStyles = makeStyles((theme) => createStyles({
	btnStyle: {
		width: 'fit-content',
		height: '48px',
		fontSize: '14px',
		lineHeight: '16px',
		marginRight: theme.spacing(2),
	},
}));


export const CloseUserDetailsButton = () => {
	const { btnStyle } = useStyles();

	const { closeDrawer } = useManageUsersListContext();

	return (
		<AscButton
			id="CloseUserDetail"
			translate="yes"
			onClick={closeDrawer}
			color="primary"
			className={btnStyle}
			data-field="CloseUserDetailsButton"
		>
                Close
		</AscButton>
	);
};
