import React from 'react';
import { DisplayText } from '../../DisplayText/DisplayText';
import { dateParse } from '../../../utilities/dateUtilities';
import { useSelector } from 'react-redux';
import { CaseDetailsPatDetailSelector } from '../../../store/selectors/CaseDetailsSelector';
import { YesNoAnswer } from '../../../interfaces/SurgeryRequest/YesNoAnswer';
import useDetailSectionGrid, { PatGridFields } from '../useDetailSectionGrid/useDetailSectionGrid';
import DetailSection from '../DetailSection';

export const PatInformationDisplay = () => {
	const {
		bedNeeded,
		date,
		location,
		preAdmissionTestingNeeded
	} = useSelector(CaseDetailsPatDetailSelector);
	const { gridContainer } = useDetailSectionGrid(DetailSection.PAT);

	const [parsedDate, time, meridiem] = React.useMemo(
		() => date && dateParse(date) || ['', '', ''],
		[date]);
	const cleanTime = (time).replace(/^0/, '');

	return (
		<div className={gridContainer}>
			<DisplayText
				label="Bed Needed"
				defaultValue="--"
				displayDefault={!bedNeeded}
				data-field="Pat_BN"
				style={{ gridArea: PatGridFields.BED_NEEDED }}
			>
				{bedNeeded}
			</DisplayText>
			<DisplayText
				label="Date"
				defaultValue="--"
				displayDefault={!date}
				data-field="Pat_DATE"
				style={{ gridArea: PatGridFields.DATE }}
			>
				{parsedDate} {cleanTime}{meridiem}
			</DisplayText>
			<DisplayText
				label="Location"
				defaultValue="--"
				displayDefault={!location}
				data-field="Pat_LOCATION"
				style={{ gridArea: PatGridFields.LOCATION }}
			>
				{location}
			</DisplayText>
			<DisplayText
				label="Pre Admission Testing Needed"
				defaultValue="--"
				data-field="Pat_PATN"
				style={{ gridArea: PatGridFields.PAT_NEEDED }}
			>
				{preAdmissionTestingNeeded ? YesNoAnswer.YES : YesNoAnswer.NO}
			</DisplayText>
		</div>
	);
};
