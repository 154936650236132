import React from 'react';
import {
	UnitCalendarPageContextProvider,
} from '@components/UnitCalendarPage/UnitCalendarPageContext/UnitCalendarPageContext';
import HospitalUnitCalendar from '@components/UnitCalendarPage/HospitalUnitCalendar/HospitalUnitCalendar';
import UnavailableTimeDrawer from '@components/UnitCalendarPage/UnavailableTimeDrawer/UnavailableTimeDrawer';
import { useAppPageBodyClassSetter } from '@components/AppPage/AppPageContext/AppPageContext';
import { makeStyles } from '@material-ui/core/styles';
import { ClickCoordinateBox } from '@components/ClickCoordinateBox/ClickCoordinateBox';

const useStyles = makeStyles(theme => ({
	bodyClass: {
		'& > div:first-child': {
			maxWidth: 'unset',
			margin: 'unset',
			backgroundColor: theme.palette.common.white,
		},
		'& [data-field="component-container"]': {
			paddingBottom: 0,
		},
	},
}));

const UnitCalendarPage = () => {
	const { setBodyClass } = useAppPageBodyClassSetter('UnitCalendarPage');
	const classes = useStyles();

	React.useEffect(() => {
		setBodyClass(classes.bodyClass);
	}, [classes.bodyClass, setBodyClass]);
	return (
		<UnitCalendarPageContextProvider>
			<ClickCoordinateBox>
				<UnavailableTimeDrawer />
				<HospitalUnitCalendar/>
			</ClickCoordinateBox>
		</UnitCalendarPageContextProvider>
	);
};

export default UnitCalendarPage;
