import { getTimezoneOffset } from 'date-fns-tz';
import { TODAY } from '@calendar/utilities/CalendarConstants';
import padString from '@calendar/utilities/padString/padString';

/***
 * timezoneAsHoursString - return timezone string in ISO format
 *
 * @param timezone {string} - timezone to determine timezone string offset
 * @param date {Date: new Date()}  - date to determine timezone string offset with daylight savings time considered
 *
 * @example
 * timezoneAsHoursString('America/Chicago') // returns either '-06:00' or '-05:00' depending on time of year
 *
 * @example
 * timeZoneAsHoursString('Asia/Calcutta') // returns '+05:30'
 *
 * @example
 * timezoneAsHoursString('America/Chicago', new Date(2022,1,1)) // returns '-06:00'
 */
const timezoneAsHoursString = (timezone: string, date = TODAY) => {
	const ms = getTimezoneOffset(timezone, date);
	const hrs = Math.abs(ms / 1000 / 60 / 60);
	const wholeHrs = Math.floor(hrs);
	const minutes = (hrs - wholeHrs) * 60;
	return `${ms < 0 ? '-' : '+'}${padString(wholeHrs.toString())}:${padString(minutes.toString())}`;
};

export default timezoneAsHoursString;
