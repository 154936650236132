import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { format } from 'date-fns-tz';
import { SectionedBoxHeader } from '@components/SectionedBox/SectionedBoxHeader/SectionedBoxHeader';
import { SectionedBoxBody } from '@components/SectionedBox/SectionedBoxBody/SectionedBoxBody';
import { SectionedBoxFooter } from '@components/SectionedBox/SectionedBoxFooter/SectionedBoxFooter';
import { SectionedBoxWrapper } from '@components/SectionedBox/SectionedBoxWrapper';
import RequestFormPageState, { RequestFormPageSources } from '@components/RequestForm/RequestFormPage.types';
import { FindATimeForm } from '@components/FindATime/FindATimeForm';
import {
	FindATimeResults
} from '@components/RequestForm/FindATime/FindATimeAccordion/FindATimeResults/FindATimeResults';
import { useFindATimeContext } from '@components/FindATime/FindATimeContext/FindATimeContextProvider';
import {
	useClearFindATimeSelection
} from '@components/FindATime/utilities/useClearFindATimeSelection/useClearFindATimeSelection';
import { AvailableTimeOption } from '@data/findATime/types/AvailableTimeOption';
import { useSchedulePageContext } from '@components/SchedulePage/SchedulePageContext/SchedulePageContext';
import useGetPrimarySurgeonNpi
	from '@components/RequestForm/utilities/hooks/useCheckBlockTimeOverlap/useGetPrimarySurgeonNpi/useGetPrimarySurgeonNpi';
import { convert24TimeToDateObj, timeStringAs24Hr } from '@utilities/dateUtilities';
import { useCollectionEntity } from '@utilities/hooks/useCollectionEntity/useCollectionEntity';
import { Unit } from '@data/unit/Unit';
import { ENDPOINT_USER_UNITS } from '@utilities/apiConstants';
import { FindATimeResponse } from '@data/findATime/types/FindATimeResponse';
import { alpha } from '@material-ui/core/styles';
import { track } from '@amplitude/analytics-browser';
import { BaseButton } from '@components/BaseButton/BaseButton';

const useStyles = makeStyles((theme)=> ({
	requestTimeButton: {
		'borderRadius': '100px',
		'height': '48px',
		'&.Mui-disabled': {
			backgroundColor: alpha(theme.palette.common.black, 0.12),
			color: alpha(theme.palette.common.black, 0.38),
		},
	},
	results: {
		overflowY: 'hidden',
		padding: theme.spacing(0),
	},
}));

export const SelectATimeSection: React.FC = () => {
	const classes = useStyles();
	const history = useHistory<RequestFormPageState>();
	const {
		findATimeResponse,
		lastSelectedTimeSlot,
		selectedDateAndTime,
		selectedTimeSlot,
		setFindATimeResponse,
		setSelectedDateAndTime,
		setSelectedTimeSlot,
	} = useFindATimeContext();
	const { watch, getValues } = useFormContext<FindATimeForm>();
	const currentFormValues = watch();
	useClearFindATimeSelection();
	const { hospitalTimeZone } = useCollectionEntity<Unit>(ENDPOINT_USER_UNITS, currentFormValues?.appointmentLocation, 'id');
	const previousFindATimeResults = history.location.state?.previousFindATimeValues?.previousFindATimeResults;

	const { setSelectedFacility, getSchedules } = useSchedulePageContext();
	const { appointmentLocation } = getValues();
	const surgeonNpi = useGetPrimarySurgeonNpi();

	const getSurgeonCalendar = React.useCallback((date: Date) => {
		setSelectedFacility(appointmentLocation, false);
		surgeonNpi && getSchedules(date, [parseInt(surgeonNpi)]);
	}, [appointmentLocation, getSchedules, setSelectedFacility, surgeonNpi]);

	const getFindATimeResponseByDate = (findATimeResponse: FindATimeResponse | undefined, desiredDate: Date) => {
		const formattedDesiredDate = format(desiredDate, 'yyyy-MM-dd');

		return findATimeResponse?.filter(timeOption => {
			return timeOption.date === formattedDesiredDate;
		});
	};

	const handleSelectTime = React.useCallback((
		time: AvailableTimeOption,
		timeSlotId: string,
		date: Date,
	) => {
		if (selectedTimeSlot !== timeSlotId) {
			const formattedStartTime = format(new Date(`01-01-1970 ${time?.start}`), 'hh:mm aa');
			const startTimeOnly = formattedStartTime.split(' ')[0];
			const time24Hour = timeStringAs24Hr(formattedStartTime);
			const dateAndTimeInTimezone = hospitalTimeZone && convert24TimeToDateObj(time24Hour, hospitalTimeZone, date);
			setSelectedDateAndTime({
				date: dateAndTimeInTimezone || date,
				time: [
					time24Hour,
					startTimeOnly
				],
			});
		}

		setSelectedTimeSlot(timeSlotId);
		lastSelectedTimeSlot.current = timeSlotId;
		getSurgeonCalendar(date);
		track('timeSlotCard-click', { fieldName: 'findATimePage-resultsSection-timeSlotCard', eventType: 'click' });
	},
	[
		getSurgeonCalendar,
		hospitalTimeZone,
		lastSelectedTimeSlot,
		selectedTimeSlot,
		setSelectedDateAndTime,
		setSelectedTimeSlot
	]);

	React.useEffect(() => {
		if (previousFindATimeResults) {
			setFindATimeResponse(previousFindATimeResults);
		}
	}, [
		previousFindATimeResults,
		setFindATimeResponse,
	]);

	const currentFindATimeResults = selectedDateAndTime?.date && getFindATimeResponseByDate(findATimeResponse, selectedDateAndTime.date);

	const findATimeRequestFormValues = React.useMemo(() => {
		return {
			appointmentLocation: currentFormValues?.appointmentLocation,
			currentFindATimeResults,
			currentSelectedTimeSlot: selectedTimeSlot,
			duration: currentFormValues?.duration,
			primarySurgeon: currentFormValues?.primarySurgeon,
			procedureDate: selectedDateAndTime?.date,
			procedureName: currentFormValues?.procedureName,
			procedureTime: selectedDateAndTime?.time,
		};
	}, [
		currentFindATimeResults,
		currentFormValues,
		selectedDateAndTime,
		selectedTimeSlot,
	]);

	const previousFindATimeValues = React.useMemo(() => {
		return {
			...currentFormValues,
			previousFindATimeResults: findATimeResponse,
		};
	}, [currentFormValues, findATimeResponse]);

	const handleRequestTimeClick = () => {
		history.push('/request', {
			findATimeRequestFormValues,
			from: RequestFormPageSources.FindATime,
			previousFindATimeValues,
		});
	};

	return (
		<SectionedBoxWrapper>
			<SectionedBoxHeader title="Select a time" />
			<SectionedBoxBody>
				{ findATimeResponse ? <FindATimeResults className={classes.results} handleSelectTime={handleSelectTime} /> : null }
			</SectionedBoxBody>
			<SectionedBoxFooter>
				<BaseButton
					className={classes.requestTimeButton}
					color="primary"
					disabled={!selectedTimeSlot || !findATimeResponse}
					onClick={handleRequestTimeClick}
					variant="contained"
					dataField="findATimePage-selectATimeSection-requestTimeButton"
				>
					Request Time
				</BaseButton>
			</SectionedBoxFooter>
		</SectionedBoxWrapper>
	);
};
