import React from 'react';
import { Text } from '@ascension/web';
import { FormAssistiveTextProps } from './FormAssistiveTextProps';
import useFieldError from '../../../utilities/hooks/useFieldError/useFieldError';
import FormError from '../FormError/FormError';

export const FormAssistiveText: React.FC<FormAssistiveTextProps> = ({ field, children }) => {
	const errorMsg = useFieldError(field);

	return errorMsg ?
		<FormError>{errorMsg}</FormError>
		:
		<Text color="rgba(0, 0, 0, 0.6)" letterSpacing={1}>
			{children}
		</Text>;
};
