import React from 'react';
import { useDispatch } from 'react-redux';
import { createOfficeEventsGetAction } from '@data/openCalendar/OfficeEvents/OfficeEventsActionCreators';
import EventApiPayload from '@data/openCalendar/EventApiPayload';

const useGetOfficeEvents = () => {
	const dispatch = useDispatch();

	return React.useCallback((requestOverrides: EventApiPayload) => {
		dispatch(createOfficeEventsGetAction(requestOverrides));
	}, [dispatch]);
};

export default useGetOfficeEvents;
