import { Label, Radio } from '@ascension/web';
import { useRadioGroup } from '@ascension/web/utils/hooks/useRadioGroup';
import React from 'react';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { FormComponentProps } from './FormComponentProps';
import useFormFieldSchema from './useFormFieldSchema';
import { FieldValues } from 'react-hook-form';
import { FieldPath } from 'react-hook-form';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'wrap',
		gap: theme.spacing(1)
	},
	radioContainer: {
		display: 'flex',
		flexDirection: 'row',
		boxSizing: 'border-box',
		gap: theme.spacing(2)
	}
}));

function FormYesNoRadio<T extends FieldValues, Name extends FieldPath<T>>({
	name,
	label,
	onChangeExternal,
	...rest
}: FormComponentProps<T> & { onChangeExternal?: (value: number) => void }) {
	const classes = useStyles();
	const { error, updateValue, isRequired, currentValue } = useFormFieldSchema<T>(name);

	const [isChecked, setIsChecked] = useRadioGroup();

	const updateChecked = React.useCallback((checked: boolean, value: T[Name]) => {
		updateValue(value);
		onChangeExternal && onChangeExternal(value);
	}, [onChangeExternal, updateValue]);

	React.useEffect(() => {
		setIsChecked(currentValue === null || currentValue === undefined || currentValue === '' ? null : Number(currentValue));
	}, [setIsChecked, currentValue]);

	return (
		<Box className={classes.container} data-field={name} {...rest}>
			<Label
				required={isRequired}
			>
				{label}
			</Label>
			<Box className={classes.radioContainer}>
				<Radio
					name={name}
					checked={isChecked[1] || false}
					label="Yes"
					value={1}
					onChange={updateChecked}
					data-field={`${name}Yes`}
				/>
				<Radio
					name={name}
					checked={isChecked[0] || false}
					label="No"
					value={0}
					onChange={updateChecked}
					data-field={`${name}No`}
				/>
			</Box>
			<ErrorMessage errorField={error}/>
		</Box>
	);
}

export default FormYesNoRadio;
