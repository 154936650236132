import React from 'react';
import { Box, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
	useIndexingSectionContext
} from '@components/PdfViewerPage/IndexingSection/IndexingSectionContext/IndexingSectionContext';
import EditIndexes from '@components/PdfViewerPage/IndexingSection/EditIndexes/EditIndexes';
import ReadOnlyIndexes from '@components/PdfViewerPage/IndexingSection/ReadOnlyIndexes/ReadOnlyIndexes';
import EditIcon from '@material-ui/icons/Edit';
import { ActionButtonsContainer } from '@components/PdfViewerPage/IndexingSection/EditIndexes/ActionButtonsContainer';

const useStyles = makeStyles(theme => ({
	indexContainer: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	},
	indexInnerContainer: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(4, 2, 1, 2),
	},
	editIcon: {
		marginRight: theme.spacing(-1.5),
	},
}));

const IndexingSection: React.FC = () => {
	const classes = useStyles();
	const { shouldShowEdit, setShowEdit, shouldDisableEditToggle } = useIndexingSectionContext();

	return (
		<Box className={classes.indexContainer}>
			<Box className={classes.indexInnerContainer}>
				<Box display="flex" justifyContent="space-between" alignItems="left">
					<Typography variant="h4">Index</Typography>
					<IconButton
						data-field="documentView-indexEdit-editIcon"
						onClick={() => setShowEdit(true)}
						color="inherit"
						disabled={shouldDisableEditToggle}
						className={classes.editIcon}
					>
						<EditIcon fontSize="small" />
					</IconButton>
				</Box>
				{shouldShowEdit ? <EditIndexes/> : <ReadOnlyIndexes/> }
			</Box>
			{shouldShowEdit && <ActionButtonsContainer/>}
		</Box>
	);
};
export default IndexingSection;
