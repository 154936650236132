import {
	CriticalCriteriaChangedModal
} from '@components/RequestForm/CriticalCriteriaChangedModal/CriticalCriteriaChangedModal';
import { SURGERY_LOCATION_CHANGED_MODAL } from '@components/Modal/modalConstants';
import React from 'react';
import { SurgeryLocation } from '@components/RequestForm/inputs/SurgeryLocation/SurgeryLocation';
import { useLocationChangeWarning } from '@components/RequestForm/RequestFormLocation/useLocationChangeWarning';
import { useFormContext } from 'react-hook-form';
import CommonRequestForm from '@interfaces/RequestForm/forms/CommonRequestForm';

export const RequestFormLocation: React.FC = () => {
	const {
		cancelChange,
		checkShouldChangeLocationImmediately,
		finishChange,
	} = useLocationChangeWarning();
	const { setValue } = useFormContext<CommonRequestForm>();

	const onBeforeChange = React.useCallback((e: React.ChangeEvent<{ value: unknown }>) => {
		setValue('findATimeUsed', false);
		return checkShouldChangeLocationImmediately(e);
	}, [setValue, checkShouldChangeLocationImmediately]);

	return (
		<>
			<SurgeryLocation
				onBeforeChange={onBeforeChange}
				dataField="requestForm-requestDetails-surgeryLocationSelect"
			/>
			<CriticalCriteriaChangedModal
				modalValues={SURGERY_LOCATION_CHANGED_MODAL}
				onClose={cancelChange}
				onAcceptChange={finishChange}
				onKeepPrevious={cancelChange}
			/>
		</>
	);
};
