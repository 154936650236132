import { RegisterOptions, FieldValues } from 'react-hook-form';
import {
	differenceInMinutes,
	format,
	addWeeks,
	isAfter,
	isBefore,
	isEqual,
	parse
} from 'date-fns';
import {
	END_DATE_TOO_LATE,
	START_DATE_TOO_EARLY,
	START_TIME_AFTER_END_TIME,
	END_TIME_AFTER_PRIME_TIME,
	INVALID_TIME_RANGE,
	START_TIME_AFTER_PRIME_TIME,
	START_TIME_BEFORE_PRIME_TIME
} from '@utilities/Validation/ValidationMessages';
import { FindATimeForm } from '@components/FindATime/FindATimeForm';
import { FindATimeFormMetaData } from '@components/FindATime/FindATimeForm';
import { TWO_BUSINESS_DAYS_FROM_NOW } from '@utilities/dateUtilities';

export const StartDateValidation: RegisterOptions<FindATimeForm> = {
	validate: (_, formValues) => {
		const { startDate } = formValues;
		if (isBefore(startDate, TWO_BUSINESS_DAYS_FROM_NOW)) {
			return START_DATE_TOO_EARLY;
		}
	},
};

export const EndDateValidation: RegisterOptions<FindATimeForm> = {
	validate: (_, formValues) => {
		const { startDate, endDate } = formValues;
		const TWO_WEEKS_FROM_START_DATE = addWeeks(startDate, 2);
		if (isAfter(endDate, TWO_WEEKS_FROM_START_DATE)) {
			return END_DATE_TOO_LATE;
		}
	},
};

// The order of validation functions in this object is important to ensure the correct error message is displayed
// minRange has precedence over beforePrimeTime and afterPrimeTime
export const StartTimeValidation: RegisterOptions = {
	validate: {
		minRange: (startValue: string, formValues: FieldValues) => {
			const formEndTime = formValues?.endTime as string;

			if (!startValue || !formEndTime) {
				return true;
			}

			const startTimeDate = parse(startValue, 'HH:mm', new Date());
			const endTimeDate = parse(formEndTime, 'HH:mm', new Date());
			const timeRangeMins = Math.abs(differenceInMinutes(startTimeDate, endTimeDate));

			if (timeRangeMins < 45) {
				return ' '; // message displays under end time field so return empty string with space
			} else {
				return true;
			}
		},
		beforePrimeTime: (startValue: string, formValues: FieldValues) => {
			const formMetaData = formValues?.metaData as FindATimeFormMetaData;
			if (!startValue || !formMetaData?.primeTimeStart) {
				return true;
			}

			// Parse the time strings into Date objects for comparison
			const startTime = parse(startValue, 'HH:mm', new Date());
			const primeTimeStart = parse(formMetaData?.primeTimeStart, 'HH:mm', new Date());

			// Compare the start time with the prime time start and return message if start before prime time
			if (isBefore(startTime, primeTimeStart)) {
				const formattedPrimeTimeStart = format(primeTimeStart, 'h:mm a');
				return `${START_TIME_BEFORE_PRIME_TIME} ${formattedPrimeTimeStart}`;
			} else {
				return true;
			}
		},
		afterPrimeTime: (startValue: string, formValues: FieldValues) => {
			const formMetaData = formValues?.metaData as FindATimeFormMetaData;
			if (!startValue || !formMetaData?.primeTimeEnd) {
				return true;
			}

			// Parse the time strings into Date objects for comparison
			const startTime = parse(startValue, 'HH:mm', new Date());
			const primeTimeEnd = parse(formMetaData?.primeTimeEnd, 'HH:mm', new Date());

			// Compare the start time with the prime time end and return message if start after prime time
			if (isAfter(startTime, primeTimeEnd)) {
				const formattedPrimeTimeEnd = format(primeTimeEnd, 'h:mm a');
				return `${START_TIME_AFTER_PRIME_TIME} ${formattedPrimeTimeEnd}`;
			} else {
				return true;
			}
		},
	},
};

// The order of validation functions in this object is important to ensure the correct error message is displayed
// afterStartTime has precedence over minRange
// minRange has precedence over beforePrimeTime and afterPrimeTime
export const EndTimeValidation: RegisterOptions = {
	validate: {
		beforeStartTime: (endValue: string, formValues: FieldValues) => {
			const formStartTime = formValues?.startTime as string;
			if (!endValue || !formStartTime) {
				return true;
			}

			// Parse the time strings into Date objects for comparison
			const endTime = parse(endValue, 'HH:mm', new Date());
			const startTime = parse(formStartTime, 'HH:mm', new Date());

			// Compare the end time with start time and return message if end is before or equal to start
			if (isBefore(endTime, startTime) || isEqual(endTime, startTime)) {
				return START_TIME_AFTER_END_TIME;
			} else {
				return true;
			}
		},
		minRange: (endValue: string, formValues: FieldValues) => {
			const formStartTime = formValues?.startTime as string;

			if (!endValue || !formStartTime) {
				return true;
			}

			// Parse the time strings into Date objects for comparison
			const startTimeDate = parse(formStartTime, 'HH:mm', new Date());
			const endTimeDate = parse(endValue, 'HH:mm', new Date());

			const timeRangeMins = Math.abs(differenceInMinutes(startTimeDate, endTimeDate));

			if (timeRangeMins < 45) {
				return INVALID_TIME_RANGE;
			} else {
				return true;
			}
		},
		afterPrimeTime: (endValue: string, formValues: FieldValues) => {
			const formMetaData = formValues?.metaData as FindATimeFormMetaData;
			if (!endValue || !formMetaData?.primeTimeEnd) {
				return true;
			}

			// Parse the time strings into Date objects for comparison
			const endTime = parse(endValue, 'HH:mm', new Date());
			const primeTimeEnd = parse(formMetaData?.primeTimeEnd, 'HH:mm', new Date());

			// Compare the end time with the prime time end and return message if end after prime time
			if (isAfter(endTime, primeTimeEnd)) {
				const formattedPrimeTimeEnd = format(primeTimeEnd, 'h:mm a');
				return `${END_TIME_AFTER_PRIME_TIME} ${formattedPrimeTimeEnd}`;
			} else {
				return true;
			}
		},
	},
};

