import React, { ReactNode } from 'react';
import { Box, Flex, Text, TextLink } from '@ascension/web';
import { AlertBody } from '../../AlertBody/AlertBody';
import { useDispatch, useSelector } from 'react-redux';
import { CaseDetailsInfoSelector } from '../../../../store/selectors/CaseDetailsSelector';
import { RootState } from '../../../../interfaces/rootState';
import { SurgeryRequestGet } from '../../../../interfaces/SurgeryRequest/SurgeryRequestGet';
import { setCancellationToSeen } from '../../../../store/actions/CancellationAlertsActionCreators';
import { CancellationStatus } from '../../../../interfaces/SurgeryRequest/CancellationStatus';
import { utcToLocalTime } from '../../../../utilities/dateUtilities';
import useHasPermission from '../../../../utilities/hooks/useHasPermission/useHasPermission';
import { PermittedAction } from '../../../../utilities/PermittedAction';

export const CancelledAlertBody = () => {

	const {
		cancellation, id
	} = useSelector(CaseDetailsInfoSelector as (state: RootState) => SurgeryRequestGet);

	const convertedDate = utcToLocalTime(cancellation.dateUpdated);

	const dispatch = useDispatch();

	const hasAcknowledgeCaseAlertPermission = useHasPermission(PermittedAction.ACKNOWLEDGE_CASE_ALERT);
	const APPROVE_MASSAGE = 'Requested to cancel the procedure was approved.';
	const handleResolved = () => {
		dispatch(setCancellationToSeen(id));
	};

	const links: ReactNode | ReactNode[] = (hasAcknowledgeCaseAlertPermission && cancellation.status !== CancellationStatus.CLEAR) && (
		<TextLink onClick={handleResolved}>Mark as Seen</TextLink>
	);

	return (
		<AlertBody
			alertUser={cancellation.userUpdated.firstName + ' ' + cancellation.userUpdated.lastName}
			alertTime={convertedDate}
			body={<Flex py={3} data-section="cancellationInfo">
				<Flex flexDirection="row" testId="alertBodyHeader">
					<Text
						fontSize={14}
						fontWeight={1}
						color="#222"
					>
						{APPROVE_MASSAGE}
					</Text>
				</Flex>
				<Box ml="auto">
					{links}
				</Box>
			</Flex>}
		/>
	);

};
