import { CLEAR_ERROR_STATUS, SET_ERROR_STATUS } from './types';
import { ErrorStatusState } from '../../interfaces/ErrorStatus/ErrorStatusState';

export const setErrorStatus = (errorStatusData: Error | ErrorStatusState) => {
	return {
		type: SET_ERROR_STATUS,
		payload: errorStatusData
	};
};

export const clearErrorStatus = () => {
	return {
		type: CLEAR_ERROR_STATUS
	};
};
