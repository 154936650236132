import React, { EventHandler, MouseEvent } from 'react';
import CopyTextProps from '@components/CopyText/CopyTextProps';
import { Box, IconButton, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import classNames from 'classnames';
import { useToast } from '@utilities/hooks/useToast/useToast';
import { ToastType } from '@interfaces/ToastStatus/ToastStatusState';

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			display: 'flex',
			justifyContent: 'space-between',
		},
	}),
);

const CopyText: React.FC<CopyTextProps> = ({
	children,
	successMessage,
	errorMessage,
	iconButtonProps,
	typographyProps,
	...rest
}) => {
	const classes = useStyles();
	const setToast = useToast();

	const handleCopy: EventHandler<MouseEvent> = React.useCallback(
		async (ev) => {
			ev.preventDefault();
			ev.stopPropagation();

			try {
				const copyText = children.trim();
				await navigator.clipboard.writeText(copyText);
				if (!successMessage) {
					return;
				}
				setToast(ToastType.SUCCESS, successMessage);
			} catch {
				if (!errorMessage) {
					return;
				}
				setToast(ToastType.ERROR, errorMessage);
			}
		},
		[children, errorMessage, setToast, successMessage],
	);

	return (
		<Box {...rest} className={classNames(classes.root, rest.className)}>
			<Typography {...typographyProps}>{children}</Typography>
			<IconButton
				color="primary"
				onClick={handleCopy}
				size="small"
				{...iconButtonProps}
			>
				<FileCopyOutlinedIcon fontSize="inherit" />
			</IconButton>
		</Box>
	);
};

export default CopyText;
