import React from 'react';
import { SectionedBoxHeader } from '@components/SectionedBox/SectionedBoxHeader/SectionedBoxHeader';
import { SectionedBoxBody } from '@components/SectionedBox/SectionedBoxBody/SectionedBoxBody';
import { SectionedBoxWrapper } from '@components/SectionedBox/SectionedBoxWrapper';
import { Box, createStyles, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => createStyles({
	surgeonHeaderContainer: {
		backgroundColor: theme.palette.grey[900],
		borderRadius: '4px 4px 0 0',
	},
	blockInfoContainer: {
		backgroundColor: theme.palette.grey[700],
		borderRadius: '4px 4px 0 0',
		position: 'relative',
		top: '-5px',
		height: '25px'
	},
	surgeonHeader: {
		display: 'flex',
		flexDirection: 'row',
		padding: theme.spacing(1, 1, 1.5, 1),
		position: 'relative',
	},
	blockInfoHeader: {
		display: 'flex',
		flexDirection: 'row',
		padding: theme.spacing(0.5, 0, 0.25, 1),
		position: 'relative',
	},
	surgeonName: {
		fontWeight: 600,
		color: theme.palette.common.white,
		lineHeight: '20px',
		letterSpacing: '0.25px'
	},
	blockInfo: {
		fontWeight: 600,
		color: theme.palette.common.white,
		lineHeight: '16px',
		letterSpacing: '0.4px'
	},
	customBody: {
		padding: 0,
		borderBottom: 0
	}
}));

export const DailySurgeonCalendarEmpty: React.FC = () => {
	const classes = useStyles();

	return (
		<SectionedBoxWrapper hasFooter={false}>
			<SectionedBoxHeader title="Daily Surgeon Schedule" lowHeaderPadding={true}/>
			<SectionedBoxBody className={classes.customBody}>
				<Box className={classes.surgeonHeaderContainer}>
					<Box className={classes.surgeonHeader}>
						<Typography variant="body1" component="h3" className={classes.surgeonName}>Surgeon</Typography>
					</Box>
				</Box>
				<Box className={classes.blockInfoContainer}>
					<Box className={classes.blockInfoHeader}>
						<Typography variant="body2" component="h3" className={classes.blockInfo}>Block Information</Typography>
					</Box>
				</Box>
			</SectionedBoxBody>
		</SectionedBoxWrapper>
	);
};
