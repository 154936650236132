import { apiAction } from '@store/middlewares/api/ApiActionCreators';
import ApiRestMethod from '@utilities/apiEndpoints/ApiRestMethod';
import ApiService from '@utilities/apiEndpoints/ApiService';
import {
	ENDPOINT_CREATE_HOLD_EVENT,
	ENDPOINT_UPDATE_DELETE_HOLD_EVENT,
} from '@utilities/apiConstants';
import { CREATE_HOLD_EVENT, DELETE_HOLD_EVENT, UPDATE_HOLD_EVENT } from '@store/actions/types';
import shouldDisableErrorHandlerByStatusNums
	from '@utilities/shouldDisableErrorHandlerByStatusNums/shouldDisableErrorHandlerByStatusNums';
import { ApiActionPayload } from '@interfaces/ApiAction/ApiActionPayload';
import endpointTemplateToUrl from '@utilities/endpointTemplateToUrl';
import { defaultCallback } from '@utilities/defaultCallback';
import {
	CreateHoldEventApiPayload,
	DeleteHoldEventApiPayload, HoldEventDetailsResponse,
	UpdateHoldEventApiPayload
} from '@data/holdEvent/types/HoldEventApiPayload';
import HoldEventDetailsRequest from '@data/holdEvent/types/HoldEventDetailsRequest';
import { ErrorStatusState } from '@interfaces/ErrorStatus/ErrorStatusState';

type MinimalApiAction = Partial<ApiActionPayload<HoldEventDetailsRequest, ErrorStatusState, HoldEventDetailsResponse>> & { label: string; url: string; method: string };
const getHoldEventApiAction = (requestOverrides: MinimalApiAction) => {
	return apiAction<HoldEventDetailsRequest, ErrorStatusState, HoldEventDetailsResponse>({
		apiService: ApiService.BTS,
		includeAccessToken: true,
		shouldDisableErrorHandler: shouldDisableErrorHandlerByStatusNums([409]),
		onSuccess: defaultCallback,
		...requestOverrides,
	});
};

export const getHoldEventApiCreateAction = (requestOverrides: CreateHoldEventApiPayload) => {
	return getHoldEventApiAction({
		method: ApiRestMethod.POST,
		url: ENDPOINT_CREATE_HOLD_EVENT,
		label: CREATE_HOLD_EVENT,
		...requestOverrides
	});
};

export const getHoldEventApiUpdateAction = (requestOverrides: UpdateHoldEventApiPayload) => {
	const url = endpointTemplateToUrl(ENDPOINT_UPDATE_DELETE_HOLD_EVENT, { holdEventId: requestOverrides.data.id });
	return getHoldEventApiAction({
		method: ApiRestMethod.PUT,
		url,
		label: UPDATE_HOLD_EVENT,
		...requestOverrides
	});
};

export const getHoldEventApiDeleteAction = (id: string, requestOverrides: DeleteHoldEventApiPayload) => {
	const url = endpointTemplateToUrl(ENDPOINT_UPDATE_DELETE_HOLD_EVENT, { holdEventId: id });
	return getHoldEventApiAction({
		method: ApiRestMethod.DELETE,
		url,
		label: DELETE_HOLD_EVENT,
		...requestOverrides
	});
};
