import { CaseQueueInfo } from '../../interfaces/CaseQueue/CaseQueueInfo';
import { dateParse, getDayMonthDateFromString, utcToTimezone } from '../dateUtilities';
import { CaseTrackerListInterface } from '../../components/CaseTrackerList/CaseTrackerListInterface';

export const mapCaseDetailsApiToQueueInfo = (caseDetailsList: CaseTrackerListInterface[]): CaseQueueInfo[] => {
	return caseDetailsList.map((caseDetails: CaseTrackerListInterface) => {
		const convertedProcedureDate = utcToTimezone(caseDetails.procedureDate, caseDetails.hospitalTimeZone);
		const [weekday, monthAbr, day] = getDayMonthDateFromString(convertedProcedureDate, true);
		const parsedProcedureDate = dateParse(convertedProcedureDate);
		const trimmedTime = parsedProcedureDate[1].replace(/^0/, '');
		const procedureSurgeon = caseDetails.surgeon;
		return {
			dateDisplay: weekday + ', ' + monthAbr + ' ' + day,
			timeDisplay: trimmedTime + ' ' + parsedProcedureDate[2].toUpperCase(),
			surgeonName: procedureSurgeon.lastName + ' ' + procedureSurgeon.professionalSuffix + ', ' + procedureSurgeon.name,
			procedureName: caseDetails.procedureName,
			caseId: caseDetails.id,
		};
	});
};
