import { SurgeryRequest } from '@interfaces/SurgeryRequest/SurgeryRequest';
import { SurgeryRequestGet } from '@interfaces/SurgeryRequest/SurgeryRequestGet';

const convertPayloadToMultipart = (requestData: SurgeryRequest | SurgeryRequestGet | Record<string, never>): FormData => {
	const form = new FormData();
	if (!('documents' in requestData)) {
		form.append('request', JSON.stringify(requestData));
		return form;
	}
	const { documents = [], ...requestDetails } = requestData;
	form.append('request', JSON.stringify(requestDetails));
	documents.forEach((d: Blob) => form.append('file', d));
	return form;
};

export default convertPayloadToMultipart;
