import React from 'react';
import { StatefulSelect } from '@components/StatefulInputs/StatefulSelect/StatefulSelect';
import CommonRequestForm from '@interfaces/RequestForm/forms/CommonRequestForm';
import { useGetSurgeonOptions } from '@utilities/hooks/useGetSurgeonOptions/useGetSurgeonOptions';
import { PrimarySurgeonValidation } from '@components/RequestForm/RequestFormValidation';
import { StatefulSelectProps } from '@components/StatefulInputs/StatefulSelect/StatefulSelect.types';

interface PrimarySurgeonProps extends Partial<Omit<StatefulSelectProps<CommonRequestForm>, 'options'>> {
	label?: string;
	dataField: string;
}

export function PrimarySurgeon({ label = 'Primary Surgeon', dataField }: PrimarySurgeonProps) {
	const options = useGetSurgeonOptions();
	if (options.length === 0) return null;

	return (
		<StatefulSelect<CommonRequestForm>
			dataField={dataField}
			options={options}
			name="primarySurgeon"
			label={label}
			styleAsRequired={true}
			controllerOptions={{
				defaultValue: options[0].value,
				rules: {
					...PrimarySurgeonValidation,
				}
			}}
		/>
	);
}
