import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, BoxProps } from '@material-ui/core';
import { useAppPageBodyClassSetter } from '@components/AppPage/AppPageContext/AppPageContext';
import classNames from 'classnames';

export const HEADER_GRID_AREA = 'header';
export const BODY_GRID_AREA = 'body';
export const FOOTER_GRID_AREA = 'footer';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'grid',
		gridTemplateAreas: `
			"${HEADER_GRID_AREA}"
			"${BODY_GRID_AREA}"
			"${FOOTER_GRID_AREA}"
		`,
		gridTemplateRows: 'min-content minmax(325px, 1fr) min-content',
		backgroundColor: theme.palette.common.white,
		width: '100vw'
	},
	bodyClass: {
		'& > div:first-child': {
			'maxWidth': 'unset',
			'margin': 'unset',
			'& [data-field="component-container"]': {
				height: 'calc(100vh - 68px)',
				paddingBottom: 0,
				display: 'flex',
				overflow: 'hidden'
			},
		},
	},
}));

interface MainPageLayoutContainerProps extends BoxProps {
	bodyClass?: string;
}

const MainPageLayoutContainer: React.FC<MainPageLayoutContainerProps> = ({ children, bodyClass, ...boxProps }) => {
	const classes = useStyles();

	const { setBodyClass } = useAppPageBodyClassSetter('BlockViewPage');

	React.useEffect(() => {
		setBodyClass(`${classes.bodyClass} ${bodyClass}`);
	}, [classes.bodyClass, bodyClass, setBodyClass]);

	return (
		<Box className={classNames(classes.root, boxProps.className)} {...boxProps}>
			{children}
		</Box>
	);
};

export default MainPageLayoutContainer;
