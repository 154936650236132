import { CLEAR_APP_ALERT, SET_APP_ALERT } from './types';
import { defaultCallback } from '../../utilities/defaultCallback';
import AppAlertAction from '../../interfaces/AppAlert/AppAlertAction';

export const clearAppAlert = (): AppAlertAction => {
	return {
		type: CLEAR_APP_ALERT,
	};
};

export const setAppAlert = (title: string, message: string, onDismiss = defaultCallback): AppAlertAction => {
	return {
		type: SET_APP_ALERT,
		payload: {
			title,
			message,
			onDismiss,
		},
	};
};
