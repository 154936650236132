import { Box, Button, Flex } from '@ascension/web';
import { TextField } from '@ascension/ui-library';
import React, { ChangeEvent, FormEvent, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { CaseDetailsInfoSelector } from '@store/selectors/CaseDetailsSelector';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { CONFIRMATION_INPUT_CONFIG } from '@components/Alerts/UnscheduledAlert/ConfirmCaseInput/ConfirmCaseInputConfig';
import {
	useConfirmError,
	useStandardConfirm,
} from '@components/CaseDetails/CaseDetailsContext/ConfirmCaseContext/ConfirmCaseUtilities';
import useHasEhrIntegrationAndRole
	from '@utilities/hooks/useHasEhrIntegrationAndRole/useHasEhrIntegrationAndRole';
import { IntegrationType } from '@interfaces/IntegrationType';
import { UserAccessEnum } from '@interfaces/SurgeryRequest/UserAccessEnum';
import NoParamNoReturnCallback from '@interfaces/NoParamNoReturnCallback';
import useGetAccessor from '@components/CaseDetails/CaseInUse/hooks/useGetAccessor/useGetAccessor';

const useStyles = makeStyles((theme) => createStyles({
	inputWrapperStyle: {
		height: 'fit-content',
	},
	form: {
		width: '100%',
	},
	inputContainerStyle: {
		'marginBottom': theme.spacing(1),
		'& .MuiOutlinedInput-input': {
			padding: theme.spacing(2),
		},
	},
	errorMessageStyle: {
		margin: 0,
	},
	submitBtn: {
		height: theme.spacing(6),
		marginTop: theme.spacing(0.25),
	},
	wrapStyle: {
		[theme.breakpoints.down('md')]: {
			flexWrap: 'wrap',
		},
		'.condensed-section &': {
			flexWrap: 'wrap',
		},
	}
}));

interface ConfirmCaseInputProps {
	initialConfirmationNumber?: string;
	onSuccessCallback?: NoParamNoReturnCallback;
}

const ConfirmCaseInput: React.FC<ConfirmCaseInputProps> = ({
	children,
	initialConfirmationNumber = '',
	onSuccessCallback,
}) => {
	const classes = useStyles();
	const { isAccessorDifferentUser: caseInUse } = useGetAccessor([UserAccessEnum.CONFIRMER]);
	const caseDetails = useSelector(CaseDetailsInfoSelector);
	const hasEhrIntegration = useHasEhrIntegrationAndRole(caseDetails);
	const type = hasEhrIntegration ? IntegrationType.EHR : IntegrationType.MANUAL;

	const {
		label,
		validCharacterTest,
		invalidCharacterErrorMessage,
	} = CONFIRMATION_INPUT_CONFIG[type];

	const [value, setValue] = React.useState(initialConfirmationNumber);

	const responseError = useConfirmError();
	const [displayError, setDisplayError] = React.useState<ReactNode>(null);
	React.useEffect(() => {
		setDisplayError(responseError);
	}, [responseError]);

	const [validStringRegExp] = React.useState(new RegExp(`^[${validCharacterTest}]+$`));
	const [filterCharacterRegExp] = React.useState(new RegExp(`[^${validCharacterTest}]`, 'g'));

	const handlePaste = (event: React.ClipboardEvent) => {
		event.preventDefault();
		const pasteData = event.clipboardData ? event.clipboardData.getData('text') : '';
		if (!validStringRegExp.test(pasteData)) {
			setValue('');
			setDisplayError(invalidCharacterErrorMessage);
			return;
		}
		setValue(pasteData.toUpperCase());
	};

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		setDisplayError(null);
		const value = event.target.value;
		const validated = value.replace(filterCharacterRegExp, '').toUpperCase();
		setValue(validated);
	};

	const confirmCase = useStandardConfirm();

	// Reset value every time the case changes
	React.useEffect(() => {
		setValue(initialConfirmationNumber);
	}, [caseDetails.id, initialConfirmationNumber]);

	const handleSubmit = React.useCallback((event: FormEvent) => {
		event.preventDefault();
		if (!value.length) { return; }
		setDisplayError(null);

		void confirmCase(value).then(() => {
			return onSuccessCallback && onSuccessCallback();
		});
	}, [value, confirmCase, onSuccessCallback]);
	return (
		<form onSubmit={handleSubmit} className={classes.form}>
			<Flex alignItems="flex-start" justifyContent="flex-start" data-field="confirm--section" className={classes.wrapStyle}>
				<Box className={classes.inputWrapperStyle} width="100%" minWidth="264px" maxWidth="368px" pt={4}>
					<TextField
						id="inputEHRNumberToConfirm"
						disabled={caseInUse}
						type="text"
						variant="outlined"
						fullWidth
						translate="yes"
						name="confirm--input"
						label={label}
						value={value}
						className={classes.inputContainerStyle}
						error={!!displayError}
						helperText={displayError}
						FormHelperTextProps={{ className: classes.errorMessageStyle }}
						onChange={handleChange}
						onPaste={handlePaste}
						data-field="confirm--input"
					/>
				</Box>
				<Flex alignItems="center">
					{children}
					<Box pl={8} pb={4} pt={4} mb={4}>
						<Button
							id="button--confirm"
							disabled={!value.length || (!hasEhrIntegration && initialConfirmationNumber === value)}
							className={classes.submitBtn}
							type="submit"
						>
							Confirm
						</Button>
					</Box>
				</Flex>
			</Flex>
		</form>
	);
};

export default ConfirmCaseInput;
