import BlockRequestParams from '@data/schedules/types/BlockRequestParams';

export enum SlotStatus {
	ACTIVE = 'active',
	RELEASED = 'released',
}

interface SlotRequestParams extends
	Pick<BlockRequestParams, 'unit' | 'facility' | 'date' | 'hospitalTimeZone'> {
		npi: string; // single surgeon NPI
		status?: string; // comma separated string of SlotStatus(s) i.e. active,released
}

export default SlotRequestParams;
