import { formatInTimeZone } from 'date-fns-tz';

const getTimeParts: (t: Date, timezone: string) => { hour: number; minute: number } = (t, timezone) => {
	const format24Hr = formatInTimeZone(t, timezone, 'H:m');
	const timeParts = format24Hr.split(':');

	return {
		hour: Number(timeParts[0]),
		minute: Number(timeParts[1]),
	};
};

export default getTimeParts;
