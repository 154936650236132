import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Button, TextField } from '@ascension/ui-library';
import ModalViewer from '@components/Modal/ModalViewer';
import { SCHEDULING_CANCEL_MODAL } from '@components/Modal/modalConstants';
import { cancelCase } from '@store/actions/CaseDetailsActionCreators';
import { useModalContext } from '@components/Modal/ModalContextProvider';
import BlueOutlineButton from '@components/BlueOutlineButton/BlueOutlineButton';
import { RequestRevisionSchema } from '@utilities/Validation/validationSchema';
import {
	SchedulingCancelCaseModalProps
} from '@components/SchedulePage/ManageScheduleEventMenu/SchedulingCancelCaseModal/SchedulingCancelCaseModalProps';
import { useSchedulePageContext } from '@components/SchedulePage/SchedulePageContext/SchedulePageContext';
import useUnlockCase from '@data/schedules/hooks/useUnlockCase';

const useStyles = makeStyles((theme) => createStyles({
	button: {
		maxHeight: '48px',
		padding: theme.spacing(2, 1.5),
	},
	buttonRow: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	cancelBtn: {
		'&.Mui-disabled': {
			backgroundColor: theme.palette.grey[500],
			color: theme.palette.common.white,
		},
	},
	dataLabel: {
		color: theme.palette.grey[700],
		fontWeight: 600,
	},
	eventInfoDetails: {
		color: theme.palette.grey[700],
	},
	modalContent: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(3),
		padding: theme.spacing(0, 1.5, 1.5, 1.5),
	},
	modalWrapper: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		padding: theme.spacing(1.5),
		maxWidth: '600px',
		width: '100%',
	},
	row: {
		display: 'flex',
		flexDirection: 'column',
	},
	text: {
		letterSpacing: '0.25px',
	},
	textArea: {
		'& .MuiOutlinedInput-multiline': {
			padding: theme.spacing(2),
		},

		'& > .MuiFormLabel-root.MuiInputLabel-root': {
			color: theme.palette.grey[700],
		},
	},
}));

export const SchedulingCancelCaseModal = ({ eventInfo }: SchedulingCancelCaseModalProps) => {
	const classes = useStyles();
	const { closeModal } = useModalContext();
	const { getSchedules } = useSchedulePageContext();
	const unlockCase = useUnlockCase();

	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
		getValues,
		reset,
		watch,
	} = useForm({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		defaultValues:  {
			message: ''
		}
	});

	const dispatch = useDispatch();
	const message = watch('message');

	const onSubmit = () => {
		const onSuccess = () => {
			getSchedules();
			closeModal(SCHEDULING_CANCEL_MODAL);
			unlockCase(eventInfo?.id);
		};

		dispatch(cancelCase(eventInfo?.id, getValues().message, { onSuccess }));
	};

	const handleClose = () => {
		closeModal(SCHEDULING_CANCEL_MODAL);
		unlockCase(eventInfo?.id);
		reset({ message: '' });
	};

	const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;

		setValue('message', value, { shouldValidate: true });
	};

	React.useEffect(() => {
		register('message', RequestRevisionSchema.message);
	}, [register]);

	return (
		<ModalViewer id={SCHEDULING_CANCEL_MODAL} className={classes.modalWrapper} onClose={handleClose}>
			<form onSubmit={handleSubmit(onSubmit)} className={classes.modalContent}>
				<Box>
					<Typography className={classes.text} component="h1" variant="h6">
						Cancel Case
					</Typography>
					<Typography className={classes.text} variant="body1">
						This action cannot be undone. The facility scheduler wil be notified of this cancellation.
					</Typography>
				</Box>
				<Box className={classes.row}>
					<Typography className={classes.dataLabel}>
						Patient
					</Typography>
					<Typography className={classes.eventInfoDetails} variant="body1">
						{eventInfo.patient || '---'}
					</Typography>
				</Box>
				<Box className={classes.row} >
					<Typography className={classes.dataLabel}>
						Procedure
					</Typography>
					<Typography className={classes.eventInfoDetails} variant="body1">
						{eventInfo.procedure || '---'}
					</Typography>
				</Box>
				<Box className={classes.row}>
					<TextField
						className={classes.textArea}
						data-field="schedulingPage-cancelCaseModal-message"
						id="message"
						error={!!errors.message?.message}
						helperText={errors.message?.message}
						label="Message"
						maxRows={3}
						minRows={3}
						multiline
						onChange={handleMessageChange}
						required
						translate="yes"
						type="text"
						variant="static"
					/>
				</Box>
				<Box className={classes.buttonRow}>
					<BlueOutlineButton
						className={classes.button}
						data-field="schedulingPage-cancelCaseModal-backBtn"
						id="backButton"
						onClick={handleClose}
					>
						Back
					</BlueOutlineButton>
					<Button
						className={classNames(classes.button, classes.cancelBtn)}
						data-field="schedulingPage-cancelCaseModal-cancelCaseBtn"
						color="destructive"
						disabled={!message}
						id="cancelCaseButton"
						translate="yes"
						type="submit"
						variant="contained"
					>
						Cancel Case
					</Button>
				</Box>
			</form>
		</ModalViewer>
	);
};
