import { useSelector } from 'react-redux';
import { UserProfileHasARoleSelector } from '@store/selectors/UserProfileSelector';
import { HospitalRoles } from '@interfaces/UserProfile/UserRoleEnum';
import useGetOfficeEvents from '@data/openCalendar/OfficeEvents/useGetOfficeEvents';
import useGetOREvents from '@data/openCalendar/OREvents/useGetOREvents';

const useGetEvents = () => {
	const hasHospitalInteractiveRole = useSelector(UserProfileHasARoleSelector(HospitalRoles));

	const getOfficeEvents = useGetOfficeEvents();
	const getOREvents = useGetOREvents();

	return hasHospitalInteractiveRole ? getOREvents : getOfficeEvents;
};

export default useGetEvents;
