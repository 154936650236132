import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
	FilterProviderProps,
	FiltersContextType,
	FiltersInfo,
} from '@components/Filters/FiltersContext/FiltersContextTypes';
import {
	apiParamsToFilters,
	arrayDeepEqual,
	commaSeparatedStringToArray,
	filtersToApiParams,
	filtersToReplaceString,
} from '@utilities/commonFunction';
import { DEFAULT_CASES_SORT, DEFAULT_PAGE, DEFAULT_SIZE } from '@utilities/apiConstants';
import { FilterApiParams, FilterParams, FilterStateProps } from '@interfaces/FilterParams';
import { parseFilterUrl } from '@utilities/filtersUtilities';
import { MyFiltersSelector } from '@store/selectors/MyFiltersSelector';
import {
	UserProfileDetailsSelector,
	UserProfileFilterUrlSelector,
	UserProfileHasARoleSelector,
} from '@store/selectors/UserProfileSelector';
import { OfficeRoles } from '@interfaces/UserProfile/UserRoleEnum';
import { defaultFilters } from '@utilities/constants';
import { StorageKey } from '@utilities/authConstants';

const DELIMITER = '::';

const FiltersContext = React.createContext<FiltersContextType | null>(null);

/***
 * Must be called from lower component to retrieve reset callback
 *
 * @example
 * const resetFilters = useResetAllFilters();
 *
 * <TextLink onClick={resetFilters}>Reset Filters</TextLink>
 *
 * @example
 * const resetFilters = useResetAllFilters();
 *
 * const customDefaults = { page: 2, surgeon: 'abcde12345' }
 * const handleReset = () => { resetFilters(customDefaults) }
 *
 * @example
 * const resetFilters = useResetAllFilters();
 *
 * const handleReset = () => resetFilters()
 *
 * @returns memoized function to run all reset handlers
 */
export const useResetAllFilters = () => {
	const context = React.useContext(FiltersContext);
	if (!context) {
		throw new Error('useResetAllFilters must be used inside a child component of FilterProvider');
	}

	const { updateFilterProps, defaultSort } = context;

	return React.useCallback((resetParamValues: FilterApiParams = {}) => {
		const {
			page, size, sortModel, surgeon, procedureDate, allOtherFilters,
			status, globalFilter, state, from, to
		} = resetParamValues;

		updateFilterProps({
			page: Number(page) || DEFAULT_PAGE,
			size: Number(size) || DEFAULT_SIZE,
			sortModel: sortModel || defaultSort,
			surgeon: commaSeparatedStringToArray(surgeon),
			procedureDate: procedureDate || '',
			allOtherFilters: commaSeparatedStringToArray(allOtherFilters),
			status: commaSeparatedStringToArray(status),
			globalFilter: globalFilter || '',
			state: commaSeparatedStringToArray(state),
			from: from || '',
			to: to || ''
		});
	}, [defaultSort, updateFilterProps]);
};

/**
 * Reveals just the values useful in lower components.
 *
 * @example
 * const { surgeons, sortModel, status, updateFilterProps } = useFilterState();
 * updateFilterProps({ sortModel: 'patientFamilyName:desc', status:  ['UNSCHEDULED', 'SCHEDULED'] })
 *
 * @returns FiltersInfo
 *
 */
export const useFilterState = (): FiltersInfo => {
	const context = React.useContext(FiltersContext);

	if (!context) {
		throw new Error('useFilterState must be used inside a child component of FilterProvider');
	}

	const {
		loadListWithFilters,
		filters,
		totalCount,
		sortModel,
		page,
		size,
		surgeons,
		procedureDate,
		status,
		state,
		allOtherFilters,
		globalFilter,
		from,
		to,
		entityName,
		filterApplied,
		updateFilterProps,
		toggleFilter,
		updateFilterPropsAndResetFilterToggle,
		filterSelectionsEmpty,
		defaultSort,
		disableDefaultFilters
	} = context;

	return {
		loadListWithFilters,
		filters,
		totalCount,
		sortModel,
		page,
		size,
		surgeons,
		procedureDate,
		status,
		state,
		allOtherFilters,
		globalFilter,
		from,
		to,
		entityName,
		filterApplied,
		updateFilterProps,
		toggleFilter,
		updateFilterPropsAndResetFilterToggle,
		filterSelectionsEmpty,
		defaultSort,
		disableDefaultFilters
	};
};

export const DEFAULT_FILTERS = {
	sortModel: DEFAULT_CASES_SORT,
	page: DEFAULT_PAGE,
	size: DEFAULT_SIZE,
	surgeon: [],
	procedureDate: 'upcoming',
	allOtherFilters: [],
	status: [],
	globalFilter: '',
	state: [],
	from: '',
	to: ''
};

/***
 * Use component to wrap filters with local context.
 *
 * @example
 * // Parent form
 * <FiltersProvider>
 *     <SomeFilter />
 * </FiltersProvider>
 *
 * @param children
 * @param defaultSort
 * @param entityName - Name used for Pagination and count in ListHeaderComponent
 * @param totalCountSelector - (r: RootState) => number;
 * @param disableRouteParams - boolean - prevent update to URL
 * @param {(params: FilterApiParams) => void } listLoader - Callback that should refresh the filtered list
 * @param disableDefaultFilters - boolean - prevent using the Session Storage filter value
 * @constructor
 */
export const FiltersProvider = ({
	children,
	defaultSort,
	entityName,
	totalCountSelector = () => undefined,
	disableRouteParams = false,
	onListLoadRequest,
	disableDefaultFilters = false,
	initialFilterRef,
}: FilterProviderProps) => {
	const [filterState, setFilterState] = React.useState<FilterStateProps>({
		...DEFAULT_FILTERS,
		sortModel: defaultSort,
	});

	const updateFilterProps = React.useCallback((input: Partial<FilterStateProps>) => {
		setFilterState((prev: FilterStateProps) => ({
			...prev,
			page: DEFAULT_PAGE,
			...input,
		}));
	}, []);

	// Set total count from selector
	const totalCount = useSelector(totalCountSelector) || 0;

	const hasSavedFilters = !!useSelector(UserProfileFilterUrlSelector);
	const { userId } = useSelector(UserProfileDetailsSelector);

	const { pathname } = useLocation();

	// Retrieve filter from session storage
	const filtersFromSessionStorage = React.useMemo(() => {
		// Retrieve filter from session storage
		const filterQueryValue = sessionStorage.getItem(StorageKey.FILTER_QUERY + entityName) || '';
		// Check if the filter is for this user
		if (!filterQueryValue.includes(userId)) { // Not for this user - remove and ignore
			sessionStorage.removeItem(StorageKey.FILTER_QUERY + entityName);
			return undefined;
		}
		// Cut the user id from the filter
		const query = filterQueryValue.split(DELIMITER)[0];
		return parseFilterUrl(query);
	}, [entityName, userId]);

	// Check if query has MyFilters enabled
	const { filterApplied: queryHasMyFiltersEnabled } = React.useMemo(
		() =>
			filtersFromSessionStorage
				? filtersFromSessionStorage
				: initialFilterRef?.current || {},
		[filtersFromSessionStorage, initialFilterRef],
	);

	const [ shouldUseMyFilters, setShouldUseMyFilters ] = React.useState(!disableDefaultFilters && !!queryHasMyFiltersEnabled);
	const isMyFiltersEnabled = hasSavedFilters && shouldUseMyFilters; // Filter applied should only be true when savedFilterExists
	const toggleShouldUseMyFilter = React.useCallback((forceToggleState?: boolean) => {
		if (forceToggleState !== undefined) {
			setShouldUseMyFilters(forceToggleState);
		} else {
			setShouldUseMyFilters(prev => !prev);
		}
	}, []);

	const shouldUseExistingFilterRef = React.useRef(!disableDefaultFilters && !!filtersFromSessionStorage);

	const updateFilterPropsAndResetFilterToggle = React.useCallback((input: Partial<FilterStateProps>) => {
		shouldUseExistingFilterRef.current = false;
		setShouldUseMyFilters(false);
		updateFilterProps(input);
	}, [setShouldUseMyFilters, updateFilterProps]);

	const filters: FilterParams = React.useMemo(() => ({
		procedureDateFilter: filterState.procedureDate,
		statusFilter: filterState.status,
		stateFilter: filterState.state,
		surgeonFilter: filterState.surgeon,
		page: Number(filterState.page),
		size: Number(filterState.size),
		sortModel: filterState.sortModel,
		from: filterState.from,
		to: filterState.to,
		globalFilter: filterState.globalFilter,
		allOtherFilters: filterState.allOtherFilters,
		filterApplied: isMyFiltersEnabled,
	}), [isMyFiltersEnabled, filterState]);

	const filterSelectionsEmpty = React.useMemo(() => {
		const defaultFilterValuesFlat = Object.values(defaultFilters).flat();
		const filterStatFlat = Object.values(filterState).flat();
		return arrayDeepEqual(defaultFilterValuesFlat, filterStatFlat);
	}, [filterState]);

	/**
	 * Use route params or default filters
	 */
	const savedFilters = useSelector(MyFiltersSelector);
	const isOfficeRole = useSelector(UserProfileHasARoleSelector(OfficeRoles));

	// Search should only update on first load
	React.useEffect(() => {
		// Should use the ones provided by the state to initialize when present
		if (initialFilterRef?.current && !shouldUseExistingFilterRef.current) {
			const { filterApplied: filterWasApplied, ...initialFilters } = apiParamsToFilters(initialFilterRef.current);
			// Use filterApplied logic when set true, otherwise set the individual parameters
			if (!filterWasApplied) {
				updateFilterProps(initialFilters);
			}
			return;
		}

		// Use query parameters when available
		if (!disableRouteParams && filtersFromSessionStorage && shouldUseExistingFilterRef.current) {
			shouldUseExistingFilterRef.current = false; // Set false to prevent using it again
			updateFilterProps(filtersFromSessionStorage);
			return;
		}

		// Otherwise set filterApplied = true to trigger default filters
		setShouldUseMyFilters(true);
	}, [disableRouteParams, updateFilterProps, initialFilterRef, filtersFromSessionStorage]);

	// When MyFilters enabled
	React.useEffect(() => {
		// My Filters not enabled - do nothing
		if (!isMyFiltersEnabled) { return; }
		// disableDefaultFilters or no filters on user profile - do nothing
		if (disableDefaultFilters || !hasSavedFilters) { return; }

		// Set default filters based on user role
		const filtersByRoles = isOfficeRole ? { ...savedFilters, surgeon: [] } : { ...savedFilters, unit: [] };
		updateFilterProps(filtersByRoles);
	}, [disableDefaultFilters, isMyFiltersEnabled, hasSavedFilters, isOfficeRole, savedFilters, updateFilterProps]);

	/**
	 * Update saved query when filters and filterApplied changes
	 */
	React.useEffect(() => {
		if (disableRouteParams) { return; }
		sessionStorage.setItem(StorageKey.FILTER_QUERY + entityName, filtersToReplaceString(filters, pathname) + (userId ? '::' + userId : ''));
	}, [filters, pathname, disableRouteParams, entityName, userId]);

	const loadListWithFilters = React.useCallback(() => {
		onListLoadRequest(filtersToApiParams(filters));
	}, [filters, onListLoadRequest]);

	// Load list when filters change
	React.useEffect(() => {
		loadListWithFilters();
	}, [loadListWithFilters]);

	const filtersValue: FiltersContextType = {
		disableDefaultFilters,
		loadListWithFilters,

		filters: filters,
		entityName,
		totalCount,
		sortModel: filters.sortModel || defaultSort,
		page: filters.page || DEFAULT_PAGE,
		size: filters.size || DEFAULT_SIZE,
		surgeons: filters.surgeonFilter as string[],
		procedureDate: filters.procedureDateFilter as string,
		allOtherFilters: filters.allOtherFilters as string[],
		status: filters.statusFilter as string[],
		globalFilter: filters.globalFilter as string,
		state: filters.stateFilter as string[],
		from: filters.from as string,
		to: filters.to as string,

		updateFilterProps,
		filterApplied: isMyFiltersEnabled,
		toggleFilter: toggleShouldUseMyFilter,
		updateFilterPropsAndResetFilterToggle,
		filterSelectionsEmpty,
		defaultSort
	};

	return (
		<FiltersContext.Provider value={filtersValue}>
			{children}
		</FiltersContext.Provider>
	);
};
