import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@ascension/web';
import {
	OtherFilterFieldTypes, otherFiltersArrayOfObjectsForCheckboxes,
	otherFiltersCategoryToIdsMap, otherFiltersIdToCategoryMap
} from '@utilities/constants';
import MyFiltersCheckbox from '../MyFiltersCheckbox';
import { useFieldFormContext } from '@utilities/hooks/useFieldFormContext/useFieldFormContext';
import { useFormMetaSchema } from '@store/context/FormMetaContext';
import { MyFiltersState } from '@interfaces/MyFilters/MyFiltersState';
import { FormAssistiveText } from '../../../FormComponents/FormAssistiveText/FormAssistiveText';
import {
	OtherFilterFieldTypesSubmittableToAPI,
	OtherFiltersObjectForCheckbox
} from '@interfaces/OtherFilterTypes';
import { UserProfileRoleSelector } from '@store/selectors/UserProfileSelector';
import { UserRole } from '@interfaces/UserProfile/UserRoleEnum';

const fieldNames = Object.values(OtherFilterFieldTypes).filter((v) => Number.isNaN(+v));

interface FormArrayVals {
	currentValue: string[] | null;
	setCurrentValue: (v: string[] | null) => void;
}

export default function OtherFilters ()  {

	const otherFiltersArrayOfObjects: OtherFiltersObjectForCheckbox[] = otherFiltersArrayOfObjectsForCheckboxes;
	const otherFiltersCategoryToArrayOfIdsMap: Record<OtherFilterFieldTypes, string[]> = React.useMemo(() => otherFiltersCategoryToIdsMap, []);
	const otherFiltersMapIdToCategory: Record<string, OtherFilterFieldTypes> = React.useMemo(() => otherFiltersIdToCategoryMap(), []);

	const {
		currentValue: stateCurrentValue,
		setCurrentValue: setStateCurrentValue,
	} = useFieldFormContext('state', {
		validationRules: useFormMetaSchema<MyFiltersState>('state'),
		triggerValidationOnChange: true
	}) as FormArrayVals;
	const {
		currentValue: statusCurrentValue,
		setCurrentValue: setStatusCurrentValue,
	} = useFieldFormContext('status', {
		validationRules: useFormMetaSchema<MyFiltersState>('status'),
		triggerValidationOnChange: true
	}) as FormArrayVals;

	const isAllSelected = !!(stateCurrentValue && !stateCurrentValue.length && statusCurrentValue && !statusCurrentValue.length);

	const handleUpdateValue = React.useCallback((id: string) => {

		const areAllCurrentValuesNull = (stateCurrentValue == null && statusCurrentValue == null);

		const getCurrentValGetterAndSetterForCategoryId = (categoryId: OtherFilterFieldTypesSubmittableToAPI) =>{
			if (categoryId == OtherFilterFieldTypes.STATUS) return { currentVal: statusCurrentValue, setCurrentVal: setStatusCurrentValue };
			else return { currentVal: stateCurrentValue, setCurrentVal: setStateCurrentValue };
		};

		const setAllCurValTo = (newValForAll: [] | null) =>{
			setStateCurrentValue(newValForAll);
			setStatusCurrentValue(newValForAll);
		};

		const handleAllClicked = () => isAllSelected ? setAllCurValTo(null) : setAllCurValTo([]);

		//this function handles change for every checkbox except for "all" checkbox
		const getNewValue = (id: string, currentValue: string[] | null) => {
			if (currentValue == null) return [ id ];
			else return currentValue.includes(id) ? currentValue.filter((c: string) => c !== id ) : [...currentValue, id];
		};

		const areOtherCurrentValuesEmptyArrays = (currentValue: string[] | null) => {
			if (stateCurrentValue !== currentValue && stateCurrentValue && stateCurrentValue.length > 0) {
				return false;
			}
			else return !(statusCurrentValue !== currentValue && statusCurrentValue && statusCurrentValue.length > 0);
		};


		//have to use function instead of actual currentValue for comparison because currentValue can be null, but setCurrentValue is never null
		const setOtherCurrentValuesToEmptyArray = (setCurrentValue: (newVal: string[]) => void) => {
			if (setStateCurrentValue !== setCurrentValue) setStateCurrentValue([]);
			if (setStatusCurrentValue !== setCurrentValue) setStatusCurrentValue([]);
		};

		//checks whether after updating appropriate currentValue with newValue all ids will be selected
		const willAllIdsBeSelected = (categoryId: OtherFilterFieldTypes, newValue: string[]) => {
			const allowedCategories: OtherFilterFieldTypesSubmittableToAPI[] = [OtherFilterFieldTypes.STATUS, OtherFilterFieldTypes.STATE];
			for (const [curCategoryId, arrayOfAllIdsForCategory] of Object.entries(otherFiltersCategoryToArrayOfIdsMap)) {
				if (allowedCategories.includes(curCategoryId as OtherFilterFieldTypesSubmittableToAPI)) {
					if (curCategoryId !== categoryId.toString()) {
						const { currentVal: currentValueForCategory } = getCurrentValGetterAndSetterForCategoryId(curCategoryId as OtherFilterFieldTypesSubmittableToAPI);
						if (arrayOfAllIdsForCategory.length !== currentValueForCategory?.length) return false;
					}
					else if (otherFiltersCategoryToArrayOfIdsMap[categoryId].length !== newValue.length)  return false;
				}
			}
			return true;
		};

		const updateWithNewValue = (newValue: string[], currentValue: string[] | null, setCurrentValue: (newVal: string[]) => void, categoryId: OtherFilterFieldTypes) =>{
			//first id selected/added
			if (areAllCurrentValuesNull && newValue.length == 1) {
				setCurrentValue(newValue);
				setOtherCurrentValuesToEmptyArray(setCurrentValue);
			}
			//last id removed from currentValue and other currentValues are empty
			else if (!newValue.length && areOtherCurrentValuesEmptyArrays(currentValue)) setAllCurValTo(null);
			//if all id's will be selected then set all to empty arrays
			else if (willAllIdsBeSelected(categoryId, newValue)) setAllCurValTo([]);
			//regular toggle - just replace newValue
			else setCurrentValue(newValue);
		};

		const categoryId: OtherFilterFieldTypes = otherFiltersMapIdToCategory[id];

		if (categoryId === OtherFilterFieldTypes.All) handleAllClicked();
		else {
			const { currentVal: getCurVal, setCurrentVal: setCurVal } = getCurrentValGetterAndSetterForCategoryId(categoryId);
			updateWithNewValue(getNewValue(id, getCurVal), getCurVal, setCurVal, categoryId);
		}

	}, [isAllSelected, otherFiltersCategoryToArrayOfIdsMap, otherFiltersMapIdToCategory, setStateCurrentValue, setStatusCurrentValue, stateCurrentValue, statusCurrentValue]);

	const mapCheckboxIdToIsChecked = React.useMemo(() => otherFiltersArrayOfObjects.reduce((newMap: {[id: string]: boolean}, curObj: OtherFiltersObjectForCheckbox) => {
		if (curObj.id == 'all') newMap[curObj.id] = isAllSelected;
		else newMap[curObj.id] = !!((stateCurrentValue && stateCurrentValue.includes(curObj.id)) || (statusCurrentValue && statusCurrentValue.includes(curObj.id)));
		return newMap;
	}, {}), [isAllSelected, otherFiltersArrayOfObjects, stateCurrentValue, statusCurrentValue]);

	const userProfileRoles = useSelector(UserProfileRoleSelector);

	function isAValidRole(showForRoles: UserRole[]) {
		return userProfileRoles.some((role) => {
			return showForRoles.includes(role);
		});
	}

	return (
		<Box display="flex" flexDirection="column"  py={8}>
			<Box display="flex" flexDirection="column" justifyContent="space-between" width={1} alignItems="start" pb={8} data-field="fullName">
				<Box fontSize={18} letterSpacing="1.4px" color="#222222" fontWeight="bold" pb = {4}>
					Other Filters
				</Box>
				<Box>
					<FormAssistiveText field={fieldNames}>Select at least one filter.</FormAssistiveText>
				</Box>
			</Box>
			<Box display="flex" flexDirection="row" flexWrap="wrap" width={1} pb={4}>
				{otherFiltersArrayOfObjects.map(({ id, label, showForRoles }) => {
					return (
						(showForRoles == undefined || isAValidRole(showForRoles)) && <MyFiltersCheckbox
							id={ id }
							name={ label }
							onCheck={ handleUpdateValue }
							key={ id }
							capitalizeName={ true }
							checked={mapCheckboxIdToIsChecked[id]}
						/>
					);
				})}
			</Box>
		</Box>
	);
}
