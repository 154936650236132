export enum ToastType {
    NONE,
    SUCCESS,
    WARNING,
    ERROR,
}

export type ActiveToastType = ToastType.WARNING | ToastType.SUCCESS | ToastType.ERROR;

interface ToastBaseState {
    toastType: ToastType;
    timeout?: number;
    message?: string;
    timestamp?: Date;
}

interface NoneState extends ToastBaseState {
    toastType: ToastType.NONE;
    timeout?: never;
    message?: never;
    timestamp?: never;
}

interface ActiveToastState extends ToastBaseState {
    toastType: ActiveToastType;
    message: string;
    timeout: number;
    timestamp: Date;
}

export type ToastStatusState = NoneState | ActiveToastState;
