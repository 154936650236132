import { Unit } from '@data/unit/Unit';
import { Facility } from '@interfaces/Facility';

type FacilityMap = Record<string, Facility>;

/***
 * getFacilitiesFromUnits - aggregates a list of facilities (aka hospitals) from a list of units
 * @param units {Unit[]} List of units, each with an associated facility
 *
 * @return {Facility[]} List of facilities (without duplicates), each facility has a list of units
 *
 * @example
 * getFacilitiesFromUnits(unitListMock);
 * // returns:
 * // facilityListMock
 */
export const getFacilitiesFromUnits = (units: Unit[]): Facility[] => {
	const facilityMap = units.reduce((facilityList: FacilityMap, unit: Unit) => {
		// if facility already exists, just append the unit to the facility's units property
		if (facilityList[unit.hospital]) {
			facilityList[unit.hospital] = {
				...facilityList[unit.hospital],
				units: [...facilityList[unit.hospital].units, unit]
			};
		} else {
			// if facility doesn't already exist, add a new facility to list
			facilityList[unit.hospital] = {
				id: unit.hospital,
				name: unit.hospitalName,
				hospitalCernerId: unit.hospitalCernerId || '',
				hospitalTimeZone: unit.hospitalTimeZone,
				units: [unit],
			};
		}

		return facilityList;
	}, {});

	return Array.from(Object.values(facilityMap));
};
