import React from 'react';
import { FieldValues, useController } from 'react-hook-form';
import { makeStyles } from '@material-ui/core';
import { useAnchorContext } from '@components/AnchorProvider/AnchorProvider';
import { CalendarTodayOutlined } from '@material-ui/icons';
import DatePeriodPicker from '@components/DatePeriodPicker/DatePeriodPicker';
import { StatefulDateRangePickerProps } from '@components/StatefulInputs/StatefulDateRangePicker/StatefulDateRangePicker.types';
import { StaticTextInput } from '@components/StatefulInputs/StatefulDateRangePicker/StaticTextInput/StaticTextInput';
import { track } from '@amplitude/analytics-browser';
import { BaseEventTrackingWrapper } from '@components/BaseEventTrackingWrapper/BaseEventTrackingWrapper';

const useStyles = makeStyles((theme) => ({
	staticTextInput: {
		'cursor': 'pointer',
		'& .MuiSvgIcon-root': {
			fill: theme.palette.primary.main,
			height: '32px',
			width: '32px',
		},
		'& > input': {
			textAlign: 'left',
			fontSize: '18px',
			cursor: 'pointer',
		},
	},
	buttonContainer: {
		'display': 'flex',
		'flexWrap': 'wrap',
		'gap': theme.spacing(3),
		'& > .MuiBox-root': {
			'flex': 1,
			// This appears to be the smallest width that will allow the label text to be displayed without wrapping
			'flexBasis': 311,
			'& > .MuiFormControl-root': {
				width: '100%',
			},
		},
	},
}));

export function StatefulDateRangePicker<FormType extends FieldValues>({
	startName,
	endName,
	startControllerOptions,
	endControllerOptions,
	startTextInputProps,
	endTextInputProps,
	startLabel,
	endLabel,
	dateRangePickerProps,
	showActionButtons,
	previewRangeDays,
	handleDisableDates,
	dataField,
}: StatefulDateRangePickerProps<FormType>) {
	const classes = useStyles();

	const { field: startField } = useController<FormType>({
		name: startName,
		defaultValue: undefined,
		...startControllerOptions,
	});

	const { field: endField } = useController<FormType>({
		name: endName,
		defaultValue: undefined,
		...endControllerOptions,
	});

	const { anchorEl, handleSetAnchorEl, handleClearAnchorEl } = useAnchorContext();

	const handleOnClose = () => {
		handleClearAnchorEl();
		startField.onBlur();
		endField.onBlur();
	};

	const [sideClicked, setSideClicked] = React.useState<'left' | 'right' | undefined>();
	const buttonContainerRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		if (anchorEl && !startField.value) {
			startField.onChange(startControllerOptions.defaultValue);
			endField.onChange(endControllerOptions.defaultValue);
		}
	}, [
		anchorEl,
		endControllerOptions.defaultValue,
		endField,
		startControllerOptions.defaultValue,
		startField,
	]);

	React.useEffect(() => {
		if (anchorEl) {
			setTimeout(() => {
				const queryString =
					sideClicked === 'left'
						? '.rdrDateDisplayItem input'
						: '.rdrDateDisplayItem:nth-child(2) input';
				const dateInput = document.querySelector<HTMLInputElement>(queryString);
				dateInput?.focus();
				dateInput?.select();
			});
		}
	}, [anchorEl, sideClicked]);

	const handleCancel = React.useCallback(() => {
		startField.onChange(undefined);
		endField.onChange(undefined);
	}, [endField, startField]);

	const handleTextInputClick = React.useCallback(
		(inputSide: 'left' | 'right') => {
			setSideClicked(inputSide);
			handleSetAnchorEl({
				currentTarget: buttonContainerRef.current,
			} as unknown as React.MouseEvent<HTMLElement>);
		},
		[handleSetAnchorEl],
	);

	const handleTrackingEvent = React.useCallback((event: React.SyntheticEvent<HTMLElement>, inputType: 'Start' | 'End') => {
		const eventType = event.type;
		const eventName = `dateRangePicker-${eventType}`;

		track(eventName, { fieldName: `${dataField}${inputType}`, eventType: eventType });
	}, [dataField]);

	return (
		<>
			<div className={classes.buttonContainer} ref={buttonContainerRef}>
				<BaseEventTrackingWrapper
					handleEvent={e => handleTrackingEvent(e, 'Start')}
					dataField={dataField}
				>
					<StaticTextInput
						name={startName}
						label={startLabel}
						field={startField}
						textInputProps={{
							...startTextInputProps,
							className: classes.staticTextInput,
							endAdornment: <CalendarTodayOutlined />,
							onClick: () => handleTextInputClick('left'),
						}}
					/>
				</BaseEventTrackingWrapper>
				<BaseEventTrackingWrapper
					handleEvent={e => handleTrackingEvent(e, 'End')}
					dataField={dataField}
				>
					<StaticTextInput
						name={endName}
						label={endLabel}
						field={endField}
						textInputProps={{
							...endTextInputProps,
							className: classes.staticTextInput,
							endAdornment: <CalendarTodayOutlined />,
							onClick: () => handleTextInputClick('right'),
						}}
					/>
				</BaseEventTrackingWrapper>
			</div>
			<DatePeriodPicker
				startDate={startField.value}
				endDate={endField.value}
				defaultStartDate={startControllerOptions.defaultValue}
				defaultEndDate={endControllerOptions.defaultValue}
				anchor={anchorEl}
				onClose={handleOnClose}
				onCancel={handleCancel}
				onStartChange={startField.onChange}
				onEndChange={endField.onChange}
				onApplyChange={handleClearAnchorEl}
				dateRangePickerProps={dateRangePickerProps}
				showActionButtons={showActionButtons}
				previewRangeDays={previewRangeDays}
				handleDisableDates={handleDisableDates}
				popoverProps={{
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},
					transformOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},
					PaperProps: {
						style: {
							minWidth: buttonContainerRef.current?.offsetWidth,
							display: 'flex',
							justifyContent: 'center',
						},
					},
				}}
			/>
		</>
	);
}
