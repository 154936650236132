import React from 'react';
import { useSelector } from 'react-redux';
import * as amplitude from '@amplitude/analytics-browser';
import { UserProfileDetailsSelector } from '@store/selectors/UserProfileSelector';
import {
	useDataFieldInteractionTracking
} from '@utilities/hooks/useDataFieldInteractionTracking/useDataFieldInteractionTracking';

const VALID_KEY_REG_EX = /^[0-9a-fA-F]{32}$/;

export const useAmplitude = () => {
	const userInfo = useSelector(UserProfileDetailsSelector);
	const userInfoRef = React.useRef(userInfo);

	userInfoRef.current = userInfo;

	// shouldInitialize is to prevent the first useEffect from running without userInfo. userInfo is necessary to set up
	// the Amplitude identify event.
	const shouldInitialize = React.useMemo(() => {
		return userInfo && Object.keys(userInfo).length > 0;
	}, [userInfo]);

	useDataFieldInteractionTracking();

	React.useEffect(() => {
		if (!shouldInitialize) { return; }

		const {
			email,
			ext,
			firstName,
			lastName,
			phone,
			primaryHospital,
			primaryPractice,
			userId,
			userRoles = [],
			username,
		} = userInfoRef.current;

		const identifyEvent = new amplitude.Identify();

		amplitude.setUserId(userId);
		identifyEvent.set('email', email);
		identifyEvent.set('fullName', `${lastName}, ${firstName}`);
		identifyEvent.set('role', userRoles);
		identifyEvent.set('phone', phone);
		identifyEvent.set('ext', ext);

		const ministry = username.split('\\')[0];
		identifyEvent.set('ministry', ministry);

		if (primaryPractice) {
			identifyEvent.set('practiceId', primaryPractice.id);
			identifyEvent.set('practiceName', primaryPractice.name);
		}

		if (primaryHospital) {
			identifyEvent.set('hospitalId', primaryHospital.id);
			identifyEvent.set('hospitalName', primaryHospital.name);
		}

		amplitude.identify(identifyEvent);
	}, [shouldInitialize]);

	// Amplitude will start on mount and track clicks without the userInfo
	React.useEffect(() => {
		if (!VALID_KEY_REG_EX.test(window.config.AMPLITUDE_API_KEY)) {
			return;
		}

		amplitude.init(window.config.AMPLITUDE_API_KEY);
	}, []);
};
