import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSchedulePageContext } from '@components/SchedulePage/SchedulePageContext/SchedulePageContext';
import { createStyles, IconButton, IconButtonProps } from '@material-ui/core';
import { Refresh as RefreshIcon } from '@material-ui/icons';

const useStyles = makeStyles(theme => createStyles({
	root: {
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(1),
	},
}));

const RefreshScheduleButton: React.FC<IconButtonProps> = ({ ...iconButtonProps }) => {
	const classes = useStyles();
	const { getSchedules } = useSchedulePageContext();

	return (
		<IconButton
			data-field="schedule-refreshCases-button"
			color="primary"
			className={classes.root}
			onClick={() => getSchedules()}
			{...iconButtonProps}
		>
			<RefreshIcon />
		</IconButton>
	);
};

export default RefreshScheduleButton;
