import React from 'react';

export const useHandleKeyDown = (keyConfig: Record<string, (e: KeyboardEvent) => void>, disableEventListener = false) => {
	const handleKeyDown = React.useCallback((e: KeyboardEvent): void => {
		keyConfig[e.key](e);
	}, [keyConfig]);

	React.useEffect(() => {
		if (disableEventListener) return;

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [disableEventListener, handleKeyDown]);
};
