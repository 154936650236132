import { AbbreviatedProcedureCardTagProps } from './AbbreviatedProcedureCardTagProps';
import { TemplateTagType } from '../../AccountSettings/MyTemplates/TemplateTile/TemplateTagType';
import { Tag } from '@ascension/web';
import React from 'react';

type TagTypeTextType = {
	[tagType in TemplateTagType]: string | undefined;
};
const TagTypeText: TagTypeTextType = {
	[TemplateTagType.DUPLICATE]: 'Duplicate',
	[TemplateTagType.EDITED]: 'Edited',
	[TemplateTagType.NEW]: 'New',
	[TemplateTagType.NONE]: undefined,
};

const AbbreviatedProcedureCardTag = ({ tagType, ...rest }: AbbreviatedProcedureCardTagProps) => {
	if (!tagType) { return null; }
	const tagText = TagTypeText[tagType];
	if (!tagText) { return null; }
	return (
		<Tag data-field="tag" color="#0B8670" m={2} {...rest} >
			{ tagText }
		</Tag>
	);
};

export default AbbreviatedProcedureCardTag;
