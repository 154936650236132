import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import ViewDocument
	from '@components/CaseDetails/DocumentInformationDisplay/DocumentDetails/DocumentActions/ViewDocument';
import DownloadDocument
	from '@components/CaseDetails/DocumentInformationDisplay/DocumentDetails/DocumentActions/DownloadDocument';
import { AttachmentStatusTypeEnum } from '@interfaces/SurgeryRequest/AttachmentStatusTypeEnum';
import {
	useDocumentDetailsContext
} from '@components/CaseDetails/DocumentInformationDisplay/DocumentDetails/DocumentDetails';

const useStyles = makeStyles({
	root: {
		display: 'flex'
	}
});

const DocumentActions = () => {
	const classes = useStyles();

	const { status } = useDocumentDetailsContext();

	if (status != AttachmentStatusTypeEnum.CLEAN) {
		return null;
	}

	return <Box className={classes.root}>
		<ViewDocument />
		<DownloadDocument fieldName="docList-download" />
	</Box>;
};

export default DocumentActions;
