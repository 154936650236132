import { Box, Label, Radio } from '@ascension/web';
import { TextArea } from '@ascension/web/components/base/TextArea';
import React from 'react';
import { useFieldFormContext } from '@utilities/hooks/useFieldFormContext/useFieldFormContext';
import { RequestRevisionValidationSchema } from './RequestRevisionValidationSchema';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';

const RequestRevision = () => {
	const {
		currentValue: requestRevisionReasonCurrentValue,
		setCurrentValue: requestRevisionReasonCurrentValueSetCurrentValue,
		error: requestRevisionReasonError,
	} = useFieldFormContext<string>('requestRevisionReason', {
		validationRules: RequestRevisionValidationSchema.revisionReason,
		triggerValidationOnChange: true,
	});

	const {
		currentValue: requestRevisionMessageCurrentValue,
		setCurrentValue: requestRevisionMessageSetCurrentValue,
		error: requestRevisionMessageError,
	} = useFieldFormContext<string>('requestRevisionMessage', {
		validationRules: RequestRevisionValidationSchema.revisionMessage,
	});

	const handleReasonRadio = React.useCallback(
		(checked: boolean, value: string) => {
			if (checked) {
				requestRevisionReasonCurrentValueSetCurrentValue(value);
			}
		},
		[requestRevisionReasonCurrentValueSetCurrentValue],
	);

	return (
		<>
			<Box pt={8} data-field="requestRevisionProcTime">
				<Label required>REVISION REASON</Label>
				<Radio
					key={1}
					name="requestRevisionReasonProcTime"
					id="requestRevisionReasonProcTime"
					checked={
						requestRevisionReasonCurrentValue ===
						'Change procedure date and/or time'
					}
					label="Change procedure date and/or time"
					value="Change procedure date and/or time"
					onChange={handleReasonRadio}
				/>
			</Box>
			<Box pt={8} data-field="requestRevisionMissingInfo">
				<Radio
					key={2}
					name="requestRevisionReasonMissingInfo"
					id="requestRevisionReasonMissingInfo"
					checked={
						requestRevisionReasonCurrentValue ===
						'Missing information'
					}
					label="Missing information"
					value="Missing information"
					onChange={handleReasonRadio}
				/>
			</Box>
			<Box pt={8}	data-field="requestRevisionOther">
				<Radio
					key={3}
					name="requestRevisionReasonOther"
					id="requestRevisionReasonOther"
					checked={requestRevisionReasonCurrentValue === 'Other'}
					label="Other"
					value="Other"
					onChange={handleReasonRadio}
				/>
			</Box>
			<ErrorMessage
				errorField={{ message: requestRevisionReasonError }}
			/>
			<Box pt={8}>
				<TextArea
					label={<span>Message</span>}
					required
					value={requestRevisionMessageCurrentValue}
					name="requestRevisionMessage"
					onChange={requestRevisionMessageSetCurrentValue}
				/>
				<ErrorMessage
					errorField={{ message: requestRevisionMessageError }}
				/>
			</Box>
		</>
	);
};

export default RequestRevision;
