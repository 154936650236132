import React from 'react';
import { useAuthBaseContext } from '@components/AuthBase/AuthBase';

/**
 * Hook to block external navigation when a form is dirty.
 *
 * This hook adds a 'beforeunload' event listener to the window object that prevents navigation
 * away from the current page when the form is dirty and auto logout is not active.
 *
 * @param {boolean} isDirtyForm - A boolean indicating whether the form is dirty. Defaults to false.
 */
export const useBlockExternalNavigation = (isDirtyForm: boolean = false) => {
	const { isAutoLogout } = useAuthBaseContext();

	React.useEffect(() => {
		/**
		 * Event handler for the 'beforeunload' event.
		 *
		 * If the form is dirty and auto logout is not active, this function prevents navigation away
		 * from the current page and sets a custom message to be displayed in the confirmation dialog.
		 * Note: Some browsers do not allow custom messages anymore
		 *
		 * @param {BeforeUnloadEvent} event - The 'beforeunload' event object.
		 */
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			if (isDirtyForm && !isAutoLogout) {
				event.preventDefault();
				event.returnValue = 'Are you sure you want to navigate away?';
			}
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		// Remove the event listener when the component is unmounted or when dependencies change.
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [isAutoLogout, isDirtyForm]);
};
