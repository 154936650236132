type RoutesType =
	| 'HOME'
	| 'CASE_LIST'
	| 'SCHEDULING'
	| 'FIND_A_TIME'
	| 'UNIT_CALENDAR'
	| 'MANAGE_USERS'
	| 'USER_REGISTRATION'
	| 'EDIT_USER_REGISTRATION'
	| 'QUEUE'
	| 'CASE_DETAILS'
	| 'REQUEST_FORM'
	| 'ACCOUNT_SETTINGS'
	| 'SETUP_FILTERS'
	| 'SETUP_PROFILE'
	| 'TERMS_OF_USE'
	| 'LOGIN';

// used within Routes.tsx and throughout app when redirecting to routes
// constants are not used when redirecting to dynamic routes (ones that include :)
export const ROUTE_CONSTANTS: Record<RoutesType, string> = {
	HOME: '/',
	CASE_LIST: '/caselist',
	SCHEDULING: '/scheduling',
	FIND_A_TIME: '/findatime',
	UNIT_CALENDAR: '/unit/calendar',
	MANAGE_USERS: '/users',
	USER_REGISTRATION: '/users/register',
	EDIT_USER_REGISTRATION: '/users/edit/:userId',
	QUEUE: '/queue/:indexPosition',
	CASE_DETAILS: '/cases/:caseId',
	REQUEST_FORM: '/request',
	ACCOUNT_SETTINGS: '/accountSettings/:type',
	SETUP_FILTERS: '/setup/filters',
	SETUP_PROFILE: '/setup/profile',
	TERMS_OF_USE: '/termsOfUse',
	LOGIN: '/login',
};
