import AppAlertState from '../../interfaces/AppAlert/AppAlertState';
import AppAlertAction from '../../interfaces/AppAlert/AppAlertAction';
import { CLEAR_APP_ALERT, SET_APP_ALERT } from '../actions/types';

const initialState: AppAlertState = {};

const AppAlertReducer = (state: AppAlertState = initialState, action: AppAlertAction): AppAlertState =>{
	switch (action.type) {
	case CLEAR_APP_ALERT:
		return { ...initialState };
	case SET_APP_ALERT:
		return {
			...action.payload
		};
	}
	return state;
};

export default AppAlertReducer;
