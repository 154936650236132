import { GET_SURGEON_LIST, UPDATE_ALL_SURGEON_LIST, UPDATE_SURGEON_LIST, UPDATE_SURGEON_LIST_ERROR } from './types';
import { ApiActionRequest } from '@interfaces/ApiAction/ApiAction';
import { apiAction } from '../middlewares/api/ApiActionCreators';
import { ENDPOINT_ALL_SURGEONS, ENDPOINT_SURGEONS } from '@utilities/apiConstants';
import { Surgeon } from '@data/surgeon/Surgeon';
import { AxiosResponse } from 'axios';
import errorToString from '../../utilities/errorToString';
import { SurgeonListTypes } from '@interfaces/SurgeonListTypes';
import { CacheType } from '@interfaces/CacheType';
import { Action, Dispatch } from 'redux';
import { RootState } from '@interfaces/rootState';
import { ThunkAction } from 'redux-thunk';
import { ApiActionPayload } from '@interfaces/ApiAction/ApiActionPayload';

export function updateSurgeonList({ data }: AxiosResponse<Surgeon[]>): SurgeonListTypes {
	return {
		type: UPDATE_SURGEON_LIST,
		payload: {
			data
		}
	};
}

export function updateFullSurgeonList({ data }: { data: Surgeon[] }): SurgeonListTypes {
	return {
		type: UPDATE_ALL_SURGEON_LIST,
		payload: { data }
	};
}

export function updateSurgeonListError(error: unknown): SurgeonListTypes {
	return {
		type: UPDATE_SURGEON_LIST_ERROR,
		error: errorToString(error)
	};
}

export function getAllSurgeonListData (practiceId: string): ApiActionRequest<Surgeon[]> {
	return apiAction<Surgeon[]>({
		url: ENDPOINT_ALL_SURGEONS + practiceId,
		method: 'GET',
		label: GET_SURGEON_LIST,
		includeAccessToken: true,
		onSuccess: updateFullSurgeonList,
		onFailure: updateSurgeonListError,
		cachePolicy: CacheType.RETRIEVE_OR_ADD_CACHE,
	});
}

export function updateAllTheSurgeons(data: AxiosResponse<Surgeon[]>): ThunkAction<void, RootState, void, Action> {
	return (dispatch: Dispatch, getState: () => RootState) => {
		const fullList = getState().surgeonList.fullSurgeonList;
		dispatch(updateSurgeonList(data));
		dispatch(updateFullSurgeonList({ data : fullList }));
	};
}

export function getSurgeonListData(overrideOptions?: Partial<ApiActionPayload<Surgeon[]>>): ApiActionRequest<Surgeon[]> {
	return apiAction<Surgeon[]>({
		url: ENDPOINT_SURGEONS,
		method: 'GET',
		label: GET_SURGEON_LIST,
		includeAccessToken: true,
		onSuccess: updateSurgeonList,
		onFailure: updateSurgeonListError,
		cachePolicy: CacheType.RETRIEVE_OR_ADD_CACHE,
		...overrideOptions,
	});
}

// Temporary refactored to simplify getSurgeonListData
export function getAndUpdateAllSurgeonListData() {
	return apiAction<Surgeon[]>({
		url: ENDPOINT_SURGEONS,
		method: 'GET',
		label: GET_SURGEON_LIST,
		includeAccessToken: true,
		onSuccess: updateAllTheSurgeons,
		onFailure: updateSurgeonListError,
		cachePolicy: CacheType.UPDATE_CACHE,
	});
}
