import React from 'react';
import { useSelector } from 'react-redux';
import { NotificationChipProps } from '@components/CaseTrackerList/CaseCard/NotificationChipProps';
import { UserProfileHasARoleSelector } from '@store/selectors/UserProfileSelector';
import Chip from '@components/Chip/Chip';

export const NotificationChip = ({ chipColor = 'default', chipText, showForRoles }: NotificationChipProps )  => {
	const shouldShowForUsersRole = useSelector(UserProfileHasARoleSelector(showForRoles)) || !showForRoles;

	return shouldShowForUsersRole ? (
		<Chip data-field={'tag' + chipText} color={chipColor}>
			{chipText}
		</Chip>
	) : null;
};
