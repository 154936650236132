import React from 'react';
import { Text } from '@ascension/web';
import { Paper, makeStyles } from '@material-ui/core';
import { SetupCardSectionProps } from './SetupCardSectionProps';

const useStyles = makeStyles({
	cardSection: {
		width: '100%',
		padding: '32px',
		margin: '12px 0',
		boxSizing: 'border-box',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		color: '#000000'
	},
	title: {
		fontSize: '24px'
	}
});

export const SetupCardSection = ({ sectionTitle, children, customCardStyles }: SetupCardSectionProps) => {
	const { cardSection, title } = useStyles();

	return (
		<Paper className={`${cardSection} ${customCardStyles}`}>
			<Text className={title}>{sectionTitle}</Text>
			{children}
		</Paper>
	);
};
