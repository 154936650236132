import React from 'react';
import { Range } from 'react-date-range';
import { addDays, format } from 'date-fns';
import { Multiselect } from '@ascension/web';
import { useFilterState } from '../FiltersContext/FiltersContext';
import { PROCEDURE_DATE_OPTIONS } from '@utilities/constants';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import DatePeriodPicker from '@components/DatePeriodPicker/DatePeriodPicker';
import { alpha, Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
	return {
		dateFilterDropdowmn: {
			'minWidth': 120,
			'border': '1px solid',
			'borderRadius': theme.spacing(0.5),
			'borderColor': alpha(theme.palette.common.black, 0.6),
			'& > div > div > div:nth-of-type(2)': {
				marginTop: theme.spacing(0.2),
			},
		},
	};
});

const defaultOption = { value: 'upcoming', label: 'UPCOMING' };

const defaultCustomStartDate = new Date();
const defaultCustomEndDate = addDays(new Date(), 7);

export default function ProcedureDateFilter() {
	const {
		from,
		to,
		procedureDate,
		updateFilterPropsAndResetFilterToggle
	} = useFilterState();

	const classes = useStyles();

	const paramValue = PROCEDURE_DATE_OPTIONS.filter(item => item.value == procedureDate);
	const initialOption = paramValue.length > 0 ? paramValue[0] : defaultOption;

	const [value, setValue] = React.useState([initialOption]);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const filterRef = React.useRef(null);

	const newProcedureDate = React.useMemo(() => procedureDate ?
		PROCEDURE_DATE_OPTIONS.filter(option => option.value == procedureDate)
		: [defaultOption], [procedureDate]);
	const valueNeedsUpdate = React.useMemo(
		() => value[0].value !== newProcedureDate[0].value,
		[value, newProcedureDate]
	);

	React.useEffect(() => {
		valueNeedsUpdate && setValue(newProcedureDate);
	}, [ newProcedureDate, valueNeedsUpdate ]);

	const openPopover = () => {
		setAnchorEl(filterRef.current);
	};

	const closePopover = React.useCallback(() => {
		setAnchorEl(null);
	}, [setAnchorEl]);

	const resetVals = React.useCallback((newValue: string) => {
		updateFilterPropsAndResetFilterToggle({
			procedureDate: newValue,
			from: '',
			to: '',
		});
	}, [updateFilterPropsAndResetFilterToggle]);

	const handleOptionChange = (newValue: { label: string; value: string }[]) => {
		setValue(newValue);
		if (newValue[0].value == 'custom') {
			openPopover();
		}
		else {
			resetVals(newValue[0].value);

			updateFilterPropsAndResetFilterToggle({
				procedureDate: newValue[0].value,
				from: '',
				to: ''
			});
		}
	};

	const handleApplyDateRange = React.useCallback((selectionRange: Range) => {
		updateFilterPropsAndResetFilterToggle({
			procedureDate: 'custom',
			from: selectionRange.startDate && format(selectionRange.startDate, 'MM/dd/yyyy'),
			to: selectionRange.endDate && format(selectionRange.endDate, 'MM/dd/yyyy'),
		});
		closePopover();
	}, [closePopover, updateFilterPropsAndResetFilterToggle]);

	const [customStartDate, setCustomStartDate] = React.useState(from);
	const [customEndDate, setCustomEndDate] = React.useState(to);

	React.useEffect(() => {
		setCustomStartDate(from);
		setCustomEndDate(to);
	}, [from, to]);

	const handleStartDateChange = React.useCallback((date: Date | undefined) => {
		date && setCustomStartDate(date?.toString());
	}, []);

	const handleEndDateChange = React.useCallback((date: Date | undefined) => {
		date && setCustomEndDate(date?.toString());
	}, []);

	return (
		<div data-field="TIMEFRAME" className={classes.dateFilterDropdowmn} ref={filterRef}>
			<Multiselect single value={value} onChange={handleOptionChange} label="Procedure Date" options={PROCEDURE_DATE_OPTIONS} />
			<Box data-field="PERIOD_PICKER">
				<DatePeriodPicker
					startDate={customStartDate}
					endDate={customEndDate}
					defaultStartDate={defaultCustomStartDate}
					defaultEndDate={defaultCustomEndDate}
					onStartChange={handleStartDateChange}
					onEndChange={handleEndDateChange}
					anchor={anchorEl}
					onClose={closePopover}
					onApplyChange={handleApplyDateRange}
				/>
			</Box>
		</div>
	);
}
