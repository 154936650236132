import {
	SAVED_CONFIRM_ID,
	SAVED_CONFIRM_NUMBER_ERROR, NAVIGATE_NEXT_CASE_QUEUE,
} from '@store/actions/types';
import errorToString from '@utilities/errorToString';
import {
	ApiActionRequest,
	CaseTrackerThunkAction,
	CaseTrackerThunkDispatch
} from '@interfaces/ApiAction/ApiAction';
import { apiAction } from '@store/middlewares/api/ApiActionCreators';
import { HospitalUnscheduledAlertActionTypes } from '@interfaces/HospitalUnscheduledAlertStoreInterface';
import {
	ENDPOINT_REQUEST_CONFIRM,
} from '@utilities/apiConstants';
import { CaseQueueAction } from '@interfaces/CaseQueue/CaseQueueAction';
import endpointTemplateToUrl from '@utilities/endpointTemplateToUrl';
import { AxiosError, AxiosResponse } from 'axios';
import filterEmptyValues from '@utilities/filterEmptyValues';
import shouldDisableErrorHandlerByStatusNums
	from '@utilities/shouldDisableErrorHandlerByStatusNums/shouldDisableErrorHandlerByStatusNums';
import { ErrorStatusState } from '@interfaces/ErrorStatus/ErrorStatusState';
import { SurgeryRequestGet } from '@interfaces/SurgeryRequest/SurgeryRequestGet';

function navigateNextCaseQueue(): CaseQueueAction {
	return {
		type: NAVIGATE_NEXT_CASE_QUEUE,
	};
}

function setConfirmError(error: unknown): HospitalUnscheduledAlertActionTypes {
	return {
		type: SAVED_CONFIRM_NUMBER_ERROR,
		error: errorToString(error)
	};
}

export function confirmIdSave (id: string, confirmationNumber: string, handleResponse?: (resp?: AxiosResponse<unknown>) => void, forceConfirm?: boolean, optionalFin?: string): ApiActionRequest<unknown, ErrorStatusState, SurgeryRequestGet> {
	const onSuccess = (response: AxiosResponse): CaseTrackerThunkAction => {
		return (dispatch: CaseTrackerThunkDispatch) => {
			dispatch(navigateNextCaseQueue());
			if (handleResponse) {
				handleResponse(response);
			}
		};
	};

	const onFailure = (error: AxiosError): CaseTrackerThunkAction => {
		return (dispatch: CaseTrackerThunkDispatch) => {
			dispatch(setConfirmError(error));
			if (handleResponse) {
				handleResponse(error?.response);
			}
		};
	};
	const params = filterEmptyValues({ force: forceConfirm, fin: optionalFin });

	return apiAction<unknown, ErrorStatusState, SurgeryRequestGet>({
		url: endpointTemplateToUrl(ENDPOINT_REQUEST_CONFIRM, { requestId: id, confirmId: confirmationNumber }),
		method: 'POST',
		label: SAVED_CONFIRM_ID,
		includeAccessToken: true,
		shouldDisableErrorHandler: shouldDisableErrorHandlerByStatusNums([404, 409]),
		onSuccess: onSuccess,
		onFailure: onFailure,
		data: params,
		asDataOrParams: 'params',
	});
}
