import { OutlinedInput } from '@material-ui/core';
import { format } from 'date-fns';
import { StandardInputWrapper } from '@components/StatefulInputs/Base/StandardInputWrapper/StandardInputWrapper';
import React from 'react';
import { FieldValues } from 'react-hook-form';
import { StaticTextInputProps } from '@components/StatefulInputs/StatefulDateRangePicker/StaticTextInput/StaticTextInputProps';

export function StaticTextInput<FormType extends FieldValues>({
	name,
	label,
	field,
	textInputProps,
}: StaticTextInputProps<FormType>) {
	return (
		<StandardInputWrapper<FormType>
			name={name}
			labelProps={{
				id: `dateRangeTextInput-label-${name}`,
				htmlFor: `dateRangeTextInput-field-${name}`,
			}}
			label={label}
			styleAsRequired
			dataField="staticTextInput"
		>
			<OutlinedInput
				{...field}
				{...textInputProps}
				name={name}
				id={`dateRangeTextInput-field-${name}`}
				value={field.value ? format(field.value, 'MM/dd/yyyy') : ''}
				type="button"
			/>
		</StandardInputWrapper>
	);
}
