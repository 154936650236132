import React from 'react';
import { useFormContext } from 'react-hook-form';
import CommonRequestForm from '@interfaces/RequestForm/forms/CommonRequestForm';
import { AppointmentType } from '@data/request/AppointmentType';
import ComposedDocumentSections
	from '@components/RequestForm/composedSections/ComposedDocumentSections/ComposedDocumentSections';
import ComposedWebSections from '@components/RequestForm/composedSections/ComposedWebSections/ComposedWebSections';
import ComposedLegacySections
	from '@components/RequestForm/composedSections/ComposedLegacySections/ComposedLegacySections';

const appointmentTypeToComposedSections: Record<AppointmentType, React.FC> = {
	[AppointmentType.WEB]: ComposedWebSections,
	[AppointmentType.LEGACY_WEB]: ComposedLegacySections,
	[AppointmentType.DOCUMENT]: ComposedDocumentSections,
};

const ComposedSections: React.FC = () => {
	const { watch } = useFormContext<CommonRequestForm>();
	const [ appointmentType ] = watch(['appointmentType']);

	const Component = appointmentType && appointmentTypeToComposedSections[appointmentType] || (() => null);

	return <Component />;
};

export default ComposedSections;
