import { EventConfigDescriptor } from '@calendar/types/EventConfigDescriptor';
import OfficeEventType from '@components/OfficeCalendarView/OfficeEventType';
import UnavailableEventBlock from '@components/OfficeCalendarView/UnavailableEventBlock/UnavailableEventBlock';
import HoldEventCard from '@components/OfficeCalendarView/HoldEventCard/HoldEventCard';

export const OfficeCalendarEventConfig: Record<OfficeEventType, Pick<EventConfigDescriptor, 'legendLabel' | 'cardComponent'>> = {
	AVAILABLE: {
		legendLabel: 'OR likely available',
	},
	UNAVAILABLE: {
		legendLabel: 'OR unavailable',
		cardComponent: UnavailableEventBlock,
	},
	SOFT_BLOCK: {
		legendLabel: '',
		cardComponent: HoldEventCard,
	},
};
