import React from 'react';
import { PRIMARY_PROCEDURE_CHANGED_MODAL } from '@components/Modal/modalConstants';
import {
	useProcedureNameContext
} from '@components/RequestForm/ProcedureName/ProcedureNameContext';
import { useFormContext } from 'react-hook-form';
import { WebRequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import { useModalContext } from '@components/Modal/ModalContextProvider';

/**
 * `useChangeCustomProcedureName` is a custom hook that handles the change of the custom procedure name.
 *
 * @returns A callback function that takes a string value as an argument.
 * This function performs the following:
 * - Checks if a primary procedure is already selected and if the new value is different.
 * - If the conditions are met, it sets the previous procedure name and opens a modal.
 * - Regardless of the conditions, it sets the selected procedure name.
 *
 * @example
 * const changeCustomProcedureName = useChangeCustomProcedureName();
 * changeCustomProcedureName('New Procedure Name');
 */

export const useChangeCustomProcedureName = () => {
	const {
		selectedProcedure,
	} = useProcedureNameContext();
	const { getValues, setValue } = useFormContext<WebRequestFormValidatedState>();
	const { openModal } = useModalContext();

	return React.useCallback((value: string) => {
		const { surgeryProcedures } = getValues();
		if (
			surgeryProcedures?.some(proc => proc.isPrimaryProcedure) &&
			value !== selectedProcedure?.procedureName
		) {
			openModal(PRIMARY_PROCEDURE_CHANGED_MODAL.id);
		}

		setValue('procedureName', value);
	}, [getValues, openModal, selectedProcedure?.procedureName, setValue]);
};
