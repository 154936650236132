import React, { ChangeEvent, ClipboardEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { PatientDetailsValidationSchema } from '@components/RequestForm/PatientDetails/PatientDetailsValidationSchema';
import { StatefulTextInput } from '@components/StatefulInputs/StatefulTextInput/StatefulTextInput';
import { validators } from '@utilities/Validation/validators';
import { RequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import {
	useCheckAddressValidationRequired
} from '@components/RequestForm/utilities/hooks/useCheckAddressValidationRequired/useCheckAddressValidationRequired';
import { INVALID_ZIP_CODE } from '@utilities/Validation/ValidationMessages';

const FIELD_NAME_ZIP_CODE = 'patientZipCode';

export const ZipCode = () => {
	const {
		watch,
		setError,
		setValue,
		trigger
	} = useFormContext<RequestFormValidatedState>();
	const patientZipCode = watch(FIELD_NAME_ZIP_CODE);
	const { shouldFieldsBeRequired } = useCheckAddressValidationRequired();

	const handleZipCodeChangeEvent = React.useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const value = event.target.value.trim();
		setValue(FIELD_NAME_ZIP_CODE, validators.numericValidator(value) === null ? value : patientZipCode || '');
	}, [patientZipCode, setValue]);

	const handleZipCodePasteEvent = React.useCallback((event: ClipboardEvent<HTMLInputElement>) => {
		event.preventDefault();
		const pasteValue = event.clipboardData ? event.clipboardData.getData('text').trim() : '';
		const isError = validators.zipCodeValidator(pasteValue) !== null;
		if (isError) {
			setValue(FIELD_NAME_ZIP_CODE, '');
			setError(FIELD_NAME_ZIP_CODE, { message: INVALID_ZIP_CODE });
			return;
		}
		setValue(FIELD_NAME_ZIP_CODE, pasteValue);
		void trigger(FIELD_NAME_ZIP_CODE);
	}, [setError, setValue, trigger]);

	return (
		<StatefulTextInput
			name={FIELD_NAME_ZIP_CODE}
			label="ZIP"
			styleAsRequired={shouldFieldsBeRequired}
			controllerOptions={{
				rules: {
					...PatientDetailsValidationSchema[FIELD_NAME_ZIP_CODE],
					required: shouldFieldsBeRequired ? PatientDetailsValidationSchema[FIELD_NAME_ZIP_CODE].required : false,
				},
				defaultValue: patientZipCode,
			}}
			textInputProps={{
				onChange: handleZipCodeChangeEvent,
				onPaste: handleZipCodePasteEvent,
				inputProps: {
					minLength: 5,
					maxLength: 5,
				},
			}}
			dataField="requestForm-patientDetails-zipCodeTextInput"
		/>
	);
};
