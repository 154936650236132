import { StartEndEvent } from '@calendar/types/';
import getDateFromApiDateTime from '@calendar/utilities/getDateFromApiDateTime/getDateFromApiDateTime';

const dateDiff = (a: string | Date | number, b: string | Date | number) =>
	// negative - A sorts before B, positive - A sorts after B, 0 - equal
	new Date(a).getTime() - new Date(b).getTime();

const sortEventsComparator = ({ start: aStart, end: aEnd }: StartEndEvent, { start: bStart, end: bEnd }: StartEndEvent) => {
	// when startDifference is 0, equal, then use end. May be 0 if equivalent as well.
	return dateDiff(aStart, bStart) || dateDiff(aEnd, bEnd);
};

/***
 * combineConsecutiveEvents - takes unordered list of objects with start/end dates (string | number | Date)
 *  and returns a list of start/end dates (Date) after combining any adjacent and overlapping events
 *
 * @param events {StartEndEvent[]} Unordered list of objects with both start and end properties. Can be EventInfo or other
 *  objects with those properties.
 * @param mergeGapMinutes {number: 0} Merge events that have a gap that's equal to or less than this number of minutes
 *
 * @returns {StartEndEvent<Date>[]} Ordered list of objects with start/end dates (Date) after combining any adjacent
 *  and overlapping events. Note that even if the provided type has additional properties, those properties will not be included in the result
 *
 * @example
 * const events = [
 *  {
 *      start: '2021-05-04T11:00:00Z',
 *      end: '2021-05-04T13:00:00Z',
 *      id: 'LUNCH',
 *  },
 *  {
 *      start: '2021-05-04T10:00:00Z',
 *      end: '2021-05-04T11:00:00Z',
 *      id: 'MORNING',
 *  },
 *  {
 *      start: '2021-05-04T10:30:00Z',
 *      end: '2021-05-04T11:00:00Z',
 *      id: 'MID_MORNING',
 *  },
 *  {
 *      start: '2021-05-04T13:00:00Z',
 *      end: '2021-05-04T15:00:00Z',
 *      id: 'AFTERNOON',
 *  },
 *  {
 *      start: '2021-05-04T07:00:00Z',
 *      end: '2021-05-04T08:00:00Z',
 *      id: 'EARLY',
 *  }
 * ];
 * combineConsecutiveEvents(events);
 * // result...
 * [
 *  {
 *      start: new Date('2021-05-04T07:00:00Z'),
 *      end: new Date('2021-05-04T08:00:00Z')
 *  },
 *  {
 *      start: new Date('2021-05-04T10:00:00Z'),
 *      end: new Date('2021-05-04T15:00:00Z')
 *  }
 * ];
 */
export default function combineConsecutiveEvents(events: StartEndEvent[], mergeGapMinutes = 0): StartEndEvent<Date>[] {
	if (events.length === 0) { return []; }

	// sort events
	events = events.sort(sortEventsComparator);

	// initialize start/end to first event start/end
	let mergedStart: Date = getDateFromApiDateTime(events[0].start);
	let mergedEnd: Date = getDateFromApiDateTime(events[0].end);

	const mergedEvs: StartEndEvent<Date>[] = [];

	for (let i = 0; i < events.length; i++) {
		const evStart = getDateFromApiDateTime(events[i].start);
		const evEnd = getDateFromApiDateTime(events[i].end);

		// Start of this event is less than current end
		if (evStart.getTime() <= (mergedEnd.getTime() + mergeGapMinutes * 60 * 1000)) {
			if (evEnd > mergedEnd) {
				mergedEnd = evEnd;
			} // else event is equivalent or is contained within existing events so do nothing with this start/end.
		} else { // event does not overlap
			// so add the previous start/end to the list of merged events
			mergedEvs.push({ start: mergedStart, end: mergedEnd });
			//  set merged start/end to current events start/end
			mergedStart = evStart;
			mergedEnd = evEnd;
		}
	}

	// Save last event
	mergedEvs.push({ start: mergedStart, end: mergedEnd });

	return mergedEvs;
}
