import { Box, createStyles } from '@material-ui/core';
import BoxFancyScroll from '@components/BoxFancyScroll/BoxFancyScroll';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ScrollIndicatorGradient from '@components/BoxScrollHorizontal/ScrollIndicatorGradient/ScrollIndicatorGradient';
import RightLeftDirectionEnum from '@interfaces/RightLeftDirectionEnum';
import BoxScrollHorizontalButton
	from '@components/BoxScrollHorizontal/BoxScrollHorizontalButton/BoxScrollHorizontalButton';
import BoxScrollHorizontalProps from '@components/BoxScrollHorizontal/BoxScrollHorizontalProps';
import BoxScrollHorizontalContextType from '@components/BoxScrollHorizontal/BoxScrollHorizontalContextType';
import useScrollContainerObserver from '@components/BoxScrollHorizontal/utilities/useScrollContainerObserver/useScrollContainerObserver';
import classNames from 'classnames';
import { GRADIENT_WIDTH } from '@theme/themeConstants';

const useStyles = makeStyles(theme => createStyles({
	main: {
		display: 'flex',
		flex: 'auto 1 1',
		position: 'relative',
		maxWidth: '100%',
	},
	root: {
		display: 'flex',
		flex: 'auto 1 1',
		gap: theme.spacing(2),
		alignItems: 'stretch',
		justifyContent: 'flex-start',
		margin: theme.spacing(0, 1),
		overflowX: 'auto',
		marginTop: theme.spacing(0),
		paddingBottom: theme.spacing(4), // Space above scroll bar
		scrollPaddingInlineStart: `${GRADIENT_WIDTH}px`,
		scrollSnapType: 'x',
	},
}));

const BoxScrollHorizontalContext = React.createContext<BoxScrollHorizontalContextType | null>(null);

export const useBoxScrollHorizontalContext = () => {
	const ctx = React.useContext(BoxScrollHorizontalContext);

	if (!ctx) {
		throw new Error('useBoxScrollHorizontalContext should not be used directly. Use <BoxScrollHorizontal> with <BoxScrollItem> components instead.');
	}

	return ctx;
};

export const useBoxScrollHorizontalContextDirection = (direction: RightLeftDirectionEnum) => {
	const { doScroll, contentHiddenByDirection: { [direction]: hasHiddenContent } } = useBoxScrollHorizontalContext();

	const doScrollDirection = React.useCallback(() => doScroll(direction), [direction, doScroll]);

	return {
		doScrollDirection,
		hasHiddenContent,
	};
};

const BoxScrollHorizontal: React.FC<BoxScrollHorizontalProps> = ({ children, columnScrollAmount, ...props }) => {
	const classes = useStyles();

	const {
		setupScrollContainerObs,
		contentHiddenByDirection,
		observeItem,
		doScroll,
	} = useScrollContainerObserver(columnScrollAmount);

	return (
		<BoxScrollHorizontalContext.Provider value={{
			doScroll,
			observeItem,
			contentHiddenByDirection,
		}}>
			<Box {...props} className={classNames(classes.main, props.className)}>
				<BoxScrollHorizontalButton direction={RightLeftDirectionEnum.LEFT} />
				<ScrollIndicatorGradient direction={RightLeftDirectionEnum.LEFT} />
				<BoxFancyScroll className={classes.root} ref={setupScrollContainerObs}>
					{children}
				</BoxFancyScroll>
				<ScrollIndicatorGradient direction={RightLeftDirectionEnum.RIGHT}/>
				<BoxScrollHorizontalButton direction={RightLeftDirectionEnum.RIGHT} />
			</Box>
		</BoxScrollHorizontalContext.Provider>
	);
};

export default BoxScrollHorizontal;
