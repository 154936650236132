import React from 'react';
import PatientNamePartInputProps from '@components/RequestForm/inputs/PatientNamePartInput/PatientNamePartInputProps';
import { useMultiFormValueSetter } from '@components/RequestForm/utilities/MultiFormAdapter/MultiFormAdapter';
import { useFieldFormContext } from '@utilities/hooks/useFieldFormContext/useFieldFormContext';
import { PatientDetailsValidationSchema } from '@components/RequestForm/PatientDetails/PatientDetailsValidationSchema';
import CommonRequestForm from '@interfaces/RequestForm/forms/CommonRequestForm';
import TextFieldInput from '@components/TextFieldInput/TextFieldInput';

const PatientNamePartInput: React.FC<PatientNamePartInputProps> = ({ namePart, label, required = false }) => {
	const fieldName = `patient${namePart}Name` as keyof CommonRequestForm;

	const setValue = useMultiFormValueSetter(fieldName);
	const {
		currentValue,
		error,
		triggerSelfValidation,
	} = useFieldFormContext<string>(fieldName, {
		validationRules: PatientDetailsValidationSchema[fieldName],
	});

	return <TextFieldInput
		placeholder={namePart}
		id={fieldName}
		name={fieldName}
		fieldName={fieldName}
		required={required}
		value={currentValue}
		errorMessage={error}
		onBlur={triggerSelfValidation}
		onChange={setValue}
		label={label}
	/>;
};

export default PatientNamePartInput;
