import React, { RefObject, useCallback, useState } from 'react';
import {
	Box,
	Flex,
	TextLink,
} from '@ascension/web';
import { TabsProps } from './TabsProps';

export function Tabs({ tabDescriptions, topSectionOffset, children, ...rest }: TabsProps) {
	const [selectedTabValue, setSelectedTabValue] = useState('appointment');

	const handleTabClick = useCallback((value: string, ref: RefObject<HTMLDivElement>) => {
		if (!ref || !ref.current) {
			return;
		}
		setSelectedTabValue(value);
		const clickedRefOffset = ref.current.offsetTop;
		window.scrollTo(0, clickedRefOffset + topSectionOffset);
	}, [ setSelectedTabValue, topSectionOffset ]);

	return (
		<Box
			width="calc(100% + 40px)"
			px="20px"
			left="-20px"
			backgroundColor="#eff0f4"
			position="relative"
		>
			<Box
				position="relative"
				{...rest}
				borderBottom="2px solid #C9C9C9"
			>
				<Flex
					flexDirection="row"
				>
					{
						tabDescriptions
							.map((description) => !description.label ? null : (
								<Box
									key={description.value}
									id={`${description.value}-tab`}
								>
									<Box
										className={'tab-text' + (selectedTabValue === description.value ? ' selected' : '')}
										px="24px"
									>
										<TextLink
											onClick={() => handleTabClick && handleTabClick(description.value, description.ref)}
										>
											{description.label}
										</TextLink>
									</Box>
								</Box>
							))}
					<Box marginLeft="auto">
						{children}
					</Box>
				</Flex>
			</Box>
		</Box>
	);
}
