import { CLEAR_ALL_CACHE, REPLACE_ENDPOINT_CACHE, SINGLE_APPEND_OR_REPLACE_CACHE } from './types';
import { ApiCacheAction } from '../../interfaces/ApiCache/ApiCacheAction';

export const saveDataToCache = <DataType>(endpoint: string, data: DataType[]): ApiCacheAction<DataType> => {
	return {
		type: REPLACE_ENDPOINT_CACHE,
		payload: { endpoint, data }
	};
};

export const addUpdateElementToCache = <DataType>(endpoint: string, data: DataType, keyProp = 'id'): ApiCacheAction<DataType> => {
	return {
		type: SINGLE_APPEND_OR_REPLACE_CACHE,
		payload: { endpoint, data, keyProp }
	};
};

export const clearAllCache = (): ApiCacheAction<void> => {
	return {
		type: CLEAR_ALL_CACHE,
	};
};
