import { Cancel, Canceler } from 'axios';
import { API_CANCELER_ADD } from './types';
import { ApiStatusAddAction } from '../../interfaces/ApiStatus/ApiStatusAction';

export const addApiCanceler = (endpoint: string, canceler: Canceler, cancelPromise: Promise<Cancel>): ApiStatusAddAction => {
	return {
		type: API_CANCELER_ADD,
		payload: {
			endpoint,
			canceler,
			cancelPromise,
		},
	};
};
