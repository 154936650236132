export interface DeepObjectEqualOptions {
	excludeKeys: string[];
	treatMissingPropsAsUndefined: boolean;
}
const defaultDeepObjectEqualOptions: DeepObjectEqualOptions = {
	excludeKeys: [],
	treatMissingPropsAsUndefined: false,
};

function isStringable(val: unknown): val is string {
	return !!(val && typeof val === 'string' || typeof val === 'object');
}

const asString = (val: unknown) => {
	return isStringable(val) ? val?.toString?.() : val;
};

/***
 * Do not use with potentially circular objects.
 * Keep in mind properties that use functions that do not use the same reference are considered different.
 * See the functions described in the tests for examples.
 *
 * @param {T} obj1
 * @param {T} obj2
 * @param {Partial<DeepObjectEqualOptions>} options
 * {string[]} excludeKeys - list of properties to exclude from check
 * {boolean} treatMissingPropsAsUndefined - missing properties are equivalent to undefined
 */
export function deepObjectEquality<T extends object = object>(obj1: T, obj2: T, options: Partial<DeepObjectEqualOptions> = defaultDeepObjectEqualOptions): boolean {
	const {
		excludeKeys,
		treatMissingPropsAsUndefined,
	} = {
		...defaultDeepObjectEqualOptions,
		...options,
	};

	const getKeys = (sourceObject: T) => {
		return Object.keys(sourceObject)
			.filter((k: string) => {
				// Remove excluded keys
				if (excludeKeys.includes(k)) {
					return false;
				}

				if (treatMissingPropsAsUndefined) {
					// Remove undefined values since it's the same as prop not being included
					return sourceObject[k as keyof T] !== undefined;
				}
				return true;
			})
			// Sort to allow string comparison of key array
			.sort() as Array<keyof T>;
	};

	const keys1 = getKeys(obj1);
	const keys2 = getKeys(obj2);

	// Filtered and sorted keys don't match so return FALSE
	if (keys1.length !== keys2.length || keys1.toString() !== keys2.toString()) {
		return false;
	}

	const isMismatchValPresent = (sourceObject: T, checkIsMatchingObject: T) => (keyOfSourceObject: keyof T) => {
		const val1 = sourceObject[keyOfSourceObject];
		const val2 = checkIsMatchingObject[keyOfSourceObject];

		const areObjects =
			val1 && Object.prototype.toString.call(val1) === '[object Object]' &&
			val2 && Object.prototype.toString.call(val2) === '[object Object]';

		return areObjects ? !deepObjectEquality(val1, val2) : asString(val1) !== asString(val2);
	};

	return !keys1.some(isMismatchValPresent(obj1, obj2)) &&
		!keys2.some(isMismatchValPresent(obj2, obj1));
}
