import React from 'react';
import AddableSectionProps from './AddableSectionProps';
import {
	Box,
	Button,
	Flex,
	Text,
	TextLink,
} from '@ascension/web';

const AddableSection = ({
	show,
	addButtonText = 'Add',
	removeLinkText = 'Remove',
	title,
	onUpdateShow,
	children,
	...props
}: AddableSectionProps) => {
	const handleRemoveClick = () => typeof onUpdateShow === 'function' && onUpdateShow(false);
	const handleAddClick = () => typeof onUpdateShow === 'function' && onUpdateShow(true);

	return show ?
		<Box {...props}>
			<Flex justifyContent="space-between">
				<Text fontSize={18} fontWeight={500} display="block">
					{title}
				</Text>
				<Box><TextLink data-field="Addable_RemoveLink" onClick={handleRemoveClick}>{removeLinkText}</TextLink></Box>
			</Flex>
			<Box>
				{ children }
			</Box>
		</Box>
		: <Button onClick={handleAddClick}>{addButtonText}</Button>;
};

export default AddableSection;
