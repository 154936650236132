import React from 'react';
import useSurgeonsSelection from '@utilities/hooks/useSurgeonsSelection/useSurgeonsSelection';
import useSessionStorageCache from '@utilities/hooks/useSessionStorageCache/useSessionStorageCache';
import { StorageKey } from '@utilities/authConstants';

const useSurgeonsSelectionByNpis = () => {
	const { prefSurgeons, selectedSurgeons, setSelectedSurgeons } = useSurgeonsSelection();
	const {
		storedValue: storedSurgeons,
		setStoredValue: setStoredSurgeons,
	} = useSessionStorageCache(StorageKey.BLOCK_SURGEONS);

	const setSelectedSurgeonsByNpis = React.useCallback((newSelectedSurgeonsByNpis: number[] | ((currentlySelected: number[]) => number[])) => {
		setSelectedSurgeons(selected => {
			const updatedList = typeof newSelectedSurgeonsByNpis === 'function' ?
				newSelectedSurgeonsByNpis(selected?.map(s => s.npi)) : newSelectedSurgeonsByNpis;
			const filteredList = prefSurgeons.filter(s => updatedList.includes(s.npi));
			setStoredSurgeons(filteredList.map(s => s.npi).join(', '));
			return filteredList;
		});
	}, [prefSurgeons, setSelectedSurgeons, setStoredSurgeons]);

	const selectedSurgeonsByNpis = React.useMemo(() => {
		return selectedSurgeons.map(s => s.npi);
	}, [selectedSurgeons]);

	React.useEffect(() => {
		setSelectedSurgeonsByNpis(currentSurgeons => currentSurgeons.length ?
			currentSurgeons :
			(storedSurgeons ?
				storedSurgeons.split(', ').map(Number) :
				prefSurgeons.map(s => s.npi))
		);
	}, [prefSurgeons, setSelectedSurgeonsByNpis, storedSurgeons]);

	return {
		prefSurgeons,
		selectedSurgeonsByNpis,
		setSelectedSurgeonsByNpis,
	};
};

export default useSurgeonsSelectionByNpis;
