import React from 'react';
import { useSchedulePageContext } from '@components/SchedulePage/SchedulePageContext/SchedulePageContext';
import { DailySurgeonCalendarEmpty } from './DailySurgeonCalendarEmpty';
import DailySurgeonCalendarFilled from './DailySurgeonCalendarFilled';
import { useFindATimeContext } from '../FindATimeContext/FindATimeContextProvider';

export const DailySurgeonCalendarSection: React.FC = () => {
	const { schedules } = useSchedulePageContext();
	const { selectedTimeSlot } = useFindATimeContext();

	const showFilledCalendar = schedules.length > 0 && selectedTimeSlot !== '';

	return (
		showFilledCalendar ?
			<DailySurgeonCalendarFilled key={schedules[0].id} blockInfo={schedules[0]}/>
			:
			<DailySurgeonCalendarEmpty />
	);
};
