import {
	Box, Flex, Text, TextLink
} from '@ascension/web';
import React, { useCallback } from 'react';
import { DEFAULT_PAGE, DEFAULT_SIZE } from '../../utilities/apiConstants';
import { ListHeaderComponentProps } from './ListHeaderComponentProps';
import { makeStyles } from '@material-ui/core/styles';
import { useFilterState, useResetAllFilters } from '../Filters/FiltersContext/FiltersContext';
import { useSelector } from 'react-redux';
import { savedFiltersToApiParams } from '../../utilities/filtersUtilities';
import { MyFiltersSelector } from '../../store/selectors/MyFiltersSelector';
import { UserProfileFilterUrlSelector, UserProfileHasARoleSelector } from '../../store/selectors/UserProfileSelector';
import { OfficeRoles } from '../../interfaces/UserProfile/UserRoleEnum';
import { MyFiltersState } from '../../interfaces/MyFilters/MyFiltersState';
import useSurgeonsFilterUpdate from '@utilities/hooks/useSurgeonsFilterUpdate/useSurgeonsFilterUpdate';

const useStyles = makeStyles({
	lowercase: {
		textTransform: 'lowercase',
	},
});

export default function ListHeaderComponent ({
	children,
	className,
}: ListHeaderComponentProps) {
	const {
		disableDefaultFilters,
		page,
		size,
		totalCount,
		entityName,
	} = useFilterState();

	const savedFilters = useSelector(MyFiltersSelector);
	const resetFilters = useResetAllFilters();
	const { filterApplied, toggleFilter, filterSelectionsEmpty } = useFilterState();
	const isOfficeRole = useSelector(UserProfileHasARoleSelector(OfficeRoles));
	const savedFilterUrl = useSelector(UserProfileFilterUrlSelector);
	const { updateSurgeonsWithIds } = useSurgeonsFilterUpdate();

	const handleResetFilters = useCallback(() => {
		const modifiedSavedFilters = disableDefaultFilters ? {} : savedFilters;
		const savedFiltersByRole: MyFiltersState = isOfficeRole ?
			{ ...modifiedSavedFilters, surgeon: [] } :
			{ ...modifiedSavedFilters, unit: [] };
		updateSurgeonsWithIds([]);
		!!savedFilterUrl && toggleFilter(true);
		resetFilters(savedFiltersToApiParams(savedFiltersByRole));
	}, [disableDefaultFilters, isOfficeRole, resetFilters, savedFilterUrl, savedFilters, toggleFilter, updateSurgeonsWithIds]);
	const startNumber= page || DEFAULT_PAGE;
	const defaultPageSize = size || DEFAULT_SIZE;
	const total = Number(totalCount) || 0;
	const displayStart = ((startNumber-1)*defaultPageSize)+1;
	const displaySize = total < (defaultPageSize*(startNumber)) ? total : size * startNumber;
	const { lowercase }  = useStyles();
	const hideClearFilters = filterApplied || filterSelectionsEmpty;

	return (
		<Flex justifyContent="center" data-field="ListHeaderComponent" className={className}>
			<Box
				mt={10}
				flexDirection="column"
				width={1}
				elevated>
				<Flex width={1} alignItems="start">
					<Flex flexDirection="row" data-field="Data_Information">
						<Flex flexDirection="column" width={180}  >
							<Text display="block">Showing <strong>{displayStart} - {displaySize}</strong> of <strong>{total}</strong> <Text className={lowercase}>{entityName}</Text></Text>
						</Flex>
						<Flex flexDirection="column">
							{
								!hideClearFilters &&
								<TextLink mt={1} onMouseDown={handleResetFilters} data-field="resetFilters">
									CLEAR FILTERS
								</TextLink>
							}
						</Flex>
					</Flex>
					<Box flex="1" textAlign="right">
						{children}
					</Box>
				</Flex>
			</Box>
		</Flex>
	);
}
