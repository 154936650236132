import { Theme, useTheme } from '@material-ui/core';
import { BlockPageCaseType } from '@components/SchedulePage/types/BlockPageConfigType';
import { ScheduleEventCardData } from '@data/schedules/types/ScheduleEventCardData';

interface EventConfig {
	cardStyles: React.CSSProperties;
}

type EventConfigMap = {
	[key in BlockPageCaseType]: EventConfig;
};

const getEventConfig = (theme: Theme): EventConfigMap => ({
	SCHEDULED: {
		cardStyles: {
			backgroundColor: theme.palette.success[50],
			borderColor: theme.palette.success.main,
			borderStyle: 'solid',
		},
	},
	UNSCHEDULED: {
		cardStyles: {
			backgroundColor: theme.palette.warning[50],
			borderColor: theme.palette.warning.main,
			borderStyle: 'dashed',
		},
	},
});

export const useBlockEventConfig = (eventInfo: ScheduleEventCardData) => {
	const theme = useTheme();
	const eventType = eventInfo.type as BlockPageCaseType;
	const eventConfig = getEventConfig(theme);

	return {
		cardStyles: eventConfig[eventType].cardStyles,
	};
};
