import React from 'react';
import { useForm, useFormContext, UseFormGetValues, UseFormReturn } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
	SurgeryProcedureInterface,
	SurgeryProcedureProps
} from '@components/RequestForm/SurgeryInformation/SurgeryInformationInterface';
import ProcedureForm from '@components/ProcedureForm/ProcedureForm';
import { getSurgeonListData } from '@store/actions/SurgeonListActionCreators';
import { PreferredSurgeonsSelector } from '@store/selectors/SurgeonListSelector';
import { ProcedureFormProvider } from '@components/ProcedureForm/ProcedureFormContext/ProcedureFormContext';
import useProcedureFormOptions from '@components/ProcedureForm/useProcedureFormOptions/useProcedureFormOptions';
import { ENDPOINT_SURGEONS } from '@utilities/apiConstants';
import { getFormattedName } from '@utilities/commonFunction';
import { RequestProcedureSchema } from '@utilities/Validation/validationSchema';
import useCollection from '@utilities/hooks/useCollection/useCollection';
import RequestFormPageState from '@components/RequestForm/RequestFormPage.types';
import { Surgeon } from '@data/surgeon/Surgeon';
import FormYesNoRadio from '@components/FormComponents/FormYesNoRadio';
import CommonRequestForm from '@interfaces/RequestForm/forms/CommonRequestForm';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { DropdownOption } from '@definitions/DropdownOption';

const useStyles = makeStyles((theme) => createStyles({
	procedureTitle: {
		display: 'flex',
		flexDirection: 'column',
		marginBottom: theme.spacing(2),
	},
	templateName: {
		fontWeight: 600,
	}
}));

const defaultRequestProcedureInfo: SurgeryProcedureInterface = {
	surgeon: { label: '', value: '' },
	cptCodes: { currentEntry: '', entriesList: [] },
	procedureName: '',
	modifierSide: {
		label: '',
		value: '',
	},
	modifierApproach: {
		label: '',
		value: '',
	},
	isPrimaryProcedure: null,
	anesthesiaType: [],
	implantsNeeded: null,
	otherEquipment: '',
	additionalComments: '',
	vendorContacted: null,
	vendorName: '',
	templateName: '',
	robotic: null
};

// Necessary for edit requests which are missing the label for the surgeon
const useUpdateSurgeonLabel = ({ getValues, setValue }: UseFormReturn<SurgeryProcedureInterface>) => {
	const surgeons = useCollection<Surgeon>(ENDPOINT_SURGEONS);
	const surgeonRef = React.useRef<DropdownOption>(getValues()['surgeon'] || {});

	React.useEffect(() => {
		const { label, value } = surgeonRef.current;
		if (label || !value) { return; }
		setValue('surgeon', { value: value, label: getFormattedName(surgeons.find((surgeon) => (surgeon.id === value), '')) }, { shouldValidate: false });
	}, [setValue, surgeons]);
};

const getPrimarySurgeon = (getValues: UseFormGetValues<CommonRequestForm>, state: RequestFormPageState, surgeons: Surgeon[]) => {
	const surgeonValue = getValues().primarySurgeon || state?.blockInfo?.surgeon.id || '';
	const surgeonLabel = getFormattedName(surgeons.find((surgeon) => (surgeon.id === surgeonValue), ''));
	return { value: surgeonValue, label: surgeonLabel };
};

const RequestSurgeryProcedureForm = ({ isOpenTimeEnabled, surgeryProcedure = defaultRequestProcedureInfo, setSurgeryProcedure, surgeryProcedureIndex, close, validate }: SurgeryProcedureProps) => {
	const classes = useStyles();
	const availableOptions = useProcedureFormOptions(PreferredSurgeonsSelector);
	const [blockModifiedOptions, setBlockModifiedOptions] = React.useState<typeof availableOptions>({});
	const { location: { state } } = useHistory<RequestFormPageState>();
	const surgeons = useCollection<Surgeon>(ENDPOINT_SURGEONS);

	const { getValues } = useFormContext<CommonRequestForm>();
	const formMethods = useForm<SurgeryProcedureInterface>({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		defaultValues: surgeryProcedure,
	});

	const primarySurgeon = React.useMemo(() => getPrimarySurgeon(getValues, state, surgeons), [getValues, state, surgeons]);

	React.useEffect(() => {
		const currentSurgeon = formMethods.getValues().surgeon;
		if (!currentSurgeon || currentSurgeon.value === '' || currentSurgeon.value === primarySurgeon.value) {
			formMethods.setValue('surgeon', primarySurgeon, { shouldValidate: false });
		}
	}, [formMethods, primarySurgeon]);

	React.useEffect(() => {
		if (state?.blockInfo) {
			const blockPreferredSurgeons = availableOptions.surgeon?.filter(s => state?.blockInfo?.surgeon.id === s.value);
			setBlockModifiedOptions({
				...availableOptions,
				surgeon: blockPreferredSurgeons,
			});
		}
	}, [availableOptions, state]);

	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(getSurgeonListData());
	}, [dispatch]);

	useUpdateSurgeonLabel(formMethods);

	const templateName = 'templateName' in surgeryProcedure ? surgeryProcedure.templateName : undefined;

	const handleSubmitValidated = React.useCallback((submittedVals: SurgeryProcedureInterface & { templateId?: string }) => {
		const procedureToAdd = { ...submittedVals };
		if ('templateId' in surgeryProcedure) {
			procedureToAdd.templateId = surgeryProcedure.templateId;
		}
		setSurgeryProcedure && setSurgeryProcedure((value: Array<SurgeryProcedureInterface> = []) => {
			const currentProcedureValue: number = surgeryProcedureIndex === undefined ? value.length : surgeryProcedureIndex;
			return [
				...value.slice(0, currentProcedureValue),
				procedureToAdd,
				...value.slice(currentProcedureValue + 1)
			];
		});
		close();
		validate && validate();
	}, [close, setSurgeryProcedure, surgeryProcedure, surgeryProcedureIndex, validate]);

	const handleChangeIsPrimary = React.useCallback((value: number) => {
		if (value === 1) {
			formMethods.setValue('surgeon', primarySurgeon, { shouldValidate: false });
			if (isOpenTimeEnabled) {
				const requestPrimaryProcedureName = getValues().procedureName;
				formMethods.setValue('procedureName', requestPrimaryProcedureName, { shouldValidate: false });
			}
		}
	}, [formMethods, getValues, isOpenTimeEnabled, primarySurgeon]);

	return (
		<ProcedureFormProvider
			onCancel={close}
			onSubmit={handleSubmitValidated}
			submitButtonText="Add"
			headerText="Add a Procedure"
		>
			<ProcedureForm isOpenTimeEnabled={!!isOpenTimeEnabled} schema={RequestProcedureSchema} options={state?.blockInfo ? blockModifiedOptions : availableOptions} {...formMethods} >
				<>
					{templateName ?
						<Box data-field="procedureForm-formHeader-templateName" className={classes.procedureTitle}>
							<Typography className={classes.templateName} variant="caption">TEMPLATE NAME</Typography>
							<Typography variant="h6">{templateName}</Typography>
						</Box> : undefined
					}
					<FormYesNoRadio
						name="isPrimaryProcedure"
						label="Primary Procedure?"
						onChangeExternal={handleChangeIsPrimary}
					/>
				</>
			</ProcedureForm>
		</ProcedureFormProvider>
	);
};

export default RequestSurgeryProcedureForm;
