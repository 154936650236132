import { AlertDisplayFieldProps } from './AlertDisplayFieldProps';
import { Flex, Text } from '@ascension/web';
import React from 'react';

export const AlertDisplayField = ({
	label,
	children,
	...rest
}: AlertDisplayFieldProps) => {
	return (
		<Flex flexDirection="column" mr="30px" {...rest}>
			{label &&
			<Text
				textTransform="uppercase"
				fontSize={12}
				color="#999"
				fontWeight={2}
			>
				{label}
			</Text>
			}
			<Text fontSize={14} mt={1}>
				{children}
			</Text>
		</Flex>
	);
};
