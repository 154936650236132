import React   from 'react';
import { ManageCaseOption } from '@components/CaseDetails/ManageCaseDropDown/ManageCaseOption';
import { useSelector } from 'react-redux';
import { CaseDetailsAppointmentSelector, IsCaseDetailsLockedSelector } from '@store/selectors/CaseDetailsSelector';
import { RenderAsProps } from '@components/CaseDetails/ManageCaseDropDown/DropDownOptions/DropDownOptionProps';
import { useModalContext } from '@components/Modal/ModalContextProvider';
import { CANCEL_MODAL } from '@components/Modal/modalConstants';

export default function CancelCaseOption({ closeDropdown }: RenderAsProps) {
	const { status } = useSelector(CaseDetailsAppointmentSelector);
	const lockStatus = useSelector(IsCaseDetailsLockedSelector);
	const isCancelledOrPending = status && ['CANCELLED', 'PENDING_CANCELLED'].includes(status);
	const disableOption = !!(lockStatus || isCancelledOrPending);
	const { openModal } = useModalContext();

	const handleOpenCancelModal = () => {
		openModal(CANCEL_MODAL);
		closeDropdown();
	};

	return (
		<ManageCaseOption onClick={handleOpenCancelModal} fieldName="cancelCase" disabled={disableOption}>
				Cancel
		</ManageCaseOption>
	);
}
