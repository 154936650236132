import React from 'react';
import { StatefulTextInput } from '@components/StatefulInputs/StatefulTextInput/StatefulTextInput';
import { useFormContext } from 'react-hook-form';
import { RequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import { useGetRequestProcedureDuration } from '@components/RequestForm/utilities/hooks/useGetRequestProcedureDuration/useGetRequestProcedureDuration';
import { useFindATimeContext } from '@components/FindATime/FindATimeContext/FindATimeContextProvider';
import useGetSelectedUnitInfo
	from '@components/RequestForm/utilities/hooks/useCheckBlockTimeOverlap/useGetSelectedUnitInfo/useGetSelectedUnitInfo';
import useGetPrimarySurgeonNpi
	from '@components/RequestForm/utilities/hooks/useCheckBlockTimeOverlap/useGetPrimarySurgeonNpi/useGetPrimarySurgeonNpi';
import {
	useDoesUnitSupportOpenTime
} from '@components/RequestForm/utilities/hooks/useDoesUnitSupportOpenTime/useDoesUnitSupportOpenTime';
import { OutlinedInputProps } from '@material-ui/core';
import { DurationValidation } from '@components/RequestForm/RequestFormValidation';

interface DurationProps  {
	label: string;
	autoUpdate?: boolean;
	inputProps?: OutlinedInputProps;
	dataField: string;
}

export const Duration = ({ autoUpdate, label, inputProps, dataField }:  DurationProps) => {
	const { formState, setValue, watch } = useFormContext<RequestFormValidatedState>();
	const { setDurationFallback } = useFindATimeContext();
	const selectedUnit = useGetSelectedUnitInfo();
	const primarySurgeonNpi = useGetPrimarySurgeonNpi();
	const hasOpenTime = useDoesUnitSupportOpenTime();
	const [procedureName] = watch(['procedureName']);

	const { hospital: hospitalId, id: unitId } = selectedUnit || {};

	const durationApi = useGetRequestProcedureDuration({
		hospitalId,
		unitId,
		procedure: procedureName,
		npi: primarySurgeonNpi,
	});

	const durationValue = React.useMemo(() => {
		// This is to set the durationValue to an empty string if the procedureName is also an empty string, fixes
		// the persistent duration problem when switching units
		if (formState.defaultValues?.duration && formState.defaultValues?.procedureName === procedureName) {
			return formState.defaultValues.duration;
		}
		return durationApi?.toString();
	}, [formState.defaultValues, procedureName, durationApi]);

	React.useEffect(() => {
		if (autoUpdate && hasOpenTime) {
			const durationStr = durationValue;
			setDurationFallback(durationStr);
			setValue('duration', durationStr, { shouldValidate: true });
		}
	}, [durationApi, setDurationFallback, setValue, autoUpdate, hasOpenTime, durationValue]);

	return (
		<StatefulTextInput
			controllerOptions={{
				rules: DurationValidation
			}}
			label={label}
			name="duration"
			textInputProps={{
				...inputProps,
			}}
			dataField={dataField}
		/>
	);
};
