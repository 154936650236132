import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import PdfViewer from '@components/PdfViewer/PdfViewer';
import { Box, Divider, Typography } from '@material-ui/core';
import { useAppPageBodyClassSetter } from '@components/AppPage/AppPageContext/AppPageContext';
import theme from '@theme/theme';
import { BackLink } from '@components/BackLink/BackLink';
import useOnAfterDocPrint from '@components/UnitCalendarPage/utilities/useOnAfterDocPrint';
import useGetDocumentObjectUrl from '@data/document/hooks/useGetDoumentObjectUrl/useGetDocumentObjectUrl';
import {
	useCaseDetailsPageContext
} from '@components/CaseDetails/CaseDetailsContext/CaseDetailsPageContext/CaseDetailsPageContext';
import DownloadDocument
	from '@components/CaseDetails/DocumentInformationDisplay/DocumentDetails/DocumentActions/DownloadDocument';
import DocumentDetails from '@components/CaseDetails/DocumentInformationDisplay/DocumentDetails/DocumentDetails';
import { useSelector } from 'react-redux';
import { UserProfileHasARoleSelector } from '@store/selectors/UserProfileSelector';
import { OfficeRoles } from '@interfaces/UserProfile/UserRoleEnum';
import IndexingSection from '@components/PdfViewerPage/IndexingSection/IndexingSection';
import IndexingSectionContextProvider
	from '@components/PdfViewerPage/IndexingSection/IndexingSectionContext/IndexingSectionContext';
import { IndexInUseMessage } from '@components/CaseDetails/CaseInUse/IndexInUseMessage/IndexInUseMessage';

const useStyles = makeStyles({
	bodyClass: {
		'& > div:first-child': {
			'maxWidth': 'unset',
			'margin': 'unset',
			'backgroundColor': theme.palette.common.white,
			'& > div': {
				height: 'calc(100vh - 68px)',
				paddingBottom: 0,
				display: 'flex',
			},
		},
	},
	pageWrapper: {
		display: 'grid',
		flex: 'auto 1 1',
		gridTemplateAreas: "'header header' 'index main'",
		gridTemplateRows: 'min-content 1fr',
		gridTemplateColumns: 'min-content 1fr',
	},
	header: {
		gridArea: 'header',
		paddingLeft: theme.spacing(5),
		paddingBottom: theme.spacing(4),
		paddingRight: theme.spacing(5),
		border: '1px solid rgba(0, 0, 0, .12)',
		backgroundColor: theme.palette.common.white,
	},
	message: {
		marginLeft: theme.spacing(3),
	},
	fullWidthDivider: {
		'&.MuiDivider-root': {
			margin: theme.spacing(2, -5, 1),
		},
	},
	pdfTitle: {
		paddingTop: theme.spacing(4)
	},
	indexContentContainer: {
		gridArea: 'index',
		width: '320px',
		backgroundColor: theme.palette.grey[100]
	},
	mainContainer: {
		gridArea: 'main',
		display: 'flex',
		flexDirection: 'column'
	},
	maxContainer: {
		flex: '1 0 1px',
		overflowY: 'auto',
	},
	titleRow: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'baseline',
	},
});

export const PdfViewerPage = () => {
	const { setBodyClass } = useAppPageBodyClassSetter('PdfViewerPage');
	const classes = useStyles();
	const {
		setSelectedAttachment,
		selectedAttachment,
	} = useCaseDetailsPageContext();
	const hasOfficeRole = useSelector(UserProfileHasARoleSelector(OfficeRoles));
	useOnAfterDocPrint();

	React.useEffect(() => {
		setBodyClass(classes.bodyClass);
	}, [classes.bodyClass, setBodyClass]);

	const docUrl = useGetDocumentObjectUrl(selectedAttachment?.id);

	const handleBackClick = React.useCallback(() => {
		setSelectedAttachment(undefined);
	}, [setSelectedAttachment]);

	if (!selectedAttachment) { return null; }

	const { id: attachmentId } = selectedAttachment;

	return (
		<Box className={classes.pageWrapper}>
			<Box className={classes.header}>
				<Box display="flex" flexDirection="row" alignItems="flex-end">
					<BackLink onClick={handleBackClick}/>
					<Box className={classes.message}>
						<IndexInUseMessage />
					</Box>
				</Box>
				<Divider variant="fullWidth" className={classes.fullWidthDivider}/>
				<Box className={classes.titleRow}>
					<Typography className={classes.pdfTitle} variant="h5" component="h1">{selectedAttachment.file}</Typography>
					<DocumentDetails {...selectedAttachment} renderAs={DownloadDocument} />
				</Box>
			</Box>
			{
				!hasOfficeRole &&
					<Box className={classes.indexContentContainer} data-field="IndexingPanel">
						<IndexingSectionContextProvider attachmentId={attachmentId}>
							<IndexingSection />
						</IndexingSectionContextProvider>
					</Box>
			}
			<Box className={classes.mainContainer}>
				<Box className={classes.maxContainer}>
					<Box>
						{docUrl && <PdfViewer pdfDoc={docUrl}/>}
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
