import React from 'react';
import {
	CopyCaseContextType,
	SectionOptions,
} from './CopyCaseProps';
import { FormSection } from '../../RequestForm/interface/FormSection';

const CopyCaseContext = React.createContext<CopyCaseContextType | null>(null);

export const useCopyCaseState = (): CopyCaseContextType => {
	const context = React.useContext(CopyCaseContext);

	if (!context) {
		throw new Error('useCopyCaseState must be used inside a child component of CopyCaseProvider');
	}

	return context;
};

const getAllSectionsAs = (setAs: boolean): SectionOptions =>
	Object.values(FormSection)
		.reduce((falseSections: SectionOptions, sectionName: FormSection) => {
			falseSections[sectionName] = setAs;
			return falseSections;
		}, {} as SectionOptions);
const allSectionsFalse: SectionOptions = getAllSectionsAs(false);
const allSectionsTrue: SectionOptions = getAllSectionsAs(true);
const getIsAllChecked = (sectionOptions: SectionOptions) => Object.values(sectionOptions).every(option => option);
const defaultSections = allSectionsTrue;

export const CopyCaseProvider: React.FC = ({ children }) => {
	const [sectionOptions, setSectionOptions] = React.useState<SectionOptions>(defaultSections);

	const [isOpen, setIsOpen] = React.useState(false);

	const closeModal = React.useCallback(() => {
		setIsOpen(false);
		setSectionOptions(defaultSections);
	}, []);
	const openModal = React.useCallback(() => {
		setIsOpen(true);
		setSectionOptions(defaultSections);
	}, []);

	const toggleAllOption = React.useCallback(() => {
		setSectionOptions((currentOptions) => getIsAllChecked(currentOptions) ? allSectionsFalse : allSectionsTrue);
	}, []);

	const isAllChecked = React.useMemo(() => getIsAllChecked(sectionOptions), [sectionOptions]);

	const toggleSection = React.useCallback((sectionToToggle: FormSection) => {
		setSectionOptions((currentOptions) => ({
			...currentOptions,
			[sectionToToggle]: !currentOptions[sectionToToggle],
		}));
	}, []);

	const copyCaseValues: CopyCaseContextType = {
		openModal,
		closeModal,
		toggleAllOption,
		toggleSection,
		isAllChecked,
		isOpen,
		sectionOptions,
	};

	return (
		<CopyCaseContext.Provider value={copyCaseValues}>
			{children}
		</CopyCaseContext.Provider>
	);
};
