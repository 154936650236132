import { EventsWithError } from '@utilities/hooks/useUnitEvents/EventParser';
import { RoomInfo } from '@data/openCalendar/RoomInfo';
import { combineConsecutiveEvents, EventInfo, getDateFromApiDateTime } from '@calendar';
import { OfficeCalendarError } from '@components/OfficeCalendarView/OfficeCalendarError';
import { differenceInHours } from 'date-fns';
import OfficeEventType from '@data/openCalendar/OfficeEventType';
import { TIME_ONLY_FORMAT } from '@utilities/constants';
import { formatInTimeZone } from 'date-fns-tz';
import CurrentHoldEvent from '@components/OfficeCalendarView/utilities/CurrentHoldEvent';

/***
 * Business rules state 30 minutes but any time into a 15 minute block will take up entire block.
 * Consider this example:
 *  - Event A :: 8:00 AM - 8:20 AM
 *  - Event B :: 9:00 AM - 10:00 AM
 * Although Event A ends at 8:20 technically leaving 40 minutes between events, end time should always be considered to round up.
 * Since only a 30-minute gap would be available in the UI to schedule (8:30 AM - 9:00 AM), anything less than 44 minutes should be shown as unavailable.
 */
const MERGE_MINUTES = 44;

interface EventParserResults {
	events: EventInfo[];
	currentHoldEventStub: EventInfo | undefined;
}

const getOfficeCalEventParser = (currentHoldEventSelector?: ((ev: CurrentHoldEvent) => boolean)) =>
	(rooms: RoomInfo[], hospitalTimeZone: string): EventsWithError<OfficeCalendarError> => {
		const { events, currentHoldEventStub } = rooms.reduce<EventParserResults>(({ events, currentHoldEventStub }: EventParserResults, room) => {
			const foundCurrentEventStub = (() => {
				if (currentHoldEventStub || !currentHoldEventSelector) { return currentHoldEventStub; }

				const foundCurrentHoldEvents = room.events.filter((e) => currentHoldEventSelector({ ...e, column: room.name }));
				// Must be exactly 1 event found
				if (foundCurrentHoldEvents.length !== 1) { return; }
				const holdEventInfo = foundCurrentHoldEvents[0];

				const startTime = getDateFromApiDateTime(holdEventInfo.start);
				const endTime = getDateFromApiDateTime(holdEventInfo.end);
				const formattedStartTime = formatInTimeZone(startTime, hospitalTimeZone, TIME_ONLY_FORMAT);
				const formattedEndTime = formatInTimeZone(endTime, hospitalTimeZone, TIME_ONLY_FORMAT);
				return {
					id: holdEventInfo.id,
					start: startTime,
					end: endTime,
					column: room.name,
					type: OfficeEventType.SOFT_BLOCK,
					title: 'New procedure',
					timeSpanDisplay: `${formattedStartTime} - ${formattedEndTime}`,
				};
			})();

			// filter out current hold event id so it won't display as unavailable
			const filteredEvents = room.events.filter(e => e.id !== foundCurrentEventStub?.id);
			const roomEvents: EventInfo[] = combineConsecutiveEvents(filteredEvents, MERGE_MINUTES)
				.map(({ start, end  }, index) => ({
					id: `${room.id}_${index}`,
					start: start,
					end: end,
					column: room.name,
					type: OfficeEventType.UNAVAILABLE,
				}));
			return { events: events.concat(roomEvents), currentHoldEventStub: foundCurrentEventStub };
		}, { events: [], currentHoldEventStub: undefined });

		let error: OfficeCalendarError = OfficeCalendarError.NONE;
		if (
			// Same number of events as there are rooms
			events.length === rooms.length &&
			// None of the events are less than 24 hours
			!events.some(e => differenceInHours(e.end, e.start, { roundingMethod: 'round' }) < 24)
		) {
			error = OfficeCalendarError.NO_OPEN_TIME;
		}

		const combinedEvents = [...events];
		if (currentHoldEventStub) {
			combinedEvents.push(currentHoldEventStub);
		}

		return {
			events: combinedEvents,
			error,
		};
	};

export default getOfficeCalEventParser;
