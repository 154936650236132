import { differenceInMinutes } from 'date-fns';
import { getDateFromApiDateTime } from '@calendar';
import convertEventResponseToUiData
	from '@components/SchedulePage/utilities/convertEventResponseToUiData/convertEventResponseToUiData';
import calculateBlockDetails from '@components/SchedulePage/utilities/calculateBlockDetails/calculateBlockDetails';
import minuteDurationToHrMinStr from '@utilities/minuteDurationToHrMinStr/minuteDurationToHrMinStr';
import Interval from '@interfaces/Interval';
import sortEvents from '@components/SchedulePage/utilities/sortEvents/sortEvents';
import sortTimeFrames from '@components/SchedulePage/utilities/sortTimeFrames/sortTimeFrames';
import { ScheduleApiResponse } from '@data/schedules/types/ScheduleApiResponse';
import { ScheduleColumnData } from '@data/schedules/types/ScheduleColumnData';
import SurgeonWithDisplayName from '@data/surgeon/SurgeonWithDisplayName';
import { decorateEventsWithGaps } from '@components/SchedulePage/utilities/decorateEventsWithGaps/decorateEventsWithGaps';
import { utcToZonedTime } from 'date-fns-tz';
import { sortObjectsByField } from '@utilities/sortObjectsByField/sortObjectsByField';

type BlockReleaseInfo = {
	isReleased: boolean;
	releasedTimeFrames: Interval[];
	nonReleasedTimeFrames: Interval[];
};

/**
 * Convert surgeontimes API response to ScheduleColumnData for UI
 *
 * @param schedules {ScheduleApiResponse[]} - SurgeonTimes API response
 * @param surgeonsById {Record<string, SurgeonWithDisplayName>} - Surgeon data organized by id
 * @param selectedDate {Date} - Date selected by user on scheduling page
 * @param hospitalTimeZone {string} - Timezone of the hospital
 *
 * @returns {ScheduleColumnData[]} - Schedule data for UI
 */
const convertSchedulesResponseToUiData = (
	schedules: ScheduleApiResponse[],
	surgeonsById: Record<string, SurgeonWithDisplayName>,
	selectedDate: Date,
	hospitalTimeZone: string,
): ScheduleColumnData[] => {
	return schedules
		.map((schedule): ScheduleColumnData => {
			const {
				isReleased,
				releasedTimeFrames,
				nonReleasedTimeFrames,
			} = (schedule.blocks || []).reduce<BlockReleaseInfo>(
				/**
				 * Iterate through blocks to determine if the schedule is released and to collect block timeframes to their buckets
				 */
				(aggregate, block): BlockReleaseInfo => {
					const thisBlockIsReleased =
						!!block.releaseDate &&
						getDateFromApiDateTime(block.releaseDate) < new Date();

					const timeFrameProp: keyof Omit<BlockReleaseInfo, 'isReleased'> = thisBlockIsReleased
						? 'releasedTimeFrames'
						: 'nonReleasedTimeFrames';

					return {
						...aggregate,
						isReleased: aggregate.isReleased && thisBlockIsReleased,
						[timeFrameProp]: aggregate[timeFrameProp].concat([
							{
								start: getDateFromApiDateTime(block.start),
								end: getDateFromApiDateTime(block.end),
							},
						]),
					};
				},
				{
					isReleased: !!schedule.blocks?.length,
					releasedTimeFrames: [],
					nonReleasedTimeFrames: [],
				},
			);

			const timeframes = (isReleased
				? releasedTimeFrames
				: nonReleasedTimeFrames)
				.sort(sortTimeFrames);

			const events =
				schedule.events
					?.map(convertEventResponseToUiData(schedule.owner))
					.sort(sortEvents) || [];

			const {
				overlapMinutes,
				toFollowTime = nonReleasedTimeFrames[0]?.start,
			} = calculateBlockDetails(timeframes, events);

			const totalMinutes = nonReleasedTimeFrames.reduce(
				(accum, t) => accum + differenceInMinutes(t.end, t.start),
				0,
			);

			const availableTime = Math.max(totalMinutes - overlapMinutes, 0);

			return {
				...schedule,
				surgeon: surgeonsById[schedule.id],
				scheduleHasBlock: !!(
					schedule.blocks && schedule.blocks?.length
				),
				events: decorateEventsWithGaps(events, hospitalTimeZone),
				displayName: schedule.owner,
				isReleased,
				individualBlocks: schedule.blocks || [],
				timeframes,
				availableTime,
				availableTimeDisplay: minuteDurationToHrMinStr(availableTime),
				toFollowTime: toFollowTime && utcToZonedTime(toFollowTime, hospitalTimeZone),
				selectedDate,
				utilization: schedule.utilization,
			};
		})
		.sort(sortObjectsByField<ScheduleColumnData>('displayName'));
};

export default convertSchedulesResponseToUiData;
