import React from 'react';
import { DropdownOption } from '@definitions/DropdownOption';
import { useFieldFormContext } from '@utilities/hooks/useFieldFormContext/useFieldFormContext';
import { UseMultiDropdownFormContextReturnInterface } from '@utilities/hooks/useMultiDropdownFormContext/UseMultiDropdownFormContextReturnInterface';
import { UseMultiDropdownFormContextConfigInterface } from '@utilities/hooks/useMultiDropdownFormContext/UseMultiDropdownFormContextConfigInterface';

export const useMultiDropdownFormContext = <ValueType = string>(name: string, initialDropdownOptions: DropdownOption<ValueType>[] = [], { validationRules }: Partial<UseMultiDropdownFormContextConfigInterface> = {}): UseMultiDropdownFormContextReturnInterface<ValueType> => {
	const [dropdownOptions, setDropdownOptions] = React.useState<DropdownOption<ValueType>[]>(initialDropdownOptions);
	const {
		currentValue,
		setCurrentValue,
		unregisterSelf,
		error,
	} = useFieldFormContext<DropdownOption<ValueType>[]>(name, { validationRules, triggerValidationOnChange: true });

	return {
		selectedDropdownOption: currentValue || [] as unknown as DropdownOption<ValueType>[],
		setSelectedValue: setCurrentValue,
		dropdownOptions,
		setDropdownOptions,
		error,
		unregisterSelf,
	};
};
