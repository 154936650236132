import { Reducer } from 'redux';
import { START_SPINNER, END_SPINNER
} from '../actions/types';
import { UtilityState, UtilityActionTypes } from '../../interfaces/utilityStoreInterface';

const initialState: UtilityState = {
	spinnerStart: false
};

export const UtilityReducer: Reducer<UtilityState, UtilityActionTypes>
	= (state: UtilityState = initialState, action: UtilityActionTypes) => {
		switch (action.type) {
		case END_SPINNER:
			return {
				...state,
				spinnerStart: false
			};
		case START_SPINNER:
			return {
				...state,
				spinnerStart: true
			};
		}
		return state;
	};
