import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EventCard from '@components/EventCard/EventCard';
import { EventInfo } from '@calendar';

const useStyles = makeStyles({
	card: {
		background: 'repeating-linear-gradient(-45deg, #EEEEEE, #EEEEEE 5px, #E0E0E0 5px, #E0E0E0 10px)',
		border: 'none',
		borderRadius: 0,
		margin: '1px 0 0 2px',
		opacity: 0.8,
		cursor: 'not-allowed',
	}
});

const UnavailableEventBlock: React.FC<EventInfo> = (event: EventInfo) => {
	const classes = useStyles();

	return (
		<EventCard
			{...event}
			additionalClassName={classes.card}
		/>
	);
};

export default UnavailableEventBlock;
