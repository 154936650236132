import buildUrlWithBase from './buildUrlWithBase';
import ApiService from './ApiService';

/***
 * Given an ApiService, returns the corresponding base URL to use
 *
 * @param service {ApiService} - ApiService enum used to find the corresponding URL in the config
 *
 * @return href string built using the BASE_API_URL to fill in the blanks
 */
const getEndpointConfigByService = (service: ApiService) => {
	const serviceUrlPart = window.config[`BASE_URL_${service}`] || window.config.BASE_API_URL;
	return {
		url: buildUrlWithBase(serviceUrlPart),
		key: window.config[`API_KEY_${service}`],
	};
};

export default getEndpointConfigByService;
