import {
	ProcedureFromTemplateInfo
} from '@components/RequestForm/SurgeryInformation/SurgeryInformationInterface';
import { getFormattedName } from '../commonFunction';
import { TemplateInfoApi } from '@interfaces/Procedure/TemplateInfoApi';
import {
	transformEquipmentDataToDropdownOptions
} from '@components/ProcedureForm/inputs/EquipmentMultiSelect/EquipmentMultiSelectTypeConverters';

const TemplateApiInfoToSurgeryProcedureReq = (templateInitVals?: Partial<TemplateInfoApi>) => {
	if (!templateInitVals) { return undefined; }

	const finalSurgeryProcReq: Partial<ProcedureFromTemplateInfo> = {};

	/**
	 * Direct copy with same property name
	 */

	if ('procedureName' in templateInitVals && templateInitVals.procedureName) {
		finalSurgeryProcReq.procedureName = templateInitVals.procedureName;
	}

	if ('modifierSide' in templateInitVals && templateInitVals.modifierSide) {
		finalSurgeryProcReq.modifierSide = { label: templateInitVals.modifierSide, value: templateInitVals.modifierSide };
	}

	if ('modifierApproach' in templateInitVals && templateInitVals.modifierApproach) {
		finalSurgeryProcReq.modifierApproach = { label: templateInitVals.modifierApproach, value: templateInitVals.modifierApproach };
	}

	if ('anesthesiaType' in templateInitVals && templateInitVals.anesthesiaType) {
		finalSurgeryProcReq.anesthesiaType = templateInitVals.anesthesiaType.map(val => ({ label: val, value: val }));
	}

	/**
	 * Different name between two objects
	 */

	if ('vendor' in templateInitVals && templateInitVals.vendor) {
		finalSurgeryProcReq.vendorName = templateInitVals.vendor;
	}

	if ('otherEquipments' in templateInitVals && templateInitVals.otherEquipments) {
		finalSurgeryProcReq.otherEquipment = templateInitVals.otherEquipments;
	}

	if ('comments' in templateInitVals && templateInitVals.comments) {
		finalSurgeryProcReq.additionalComments = templateInitVals.comments;
	}

	if ('name' in templateInitVals && templateInitVals.name) {
		finalSurgeryProcReq.templateName = templateInitVals.name;
	}

	if ('id' in templateInitVals && templateInitVals.id) {
		finalSurgeryProcReq.templateId = templateInitVals.id;
	}

	/**
	 * Requires a bit more processing
	 */

	if ('cptCodes' in templateInitVals && templateInitVals.cptCodes && templateInitVals.cptCodes.length) {
		finalSurgeryProcReq.cptCodes = { currentEntry: '', entriesList: templateInitVals.cptCodes };
	}

	if ('implantsNeeded' in templateInitVals && (templateInitVals.implantsNeeded === true || templateInitVals.implantsNeeded === false)) {
		finalSurgeryProcReq.implantsNeeded = templateInitVals.implantsNeeded ? 1 : 0;
	}

	if ('surgeon' in templateInitVals && templateInitVals.surgeon) {
		finalSurgeryProcReq.surgeon = {
			value: templateInitVals.surgeon.id,
			label: getFormattedName(templateInitVals.surgeon),
		};
	}

	if ('robotic' in templateInitVals && templateInitVals.robotic) {
		finalSurgeryProcReq.robotic = templateInitVals.robotic;
	}

	if ('equipment' in templateInitVals && templateInitVals.equipment) {
		finalSurgeryProcReq.equipment = transformEquipmentDataToDropdownOptions(templateInitVals.equipment);
	}

	return Object.keys(finalSurgeryProcReq).length ? finalSurgeryProcReq : undefined;
};

export default TemplateApiInfoToSurgeryProcedureReq;
