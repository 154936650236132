import React, { SetStateAction } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useFilterState } from '../Filters/FiltersContext/FiltersContext';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton, InputBase, Paper } from '@material-ui/core';

const useStyles = makeStyles(theme => createStyles({
	root: {
		'boxShadow': 'none',
		'borderRadius': '3px',
		'border': '1px solid #999999',
		'display': 'flex',
		'justifyContent': 'space-between',
		'flex': '1 1 100%',
		'& > button': {
			opacity: '1'
		}
	},
	searchIconButton: {
		color: '#1E69D2',
		opacity: '1'
	},
	closeIconButton: {
		color: 'rgba(0, 0, 0, .87)',
		opacity: '1'
	},
	input: {
		paddingLeft: theme.spacing(2),
		width: '100%',
	},
}));


export default function SearchComponent ({ hasMaxValue, placeholder }: { hasMaxValue: boolean; placeholder: string }) {
	const { globalFilter, updateFilterProps } = useFilterState();
	const { root, searchIconButton, closeIconButton, input } = useStyles();

	const [value, setValue] = React.useState(globalFilter);

	const previousValueRef = React.useRef(value);
	const handleClear = React.useCallback(() => {
		setValue('');
		updateFilterProps({ globalFilter: '' });
		previousValueRef.current = '';
	}, [updateFilterProps]);

	const handleSubmit = React.useCallback((event: React.FormEvent<HTMLDivElement> | React.FocusEvent<HTMLDivElement>) => {
		if (event.type === 'submit') {
			event.preventDefault();
		}
		const trimmedValue = value.trim();
		if (trimmedValue === previousValueRef.current) { return; }
		setValue(trimmedValue);
		updateFilterProps({ globalFilter: trimmedValue });
		previousValueRef.current = trimmedValue;
	}, [updateFilterProps, value]);

	const handleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue: SetStateAction<string> = event.target.value;
		if (!hasMaxValue || (hasMaxValue && inputValue != null && inputValue.length <= 50)) {
			setValue(inputValue);
		}
	}, [hasMaxValue]);

	React.useEffect(() => {
		setValue(globalFilter);
	}, [globalFilter]);

	return (
		<Paper
			component="form"
			className={root}
			onSubmit={handleSubmit}
			data-field="searchForm"
			onBlur={handleSubmit}
		>
			<InputBase
				data-field="searchBar"
				placeholder={placeholder}
				className={input}
				value={value}
				onChange={handleChange}
			/>
			{value.length === 0 ?
				<IconButton disabled>
					<SearchIcon className={searchIconButton}/>
				</IconButton> :
				<IconButton onMouseDown={e => e.preventDefault()} onClick={handleClear}>
					<CloseIcon className={closeIconButton}/>
				</IconButton>
			}
		</Paper>
	);
}

