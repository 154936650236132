import React from 'react';
import { Box, createStyles, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ColumnHeaderCommon from '@components/SchedulePage/ColumnHeaderCommon/ColumnHeaderCommon';
import ColumnHeaderProps from '@components/SchedulePage/types/ColumnHeaderProps';

const useStyles = makeStyles(theme => createStyles({
	root: {
		padding: theme.spacing(0.25, 1, 0.5, 1),
		backgroundColor: theme.palette.secondary.dark,
		color: theme.palette.common.white,
		display: 'flex',
		flexDirection: 'column',
		borderRadius: '4px 4px 0 0',
	},
	nonBlockText: {
		fontSize: '0.875rem',
		fontWeight: 600,
	},
	nonBlockSection: {
		alignItems: 'center',
		display: 'flex',
		flexGrow: 1,
		marginTop: theme.spacing(.5),
		textTransform: 'unset',
	},
}));

type NonBlockColumnHeaderProps = ColumnHeaderProps;

const NonBlockColumnHeader: React.FC<NonBlockColumnHeaderProps> = ({ blockInfo }) => {
	const classes = useStyles();

	return (
		<Box className={classes.root} data-field="non-block-column-header" component="header">
			<ColumnHeaderCommon blockInfo={blockInfo} />
			<Box className={classes.nonBlockSection}>
				<Typography className={classes.nonBlockText}>Non-Block Time Only</Typography>
			</Box>
		</Box>
	);
};

export default NonBlockColumnHeader;
