import { Warning as WarningIcon } from '@material-ui/icons';
import { FormHelperText } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	warningMessage: {
		'color': theme.palette.grey['900'],
		'display': 'flex',
		'alignItems': 'start',
		'& > .MuiSvgIcon-root': {
			color: theme.palette.gold.dark,
			height: '16px',
			width: '16px',
			marginRight: theme.spacing(1),
			marginTop: theme.spacing(0.25)
		},
	},
}));

interface WarningMessageMuiProps {
	warningMessage: string;
}

const WarningMessageMui: React.FC<WarningMessageMuiProps> = ({ warningMessage }) => {
	const classes = useStyles();
	return (
		<FormHelperText className={classes.warningMessage}>
			<WarningIcon />
			{warningMessage}
		</FormHelperText>
	);
};

export default WarningMessageMui;
