import React from 'react';
import { Tooltip as MUITooltip, TooltipProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useTooltipStyles = makeStyles((theme) => ({
	tooltip: {
		backgroundColor: theme.palette.grey[800],
		width: 244,
		padding: theme.spacing(1),
		// Same font as body2. Tooltip can't accept variant prop.
		fontSize: 14,
		fontWeight: 500,
		fontFamily: 'HCo Whitney SSm',
	},
	arrow: {
		color: theme.palette.grey[800],
		// Custom triangle. Did not modify position because we risk it looking weird
		// depending on how long text we're wrapping is.
		fontSize: 18,
		borderStyle: 'solid',
		borderRight: '9px solid transparent',
		borderLeft: '9px solid transparent',
		borderTop: '16px solid',
		borderBottom: 0,
	},
}));

export function Tooltip({ children, ...props }: TooltipProps) {
	const tooltipClasses = useTooltipStyles();
	return (
		<MUITooltip classes={tooltipClasses} enterDelay={250} {...props}>
			{children}
		</MUITooltip>
	);
}
