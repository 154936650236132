import { Box } from '@ascension/web';
import { TextArea } from '@ascension/web/components/base/TextArea';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import React from 'react';
import { FormComponentProps } from './FormComponentProps';
import useFormFieldSchema from './useFormFieldSchema';
import { FieldValues } from 'react-hook-form';

function FormTextArea<T extends FieldValues>({
	name,
	label,
	...rest
}: FormComponentProps<T>) {
	const {
		updateValue,
		currentValue,
		error,
		isRequired,
	} = useFormFieldSchema<T>(name);

	return (
		<Box mb={13} data-field={name} {...rest}>
			<TextArea
				label={label}
				name={name}
				onChange={updateValue}
				value={currentValue}
				required={isRequired}
				data-field={name}
			/>
			<ErrorMessage errorField={error}/>
		</Box>
	);
}

export default FormTextArea;
