import { FieldErrors, FieldName, FieldValues } from 'react-hook-form';

function getFormErrorForField<T extends FieldValues>(fieldName: FieldName<T>): (formErrors: FieldErrors<T>) => string {
	return (formErrors) => {
		const errorDesc = formErrors[fieldName];
		if (errorDesc) {
			return typeof errorDesc.message === 'string' ? errorDesc.message : 'Invalid';
		}
		return '';
	};
}

export default getFormErrorForField;
