import { Reducer } from 'redux';
import { ApiStatusState, CallsInFlightStatus } from '@interfaces/ApiStatus/ApiStatusState';
import { ApiStatusAction } from '@interfaces/ApiStatus/ApiStatusAction';
import { API_CANCELER_ADD, API_END, API_START } from '@store/actions/types';

const initialCallsInFlightState: CallsInFlightStatus = {
	listById: {},
	globalLoadingCount: 0,
	countByLabel: {},
	countByEndpoint: {},
};
const initialState: ApiStatusState = {
	cancelers: {},
	callsInFlight: initialCallsInFlightState,
};

export const ApiStatusReducer: Reducer<ApiStatusState, ApiStatusAction> = (state: ApiStatusState = initialState, action: ApiStatusAction) => {
	const { listById, globalLoadingCount, countByLabel, countByEndpoint } = state.callsInFlight;

	switch (action.type) {
	case API_CANCELER_ADD:
		return {
			...state,
			cancelers: {
				...state.cancelers,
				[action.payload.endpoint]: {
					canceler: action.payload.canceler,
					cancelPromise: action.payload.cancelPromise
				},
			},
		};
	case API_START: {
		return {
			...state,
			callsInFlight: {
				listById: {
					...listById,
					[action.payload.id]: action.payload,
				},
				globalLoadingCount: action.payload.noGlobalLoader ? globalLoadingCount : globalLoadingCount + 1,
				countByLabel: {
					...countByLabel,
					[action.payload.label]: (countByLabel[action.payload.label] || 0) + 1,
				},
				countByEndpoint: {
					...countByEndpoint,
					[action.payload.endpoint]: (countByEndpoint[action.payload.endpoint] || 0) + 1,
				},
			},
		};
	}
	case API_END: {
		const callsInFlightUpdated = {
			listById: {
				...listById,
			},
			countByEndpoint: {
				...countByEndpoint,
			},
			countByLabel: {
				...countByLabel,
			},
			globalLoadingCount: globalLoadingCount,
		};
		const { id, endpoint, label, noGlobalLoader } = listById[action.payload];
		delete callsInFlightUpdated.listById[id];
		callsInFlightUpdated.countByEndpoint[endpoint]--;
		callsInFlightUpdated.countByLabel[label]--;
		if (!noGlobalLoader) {
			callsInFlightUpdated.globalLoadingCount--;
		}
		return {
			...state,
			callsInFlight: Object.keys(callsInFlightUpdated.listById).length ? callsInFlightUpdated : initialCallsInFlightState,
		};
	}
	}
	return state;
};
