import { SelectOption } from '@components/StatefulInputs/StatefulSelect/StatefulSelect.types';

export const renderSelectedValues = (selectedOptions: SelectOption[]) => {
	return selectedOptions
		.map((option) => option.label)
		.sort()
		.join(' | ');
};

export const isOptionSelected = (options: SelectOption[], option: SelectOption): boolean => {
	return options.some(item => item.value === option.value);
};

export const getOptionLabelFromValue = (value: string, menuOptions: SelectOption[]) => {
	return menuOptions.find((option) => option.value === value)?.label;
};

export const getOptionValueFromLabel = (label: string, menuOptions: SelectOption[]) => {
	return menuOptions.find((option) => option.label === label)?.value;
};
