export enum UserRole {
	SURGEON = 'DS-CT-SURGEON',
	OFFICE_MANAGER = 'DS-CT-OFFICEMANAGER',
	OFFICE_SCHEDULER = 'DS-CT-OFFICESCHEDULER',
	OFFICE_VIEWER = 'DS-CT-OFFICEVIEWER',
	OR_SCHEDULER = 'DS-CT-ORSCHEDULER',
	OR_VIEWER = 'DS-CT-HOSPITALVIEWER',
	KSWIC_SURGEON = 'KSWIC-CT-SURGEON',
	KSWIC_OFFICE_MANAGER = 'KSWIC-CT-OFFICEMANAGER',
	KSWIC_OFFICE_SCHEDULER = 'KSWIC-CT-OFFICESCHEDULER',
	KSWIC_OFFICE_VIEWER = 'KSWIC-CT-OFFICEVIEWER',
	KSWIC_OR_SCHEDULER = 'KSWIC-CT-ORSCHEDULER',
	KSWIC_OR_VIEWER = 'KSWIC-CT-HOSPITALVIEWER',
	MIROC_SURGEON = 'MIROC-CT-SURGEON',
	MIROC_OFFICE_MANAGER = 'MIROC-CT-OFFICEMANAGER',
	MIROC_OFFICE_SCHEDULER = 'MIROC-CT-OFFICESCHEDULER',
	MIROC_OFFICE_VIEWER = 'MIROC-CT-OFFICEVIEWER',
	MIROC_OR_SCHEDULER = 'MIROC-CT-ORSCHEDULER',
	MIROC_OR_VIEWER = 'MIROC-CT-HOSPITALVIEWER',
	OKTUL_SURGEON = 'OKTUL-CT-SURGEON',
	OKTUL_OFFICE_MANAGER = 'OKTUL-CT-OFFICEMANAGER',
	OKTUL_OFFICE_SCHEDULER = 'OKTUL-CT-OFFICESCHEDULER',
	OKTUL_OFFICE_VIEWER = 'OKTUL-CT-OFFICEVIEWER',
	OKTUL_OR_SCHEDULER = 'OKTUL-CT-ORSCHEDULER',
	OKTUL_OR_VIEWER = 'OKTUL-CT-HOSPITALVIEWER',
}

export enum UserRegistrationRole {
	OFFICE_MANAGER = 'OFFICEMANAGER',
	OFFICE_SCHEDULER = 'OFFICESCHEDULER',
	OFFICE_VIEWER = 'OFFICEVIEWER',
}

export const OfficeRoles = [
	UserRole.OFFICE_MANAGER,
	UserRole.KSWIC_OFFICE_MANAGER,
	UserRole.MIROC_OFFICE_MANAGER,
	UserRole.OKTUL_OFFICE_MANAGER,

	UserRole.OFFICE_SCHEDULER,
	UserRole.KSWIC_OFFICE_SCHEDULER,
	UserRole.MIROC_OFFICE_SCHEDULER,
	UserRole.OKTUL_OFFICE_SCHEDULER,

	UserRole.OFFICE_VIEWER,
	UserRole.KSWIC_OFFICE_VIEWER,
	UserRole.MIROC_OFFICE_VIEWER,
	UserRole.OKTUL_OFFICE_VIEWER,

	UserRole.SURGEON,
	UserRole.KSWIC_SURGEON,
	UserRole.MIROC_SURGEON,
	UserRole.OKTUL_SURGEON,
];
export const OfficeInteractiveRoles = [
	UserRole.OFFICE_MANAGER,
	UserRole.KSWIC_OFFICE_MANAGER,
	UserRole.MIROC_OFFICE_MANAGER,
	UserRole.OKTUL_OFFICE_MANAGER,

	UserRole.SURGEON,
	UserRole.KSWIC_SURGEON,
	UserRole.MIROC_SURGEON,
	UserRole.OKTUL_SURGEON,

	UserRole.OFFICE_SCHEDULER,
	UserRole.KSWIC_OFFICE_SCHEDULER,
	UserRole.MIROC_OFFICE_SCHEDULER,
	UserRole.OKTUL_OFFICE_SCHEDULER,
];
export const OfficeManagerRoles = [
	UserRole.OFFICE_MANAGER,
	UserRole.KSWIC_OFFICE_MANAGER,
	UserRole.MIROC_OFFICE_MANAGER,
	UserRole.OKTUL_OFFICE_MANAGER,
];
export const HospitalRoles = [
	UserRole.OR_SCHEDULER,
	UserRole.KSWIC_OR_SCHEDULER,
	UserRole.MIROC_OR_SCHEDULER,
	UserRole.OKTUL_OR_SCHEDULER,

	UserRole.OR_VIEWER,
	UserRole.KSWIC_OR_VIEWER,
	UserRole.MIROC_OR_VIEWER,
	UserRole.OKTUL_OR_VIEWER,
];
export const HospitalInteractiveRoles = [
	UserRole.OR_SCHEDULER,
	UserRole.KSWIC_OR_SCHEDULER,
	UserRole.MIROC_OR_SCHEDULER,
	UserRole.OKTUL_OR_SCHEDULER,
];
export const ViewOnlyRoles = [
	UserRole.OR_VIEWER,
	UserRole.KSWIC_OFFICE_VIEWER,
	UserRole.MIROC_OFFICE_VIEWER,
	UserRole.OKTUL_OFFICE_VIEWER,

	UserRole.OFFICE_VIEWER,
	UserRole.KSWIC_OR_VIEWER,
	UserRole.MIROC_OR_VIEWER,
	UserRole.OKTUL_OR_VIEWER,
];
