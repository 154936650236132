import {
	useOfficeCalendarViewContext,
} from '@components/OfficeCalendarView/OfficeCalendarViewContext/OfficeCalendarViewContext';
import { useFormContext } from 'react-hook-form';
import React from 'react';
import { EventInfo } from '@calendar';
import { getZonedTimeFromTimeAndDate } from '@utilities/dateUtilities';
import { TEMP_STUB_EVENT_ID } from '@components/UnitCalendarPage/utilities/calendarConstants';
import { addDays } from 'date-fns';
import OfficeEventType from '@data/openCalendar/OfficeEventType';
import { EventTimeValidationSchema } from '@components/UnitCalendarPage/EventTimeSpanInput/EventTimeValidationSchema';
import { OfficeCalendarError } from '@components/OfficeCalendarView/OfficeCalendarError';
import SelectOpenTimeFormValues from '@components/OfficeCalendarView/SelectOpenTimeFormValues';

const useCalendarEventsWithHoldEvent = () => {
	const {
		events,
		unitConfig: { hospitalTimeZone },
		selectedRoom,
		selectedDate,
		currentHoldEvent,
		calendarError,
		columns,
	} = useOfficeCalendarViewContext();

	const canShowCurrent = React.useMemo(() => {
		return (
			!calendarError || [ // No calendar error OR error is NONE or modal error
				OfficeCalendarError.NONE,
				OfficeCalendarError.TIME_NO_LONGER_AVAIL,
			].includes(calendarError))
			&& !!columns.length // AND rooms are loaded (hide will events are downloading still)
			&& !!selectedRoom; // AND room is selected (hide while closing)
	}, [calendarError, columns.length, selectedRoom]);

	const room = selectedRoom;
	const currentHoldEventId = currentHoldEvent?.id;

	const { watch } = useFormContext<SelectOpenTimeFormValues>();
	const [ timeSpan ] = watch(['timeSpan']);

	const [stubEvent, setStubEvent] = React.useState<EventInfo | undefined>(undefined);

	React.useEffect(() => {
		// When timespan start/end invalid, don't update the stubEvent
		if (!(EventTimeValidationSchema.timeSpan.validate.checkAll(timeSpan, { ignoreIncrements: true }) === true || undefined)) {
			return;
		}

		const { start, end } = timeSpan;
		const getConvertedTime = (t: string) => getZonedTimeFromTimeAndDate(t, hospitalTimeZone, selectedDate);

		const startTimeConverted = start && getConvertedTime(start);
		const endTimeConverted = end && getConvertedTime(end);

		// When converted times are falsy or undefined due to invalid start/end string, don't update stubEvent
		if (!startTimeConverted || !endTimeConverted) {
			return;
		}

		// Otherwise set the stub event with the new times
		setStubEvent({
			// Should use event ID when present (editing an event) otherwise generic ID const (new event)
			id: currentHoldEventId || TEMP_STUB_EVENT_ID,
			type: OfficeEventType.SOFT_BLOCK,
			title: 'New procedure',
			start: startTimeConverted,
			end: end === '12:00 AM' ? addDays(endTimeConverted, 1) : endTimeConverted,
			column: room || '',
			timeSpanDisplay: `${start} - ${end}`,
		});
	}, [hospitalTimeZone, room, selectedDate, timeSpan, currentHoldEventId]);

	if (!stubEvent || !room || !canShowCurrent) { return events; }

	return [
		...events.filter(e => e.id !== currentHoldEventId),
		stubEvent,
	];
};

export default useCalendarEventsWithHoldEvent;
