import React from 'react';
import { Box, Flex } from '@ascension/web';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	container: {
		bottom: 0,
		position: 'sticky',
		flexDirection: 'column',
		zIndex: 2,
	},
	gradientBorder: {
		height: theme.spacing(1.25),
		background: 'linear-gradient(180deg, transparent 0%, #FFF 100%)',
	},
}));

export const StickyContainer: React.FC = ({ children }) => {
	const { container, gradientBorder } = useStyles();

	return (
		<Flex className={container}>
			<Box className={gradientBorder} />
			{ children }
		</Flex>
	);
};
