import CalendarMainControls from '@components/SchedulePage/CalendarMainControls/CalendarMainControls';
import PageHeader from '@components/PageHeader/PageHeader';
import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => createStyles({
	root: {
		width: '100%',
		[theme.breakpoints.down('md')]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		},
	},
}));

export const SchedulingPageHeader = () => {
	const classes = useStyles();

	return (
		<>
			<PageHeader title="Scheduling" className={classes.root}>
				<CalendarMainControls />
			</PageHeader>
		</>
	);
};
