import React from 'react';
import TermsOfUseContent from '@components/TermsOfUse/TermsOfUseContent';
import { useModalContext } from '@components/Modal/ModalContextProvider';
import { TERMS_OF_USE_MODAL } from '@components/Modal/modalConstants';
import ModalViewer from '@components/Modal/ModalViewer';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	modalWrapper: {
		maxHeight: '90vh',
		maxWidth: '50vw',
	}
});

const TermsOfUseModal: React.FC = () => {
	const classes = useStyles();
	const { closeModal } = useModalContext();

	return (
		<ModalViewer
			className={classes.modalWrapper}
			id={TERMS_OF_USE_MODAL}
			onClose={() => closeModal(TERMS_OF_USE_MODAL)}
			shouldCloseOnBackdropClick
			shouldCloseOnEscapeKeyDown
		>
			<TermsOfUseContent/>
		</ModalViewer>
	);
};

export default TermsOfUseModal;
