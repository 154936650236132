import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useIndexingSectionContext } from '@components/PdfViewerPage/IndexingSection/IndexingSectionContext/IndexingSectionContext';

const useStyles = makeStyles(theme => ({
	indexContainerOuter: {
		'marginTop': theme.spacing(3),
		'overflowY': 'scroll',
		'height': 'calc(100vh - 368px)',
		'&::-webkit-scrollbar': {
			width: '4px',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.palette.grey[400],
			borderRadius: '100px'
		},
	},
	indexContainerInner: {
		marginBottom: theme.spacing(2)
	},
	pagesText: {
		color: theme.palette.grey[500]
	},
	documentTypeText: {
		fontSize: '20px',
		fontWeight: 600,
		height: '24px'
	}
}));

const ReadOnlyIndexes: React.FC = () => {
	const classes = useStyles();
	const { initialIndexListApi } = useIndexingSectionContext();

	const hasExistingIndexes = !!initialIndexListApi.length;

	return (
		<Box className={classes.indexContainerOuter}>
			{hasExistingIndexes && initialIndexListApi.map((item, index) => {
				return (
					<Box key={`${item.type}-${index}`} className={classes.indexContainerInner}>
						<Typography variant="caption" className={classes.pagesText}>Pages {item.page}</Typography>
						<Typography component="p" className={classes.documentTypeText}>{item.type}</Typography>
					</Box>
				);
			})}

			{!hasExistingIndexes &&
				<Typography
					variant="body2"
				>
					This document has not been indexed
				</Typography>
			}
		</Box>
	);
};

export default ReadOnlyIndexes;
