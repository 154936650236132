export enum ToastConstants {
	SAVE_SUCCESS = 'Your changes have been saved',
	SAVE_ERROR = 'We\'re not able to save your changes. Please try again.',
	REQUEST_SUCCESS = 'Request successfully submitted',
	REQUEST_UPDATE_SUCCESS = 'Request successfully updated.',
	SAVE_SUCCESS_ACCOUNT_CONFIRMED = 'Success, your account has been confirmed',
	SUCCESSFULLY_SAVED_FILTERS = 'Success you saved your filters',
	FORCE_SAVE_SUCCESS = 'Case scheduled and linked to EHR',
	SUBMIT_REGISTRATION_SUCCESS = 'User has been submitted and is pending activation',
	USER_DEACTIVATED = 'User has been deactivated',
	EDIT_REGISTRATION_SUCCESS = 'Edit request has been sent to your Provisioning Manager for approval',
	CREATE_UNAVAILABLE_EVENT_SUCCESS = 'Unavailable time created',
	EDIT_UNAVAILABLE_EVENT_SUCCESS = 'Unavailable time edited',
    DELETE_UNAVAILABLE_EVENT_SUCCESS = 'Unavailable time deleted',
	SAVE_INDEX_ERROR = 'Error saving index',
	SAVE_INDEX_SUCCESS = 'Indexes saved',

}
