import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { UserProfilePrimaryPracticeSelector } from '@store/selectors/UserProfileSelector';
import React from 'react';
import { getZonedTimeFromTimeAndDate, timeStringAs12Hr } from '@utilities/dateUtilities';
import { addMinutes, format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { RequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import { ENDPOINT_USER_UNITS } from '@utilities/apiConstants';
import useCollection from '@utilities/hooks/useCollection/useCollection';
import { Unit } from '@data/unit/Unit';
import HoldEventDetailsRequest from '@data/holdEvent/types/HoldEventDetailsRequest';

const useHoldEventRequest = () => {
	const { getValues } = useFormContext<RequestFormValidatedState>();

	const { name: practiceName } = useSelector(UserProfilePrimaryPracticeSelector);

	const units = useCollection<Unit>(ENDPOINT_USER_UNITS);

	return React.useCallback((): HoldEventDetailsRequest | void => {
		const { procedureTime, procedureDate, duration, room, roomId, holdEventId, appointmentLocation } = getValues();
		const unit = units.find(u => u.id === appointmentLocation);

		if (!procedureDate || !procedureTime || !procedureTime[1] || !duration || !unit || !room || !roomId) {
			return;
		}

		const { hospitalTimeZone, hospital: hospitalId, id: unitId } = unit;

		const startTime12Hr = timeStringAs12Hr(procedureTime[1]);
		const startTime = startTime12Hr && getZonedTimeFromTimeAndDate(startTime12Hr, hospitalTimeZone, procedureDate);

		if (!startTime) { return; }

		const endTime = addMinutes(startTime, parseInt(duration));

		const formattedStartTime = formatInTimeZone(startTime, 'Z', 'yyyy-MM-dd HH:mm');
		const formattedEndTime =  formatInTimeZone(endTime, 'Z', 'yyyy-MM-dd HH:mm');

		return {
			id: holdEventId,
			hospitalId,
			unitId,
			hospitalTimeZone,
			room: room,
			roomId: roomId,
			date: format(procedureDate, 'yyyy-MM-dd'),
			startTime: formattedStartTime,
			endTime: formattedEndTime,
			description: practiceName,
		};
	}, [getValues, practiceName, units]);
};

export default useHoldEventRequest;
