import { Dispatch, SetStateAction } from 'react';
import { prevTimeToUpdatePeriod, prevTimeToUpdateTime, timeStringAs12Hr } from '@utilities/dateUtilities';
import padString from '@calendar/utilities/padString/padString';

export const parseAndUpdateTimeState = (
	timeSetter: Dispatch<SetStateAction<[string, string | undefined]>>,
	timeUpdate?: string,
	currentDateVal?: Date,
) =>
	timeSetter((prevTimeValue) => {
		const prevTime = prevTimeValue && prevTimeValue[0];
		return [prevTimeToUpdateTime(prevTime, currentDateVal, timeUpdate), timeUpdate];
	});

export const parsePeriodAndUpdateTimeState = (
	timeSetter: Dispatch<SetStateAction<[string, string | undefined]>>,
	periodUpdate?: string,
	currentDateVal?: Date,
) =>
	timeSetter((prevTimeValue) => {
		const prevTime = prevTimeValue && prevTimeValue[0];
		return [prevTimeToUpdatePeriod(prevTime, currentDateVal, periodUpdate), prevTimeValue[1]];
	});

export const convertAndSet12HourTime = (timeSetter: Dispatch<SetStateAction<[string, string | undefined]>>) =>
	(time24Val: string = '') => {
		const timeAs12Hr = timeStringAs12Hr(time24Val) || ''; // Change from 24 hour to 12 hour
		const timeValToUserInput = timeAs12Hr
			? padString(
				timeAs12Hr
					// Remove space and AM/PM at end
					.replace(/[^\d:]/g, ''),
				// Pad with proceeding 0 when less than 5 characters;
				5,
				'0',
				false,
			)
			: '';
		timeSetter([time24Val, timeValToUserInput]);
	};
