import {
	ASCENSION_ID_INSTANCE,
	ASCENSION_ID_APPLICATION_ID,
	ASCENSION_ID_BASE_DOMAIN,
	DEFAULT_PAGE, DEFAULT_SIZE
} from './apiConstants';
import { Surgeon } from '@data/surgeon/Surgeon';
import { FilterApiParams, FilterParams, FilterStateProps } from '@interfaces/FilterParams';
import { YesNoAnswer } from '@interfaces/SurgeryRequest/YesNoAnswer';
import SurgeonWithDisplayName from '@data/surgeon/SurgeonWithDisplayName';
import compareArrays from '@utilities/arrays/compareArrays';

export function filtersToReplaceString({
	page,
	size,
	sortModel,
	procedureDateFilter,
	statusFilter,
	stateFilter,
	from,
	to,
	surgeonFilter,
	globalFilter,
	allOtherFilters,
	filterApplied
}: FilterParams, pathname = '/?'): string {
	let filters = `page=${page}&size=${size}`;
	if (sortModel) {
		filters += '&sortModel=' + sortModel;
	}
	if (procedureDateFilter && procedureDateFilter !== '') {
		filters += '&procedureDate=' + procedureDateFilter;
	}
	if (from) {
		filters += '&from='+from;
	}
	if (to) {
		filters += '&to='+to;
	}
	if (statusFilter) {
		statusFilter.forEach((filter) => {
			filters += '&status=' + filter;
		});
	}
	if (stateFilter) {
		stateFilter.forEach((filter) => {
			filters += '&state=' + filter;
		});
	}
	if (surgeonFilter && surgeonFilter.length) {
		surgeonFilter.forEach((filter) => {
			filters += '&surgeon=' + filter;
		});
	}

	if (allOtherFilters) {
		allOtherFilters.forEach((filter) => {
			filters += '&allOtherFilters=' + filter;
		});
	}

	if (globalFilter)
		filters += '&globalFilter=' + globalFilter;

	if (filterApplied)
		filters += '&filterApplied=' + filterApplied;

	filters = `${pathname.replace(/\/\?$/, '')}?${filters}`;
	return  filters;
}

export function filtersToApiParams({
	page,
	size,
	sortModel,
	procedureDateFilter,
	statusFilter,
	stateFilter,
	from,
	to,
	surgeonFilter,
	globalFilter,
	allOtherFilters,
	allTask,
}: FilterParams, shouldRemoveUndefined = false): FilterApiParams {
	const asString = (val?: number | string | string[]): string | undefined => {
		return typeof val === 'string' || typeof val === 'number' || Array.isArray(val) ?
			val.toString() :
			undefined;
	};

	const surgeonStr = asString(surgeonFilter) || '';
	const surgeon = surgeonStr === 'all' ? undefined : surgeonStr;

	const apiParams: FilterApiParams = {
		page: asString(page),
		size: asString(size),
		sortModel,
		procedureDate: procedureDateFilter,
		status: asString(statusFilter),
		state: asString(stateFilter),
		surgeon,
		from,
		to,
		globalFilter,
		allOtherFilters: asString(allOtherFilters),
	};

	if (shouldRemoveUndefined) {
		for (const prop in apiParams) {
			if (apiParams[prop] === undefined) {
				delete apiParams[prop];
			}
		}
	}

	// Task parameter should always be included. Add after removing undefined
	apiParams.task = allTask || '0';

	return apiParams;
}

export const apiParamsToFilters = ({
	page,
	size,
	sortModel,
	procedureDate,
	status,
	state,
	surgeon,
	from,
	to,
	globalFilter,
	allOtherFilters,
	filterApplied,
}: FilterApiParams): FilterStateProps => {
	const splitOrEmpty = (val: string | undefined): string[] => val ? val.split(',') : [];

	return {
		page: Number(page) || DEFAULT_PAGE,
		size: Number(size) || DEFAULT_SIZE,
		sortModel,
		procedureDate: procedureDate,
		status: splitOrEmpty(status),
		state: splitOrEmpty(state),
		from,
		to,
		surgeon: splitOrEmpty(surgeon),
		globalFilter,
		allOtherFilters: splitOrEmpty(allOtherFilters),
		filterApplied,
	};
};

export const getBooleanRadioValue = (value: string | null) => {
	return value === YesNoAnswer.YES ? 1 : value === YesNoAnswer.NO ? 2 : null;
};

export const getNavigateToIdProvider = () => {
	const redirectUrl = encodeURIComponent(window.location.origin + '/');
	return (
		`https://${ASCENSION_ID_INSTANCE}.${ASCENSION_ID_BASE_DOMAIN}/` +
		`${ASCENSION_ID_INSTANCE}.${ASCENSION_ID_BASE_DOMAIN}/oauth2/v2.0/authorize` +
		'?' + `p=B2C_1A_Password_Reset` +
		`&client_id=${ASCENSION_ID_APPLICATION_ID}` +
		`&nonce=defaultNonce` +
		`&redirect_uri=${redirectUrl}` +
		`&state=defaultState` +
		`&scope=openid&response_type=id_token&prompt=login`
	);
};

export type Name = Partial<Pick<Surgeon, 'name' | 'lastName' | 'personalSuffix' | 'professionalSuffix' | 'middleName'>>;
export const getFormattedName = (nameParts?: Name, defaultVal = '---'): string => {
	const { name, lastName, personalSuffix, professionalSuffix, middleName } = nameParts || {};
	let formattedName = `${lastName || ''}${personalSuffix ? ' ' + personalSuffix : ''} ${professionalSuffix || ''}`.trim();
	formattedName += `${formattedName.length && name ? ', ' : ''}${name || ''}`;
	formattedName += `${formattedName.length && middleName ? ' ' : ''}${middleName ? middleName[0] + '.' : ''}`;
	return formattedName || defaultVal;
};

export const alphaByDisplayName = ({ displayName: a }: SurgeonWithDisplayName, { displayName: b }: SurgeonWithDisplayName) => {
	if (a === b) { return 0; }
	if (!a) { return -1; }
	if (!b) { return 1; }
	return a > b ? 1 : -1;
};

export function getRandomString(length: number) {
	let s = '';
	do {
		const cryptoRand = window.crypto.getRandomValues(new Uint32Array(1))[0];
		s += cryptoRand.toString(36).substr(2);
	} while (s.length < length);
	s = s.substr(0, length);
	return s;
}

export const arraysOverlap = (sourceArray: unknown[], targetArray: unknown[]) => sourceArray.some(sourceElement => targetArray.includes(sourceElement));

export const arrayDeepEqual = (arr1: unknown[], arr2: unknown[]) =>
	// Actually same by reference
	arr1 === arr2 ||
	// Both are falsy
	(!arr1 && !arr2) ||
	// Or all the following are true: both truthy, both same length, and finally expensive sorted stringify
	(arr1 && arr2 && arr1.length === arr2.length &&
		arr1.sort().join() === arr2.sort().join());

export const commaSeparatedStringToArray = (input?: string) => input && input.split(',') || [];

export const getSelectionUpdateWithAll = (
	newSelection: string[],
	allOptions: string[],
	currentlySelected: string[],
	allOption: string | null = 'all'
) => {
	const isSelectionSameAsPrevious = compareArrays(currentlySelected, newSelection, 1);
	if (isSelectionSameAsPrevious) {
		return currentlySelected;
	}

	const isNewSelectionEmpty = !newSelection.length;
	if (isNewSelectionEmpty) {
		return []; // Select all
	}

	const allSelected = compareArrays(currentlySelected, allOptions, 1);
	// when 'all' options added and all options not selected
	if (allOption !== null && newSelection[0] === allOption && !allSelected) {
		return allOptions; // Select all
	}

	return newSelection;
};
