import React from 'react';
import { DisplayText } from '@components/DisplayText/DisplayText';
import DocumentFieldProps
	from '@components/CaseDetails/DocumentInformationDisplay/DocumentDetails/DocumentField/DocumentFieldProps';

const DocumentField: React.FC<DocumentFieldProps> = ({ children, fieldName, ...props }) => {
	return <DisplayText
		label=""
		displayDefault={!children}
		defaultValue="--"
		data-field={`caseDetails-documentSection-${fieldName}`}
		{...props}
	>
		{children}
	</DisplayText>;
};

export default DocumentField;
