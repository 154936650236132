import { SurgeryProcedureInterface } from '../../RequestForm/SurgeryInformation/SurgeryInformationInterface';
import { DropdownOption } from '../../../definitions/DropdownOption';

const modifierSide: DropdownOption[] = [
	{ value: 'None', label: 'None' },
	{ value: 'Left', label: 'Left' },
	{ value: 'Right', label: 'Right' },
	{ value: 'Bilateral', label: 'Bilateral' }
];

const anesthesiaType: DropdownOption[] = [
	{ label: 'None', value: 'None' },
	{ label: 'Anesthesia Choice', value: 'Anesthesia Choice' },
	{ label: 'Bier Block', value: 'Bier Block' },
	{ label: 'Catheter Placement', value: 'Catheter Placement' },
	{ label: 'Epidural', value: 'Epidural' },
	{ label: 'General', value: 'General' },
	{ label: 'Lidocaine', value: 'Lidocaine' },
	{ label: 'Local', value: 'Local' },
	{ label: 'MAC', value: 'MAC' },
	{ label: 'Moderate Sedation', value: 'Moderate Sedation' },
	{ label: 'No Sedation', value: 'No Sedation' },
	{ label: 'Patient Choice', value: 'Patient Choice' },
	{ label: 'Peripheral Nerve Block', value: 'Peripheral Nerve Block' },
	{ label: 'Regional Block', value: 'Regional Block' },
	{ label: 'Sedation', value: 'Sedation' },
	{ label: 'Spinal', value: 'Spinal' },
];

const modifierApproach: DropdownOption[] = [
	{ value: 'None', label: 'None' },
	{ value: 'Anterior', label: 'Anterior' },
	{ value: 'Back', label: 'Back' },
	{ value: 'Caudal', label: 'Caudal' },
	{ value: 'Cephalic', label: 'Cephalic' },
	{ value: 'Distal', label: 'Distal' },
	{ value: 'Dorsal', label: 'Dorsal' },
	{ value: 'Face', label: 'Face' },
	{ value: 'Flank', label: 'Flank' },
	{ value: 'Foot', label: 'Foot' },
	{ value: 'Groin', label: 'Groin' },
	{ value: 'Hand', label: 'Hand' },
	{ value: 'Inner', label: 'Inner' },
	{ value: 'Interior', label: 'Interior' },
	{ value: 'Lateral', label: 'Lateral' },
	{ value: 'Lower Arm', label: 'Lower Arm' },
	{ value: 'Lower Leg', label: 'Lower Leg' },
	{ value: 'Medial', label: 'Medial' },
	{ value: 'Neck', label: 'Neck' },
	{ value: 'Outer', label: 'Outer' },
	{ value: 'Posterior', label: 'Posterior' },
	{ value: 'Proximal', label: 'Proximal' },
	{ value: 'Superior', label: 'Superior' },
	{ value: 'Torso', label: 'Torso' },
	{ value: 'Upper', label: 'Upper' },
	{ value: 'Upper Arm', label: 'Upper Arm' },
	{ value: 'Upper Leg', label: 'Upper Leg' },
	{ value: 'Volar', label: 'Volar' },
	{ value: 'w/Possible', label: 'w/Possible' },
	{ value: 'Wrist', label: 'Wrist' }
];

export const defaultDropdownOptionsIndexed: Partial<Record<keyof SurgeryProcedureInterface, DropdownOption[]>> = {
	modifierSide,
	anesthesiaType,
	modifierApproach,
};
