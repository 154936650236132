import React from 'react';
import { useFormContext } from 'react-hook-form';
import useCollection from '@utilities/hooks/useCollection/useCollection';
import { Unit } from '@data/unit/Unit';
import { ENDPOINT_USER_UNITS } from '@utilities/apiConstants';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	lineBreakBottom: {
		marginBottom: theme.spacing(4),
	}
}));

const LocationChangeModalBody: React.FC = () => {
	const classes = useStyles();
	const { watch } = useFormContext<{ appointmentLocation: string; }>();
	const [ appointmentLocation ] = watch(['appointmentLocation']);
	const units = useCollection<Unit>(ENDPOINT_USER_UNITS);

	const unitInfo = React.useMemo(() => {
		return units.find((u) => u.id === appointmentLocation);
	}, [appointmentLocation, units]);

	const locationName = unitInfo && unitInfo.name && unitInfo.hospitalName ? `${unitInfo.hospitalName}, ${unitInfo.name}`: '';

	return (
		<>
			<Typography className={classes.lineBreakBottom}>
				You originally requested a time and date at <strong>{locationName}.</strong>
			</Typography>
			<Typography>
				If you change the procedure <strong>Location</strong>, you will need to request a new date & time.
			</Typography>
		</>
	);
};

export default LocationChangeModalBody;
