import React from 'react';
import { SurgeryRequestAccessor } from '@interfaces/SurgeryRequest/SurgeryRequestAccessor';
import { ApiActionRequest } from '@interfaces/ApiAction/ApiAction';
import endpointTemplateToUrl from '@utilities/endpointTemplateToUrl';
import { ENDPOINT_UPDATE_CASE_IN_USE_STATUS } from '@utilities/apiConstants';
import { apiAction } from '@store/middlewares/api/ApiActionCreators';
import { EMPTY_ACTION, POST_CASE_ACCESSORS_REQUEST } from '@store/actions/types';
import { useDispatch, useSelector } from 'react-redux';
import { UserProfileDetailsSelector } from '@store/selectors/UserProfileSelector';
import { UserAccessEnum } from '@interfaces/SurgeryRequest/UserAccessEnum';
import { AxiosError, AxiosResponse } from 'axios';
import { updateAccessorsAction } from '@store/actions/CaseDetailsActionCreators';
import { Action } from 'redux';
import { ApiActionPayload } from '@interfaces/ApiAction/ApiActionPayload';
import useRemoveCaseAccessorEffect
	from '@components/CaseDetails/CaseInUse/hooks/useGetCaseAsAccessor/useRemoveCaseAccessorEffect/useRemoveCaseAccessorEffect';

const ACCESSOR_TTL_MINUTES = 4;

const responseHandler = (response: AxiosResponse<SurgeryRequestAccessor[]>) => {
	return updateAccessorsAction(response);
};

const SET_ACCESSOR_ERROR_CONFIG: Record<string, (e: AxiosError<SurgeryRequestAccessor[]>) => Action> = {
	409: ({ response }) => response && response.data && response.config.url ? updateAccessorsAction(response) : { type: EMPTY_ACTION },
};

const errorHandler = (error: AxiosError<SurgeryRequestAccessor[]>) => {
	const { response: { status } = {} } = error;
	if (status && SET_ACCESSOR_ERROR_CONFIG[status]) {
		return SET_ACCESSOR_ERROR_CONFIG[status](error);
	}
	return;
};

const setAccessor = (
	requestId: string,
	accessor: SurgeryRequestAccessor,
	options?: Pick<ApiActionPayload<SurgeryRequestAccessor[]>, 'shouldDisableLoadIndicator' | 'onFailure'>
): ApiActionRequest<SurgeryRequestAccessor, SurgeryRequestAccessor[], SurgeryRequestAccessor[]> => {
	const setAccessorsUrl = endpointTemplateToUrl(ENDPOINT_UPDATE_CASE_IN_USE_STATUS, { requestId });

	const shouldDisableErrorHandler = (error: AxiosError<SurgeryRequestAccessor[]>) => {
		return !!(error?.response?.status && error.response.status in SET_ACCESSOR_ERROR_CONFIG);
	};

	return apiAction<SurgeryRequestAccessor, SurgeryRequestAccessor[], SurgeryRequestAccessor[]>({
		url: setAccessorsUrl,
		method: 'POST',
		label: POST_CASE_ACCESSORS_REQUEST,
		includeAccessToken: true,
		shouldDisableErrorHandler,
		data: accessor,
		onSuccess: responseHandler,
		...options,
	});
};

const useSetAccessor = (requestId: string, accessType: UserAccessEnum) => {
	const {
		userId,
		firstName,
		lastName,
	} = useSelector(UserProfileDetailsSelector);
	const dispatch = useDispatch();

	const intervalTimerRef = React.useRef<ReturnType<typeof setTimeout>>();

	const cleanup = React.useCallback(() => {
		if (!intervalTimerRef.current) {
			return;
		}
		clearTimeout(intervalTimerRef.current);
		intervalTimerRef.current = undefined;
	}, []);

	const setter = React.useCallback((shouldDisableLoadIndicator?: boolean) => {
		cleanup();

		const onFailure = (error: AxiosError<SurgeryRequestAccessor[]>) => {
			cleanup();
			return errorHandler(error);
		};

		dispatch(setAccessor(
			requestId,
			{
				userId,
				firstName,
				lastName,
				access: accessType,
			},
			{ onFailure, shouldDisableLoadIndicator }
		));

		intervalTimerRef.current =  setTimeout(() => setter(true), ACCESSOR_TTL_MINUTES * 60 * 1000);
	}, [accessType, cleanup, dispatch, firstName, lastName, requestId, userId]);


	React.useEffect(() => {
		return cleanup;
	}, [cleanup, setter]);

	// Automatically cleanup on unmount, unload, or caseId change
	const remover = useRemoveCaseAccessorEffect(accessType);

	return { cleanup, setter, remover };
};

export default useSetAccessor;
