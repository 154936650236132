import React from 'react';
import {
	Box,
	Text,
} from '@ascension/web';
import { DisplayTextProps } from './DisplayTextProps';
import { useCommonStyles } from '@utilities/hooks/useCommonStyles/useCommonStyles';

export const DisplayText = ({ label, loading, defaultValue, displayDefault, children, ...rest }: DisplayTextProps) => {
	const { allowTextBreak } = useCommonStyles();

	return (
		<Box
			{...rest}
		>
			<Text className="label-text" fontSize="12px">
				{label}
			</Text>

			<Box data-field="value-text" className={`${loading && 'field-loading'} ${allowTextBreak} value-text`} >
				{displayDefault ?
					<Text color="gray" fontSize="12px" className="default-value-text">{defaultValue}</Text> :
					children
				}
			</Box>
		</Box>
	);
};
