import React from 'react';
import { Box, Flex, Label, Radio } from '@ascension/web';
import { Dropdown, Checkbox } from '@ascension/web';
import { PatientDetailsValidationSchema } from './PatientDetailsValidationSchema';
import { useFieldFormContext } from '@utilities/hooks/useFieldFormContext/useFieldFormContext';
import { useMaskFormContext } from '@utilities/hooks/useMaskFormContext/useMaskFormContext';
import { useDropdownFormContext } from '@utilities/hooks/useDropdownFormContext/useDropdownFormContext';
import SSNInput from './SSNInput/SSNInput';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';
import { AllergyOptionsEnum } from '@interfaces/SurgeryRequest/AllergyOptionsEnum';
import FullPatientNameInput from '@components/RequestForm/PatientDetails/PatientName/FullPatientNameInput';
import TextFieldInput from '@components/TextFieldInput/TextFieldInput';
import { PatientAddress } from '@components/RequestForm/PatientDetails/PatientAddress/PatientAddress';

const useStyles = makeStyles((theme) => (createStyles({
	marginBottomMd: {
		marginBottom: theme.spacing(2)
	},
	standardGap: {
		columnGap: theme.spacing(2),
		alignItems: 'center'
	},
	comments: {
		'& .MuiOutlinedInput-root': {
			height: '150px',
		},
		'& .MuiOutlinedInput-inputMultiline': {
			maxHeight: '120px',
		}
	},
})));

export default function PatientDetails () {
	const [
		dateValue,
		setDateValue,
		dateError,
		datePlaceholder,
		triggerDateValidation
	] = useMaskFormContext('patientDOB', 'date', {
		validationRules: PatientDetailsValidationSchema.patientDOB,
		triggerValidationOnChange: false,
	});

	const patientGenderOptions = [{ label: 'M', value: 'M' }, { label: 'F', value: 'F' }];

	const {
		selectedDropdownOption: patientGenderSelectedDropdownOption,
		setSelectedValue: patientGenderSetSelectedValue,
		error: patientGenderError
	} = useDropdownFormContext('patientGender', patientGenderOptions, {
		validationRules: PatientDetailsValidationSchema.patientGender,
	});

	const [
		patientPhoneNumberCurrentValue,
		patientPhoneNumberSetCurrentValue,
		patientPhoneNumberError,
		patientPhoneNumberPlaceholder,
		patientPhoneNumberTriggerSelfValidation
	] = useMaskFormContext('patientPhoneNumber', 'phone', {
		validationRules: PatientDetailsValidationSchema.patientPhoneNumber,
		triggerValidationOnChange: false,
	});

	const {
		currentValue: patientEmailCurrentValue,
		setCurrentValue: patientEmailSetCurrentValue,
		error: patientEmailError,
		triggerSelfValidation: patientEmailTriggerSelfValidation,
	} = useFieldFormContext<string>('patientEmail', {
		validationRules: PatientDetailsValidationSchema.patientEmail,
		triggerValidationOnChange: false
	});

	const {
		currentValue: interpreterNeededCurrentValue,
		setCurrentValue: interpreterNeededSetCurrentValue,
	} = useFieldFormContext<boolean>('patientInterpreterNeeded');

	const {
		currentValue: patientInterpretLanguageCurrentValue,
		setCurrentValue: patientInterpretLanguageSetCurrentValue,
		error: patientInterpretLanguageError,
		triggerSelfValidation: patientInterpretLanguageTriggerSelfValidation,
	} = useFieldFormContext<string>('patientInterpretLanguage', {
		validationRules: interpreterNeededCurrentValue ?
			PatientDetailsValidationSchema.patientInterpretLanguageRequired :
			PatientDetailsValidationSchema.patientInterpretLanguage,
	});

	const {
		currentValue: patientLatexAllergyCurrentValue,
		setCurrentValue: patientLatexAllergySetCurrentValue,
	} = useFieldFormContext<AllergyOptionsEnum>('patientLatexAllergy' );

	const {
		currentValue: otherPatientDetailsCurrentValue,
		setCurrentValue: otherPatientDetailsSetCurrentValue,
	} = useFieldFormContext<string>('otherPatientDetails');

	const {
		currentValue: patientIodineAllergyCurrentValue,
		setCurrentValue: patientIodineAllergySetCurrentValue,
	} = useFieldFormContext<AllergyOptionsEnum>('patientIodineAllergy' );

	const handleLatexRadioYes = (checked: boolean) => {
		if (checked) {
			patientLatexAllergySetCurrentValue(AllergyOptionsEnum.YES);
		}
	};

	const handleLatexRadioNo = (checked: boolean) => {
		if (checked) {
			patientLatexAllergySetCurrentValue(AllergyOptionsEnum.NO);
		}
	};

	const handleLatexRadioNa = (checked: boolean) => {
		if (checked) {
			patientLatexAllergySetCurrentValue(AllergyOptionsEnum.UNABLE_TO_OBTAIN);
		}
	};

	const handleIodineRadioYes = (checked: boolean) => {
		if (checked) {
			patientIodineAllergySetCurrentValue(AllergyOptionsEnum.YES);
		}
	};

	const handleIodineRadioNo = (checked: boolean) => {
		if (checked) {
			patientIodineAllergySetCurrentValue(AllergyOptionsEnum.NO);
		}
	};

	const handleIodineRadioNa = (checked: boolean) => {
		if (checked) {
			patientIodineAllergySetCurrentValue(AllergyOptionsEnum.UNABLE_TO_OBTAIN);
		}
	};

	const handleInterpreterNeeded = (isChecked: boolean) => {
		interpreterNeededSetCurrentValue(isChecked);
		if (!isChecked) {
			patientInterpretLanguageSetCurrentValue('');
		}
	};

	const boxWidth = [1, .47, .32];
	const { marginBottomMd, standardGap, comments } = useStyles();

	return (
		<>
			<Flex flexDirection="row" flexWrap="wrap" justifyContent="flex-start" className={standardGap}>
				<FullPatientNameInput />
				<Box width={boxWidth} className={marginBottomMd} data-field="patientDOB">
					<TextFieldInput
						label="DATE OF BIRTH"
						placeholder={datePlaceholder}
						id="patientDOB"
						name="patientDOB"
						fieldName="patientDOB"
						required
						errorMessage={dateError}
						onBlur={triggerDateValidation}
						value={dateValue}
						onChange={setDateValue}
					/>
				</Box>
				<Box width={boxWidth} className={marginBottomMd} data-field="patientGender">
					<Dropdown
						label="Gender"
						placeholder="Select"
						id="patientGender"
						name="patientGender"
						required
						options={patientGenderOptions}
						selected={patientGenderSelectedDropdownOption}
						errorMessage={patientGenderError}
						onSelect={patientGenderSetSelectedValue}
						typeToSelect={true}
					/>
				</Box>
				<Box width="100%">
					<SSNInput />
				</Box>

				<PatientAddress />

				<Box width={boxWidth} className={marginBottomMd} data-field="patientPhoneNumber">
					<TextFieldInput
						label="PHONE NUMBER"
						placeholder={patientPhoneNumberPlaceholder}
						id="patientPhoneNumber"
						name="patientPhoneNumber"
						fieldName="patientPhoneNumber"
						required
						type="tel"
						errorMessage={patientPhoneNumberError}
						onBlur={patientPhoneNumberTriggerSelfValidation}
						value={patientPhoneNumberCurrentValue}
						onChange={patientPhoneNumberSetCurrentValue}
					/>
				</Box>
				<Box width={boxWidth} className={marginBottomMd} data-field="patientEmail">
					<TextFieldInput
						label="EMAIL"
						id="patientEmail"
						name="patientEmail"
						fieldName="patientEmail"
						value={patientEmailCurrentValue}
						errorMessage={patientEmailError}
						onBlur={patientEmailTriggerSelfValidation}
						onChange={patientEmailSetCurrentValue}
					/>
				</Box>
				<Flex justifyContent="flex-start" width="100%">
					<Box width={boxWidth} className={[marginBottomMd, 'margin-right-16']} data-field="patientInterpretLanguage">
						<Checkbox
							label="INTERPRETER NEEDED"
							id="interpreterNeeded"
							name="patientInterpreterNeeded"
							data-field="interpreterNeeded"
							checked={interpreterNeededCurrentValue}
							onChange={ (value: boolean) => handleInterpreterNeeded(value)}
						/>
						<TextFieldInput
							placeholder="Language"
							id="patientInterpretLanguage"
							name="patientInterpretLanguage"
							fieldName="patientInterpretLanguage"
							disabled={!interpreterNeededCurrentValue}
							value={patientInterpretLanguageCurrentValue}
							errorMessage={patientInterpretLanguageError}
							onBlur={patientInterpretLanguageTriggerSelfValidation}
							onChange={patientInterpretLanguageSetCurrentValue}
						/>
					</Box>
					<Box width={boxWidth} data-field="patientLatexAllergy">
						<Label id="patientLatexAllergyLabel">
							LATEX ALLERGY?
						</Label>
						<Flex flexDirection="row">
							<Box py={4} pr={4}>
								<Radio
									key={1}
									name="patientLatexAllergyYES"
									id="patientLatexAllergyYES"
									checked={patientLatexAllergyCurrentValue === AllergyOptionsEnum.YES}
									label="Yes"
									value="Yes"
									onChange={handleLatexRadioYes}
								/>
							</Box>
							<Box py={4} pr={4}>
								<Radio
									key={2}
									name="patientLatexAllergyNO"
									id="patientLatexAllergyNO"
									checked={patientLatexAllergyCurrentValue === AllergyOptionsEnum.NO}
									label="No"
									value="No"
									onChange={handleLatexRadioNo}
								/>
							</Box>
							<Box py={4} pr={4}>
								<Radio
									key={3}
									name="PatientLatexAllergyNA"
									id="PatientLatexAllergyNA"
									checked={patientLatexAllergyCurrentValue === AllergyOptionsEnum.UNABLE_TO_OBTAIN}
									label="Unable to Obtain"
									value={null}
									onChange={handleLatexRadioNa}
								/>
							</Box>
						</Flex>
					</Box>
				</Flex>
				<Box width={boxWidth} data-field="patientIodineAllergy">
					<Label id="patientIodineAllergyLabel">
						IODINE ALLERGY?
					</Label>
					<Flex flexDirection="row">
						<Box py={4} pr={4}>
							<Radio
								key={1}
								name="patientIodineAllergyYES"
								id="patientIodineAllergyYES"
								checked={patientIodineAllergyCurrentValue === AllergyOptionsEnum.YES}
								label="Yes"
								value="Yes"
								onChange={handleIodineRadioYes}
							/>
						</Box>
						<Box py={4} pr={4}>
							<Radio
								key={2}
								name="patientIodineAllergyNO"
								id="patientIodineAllergyNO"
								checked={patientIodineAllergyCurrentValue === AllergyOptionsEnum.NO}
								label="No"
								value="No"
								onChange={handleIodineRadioNo}
							/>
						</Box>
						<Box py={4} pr={4}>
							<Radio
								key={3}
								name="PatientIodineAllergyNA"
								id="PatientIodineAllergyNA"
								checked={patientIodineAllergyCurrentValue === AllergyOptionsEnum.UNABLE_TO_OBTAIN}
								label="Unable to Obtain"
								value="Unable to Obtain"
								onChange={handleIodineRadioNa}
							/>
						</Box>
					</Flex>
				</Box>
			</Flex>
			<Box mt={13} mr={200}>
				<TextFieldInput
					label="Other Patient Info"
					className={comments}
					multiline
					minRows={6}
					value={otherPatientDetailsCurrentValue}
					name="otherPatientDetails"
					fieldName="otherPatientDetails"
					onChange={otherPatientDetailsSetCurrentValue}
					data-field="patientInfo-otherDetails"
				/>
			</Box>
		</>
	);
}
