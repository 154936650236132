import React from 'react';
import { Box, Dialog, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useManageUsersListContext } from '../ManageUsersListContext/ManageUsersListContext';
import { useSelector } from 'react-redux';
import { UserProfileDetailsSelector } from '../../../store/selectors/UserProfileSelector';
import DeactivateSelfContent from './DeactivateSelfContent/DeactivateSelfContent';
import DeactivateUserContent from './DeactivateUserContent/DeactivateUserContent';
import { InteractionState } from '../ManageUsersListContext/ManageUsersListContextType';

const useStyles = makeStyles(theme => ({
	modal: {
		display: 'flex',
		flexDirection: 'column',
	},
	closeIcon: {
		marginTop: theme.spacing(2),
		marginRight: theme.spacing(3),
		height: '12px',
		width: '12px',
	},
}));

const DeactivateUserModal: React.FC = () => {
	const classes = useStyles();

	const { userId } = useSelector(UserProfileDetailsSelector);
	const { interactionState, managedUser, resetInteractionState } = useManageUsersListContext();

	const managingCurrentUser = userId === managedUser?.userId;

	return (
		<Dialog open={interactionState === InteractionState.DEACTIVATE}>
			<Box className={classes.modal}>
				<Box display="flex" justifyContent="flex-end">
					<IconButton  data-field="deactivate-modal-close-icon" className={classes.closeIcon} onClick={resetInteractionState}>
						<CloseIcon />
					</IconButton>
				</Box>
			</Box>
			{managingCurrentUser ? <DeactivateSelfContent/> : <DeactivateUserContent/>}
		</Dialog>
	);
};

export default DeactivateUserModal;
