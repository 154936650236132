import React from 'react';
import { makeStyles, createStyles, BoxProps } from '@material-ui/core';
import BoxFancyScroll from '@components/BoxFancyScroll/BoxFancyScroll';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => createStyles({
	body: {
		borderTop: `1px solid ${theme.palette.grey[800]}`,
		borderBottom: `1px solid ${theme.palette.grey[800]}`,
		borderRadius: '4px',
		boxShadow: '0px 0px 8px 0px rgba(15, 60, 151, 0.25) inset',
		gridArea: 'body',
		padding: theme.spacing(3),
		overflow: 'auto'
	},
}));

export const SectionedBoxBody: React.FC<BoxProps> = ({ children, className, ...boxProps }) => {
	const classes = useStyles();

	return (
		<BoxFancyScroll className={classNames(classes.body, className)} {...boxProps}>
			{children}
		</BoxFancyScroll>
	);
};
