import React from 'react';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import { FindATimeResponse } from '@data/findATime/types/FindATimeResponse';
import { ResultRow } from '@components/RequestForm/FindATime/FindATimeAccordion/FindATimeResults/Results/ResultRow';
import { AvailableTimeOption } from '@data/findATime/types/AvailableTimeOption';

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'column',
		},
	}),
);

interface ResultsProps {
	data: FindATimeResponse;
	handleSelectTime: (time: AvailableTimeOption, timeSlotId: string, date: Date) => void;
	handleSetFindATimeUsed?: (newValue: boolean) => void;
}

export const Results: React.FC<ResultsProps> = ({ data, handleSelectTime, handleSetFindATimeUsed }) => {
	const classes = useStyles();

	const ResultRows = data.map((availableDateOption) => {
		return <ResultRow key={availableDateOption.date} option={availableDateOption} handleSelectTime={handleSelectTime} handleSetFindATimeUsed={handleSetFindATimeUsed} />;
	});

	return <Box className={classes.root}>{ResultRows}</Box>;
};
