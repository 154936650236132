import React from 'react';
import { Flex, Text } from '@ascension/web';
import { makeStyles } from '@material-ui/core';
import MyFiltersForm from '../../AccountSettings/MyFilters/MyFiltersForm';
import { SetupCardSection } from '../SetupCardSection/SetupCardSection';
import MyFiltersSetupButtons from '../MyFiltersSetupButtons/MyFiltersSetupButtons';

const useStyles = makeStyles({
	container: {
		width: '640px',
		flexDirection: 'column',
		margin: 'auto'
	},
	titleStyle: {
		fontSize: '45px',
		marginTop: '32px',
		marginBottom: '24px'
	},
	cardBase: {
		marginBottom: '-40px',
		overflow: 'hidden',
		overflowY: 'scroll',
		maxHeight: 'calc(100vh - 194px)',
		paddingBottom: '0px',
		height: '100%'
	}
});

export const FiltersSetupPage = () => {
	const { container, titleStyle, cardBase } = useStyles();

	return (
		<Flex className={container}>
			<Text className={titleStyle}>Saved Filters</Text>
			<SetupCardSection sectionTitle="My Filters" customCardStyles={cardBase}>
				<MyFiltersForm buttonsComponent={<MyFiltersSetupButtons/>} />
			</SetupCardSection>
		</Flex>
	);
};
