import React from 'react';
import BoxWithLoaderProps from '@components/Loaders/BoxWithLoader/BoxWithLoaderProps';
import { Box } from '@material-ui/core';
import Loader from '@components/Loaders/Loader/Loader';
import useIsLoading from '@utilities/hooks/useIsLoading/useIsLoading';

const BoxWithLoader: React.FC<BoxWithLoaderProps> = ({ label, endpoint, children, loaderProps, ...props }) => {
	const isLoading = useIsLoading({ label, endpoint });

	return (
		<Box {...props}>
			{isLoading && <Loader {...loaderProps}/>}
			{children}
		</Box>
	);
};

export default BoxWithLoader;
