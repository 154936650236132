import React from 'react';
import { Box, Flex, Text } from '@ascension/web';
import { Checkbox, Link, Typography } from '@material-ui/core';
import { MyNotificationsCheckboxProps } from './MyNotificationsCheckboxProps';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserProfileDetailsSelector } from '../../../../store/selectors/UserProfileSelector';

const useStyles = makeStyles((theme) => ({
	container: {
		marginTop: theme.spacing(4),
		alignItems: 'self-start',
	},
	checkboxStyle: {
		padding: '0',
		marginRight: theme.spacing(1)
	},
	textStyle: {
		paddingTop: theme.spacing(0.25)
	},
	sendNotificationTo: {
		'& .MuiTypography-h3': {
			'paddingTop': theme.spacing(3),
			'&+div': {
				paddingTop: theme.spacing(1),
			},
		},
		'& a': {
			textTransform: 'initial',
		},
		'& span + button': {
			marginLeft: theme.spacing(2),
			top: '-2px',
		},
		'& .MuiTypography-body1': {
			fontWeight: 'initial',
		}
	},
}));

export const MyNotificationsCheckbox = ({ checked, onCheck, showEditLink }: MyNotificationsCheckboxProps) => {
	const classes = useStyles();
	const { container, checkboxStyle, textStyle } = classes;

	const history = useHistory();
	const handleClickEditEmail = () => history.push('/accountSettings/myInformation');

	const { email } = useSelector(UserProfileDetailsSelector);

	return (
		<Flex className={container}>
			<Checkbox
				checked={checked}
				value="emailNotifications"
				onChange={onCheck}
				color="primary"
				className={checkboxStyle}
			/>
			<Flex flexDirection="column">
				<Text className={textStyle}>
					Receive realtime email updates of the activity within your cases
				</Text>
				{checked && showEditLink && <Box className={classes.sendNotificationTo} data-field="notification-email-section">
					<Typography variant="h3">Send notification to:</Typography>
					<Box>
						<Typography
							variant="body1"
							component="span"
						>
							{email}
						</Typography>
						<Link
							component="button"
							onClick={handleClickEditEmail}
							variant="body1"
							data-field="notification-edit-email"
						>
							Edit preferred email
						</Link>
					</Box>
				</Box>}
			</Flex>
		</Flex>
	);
};
