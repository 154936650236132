import React from 'react';
import useSurgeonsSelection from '@utilities/hooks/useSurgeonsSelection/useSurgeonsSelection';

const useSurgeonsSelectionByIds = () => {
	const { prefSurgeons, selectedSurgeons, setSelectedSurgeons } = useSurgeonsSelection();

	const setSelectedSurgeonsByIds = React.useCallback((newSelectedSurgeonsByIds: string[] | ((currentlySelected: string[]) => string[])) => {
		const getSurgeonListFromIds = (surgeonIds: string[]) => prefSurgeons.filter(prefSurgeon => surgeonIds.includes(prefSurgeon.id));
		if (typeof newSelectedSurgeonsByIds !== 'function') {
			return setSelectedSurgeons(getSurgeonListFromIds(newSelectedSurgeonsByIds));
		}
		return setSelectedSurgeons((currentlySelected) =>
			getSurgeonListFromIds(
				newSelectedSurgeonsByIds(
					currentlySelected.map(surgeon => surgeon.id)
				)
			)
		);
	}, [prefSurgeons, setSelectedSurgeons]);

	const selectedSurgeonsByIds = React.useMemo(() => {
		return selectedSurgeons.map(s => s.id);
	}, [selectedSurgeons]);

	return {
		prefSurgeons,
		selectedSurgeonsByIds,
		setSelectedSurgeonsByIds,
	};
};

export default useSurgeonsSelectionByIds;
