import React from 'react';
import { ManageCaseOption } from '@components/CaseDetails/ManageCaseDropDown/ManageCaseOption';
import { RenderAsProps } from '@components/CaseDetails/ManageCaseDropDown/DropDownOptions/DropDownOptionProps';
import { useDrawerContext } from '@components/DrawerWrapper/DrawerContextProvider';
import { CASE_ACTIVITY_DRAWER } from '@components/DrawerWrapper/drawerConstants';

export const ViewActivity = ({ closeDropdown }: RenderAsProps) => {
	const { activeDrawer, openDrawer } = useDrawerContext();
	const showOption = activeDrawer !== CASE_ACTIVITY_DRAWER;

	const handleOnClick = () => {
		openDrawer(CASE_ACTIVITY_DRAWER);
		closeDropdown();
	};

	return (
		<>
			{ showOption &&
				<ManageCaseOption onClick={handleOnClick} fieldName="viewActivity">
					View Activity
				</ManageCaseOption>
			}
		</>
	);
};
