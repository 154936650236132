import React from 'react';
import { Box, makeStyles, createStyles, Typography, BoxProps } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => createStyles({
	header: {
		padding: theme.spacing(0, 3),
		color: theme.palette.grey[800],
		display: 'flex',
		alignItems: 'center',
		gridArea: 'header',
	},
	customBoxHeader: {
		padding: theme.spacing(1),
		color: theme.palette.grey[800],
		display: 'flex',
		alignItems: 'center',
		gridArea: 'header',
	}
}));

export interface SectionedBoxHeaderProps extends BoxProps {
	title: string;
	lowHeaderPadding?: boolean;
}

export const SectionedBoxHeader: React.FC<SectionedBoxHeaderProps> = ({ children, title, lowHeaderPadding = false, ...boxProps }) => {
	const classes = useStyles();
	return (
		<Box className={classNames({ [classes.customBoxHeader]: lowHeaderPadding, [classes.header]: !lowHeaderPadding })} {...boxProps}>
			<Typography component="h3" variant="subtitle2">
				{title}
			</Typography>
			{children}
		</Box>
	);
};
