import React  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, alpha, makeStyles } from '@material-ui/core';
import { Multiselect } from '@ascension/web';
import {
	AllSurgeonFilterProps,
	PreferredSurgeonFilterProps,
	SurgeonFilterInnerProps,
} from '@components/Filters/SurgeonFilter/SurgeonFilterProps';
import { RootState } from '@interfaces/rootState';
import { getAllSurgeonListData, getSurgeonListData } from '@store/actions/SurgeonListActionCreators';
import { DropdownOption } from '@definitions/DropdownOption';
import { Surgeon } from '@data/surgeon/Surgeon';
import { useFilterState } from '@components/Filters/FiltersContext/FiltersContext';
import {
	UserProfilePrimaryPracticeSelectorId,
} from '@store/selectors/UserProfileSelector';
import { FullSurgeonsSelector } from '@store/selectors/SurgeonListSelector';
import { getFormattedName } from '@utilities/commonFunction';
import { NO_SURGEON_DROPDOWN } from '@utilities/typeConverters/constants';

const useStyles = makeStyles((theme) => {
	return {
		surgeonDropdown: {
			'minWidth': 120,
			'& > div > div > div:nth-of-type(2)': {
				marginTop: theme.spacing(.2),
			},
			'& > div > div > div > div:first-child': {
				borderWidth: '1px',
				borderRadius: theme.spacing(.5),
				borderColor: alpha(theme.palette.common.black, .6),
			},
		}
	};
});

function SurgeonFilter({ availableOptions, placeholder, onSurgeonFilterUpdate, ...rest }: SurgeonFilterInnerProps) {
	const classes = useStyles();
	const [selectedOptions, setSelectedOptions] = React.useState<DropdownOption[]>();
	const { surgeons, updateFilterPropsAndResetFilterToggle } = useFilterState();

	const handleSetValue = (newValue: DropdownOption[]) => {
		setSelectedOptions(newValue);
		const newSurgeonsIds = newValue.map(option => option.value);
		void onSurgeonFilterUpdate?.(newSurgeonsIds);

		updateFilterPropsAndResetFilterToggle({ surgeon: newSurgeonsIds });
	};

	// Set selected surgeons
	React.useEffect(() => {
		const selected = availableOptions.filter(({ value }: DropdownOption) =>
			surgeons.some((newValue: string) => newValue === value));

		const selectedWithDefault = selected.length ? selected : availableOptions.slice(0, 1);
		setSelectedOptions(selectedWithDefault);
	}, [availableOptions, onSurgeonFilterUpdate, surgeons]);

	return (
		<Box data-field="Surgeon_Filter" className={classes.surgeonDropdown}>
			<Multiselect filterable value={selectedOptions} onChange={handleSetValue} label="Filter by" options={availableOptions} placeholder={placeholder} limitOptionsVisibilityBy={151} {...rest}/>
		</Box>
	);
}

export const PreferredSurgeonFilter = ({ showNoSurgeon, ...rest }: PreferredSurgeonFilterProps) => {
	const dispatch = useDispatch();
	React.useEffect(() => {
		dispatch(getSurgeonListData());
	}, [dispatch]);

	const surgeonList = useSelector((state: RootState) => state.surgeonList.surgeonList);
	const [totalSurgeons, setTotalSurgeons] = React.useState(surgeonList.length);

	React.useEffect(() => {
		setTotalSurgeons(surgeonList.length);
	}, [surgeonList, setTotalSurgeons]);

	const placeholder = React.useCallback((selectedSurgeons: string[]) => {
		if (!selectedSurgeons) {
			return 'Select';
		}
		if (selectedSurgeons.length === 1 && selectedSurgeons[0].startsWith('All')) {
			return `All Preferred (${totalSurgeons})`;
		}
		if (selectedSurgeons.length === 1) {
			return selectedSurgeons[0];
		}

		return `${selectedSurgeons.length} Surgeons Selected (${totalSurgeons})`;
	}, [totalSurgeons]);

	const options = React.useMemo(() => {
		if (!surgeonList || !surgeonList.length) { return []; }
		const formatted = surgeonList.map((item: Surgeon) => ({
			value: item.id,
			label: getFormattedName(item),
		}));
		formatted.unshift({ value: 'all', label: 'All Preferred (' + totalSurgeons + ')' });
		if (showNoSurgeon) {
			formatted.push({ ...NO_SURGEON_DROPDOWN });
		}
		return formatted;
	}, [showNoSurgeon, surgeonList, totalSurgeons]);

	return <SurgeonFilter availableOptions={options} placeholder={placeholder} {...rest}/>;
};

export const AllSurgeonFilter = ({ ...rest }: AllSurgeonFilterProps) => {
	const dispatch = useDispatch();
	const practiceId = useSelector<RootState, string>(UserProfilePrimaryPracticeSelectorId);

	// Retrieve all surgeons for practice
	React.useEffect(() => {
		if (practiceId !== '') {
			dispatch(getAllSurgeonListData(practiceId));
		}
	}, [dispatch, practiceId]);

	const surgeonList = useSelector(FullSurgeonsSelector);

	const [totalSurgeons, setTotalSurgeons] = React.useState(surgeonList.length);
	React.useEffect(() => {
		setTotalSurgeons(surgeonList.length);
	}, [surgeonList, setTotalSurgeons]);

	const placeholder = React.useCallback((selectedSurgeons: string[]) => {
		if (!selectedSurgeons) {
			return 'Select';
		}

		if (selectedSurgeons.length === 1 && selectedSurgeons[0].startsWith('All')) {
			return `All (${totalSurgeons})`;
		}

		if (selectedSurgeons.length === 1) {
			return selectedSurgeons[0];
		}

		return `${selectedSurgeons.length} Surgeons Selected (${totalSurgeons})`;
	}, [totalSurgeons]);

	const options = React.useMemo(() => {
		if (!surgeonList || !surgeonList.length) { return []; }
		const formatted: DropdownOption[] = surgeonList.map((item: Surgeon) => ({
			value: item.id,
			label: getFormattedName(item),
		}));
		formatted.unshift({ value: 'all', label: 'All' });
		formatted.push({ ...NO_SURGEON_DROPDOWN });
		return formatted;
	}, [surgeonList]);

	return <SurgeonFilter availableOptions={options} placeholder={placeholder} {...rest} />;
};


