import React, { ChangeEvent } from 'react';
import { Checkbox, CheckboxProps, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface PrimaryCheckboxProps extends CheckboxProps {
	checked: boolean | undefined;
	handleChange: (event: ChangeEvent, value: boolean) => void;
	label?: string | JSX.Element;
}

const useStyles = makeStyles({
	checkbox: {
		'& .MuiSvgIcon-root': {
			height: '28px',
			width: '28px',
		},
	},
	label: {
		'& .MuiTypography-root': {
			fontFamily: 'Lato',
		},
	}
});

const PrimaryCheckbox: React.FC<PrimaryCheckboxProps> = ({ checked, handleChange, label, ...checkBoxProps }) => {
	const classes = useStyles();
	return (
		<FormControlLabel
			control={
				<Checkbox
					checked={!!checked}
					onChange={handleChange}
					color="primary"
					className={classes.checkbox}
					{...checkBoxProps}
				/>
			}
			label={label}
			className={classes.label}
		/>
	);
};

export default PrimaryCheckbox;
