import CalendarErrorContainer from '@components/CalendarError/presentationalComponents/CalendarErrorContainer';
import CalendarErrorTitle from '@components/CalendarError/presentationalComponents/CalendarErrorTitle';
import CalendarErrorBody from '@components/CalendarError/presentationalComponents/CalendarErrorBody';
import CalendarErrorButton from '@components/CalendarError/presentationalComponents/CalendarErrorButton';
import React from 'react';
import {
	useOfficeCalendarViewContext
} from '@components/OfficeCalendarView/OfficeCalendarViewContext/OfficeCalendarViewContext';

const OutOfRangeFutureCalendarError = () => {
	const {
		setSelectedRoom,
		toggleDrawer,
	} = useOfficeCalendarViewContext();

	const handleReturnToSurgery = React.useCallback(() => {
		setSelectedRoom(undefined);
		toggleDrawer(false)();
	}, [setSelectedRoom, toggleDrawer]);

	return (
		<CalendarErrorContainer>
			<CalendarErrorTitle>
				We can’t determine availability more than 30 days in advance.
			</CalendarErrorTitle>
			<CalendarErrorBody>
				You can still submit a Surgery Request, but your requested date and time will be subject to OR review.
			</CalendarErrorBody>
			<CalendarErrorButton onClick={handleReturnToSurgery}>
				Return to Surgery Request
			</CalendarErrorButton>
		</CalendarErrorContainer>
	);
};

export default OutOfRangeFutureCalendarError;
