import React from 'react';
import UseMaskFormContextReturnInterface from './UseMaskFormContextReturnInterface';
import { useFieldFormContext } from '../useFieldFormContext/useFieldFormContext';
import { UseFieldFormContextConfigInterface } from '../useFieldFormContext/UseFieldFormContextConfigInterface';
import { useMask } from '@utilities/hooks/useMask/useMask';
import { MaskType } from '@utilities/hooks/useMask/maskConfigurations';

export const useMaskFormContext = (formPropertyName: string, maskType: MaskType | string, config?: UseFieldFormContextConfigInterface): UseMaskFormContextReturnInterface => {
	const {
		currentValue = '',
		setCurrentValue,
		error,
		triggerSelfValidation
	} = useFieldFormContext<string>(formPropertyName, config);


	const { value: currentMaskedValue, updateValue: updateMaskValue, mask: { placeholder } } = useMask(maskType, currentValue);

	React.useEffect(() => {
		if (currentMaskedValue !== null) {
			setCurrentValue(currentMaskedValue);
		}
	}, [currentMaskedValue, setCurrentValue]);

	return [
		currentValue,
		updateMaskValue,
		error,
		placeholder,
		triggerSelfValidation,
	];
};
