import React from 'react';
import TimeTick from '@calendar/types/TimeTick';
import getTimeScale from '@calendar/utilities/getTimeScale/getTimeScale';
import getTimeParts from '@calendar/utilities/getTimeParts/getTimeParts';
import { formatInTimeZone } from 'date-fns-tz';

/**
 * Should NOT be used to implement y-axis ticks for Calendar as that is already handled by the component
 *
 * useTimeTicks can be used to get an array of TimeTick objects for a 24 hour period based on specified timezone and interval
 * @param {string} timezone - valid timezone string for date-fns
 * @param {number} interval - in minutes
 *
 * @returns TimeTick[]
 *
 * @example
 * useTimeTicks('America/Chicago', 15);
 * // returns [
 * {formatted: "12:00 AM", hour: 0, minute: 0, number: 1667192400000},
 * {formatted: "12:15 AM", hour: 0, minute: 15, number: 1667193300000},...
 * {formatted: "12:00 AM", hour: 0, minute: 0, number: 1667278800000}
 * ]
 * (total array length 97)
 */

const useTimeTicks = (timezone: string, interval: number, tickStart: Date) => {
	const [timeTicks, setTimeTicks] = React.useState<TimeTick[]>([]);

	React.useEffect(() => {
		try {
			const ticks = getTimeScale(timezone, interval, tickStart);
			setTimeTicks(ticks.map((t, i): TimeTick => {
				const formatted = formatInTimeZone(t, timezone, 'h:mm aa');
				const { hour, minute } = getTimeParts(t, timezone);

				return {
					number: t.getTime(),
					hour,
					minute,
					formatted,
					minutesSinceMidnight: i * interval,
				};
			}));
		} catch {
			setTimeTicks([]);
		}
	}, [interval, setTimeTicks, tickStart, timezone]);

	return timeTicks;
};

export default useTimeTicks;
