import { getDateFromApiDateTime } from '@calendar';
import EventFlag from '@data/schedules/types/EventFlag';
import { ScheduleEventApiResponse } from '@data/schedules/types/ScheduleEventApiResponse';
import { ScheduleEventType } from '@data/schedules/types/ScheduleEventType';
import { ScheduleEventCardData } from '@data/schedules/types/ScheduleEventCardData';

export const DEFAULT_DURATION_MINS = 30;
const convertEventResponseToUiData = (owner: string) => (
	e: ScheduleEventApiResponse,
): ScheduleEventCardData => {
	const startDate = getDateFromApiDateTime(e.start);
	const endDate = getDateFromApiDateTime(
		e.end || startDate.getTime() + DEFAULT_DURATION_MINS * 1000 * 60,
	);
	let durationCalcMins = Math.round(
		(endDate.getTime() - startDate.getTime()) / 1000 / 60,
	);
	if (
		(!e.end || e.type === ScheduleEventType.UNSCHEDULED) &&
		durationCalcMins < DEFAULT_DURATION_MINS
	) {
		durationCalcMins = DEFAULT_DURATION_MINS;
	}
	return {
		...e,
		owner,
		startDate,
		endDate,
		durationCalcMins,
		description: `Pt: ${e.patient}\n${e.procedure}`,
		isNeedsRevision: e.flags?.includes(EventFlag.NEEDS_REVISION),
		isPracticeEvent: !e.flags?.includes(EventFlag.OTHER_PRACTICE),
		isBlockEvent: e.flags?.includes(EventFlag.BLOCK),
	};
};

export default convertEventResponseToUiData;
