import React from 'react';
import { useDispatch } from 'react-redux';
import { getHoldEventApiCreateAction } from '@data/holdEvent/actionCreators/HoldEventActionCreators';
import { CreateHoldEventApiPayload } from '@data/holdEvent/types/HoldEventApiPayload';

const useCreateHoldEvent = () => {
	const dispatch = useDispatch();

	return React.useCallback((overrideRequest: CreateHoldEventApiPayload) => {
		dispatch(getHoldEventApiCreateAction(overrideRequest));
	}, [dispatch]);
};

export default useCreateHoldEvent;
