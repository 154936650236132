import { Reducer } from 'redux';
import { SurgeonListState, SurgeonListTypes } from '../../interfaces/SurgeonListTypes';
import { UPDATE_SURGEON_LIST, UPDATE_SURGEON_LIST_ERROR, UPDATE_ALL_SURGEON_LIST } from '../actions/types';
import { Surgeon } from '../../data/surgeon/Surgeon';
import { alphaByDisplayName, getFormattedName } from '@utilities/commonFunction';

const initialState: SurgeonListState = {
	surgeonList: [],
	fullSurgeonList: [],
	error: null
};
export const SurgeonListReducer: Reducer<SurgeonListState, SurgeonListTypes>
	= ( state = initialState, action: SurgeonListTypes) => {
		switch (action.type) {
		case UPDATE_SURGEON_LIST:
			return {
				...state,
				surgeonList: action.payload.data.map((s) => ({
					...s,
					displayName: getFormattedName(s),
				})).sort(alphaByDisplayName),
			};
		case UPDATE_ALL_SURGEON_LIST:
			return {
				...state,
				fullSurgeonList: [...action.payload.data.map((value: Surgeon) => {
					return { ...value, isPreferred: state.surgeonList.some(data => data.id === value.id)  };
				})]
			};
		case UPDATE_SURGEON_LIST_ERROR:
			return { ...state, error: action.error };
		default:
			return state;
		}
	};
