import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Box, FormHelperText, Typography } from '@material-ui/core';
import OptionLabelProps from '@components/OptionLabel/OptionLabelProps';

const useStyles = makeStyles(theme => ({
	optionLabel: {
		...theme.typography.subtext1,
		fontWeight: 600,
		letterSpacing: 'normal',
	},
	optionHelpText: {
		...theme.typography.subtext1,
		letterSpacing: 'normal',
	},
}));

const OptionLabel: React.FC<OptionLabelProps> = ({ children, helperText }) => {
	const classes = useStyles();
	return <Box>
		<Typography className={classes.optionLabel}>{children}</Typography>
		{helperText && <FormHelperText className={classes.optionHelpText}>{helperText}</FormHelperText>}
	</Box>;
};

export default OptionLabel;
