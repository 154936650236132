import { useState, useCallback } from 'react';
import { Mask, maskConfigurations, MaskType } from '@utilities/hooks/useMask/maskConfigurations';

type OptionalString = string | null;
type MaskReturn = {
    value: OptionalString;
    updateValue: (v: string) => void;
    mask: Mask;
};
const onlyNumbers = /\d/g;

/**
 * Formats an input string based on a provided mask.
 *
 * This function extracts numbers from the input string and then reconstructs the string
 * according to the provided mask. It does this by splitting the mask into parts, and then
 * reassembling the input string piece by piece according to the mask.
 *
 * @param {string} inputValue - The string to be formatted.
 * @param {Mask} mask - The mask object that defines how to format the string.
 *
 * @returns {string} The formatted string.
 *
 * @example
 *
 * // For a mask with placeholder 'MM / DD / YYYY' and inputValue '12312020',
 * // the function will return '12 / 31 / 2020'.
 *
 * const formattedValue = formatValue('12312020', mask);
 * // Outputs: '12 / 31 / 2020'
 */
const formatValue = (inputValue: string, mask: Mask) => {
	// Extract only numbers from the input string
	const inputNumbers = inputValue.match(onlyNumbers) || [];

	let result = '';
	let numIndex = 0;

	// Iterate through the mask characters
	for (const maskChar of mask.placeholder) {
		const isPlaceholder = ['H', 'M', 'D', 'Y', '#'].includes(maskChar);

		// If the mask character is a placeholder and there is an input number for it, append the input number to the result
		if (isPlaceholder && inputNumbers[numIndex]) {
			result += inputNumbers[numIndex++];
			continue;
		}

		// If the mask character is not a placeholder and there is an input number for it, append maskChar to the result
		if (!isPlaceholder && inputNumbers[numIndex]) {
			result += maskChar;
			continue;
		}

		// If the mask character is a placeholder without a corresponding input number, stop processing
		break;
	}

	return result;
};

function assertValidMaskType(maskType: unknown): asserts maskType is MaskType {
	if (typeof maskType !== 'string' || !Object.values(MaskType).includes(maskType as MaskType)) {
		throw new Error(`Invalid mask type: ${typeof maskType === 'string' && maskType}`);
	}
}

/**
 * `useMask` is a custom hook for handling input masks in forms.
 *
 * @param {MaskType} maskType - The type of mask to be applied. This should be one of the keys in the `masks` object.
 * @param {OptionalString} initialValue - The initial value for the input field.
 *
 * @returns {MaskReturn} An array of three elements:
 *   - The current value of the input field.
 *   - A function that updates the value of the input field based on the mask.
 *   - The mask configuration object which includes placeholder and optional validate function.
 *   - The placeholder is the format for how the returned masked value will look
 *
 * @example
 *   // Current usage of `useMask` in the components involve the implementation of `useMaskFormContext`. Check the implementation for more context.
 *   const [value, updateValue, mask] = useMask('date', '12312020');
 *
 *  // In your component's render method:
 *  <input
 *    value={value}
 *    onChange={e => updateValue(e.target.value)}
 *    placeholder={mask.placeholder}
 *  />
 *
 *  // Masked return value
 *    12 / 31 / 2020
 *
 * The mask types currently supported are 'time', 'date', 'ssn', and 'phone'.
 * Each mask type has a specific placeholder and optional validate function associated with it.
 * The validate function is used to further validate or format the user's input, beyond what the mask already provides.
 *
 *  // To add a new mask type
 *      1. Simply add a new object to `masks.ts` and give the placeholder key and value
 *      2. If the placeholder chars are any other than ['H', 'M', 'D', 'Y', '#'], add them in the `isPlaceholder` check in `formatValue` function
 *      3. Similarly add validate function if necessary (refer to the `date` object)
 */
export const useMask = (maskType: MaskType | string | Mask, initialValue: OptionalString): MaskReturn => {
	const [value, setValue] = useState(initialValue);

	let mask: Mask;

	if (typeof maskType === 'string') {
		assertValidMaskType(maskType);
		mask = maskConfigurations[maskType];
	} else {
		mask = maskType;
	}

	const updateValue = useCallback((inputText: string) => {
		let updatedValue = formatValue(inputText, mask);
		if (mask.validate) {
			updatedValue = mask.validate(updatedValue);
		}
		setValue(updatedValue);
	}, [mask]);

	return { value, updateValue, mask };
};
