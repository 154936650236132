import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, BoxProps } from '@material-ui/core';
import { HEADER_GRID_AREA } from '@components/MainPageLayout/presentationalComponents/MainPageLayoutContainer';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
	root: {
		gridArea: HEADER_GRID_AREA,
		display: 'flex',
		flexWrap: 'wrap',
	},
}));

const MainPageLayoutHeader: React.FC<BoxProps> = ({ children, ...boxProps }) => {
	const classes = useStyles();

	return (
		<Box className={classNames(classes.root, boxProps.className)} {...boxProps}>
			{children}
		</Box>
	);
};

export default MainPageLayoutHeader;
