import { TIME_12_REGEX, TIME_ONLY_FORMAT } from '@utilities/constants';
import { formatInTimeZone } from 'date-fns-tz';
import { addMinutes } from 'date-fns';
import { getZonedTimeFromTimeAndDate } from '@utilities/dateUtilities';

/***
 *
 * @param timeZone {string} - valid time zone
 * @param baseTime {string} - 12 hour formatted string. typically start time to use to calculate the result end time
 * @param durationMinutes - number of minutes to add to start time
 * @param maxDate - max time to use to clamp
 */
export const calculateTimeWithDuration = (timeZone: string, baseTime?: string, durationMinutes = 60, maxDate?: Date): string | undefined => {
	if (!baseTime || !TIME_12_REGEX.test(baseTime)) {
		return;
	}

	const getClampedTime = (time: Date): string /** 'h:mm aa' 12hr time **/ => {
		// When new time is greater than max date
		if (maxDate && time && time > maxDate) {
			// Return max date formatted
			return formatInTimeZone(maxDate, timeZone, TIME_ONLY_FORMAT);
		}

		// return new time formatted
		return formatInTimeZone(time, timeZone, TIME_ONLY_FORMAT);
	};

	// Convert to date for end time calculation
	const newStartDate = getZonedTimeFromTimeAndDate(baseTime, timeZone);

	// Calculate the end time with the given start date and durationMinutes
	const newEndDate = !!newStartDate && addMinutes(newStartDate, durationMinutes) || undefined;

	// Clamp the time
	return newEndDate && getClampedTime(newEndDate);
};
